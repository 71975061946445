(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', 'UcAlertService'];

    function errorHandlerInterceptor ($q, UcAlertService)
    {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response)
        {
            if (	!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.endsWith('/api/account')))) &&
            		!(response.status === 403 && (response.data.path && response.data.path.endsWith('/api/logout')))	)
			{
            	if(response.config && response.config.url && response.config.url.indexOf('/message-listener') > -1)
            	{
            		// Não deve logar erros do message-listener
            	}
            	else
            	{
                    if (response.data.value) {
                        response.data = angular.fromJson(response.data);
                    }
            		UcAlertService.processError(response);
            	}
            }
            return $q.reject(response);
        }
    }
})();
