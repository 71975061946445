(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProcedurePlaceDomainController', ProcedurePlaceDomainController);
    
    ProcedurePlaceDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'ProcedurePlace', 'pageConfig', '$rootScope', 'FormConstants', 'tabList', '$filter', '$stateParams', '$timeout'];

    function ProcedurePlaceDomainController(FormDomain, $scope, $state, entity, ProcedurePlace, pageConfig, $rootScope, FormConstants, tabList, $filter, $stateParams, $timeout)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
        vm.tabList = tabList;
        vm.listDaysWeek = [];
        var isSaving = false;

        $rootScope.initialFocus("code");
        
        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "PROCEDURE_PLACE",
        	stateRoot: "procedurePlace",
        	previousState: {name: $state.current.parent},
        	onClear: function() { vm.formHandler.clear(); }
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: ProcedurePlace,
        	historyConfig: {historyId: "PROCEDURE_PLACE"},
        	getForm: function(){ return $scope.editForm; },
        	beforeInvokeService: function(p_json)
        	{
        		// Adiciona o campo de Obs Disponibilidae
        		try
        		{
        			p_json.availabilityObs = editor.getData();
        		}
        		catch(exc)
        		{
        			console.error("Error at read availabilityObs: " + exc.message);
        		}
        	}
        };
        
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "String",
					id: "code",
					required: true,
					label: "common.domain.procedurePlace.code",
					maxLength: 30
				})
        	])
			.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "String",
					id: "description",
					required: true,
					label: "common.domain.procedurePlace.description",
					maxLength: 300
				})
        	])
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "client.global.label.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();
        
        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        }
        
        //Tab schedule
        
        function saveCallback()
        {
        	isSaving = false;
		};
        
        function initDaysWeek() 
		{
			vm.startTime = '';
			vm.endTime = '';
			var daysWeek = [ { day: 'D', number: 0, status: false, startTime: '', endTime: ''}, 
							{ day: 'S', number: 1, status: false, startTime: '', endTime: ''},
							{ day: 'T', number: 2, status: false, startTime: '', endTime: ''},
							{ day: 'Q', number: 3, status: false, startTime: '', endTime: ''},
							{ day: 'Q', number: 4, status: false, startTime: '', endTime: ''},
							{ day: 'S', number: 5, status: false, startTime: '', endTime: ''},
							{ day: 'S', number: 6, status: false, startTime: '', endTime: ''}]; 

			return daysWeek;
		}

		vm.daysWeek = initDaysWeek();
        
		
		
        vm.tabHandler = {
            	active: 'main',
            	disabledMap: {},
            	isActive: function(p_code)
            	{
            		return (p_code === vm.tabHandler.active);
            	},
            	isDisabled: function(p_code)
            	{
    				if(p_code == "schedulesAvailable" && vm.pageConfig.pageMode === 'CREATE') {
    					return true;
    				} 
    				else {
    					return vm.tabHandler.disabledMap[p_code];
    				}
            	},
            	select: function(p_id)
            	{
            		// só se estiver ativo
            		if(!vm.tabHandler.isDisabled(p_id))
            		{
            			vm.tabHandler.active = p_id;
    				}
            	}
            };
        
        vm.selectDay = function(day, index)
		{
			vm.daysWeek[index].status = !day.status;
			if ($filter('filter')(vm.daysWeek, {status: true}).length > 0) {
				vm.daysWeekIsValid = true;
			}
		};

		vm.clearTime = function(id)
		{
			if (id === 1) {
				vm.startTime = '';
			} else {
				vm.endTime = '';
			}
		};

		function parameterseSchedulesAvailable(edit) 
		{
			var objJson = [];
			angular.forEach(vm.daysWeek, function(item) {
				if (item.status) {
					objJson.push({ id: (edit ? item.id : null), idSchedulable: $stateParams.id, weekDay: item.number, startTime: vm.formSchedulesAvailable.startTime.$viewValue, endTime: vm.formSchedulesAvailable.endTime.$viewValue });
				}
			});

			return objJson;
		}

		function saveCallback()
        {
        	isSaving = false;
		};

		var timeStringToMin = function(time)
		{
			if (angular.isUndefined(time) || time === 0)
			{
				return 0;
			}
			else
			{
				var hoursMinutes = time.split(':');
				var hours = parseInt(hoursMinutes[0], 10);
				var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
				return hours * 60 + minutes;
			}
		};
		
		/**
		 * TODO_CODE: Reescrever: foi alterado para permitir sobreposicação de bordas e quebrou
		 */
		function validateSchedulesAvailable(day, startTime, endTime)
		{
			var auxList = _.filter(vm.listDaysWeek, function(e) {return e.weekDay === day; });
			var startTime = timeStringToMin(startTime);
			var endTime = timeStringToMin(endTime);
			
			var result = true;
			_.each(auxList, function(l){
				if (startTime < timeStringToMin(l.startTime) && endTime > timeStringToMin(l.startTime))
				{
					result = false;
				}
				else if (startTime > timeStringToMin(l.startTime) && endTime < timeStringToMin(l.endTime))
				{
					result = false;
				}
				else if (startTime < timeStringToMin(l.endTime) && endTime > timeStringToMin(l.endTime))
				{
					result = false;
				}
				else if (startTime === timeStringToMin(l.startTime) && endTime === timeStringToMin(l.endTime))
				{
					result = false;
				}
				else if (l.startTime === '00:00' && l.endTime === '00:00')
				{
					result = false;
				}
			});

			return result;
		};

		vm.saveSchedulesAvailable = function()
		{
			vm.daysWeekIsValid = true;
			if ($filter('filter')(vm.daysWeek, {status: true}).length === 0) {
				vm.daysWeekIsValid = false;
				return false;
			}

			vm.timeBigger = false;
			if (Date.parse('01/01/1970 ' + vm.formSchedulesAvailable.startTime.$viewValue + ':00') > Date.parse('01/01/1970 ' + vm.formSchedulesAvailable.endTime.$viewValue + ':00')) {
				vm.timeBigger = true;
				return false;
			}

			vm.timeConflict = false;
			angular.forEach(vm.daysWeek, function(item) {
				if (item.status) {
					if (!validateSchedulesAvailable(item.number, vm.formSchedulesAvailable.startTime.$viewValue, vm.formSchedulesAvailable.endTime.$viewValue)) {
						vm.timeConflict = true;
					}
				}
			});

			if (vm.timeConflict) {
				return false;
			}

        	if (vm.formSchedulesAvailable.$valid)
            {
				isSaving = true;
				var objJson = parameterseSchedulesAvailable(false);
				ProcedurePlace.refreshAvailabilitySchedule(objJson, 
					function(response) {
						angular.forEach(response, function(item, key) {
							objJson[key].id = item;
							vm.listDaysWeek.push(objJson[key]);
						});
						vm.daysWeek = initDaysWeek();
						vm.formSchedulesAvailable.$submitted = false;
						isSaving = false;
					}, 
					saveCallback);
			}
		};

		vm.deleteSchedulesRegistered = function(id, index) 
		{
			ProcedurePlace.delete({ id: id }, 
				function()
				{
					vm.listDaysWeek.splice(index, 1);
				}, 
				saveCallback);
		}

		vm.getSchedulesAvailable = function() 
		{
			ProcedurePlace.listAvailabilitySchedule({ id: $stateParams.id }, 
				function(response)
				{
					angular.forEach(response, function(item) {
						vm.listDaysWeek.push({ id: item.id, weekDay: item.weekDay, startTime: item.startTime, endTime: item.endTime });
					});
				});
		};
		
		 /*private*/ var editor = null;
        $timeout(function()
        {
        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
        }, 100);
        
        if (vm.pageConfig.pageMode != 'CREATE') {
			vm.getSchedulesAvailable();
		}
	        
		//Tab schedule
        
      
    }
})();
