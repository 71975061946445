(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsTemplateModalController', RecordsTemplateModalController);

    RecordsTemplateModalController.$inject = ['$uibModalInstance', 'pageConfig', 'recordType', 'templateTree', 'RecordsTemplateDirectoryModalInvoker', 'RecordsTemplateItemModalInvoker', 'RecordsTemplate', '$translate'];

    function RecordsTemplateModalController($uibModalInstance, pageConfig, recordType, templateTree, RecordsTemplateDirectoryModalInvoker, RecordsTemplateItemModalInvoker, RecordsTemplate, $translate)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        vm.recordType = recordType;
        
        /*public*/ vm.templateTree = templateTree;
        
        /*public*/ vm.showMap = {};
        
        /*public*/ vm.query = {name: ""};
        
        /*public*/ vm.showContent = null;
        
        /*private*/ var initialContentHtml = null;
        
        /*private*/ var selectedMap = {}; 
 
        /*public*/ vm.dirMenuOptions = [
        	['<i class="fa fa-pencil text-accent" aria-hidden="true"></i>&nbsp;' + $translate.instant("client.global.label.edit") + "/" + $translate.instant("client.global.label.delete"), function ($itemScope)
        	{
        		vm.editDir($itemScope.dir);
        	}]
        ];
        
        /*public*/ vm.itemMenuOptions = [
        	['<i class="fa fa-pencil text-accent" aria-hidden="true"></i>&nbsp;' + $translate.instant("client.global.label.edit") + "/" + $translate.instant("client.global.label.delete"), function ($itemScope)
        	{
        		vm.editItem($itemScope.itm);
        	}]
        ];
        
        /*public*/ vm.editDir = function(p_obj)
        {
        	RecordsTemplateDirectoryModalInvoker.invoke({
        		recordType: recordType,
        		templateDirId: (p_obj) ? p_obj.id : null,
        		callback: function(p_flag)
        		{
        			if(p_flag) // Recarrega
        			{
        				reload(false);
        			}
        		}
        	});
        }
        
        /*public*/ vm.editItem = function(p_obj)
        {
        	RecordsTemplateItemModalInvoker.invoke({
        		recordType: recordType,
        		templateId: (p_obj) ? p_obj.id : null,
        		callback: function(p_flag)
        		{
        			if(p_flag) // Recarrega
        			{
        				reload(true);
        			}
        		}
        	});
        }
        
        /*public*/ vm.selectTemplate = function()
        {
        	$uibModalInstance.close(generateContentString());
        }
        
        /*public*/ vm.selectItem = function(p_obj)
        {
        	// Inverte a seleção
        	p_obj.selected = !p_obj.selected;
        	
        	if(p_obj.selected)
        	{
        		selectedMap[p_obj.id] = p_obj;

        		// Conteúdo já carregado?
            	if(p_obj.content)
            	{
            		flush();
            	}
            	else
            	{
        			RecordsTemplate.getComplete({id: p_obj.id}, function(p_dto)
        			{
        				p_obj.content = (p_dto && p_dto.id) ? p_dto.content : null;
        				flush();
        			});
            	}        		
        	}
        	else
        	{
        		delete selectedMap[p_obj.id];
        		flush();
        	}
        	
        	vm.showContent = (Object.keys(selectedMap).length > 0);
        }
        
        /*private*/ function generateContentString()
        {
        	var code = "";
        	var keysArray = Object.keys(selectedMap);
        	for (var i = 0; i < keysArray.length; i++)
        	{
        		code += "<div class='template-selected-record-item'>" + selectedMap[keysArray[i]].content + "</div>"
			}
        	return code;
        }
        
        /*private*/ function flush()
        {
        	// Flush em todos os itens selecionados
        	var code = generateContentString();

        	var pw = frames["showTemplateFrame"];
			pw.document.write('<html>');
			pw.document.write('<head></head>');
			pw.document.write(generateTemplateCssStyle());
			pw.document.write('<body class="template-selected-record-area">');
            pw.document.write(code);
			pw.document.write('</body>');
			pw.document.write('</html>');
			
			try
			{
				pw.document.close(); // necessary for IE >= 10
			}
			catch(exc)
			{
				console.error("Error on prepare document to showTemplateFrame: " + exc.message);
			}
        }
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        
        /*private*/ function reload(p_reloadSelectedTemplate)
        {
			RecordsTemplate.getTree({recordType: recordType.id}, function(p_templateTree)
			{
				vm.templateTree = p_templateTree;
				
				// Percorre a árvore se atualiza os objetos selecionados
				for (var i = 0; i < vm.templateTree.list.length; i++)
				{
					if(vm.templateTree.list[i])
					{
						for (var j = 0; j < vm.templateTree.list[i].list.length; j++)
						{
							var item = vm.templateTree.list[i].list[j];
							if(selectedMap[item.id])
							{
								item.selected = true;
								selectedMap[item.id] = item;

								// Recarrega o conteúdo
				        		(function(p_targetId, p_selectedMap)
		                		{
		                			RecordsTemplate.getComplete({id: p_targetId}, function(p_dto)
		           					{
		                				p_selectedMap[p_targetId].content = (p_dto && p_dto.id) ? p_dto.content : null;
		                				flush();
		                			});
		                		}(item.id, selectedMap));

							}
						}
					}
				}
			});
        }
	}
})();