(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceConfirmationController', AttendanceConfirmationController);

    AttendanceConfirmationController.$inject = ['$translate', '$sce', 'confirmationData', 'UcAlertService', '$timeout', '$rootScope', 'AttendanceConfirmation', '$scope', '$stateParams'];

    function AttendanceConfirmationController($translate, $sce, confirmationData, UcAlertService, $timeout, $rootScope, AttendanceConfirmation, $scope, $stateParams)
    {
        var vm = this;
        
        vm.error = false;
        vm.showPanel = false;
        vm.feedback = null;
        
        
        /*
         * Se a url não tiver o parâmetro para identificar o usuário, é sinalizado na tela e o fluxo é interrompido
         */
        if(confirmationData.noParam)
        {
        	sendError($translate.instant('client.page.attendanceConfirmation.noParamMsg'));
        }
        else
        {
        	// $timeout no init p; dar tempo de criar o método
        	$timeout(function(){
        		init();
        	}, 1);
        }
        
        /*public*/ vm.confirm = function()
        {
        	doOperation(true);
        };
        
        /*public*/ vm.decline = function()
        {
        	doOperation(false);
        };
        
        /*private*/ function init()
        {
        	// Parâmetro válido?
        	if(confirmationData.invalidParam)
        	{
        		sendError($translate.instant('client.page.attendanceConfirmation.invalidParamMsg'));
        	}
        	else
        	{
        		if(confirmationData.feedback)
        		{
        			// Exibir feedback?
        			vm.feedback = $sce.trustAsHtml(confirmationData.feedback);
        		}
        		else
        		{
        			setPanel();
        		}
        	}
        };
        
        /*private*/ function setPanel()
        {
	        var temp = $translate.instant("client.page.attendanceConfirmation.mainText", {name: "$name$"});
	        temp = temp.replace("$name$", "<span>" + confirmationData.name + "</span>");
	        vm.mainText = $sce.trustAsHtml(temp);
	        
	        vm.itemList = confirmationData.itemList;
	        vm.showPanel = true;
        }
        
        /*private*/ function sendError(p_msg)
        {
	        vm.error = true;
	        
	        // Para dar tempo de criar o box
	        $timeout(function()
	        {
	        	UcAlertService.message(p_msg, "ERROR", "BOX");
	        }, 250);
        };
        
        /*private*/ function doOperation(p_confirmation)
        {
        	var operation = (p_confirmation) ? $translate.instant("client.page.attendanceConfirmation.attendanceConfirm") : $translate.instant("client.page.attendanceConfirmation.attendanceDecline");
        	var message = $translate.instant("client.global.messages.info.confirmOperationP0", {operation: "[" + operation + "]"});
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					AttendanceConfirmation.execute({uniqueId: $stateParams.uniqueId, operation: p_confirmation}, function()
					{
						var msg = $translate.instant((p_confirmation) ? "client.page.attendanceConfirmation.attendanceConfirmatedSuccessfully" : "client.page.attendanceConfirmation.attendanceDeclinedSuccessfully");
						vm.feedback = $sce.trustAsHtml(msg);
						try
						{
//							$scope.$apply();
						}
						catch(eee)
						{
							console.error(eee.message);
						}
					});
				} 
			});

        }

	}
})();
