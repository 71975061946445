(function()
{
    'use strict';
    angular
        .module('sgmApp')
        .constant('FormConstants',
   		{
        	AddFieldListeners:
        	{
        		/***
        		 * Só adiciona o form-field se o domain tiver id  
        		 */
        		IdNotNull: function(p_domain)
        		{
        			return (p_domain && p_domain.id);
        		}
        	},
//        	CssClass:
//        	{
//        		DescriptionSelect: "description-select"
//        	},
        	DataSources:
        	{
        		BackEnd:
        		{
        			Assistent: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("Assistent", {});
        			},
        			
        			City: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("City", {
	        				minCharToSearch: 3,
						});
        			},
        			Procedure: function(BackEndSelectDataSource, $translate, p_dependsOn, p_fetchAllForEmptyDependent, p_searchActiveOnly)
        			{
	        			return BackEndSelectDataSource.create("Procedure",
						{
//							isDescriptionSelect: true,
							dependsOn: p_dependsOn,
							fetchAllForEmptyDependent: p_fetchAllForEmptyDependent,
							searchActiveOnly: p_searchActiveOnly
							/*parseSelectOptionText: function(p_opt, p_search, p_highlightFilter)
							{
								var family = (p_opt.familyProcedure) ? p_opt.familyProcedure.defaultDescription : "-";
								
								var text = p_highlightFilter(p_opt.description, p_search) + "<div class='sub-title-select'>" + $translate.instant('client.global.label.procedureFamily') + ": " + family + '</div>';
								return text;
							}*/
						});
        			},
        			FamilyProcedure: function(BackEndSelectDataSource, $translate, criteria)
        			{
						console.log(criteria);
        				return BackEndSelectDataSource.create("FamilyProcedure", {
							criteria: criteria
        				});
        			},
        			Profession: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("Profession", {
						});
        			},
        			ExpenseType: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("ExpenseType", {
						});
					},
					ExpenseCostCenter: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("ExpenseCostCenter", {
						});
					},
					Supplier: function(BackEndSelectDataSource, $translate, criteria)
					{
						return BackEndSelectDataSource.create("Supplier", {
							criteria: criteria
						});
					},
					SupplierCategory: function(BackEndSelectDataSource, $translate)
					{
						return BackEndSelectDataSource.create("SupplierCategory", {
						});
					},
					SupplierSubcategory: function(BackEndSelectDataSource, $translate, p_dependsOn, p_searchActiveOnly)
					{
						return BackEndSelectDataSource.create("SupplierSubcategory", {
							dependsOn: p_dependsOn,
							searchActiveOnly: p_searchActiveOnly
						});
					},
					ExpensePaymentOption: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("ExpensePaymentOption", {
						});
					},
					User: function(BackEndSelectDataSource, $translate)
        			{
	        			return BackEndSelectDataSource.create("User", {
						});
        			},
        			Equipment: function(BackEndSelectDataSource, $translate)
        			{
        				return BackEndSelectDataSource.create("Equipment", {
        				});
        			},
        			ProcedurePlace: function(BackEndSelectDataSource, $translate)
        			{
        				return BackEndSelectDataSource.create("ProcedurePlace", {
        				});
        			},
        			Professional: function(BackEndSelectDataSource, $translate)
        			{
        				var specialtiesLabel = $translate.instant('client.global.label.specialties');
	        			return BackEndSelectDataSource.create("Professional",
						{
//							isDescriptionSelect: true,
							parseSelectOptionText: function(p_opt, p_search, p_highlightFilter)
							{
								var specialties = "";
								if(!p_opt.specialtyList || p_opt.specialtyList.length == 0)
								{
									specialties = "-";
								}
								else
								{
									for (var i = 0; i < p_opt.specialtyList.length; i++)
									{
										if(i > 0)
										{
											specialties += ", ";
										}
										specialties += p_opt.specialtyList[i].defaultDescription;
									}
								}
								return p_highlightFilter(p_opt.defaultDescription, p_search) + "<div class='sub-title-select'>" + specialtiesLabel + ": " + specialties + '</div>';
							}
						});
        			},
        			IndicationsManager: function(BackEndSelectDataSource, $translate)
        			{
        				return BackEndSelectDataSource.create("IndicationsManager", {
        				});
        			},
					Manufacturer: function(BackEndSelectDataSource, $translate)
					{
						return BackEndSelectDataSource.create("Manufacturer", {
						});
					},
					MeasureUnit: function(BackEndSelectDataSource, $translate)
					{
						return BackEndSelectDataSource.create("MeasureUnit", {
						});
					},
					Product: function(BackEndSelectDataSource, $translate, criteria)
					{
						if(criteria == null) {
							criteria = {
								searchActiveOnly: true
							}
						}
						return BackEndSelectDataSource.create("Product", {
							criteria: criteria,
						});
					},
					ProductKit: function(BackEndSelectDataSource, $translate, criteria)
					{
						if(criteria == null) {
							criteria = {
								searchActiveOnly: true
							}
						}
						return BackEndSelectDataSource.create("ProductKit", {
							criteria: criteria,
						});
					},
					ManufacturerProduct: function(BackEndSelectDataSource, $translate, 
						searchKey,
						transformOptionsList,
						criteria, 
						dependsOn,
						parseSelectOptionText,
						parseSelectedOption)
					{
						if(criteria == null) {
							criteria = {
								searchActiveOnly: true
							}
						}
						return BackEndSelectDataSource.create("ManufacturerProduct", {
							searchKey: searchKey,
							transformOptionsList: transformOptionsList,
							criteria: criteria,
							dependsOn: dependsOn,
							parseSelectOptionText: parseSelectOptionText,
							parseSelectedOption: parseSelectedOption
						});
					},
					ManufacturerProductStockFlow: function(BackEndSelectDataSource, $translate)
					{
						return BackEndSelectDataSource.create("ManufacturerProductStockFlow", {
						
						});
					},
					StockFlowMotive: function(BackEndSelectDataSource, $translate)
					{
						return BackEndSelectDataSource.create("StockFlowMotive", {
						
						});
					},
					Attendance: function(BackEndSelectDataSource, $translate)
					{
						return BackEndSelectDataSource.create("Attendance", {
						
						});
					},
					ManufacturerProductBatch: function(BackEndSelectDataSource, 
						dependsOn,
						transformOptionsList,
						criteria,
						searchKey
						)
					{
						return BackEndSelectDataSource.create("ManufacturerProductBatch", {
							dependsOn: dependsOn,
							transformOptionsList: transformOptionsList,
							criteria: criteria,
							searchKey: searchKey,
						});
					},
					StockPlace: function(BackEndSelectDataSource, $translate,
						dependsOn,
						transformOptionsList,
						criteria
						)
					{
						return BackEndSelectDataSource.create("StockPlace", {
							dependsOn: dependsOn,
							transformOptionsList: transformOptionsList,
							criteria: criteria,
						});
					},
					ProntuaryModel: function(BackEndSelectDataSource, $translate, isFetchComplete)
					{
						return BackEndSelectDataSource.create("ProntuaryModel", {
							criteria: {
								isFetchComplete: isFetchComplete
							}
						});
					},
        		},
        		FrontEnd:
        		{
        			Boolean: function($translate, p_firstEmpty) 
        			{
        				var arr = [];
        				if(p_firstEmpty)
        				{
        					arr.push({id: null, defaultDescription: ""});
        				}
        				arr.push({id: true, defaultDescription: $translate.instant("client.global.label.yes")});
        				arr.push({id: false, defaultDescription: $translate.instant("client.global.label.no")});
        				return arr;
        			}
        		}
        	},
        	Events:
        	{
        		Runtime:
        		{
        			ReadOnly: function(p_fieldName)
        			{
        				return "Events.Runtime.ReadOnly." + p_fieldName;
        			},
        			ShowTabError: function()
        			{
        				return "Events.Runtime.ShowTabError";
        			}
        		}
        	},
        	ValTransforms:
        	{
        		/**
        		 * Retorna o valor boolean na mesma variável
        		 */
        		BOOLEAN: function(p_json, p_formField, p_value)
        		{
        			var val = (p_value && !isNull(p_value.id)) ? p_value.id : null;
        			p_json[p_formField.id] = val;
        		},

        		/**
        		 * O retorno é sempre no mesmo formarto
        		 */
        		DATE_TIME: function(p_json, p_formField, p_value)
        		{
        			// Data inválida, retorna nulo
        			if(!isValidDate(p_value))
        			{
        				p_json[p_formField.id] = null;
        			}
        			else
        			{
        				// O formato DEVE ser: [yyyy-MM-dd HH:mm:ss]
        				var dateStr = completeLeftWithZero(p_value.getFullYear(), 4) + "-" + 
        					completeLeftWithZero(p_value.getMonth() + 1, 2) + "-" +
        					completeLeftWithZero(p_value.getDate(), 2) + " " +
        					completeLeftWithZero(p_value.getHours(), 2) + ":" +
        					completeLeftWithZero(p_value.getMinutes(), 2) + ":" +
        					completeLeftWithZero(p_value.getSeconds(), 2);
        				p_json[p_formField.id] = dateStr;
        			}
        		},
        	
        		/**
        		 * Armazena o id do objeto no select no json com o nome <id-do-form-field> + "Id" 
        		 */
        		DEFAULT_SELECT_VAL_TRANSFORM: function(p_json, p_formField, p_value)
        		{
        			var val = (p_value && !isNull(p_value.id)) ? p_value.id : null;
        			p_json[p_formField.id + "Id"] = val;
        		},
        		
        		/**
        		 * Análogo ao DEFAULT_SELECT_VAL_TRANSFORM, só que usa uma lista
        		 */
        		DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM: function(p_json, p_formField, p_value, keepObjectInJson)
        		{
        			var ret = "";
        			if(p_value)
        			{
        				angular.forEach(p_value, function(p_localValue, p_key)
        				{
        					if(p_localValue && !isNull(p_localValue.id))
        					{
        						if(ret != "")
        						{
        							ret += ";";
        						}
        						ret += p_localValue.id;
        					}
        				});
        			}
					if(!keepObjectInJson) {
						p_json[p_formField.id] = null;
					}
					
        			p_json[p_formField.id + "Id"] = ret;
				},
				
				DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM_LONG_LIST: function(p_json, p_formField, p_value)
        		{
        			var ret = "";
        			if(p_value)
        			{
        				angular.forEach(p_value, function(p_localValue, p_key)
        				{
        					if(p_localValue && !isNull(p_localValue))
        					{
        						if(ret != "")
        						{
        							ret += ";";
        						}
        						ret += p_localValue;
        					}
        				});
        			}
        			p_json[p_formField.id + "Id"] = ret;
        		},

				/**
        		 * DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM_ARRAY: retorna array de ids
        		 */
				 DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM_ARRAY: function(p_json, p_formField, p_value, p_suffix)
				 {
					let ret = [];
					 if(p_suffix === null || p_suffix === undefined) {
						 p_suffix = "Id";
					 }
					 if(p_value)
					 {
						 
						 angular.forEach(p_value, function(p_localValue, p_key)
						 {
							 if(p_localValue && !isNull(p_localValue.id))
							 {
								ret.push(p_localValue.id);
							 }
						 });
					 }
					 p_json[p_formField.id + p_suffix] = ret;
				 },
        	}
        });
})();
