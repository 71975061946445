(function()
{
    'use strict';
    angular
        .module('sgmApp')
        .constant('GridConstants',
   		{
        	// $broadcast e $emit
        	Events:
        	{
        		AfterFetchOnServer: "Events-AfterFetchOnServer",
        		OnGridSelectionDone: "On-Grid-Selection-Done",
        		OnGridReady: "On-Grid-Ready",
        		RefreshGrid: "Events-RefreshGrid"
        	},

        	Height:
        	{
        		/**
        		 * Valor a ser descontado do novo tamanho do grid para garantir que não dará scroll desnecessariamente 
        		 */
        		AdjustGridHeightGap: 15,
        		
        		DefaultGridHeight: 250,
        		FooterHeight: 25
        	},
        	
        	State:
        	{
        		Complete: "COMPLETE",
        		Loading: "LOADING",
        	}
        });
})();
