(function() {
    'use strict';

	angular.module('sgmApp').service('SalesRequestEditModalInvoker', SalesRequestEditModalInvoker);
	
	SalesRequestEditModalInvoker.$inject = ['$uibModal'];
	
	function SalesRequestEditModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/attendance/sales-request/sales-request-edit-modal-dialog.html',
                controller: 'SalesRequestEditModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT",
    	                	formValidateTarget: 'SalesRequestEdit'
                    	})
                    }],
                    familyProcedureList: ['FamilyProcedure', function(FamilyProcedure)
                    {
                    	return FamilyProcedure.listAllIncludeInactive().$promise;
                    }],
                    attendanceOriginal: ['Attendance', function(Attendance)
                    {
                    	return Attendance.get({id: p_param.attendanceId}).$promise;
                    }],
                    salesRequestOriginal: ['SalesRequest', function(SalesRequest)
                    {
                    	return SalesRequest.getFull({id: p_param.salesRequestId}).$promise;
                    }],
                    anesthetistList: ['Attendance', function(Attendance)
                    {
                        return Attendance.listAnesthetistSelectOptions().$promise;
                    }],
                    account: ['Principal', function(Principal)
                    {
                        return Principal.identity();
                    }]
                }
            }).result.then(function()
            {
            	if(p_param.callback)
            	{
            		p_param.callback();
            	}
            }, function()
            {
            	if(p_param.cancelCallback)
            	{
            		p_param.cancelCallback();
            	}
            });
		};
		
	};

})();
