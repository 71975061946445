(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('PasswordResetFinish', PasswordResetFinish);

    PasswordResetFinish.$inject = ['$resource', 'URL', 'SERVICES'];

    function PasswordResetFinish($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;

        var service = $resource(url + 'api/account/reset_password/finish', {}, {});

        return service;
    }
})();
