/**
 * Code get from http://stackoverflow.com/questions/17417607/angular-ng-bind-html-and-directive-within-it
 */

(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('htmlCompile', htmlCompileDirective);
    
    htmlCompileDirective.$inject = ['$compile'];
    function htmlCompileDirective($compile)
    {
    	return function(scope, element, attrs)
    	{
            var ensureCompileRunsOnce = scope.$watch(
            	function(scope)
            	{
            		// watch the 'compile' expression for changes
            		return scope.$eval(attrs.htmlCompile);
            	},
            	function(value)
            	{
            		// when the 'compile' expression changes
            		// assign it into the current DOM
            		element.html(value);
            		
            		// compile the new DOM and link it to the current
            		// scope.
            		// NOTE: we only compile .childNodes so that
            		// we don't get into infinite loop compiling ourselves
            		$compile(element.contents())(scope);
            		
            		// Use un-watch feature to ensure compilation happens only once.
            		ensureCompileRunsOnce();
            	}
            );
        };
    };
})();