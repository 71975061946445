(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientManagerTabNotesController', ClientManagerTabNotesController);
    
    ClientManagerTabNotesController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'TextDialogInvoker', 'Client'];
    function ClientManagerTabNotesController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, TextDialogInvoker, Client)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        formHandler.reopenConfig();
        
        var textAreaClass = "medium-textarea";
        
        if(injectedResources && injectedResources.medicNoteInfo && injectedResources.medicNoteInfo.show)
        {
        	textAreaClass = "small-textarea";
        	entity["medicObs"] = injectedResources.medicNoteInfo.text;
        	
            formHandler.add([new FormField({
				formTarget: "notes",
           		cellCssClass: "col-sm-12",
				type: "TextArea",
				id: "medicObs",
				label: "client.page.attendance.tabs.notes.medicObs",
				extraCssClass: textAreaClass
    		})]);
        }
        
        formHandler.add([new FormField({
					formTarget: "notes",
	           		cellCssClass: "col-sm-12",
					type: "TextArea",
					id: "clientObs",
					readOnly: true,
					label: "common.domain.client.clientObs",
					extraCssClass: textAreaClass
        		})
        	])
		.endConfig();

        vm.addClientNote = function() {
			TextDialogInvoker.invoke(
				{
					title: $translate.instant("client.page.attendance.tabs.notes.addNote"),
					pageConfig: {
						pageMode: 'CREATE'
					},
					callback: function(text) {
						createClientNote(text);
					}
				}
			);
		}

		function createClientNote(text) {
			Client.createClientNote({clientId: entity.id, content: text}, function(res) {
				entity['clientObs'] = res.value;
			})
		}
        /**
         * Sincroniza a "bolinha" para só ser exibida quando houver contéudo na aba
         */
        //@Override
        /*public*/ vm.refreshSignal = function()
        {
        	$timeout(function()
        	{
        		// ALgum campo preenchido?
        		if(entity && (entity.clientObs || entity.medicObs))
        		{
        			$("#notesSignal").removeClass("display-none");
        		}
        		else
        		{
        			$("#notesSignal").addClass("display-none");
        		}
        	}, 500); // 500 milis de delay
        };

    };
})();