(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('hintAttendanceConfirmation', hintAttendanceConfirmationDirective);
    
    hintAttendanceConfirmationDirective.$inject = [];
    function hintAttendanceConfirmationDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "HintAttendanceConfirmationDirectiveController",
            templateUrl: "app/pages/managers/attendance/confirmation/hint-attendance-confirmation.template.html",
            scope:
            {
            	entity: '='
            }
    	}
    };
})();
