(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ClientAttendanceListController', ClientAttendanceListController);

	/**
	 *	clientSumaryId vem da functions resolve da chamada do modal $uibModal
	 */
	ClientAttendanceListController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'UcGridService', '$uibModalInstance', 'entidade'];
	function ClientAttendanceListController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, UcGridService, $uibModalInstance, entidade) {
		var vm = $scope.vm;
		var entity = $scope.vm.domain;
		var formHandler = vm.formHandler;

		var injectedResources = vm.injectedResources;

		if (entidade && entidade.id) {
			var sort = {
				"name": "plannedDate",
				"type": "desc"
			};

			var gridFieldList = UcGridService.createFieldList([
				{ headerName: $translate.instant("client.page.attendance.tabs.summary.plannedDate"), field: "plannedDate", cellStyle: { alignItems: "center", textAlign: "center" }, width: 15 },
				{ headerName: $translate.instant("client.page.attendance.tabs.summary.procedure"), field: "procedureDescription", width: 31 },
				{ headerName: $translate.instant("client.page.attendance.tabs.summary.status"), field: "status", width: 12, cellStyle: { alignItems: "center", textAlign: "center" }, cellRenderer: PillRenderer.renderer },
				{ headerName: $translate.instant("client.page.attendance.tabs.summary.finalValue"), field: "finalValue", width: 12, cellStyle: { alignItems: "center", textAlign: "center" } }
			]);


			var grid = UcGridService.create(
				{
					gridId: "CLIENT_ATTENDANCE_HISTORY",
					getFilterData: function() { return { clientId: entidade.id,sort:"plannedDate,asc" }; },
					gridFieldList: gridFieldList,
					sort: sort
				});

			vm.summaryGridOptions = grid.getGridOptions();
		}

		function onSummarySelect() {
			if (entidade && entidade.id) {
				$timeout(function() {
					grid.refresh();
				}, 1);
			}
		};

		// Para o load na aba
		onSummarySelect();

		$scope.$on('client-manager.refreshTab.summary', function() {
			onSummarySelect();
		});

		vm.closeModal = function() {
			$uibModalInstance.dismiss();
		}

	};
})();