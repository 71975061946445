(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierManagerTabContactController', SupplierManagerTabContactController);
    
    SupplierManagerTabContactController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'FormDomain','SupplierContactDialogInvoker' ];
    function SupplierManagerTabContactController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, FormDomain, SupplierContactDialogInvoker)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        
        function callBackAddContact(contact){
        	if(contact){
        		vm.contactList.push(contact);
        	}
        }
        
        vm.adicionarContact = function(){
        	SupplierContactDialogInvoker.invoke({callback: callBackAddContact});
        }
        
        vm.removeContact = function(index){
        	if(vm.contactList[index].id){
        		vm.contactIdListToRemove.push(vm.contactList[index].id);
        	}
        	vm.contactList.splice(index, 1)
        }
        
    };
})();