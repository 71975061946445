(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('FormDynamicDirectiveController', FormDynamicDirectiveController);
        
    FormDynamicDirectiveController.$inject = ['$scope', '$translate'];
    function FormDynamicDirectiveController($scope, $translate)
    {
    	var vm = this;
    	
    	vm.formHandler = $scope.formHandler;
    	vm.pageConfig = $scope.pageConfig;
    	vm.buttonsModel = $scope.buttonsModel;
    	vm.suppressButtons = $scope.suppressButtons;
    	
    	var formId = $scope.formId;
    	
    	/*public*/ vm.hasButtons = function()
    	{
    		return (vm.buttonsModel);
    	};
    	
    	/**
    	 * Função para decidir se o componente deve ou não ser adicionado ao DOM (ng-if)
    	 */
    	/*public*/ vm.addField = function(p_fieldModel)
    	{
    		// Se tiver o campo FormField.formTarget estiver setado, so adiciona se o formulário tiver o formId setado é igual
    		if(p_fieldModel.formTarget && !checkFormTarget(p_fieldModel.formTarget))
			{
    			return false;
			}
    	
    		// Verifica se tem um listenter definido
    		if(p_fieldModel.addFieldListener)
    		{
    			// Ele que decidi
    			return p_fieldModel.addFieldListener(vm.formHandler.getModel()); 
    		}
    		else // default é adicionar
    		{
    			return true;
    		}
    	};
    	
    	/**
    	 * Só adiciona se o formulário tiver o formId setado é igual
    	 */
    	/*private*/ function checkFormTarget(p_formTarget)
    	{
    		return (formId && formId == p_formTarget);
    	}

    };
})();