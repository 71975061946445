(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('SalesRequestInvoiceCancelDialogInvoker', SalesRequestInvoiceCancelDialogInvoker);

    SalesRequestInvoiceCancelDialogInvoker.$inject = ['$uibModal'];

    function SalesRequestInvoiceCancelDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/entities/sales-request-invoice/sales-request-invoice-cancel-dialog/sales-request-invoice-cancel-dialog.html',
				controller: 'SalesRequestInvoiceCancelDialogController',
				controllerAs: 'vm',
				size: 'lg',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
					listCancelCodes: ['SalesRequestInvoice', function(SalesRequestInvoice)
					{
						return SalesRequestInvoice.listCancelCodes();
					}],
					entity: p_param.entity,
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();