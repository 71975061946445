
(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AgendaLockingDialogController', AgendaLockingDialogController);

    AgendaLockingDialogController.$inject = ['$scope', '$filter', '$uibModalInstance', 'FormDomain', 'FormConstants', 'entity', '$timeout', 'schedulableList', 'pageConfig', '$state', 'FrontEndSelectDataSource', 'AgendaLockingTime', 'UcAlertService', 'Agenda', '$translate', '$rootScope', 'Principal', 'PermissionSet', 'repeatOptionsList'];

    function AgendaLockingDialogController($scope, $filter, $uibModalInstance, FormDomain, FormConstants, entity, $timeout, schedulableList, pageConfig, $state, FrontEndSelectDataSource, AgendaLockingTime, UcAlertService, Agenda, $translate, $rootScope, Principal, PermissionSet, repeatOptionsList)
    {
        var vm = this;
        
        var hasPermission = Principal.hasAnyAuthority([PermissionSet.AGENDA.locking]);
		var LIST_REPEAT_VALUE = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30];
    	var listRepeatValue = [];
    	for (var i = 0; i < LIST_REPEAT_VALUE.length; i++)
    	{
    		listRepeatValue.push({id: LIST_REPEAT_VALUE[i], defaultDescription: "" + LIST_REPEAT_VALUE[i]});
		}
        vm.domain = entity;
        vm.pageConfig = pageConfig;

        // Se for edição, ajusta os campos
        if(entity.id)
        {
    		entity.start = (entity.startDateNoTimezone) ? moment(entity.startDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d : null;
    		entity.end = (entity.endDateNoTimezone) ? moment(entity.endDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d : null;
			entity.schedulable = {};
    		entity.schedulable[entity.idSchedulable+''] = {
				id: entity.idSchedulable,
				defaultDescription: entity.name
    		}
			if(entity.repetitionNumber)
    		{
    			entity.repetitionNumberSelect = {id: entity.repetitionNumber, defaultDescription: "" + entity.repetitionNumber}
    		}
			console.log(entity);
        }
        
        vm.buttonsModel = {
        	isSaving: false,
        	onClear: function() { vm.formHandler.clear(); },
        	extraButtonList: [{
	        	order: 90,
	        	cssClass: 'btn btn-success',
	        	cssClassIcon: 'glyphicon glyphicon-save',
	        	label: 'client.global.label.save',
	        	type: 'submit',
	        	isToAdd: function() { return true; }
	        },
	        {
	        	order: 91,
	        	cssClass: 'btn btn-danger',
	        	cssClassIcon: 'fa fa-times',
	        	label: 'client.global.label.delete',
	        	type: 'button',
	        	isToAdd: function() { return (entity.id); },
	        	isDisabled: function()
	        	{
	        		return !hasPermission;
	        	},
	        	click: function()
	        	{
	        		deleteLocking();
	        	}
	        },
	        {
	        	order: 92,
	        	cssClass: 'btn btn-default',
	        	cssClassIcon: 'fa fa-ban',
	        	label: 'client.global.label.cancel',
	        	type: 'button',
	        	isToAdd: function() { return true; },
	        	click: function()
	        	{
	        		vm.closeModal();
	        	}
	        }]
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	historyConfig: {historyId: "AGENDA_LOCKING_TIME"},
        	getForm: function(){ return $scope.editForm; },
			beforeInvokeService: function(p_json)
        	{
        		adjustJSonBeforeSend(p_json);
        	},
        	onSaveSuccess: function(p_result, p_headers)
        	{
        		$uibModalInstance.close({
        			success: true
        		});
        	}
        };

		var repetitionTypeDataSource = FrontEndSelectDataSource.create(repeatOptionsList);
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "Select",
					id: "schedulable",
					required: true,
					multiple: true,
					readOnly: !!(entity.id), // Edição é readonly
					label: "client.page.attendance.occupyBlockSchedule",
					dataSource: FrontEndSelectDataSource.create(schedulableList),
					jsonValTransform: (p_json, p_formField, p_value) => FormConstants.ValTransforms.DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM(p_json, p_formField, p_value, true)
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "String",
					id: "description",
					required: true,
					label: "client.page.attendance.blockSchededuleLabel",
					maxLength: 1000
				})
        	])
			.add([new FormField({
					cellCssClass: "col-sm-12",
		       		type: "DateTime",
					id: "start",
					required: true,
					label: "client.page.agendaLockingDialog.start",
					onChange: function() {
						entity.repetitionType = null;
						synchItemList();
					}
				})
        	])
			.add([new FormField({
					cellCssClass: "col-sm-12",
		       		type: "DateTime",
					id: "end",
					required: true,
					label: "client.page.agendaLockingDialog.end",
					addFieldListener: function()
					{
						syncStartTimeInput();
						return !entity.fullDay;
					},
					onChange: function() {
						entity.repetitionType = null;
						synchItemList();
					}
				})
        	])
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "fullDay",
					label: "client.page.agendaLockingDialog.fullDay",
					onChange: function() {
						entity.repetitionType = null;
						synchItemList();
					}
				})
			])
			.add([new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "repetitionType",
					required: true,
					label: "client.global.label.repeat",
					dataSource: repetitionTypeDataSource,
					onChange: function()
					{
						synchItemList();
					}
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "repetitionNumberSelect",
					label: "client.page.agendaReminderDialog.timesRepeat",
					dataSource: FrontEndSelectDataSource.create(listRepeatValue),
					onChange: function()
					{
						synchItemList();
					},
					addFieldListener: function()
		        	{
		        		return (entity.repetitionType != null && entity.repetitionType.id != "U");
		        	},
				})
			])
        	.add([new FormHtmlRender({
	    			cellCssClass: "col-sm-12",
	    			label: "client.page.agendaReminderDialog.preview",
	    			hasLabel: true,
					render: function(p_objModel, p_fieldModel, p_pageMode, p_editMode)
					{
						return "<div id='ard-preview'></div>";
					}
				})
        	])
			.endConfig();

        /*private*/ function synchItemList()
        {
			setRepetitionTypeSelect();
        	$timeout(function()
        	{
        		renderPreview();
        	}, 100);
        }

		function setRepetitionTypeSelect() {
			var repetitionList = repeatOptionsList;
			if(entity.start && (entity.fullDay || entity.end)) {
				var start = moment(entity.start);
				var end = entity.fullDay ? start.endOf('day') : moment(entity.end);
				if(entity.fullDay) {
					start = start.startOf('day');
				}
				var diff = end.diff(start, 'days');
				console.log(diff);
				if(diff == 0) {
					repetitionList = repeatOptionsList;
				} else if (diff > 0 && diff < 7) {
					console.log(repeatOptionsList);
					repetitionList = repeatOptionsList.filter(obj => obj.id == "W" || obj.id == "M" || obj.id == "U");
					console.log(repetitionList)
				} else if (diff >= 7 && diff < 28) {
					repetitionList = repeatOptionsList.filter(obj => obj.id == "M" || obj.id == "U");
				} else if (diff >= 28) {
					repetitionList = repeatOptionsList.filter(obj => obj.id == "U");
				} else {
					repetitionList = [];
				}
				repetitionTypeDataSource.setOptionsList(repetitionList);
			} else {
				repetitionTypeDataSource.setOptionsList([]);
			}
			
		}

        synchItemList();

        /*private*/ function syncStartTimeInput()
        {
       		$("#start_time").attr("disabled", entity.fullDay);
       		$("#start_time").attr("readOnly", entity.fullDay);
       		if(entity.fullDay)
       		{
       			entity["start_time"] = "00:00";
       		}
        }
        syncStartTimeInput();
        
		function schedulableEntriesRepeat(entries, index, jsonList, ok, p_json, message, p_onSaveSuccess, p_onSaveError) {
			if(index < entries.length) {
				let schedulableEntry = entries[index];
				let schedulable = schedulableEntry[1];
				let json = {};
				Object.assign(json, p_json);
				json.idSchedulable = schedulable.id;
				json.schedulableId = schedulable.id;
				json.schedulable = schedulable;
				jsonList.push(json);
				Agenda.checkScheduling(
					{
						id: json.id,   // Usar do entity, não do json
						schedulingType: "B",
						procedurePlaceId: null,
						schedulableId: json.idSchedulable,
						startDate: json.start,
						endDate: json.end,
						fullDay: json.fullDay
					}
				).$promise.then(function(p_result) {
					ok = ok && p_result.ok;
					if(!p_result.ok) {
						message += $translate.instant("client.page.attendance.currentMarkingIsInConflictWithEventsBelowName", {name: schedulable.name});
						message += "<ul>";
						for (var i = 0; i < p_result.conflictList.length; i++)
						{
							message += "<li>" + p_result.conflictList[i] + "</li>";
						}
						message += "</ul>"
					}
					index += 1;
					schedulableEntriesRepeat(entries, index, jsonList, ok, p_json, message, p_onSaveSuccess, p_onSaveError);
				});
			} else {
				if(ok) {
					customSave(jsonList, p_onSaveSuccess, p_onSaveError);
				}
				else {
					message += $translate.instant("client.global.messages.info.confirmOperation")
					$rootScope.confirm(
					{
						message: message,
						callback: function()
						{
							customSave(jsonList, p_onSaveSuccess, p_onSaveError);
						} 
					});
				}
			}
		}
		/*private*/ function renderPreview()
        {
        	var html = "";
        	if(entity.start && (entity.end || entity.fullDay) && entity.repetitionType)
        	{
        		var repeatType = entity.repetitionType.id;
        		var repetitionNumber = (entity.repetitionNumberSelect && entity.repetitionNumberSelect.id) ? entity.repetitionNumberSelect.id : 0;
        		if(repetitionNumber > 0)
        		{
        			repetitionNumber--;
        		}
        		
				if(entity.fullDay) {
					entity.end = moment(entity.start).endOf('day').toDate();
				}
        		var start = new Date(entity.start.getTime());
				var end = new Date(entity.end.getTime());
        		html += generatePreviewDiv(start, end);
        		
        		if(repeatType != "U" && repetitionNumber > 0)
        		{
        			for (var i = 0; i < repetitionNumber; i++)
        			{
        				if(repeatType == "D")
        				{
        					start.setDate(start.getDate() + 1);
							end.setDate(end.getDate() + 1);
        				}
        				else if(repeatType == "W")
        				{
        					start.setDate(start.getDate() + 7);
							end.setDate(end.getDate() + 7);
        				}
        				else if(repeatType == "M")
        				{
        					start.setMonth(start.getMonth() + 1);
							end.setMonth(end.getMonth() + 1);
        				}
        				html += generatePreviewDiv(new Date(start.getTime()), new Date(end.getTime()));
					}
        		}
        	}
        	if(!html)
        	{
        		html = "<span style='font-style:italic'>" + $translate.instant('client.page.agendaReminderDialog.setFormAbove') + "</span>";
        	}
			console.log(html);
        	$("#ard-preview").html(html);
        }
        
        /*private*/ function generatePreviewDiv(p_date, p_end)
        {
        	var mnt_start = moment(p_date);
			var mnt_end = moment(p_end);
        	var weekIndex = mnt_start.weekday() + 1;
        	if(weekIndex == 7)
        	{
        		weekIndex = 0;
        	}
        	return "<div class='date-preview'>" + mnt_start.format('DD/MM/YY HH:mm') + " - "+ mnt_end.format('DD/MM/YY HH:mm') + ")</div>";
        }
        
        /*private*/ function adjustJSonBeforeSend(p_json)
        {
        	
        	if(p_json.repetitionTypeId == "U")
        	{
        		p_json.repetitionNumber = 0;
        	}
        	else
        	{
        		p_json.repetitionNumber = (entity.repetitionNumberSelect && entity.repetitionNumberSelect.id) ? entity.repetitionNumberSelect.id : 0;
        	}
			console.log(p_json);
        }

        /*public*/ vm.save = function()
        {
        	UcAlertService.dismissBox();
        	var message = '';
        	// Save customizado
			var ok = true;
			var repeatType = (entity.repetitionType) ? entity.repetitionType.id : null;
       		var repetitionNumber = (entity.repetitionNumberSelect && entity.repetitionNumberSelect.id) ? entity.repetitionNumberSelect.id : 0;
       		
       		if(repeatType && repeatType != "U" && repetitionNumber == 0)
       		{
       			UcAlertService.error($translate.instant("client.page.agendaReminderDialog.repetitionNumberRequiredAndDifferentOfZeroForThisRepetitionType"));
       		}
       		else
       		{
				vm.formHandler.save(function(p_json, p_onSaveSuccess, p_onSaveError)
				{
					let schedulableEntries = Object.entries(p_json.schedulable);
					adjustJSonBeforeSend(p_json);
					p_json.id = entity.id;
					let jsonList = [];
					schedulableEntriesRepeat(schedulableEntries, 0, jsonList, true, p_json, message, p_onSaveSuccess, p_onSaveError);
				});
			}
        }
        
        /*private*/ function customSave(p_jsonList, p_onSaveSuccess, p_onSaveError)
        {
			console.log(p_jsonList);
        	AgendaLockingTime.saveLocking({
				"agendaTimeList": p_jsonList
			}, p_onSaveSuccess, p_onSaveError);
        };
        
        /*private*/ function deleteLocking()
        {
        	var message = $translate.instant("client.global.messages.info.deleteRecordP0", {item: ":p0"}) + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
        	
			// Atribui o valor por replace porque o parametro do $translate está escaping
			message = message.replace(":p0", UcUtil.b(entity.description));
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					AgendaLockingTime.delete({id: entity.id}, 
						function()
						{
			        		$uibModalInstance.close({
			        			success: true
			        		});
						});
				} 
			});
		}
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss('cancel');
        }
    }
})();