(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('StaticTooltipBoxDirectiveController', StaticTooltipBoxDirectiveController);

    StaticTooltipBoxDirectiveController.$inject = ['$scope', '$rootScope', 'StaticTooltipBoxHandler'];
    function StaticTooltipBoxDirectiveController($scope, $rootScope, StaticTooltipBoxHandler)
    {
    	var vm = this;

    	var defaultConfig = 
    	{
    		id: "staticTooltipBoxId",
    		height: "100px",
    		width: "100px",
    		cssClass: "",
    		content: "-"
    	};
    	
    	vm.config = {};
    	angular.extend(vm.config, defaultConfig);
    	angular.extend(vm.config, $scope.config);
    	
    	vm.cssClass = "static-tooltip-box display-none " + vm.config.cssClass;
    	
    	vm.content = $rootScope.toTrustedHtml(vm.config.content);
    	
    	/*public*/ vm.closeMe = function()
    	{
    		StaticTooltipBoxHandler.hide(vm.config.id);
    	}
    };
})();