(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('procedure', {
            parent: 'entity',
            url: '/procedure?page&sort',
            data: {
                authorities: [PermissionSet.PROCEDURE.access, PermissionSet.PRODUCT.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'ProcedureController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                familyProcedureList: ['FamilyProcedure', function(FamilyProcedure)
                {
                    return FamilyProcedure.listAllIncludeInactive();
                }],
                gridDefaultSort: function()
                {
                	return {
	            		"name": "description", 
	            		"type": "asc"
                	};
            	},
//            	tabList: function()
//                {
//                	return [
//                		{code: "main", icon: "fa fa-info", label: "client.global.label.registrationData"},
//                		{code: "standardValue", icon: "fa fa-usd", label: "client.page.procedure.standardValue"}
//                	];
//                },
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.procedure.title',
	                	icon: $rootScope.menuIcons.cadastre.procedure,
	                	formValidateTarget: 'Procedure',
	                	permission: PermissionSet.PROCEDURE
                	})
                }]
            }
        })
        .state('procedure.detail', {
            parent: 'procedure',
            url: '/{id}/detail',
            views: {
                'content@': {
                    templateUrl: 'app/entities/procedure/procedure-domain.html',
                    controller: 'ProcedureDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
            	defaultDurationList: ['Attendance', function (Attendance){
            			return Attendance.listDefaultDuration().$promise;
            		}	
        		],
                entity: ['$stateParams', 'Procedure', function($stateParams, Procedure)
                {
                    return Procedure.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]            
            }
        })
        .state('procedure.edit', {
            parent: 'procedure',
            url: '/{id}/edit',
            views: {
                'content@': {
                    templateUrl: 'app/entities/procedure/procedure-domain.html',
                    controller: 'ProcedureDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
            	defaultDurationList: ['Attendance', function (Attendance){
	        			return Attendance.listDefaultDuration().$promise;
	        		}	
	    		],
                entity: ['Procedure', '$stateParams', function(Procedure, $stateParams)
                {
                    return Procedure.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('procedure.new', {
            parent: 'procedure',
            url: '/new',
            views: {
                'content@': {
                    templateUrl: 'app/entities/procedure/procedure-domain.html',
                    controller: 'ProcedureDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {}
                },
                defaultDurationList: ['Attendance', function (Attendance){
	        			return Attendance.listDefaultDuration().$promise;
	        		}	
	    		],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }

})();
