(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('PageHeaderDirectiveController', PageHeaderDirectiveController);
        
    PageHeaderDirectiveController.$inject = ['$scope'];
    function PageHeaderDirectiveController($scope)
    {
    	var vm = this;

    	vm.pageConfig = $scope.pageConfig;
    	var pageMode = $scope.pageConfig.pageMode;
    	vm.subTitle = "";
    	
    	
    	// se o subTitle estiver setado, mantém
    	if(vm.pageConfig.subTitle || vm.pageConfig.subTitle === "")
    	{
    		vm.subTitle = vm.pageConfig.subTitle
    	}
    	else
    	{
    		if(pageMode == "FILTER")
    		{
    			vm.subTitle = 'client.global.label.filterPage';
    		}
    		else if(pageMode == "READONLY")
    		{
    			vm.subTitle = 'client.global.label.recordDetails';
    		}
    		else if(pageMode == "CREATE")
    		{
    			vm.subTitle = 'client.global.label.createRecord';
    		}
    		else if(pageMode == "EDIT")
    		{
    			vm.subTitle = 'client.global.label.editRecord';
    		}
    	}
    };
})();