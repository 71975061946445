(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('formHtmlRender', formHtmlRenderDirective);
    
    formHtmlRenderDirective.$inject = [];
    function formHtmlRenderDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "FormHtmlRenderDirectiveController",
            templateUrl: "app/components/uc-form/form-html-render/form-html-render.directive.template.html",
            scope:
            {
            	pageConfig: '=',
            	objModel: '=',
            	fieldModel: '='
            },
    		require: ['^form'],
    		link: function(scope, element, attr, mCtrl)
    		{
    			var FormController = mCtrl[0];
    			scope.form = FormController; 
    		}            
    	}
    };
})();
