(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierCategoryDomainController', SupplierCategoryDomainController);
    
    SupplierCategoryDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'SupplierCategory', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout','supplyTypeList'];

    function SupplierCategoryDomainController(FormDomain, $scope, $state, entity, SupplierCategory, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout, supplyTypeList)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "SUPPLIER_CATEGORY",
        	stateRoot: "supplierCategory",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: SupplierCategory,
        	historyConfig: {historyId: "SUPPLIER_CATEGORY"},
        	getForm: function(){ return $scope.editForm; },
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
	           		cellCssClass: "col-sm-4",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
	    	])        
        	.add([new FormField({
           		cellCssClass: "col-sm-2",
				type: "String",
				id: "code",
				label: "common.domain.supplierCategory.code",
				required: true,
				maxLength: 30
			}),
			new FormField({
				cellCssClass: "col-sm-4",
				type: "Select",
				id: "supplyType",
				required: true,
				label: "common.domain.supplierCategory.supplyType",
				dataSource: FrontEndSelectDataSource.create(supplyTypeList)
			})
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "String",
					id: "name",
					label: "common.domain.supplierCategory.name",
					required: true,
					maxLength: 255
				})
        	])	
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "common.domain.supplierCategory.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();
			
			
//        /*private*/ var editor = null;
//        $timeout(function()
//        {
//        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
//        }, 100);


        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("supplierCategory");
        }
    }
})();
