(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'UcAlertService', 'FormStatePersister', 'VERSION', 'VERSION_DATE', 'UcService', '$translate', '$scope', 'MessageListener'];

    function LoginController ($rootScope, $state, $timeout, Auth, UcAlertService, FormStatePersister, VERSION, VERSION_DATE, UcService, $translate, $scope, MessageListener) {
        var vm = this;

        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        
        vm.showForm = true;
        
        vm.systemInfo = $translate.instant("client.global.label.version") + ": "  + VERSION +" - " + VERSION_DATE;
        UcService.getEnvInfo({}, function(p_)
        {
        	if(p_.showVersion)
        	{
        		vm.systemInfo += " - " + p_.description;
        	}
        });

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
//            $uibModalInstance.dismiss('cancel');
        }

        function login (event)
        {
        	UcAlertService.dismissAll();
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                //$uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' || $state.current.name === 'login' || 
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                	
                	FormStatePersister.resetAll();
                    
                	$state.go('attendance');
                }

                $rootScope.$broadcast('authenticationSuccess');
                
                MessageListener.start();

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
            });
        }

        function register () {
            //$uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            //$uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
        
        // Para o modal login, caso caia na página de login
        $scope.$on('authenticationSuccess', function()
        {
        	vm.showForm = false;
        });        
    }
})();
