(function()
{
    'use strict';
    angular
        .module('sgmApp')
        .factory('UcService', UcService);

    UcService.$inject = ['$resource', 'URL', 'SERVICES'];

    function UcService ($resource, URL, SERVICES)
    {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/uc';

        return $resource(resourceUrl, {}, {
        	'getEnvInfo': { method: 'GET', url: url + 'api/uc/get-env-info' },
        	'fetchSelect': { method: 'GET', url: url + 'api/uc/fetch-select', isArray: true },
        	'loadPhoto': { method: 'GET', url: url + 'api/uc/load-photo/:photoId/:photoType' },
        	'removePhoto': { method: 'DELETE', url: url + 'api/uc/remove-photo/:photoId' },
        	'uploadCamera': { method: 'PUT', url: url + 'api/uc/upload-camera' },
        	'getTrackChanges': { method: 'GET', url: url + 'api/uc/get-track-changes/:trackChangesId/:objectId'},
        	'getClientCode': { method: 'GET', url: url + 'api/uc/get-client-code'}
        });
    }
})();
