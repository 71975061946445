(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('ManufacturerProductStockInfoDialogInvoker', ManufacturerProductStockInfoDialogInvoker);

		ManufacturerProductStockInfoDialogInvoker.$inject = ['$uibModal'];

    function ManufacturerProductStockInfoDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/product-stock-control/manufacturer-product-stock-info-dialog/manufacturer-product-stock-info-dialog.html',
				controller: 'ManufacturerProductStockInfoController',
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					manufacturerProductId: p_param.manufacturerProductId,
					manufacturerProductBatchList:  ['ManufacturerProductBatch', function(ManufacturerProductBatch) {
						//return ManufacturerProductBatch.fetchComplete({manufacturerProductId : p_param.manufacturerProductId}).$promise;
					}],
					stockPlaceList:  ['StockPlace', function(StockPlace) {
						//return StockPlace.getByManufacturerProductId({manufacturerProductId : p_param.manufacturerProductId}).$promise;
					}],
					stockInfo:  ['ManufacturerProductStockFlow', function(ManufacturerProductStockFlow) {
						return ManufacturerProductStockFlow.getManufacturerProductStockInfo({manufacturerProductId : p_param.manufacturerProductId}).$promise;
					}]
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();