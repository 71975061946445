(function () {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ExpenseManagerTabAttachmentController', ExpenseManagerTabAttachmentController);

    ExpenseManagerTabAttachmentController.$inject = ['$scope', '$rootScope', 'UcDatasourceService', 'UcAlertService', 'FormConstants', 'DownloadUtil', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', 'URL', 'SERVICES', '$timeout', 'UcGridService', 'ExpenseAttachmentModalInvoker'];
    function ExpenseManagerTabAttachmentController($scope, $rootScope, UcDatasourceService, UcAlertService, FormConstants, DownloadUtil, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, URL, SERVICES, $timeout, UcGridService, ExpenseAttachmentModalInvoker) {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;

        var injectedResources = vm.injectedResources;

        if (entity && entity.id) {

            var sort = {
                "name": "dataAnexo",
                "type": "desc"
            };

            var gridFieldList = UcGridService.createFieldList([
                { headerName: $translate.instant("client.page.expense.tab.attachment.dataAnexo"), field: "dataAnexo", cellStyle: { alignItems: "center", textAlign: "center" }, width: 200 },
                { headerName: $translate.instant("client.page.expense.tab.attachment.name"), field: "name", width: 200 },
                { headerName: $translate.instant("client.page.expense.tab.attachment.description"), field: "description", width: 200 },
                UcGridService.button.ACTION({ field: "download", title: "client.global.label.download", width: 50, btnClass: "btn-primary", fontIcon: "fa fa-download", suppressSizeToFit: false, action: downloadAttachment2 }),
                UcGridService.button.ACTION({ field: "delete", title: "client.global.label.delete", width: 50, btnClass: "btn-primary", fontIcon: "fa fa-trash-o", suppressSizeToFit: false, action: deleteAttachment })
            ]);

            var grid = UcGridService.create(
                {
                    gridId: "EXPENSE_ATTACHMENT",
                    getFilterData: function () { return { expenseIdFk: entity.id }; },
                    gridFieldList: gridFieldList,
                    sort: sort
                });

            vm.attachmentGridOptions = grid.getGridOptions();
        }

        /*private*/ function onattachmentSelect() {
            if (entity && entity.id) {
                $timeout(function () {
                    grid.refresh();
                }, 1);
            }
        };

                /*public*/ vm.addAttach = function () {
            ExpenseAttachmentModalInvoker.invoke({
                expense: entity.id,
                callback: function () {
                    onattachmentSelect();
                }
            });
        }

        function downloadAttachment2(data) {
            var url = URL[SERVICES.environment].api;
            DownloadUtil.download(url + "api/expense/download/" + data.data.id);
        }

        function deleteAttachment(data) {

            if (!vm.masterUser && (vm.domain.expenseStatus && vm.domain.expenseStatus.id === "ENC")) {
                return;
            }

            var message = $translate.instant("client.global.messages.info.deleteRecord") + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");

            $rootScope.confirm(
                {
                    message: message,
                    callback: function () {
                        UcDatasourceService.delete(
                            {
                                id: data.data.id,
                                gridId: 'EXPENSE_ATTACHMENT'
                            },
                            function (p_customMessage) {
                                UcAlertService.success($translate.instant("client.global.messages.info.operationSuccessfuly"));
                                onattachmentSelect();
                            });
                    }
                });
        }

        // Para o load na aba
//        onattachmentSelect();

        $scope.$on('expense-manager.refreshTab.attachment', function () {
            onattachmentSelect();
        });
    };
})();