(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('product', {
            parent: 'entity',
            url: '/product?page&sort',
            data: {
                authorities: [PermissionSet.PRODUCT.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'ProductController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                gridDefaultSort: function()
                {
                	return {
	            		"name": "name", 
	            		"type": "asc"
                	};
            	},
                categoriesList: function(SupplierCategory){
        			return SupplierCategory.listAllActive().$promise;
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.product.title',
	                	icon: $rootScope.menuIcons.cadastre.product,
	                	formValidateTarget: 'Product',
	                	permission: PermissionSet.PRODUCT
                	})
                }]
            }
        })
        .state('product.base-domain', {
            parent: 'product',
            abstract: true,
            url: '/domain/:activeTab',
        })
        .state('product.detail', {
            parent: 'product.base-domain',
            url: '/{id}/detail',
            views: {
                'content@': {
                	templateUrl: 'app/entities/product/product-domain.html',
                    controller: 'ProductDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                categoriesList: ['SupplierCategory', function(SupplierCategory){
                    return SupplierCategory.listAllActive().$promise;
                }],
                entity: ['$stateParams', 'Product', function($stateParams, Product)
                {
                    return Product.getProductWithManufacturerProducts({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]
            }
        })
        .state('product.edit', {
            parent: 'product.base-domain',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/entities/product/product-domain.html',
                    controller: 'ProductDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                categoriesList: ['SupplierCategory', function(SupplierCategory){
                    return SupplierCategory.listAllActive().$promise;
                }],
                entity: ['Product', '$stateParams', function(Product, $stateParams)
                {
                    return Product.getProductWithManufacturerProducts({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('product.new', {
            parent: 'product.base-domain',
            url: '/new',                    
            views: {
                'content@': {
                	templateUrl: 'app/entities/product/product-domain.html',
                    controller: 'ProductDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                categoriesList: ['SupplierCategory', function(SupplierCategory){
                    return SupplierCategory.listAllActive().$promise;
                }],
                entity: function()
                {
                    return {
                    	subcategoryIdList:[],
                    }
                },
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }
})();
