(function(){
	'use strict';
	angular.module('sgmApp').controller('ProcedureProntuaryModelDialogController', ProcedureProntuaryModelDialogController);
	
	ProcedureProntuaryModelDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'entity'];
	
	
	function ProcedureProntuaryModelDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, entity){
		
		var vm = this;
		vm.pageConfig = pageConfig;
		vm.formModel = {};
		if(entity) {
			entity = Object.assign({}, entity);
			vm.formModel.prontuaryModel = entity;
		}
		vm.prontuaryFormConfig = {
			pageMode: 'READONLY',
			prontuary: vm.formModel.prontuaryModel
		}
		
		var formOptions = {
				model: vm.formModel,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormProcedureProntuaryModel; }
	        };
		
		vm.formHandler = FormDomain.create(formOptions).
			add([
				new FormField({
					type: "Select",
					id: "prontuaryModel",
					required: true,
					label: "client.page.procedure.tab.prontuaryModel.dialog.selectProntuaryModel",
					cellCssClass: "col-sm-6",
					onChange: function(obj) {
						vm.prontuaryFormConfig.prontuary = obj.prontuaryModel;
						console.log(vm.prontuaryFormConfig);
					},
					dataSource: FormConstants.DataSources.BackEnd.ProntuaryModel(BackEndSelectDataSource, $translate, true),
				}),
			])
		
		 /*public*/ vm.closeModal = function(prontuaryModel)
		 {
			$uibModalInstance.close(prontuaryModel);
        };
        
        vm.onSaveProcedureProntuaryModel = function(){
        	
        	var domain = null;
			//if is save needs to validate and return the object
			console.log($scope.editFormProcedureProntuaryModel);
			console.log(vm.formHandler.getRowList());
			FormUtil.validateAll($scope.editFormProcedureProntuaryModel, vm.formHandler.getRowList());
			if($scope.editFormProcedureProntuaryModel.$valid) {
				domain = vm.formHandler.readForm().prontuaryModel;
				vm.closeModal(domain);
			}
        	
        }
	       
	}
})();
