(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsEditHistoryModalController', RecordsEditHistoryModalController);

    RecordsEditHistoryModalController.$inject = ['$uibModalInstance', 'historyList'];

    function RecordsEditHistoryModalController($uibModalInstance, historyList)
    {
        var vm = this;

        vm.historyList = historyList;

        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();