(function(){
	'use strict';
	angular.module('sgmApp').controller('SupplierContactDialogController', SupplierContactDialogController);
	
	SupplierContactDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'Supplier', 'UcAlertService'];
	
	function SupplierContactDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, Supplier, UcAlertService){
		
		var vm = this;
		vm.pageConfig = pageConfig;
		
		var entity = {};
		
		var formOptions = {
				model: entity,
				service: Supplier,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormSupplierContact; }
	        };
		
        vm.formHandler = FormDomain.create(formOptions).
    	add([
    		new FormField({
				cellCssClass: "col-sm-4",
				type: "String",
				readOnly: vm.readOnlyFinish,
				id: "name",
				required: true,
				label: "common.domain.supplierContact.name"
			}),
			new FormField({
				cellCssClass: "col-sm-2",
				type: "String",
				readOnly: vm.readOnlyFinish,
				id: "position",
				label: "common.domain.supplierContact.position"
			}),
			new FormField({
				cellCssClass: "col-sm-3",
				type: "String",
				readOnly: vm.readOnlyFinish,
				id: "email",
				label: "common.domain.supplierContact.email"
			}),
			new FormField({
				cellCssClass: "col-sm-3",
				type: "Phone",
				readOnly: vm.readOnlyFinish,
				id: "phoneNumber",
				label: "common.domain.supplierContact.phoneNumber"
			})
    		
    	]);
    
		 /*public*/ vm.closeModal = function(supplierContact)
        {
			if(!supplierContact || (supplierContact.name && supplierContact.name !== "")){
				$uibModalInstance.close(supplierContact);
			}
        };
        
        vm.onSaveContact = function(){
        	
        	var domain = null;
			//if is save needs to validate and return the object
			
			domain = vm.formHandler.readForm();
			FormUtil.validateAll($scope.editFormSupplierContact, vm.formHandler.getRowList());
//        	formOptions.editFormSupplierContact.validate();
//			FormUtil.validateField(formOptions.formValidateTarget, $scope.editFormSupplierContact, UcAlertService, $translate)
        	
        	vm.closeModal(domain);
        }
        
	}
})();
