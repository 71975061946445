(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('AttendanceProductReportUtil', AttendanceProductReportUtil);

	AttendanceProductReportUtil.$inject = ['UcAlertService', '$translate'];

    function AttendanceProductReportUtil(UcAlertService, $translate)
    {
    	return {
    		generatePdf: function(attendances, accountName)
    		{
				function addAttendanceProductReportPdf(attendance) {
					var attendanceProductSet = attendance.attendanceProductSet;
					var html = ""
					html += '<h5 style="text-align: center; margin-top: 5px;">Atendimento '+attendance.id+'</h3>'
					html += '<table class="report-info" style="margin: 10px 0px;">';
					html += '<tr class="report-info-row"><td>' 
							+ '<span class="report-info-title">Data de Geração:</span><span class="report-info-detail">'+moment().format('DD/MM/YYYY HH:mm')+'</span>' 
							+ '</td>';
					html += '<td>' 
							+ '<span class="report-info-title">Usuário:</span><span class="report-info-detail">'+accountName+'</span>' 
							+'</td></tr>';
					html += '<tr class="report-info-row"><td>' 
							+ '<span class="report-info-title">ID Atendimento:</span><span class="report-info-detail">'+attendance.id+'</span>' 
							+ '</td>';
					html += '<td>' 
							+ '<span class="report-info-title">ID Requisição:</span><span class="report-info-detail">'+attendance.salesRequest.id+'</span>' 
							+ '</td></tr>';
					html += '<tr class="report-info-row"><td>' 
							+ '<span class="report-info-title">ID Paciente:</span><span class="report-info-detail">'+attendance.salesRequest.client.id+'</span>' 
							+ '</td>';
					html += '<td>' 
							+ '<span class="report-info-title">Nome Paciente:</span><span class="report-info-detail">'+attendance.salesRequest.client.defaultDescription+'</span>' 
							+ '</td></tr>';
					html += '<tr class="report-info-row"><td>' 
							+ '<span class="report-info-title">Data do Atendimento:</span><span class="report-info-detail">'+moment(attendance.plannedDate).format('DD/MM/YYYY HH:mm')+'</span>' 
							+ '</td>';    
					html += '<td>' 
							+ '<span class="report-info-title">Nome do Médico:</span><span class="report-info-detail">'+attendance.userMedicId.defaultDescription+'</span>' 
							+ '</td></tr>'; 
					html += '<tr class="report-info-row"><td>' 
							+ '<span class="report-info-title">Procedimento:</span><span class="report-info-detail">'+(attendance.procedure ? attendance.procedure.defaultDescription : attendance.salesRequest.procedure.defaultDescription)+'</span>' 
							+ '</td>'; 
					html += '<td class="report-info-row">' 
							+ '<span class="report-info-title">Família do Procedimento:</span><span class="report-info-detail">'+(attendance.familyProcedure ? attendance.familyProcedure.defaultDescription : attendance.salesRequest.procedure.familyProcedure.defaultDescription)+'</span>' 
							+ '</td></tr>';       
					html += '</table>'
					html += `<table style="width: auto; font-size: 8.5pt; border-collapse: collapse;word-break: keep-all;">
							<tr style="background-color: #09486d; color: white; font-weight: bold;">
								<th >Desc. Gen. Produto</th>
								<th >Un.<br>Medida</th>
								<th >Qtd.<br>Usada</th>
								<th >Prod. do Fab.</th>
								<th style="width: 50px">Lote</th>
								<th >Fabricante</th>
								<th >Loc.<br>Estoque</th>
								<th >ID<br>Fluxo</th>
								<th >Data da conferência</th>
								<th >Portaria 344/98</th>
							</tr>
							`
					var usedProducts = attendanceProductSet.filter(obj => obj.usedAmount > 0);
					for(var i = 0; i < usedProducts.length; i++) {
						var attendanceProduct = usedProducts[i];
						html += `<tr>
							<td>`+attendanceProduct.product.description+`</td>
							<td>`+attendanceProduct.product.measureUnit.description+`</td>
							<td>`+attendanceProduct.usedAmount+`</td>
							<td>`+attendanceProduct.manufacturerProduct.name + " (" + attendanceProduct.manufacturerProduct.barCode+")"+`</td>
							<td>`+attendanceProduct.manufacturerProductBatch.batchCode+`</td>
							<td>`+attendanceProduct.manufacturerProduct.manufacturer.tradingName+`</td>
							<td>`+attendanceProduct.stockPlace.defaultDescription+`</td>
							<td>`+attendanceProduct.manufacturerProductStockFlowIdFk+`</td>
							<td>`+(attendanceProduct.manufacturerProductStockFlowDate ? moment(attendanceProduct.manufacturerProductStockFlowDate).format('DD/MM/YYYY HH:mm') : 'Não informado')+`</td>
							<td>`+(attendanceProduct.product.concierge34498 ? 'Sim' : 'Não')+`</td>
							</tr>
						`;
					}
					html += '</table>';
					return html;
				}
    			var html = '<html moznomarginboxes mozdisallowselectionprint><head><title>Relatório de Produtos</title>';
                html += `<style>
                @media print
                {
                    table { page-break-inside:auto;  font-size: 8.5pt; border-collapse: collapse;word-break: keep-all; width: 100%;}
                    tr    { page-break-inside:avoid; page-break-after:auto }
                    thead { display:table-header-group }
                    tfoot { display:table-footer-group }
                    td, th, tr { border: 1px solid black; }
                    .report-info-title { font-weight: bold; margin-right: 3px; }
                    .report-info-row td { padding: 5px;}
                }
				@page {
					margin: 1cm;
				}
            	</style>`;
                html +=  '</head>';
                html += generatePrintCssStyle();
                html += '<body>';
                html += '<div id="print-area">'
				html += '<h3 style="text-align: center; margin-top: 20px;">Relatório de Produtos Utilizados</h3>'
				if(Array.isArray(attendances)) {
					for(var i = 0; i < attendances.length; i++) {
						var attendance = attendances[i];
						html += addAttendanceProductReportPdf(attendance);
						if(i < attendances.length - 1) {
							html += '<div style="width: 100%; height: 1px; background: black; margin: 20px 0px;"></div>'
						}
					}
				} else {
					html += addAttendanceProductReportPdf(attendances);
				}
                html += '</div>';
                html += '</body>';
                html += '</html>';
                var win = window.open('','printwindow');
                win.document.write(html);
                win.print();
                win.close();
    		}
    	};
    }
})();