(function() {
    'use strict';
    
    angular.module('sgmApp').service('FrontEndSelectDataSource', FrontEndSelectDataSource);

    FrontEndSelectDataSource.$inject = ['$translate', '$sce', '$filter'];
    
    function FrontEndSelectDataSource($translate, $sce, $filter)
    {
    	var vm = this;
    
    	 /* implements ISelectDataSource*/
    	
    	vm.create = function(p_optionList, p_params)
    	{
    		return new _f(p_optionList, p_params);
    	};

    	
    	function _f(p_optionList, p_params)
		{
			var self = this;
			
			// Setado no init
			var multiple = false;

	    	var selectPlaceHolder = (p_params && p_params.placeHolder) ?  p_params.placeHolder : $translate.instant("client.global.label.select");
	    	
	    	// @Override
			/*public*/ this.optionsList = p_optionList;
			
			var defaultParams =
			{
				dsJsonValTransform: null
			};

			var params = defaultParams;
			angular.extend(params, (p_params) ? p_params : {});
			
			this.dsJsonValTransform = params.dsJsonValTransform;
			
			// @Override
			/*public*/ this.init = function(p_multiple)
			{
				multiple = p_multiple;
			};

			// @Override
			/*public*/ this.getOptionList = function(p_search)
			{
				return $filter("selectFilter")(this.optionsList, {defaultDescription: p_search});
			}

			// @Override
			/*public*/ this.onSelect = function(p_item, p_objModel, p_fieldModelId)
			{
			};

			// @Override
			/*public*/ this.refresh = function(p_search)
			{
			};

			/*public*/ this.setOptionsList = function(p_optionList) {
				self.optionsList = p_optionList;
			}
			
			// @Override
	    	/*public*/ this.parseSelectOptionText = function(p_opt, p_search)
	    	{
	    		var text = (p_opt && p_opt.defaultDescription) ? p_opt.defaultDescription : "";
	    		return $sce.trustAsHtml($filter("highlight")(text, p_search));
	    	};
	    	
	    	// @Override
	    	/*public*/ this.parseSelectedOption = function(p_opt)
	    	{
	    		var text = (p_opt && p_opt.defaultDescription) ? p_opt.defaultDescription : "";
	    		return $sce.trustAsHtml(text);
	    	};

	    	// @Override
	    	/*public*/ this.resolveSelectPlaceHolder = function()
	    	{
	    		return selectPlaceHolder;
	    	};
    	};
    }
})();