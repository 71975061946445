(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProductKitItemTabController', ProductKitItemTabController);
    
    ProductKitItemTabController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'ProductKitItemDialogInvoker', 'ProductKitItem', '$uibModal'];
    function ProductKitItemTabController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, ProductKitItemDialogInvoker, ProductKitItem, $uibModal)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        function callBackAddProductKitItem(productKitItem){
        	if(productKitItem){
                productKitItem.productKitId = vm.domain.id;
                if(!productKitItem.id) {
                    ProductKitItem.save(productKitItem, function(res, putResponseHeaders) {
                        vm.productKitItems.push(res);
                    })
                } else {
                    ProductKitItem.update(productKitItem, function(res, putResponseHeaders) {
                        vm.productKitItems.push(res);
                    })
                }
        	}
        }

        function callBackUpdateProductKitItem(productKitItem, index){
        	if(productKitItem){
                productKitItem.productKitId = vm.domain.id;
                ProductKitItem.update(productKitItem, function(res, putResponseHeaders) {
                    vm.productKitItems[index] = res;
                });
        	}
        }


        vm.editProductKitItem = function(productKitItem, index){
        	ProductKitItemDialogInvoker.invoke({callback: function(res) { 
                callBackUpdateProductKitItem(res, index)
            }, entity: productKitItem});
        }

        vm.addProductKitItem = function(){
        	ProductKitItemDialogInvoker.invoke({callback: callBackAddProductKitItem});
        }

        vm.removeProductKitItem = function(index) {
            $uibModal.open({
				templateUrl: 'app/common/delete-dialog/delete-dialog-template.html',
				controller: 'DeleteDialogController',
				controllerAs: 'vm',
				size: 'sm',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
                    service: 'ProductKitItem',
                    obj: vm.productKitItems[index]
                }
			}).result.then(function(p_)
			{
				if(p_) {
                    vm.productKitItems.splice(index, 1);
                }
			}, function() {
				/*void*/
			});
		}
    };
})();