(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProcedureProductsTabController', ProcedureProductsTabController);
    
    ProcedureProductsTabController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'ProcedureProductDialogInvoker', 'ProcedureProduct', '$uibModal'];
    function ProcedureProductsTabController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, ProcedureProductDialogInvoker, ProcedureProduct, $uibModal)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        function callBackAddProcedureProduct(procedureProduct){
        	if(procedureProduct){
                procedureProduct.procedureId = entity.id;
                if(!procedureProduct.id) {
                    ProcedureProduct.save(procedureProduct, function(res, putResponseHeaders) {
                        vm.procedureProducts.push(res);
                    })
                }
        	}
        }

        function callBackUpdateProcedureProduct(procedureProduct, index){
        	if(procedureProduct){
                procedureProduct.procedureId = entity.id;
                ProcedureProduct.update(procedureProduct, function(res, putResponseHeaders) {
                    vm.procedureProducts[index] = res;
                });
        	}
        }


        vm.editProcedureProduct = function(procedureProduct, index){
            if(vm.productTabIsReadonly) {
                return;
            }
        	ProcedureProductDialogInvoker.invoke({callback: function(res) { 
                callBackUpdateProcedureProduct(res, index)
            }, entity: procedureProduct});
        }

        vm.addProcedureProduct = function(){
            if(vm.productTabIsReadonly) {
                return;
            }
        	ProcedureProductDialogInvoker.invoke({callback: callBackAddProcedureProduct});
        }

        vm.removeProcedureProduct = function(index) {
            if(vm.productTabIsReadonly) {
                return;
            }
            $uibModal.open({
				templateUrl: 'app/common/delete-dialog/delete-dialog-template.html',
				controller: 'DeleteDialogController',
				controllerAs: 'vm',
				size: 'sm',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
                    service: 'ProcedureProduct',
                    obj: vm.procedureProducts[index]
                }
			}).result.then(function(p_)
			{
				if(p_) {
                    vm.procedureProducts.splice(index, 1);
                }
			}, function() {
				/*void*/
			});
		}
    };
})();