(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientManagerTabLastAttendenceController', ClientManagerTabLastAttendenceController);
    
    ClientManagerTabLastAttendenceController.$inject = ['$scope', 'FormDomain', 'Attendance'];
    function ClientManagerTabLastAttendenceController($scope, FormDomain, Attendance)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        
        vm.lastAttendance = {};
        
        // Busca o último atendimento do cliente
        if(entity && entity.id)
        {
        	Attendance.getLastAttendanceByClient({clientId: entity.id}, function(p_dto)
        	{
        		handleLastAttendanceReturn(p_dto);
        	});
        }
        
        vm.lastAttendancePageConfig = new PageConfig({
    		pageMode: "READONLY"
    	});
        
        var attendanceFormOptions = {
        	model: vm.lastAttendance,
        	pageConfig: vm.lastAttendancePageConfig 
        };
        vm.attendanceFormHandler = FormDomain.create(attendanceFormOptions)        
        	.add([new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "lastAttendence",
					label: "client.page.client.tab.lastAttendence.medic",
					id: "medic",
					type: "String"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "lastAttendence",
					label: "client.page.client.tab.lastAttendence.familyProcedure",
					id: "familyProcedure",
					type: "String"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "lastAttendence",
					label: "client.page.client.tab.lastAttendence.procedure",
					id: "procedure",
					type: "String"
				})
        	])
        	.add([new FormHtmlRender({
	    			formTarget: "lastAttendence",
	    			cellCssClass: "col-sm-4",
	    			label: "client.global.label.status",
	    			hasLabel: true,
					render: function(p_objModel, p_fieldModel, p_pageMode, p_editMode)
					{
						return PillRenderer.rendererEnum(p_objModel["status"]);
					}
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "lastAttendence",
					label: "client.page.client.tab.lastAttendence.date",
					id: "dateFormatted",
					type: "String"
				})
        	])	        	
		.endConfig();
        
        /*private*/ function handleLastAttendanceReturn(p_dto)
        {
        	// O teste do id identifica objeto null
        	if(!p_dto.id)
        	{
        		// Não há atendimentos. Seta a flag
        		vm.lastAttendance.noAttendance = true;
        	}
        	else
        	{
        		// Seta os campos para edição
        		angular.extend(vm.lastAttendance,
        		{
        			medic: (p_dto.userMedicId) ? p_dto.userMedicId.name : null,
        			familyProcedure: (p_dto.salesRequest && p_dto.salesRequest.procedure && p_dto.salesRequest.procedure.familyProcedure) ? p_dto.salesRequest.procedure.familyProcedure.defaultDescription : null,
        			procedure: (p_dto.salesRequest && p_dto.salesRequest.procedure) ? p_dto.salesRequest.procedure.defaultDescription : null,
        			status: p_dto.status,
        			dateFormatted: p_dto.plannedDateFormatted
        		});
        	}
        };
    };
    

})();