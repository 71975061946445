(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsModalInvoker', RecordsModalInvoker);
	
	RecordsModalInvoker.$inject = ['$uibModal'];
	
	function RecordsModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/records-modal-dialog.html',
                controller: 'RecordsModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                windowClass: 'modal-dialog-90-percent',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT"
                    	})
                    }],
                    client: function()
                    {
                    	return p_param.client;
                    },
                    attendanceId: function()
                    {
                    	return p_param.attendanceId;
                    },
                    attendanceProcedureId: function() {
                        return p_param.attendanceProcedureId
                    },
                    prontuaryTabList: ['ProntuaryModel', function(ProntuaryModel)
                    {
                        return ProntuaryModel.listProntuaryTabs().$promise;
                    }],
                    productsAttendances: ['Attendance', function(Attendance)
                    {
                        if(p_param.client)
                            return Attendance.getClientProductsAttendances({clientId: p_param.client.id}).$promise;
                        return null;
                    }],
                }
            }).result.then(function(p_salesRequest)
            {

            }, function()
            {

            });
		};
		
	};
})();
