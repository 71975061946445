(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('InstallmentCancelDialogInvoker', InstallmentCancelDialogInvoker);

    InstallmentCancelDialogInvoker.$inject = ['$uibModal'];

    function InstallmentCancelDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/expense/dialog/installment-cancel-dialog.html',
				controller: 'InstallmentCancelDialogController',
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					installmentCancelData: p_param.installmentCancelData,
					pageConfig: [function()
					{
						return {};
					}]
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();