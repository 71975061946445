(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(config);
    
    config.$inject = ['growlProvider'];

    function config(growlProvider)
    {
    	growlProvider.onlyUniqueMessages(false);
    	growlProvider.globalDisableCountDown(true);
    	growlProvider.globalTimeToLive({success: 5000, error: 10000, warning: 10000, info: 5000});
    }
})();

