(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'MenuHighlight', '$rootScope', '$timeout', 'clientCode', 'UcService'/*, 'LoginService'*/];

    function NavbarController ($state, Auth, Principal, ProfileService, MenuHighlight, $rootScope, $timeout, clientCode, UcService/*, LoginService*/)
    {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;

        var clientCodeResolved = "";
        if(clientCode && clientCode.value)
        {
        	clientCodeResolved = clientCode.value + "/";	
        }
        
        vm.menuIcon = "https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/" + clientCodeResolved + "client_logo.png";
        
        $rootScope.$on("global.login.complete", function()
        {
        	refreshUserProfile();
        });
        
        // Próximo ciclo, para dar tempo de criar a função
        $timeout(function()
        {
        	refreshUserProfile();
        }, 1);


        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        
        vm.clickMenu = function()
        {
        	MenuHighlight.refresh(null);
        	collapseNavbar();
        }

        
//        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

//        function login() {
//            collapseNavbar();
//            LoginService.open();
//        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            //$state.go('dashboard');
            $state.go('login');
            
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
        
        
        vm.toogleMenu = function()
        {
    		$("body").toggleClass("nav-toggle");
        };
        
        /**
         * Atualiza as informações da conta do usuário
         */
        /*private*/ function refreshUserProfile()
        {
            Principal.identity().then(function(account)
            {
                vm.account = account;
                
                if(vm.isAuthenticated())
                {
                	// Carrega a imagem no menu - próximo ciclo por segurança
                	$timeout(function()
                	{
                    	UcService.loadPhoto({photoId: "user-" + vm.account.id, photoType: "AVATAR"}, function(p_imageDto)
                    	{
                    		var photoLoaded = false;
                    		if(p_imageDto.image)
                    		{
                    			try
                    			{
                    				$("#navbar-profile-img").attr("src",  p_imageDto.image.imageLink);
//                    				$("#navbar-profile-img").attr("src", "data:" + p_imageDto.image.contentType + ";base64," + p_imageDto.image.bytes);
	                    			photoLoaded = true;
                    			}
                    			catch(eee)
                    			{
                    				console.error("Error at load navbar user profile image: " + eee.message);
                    			}
                    		}
                    		if(!photoLoaded)
                    		{
                    			$("#navbar-profile-img").attr("src", "https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/profile.png");
                    		}
                    	});
                	}, 1);
                }
                
            });
        };

    }
})();
