(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('StockPlace', StockPlace);

    StockPlace.$inject = ['$resource', 'URL', 'SERVICES'];

    function StockPlace ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/stock-place/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByManufacturerProductId': { method: 'GET', url: url + 'api/stock-place/amount-on-stock/:manufacturerProductId', isArray: true },
            'update': { method:'PUT' },
        });
    }
})();
