(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('ProductKit', ProductKit);

    ProductKit.$inject = ['$resource', 'URL', 'SERVICES'];

    function ProductKit ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/product-kit/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }   
            },
            'update': { method:'PUT' },
            'listAllActive': {method: 'GET', url: url + 'api/product-kit/list-all-active', isArray: true},
        });
    }
})();
