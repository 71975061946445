(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('productStockControl', {
            parent: 'entity',
            url: '/productStockControl?page&sort',
            data: {
                authorities: [PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'ProductStockControlController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                categoriesList: ['SupplierCategory', function(SupplierCategory){
                    return SupplierCategory.listAllActive().$promise;
                }],
                gridDefaultSort: function()
                {
                	return {
	            		"name": "id", 
	            		"type": "desc"
                	};
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.productStockControl.title',
	                	icon: $rootScope.menuIcons.main.productStockControl,
	                	formValidateTarget: 'ProductStockControl',
	                	permission: PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW
                	})
                }]
            }
        });
    }
})();
