(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('prontuaryModel', {
            parent: 'entity',
            url: '/prontuaryModel?page&sort',
            data: {
                authorities: [PermissionSet.PRONTUARY_MODEL.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'ProntuaryModelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                gridDefaultSort: function()
                {
                	return {
	            		"name": "name", 
	            		"type": "asc"
                	};
            	},
                prontuaryItemTypesList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryItemTypes().$promise;
                }],
                prontuaryTabList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryTabs().$promise;
                }],
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.prontuaryModel.title',
	                	icon: $rootScope.menuIcons.cadastre.prontuaryModel,
	                	formValidateTarget: 'ProntuaryModel',
	                	permission: PermissionSet.PRONTUARY_MODEL
                	})
                }]
            }
        })
        .state('prontuaryModel.base-domain', {
            parent: 'prontuaryModel',
            abstract: true,
            url: '/domain/:activeTab',
        })
        .state('prontuaryModel.detail', {
            parent: 'prontuaryModel.base-domain',
            url: '/{id}/detail',
            views: {
                'content@': {
                	templateUrl: 'app/entities/prontuary-model/prontuary-model-domain.html',
                    controller: 'ProntuaryModelDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                prontuaryItemTypesList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryItemTypes().$promise;
                }],
                prontuaryTabList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryTabs().$promise;
                }],
                entity: ['ProntuaryModel', '$stateParams', function(ProntuaryModel, $stateParams)
                {
                    return ProntuaryModel.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]
            }
        })
        .state('prontuaryModel.edit', {
            parent: 'prontuaryModel.base-domain',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/entities/prontuary-model/prontuary-model-domain.html',
                    controller: 'ProntuaryModelDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                prontuaryItemTypesList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryItemTypes().$promise;
                }],
                prontuaryTabList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryTabs().$promise;
                }],
                entity: ['ProntuaryModel', '$stateParams', function(ProntuaryModel, $stateParams)
                {
                    return ProntuaryModel.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('prontuaryModel.new', {
            parent: 'prontuaryModel.base-domain',
            url: '/new',                    
            views: {
                'content@': {
                	templateUrl: 'app/entities/prontuary-model/prontuary-model-domain.html',
                    controller: 'ProntuaryModelDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                prontuaryItemTypesList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryItemTypes().$promise;
                }],
                prontuaryTabList: ['ProntuaryModel', function(ProntuaryModel)
                {
                    return ProntuaryModel.listProntuaryTabs().$promise;
                }],
                entity: function()
                {
                    return {}
                },
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }
})();
