'use strict';

/**
 * Json de configuração das páginas do sistema
 * 
 *  IMPORTANTE: Toda nova configuração DEVE ser criada aqui com uma descrição 
 */
var _DEFAULT_PAGE_CONFIG =
{
	pageMode: null,					// Modo da página: FILTER, READONLY, CREATE, EDIT 
	title: null,					// Título da página - chave i18n 
	icon: null,						// Ícone da página - preferencialmente cria chaves em $rootScope.menuIcons
	formValidateTarget: null		// Nome do pseud-form para o componente de validação
//	editPermission: null			// Permissão de edição da página (usado no grid)
};

function PageConfig(p_config)
{
	// Carrega os valoes default
	angular.extend(this, _DEFAULT_PAGE_CONFIG);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_config) ? p_config : {});
	
	/*public*/ this.clone = function()
	{
		var clone = {};
		
		// Copia os valores atuais
		angular.extend(clone, this);
		
		return clone;
	};
	
	/*public*/ this.set = function(p_config)
	{
		if(p_config)
		{
			// Sobreescreve os valores
			angular.extend(this, p_config);
		}
		return this;
	};	
};
