(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('CancelAttendanceDialogController', CancelAttendanceDialogController);

    CancelAttendanceDialogController.$inject = ['$scope', '$uibModalInstance', 'FormDomain', 'FrontEndSelectDataSource', '$translate', 'Attendance', '$rootScope', 'cancelAttendanceReasonList'];

    function CancelAttendanceDialogController($scope, $uibModalInstance, FormDomain, FrontEndSelectDataSource, $translate, Attendance, $rootScope, cancelAttendanceReasonList)
    {
        var vm = this;
        
        vm.pageConfig = new PageConfig({
    		pageMode: "CUSTOM_EDIT", // Para não ser inseridos buttons
    		formValidateTarget: "CancelAttendanceDialog"
    	});
        
        var entity = {
        };
        
        var formOptions = {
        	model: entity,
        	pageConfig: vm.pageConfig,
        	buttonsModel: vm.buttonsModel,
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add(new FormField({
	        	cellCssClass: "col-sm-12",
				type: "Select",
				id: "cancelAttendanceReason",
				required: true,
				label: "client.page.cancelAttendanceDialog.cancelAttendanceReason",
				dataSource: FrontEndSelectDataSource.create(cancelAttendanceReasonList)
	        }))	        
	        .add(new FormField({
	        	cellCssClass: "col-sm-12",
				type: "TextArea",
				id: "cancelationJustification",
				label: "client.page.cancelAttendanceDialog.cancelationJustification",
				maxLength: 1000
			}))
			.endConfig();
        
        /*private*/ var saveButton = {
            	order: 0,
            	cssClass: 'btn btn-success',
            	cssClassIcon: 'glyphicon glyphicon-save',
            	label: 'client.global.label.save',
            	type: 'submit',
            	isDisabled: function() { return false; },
            	isToAdd: function() { return true; },
            	click: function() { /* void - por ser submit, a ação está no ng-submit do form */ }
            };

        /*private*/ var closeButton = {
        	order: 10,
        	cssClass: 'btn btn-default',
        	cssClassIcon: 'fa fa-times',
        	label: 'client.global.label.close',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function()
        	{
            	$uibModalInstance.dismiss();
        	}
        };
        
        vm.buttonsModel = {
	    	customButtonList: [ saveButton, closeButton ]
	    }; 

		/*public*/ vm.closeModal = function()
		{
			$uibModalInstance.dismiss();
		}

        /*public*/ vm.save = function()
        {
        	vm.formHandler.preSave(internalOnSave)
        }
        
        /*private*/ function internalOnSave(p_json)
        {
			var message = $translate.instant("client.page.cancelAttendanceDialog.cancelationConfirmation") + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					saveConfirmation(p_json);
				} 
			});
        };
        	
        /*private*/ function saveConfirmation(p_json)
        {
        	// Seta o id do Atendimento que está sendo cancelado
        	p_json.id = $scope.currentAttendance.id;
        	
        	// Chama o serviço e retorna o novo atendimento para o parent
        	Attendance.cancel(p_json, 
        		function()
	        	{
        			$uibModalInstance.close(true);
	        	});
        };
    }
})();