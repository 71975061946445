(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ExpenseDomainController', ExpenseDomainController);
    
    ExpenseDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'pageConfig', '$stateParams', 'listExpenseStatusType', 'listExpenseTimeStatusType', 'listInstallmentsquantity'];

    function ExpenseDomainController(FormDomain, $scope, $state, entity, pageConfig, $stateParams, listExpenseStatusType, listExpenseTimeStatusType, listInstallmentsquantity)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
        vm.injectedResources = {
        	listExpenseStatusType: listExpenseStatusType,
        	listExpenseTimeStatusType: listExpenseTimeStatusType,
			listInstallmentsquantity: listInstallmentsquantity
        };
    };
})();
