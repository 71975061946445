(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('IndicationsController', IndicationsController);

	IndicationsController.$inject = [
	    'FormFilter',
        'UcGridService',
        '$translate',
        'account',
        'filterPageConfig',
        'gridDefaultSort',
        '$rootScope',
        'IndicationsService',
        'FrontEndSelectDataSource',
        'indicationTypeList'
    ];

	function IndicationsController(
	    FormFilter,
        UcGridService,
        $translate,
        account,
        filterPageConfig,
        gridDefaultSort,
        $rootScope,
        IndicationsService,
        FrontEndSelectDataSource,
        indicationTypeList
    ) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		$rootScope.initialFocus("defaultDescription");

		vm.buttonsModel =
		{
			stateRoot: "indications",
			onFilter: function() { doFilter(); },
			onClear: function() { doClear(); }
		};

        var indicationTypeFormField = new FormField({
            cellCssClass: "col-sm-4",
            type: "Select",
            id: "indicationTypeList",
            label: "client.page.indications.indicationType",
            multiple: true,
            dataSource: FrontEndSelectDataSource.create(indicationTypeList)
        });

		var formOptions = {
			formStatePersisterId: "Indications"
			//        	checkFormChange: function() { doFilter(); }
		};
		vm.formHandler = FormFilter.create(formOptions)
			.add([new FormField({
				cellCssClass: "col-sm-6",
				type: "String",
				id: "description",
				label: "common.domain.indications.description"
			}),
                indicationTypeFormField,
			])

			.endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.indications.id"), field: "id", width: 25 },
			{ headerName: $translate.instant("common.domain.indications.description"), field: "description", width: 60 },
			{ headerName: $translate.instant("common.domain.indications.indicationsCategory"), field: "indicationsTypeDescription", width: 60 },
            UcGridService.field.ACTIVE()
		]);

		var grid = UcGridService.create(
			{
				gridId: "INDICATIONS",
				//        	permission: vm.pageConfig.editPermission,
				stateBaseName: "indications",
				account: account,
                getFilterData: function() { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
			});

		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};
	}
})();
