(function() {
	'use strict';
	angular
		.module('sgmApp')
		.factory('IndicationsService', IndicationsService);

	IndicationsService.$inject = ['$resource', 'URL', 'SERVICES'];

	function IndicationsService($resource, URL, SERVICES) {
		var url = URL[SERVICES.environment].api;
		var resourceUrl = url + 'api/indications/:id';

		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true },
			'get': {
				method: 'GET',
				transformResponse: function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update': { method: 'PUT' },
			'listAll': { method: 'GET', url: url + 'api/indications/list-all', isArray: true },
			// Retorna um Array para os campos select
			'listAllInList': { method: 'GET', url: url + 'api/indications/list-all-in-list', isArray: true },
			'listAllActive': { method: 'GET', url: url + 'api/indications/list-all-active', isArray: true },
		});
	}
})();
