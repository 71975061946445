(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ManufacturerProductStockFlowController', ManufacturerProductStockFlowController);

	ManufacturerProductStockFlowController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'Principal', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'ManufacturerProductStockFlow', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'DownloadUtil', 'categoriesList', 'PermissionSet', 'ManufacturerProductStockFlowDialogInvoker', '$filter', '$sce', 'stockFlowTypesList', 'ConfirmDialogInvoker'];

	function ManufacturerProductStockFlowController(FormFilter, UcGridService, $translate, account, Principal, filterPageConfig, gridDefaultSort, $rootScope, ManufacturerProductStockFlow, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, DownloadUtil, categoriesList, PermissionSet, ManufacturerProductStockFlowDialogInvoker, $filter, $sce, stockFlowTypesList, ConfirmDialogInvoker) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;
		vm.stockAmountTotal = 0;
		vm.extraFieldRenderer = renderAmountStock;
		let toReloadAmount = true;
		vm.buttonsModel =
			{
				stateRoot: "manufacturerProductStockFlow",
				onFilter: function () { doFilter(); },
				onClear: function () { doClear(); },
				useSuggestedButton: false,
				extraButtonList: [
					{
						order: 0,
						cssClass: 'btn btn-accent',
						cssClassIcon: 'glyphicon glyphicon-search',
						label: 'client.global.label.filter',
						type: 'button',
						isDisabled: function()
						{
							return false;
						},
						isToAdd: function()
						{
							return true;
						},
						click: function()
						{
							doFilter();
						}
					},
					{
						order: 70,
						cssClass: 'btn btn-danger',
						cssClassIcon: 'fa fa-eraser',
						label: 'client.global.label.reset',
						type: 'button',
						isDisabled: function()
						{
							return false;
						},
						isToAdd: function()
						{
							return true;
						},
						click: function()
						{
							doClear();
						}
					},
					{
						order: 80,
						cssClass: 'btn btn-primary',
						cssClassIcon: 'fa fa-file-excel-o',
						label: 'client.global.label.export',
						type: 'button',
						isDisabled: function () {
							return !Principal.hasAnyAuthority([PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW.report]);
						},
						isToAdd: function () { return true; },
						click: function () { generateStockFlowReport(); }
					},
					{
						order: 90,
						cssClass: 'btn btn-success',
						label: 'client.page.manufacturerProductStockFlow.addInStockFlow',
						type: 'button',
						isDisabled: function () {
							return !Principal.hasAnyAuthority([PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW.create]);
						},
						isToAdd: function () { return true; },
						click: function () { addStockFlow(true); }
					},
					{
						order: 100,
						cssClass: 'btn btn-danger',
						label: 'client.page.manufacturerProductStockFlow.addOutStockFlow',
						type: 'button',
						isDisabled: function () {
							return !Principal.hasAnyAuthority([PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW.create]);
						},
						isToAdd: function () { return true; },
						click: function () { addStockFlow(false); }
					},
					/*{
						order: 90,
						cssClass: 'btn btn-success',
						label: 'updateOldFlow',
						type: 'button',
						isToAdd: function () { return true; },
						isDisabled: function() {
							return false;
						},
						click: function () { 
							ManufacturerProductStockFlow.updateOldFlows().$promise.then((res) => {
								console.log(res);
							});
						 }
					},*/
			]
			};

		$rootScope.initialFocus("id");

		var formOptions = {
			formStatePersisterId: "ManufacturerProductStockFlow" 
		};

		var categoriesFormField = new FormField({
			cellCssClass: "col-sm-3",
			type: "Select",
			id: "productCategoryList",
			dependencyName: "categoryListId",
			multiple: true,
			label: "common.domain.manufacturerProductStockFlow.productCategory",
			dataSource: FrontEndSelectDataSource.create(categoriesList)
		});
		vm.formHandler = FormFilter.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "NumberRaw",
					id: "id",
					label: "common.domain.manufacturerProductStockFlow.id",
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Date",
					id: "startFlowDate",
					label: "common.domain.manufacturerProductStockFlow.startFlowDate",
					jsonValTransform: function (p_json, p_model, p_val)
					{
						if(p_val)
						{
							// Primeira hora do dia
							p_val.setHours(0);
							p_val.setMinutes(0);
							p_val.setSeconds(0);
							p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
						}
					},

				}),
				new FormField({
		       		cellCssClass: "col-sm-2",
					type: "Date",
					id: "endFlowDate",
					label: "common.domain.manufacturerProductStockFlow.endFlowDate",
					jsonValTransform: function (p_json, p_model, p_val)
					{
						if(p_val)
						{
							// Última hora do dia
							p_val.setHours(23);
							p_val.setMinutes(59);
							p_val.setSeconds(59);
							p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
						}
					},
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "NumberRaw",
					id: "attendanceId",
					label: "common.domain.manufacturerProductStockFlow.attendance",
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "stockFlowType",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.stockFlowType",
					dataSource: FrontEndSelectDataSource.create(stockFlowTypesList)
				})
				
			])
			.add([
				categoriesFormField,
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "productSubcategoryList",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.productSubcategory",
					dataSource: FormConstants.DataSources.BackEnd.SupplierSubcategory(BackEndSelectDataSource, $translate, categoriesFormField)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "product",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.productDescription",
					dataSource: FormConstants.DataSources.BackEnd.Product(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "stockFlowMotive",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.stockFlowMotive",
					dataSource: FormConstants.DataSources.BackEnd.StockFlowMotive(BackEndSelectDataSource, $translate),
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "stockPlace",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.stockPlace",
					dataSource: FormConstants.DataSources.BackEnd.StockPlace(BackEndSelectDataSource, $translate),
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "manufacturerProductBatch",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductBatchCode",
					dataSource: FormConstants.DataSources.BackEnd.ManufacturerProductBatch(BackEndSelectDataSource),
				}),
				
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "supplier",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.supplier",
					dataSource: FormConstants.DataSources.BackEnd.Supplier(BackEndSelectDataSource, $translate, {
						productSupplier: true
					}),
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "manufacturerProduct",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.manufacturerProduct",
					dataSource: FormConstants.DataSources.BackEnd.ManufacturerProduct(BackEndSelectDataSource, $translate),
					onChange: function(value) {
						toReloadAmount = true;
					}
				})
				
			])
			.endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 1 },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.flowDate"), field: "flowDate", cellStyle: { alignItems: "center", textAlign: "center" }, width: 15, cellRenderer: function(p_param) { return renderDate(p_param) } },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.stockFlowType"), field: "stockFlowType", colId: "stockFlowTypeId", cellStyle: { alignItems: "center", textAlign: "center" }, width: 10, cellRenderer: function(p_param) { return renderStockFlowType(p_param) }  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.productDescription"), field: "productDescription", width: 25  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.manufacturerProductName"), field: "manufacturerProductName", width: 15  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.manufacturerTradingName"), field: "manufacturerTradingName", width: 15  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.stockFlowMotive"), field: "stockFlowMotiveDescription", width: 10, cellRenderer: function(p_param) { return renderStockFlowMotive(p_param) }  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.stockPlace"), field: "stockPlaceDescription", width: 10  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.manufacturerProductBatchCode"), field: "manufacturerProductBatchCode", width: 10  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.productMeasureUnit"), field: "productMeasureUnit", width: 10  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.amount"), field: "amount", width: 5  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.unitValue"), field: "unitValue", width: 10  },
			{ headerName: $translate.instant("common.domain.manufacturerProductStockFlow.totalValue"), field: "totalValue", width: 10  },

			
		]);

		var grid = UcGridService.create(
			{
				gridId: "MANUFACTURER_PRODUCT_STOCK_FLOW",
				stateBaseName: "manufacturerProductStockFlow",
				account: account,
				getFilterData: function () { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
				onRowClicked: function(data) {
					openViewStockFlowModal(data);
				}
			});

		
		
		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh()
			setTimeout(function(){
				getTotalAmountOnStock();
			}, 1);
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};
		
		/*private*/ function generateStockFlowReport() {
		 	var data = vm.formHandler.readForm();
			angular.extend(data, grid.getSortParams());
			delete data["manufacturerProduct"];
			delete data["supplier"];
			delete data["manufacturerProductBatch"];
			delete data["stockPlace"];
			delete data["product"];
			delete data["productSubcategoryList"];
			delete data["stockFlowType"];
			delete data["stockFlowMotive"];
			var countCriteria = {};
			angular.extend(countCriteria, data);
			countCriteria['countBy'] = 'id';
			ManufacturerProductStockFlow.count(countCriteria).$promise.then(function(res) {
				var count = res.value;
				if(
					count <= 3000 &&
					(
						(data['startFlowDate'] == null && data['endFlowDate'] == null) 
						|| (data['startFlowDate'] != null && data['endFlowDate'] != null) 
					) 
					/* && 
					(
						(data.manufacturerProductId && data.manufacturerProductId.length > 0 && data.manufacturerProductId.indexOf(';') == -1)
						|| (data.productId && data.productId.length > 0 && data.productId.indexOf(';') == -1)
					) */
				) {
					
					data.generateReport = true;
					DownloadUtil.download('api/manufacturer-product-stock-flow/generate-report', data);
				} else {
					var exportDialogText = $translate.instant("client.page.manufacturerProductStockFlow.exportDialog.text");
					var exportDialogTitle = $translate.instant("client.page.manufacturerProductStockFlow.exportDialog.title");
					if(
						(
							(data['startFlowDate'] == null && data['endFlowDate'] != null) 
							|| (data['startFlowDate'] != null && data['endFlowDate'] == null) 
						) 
					) {
						exportDialogText = $translate.instant("client.page.manufacturerProductStockFlow.exportDialog.dateText");
					} else if (count > 3000) {
						exportDialogTitle = $translate.instant("client.page.manufacturerProductStockFlow.exportDialog.countTitle");
						exportDialogText = $translate.instant("client.page.manufacturerProductStockFlow.exportDialog.countText");
					}
					ConfirmDialogInvoker.invoke(
						{
							hideButtons: true,
							title: exportDialogTitle,
							text: exportDialogText
						}
					);
				}	
			})
		}
		function getTotalAmountOnStock() {
			const criteria = vm.formHandler.readForm();
			criteria.sumBy = 'amountOnStock';	
			ManufacturerProductStockFlow.sum(criteria).$promise.then(function(res) {
				vm.stockAmountTotal = res.value;
				toReloadAmount = false;
			})
		}

		function renderAmountStock() {
			const manufacturerProduct = vm.formHandler.readForm().manufacturerProductId;
			if(manufacturerProduct && !toReloadAmount) {
				return $sce.trustAsHtml("<div style='margin: 10px;font-size:13pt; color: white;'><span style='font-weight:bold; margin-right: 10px;'>Quantidade em estoque do(s) produto(s):</span>" + vm.stockAmountTotal + "</div>");
			} else {
				return $sce.trustAsHtml("");
			}
		}

		function renderDate(p_param) {
			if (p_param && p_param.data && p_param.data.flowDate) {
				return $filter('date')(p_param.data.flowDate, 'short');
			}
			else {
				return "";
			}
		}

		function renderStockFlowType(p_param) {
			if (p_param && p_param.data && p_param.data.stockFlowType) {
				let str = "<div style='height:100%;background-color:transparent'><span class='uc-pills "
				if(p_param.data.stockFlowType.id == "IN") {
					str += "uc-pills-green"
				} else {
					str += "uc-pills-red"
				}
				str += "'>"

				return $sce.trustAsHtml(str + p_param.data.stockFlowType.defaultDescription + "</span></div>");
			}
			else {
				return "";
			}
		}

		function renderStockFlowMotive(p_param) {
			if(p_param && p_param.data) {
				if (p_param.data.attendanceId) {
					return "Atend." + p_param.data.attendanceId;
				}
				else if(p_param.data.stockFlowMotiveDescription) {
					return p_param.data.stockFlowMotiveDescription;
				} 
			}
			return "";
		}
		function openViewStockFlowModal(row) {
			let isInput = false;
			const id = row.data.id;
			if(id) {
				ManufacturerProductStockFlow.get({id: id}).$promise.then(function(res) {
					if(res.stockFlowType.id == "IN") {
						isInput = true;
					}
					ManufacturerProductStockFlowDialogInvoker.invoke({
						isInput: isInput,
						entityId: id,	
						isReadonly: true,
						//callback: callback
					})
				})
				
			}
			

			
			
			
		}

		/*private*/ function hasStopPropagation(p_event)
		{
			var targetObj = $(p_event.target);
			if(targetObj && (targetObj.attr("stop-propagation") || targetObj.closest("[stop-propagation='true']").length))
			{
				return true;
			}
			else
			{
				return false;
			}
		}


		function addStockFlow(isInput) {
			ManufacturerProductStockFlowDialogInvoker.invoke({
				isInput: isInput,
				callback: callback
			})
		}


		function callback(stockFlow, isInput) {
			if(stockFlow) {
				if(isInput) {
					onCreate(ManufacturerProductStockFlow.createInStockFlow(stockFlow));
				} else {
					onCreate(ManufacturerProductStockFlow.createOutStockFlow(stockFlow));
				}
			}
		}

		function onCreate(createMethod) {
			createMethod.$promise.then(function(obj) {
				doFilter();
			})
		}
	}
})();
