(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsTemplateModalInvoker', RecordsTemplateModalInvoker);
	
	RecordsTemplateModalInvoker.$inject = ['$uibModal'];
	
	function RecordsTemplateModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/template/records-template-modal-dialog.html',
                controller: 'RecordsTemplateModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: 'modal-dialog-80-percent',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT"
                    	})
                    }],
                    recordType: function()
                    {
                    	return p_param.recordType;
                    },
                    templateTree: ['RecordsTemplate', function(RecordsTemplate)
                    {
                        return RecordsTemplate.getTree({recordType: p_param.recordType.id}).$promise;
                    }],
                }
            }).result.then(function(p_flag)
            {
            	if(p_param.callback)
            	{
            		p_param.callback(p_flag);
            	}

            }, function()
            {

            });
		};
		
	};
})();
