(function() {
    'use strict';

    angular
        .module('sgmApp')
        .service('ControllerListener', ControllerListener);

    ControllerListener.$inject = ['$interval'];

    function ControllerListener ($interval)
    {
    	var vm = this;
    	
    	/*public*/ vm.create = function($scope, p_function)
    	{
            var listener = $interval(function()
            {
            	p_function();
            }, 1000);
            
            
            $scope.$on('$destroy', function()
            {
                $interval.cancel(listener);
            });
            return listener;
    	}
    	
    }
})();
