(function() {
    'use strict';

	angular.module('sgmApp').service('AttendanceStatusSelectorModalInvoker', AttendanceStatusSelectorModalInvoker);
	
	AttendanceStatusSelectorModalInvoker.$inject = ['$uibModal'];
	
	function AttendanceStatusSelectorModalInvoker($uibModal)
	{
		var vm = this;
		
		/**
		 * Invoca o modal 
		 * 
		 * param {
		 *    - attendanceId
		 * 	  - callback
		 *    - cancelCallback
		 * }
		 * 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/attendance/status/attendance-status-selector-modal-dialog.html',
                controller: 'AttendanceStatusSelectorModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                	userPermissions: ['Attendance', function(Attendance)
	                {
	                    return Attendance.getPermissions().$promise;
	                }],                	
                	pageConfig: ['$injector', function($injector)
                    {
                    	return new PageConfig({
                    		pageMode: "READONLY"
                    	})
                    }],
                    account: ['Principal', function(Principal)
                    {
                        return Principal.identity();
                    }],
                    entity: ['Attendance', function(Attendance)
                    {
                    	return Attendance.get({id: p_param.attendanceId}).$promise;
                    }]                    
                }
            }).result.then(function()
            {
            	if(p_param.callback)
            	{
            		p_param.callback();
            	}
            }, function()
            {
            	if(p_param.cancelCallback)
            	{
            		p_param.cancelCallback();
            	}
            });
		};
		
	};

})();
