(function() {
    'use strict';

    angular
        .module('sgmApp')
        .directive('formFieldValidate', formFieldValidateDirective);
    
    formFieldValidateDirective.$inject = ['FormFieldValidate'];
    function formFieldValidateDirective(FormFieldValidate)
    {
    	return {
    		require: ['ngModel', '^form'],
    		link: function(scope, element, attr, mCtrl)
    		{
    			var NgModelController = mCtrl[0];
    			var FormController = mCtrl[1];
    			if(FormController&& FormController.addFormValidate)
    			{
    				FormController.addFormValidate(attr.name, NgModelController);
    			}
	    	}
    	};
    };
    
    angular
    .module('sgmApp')
    .directive('formValidateForm', formValidateFormDirective);

	formValidateFormDirective.$inject = ['FormFieldValidate'];
	function formValidateFormDirective(FormFieldValidate)
	{
		return {
			require: ['^form'],
			link: function(scope, element, attr, mCtrl)
			{
				var randomId = UcUtil.randomString(10);
	            element.attr("id", "f" + randomId);
	
	            var FormController = mCtrl[0];
	            FormController.ffvId = "f" + randomId
	            
				if(FormController)
				{
					FormController.addFormValidate = function(p_field, NgModelController)
					{
						var v_formTarget = attr["formValidateForm"];
						var myValidation = function(value)
						{
							if(FormFieldValidate.hasValidation(v_formTarget, p_field))
							{
								return FormFieldValidate._directiveBind(value, NgModelController, v_formTarget, p_field);
							}
							return value;
						}
						myValidation.ucFormValidation = true;
						NgModelController.$parsers.push(myValidation);
					};
				}
	    	}
		};
	
	};    
    
})();
