(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceController', AttendanceController);

    AttendanceController.$inject = ['$scope', 'FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Attendance', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'attendanceStatusList', '$state', 'SalesRequestModalInvoker', 'familyProcedureList', 'ClientCommon', 'DownloadUtil', 'TabStatePersister', 'Principal', '$uibModal', 'uiCalendarConfig', 'ScheduleAttendanceDialogInvoker', '$timeout', 'AgendaLockingDialogInvoker', 'PermissionSet', 'FormStatePersister', 'requestTypeList', 'AgendaReminderDialogInvoker', '$injector', 'AttendanceStatusSelectorModalInvoker', 'UcAlertService', 'AttendanceFreeScheduleDialogInvoker', 'attendanceProductStatusList', '$sce', 'FormDomain', 'SalesRequestInvoiceDetailDialogInvoker', 'ConfirmDialogInvoker'];

    function AttendanceController($scope, FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, Attendance, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, attendanceStatusList, $state, SalesRequestModalInvoker, familyProcedureList, ClientCommon, DownloadUtil, TabStatePersister, Principal, $uibModal, uiCalendarConfig, ScheduleAttendanceDialogInvoker, $timeout, AgendaLockingDialogInvoker, PermissionSet, FormStatePersister, requestTypeList, AgendaReminderDialogInvoker, $injector, AttendanceStatusSelectorModalInvoker, UcAlertService, AttendanceFreeScheduleDialogInvoker, attendanceProductStatusList, $sce, FormDomain, SalesRequestInvoiceDetailDialogInvoker, ConfirmDialogInvoker)
    {
        var vm = this;

        $rootScope.initialFocus("attendanceId");

        $timeout(function(){
        	/*AttendanceWhatsappModalInvoker.invoke({
        		entity:
    			{
        			id: 26526,
	        		client: "João Sivla",
	        		clientId: -1,
	        		whatsappNumber: "31988490276"
    			},
        	})*/
//        	SalesRequestEditModalInvoker.invoke({
//        		attendanceId: entity.id,
//        		salesRequestId: entity.salesRequest.id,
//        		callback: function() { $state.reload(); }
//        	});
        }, 10);


        $timeout(function(){
//        	doCreate();
//        	AgendaLockingDialogInvoker.invoke({});
//        	AttendanceStatusSelectorModalInvoker.invoke({attendanceId: 24458, callback: function() { doFilter(); }});
        	todaysAgenda(true);
        }, 500);

        vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;
		vm.listBlockSchededule = [];
		vm.listSchedulable = [];
		vm.showScheduling = false;
		vm.idResource = 0;
		vm.hasAttendancePermissions = Principal.hasAnyAuthority(objectKeysToArray(PermissionSet.ATTENDANCE));
        vm.buttonsModel =
        {
        	stateRoot: "attendance",
//        	onFilter: function() { doFilter(); },
//        	onClear: function() { doClear(); },
//        	onCreate: function() { doCreate(); },
        	beforeCreate: function() { return clearEditTabState(); },
        	useSuggestedButton: false,
        	extraButtonList: [
    		{
	        	order: 0,
	        	cssClass: 'btn btn-accent',
	        	cssClassIcon: 'glyphicon glyphicon-search',
	        	label: 'client.global.label.filter',
	        	type: 'button',
	        	isDisabled: function(){ return false;},
	        	isToAdd: function(){ return true;},
	        	click: function(){ doFilter(); }
        	},
        	{
        		order: 10,
	        	cssClass: 'btn btn-danger',
	        	cssClassIcon: 'fa fa-eraser',
	        	label: 'client.global.label.reset',
	        	type: 'button',
        		isDisabled: function(){ return false;},
        		isToAdd: function(){ return true;},
        		click: function(){ doClear(); }
        	},
        	{
	        	order: 12,
	        	cssClass: 'btn btn-accent agenda-walk-left',
	        	cssClassIcon: 'glyphicon glyphicon-chevron-left',
	        	type: 'button',
	        	isToAdd: function() { return true; },
	        	click: function() { walkDate(false); }
			},
	        {
	        	order: 14,
	        	cssClass: 'btn btn-accent',
	        	cssClassIcon: 'fa fa-calendar',
	        	label: 'client.page.attendance.todaysAgenda',
	        	type: 'button',
	        	isToAdd: function() { return true; },
	        	click: function() { todaysAgenda(false); }
			},
	        {
	        	order: 16,
	        	cssClass: 'btn btn-accent agenda-walk-right',
	        	cssClassIcon: 'glyphicon glyphicon-chevron-right',
	        	type: 'button',
	        	isToAdd: function() { return true; },
	        	click: function() { walkDate(true); }
			},
        	{
        		order: 50,
	        	cssClass: 'btn btn-primary',
	        	cssClassIcon: 'fa fa-calendar',
	        	label: 'client.global.label.seeSchedule',
	        	type: 'button',
        		isDisabled: function(){ return false;},
        		isToAdd: function(){ return true;},
        		click: function(){ doCreate(); }
        	},
        	{
        		order: 50,
        		cssClass: 'btn btn-primary',
        		cssClassIcon: 'fa fa-calendar',
        		label: 'client.global.label.search',
        		type: 'button',
        		isDisabled: function(){ return false;},
        		isToAdd: function(){ return vm.hasAttendancePermissions;},
        		click: function(){ doSearchAvailability(); }
        	},
	        {
	        	order: 90,
	        	cssClass: 'btn btn-primary',
	        	cssClassIcon: 'fa fa-ban',
	        	label: 'client.page.attendance.blockSchedule',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		return !Principal.hasAnyAuthority([PermissionSet.AGENDA.locking]);
	        	},
	        	isToAdd: function() { return true; },
	        	click: function()
	        	{
	        		AgendaLockingDialogInvoker.invoke({
	        			callback: function()
        				{
	        				grid.refresh();
        				}
	        		});
	        	}
	        },
	        {
	        	order: 91,
	        	cssClass: 'btn btn-primary',
	        	cssClassIcon: $rootScope.menuIcons.managers.agenda.agendaReminder,
	        	label: 'client.page.attendance.reminders',
	        	type: 'button',
	        	isToAdd: function() { return vm.hasAttendancePermissions; },
	        	click: function()
	        	{
	        		AgendaReminderDialogInvoker.invoke({
	        			callback: function()
        				{
	        				grid.refresh();
        				}
	        		});
	        	}
	        },
			{
	        	order: 92,
	        	cssClass: 'btn btn-success hide-mobile',
	        	cssClassIcon: 'fa fa-file-excel-o',
	        	label: 'client.global.label.generateCashFlowReport',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		return !Principal.hasAnyAuthority(['ROLE_ATENDIMENTO_RELATORIO_CAIXA', 'ROLE_ATENDIMENTO_ADMINISTRACAO']);
	        	},
	        	isToAdd: function() { return true; },
	        	click: function() { generateCashFlowReport(); }
	        },
	        {
	        	order: 93,
	        	cssClass: 'btn btn-success hide-mobile',
	        	cssClassIcon: 'fa fa-file-excel-o',
	        	label: 'client.global.label.generateAgendaReport',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		return !Principal.hasAnyAuthority(['ROLE_ATENDIMENTO_RELATORIO_AGENDA', 'ROLE_ATENDIMENTO_ADMINISTRACAO']);
	        	},
	        	isToAdd: function() { return true; },
	        	click: function() { generateAgendaReport(); }
	        },
    		{
	        	order: 94,
	        	cssClass: 'btn btn-success hide-mobile',
	        	cssClassIcon: 'fa fa-file-excel-o',
	        	label: 'client.global.label.generatePerformanceReport',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		return !Principal.hasAnyAuthority(['ROLE_ATENDIMENTO_RELATORIO_PERFORMANCE', 'ROLE_ATENDIMENTO_ADMINISTRACAO']);
	        	},
	        	isToAdd: function() { return true; },
	        	click: function() { generateReport(); }
	        },
	        {
	        	order: 95,
	        	cssClass: 'btn btn-success hide-mobile',
	        	cssClassIcon: 'fa fa-file-excel-o',
	        	label: 'client.global.label.generatePaymentReport',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		return !Principal.hasAnyAuthority(['ROLE_ATENDIMENTO_RELATORIO_PAGAMENTO', 'ROLE_ATENDIMENTO_ADMINISTRACAO']);
	        	},
	        	isToAdd: function() { return true; },
	        	click: function() { generatePaymentReport(); }
	        },
			{
	        	order: 96,
	        	cssClass: 'btn btn-primary hide-mobile',
	        	cssClassIcon: 'fa fa-sticky-note-o',
	        	label: 'client.page.attendance.exportMultipleInvoices',
	        	type: 'button',
	        	isToAdd: function() { return Principal.hasAnyAuthority([PermissionSet.SALES_REQUEST_INVOICE.access]); },
	        	click: function() { exportMultipleInvoices(); }
	        }
	        ]
        };
		var clickedRowNode;
        var familyProcedureFormField = new FormField({
       		cellCssClass: "col-sm-4",
       		type: "Select",
			id: "familyProcedureList",
			dependencyName: "familyProcedureListId",
			label: "client.page.attendance.familyProcedure",
			multiple: true,
			dataSource: FrontEndSelectDataSource.create(familyProcedureList)
    	});

        var formOptions = {
        	formStatePersisterId: "Attendance"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([new FormField({
		       		cellCssClass: "col-sm-3",
		       		type: "Date",
					id: "plannedDateStart",
					label: "client.page.attendance.plannedDateStart",
					jsonValTransform: function (p_json, p_model, p_val)
					{
						if(p_val)
						{
							// Primeira hota do dia
							p_val.setHours(0);
							p_val.setMinutes(0);
							p_val.setSeconds(0);
							p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
						}
					}
//					defaultValue: getFirstTimeDay()
	        	}),
	        	new FormField({
		       		cellCssClass: "col-sm-3",
		       		type: "Date",
					id: "plannedDateEnd",
					label: "client.page.attendance.plannedDateEnd",
					jsonValTransform: function (p_json, p_model, p_val)
					{
						if(p_val)
						{
							// Primeira hota do dia
							p_val.setHours(23);
							p_val.setMinutes(59);
							p_val.setSeconds(59);
							p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
						}
					}
//					defaultValue: getLastTimeDay()
	        	}),
	        	new FormField({
		       		cellCssClass: "col-sm-2 hide-mobile",
					type: "NumberRaw",
					id: "attendanceId",
					label: "common.domain.attendance.id"

	        	}),
				new FormField({
					cellCssClass: "col-sm-2 hide-mobile",
					type: "NumberRaw",
				 	id: "salesRequestId",
				 	label: "client.page.attendance.salesRequestSGR",
			 	}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Select",
					id: "attendanceTypeList",
					multiple: true,
					label: "client.page.attendance.type",
					style: "margin-right:10px",
					dataSource: FrontEndSelectDataSource.create(requestTypeList, {placeHolder: $translate.instant("client.page.attendance.attendanceLockingReminder")})
				}),
		        
		    ])
        	.add([familyProcedureFormField,
	        	new FormField({
		       		cellCssClass: "col-sm-4",
		       		type: "Select",
					id: "procedureList",
					label: "common.domain.salesRequest.procedure",
					multiple: true,
					dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, true)
	        	}),
	        	new FormField({
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "medicList",
					multiple: true,
					label: "client.page.attendance.professional",
					dataSource: FormConstants.DataSources.BackEnd.Professional(BackEndSelectDataSource, $translate)
		        }),
	        	
		    ])
		    .add([new FormField({
		       		cellCssClass: "col-sm-4",
					type: "SelectModal",
					id: "clientList",
					label: "common.domain.salesRequest.client",
					multiple: true,
					modalConfig: {
						bindLabel: "name",
						searchId: "Client"
					}
		        }),
		    	new FormField({
					cellCssClass: "col-sm-2",
					type: "Select",
					id: "confirmated",
					label: "client.page.attendance.confirmated",
					style: "margin-right:10px",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
				}),
		        new FormField({
					cellCssClass: "col-sm-2",
					type: "Select",
					id: "statusList",
					multiple: true,
					label: "client.page.attendance.attendanceStatus",
					dataSource: FrontEndSelectDataSource.create(attendanceStatusList)
			 	}),
	        	new FormField({
		       		cellCssClass: "col-sm-2 hide-mobile",
					type: "Select",
					id: "hasPendency",
					label: "client.page.attendance.pendencyCurrency",
					style: "margin-right:10px",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
	        	}),
				new FormField({
					cellCssClass: "col-sm-2 hide-mobile",
					type: "Select",
					id: "attendanceProductStatus",
					multiple: true,
					label: "client.page.attendance.attendanceProductStatusFilter",
					dataSource: FrontEndSelectDataSource.create(attendanceProductStatusList)
			 	})
		    ])
			.endConfig();

        vm.gridFieldList = UcGridService.createFieldList([
        	UcGridService.field.AVATAR(function(p_param)
        	{
        		if(p_param && p_param.dataType == "LOCKING")
        		{
        			return '<div class="no-user-avatar"><i class="fa fa-ban" aria-hidden="true"></i></div>';
        		}
        		else if(p_param && p_param.dataType == "REMINDER")
        		{
        			return '<div class="no-user-avatar"><i class="fa fa-bell-o" aria-hidden="true"></i></div>';
        		}
        		else
        		{
        			return "";
        		}
        	}),
        	{headerName: $translate.instant("client.page.attendance.idSalesRequestSGR"), field: "salesRequestId", cellStyle: {lineHeight: '25px', alignItems: "center", textAlign: "center"}, width: 3, cellRenderer: function(p_param) { return renderSalesRequestIdGridCell(p_param); }},
        	{headerName: $translate.instant("client.page.attendance.plannedDate"), field: "plannedDate", cellStyle: {alignItems: "center", textAlign: "center"}, width: 12, cellRenderer: function(p_param) { return renderPlannedDateGridCell(p_param); }},
        	{headerName: $translate.instant("client.page.attendance.status"), field: "status", cellStyle: {alignItems: "center", textAlign: "center"}, width: 12, cellRenderer: function(p_param) { return renderPillRendererGridCell(p_param); },
        		onCellClicked: function(p_params)
        		{
        			if(p_params.data && p_params.data.dataType == "ATTENDANCE")
        			{
        				AttendanceStatusSelectorModalInvoker.invoke({
        					attendanceId: p_params.data.id,
        					callback: function() {
        						doFilter();
        					}
        				});
        			}
        		}
        	},
			{headerName: $translate.instant("client.page.attendance.client"), field: "client", width: 18},
        	{headerName: $translate.instant("client.page.attendance.procedure"), field: "procedure", width: 18},
        	{headerName: $translate.instant("client.page.attendance.medic"), field: "medic", width: 18},
        	{headerName: $translate.instant("client.page.attendance.sessionsDetails"), field: "sessionsDetails", cellStyle: {alignItems: "center", textAlign: "center"}, suppressSorting: true, width: 3, cellRenderer: function(p_param) { return renderSessionsDetailsGridCell(p_param); }},
        	{headerName: $translate.instant("client.page.attendance.conf"), field: "confirmatedDetails", cellStyle: {alignItems: "center", textAlign: "center"}, suppressSorting: true, width: 3, suppressMenu: true,
        		cellRenderer: function(p_param)
        		{
        			return safeExec(function()
        			{
        				return "<span stop-propagation='true' id='attendance-confirmated-flag-" + p_param.data.id + "'>" + renderConfirmatedFlagGridCell(p_param) + "</span>";
        			});
        		},
        		onCellClicked: function(p_params)
                {
        			if(p_params.data && p_params.data.dataType == "ATTENDANCE" && p_params.data.status && p_params.data.status.canConfirmation)
        			{
        				$scope.$emit("hint-attendance-confirmation.invoke", {entity: p_params.data, event: p_params.event});
        			}
        			else
        			{
        				$scope.$emit("hint-attendance-confirmation.close");
        			}
                }
        	},
			{headerName: $translate.instant("client.page.attendance.attendanceProductStatus"), field: "totalProductsCost", cellStyle: {alignItems: "center", textAlign: "center"}, width: 9, cellRenderer: function(p_param) { return renderStatusAttendanceProduct(p_param) }, },
        	ClientCommon.createOpenModalGridIcon({
        		field: "clientId",
        		icon: "fa fa-user",
        		hint: "client.page.attendance.clientDetails",
				account: account,
				blinkIcon: function (p_params) {
					if (p_params.data && p_params.data.hasSpecialNeeds) {
						return true;
					}
					return false;
				},
        		callback: function()
            	{
            		grid.refresh();
            	}
        	})
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "ATTENDANCE",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "attendance",
        	account: account,
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList,
        	rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
        	sort: vm.sort,
        	onRowClicked: function(p_params)
        	{
				clickedRowNode = p_params;
        		// Possui stop-propagation
        		if(hasStopPropagation(p_params.event))
        		{
        			return;
        		}

        		if(p_params.data && p_params.data.dataType == "LOCKING")
        		{
        			var lockingId = p_params.data.id.replace("LOCKING-", "");
        			AgendaLockingDialogInvoker.invoke({
        				agendaLockingTimeId: lockingId,
        				callback: function()
        				{
        					grid.refresh();
        				}
        			});
        		}
        		else if(p_params.data && p_params.data.dataType == "REMINDER")
        		{
        			var reminderId = p_params.data.id.replace("REMINDER-", "");
        			AgendaReminderDialogInvoker.invoke({
        				id: reminderId,
        				callback: function()
        				{
        					grid.refresh();
        				}
        			});
        		}
        		else
        		{
        			$state.go('attendance.detail', {id: p_params.data.id});
        		}
        	},
        	beforeOnRowClicked: function() { return clearEditTabState(); },
        	getRowClass: function(p_params)
        	{
        	    if(p_params.data && p_params.data.dataType == "LOCKING")
        	    {
        	        return "grid-attendance-row-locking";
        	    }
        	    else if(p_params.data && p_params.data.dataType == "REMINDER")
        	    {
        	        return "grid-attendance-row-reminder";
        	    }
        	}
        });

        vm.gridOptions = grid.getGridOptions();


        /*Valida se há data de agendamento informada e se o perído <= a 1 ano! Se não for, informa o usuário */
        /*private*/ function validatePerformanceReportFilter(data){
        	if(data && data['plannedDateStart']){
        		var beginDate = moment(data['plannedDateStart'], 'YYYY-MM-DD HH:mm:ss');
        		var endDate = moment().hours(23).minutes(59);
        		if(data['plannedDateEnd']){
        			endDate = moment(data['plannedDateEnd'], 'YYYY-MM-DD HH:mm:ss');
        		}
        		if(endDate.diff(beginDate, 'days') >= 365){
        			return false;
        		}
        		return true;
        	}
        	return false;
        }


        /*private*/ function generateReport()
        {
        	var data = vm.formHandler.readForm();

        	if(!validatePerformanceReportFilter(data)){
        		UcAlertService.message($translate.instant("client.page.attendance.performanceReportPlannedDateValidationErrorMessage"), "WARNING", "BOX");
        		return;
        	}

        	delete data["clientList"];
        	delete data["familyProcedureList"];
        	delete data["medicList"];
        	delete data["procedureList"];
        	delete data["salesRequest"];
       		delete data["statusList"];
       		delete data["attendanceTypeList"];

       		data.generateReport = true;

        	DownloadUtil.download('api/attendance/generate-performance-report', data);
        };


        /*private*/ function generateCashFlowReport()
        {
        	UcAlertService.dismissBox();
			$uibModal.open({
                templateUrl: 'app/pages/managers/attendance/report/cash-flow/cash-flow-report-dialog.html',
                controller: 'CashFlowReportDialogController',
                controllerAs: 'vm',
				resolve: {}
            });
        };

        /*private*/ function generatePaymentReport()
        {
        	UcAlertService.dismissBox();
			$uibModal.open({
                templateUrl: 'app/pages/managers/attendance/report/payment/payment-report-dialog.html',
                controller: 'PaymentReportDialogController',
                controllerAs: 'vm',
				resolve: {}
            });
        };

        /*private*/ function generateAgendaReport()
        {
        	UcAlertService.dismissBox();
			$uibModal.open({
                templateUrl: 'app/pages/managers/attendance/report/agenda/agenda-report-dialog.html',
                controller: 'AgendaReportDialogController',
                controllerAs: 'vm',
				resolve:
				{
					schedulableList: ['Attendance', function(Attendance)
                    {
                    	return Attendance.listSchedulable({}).$promise;
                    }]
				}
            });
        };

		function exportMultipleInvoices() {
			var searchModalConfig =
			{
				bindLabel: "name",
				searchId: "Client",
				multiple: true,
				closeAndReturn: function(selectedMap, keys) {
					var obj;
					var list = [];
					for (var i = 0; i < keys.length; i++)
					{
						obj = selectedMap[keys[i]];
						console.log(obj);
						list.push(obj.item);
					}
					return list;
				}
			};

			$uibModal.open({
				templateUrl: 'app/components/search-modal/search-modal-dialog.html',
				controller: 'SearchModalController',
				controllerAs: 'vm',
				scope: $scope,
				size: 'lg',
				windowClass: 'modal-dialog-90-percent',
				resolve: {
					account: ['Principal', function(Principal) {
						return Principal.identity();
					}],
					searchModalConfig: () => searchModalConfig,
				}
			}).result.then(function(clientList) {
				console.log(clientList);
				var selectedSalesRequests = {};
				if(clientList.length > 0);
					selectSalesRequestsForInvoice(0, clientList, selectedSalesRequests);
			}, function() {
				/*void*/
			});
		}


		function selectSalesRequestsForInvoice(i, clientList, selectedSalesRequests) {
			var client = clientList[i];
			var familyProcedureFormField = new FormField({
				cellCssClass: "col-sm-3",
				type: "Select",
				id: "familyProcedure",
				dependencyName: "familyProcedureListId",
				label: "common.domain.salesRequest.familyProcedure",
				multiple: true,
				dataSource: FormConstants.DataSources.BackEnd.FamilyProcedure(BackEndSelectDataSource, $translate),
		 	});

		 	var procedureFormField = new FormField({
				cellCssClass: "col-sm-3",
				type: "Select",
				id: "procedure",
				label: "common.domain.salesRequest.procedure",
				multiple: true,
				searchActiveOnly: true,
				dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, true, true)
		 	});
			var searchModalConfig =
			{
				bindLabel: "id",
				searchId: "SalesRequest",
				gridId: "SALES_REQUEST_MODAL",
				gridWrapperId: "SALES_REQUEST_MODAL_INVOICE",
				multiple: true,
				customIcon: 'fa fa-users',
				criteria: {
					clientListId: client.id,
					enableFetch: true
				},
				hideSearch: true,
				customTitle: $translate.instant("common.domain.salesRequest.client")+" " + i + ": ID " + client.id,
				customToggleRowCondition: function(item) {
					return item.hasActiveInvoice != "VALID" && item.hasActiveInvoice != "FREE" && !item.hasOnlyCanceledAttendances;
				},
				extraFieldRenderer: function() {
					return $sce.trustAsHtml("<div style='margin: 0px 20px;'><div style='color: white'><b>"+$translate.instant("client.page.attendance.multipleInvoiceModal.salesRequest.name")+":&nbsp;</b><span>"+client.name+"&nbsp;|&nbsp;</span><b>CPF:&nbsp;</b> " + (client.cpf ? client.cpf : $translate.instant("client.global.label.notInformed")) + "</div>"
					+ "<div style='margin-top: 10px;'>"+$translate.instant("client.page.attendance.multipleInvoiceModal.salesRequest.subtitle")+"</div></div>");
				},
				formHandler: FormFilter.create({})
					.add([new FormField({
						cellCssClass: "col-sm-3",
						type: "NumberRaw",
						id: "salesRequestId",
						label: "client.global.label.id"
					}),
					new FormField({
						cellCssClass: "col-sm-3",
						type: "NumberRaw",
						id: "attendanceId",
						label: "common.domain.salesRequest.attendancesId"
					}),
					familyProcedureFormField,
					procedureFormField
				]),
				hideClearButton: true,
				fieldList: [
					{headerName: $translate.instant("common.domain.salesRequest.salesRequest"), field: "id", cellStyle: {alignItems: "center", textAlign: "center"}, width: 10},
					{headerName: $translate.instant("common.domain.salesRequest.dateFirstAttendance"), field: "dateFirstAttendance", cellStyle: {alignItems: "center", textAlign: "center"}, width: 15},
					{headerName: $translate.instant("common.domain.salesRequest.attendancesId"), field: "attendancesId", cellStyle: {alignItems: "center", textAlign: "center"}, width: 30,
						cellRenderer: function(p_param) {
							let str = "";
							if(p_param.data && p_param.data['attendancesId']) {
								var arr = p_param.data['attendancesId'];
								var length = arr.length;
								for(var i = 0; i < length; i++) {
									str += "<span>" + arr[i];
									if(i < length - 1) {
										str += " |&nbsp;";
									}
									str += "</span>";
								}
							}
							return  "<div style='display: flex; align-items: center;flex-wrap: wrap; font-size: 10pt;'>" + str +"</div>";
						}
					},
					{headerName: $translate.instant("common.domain.salesRequest.procedure"), field: "procedure", width: 20},
					{headerName: $translate.instant("common.domain.salesRequest.familyProcedure"), field: "familyProcedure", width: 20},
					{headerName: $translate.instant("common.domain.salesRequest.hasActiveInvoice"), field: "hasActiveInvoice", cellStyle: {alignItems: "center", textAlign: "center"}, width: 30,
						cellRenderer: function(p_param) {
							var cssClass = "";
							var label = "";
							if(p_param.data) {
								var hasActiveInvoice = p_param.data['hasActiveInvoice'];
								if(hasActiveInvoice == "NOT_VALID") {
									cssClass = 'uc-pills-red';
								} else if(hasActiveInvoice == "VALID") {
									cssClass = 'uc-pills-green';
								} else if(hasActiveInvoice == "MANUAL") {
									cssClass = 'uc-pills-yellow';
								} else if(hasActiveInvoice == "FREE") {
									cssClass = 'uc-pills-gray';
								}
								label = $translate.instant("client.page.attendance.multipleInvoiceModal.salesRequest.hasActiveInvoiceType." + hasActiveInvoice);
								var pillRender = {
									value: {
										label,
										cssClass
									}
								}
								return "<div stop-propagation='true' style='height:100%;background-color:transparent'>" + PillRenderer.renderer(pillRender) + "</div>";
							}
							return "";
						}
					},
					UcGridService.field.BOOLEAN({field: "hasOnlyCanceledAttendances", title: "common.domain.salesRequest.hasOnlyCanceledAttendances", width: 5, suppressSizeToFit: false})
				],
				closeAndReturn: function(selectedMap, keys) {
					var obj;
					var list = [];
					for (var i = 0; i < keys.length; i++)
					{
						obj = selectedMap[keys[i]];
						list.push(obj.item);
					}
					return list;
				}
			};
			if(i > 0) {
				searchModalConfig.extraButtons = [{
					buttonClass: "btn btn-info",
					iconClass: "glyphicon glyphicon-arrow-left",
					label: 'client.global.label.back',
					callback: function($uibModalInstance)
					{
						selectSalesRequestsForInvoice(--i, clientList, selectedSalesRequests);
						$uibModalInstance.dismiss();
					}
				}];
			}
			if(selectedSalesRequests[client.id]) {
				searchModalConfig.currentValue = selectedSalesRequests[client.id];
			}

			$uibModal.open({
				templateUrl: 'app/components/search-modal/search-modal-dialog.html',
				controller: 'SearchModalController',
				controllerAs: 'vm',
				scope: $scope,
				size: 'lg',
				windowClass: 'modal-dialog-90-percent',
				resolve: {
					account: ['Principal', function(Principal) {
						return Principal.identity();
					}],
					searchModalConfig: () => searchModalConfig,
				}
			}).result.then(function(salesRequests) {
				selectedSalesRequests[client.id] = salesRequests;
				if(salesRequests.findIndex(obj => obj.hasActiveInvoice == 'MANUAL') > -1) {
					ConfirmDialogInvoker.invoke(
						{
							callback: function(res) {
								i++;
								if(i < clientList.length) {
									selectSalesRequestsForInvoice(i, clientList, selectedSalesRequests);
								} else {
									selectSalesRequestMainClientForInvoice(selectedSalesRequests, clientList);
								}
							},
							dismiss: function() {
								console.log("lol");
								selectSalesRequestsForInvoice(i, clientList, selectedSalesRequests);
							},
							text: $translate.instant("client.page.attendance.tabs.invoice.createNfseWarn")
						}
					);
				} else {
					i++;
					if(i < clientList.length) {
						selectSalesRequestsForInvoice(i, clientList, selectedSalesRequests);
					} else {
						selectSalesRequestMainClientForInvoice(selectedSalesRequests, clientList);
					}
				}
			}, function() {
				/*void*/
			});
		}

		function selectSalesRequestMainClientForInvoice(selectedSalesRequests, clientList) {


			$uibModal.open({
				templateUrl: 'app/common/page-domain/page-domain.html',
				controller: ['$scope', '$uibModalInstance', 'clientList', 'FormDomain', function($scope, $uibModalInstance, clientList, FormDomain) {
					var vm = this;
					vm.domain = {};
					vm.buttonsModel =
					{
						useSuggestedButton: false,
						extraButtonList: [
							{
								order: 1,
								cssClass: 'btn btn-primary',
								cssClassIcon: 'glyphicon glyphicon-chevron-right',
								type: 'button',
								label: 'client.global.label.confirm',
								isToAdd: function () { return true },
								click: function() {
									vm.save();
								 }
							},
							{
								order: 100,
								cssClass: 'btn btn-accent',
								cssClassIcon: 'fa fa-times',
								label: 'client.global.label.cancel',
								type: 'button',
								isToAdd: function () { return true },
								click: function () {
									$uibModalInstance.dismiss()
								},
							}
						]
					};
					vm.pageConfig = {
						showSmallHeader: true,
						title: 'Cliente - NFSe',
	                	icon: 'fa fa-sticky-note-o',
						formHeader: "Selecione para qual cliente a NFS-e deve ser emitida:"
					};
					var formOptions = {
						model: vm.domain,
						pageConfig: vm.pageConfig,
						buttonsModel: vm.buttonsModel,
						getForm: function(){ return $scope.editForm; }
					};
					vm.formHandler = FormDomain.create(formOptions)
						.add([
							new FormField({
								cellCssClass: "col-sm-4",
								type: "Select",
								required: true,
								dataSource: FrontEndSelectDataSource.create(clientList),
								id: "client",
								noLabel: true
							}),
						])
						.endConfig();

					/*public*/ vm.save = function()
					{
						var entity = vm.formHandler.readForm();
						if(entity.client) {
							$uibModalInstance.close(entity.client);
						}

					};
				}],
				controllerAs: 'vm',
				scope: $scope,
				size: 'md',
				windowClass: 'modal-dialog-90-percent',
				resolve: {
					clientList: () => clientList,
					FormDomain: () => FormDomain
				}
			}).result.then(function(selectedClient) {
				openInvoiceModal(selectedClient, selectedSalesRequests);
			});
		}

		function openInvoiceModal(selectedClient, selectedSalesRequests) {
			var salesRequestIdFk = [];
			var totalValue = 0;
			Object.entries(selectedSalesRequests).forEach(([key, value]) => {
				value.forEach((request) => {
					salesRequestIdFk.push(request.id);
					totalValue += request.totalValue;
				});
			});
			console.log(selectedClient);
			var invoice = {
				manual: false,
				clientName: selectedClient.name,
				clientCpf: selectedClient.cpf,
				clientAddressCep: selectedClient.zipCode,
				clientAddressNumber: selectedClient.addressNumber,
				clientAddressComplement: selectedClient.addressComplement,
				salesRequestIdFk: salesRequestIdFk,
				totalValue: totalValue,
				obs: "Requisições: " + salesRequestIdFk,
				invoiceDetail: `ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS, EXCETO MATERNIDADES
				DR JOSE ROGERIO REGIS JUNIOR CPF: 004.797.696-99 CRM-35060
				DR LUCIANA ALBERGARIA IAMIM REGIS CPF: 091.360.837-80 CRM-35012
				DR RUBEM MATEUS CAMPOS MIRANDA CPF: 010.757.966-95 CRM-37674
				DR MARCOS FELIPE FONSECA ALVES CPF: 049.874.846-41 CRM-42466
				DR ISABELA VIEIRA GOMIDE CPF: 013.568.316-56 CRM-47633
				DR CAROLINA VIEIRA ALVES RESENDE LAMOUNIER CPF: 012.630.046-10 CRM-55045          
				DR MARINA RODRIGUES COSTA LAGES CPF: 072.885.376-09 CRM-68372
				DR JULIA LAGES MOTTA HERMONT CPF: 063.071.096-10 CRM-80388
				DR GUILHERME HENRIQUE SILVEIRA TEIXEIRA CPF: 015.635.476-43 CRM-68240

				Valor Aproximado dos Tributos: 11,33%`,
			};
			SalesRequestInvoiceDetailDialogInvoker.invoke(
				{
					entity: invoice,
					callback: () => {},
					hasCreatePermission: true
				}
			);
		}


        /*private*/ function doFilter()
        {
        	UcAlertService.dismissBox();
        	grid.refresh();
        };

        /*private*/ function doSearchAvailability(){
        	var objData = {
    				resource: null,
    				startDate: null
                }

        	AttendanceFreeScheduleDialogInvoker.invoke(new ScheduleAttendanceDialogInvokerParam({
				objData: objData,
				hasAttendancePermissions: vm.hasAttendancePermissions,
				callback: function(p_)
				{
					if(p_ && p_.attendanceInput)
					{
						var attendanceInput = cloneObj(p_.attendanceInput);

						$rootScope.confirm(
						{
							message: $translate.instant("client.page.attendance.useExistingSalesRequest"),
							callback: function() // Yes
							{
								var filter = vm.formHandler.getModel();
								SalesRequestModalInvoker.invoke({
									clientList: filter["clientList"],
									procedureList: filter["procedureList"],
									attendanceInput: p_.attendanceInput,
									showGoWithoutSelection: true,
									onlyOpen: true,
									selectCallback: function(p_salesRequest)
									{
										attendanceInput.salesRequestId = (p_salesRequest) ? p_salesRequest.id : null;
										attendanceInput.selectSalesRequestOnCreate = true;
						            	$state.go('attendance.new', attendanceInput);
									}
								});
							},
							noCallback: function() // No
							{
								attendanceInput.salesRequest = null;
								$state.go('attendance.new', attendanceInput);
							}
						});
					}
				}
			}));
        };

        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};

        /*private*/ function doCreate()
		{
			var objData = {
				resource: null,
				startDate: null
            }

			ScheduleAttendanceDialogInvoker.invoke(new ScheduleAttendanceDialogInvokerParam({
				objData: objData,
				hasAttendancePermissions: vm.hasAttendancePermissions,
				callback: function(p_)
				{
					if(p_ && p_.attendanceInput)
					{
						var attendanceInput = cloneObj(p_.attendanceInput);

						$rootScope.confirm(
						{
							message: $translate.instant("client.page.attendance.useExistingSalesRequest"),
							callback: function() // Yes
							{
								var filter = vm.formHandler.getModel();
								SalesRequestModalInvoker.invoke({
									clientList: filter["clientList"],
									procedureList: filter["procedureList"],
									attendanceInput: p_.attendanceInput,
									showGoWithoutSelection: true,
									onlyOpen: true,
									selectCallback: function(p_salesRequest)
									{
										attendanceInput.salesRequestId = (p_salesRequest) ? p_salesRequest.id : null;
										attendanceInput.selectSalesRequestOnCreate = true;
						            	$state.go('attendance.new', attendanceInput);
									}
								});
							},
							noCallback: function() // No
							{
								attendanceInput.salesRequest = null;
								$state.go('attendance.new', attendanceInput);
							}
						});
					}
				}
			}));
		};

		/*private*/ function hasStopPropagation(p_event)
		{
			var targetObj = $(p_event.target);
			if(targetObj && (targetObj.attr("stop-propagation") || targetObj.closest("[stop-propagation='true']").length))
			{
				return true;
			}
			else
			{
				return false;
			}
		}

		/*
		 * Limpa o tab da página de edição
		 */
		/*private*/ function clearEditTabState()
		{
			TabStatePersister.reset("AttendanceDomainController");

			// Retorna true para não interromper o fluxo da operação
			return true;
		};

		/**
		 * Seta o formulário somente com a agenda de hoje
		 */
		/*private*/ function todaysAgenda(p_pageLoad)
		{
			// No p_pageLoad, só execura uma vez por sessão
			if(p_pageLoad)
			{
				var pageLoadState = FormStatePersister.get("TodaysAgendaLoad", {executed: false});
				if(pageLoadState.executed)
				{
					return;
				}
				pageLoadState.executed = true;
			}

			vm.formHandler.clear();
			vm.gridOptions.showBlockSchedule = false;

			Attendance.getTodayAgendaConfig({}, function(p_filterConfig)
			{
				var model = vm.formHandler.getModel();
				model.medicList = p_filterConfig.medicList;
				model.familyProcedureList = p_filterConfig.familyProcedureList;
				model.statusList = p_filterConfig.attendanceStatusList;
				model.attendanceTypeList = p_filterConfig.attendanceTypeList;

				var startDate = moment(p_filterConfig.startDateText, "YYYY-MM-DD")._d;
				var endDate = moment(p_filterConfig.endDateText, "YYYY-MM-DD")._d;
				model.plannedDateStart = startDate;
				model.plannedDateEnd = endDate;
	        	//$rootScope.$broadcast('form.setValue.plannedDateStart', startDate);
	        	//$rootScope.$broadcast('form.setValue.plannedDateEnd', endDate);

	        	if(!p_pageLoad)
	        	{
	        		doFilter();
	        	}
			});
		};

		/**
		 * Altera as datas sempre em um dia
		 */
		/*private*/ function walkDate(p_plus)
		{
			var model = vm.formHandler.getModel()
			model.plannedDateStart = doWalkDate(model.plannedDateStart, p_plus);
			model.plannedDateEnd = doWalkDate(model.plannedDateEnd, p_plus);

			doFilter();
		}

		/*private*/ function doWalkDate(p_date, p_plus)
		{
			if(p_date)
			{
				return moment(p_date).add(p_plus ? 1 : -1, 'days')._d;
			}
			else
			{
				return p_date;
			}
		}

		/*private*/ function renderSalesRequestIdGridCell(p_param)
		{
			var code = "";
			if(p_param.data && p_param.data.salesRequestId)
			{
				code = "<span>"+p_param.data.salesRequestId+"</span>";
			}

			// Tem pendência de pagamento
			
			if(p_param.data && p_param.data.hasPaymentPending)
			{
				code += '<br/><span class="uc-pills uc-pills-red uc-pills-small" style="cursor:default" title="' + $translate.instant("client.page.attendance.thereArePaymentPendenciesWithThisAttendance") + '">$ ' + $translate.instant("client.global.label.pendency") + '</span>';
			}

			return code;
		}

		/*private*/ function renderSessionsDetailsGridCell(p_param)
		{
			var code = "";
			if(p_param.data)
			{
				code = p_param.data.sessionsDetails;
				if(p_param.data && p_param.data.complementaryFlag)
				{
					code = "<div class='sessions-details-grid-cell'><div>" + code + "</div><div>" + p_param.data.complementaryFlag.pill + "</div>";
				}
			}
			return code;
		}

		/*private*/ function renderConfirmatedFlagGridCell(p_param)
		{
			var code = "";
			if(p_param.data && p_param.data.confirmationStatus)
			{
				var icon = "https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/icons/" + p_param.data.confirmationStatus.icon;
				var hint = p_param.data.confirmationStatus.hint;
				if(p_param.data.confirmationStatus.id == 'SRE' && p_param.data.hasCancelReason) {
					hint += " (Cliente)";
				}

				code = '<a><img src="' + icon + '" title="' + hint + '" class="icon-22"></a>';
			}
			return code;
		}

		/*private*/ function renderPlannedDateGridCell(p_param)
		{
			var code = "";
			if(p_param.data)
			{
				
				if(p_param.data.dataType == "LOCKING") {
					code = "<div>"+p_param.data.plannedDate + "</div>\n<div>" + p_param.data.plannedDateEnd +"</div>";
				} else {
					code = p_param.data.plannedDateFull;
					if(code) {
						code = $.trim(code).replace(" ", "</div><div>")
					}
				}
				if(code)
				{
					code = "<div class='planned-date-grid-cell'><div>" + code + "</div></div>";
				}
				
			}
			return code;
		}

		/*private*/ function renderPillRendererGridCell(p_param, doNotIncludeStatusDiv)
		{
			var html = doNotIncludeStatusDiv || !p_param.data ? "" : "<div id='status-pill-" + p_param.data.id + "'>";
			html +="<div stop-propagation='true' style='height:100%;background-color:transparent'>"
			if(p_param.data && p_param.data.dataType == "LOCKING")
			{
				html += "<span class='uc-pills " + 'uc-pills-red' + "'>" + $translate.instant("client.global.label.locking") + "</span>";
			}
			else if(p_param.data && p_param.data.dataType == "REMINDER")
			{
				html += "<span class='uc-pills " + 'uc-pills-blue' + "'>" + $translate.instant("client.global.label.reminder") + "</span>";
			}
			else
			{
				html += PillRenderer.renderer(p_param.data ? {value: (p_param.data.status)} : null);
			}
			html += "</div>"
			html += doNotIncludeStatusDiv ? "" : "<div>";
			return html;
		}



		/*private*/ function renderStatusAttendanceProduct(p_param)
		{
			var pillRender;
			if(p_param.data && p_param.data.dataType == "ATTENDANCE") {
				var cssClass;
				var label;
				if(p_param.data.totalProductsCost != null) {
					label = $translate.instant("common.domain.attendanceProductStatus.COMPLETED");
					cssClass = 'uc-pills-green';
				} else {
					label = $translate.instant("common.domain.attendanceProductStatus.IN_PROGRESS");
					cssClass = 'uc-pills-yellow';
				}
				pillRender = {
					value: {
						label,
						cssClass
					}
				}
			}
			return "<div stop-propagation='true' style='background-color:transparent'>" + PillRenderer.renderer(pillRender) + "</div>";
		}

		$scope.$on('$destroy', function()
		{
			$scope.$emit("hint-attendance-confirmation.close");
		});

		$rootScope.$on('reload-clicked-attendance-row', function()
		{
			console.log(clickedRowNode.data);
			$("#status-pill-" + clickedRowNode.data.id).html(renderPillRendererGridCell(clickedRowNode, true))
		});

		// Seta o hint dos botões de walk da agenda
        $timeout(function()
        {
        	$(".agenda-walk-left").attr("title", $translate.instant("client.page.attendance.minus1Day"));
        	$(".agenda-walk-right").attr("title", $translate.instant("client.page.attendance.plus1Day"));
        }, 500);

	}
})();
