'use strict';

/**
 * Json de configuração para o rrenderizador de HTML
 * 
 *  IMPORTANTE: Toda nova configuração DEVE ser criada aqui com uma descrição 
 */
var _DEFAULT_PAGE_CONFIG =
{
	// REQUIRED
	id: null,						// Id do  div wrapper do componente
	cellCssClass: null,				// String cellCssClass - classe css do div wrapper do componente 
	hasLabel: false,				// Indica se deve renderizar o label
	compile: false,					// Se o código precisa ser compilado: possui aluma função js. Exemplo: ng-click
	render: null,					// Função que renderiza o HTML
									// -- Parâmetros (p_objModel, p_fieldModel, p_pageMode, p_editMode)
	
	// Opcionais
	label: null,					// Label no formato de chave i18n
	formTarget: null,				// Formulário "target" do campo. Se esse atributo for preenchido, o componente só será adicionado no formulário que tiver este form-id. 
									// Util quando houver mais de um form-dynamic na página. Exemplo: página com abas 
	
	// [function]
	addFieldListener: null,			// Checa se o componente deve ou não ser adicionado no formulário	
};

function FormHtmlRender(p_config)
{
	// Carrega os valores default
	angular.extend(this, _DEFAULT_PAGE_CONFIG);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_config) ? p_config : {});
	
	this.isField = false;
};