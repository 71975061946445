(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('ManufacturerProductStockFlowDialogInvoker', ManufacturerProductStockFlowDialogInvoker);

    ManufacturerProductStockFlowDialogInvoker.$inject = ['$uibModal', 'ManufacturerProductStockFlow'];

    function ManufacturerProductStockFlowDialogInvoker($uibModal, ManufacturerProductStockFlow)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/manufacturer-product-stock-flow/dialog/manufacturer-product-stock-flow-dialog.html',
				controller: 'ManufacturerProductStockFlowDialogController',
				controllerAs: 'vm',
				backdrop: 'static',
				size: 'lg',
				resolve: {
					pageConfig: [function()
					{
						return {
							formValidateTarget: 'ManufacturerProductStockFlow',
							pageMode: p_param.isReadonly ? "READONLY" : "CREATE"
						};
					}],
					entity: ['ManufacturerProductStockFlow', function(ManufacturerProductStockFlow)
					{
						return p_param.entityId ? ManufacturerProductStockFlow.get({id : p_param.entityId}).$promise : (p_param.entity || {});
					}],
					isInput: p_param.isInput,
                }
			}).result.then(function(stockFlow)
			{
				if(p_param.callback)
				{
					p_param.callback(stockFlow, p_param.isInput);
				} else {
					if(stockFlow) {
						if(p_param.isInput) {
							ManufacturerProductStockFlow.createInStockFlow(stockFlow);
						} else {
							ManufacturerProductStockFlow.createOutStockFlow(stockFlow);
						}
					}
				}
			}, function() {
				/*void*/
			});
		}
    }
})();