(function(){
	'use strict';
	angular.module('sgmApp').controller('SalesRequestInvoiceDetailDialogController', SalesRequestInvoiceDetailDialogController);
	
	SalesRequestInvoiceDetailDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'SalesRequestInvoice', 'entity', 'listNaturezaOperacao', 'FrontEndSelectDataSource', '$timeout', 'hasCreatePermission'];
	
	
	function SalesRequestInvoiceDetailDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, SalesRequestInvoice, entity, listNaturezaOperacao, FrontEndSelectDataSource, $timeout, hasCreatePermission){
		
		var vm = this;
	
		vm.pageConfig = {
			pageMode: entity.id ? (entity.manual && entity.invoiceStatus.id == 'REGULAR' && hasCreatePermission ? 'EDIT' : 'READONLY') : 'CREATE'
		};
		vm.entity = entity;
		if(entity.clientCpf) {
			entity.clientCpf = entity.clientCpf.replace(/\D/g, "");
		}
		if(entity.id) {
			//entity.createdDate = entity.createdDateWithoutTimezone ? moment(entity.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
			entity.issueDate = entity.issueDateWithoutTimezone ? moment(entity.issueDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
			entity.competencyDate = entity.competencyDateWithoutTimezone ? moment(entity.competencyDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
			if(!entity.manual) {
				entity.naturezaOperacaoCodigoInvoicyId = entity.naturezaOperacaoCodigoInvoicy;
				listNaturezaOperacao.$promise.then((ar) => {
					entity.naturezaOperacaoCodigoInvoicy = ar.find(obj => obj.id == entity.naturezaOperacaoCodigoInvoicyId)
				})
			}			
		} else {
			if(!entity.manual) {
				entity.naturezaOperacaoCodigoInvoicyId = 1;
				listNaturezaOperacao.$promise.then((ar) => {
					entity.naturezaOperacaoCodigoInvoicy = ar.find(obj => obj.id == entity.naturezaOperacaoCodigoInvoicyId)
				})
			}
		}
		var formOptions = {
				model: entity,
				service: SalesRequestInvoice,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormSalesRequestInvoiceDetail; }
	        };
		var cepInput = new FormField({
			cellCssClass: "col-sm-3",
			type: "ZipCode",
			id: "clientAddressCep",
			label: "common.domain.salesRequestInvoice.clientAddressCep",
			searchEngine:
			{
				handleResult: function(p_data)
				{
					if(p_data)
					{
						entity.clientAddressStreet = p_data.address;
						entity.clientAddressNeighborhood = p_data.district;
						entity.clientAddressCity = p_data.city;
						entity.clientAddressCityCode = p_data.ibge;
						entity.clientAddressState = p_data.uf;
						vm.foundAddress = true;
						vm.readOnlyAddressFields = true;
					} else {
						vm.foundAddress = false;
						//vm.readOnlyAddressFields = false;
					}
					
				}
			},
			required: vm.isNotManual
		});
		vm.isNotManual = !entity.manual;
		vm.readOnlyAddressFields = true;
		vm.foundAddress = null;
		vm.formHandler = FormDomain.create(formOptions).
			add([
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "id",
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: 'createdDateFormatted',
					readOnly: true,
					label: "common.domain.salesRequestInvoice.createdDate",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "userNameCreated",
					hide: !entity.userNameCreated,
					label: "common.domain.salesRequestInvoice.userCreated",
					readOnly: true,
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Date",
					id: "issueDate",
					hide: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.issueDate"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Date",
					id: "competencyDate",
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.competencyDate"
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Money",
					id: "totalValue",
					readOnly: true,
					label: "common.domain.salesRequestInvoice.totalValue"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "NumberRaw",
					id: "number",
					hide: vm.isNotManual && !entity.id,
					required: !vm.isNotManual,
					label: "common.domain.salesRequestInvoice.number"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "rpsSeries",
					hide: !entity.id || !vm.isNotManual,
					readOnly: true,
					label: "common.domain.salesRequestInvoice.rpsSeries"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "rpsNumber",
					hide: !entity.id || !vm.isNotManual,
					readOnly: true,
					label: "common.domain.salesRequestInvoice.rpsNumber",
				})
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-8",
					type: "String",
					id: "clientName",
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientName"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "CnpjCpf",
					id: "clientCpf",
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientCpf"
				}),
			]).add([
				cepInput,
				new FormField({
					cellCssClass: "col-sm-2",
					type: "String",
					id: "clientAddressState",
					readOnly: vm.readOnlyAddressFields,
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientAddressState"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "clientAddressCity",
					readOnly: vm.readOnlyAddressFields,
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientAddressCity"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "clientAddressNeighborhood",
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientAddressNeighborhood"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "clientAddressStreet",
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientAddressStreet"
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "NumberRaw",
					id: "clientAddressNumber",
					required: vm.isNotManual,
					label: "common.domain.salesRequestInvoice.clientAddressNumber"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "clientAddressComplement",
					label: "common.domain.salesRequestInvoice.clientAddressComplement"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-4",
					type: "Select",
					hide: !vm.isNotManual,
					readOnly: true,
					dataSource: FrontEndSelectDataSource.create(listNaturezaOperacao),
					id: "naturezaOperacaoCodigoInvoicy",
					label: "common.domain.salesRequestInvoice.naturezaOperacao"
				}),
			])
			.add([
				new FormField({
					type: "TextArea",
					required: true,
					id: "invoiceDetail",
					extraCssClass: ' medium-textarea',
					cellCssClass: "col-sm-12",
					label: "common.domain.salesRequestInvoice.invoiceDetail",
				}),
			])
			.add([
				new FormField({
					type: "TextArea",
					id: "obs",
					cellCssClass: "col-sm-12",
					label: "common.domain.salesRequestInvoice.obs",
				})
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "String",
					id: "invoicyStatusCode",
					readOnly: true,
					label: "common.domain.salesRequestInvoice.invoicyStatusCode",
					hide: !vm.isNotManual || !entity.id,
					
				}),
				new FormField({
					cellCssClass: "col-sm-10",
					type: "TextArea",
					id: "invoicyStatusDescription",
					readOnly: true,
					hide: !vm.isNotManual || !entity.id,
					label: "common.domain.salesRequestInvoice.invoicyStatusDescription"
				})
			]);
		
		 /*public*/ vm.closeModal = function(salesRequestInvoice)
		 {
			$uibModalInstance.close(salesRequestInvoice);
        };

		if(entity.clientAddressCep) {
			
			$timeout(function()
            {
            	console.log(cepInput);
				cepInput.doSearchEngine();
            }, 100);
			
		}

		function onSaveSuccess(p_result, p_headers) {
			console.log(p_result);
			vm.closeModal(p_result);
		}

		function onSaveError() {

		}
        vm.onSaveSalesRequestInvoice = function(){
        	console.log("AAAAAA");
        	var domain = null;
			FormUtil.validateAll($scope.editFormSalesRequestInvoiceDetail, vm.formHandler.getRowList());
			console.log($scope.editFormSalesRequestInvoiceDetail);
			if($scope.editFormSalesRequestInvoiceDetail.$valid) {
				domain = {};
				Object.assign(domain, entity);
				Object.assign(domain, vm.formHandler.readForm());
				console.log(domain);
				if(vm.isNotManual) {
					domain.issueDate = null;
				}
				if(domain.id) {
					SalesRequestInvoice.update(domain, onSaveSuccess, onSaveError);
				} else {
					SalesRequestInvoice.save(domain, onSaveSuccess, onSaveError);
				}

				
			}
        	
        }
	       
	}
})();
