(function(){
	'use strict';
	angular.module('sgmApp').controller('UpdateProntuaryItemDialogController', UpdateProntuaryItemDialogController);
	
	UpdateProntuaryItemDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'prontuaryItem'];
	
	
	function UpdateProntuaryItemDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, prontuaryItem){
		
		var vm = this;
		
		vm.prontuaryItem = prontuaryItem;
		vm.updatedProntuaryItem = vm.prontuaryItem.prontuaryModelItem;
		if(vm.updatedProntuaryItem) {
			if(vm.prontuaryItem.prontuaryItemOptions && vm.updatedProntuaryItem.prontuaryItemOptions) {
				var selectedOptions = vm.prontuaryItem.prontuaryItemOptions.filter(obj => obj.selected);
				if(selectedOptions.length == 1 || vm.updatedProntuaryItem.type.id == 'CHECK') {
					for(var i = 0; i < selectedOptions.length; i++) {
						var selectedOption = selectedOptions[i];
						var equivalentItemOption = vm.updatedProntuaryItem.prontuaryItemOptions.find(obj => obj.id == selectedOption.prontuaryModelItemOptionIdFk);
						if(equivalentItemOption) {
							console.log("SELECTED");
							equivalentItemOption.selected = true;
						}
					}
					
				}
			}
			vm.updatedProntuaryItem.text = vm.prontuaryItem.text;
		}
		
		console.log(vm.updatedProntuaryItem);

		 /*public*/ vm.closeModal = function(obj, isDelete)
		 {
			$uibModalInstance.close({
				obj: obj,
				isDelete: isDelete
			});
        };
		function findAttendanceOldOptionWithSameModelItemOptionId(prontuaryModelItemOptionIdFk) {
			if(vm.prontuaryItem.prontuaryItemOptions) {
				return vm.prontuaryItem.prontuaryItemOptions.find(obj => obj.prontuaryModelItemOptionIdFk == prontuaryModelItemOptionIdFk);
			}
			return;
		}
		vm.confirm = function() {
			if(vm.updatedProntuaryItem) {
				vm.updatedProntuaryItem.prontuaryModelItemIdFk = vm.updatedProntuaryItem.id;
				vm.updatedProntuaryItem.id = vm.prontuaryItem.id;
				vm.updatedProntuaryItem.order = vm.prontuaryItem.order;
				vm.updatedProntuaryItem.lastTimeItemUpdated = moment()._d;
				if(vm.updatedProntuaryItem.prontuaryItemOptions) {
					for(var i = 0; i < vm.updatedProntuaryItem.prontuaryItemOptions.length; i++) {
						var option = vm.updatedProntuaryItem.prontuaryItemOptions[i];
						option.prontuaryModelItemOptionIdFk = option.id;
						var oldOption = findAttendanceOldOptionWithSameModelItemOptionId(option.prontuaryModelItemOptionIdFk);
						if(oldOption) {
							option.id = oldOption.id;
						}
						
					}
				}
				if(vm.updatedProntuaryItem.grid) {
					var grid = vm.updatedProntuaryItem.grid;
					grid.prontuaryModelGridIdFk = grid.id;
					grid.id = null;
					if(grid.columns) {
						for(var i = 0; i < grid.columns[i]; i++) {
							var column = grid.columns[i];
							column.prontuaryModelGridColumnIdFk = column.id;
						}
					}
					if(grid.rows) {
						for(var i = 0; i < grid.rows[i]; i++) {
							var row = grid.rows[i];
							row.prontuaryModelGridRowIdFk = row.id;
							row.id = null;
						}
					}
				}
				console.log(vm.updatedProntuaryItem);
				vm.updatedProntuaryItem.itemIsNotUpdated = false;
				vm.closeModal(vm.updatedProntuaryItem, false);
			} else {
				vm.closeModal(null, true);
			}
		}
        
	       
	}
})();
