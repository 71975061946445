(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ConfirmDialogController', ConfirmDialogController);

    ConfirmDialogController.$inject = ['$uibModalInstance', 'title', 'text', '$translate', 'hideButtons'];

    function ConfirmDialogController ($uibModalInstance, title, text, $translate, hideButtons)
    {
        var vm = this;
        
        vm.title = title ? title : $translate.instant("client.global.messages.info.confirmOperation");
        vm.text = text;
        vm.hideButtons = hideButtons;
        vm.closeModal = function()
        {
            $uibModalInstance.dismiss('cancel');
        }

        vm.confirm = function()
        {
            $uibModalInstance.close(true);
        }
    }
})();
