(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ExpenseManagerDirectiveController', ExpenseManagerDirectiveController);

	ExpenseManagerDirectiveController.$inject = ['$scope', '$translate', 'Principal', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$state', '$stateParams', 'ExpenseCommon', 'Expense', 'FormDomain', 'UcAlertService','InstallmentExpenseCalcDialogInvoker', 'InstallmentSummaryDialogInvoker', 'InstallmentCancelDialogInvoker'];
	function ExpenseManagerDirectiveController($scope, $translate, Principal, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $state, $stateParams, ExpenseCommon, Expense, FormDomain, UcAlertService, InstallmentExpenseCalcDialogInvoker, InstallmentSummaryDialogInvoker, InstallmentCancelDialogInvoker) {
		var vm = this;

		vm.domain = $scope.domain;
		vm.pageConfig = $scope.pageConfig;
		vm.injectedResources = $scope.injectedResources;

		vm.masterUser = Principal.hasAnyAuthority(['ROLE_DESPESA_ADMINISTRACAO']);
		vm.readOnlyFinish = false;

		vm.isClosed = vm.domain && vm.domain.expenseStatus && (vm.domain.expenseStatus.id === "ENC" || vm.domain.expenseStatus.id === "CAN");
		//Não permite editar uma despesa que esteja encerrada!
		if(vm.isClosed && $state.current.name === "expense.edit")
		{
			$state.go('expense.detail', { id: $stateParams.id });
		}
		
		vm.installmentTable = {
	        	rows: [],
	        	model: {},
	        	isMultiple: isMultipleInstallments
	        };
		
		vm.installmentCalcData = {
				totalValue: null,	
				expensePaymentOption: null,
				firstInstallmentExpirationDate: null,
				competenceDate: null
			};
		
		vm.installmentCancelData = {
				cancelExpenseReason: null
		};
		
		vm.initOnCreate = function(){
			if(vm.pageConfig.pageMode === 'CREATE'){
				refreshInstallmentTable(1);
			}
		}
		
		function isMultipleInstallments(){
			return vm.installmentTable.rows.length > 1;
		}
		
		if (!vm.domain.expenseDate) {
			vm.domain.expenseDate = new Date();
		}
		else{
			vm.domain.expenseDate = (vm.domain.expenseDateWithoutTimezone) ? moment(vm.domain.expenseDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : null;
		}

		if (vm.masterUser === true && vm.isClosed) {
			vm.readOnlyFinish = false;
		}
		else if (vm.isClosed) {
			vm.readOnlyFinish = true;
		}

		if (vm.domain.installmentQuantity) {
			angular.forEach(vm.injectedResources.listInstallmentsquantity, function (valor) {
				if (valor.id === vm.domain.installmentQuantity) {
					vm.domain.installmentQuantity = angular.copy(valor);
					return;
				}
			});
		}

		if (vm.domain.expenseUserResponsibleSet && vm.domain.expenseUserResponsibleSet.length > 0) {
			var optionsUser = [];
			angular.forEach(vm.domain.expenseUserResponsibleSet, function (data) {
				optionsUser.push(data.user);
			});
			vm.domain.expenseUserResponsibleSet = optionsUser;
		}

		if (vm.domain.user) {
			vm.domain.userName = vm.domain.user.name;
		}

		vm.tabList = ExpenseCommon.tabList;

		var source = $scope.source;

		// Apenas para o modal
		var uibModalInstance = $scope.uibModalInstance;

		vm.tabHandler = {
			disabledMap: {},
			active: $stateParams.activeTab,
			isActive: function (p_code) {
				return (p_code === vm.tabHandler.active);
			},
			isDisabled: function (p_code) {
				if ((p_code === "attachment") && !vm.domain.id) {
					return true;
				}
				return false;
			},
			isShow: function (p_code) {
				return true;
			},
			select: function (p_id) {
				if (vm.tabHandler.isDisabled(p_id)) {
					return;
				}

				$state.go('.', { activeTab: p_id }, { notify: false });
				vm.tabHandler.active = p_id;

				if (p_id == "attachment") // Se for Summary, atualiza o grid
				{
					$scope.$broadcast('expense-manager.refreshTab.attachment');
				}
			}
		};

		var tabErrorListner = $scope.$on(FormConstants.Events.Runtime.ShowTabError, function (p_, p_arg) {
			if (p_arg) {
				vm.tabHandler.select(p_arg);
			}
		});
		$scope.$on('$destroy', function () {
			if (angular.isDefined(tabErrorListner) && tabErrorListner !== null) {
				tabErrorListner();
			}
		});

		if (!$stateParams.activeTab) {
			vm.tabHandler.select("payment");
		}

		/**********************************************************/

		var formOptions = {
			model: vm.domain,
			pageConfig: vm.pageConfig,
			buttonsModel: null,
			service: Expense,
			afterCreateState: "expense.detail",
			showTabWithError: true,
			afterUpdate: function () { doAfterUpdate(); },
			getForm: function () { return $scope.editForm; },
			historyConfig: {historyId: "EXPENSE"},
			beforeInvokeService: function(p_json)
        	{
        		// Preenche a lista de parcelas no momento da criação da despesa.
				// No caso de edição, a lista de parcelas não é modificada
				if(vm.pageConfig && vm.pageConfig.pageMode === "CREATE"){
					p_json.installmentList = getInstallmentList();
					p_json.providedTotalValue = getTotalInstallmentsValue();
				}
        	}
		};
		
		//Used in expense-tab
		vm.fieldResponsibleUser = new FormField({
			formTarget: "main",
			cellCssClass: "col-sm-6",
			type: "Select",
			id: "expenseUserResponsibleSet",
			multiple: true,
			label: "common.domain.expense.responsibleUser",
			dataSource: FormConstants.DataSources.BackEnd.User(BackEndSelectDataSource, $translate)
		});

		vm.formHandler = FormDomain.create(formOptions).add([])
			.add([
				new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-2",
				type: "String",
				id: "id",
				label: "common.domain.expense.id",
				readOnly: true
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "sourceId",
					label: "common.domain.expense.sourceId",
					readOnly: true
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-1",
					type: "String",
					id: "installmentNumber",
					label: "common.domain.expense.installmentNumber",
					readOnly: true
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-4",
					type: "String",
					id: "userName",
					multiple: false,
					readOnly: true,
					label: "common.domain.expense.createdUser"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "expenseDate",
					label: "common.domain.expense.expenseDate"
				})
			]).add([
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "expenseType",
					required: true,
					label: "common.domain.expense.type",
					dataSource: FormConstants.DataSources.BackEnd.ExpenseType(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "Select",
					required: false,
					readOnly: true,
					id: "expenseStatus",
					label: "common.domain.expense.status",
					dataSource: FrontEndSelectDataSource.create(vm.injectedResources.listExpenseStatusType)
			})]).add([
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					required: true,
					label: "common.domain.expense.description"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "expenseTime",
					required: false,
					readOnly: true,
					label: "common.domain.expense.statusTime",
					dataSource: FrontEndSelectDataSource.create(vm.injectedResources.listExpenseTimeStatusType)
				})]).
			add([
				vm.fieldResponsibleUser,
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "Select",
					required: true,
					id: "expenseCostCenter",
					label: "common.domain.expense.costCenter",
					dataSource: FormConstants.DataSources.BackEnd.ExpenseCostCenter(BackEndSelectDataSource, $translate)
				})])
			.add([
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					readOnly: vm.readOnlyFinish,
	    			label: "common.domain.expense.assignor",
	    			type: "String",
	    			id: "assignor"
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					formTarget: "main",
					readOnly: !(vm.pageConfig.pageMode === "CREATE"),
					type: "Select",
					id: "installmentQuantity",
					label: "common.domain.expense.installmentQuantity",
					dataSource: FrontEndSelectDataSource.create(vm.injectedResources.listInstallmentsquantity),
					onChange: function(p_objModel, p_fieldModel, p_currentValue)
					{
						refreshInstallmentTable(p_currentValue === null ? 1 : p_currentValue.id);
					}
				})])
			.add([
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "supplier",
					required: false,
					label: "common.domain.expense.supplier",
					dataSource: FormConstants.DataSources.BackEnd.Supplier(BackEndSelectDataSource, $translate)
				})
			])
			.endConfig();


        /*public*/ vm.save = function () {
        	
        	if(validatePaymentTabBeforeCreate() && validatePaymentTabBeforeClose()){
        		
        		if(vm.pageConfig && vm.pageConfig.pageMode !== "CREATE" && vm.domain.effectiveDatePayment){
	        		var params = {
	            			installment: vm.domain,
	            			callback: vm.onCloseSummaryModal
	    				};
	        		
	        		InstallmentSummaryDialogInvoker.invoke(params);
        		}else{
        			vm.formHandler.save();
        		}
        	}
		};

		vm.buttonsModel = {
			useSuggestedButton: false,
			isSaving: false,
			gridId: "EXPENSE",
			previousState: { name: "expense" },
			stateRoot: "expense",
			extraButtonList: [
				createSaveButton(),
				createEditButton(),
				createCancelButton(),
				createBackButton(),
				createStandardPaymentButton()
			]
		};

		  /*private*/ function createBackButton() {
			return {
				order: 500,
				cssClass: 'btn btn-default',
				cssClassIcon: 'glyphicon glyphicon-arrow-left',
				label: 'client.global.label.back',
				type: 'button',

				isDisabled: function () {
					return false;
				},
				isToAdd: function () {
					return true;
				},
				click: function () {
					$state.go('expense');
				}
			};
		};

	    /*private*/ function createEditButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'glyphicon glyphicon-pencil',
				label: 'client.global.label.edit',
				type: 'button',
				isDisabled: function () { 
					if(vm.isClosed){
						return true;
					}
					return !Principal.hasAnyAuthority(['ROLE_DESPESA_EDICAO']); 
				 },
				isToAdd: function()
	        	{
	        		// Só para READONLY
	        		return (vm.pageConfig && vm.pageConfig.pageMode === "READONLY");
	        	},
				click: function () {
					$state.go('expense.edit', { id: $stateParams.id });
				}
			}
		};
		
		/*private*/ function createStandardPaymentButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'fa fa-usd',
				label: 'client.page.expense.tab.payment.standardPayment',
				type: 'button',
				isToAdd: function()
				{
					 return vm.domain.id && !vm.isClosed && vm.pageConfig && vm.pageConfig.pageMode === "EDIT";
				},
				click: function () {
					onClickStandardPaymentButton();
				}
			}
		};

		/* Zera a multa, o desconto e os juros, e copia os valores previstos para os efetivos */
		function onClickStandardPaymentButton(){
			if(!vm.domain)
				return;
			
			vm.domain.interest = 0;
			vm.domain.penalty = 0;
			vm.domain.discount = 0;
			vm.domain.differenceValue = 0;
			vm.domain.effectiveValue = vm.domain.providedValue;
			if(vm.domain.plannedDatePayment != null){
				vm.domain.effectiveDatePayment = vm.domain.plannedDatePayment;
			}
			vm.setRequiredToClose(true);
		}
		
	    /*private*/ function createCancelButton() {
			return {
				order: 100,
				cssClass: 'btn btn-accent',
				cssClassIcon: 'fa fa-undo',
				label: 'common.domain.expense.cancel',
				type: 'button',
				isDisabled: function () { return vm.domain.expenseStatus.id === "CAN" || !Principal.hasAnyAuthority(['ROLE_DESPESA_EDICAO']) && vm.domain.expenseStatus.id === "CAN";},
				isToAdd: function () { return vm.pageConfig && vm.pageConfig.pageMode !== "CREATE" },
				click: function () {
					var params = {
							installmentCancelData: vm.installmentCancelData,
		        			callback: vm.onCloseCancelDialog
						};
		        	InstallmentCancelDialogInvoker.invoke(params);
				}
			};
		};
		
		vm.onCloseCancelDialog = function(isToSave){
			
			if(!isToSave){
				return;
			}
			var cancelReason = $translate.instant('client.page.expense.cancelReasonLabel')+": " + vm.installmentCancelData.cancelExpenseReason;
			
			Expense.cancel({ id: $stateParams.id, reason: cancelReason }).$promise.then(function(p_data){
				UcAlertService.success($translate.instant("client.global.messages.info.expenseCancel"));
				$state.go('expense.detail', { id: $stateParams.id }, {reload: true});
			});
		}
		
	    /*private*/ function createSaveButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'glyphicon glyphicon-save',
				label: 'client.global.label.save',
				type: 'submit',
				isDisabled: function () { 
					if(vm.isClosed ||vm.isSaving){
						return true;
					}
					if(vm.pageConfig.pageMode === 'CREATE' && Principal.hasAnyAuthority(['ROLE_DESPESA_CRIACAO']))
        			{
        				return false;
        			}
        			if(vm.pageConfig.pageMode === 'EDIT' && Principal.hasAnyAuthority(['ROLE_DESPESA_EDICAO']))
        			{
        				return false;
        			}
					return true; 
				 },
				isToAdd: function () { return true; },
				click: function () { /* void - por ser submit, a ação está no ng-submit do form */ }
			};
		};

	    /*private*/ function doAfterUpdate() {
	    	if(vm.isClosed){
	    		$state.go('expense.show', { id: $stateParams.id });
	    	}else{
	    		$state.reload();
	    	}
		}

	    /*private*/ function validatePaymentTabBeforeCreate(){
	    	
	    	if(vm.pageConfig && vm.pageConfig.pageMode === "CREATE"){
	    		
	        	angular.forEach(vm.installmentTable.rows, function(row){
		    		FormUtil.validateAll($scope.editForm, row.form.getRowList());
		    	});
	        	
	        	var totalInstallmentsValues = getTotalInstallmentsValue();
	        	//validando o total das parcelas
	        	
	        	if(totalInstallmentsValues && vm.installmentCalcData.totalValue && vm.installmentCalcData.totalValue !== 0){
	        		if(totalInstallmentsValues !== vm.installmentCalcData.totalValue){
	        			UcAlertService.dismissBox();
	        			UcAlertService.message($translate.instant("client.page.expense.tab.payment.differentTotalValueMessage"), "WARNING", "BOX");
	        			
	        			return false;
	        		}
	        	}
	    	}
			
	    	return true;
	    }
	    
	    /*private*/ function validatePaymentTabBeforeClose(){
	    	if(vm.pageConfig && vm.pageConfig.pageMode !== "CREATE")
	    	{
	    		var msg = "<ul>";
	    		var hasError = false;
	    		//Se estiver no encerramento
	    		if(vm.domain.effectiveDatePayment){
	    			
	    			if(!vm.domain.expensePaymentOption || !vm.domain.competenceDate ||
	    					vm.domain.interest == null|| vm.domain.penalty == null || vm.domain.discount == null|| !vm.domain.nfDoc){
	    				
	    				msg+= "<li>" + $translate.instant('client.page.expense.allFieldsNeedToBeFilledMessage')+ "</li>";
	    				hasError = true;
	    			}
	    			
	    			if(!vm.domain.assignor && !vm.domain.supplier)
	    			{
	    				msg+= "<li>" + $translate.instant('client.page.expense.supplierOrAssignorMustBeFilled')+ "</li>";
	    				hasError = true;
	    			}
	    			msg += "</ul>";
	    			
	    			UcAlertService.dismissBox();
	    			if(hasError){
	    				UcAlertService.message(msg, "WARNING", "BOX");
	    				return false;
	    			}
	    		}
	    	}
	    	return true;
	    }
	    
	    
	    /*provate */ function refreshInstallmentTable(p_installmentNumber)
        {
        	// Limpa a componente
        	vm.installmentTable.rows = [];
        	
        	if(!p_installmentNumber)
        	{
        		p_installmentNumber = 0;
        	}
        	
        	var formFieldArray = [];
        	// Recria
        	for(var i = 0 ; i < p_installmentNumber ; i++)
        	{
        		var formFieldArray = [];
        		
        		var fieldProvidedValue = new FormField({
					type: "Money",
					readOnly: vm.readOnlyFinish,
					id: "providedValue"+ i,
					cellCssClass: "col-sm-3",
					required: true,
					label: "common.domain.expense.providedValue",
					onChange: function(p_objModel, p_fieldModel, p_currentValue){
						vm.recalcEffectiveValueFields();
					}
				});

        		var fieldPlannedDatePayment = new FormField({
					type: "Date",
					cellCssClass: "col-sm-3",
					readOnly: vm.readOnlyFinish,
					id: "plannedDatePayment"+ i,
					label: "common.domain.expense.plannedDatePayment"
				});
        		
        		var fieldPaymentOption =  new FormField({
					type: "Select",
					cellCssClass: "col-sm-3",
					readOnly: vm.readOnlyFinish,
					id: "expensePaymentOption"+ i,
					label: "common.domain.expense.paymentOption",
					dataSource: FormConstants.DataSources.BackEnd.ExpensePaymentOption(BackEndSelectDataSource, $translate)
				});
        		

        		var fieldCompetenceDate = new FormField({
					type: "Month",
					cellCssClass: "col-sm-2",
					readOnly: vm.readOnlyFinish,
					id: "competenceDate" + i,
					label: "common.domain.expense.competenceDate"
				});
				
				var fieldNfDoc =  new FormField({
					label: "common.domain.expense.nfDoc",
					id: "nfDoc" + i,
					readOnly: vm.readOnlyFinish,
					type: "String",
					maxLength: 50,
					cellCssClass: "col-sm-4",
				});
				
				var fieldObs =  new FormField({
					type: "String",
					cellCssClass: "col-sm-8",
					readOnly: vm.readOnlyFinish,
					id: "obs"+ i,
					label: "common.domain.expense.obs",
					maxLength: 200
				});
				
        		
        		var formHandlerInstallments = FormDomain.create(vm.formOptionsInstallments).
        			add([fieldProvidedValue, fieldPlannedDatePayment, fieldPaymentOption, fieldCompetenceDate]).add([fieldNfDoc, fieldObs]);
        		
        		vm.installmentTable.rows.push({
        			label: i + 1,
        			form: formHandlerInstallments
        		});
        		
        	}
        };
        
        
        vm.formOptionsInstallments = {
    			model: vm.installmentTable.model,
    			pageConfig: vm.pageConfig,
    			service: Expense,
    			afterCreateState: "expense.detail",
        };
        
       
        
        /*pubic*/
        vm.recalcEffectiveValueFields =  function (){

    		var totalValue = null;
    		var installment = vm.domain;
    		if(installment.providedValue){
    			totalValue = parseFloat(installment.providedValue);
    			if(installment.interest){
    				totalValue += installment.interest;
    			}
    			
    			if(installment.penalty){
    				totalValue += installment.penalty;
    			}
    			
    			if(installment.discount){
    				totalValue -= installment.discount;
    			}
    			installment.effectiveValue = totalValue;
    			installment.differenceValue = totalValue - parseFloat(installment.providedValue);
    			
    		}
        }
        
        
        /*private*/ function getInstallmentByIndex(form, i){
        	
        	var formObj = form.readForm();
        	var installment = {};
        	
	        	installment.providedValue =formObj["providedValue" + i];
	    		installment.effectiveValue = formObj["effectiveValue" + i];
	    		installment.interest = formObj["interest" + i];
	    		installment.penalty = formObj["penalty" + i];
	    		installment.discount = formObj["discount" + i];
	    		installment.differenceValue = formObj["differenceValue" + i];
	    		var paymentOption  = formObj["expensePaymentOption" + i];	
	    		installment.expensePaymentOptionId = paymentOption ? paymentOption.id : null;
	    		installment.plannedDatePayment = formObj["plannedDatePayment" + i];
	    		installment.effectiveDatePayment = formObj["effectiveDatePayment" + i];
	    		installment.competenceDate = formObj["competenceDate" + i];
	    		installment.nfDoc = formObj["nfDoc" + i];
	    		installment.obs = formObj["obs" + i];
	    		installment.installmentNumber = i+1;
    		return installment;
        }
        
        /*private*/ function getInstallmentList()
        {
        	var list = [];
        	var val;
        	for(var i = 0 ; i < vm.installmentTable.rows.length ; i++)
        	{
        		var installment = getInstallmentByIndex(vm.installmentTable.rows[i].form, i);
        		list.push(installment);
        	}
        	return list;
        };
        
        /* private*/ function getTotalInstallmentsValue(){
        	
        	var total = 0;
        	for(var i = 0 ; i < vm.installmentTable.rows.length ; i++)
        	{
        		var installment = getInstallmentByIndex(vm.installmentTable.rows[i].form, i);
        		total = parseFloat(total) + parseFloat(installment.providedValue);
        	}
        	return total;
        }
        
        
        vm.onCloseSummaryModal = function(save){
        	if(save){
        		vm.formHandler.save();
        	}
        }

        /*Recalcula as parcelas*/
        vm.onCloseCalcInstallmentModal = function(recalc){
        	if(recalc && vm.domain.installmentQuantity){
        		var installmentQuantity = vm.domain.installmentQuantity.id;
        		var installmentValue = vm.installmentCalcData.totalValue / installmentQuantity;
        		var mod = vm.installmentCalcData.totalValue % installmentQuantity;
        		var rest = 0;
        		
        		installmentValue = installmentValue.toFixed(2);
        		var expirationDate = vm.installmentCalcData.firstInstallmentExpirationDate;
        		
        		for (var i = 0; i < installmentQuantity; i++) {
        			
        			//Se for a ultima iteração, calcula a parcela como sendo o restante.
        			if((installmentQuantity - 1) === i)
        			{
        				var valueToAddLastInstallment = (vm.installmentCalcData.totalValue - (installmentQuantity * installmentValue)).toFixed(2);
        				installmentValue = parseFloat(installmentValue) + parseFloat(valueToAddLastInstallment);
        			}
        			
        			if(parseFloat(installmentValue) !== 0){
        				vm.installmentTable.model['providedValue'+ i] = installmentValue;
        			}
    			
        			if(expirationDate){
        				vm.installmentTable.model['plannedDatePayment'+ i] = expirationDate;
        				expirationDate = moment(expirationDate).add(1,'month').toDate();
        			}
        			if(vm.installmentCalcData.expensePaymentOption){
        				vm.installmentTable.model['expensePaymentOption'+ i] = vm.installmentCalcData.expensePaymentOption;
        			}
        			if(vm.installmentCalcData.competenceDate){
        				vm.installmentTable.model['competenceDate'+ i] = vm.installmentCalcData.competenceDate;
        			}
        			if(vm.installmentCalcData.nfDoc){
        				vm.installmentTable.model['nfDoc'+ i] = vm.installmentCalcData.nfDoc;
        			}
    			}
			}
    	}
        
        vm.openCalcInstallmentModal = function(){
        	var params = {
        			installmentCalcData: vm.installmentCalcData,
        			callback: vm.onCloseCalcInstallmentModal
				};
        	InstallmentExpenseCalcDialogInvoker.invoke(params);
        }
        
        vm.initOnCreate();
	};
})();