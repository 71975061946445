(function() {
    'use strict';

	angular.module('sgmApp').service('PrintProntuaryFormDialogInvoker', PrintProntuaryFormDialogInvoker);
	
	PrintProntuaryFormDialogInvoker.$inject = ['$uibModal'];
	
	function PrintProntuaryFormDialogInvoker($uibModal)
	{
		var vm = this;
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/components/prontuary-form/print-prontuary-form-dialog/print-prontuary-form-dialog.html',
                controller: 'PrintProntuaryFormDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                	prontuaries: function()
                    {
                    	return p_param.prontuaries;
                    },
					clientName: function() {
						return p_param.clientName;
					}
                }
            });
		};
	};
})();
