(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ProductStockControlController', ProductStockControlController);

		ProductStockControlController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'Principal', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Product', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'DownloadUtil','TabStatePersister', 'PermissionSet', '$filter', '$sce', 'categoriesList', 'ManufacturerProductStockFlow', 'ManufacturerProductStockInfoDialogInvoker'];

	function ProductStockControlController(FormFilter, UcGridService, $translate, account, Principal, filterPageConfig, gridDefaultSort, $rootScope, Product, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, DownloadUtil,TabStatePersister, PermissionSet, $filter, $sce, categoriesList, ManufacturerProductStockFlow, ManufacturerProductStockInfoDialogInvoker) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;
		vm.stockTotalPrice = 0;
		vm.extraFieldRenderer = renderExtraField;
		vm.buttonsModel =
			{
				useSuggestedButton: false,
				stateRoot: "productStockControl",
				onFilter: function () { doFilter(); },
				onClear: function () { doClear(); },
				extraButtonList: [
					{
						order: 0,
						cssClass: 'btn btn-accent',
						cssClassIcon: 'glyphicon glyphicon-search',
						label: 'client.global.label.filter',
						type: 'button',
						isDisabled: function()
						{
							return false;
						},
						isToAdd: function()
						{
							return true;
						},
						click: function()
						{
							doFilter();
						}
					},
					{
						order: 70,
						cssClass: 'btn btn-danger',
						cssClassIcon: 'fa fa-eraser',
						label: 'client.global.label.reset',
						type: 'button',
						isDisabled: function()
						{
							return false;
						},
						isToAdd: function()
						{
							return true;
						},
						click: function()
						{
							doClear();
						}
					},
					/*{
						order: 80,
						cssClass: 'btn btn-primary',
						cssClassIcon: 'fa fa-file-excel-o',
						label: 'client.page.productStockControl.exportProductStockControlReport',
						type: 'button',
						isDisabled: function () {
							return !Principal.hasAnyAuthority([PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW.report]);
						},
						isToAdd: function () { return true; },
						click: function () { generateProductStockControlReport(); }
					},*/
					{
						order: 80,
						cssClass: 'btn btn-primary',
						cssClassIcon: 'fa fa-file-excel-o',
						label: 'client.page.productStockControl.exportManufacturerProductStockControlReport',
						type: 'button',
						isDisabled: function () {
							return !Principal.hasAnyAuthority([PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW.report]);
						},
						isToAdd: function () { return true; },
						click: function () { generateManufacturerProductStockControlReport(); }
					},
				]
			};

		$rootScope.initialFocus("id");

		var formOptions = {
			formStatePersisterId: "ProductStockControl"
		};
		var categoriesFormField = new FormField({
			cellCssClass: "col-sm-3",
			type: "Select",
			id: "productCategoryList",
			dependencyName: "categoryListId",
			multiple: true,
			label: "client.page.productStockControl.category",
			dataSource: FrontEndSelectDataSource.create(categoriesList)
		});
		vm.formHandler = FormFilter.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "NumberRaw",
					id: "id",
					label: "client.page.productStockControl.productId",
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "NumberRaw",
					id: "manufacturerProductId",
					label: "client.page.productStockControl.manufacturerProductId",
				}),

				categoriesFormField,
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "productSubcategoryList",
					multiple: true,
					label: "client.page.productStockControl.subcategory",
					dataSource: FormConstants.DataSources.BackEnd.SupplierSubcategory(BackEndSelectDataSource, $translate, categoriesFormField)
				}),

			]).add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "manufacturer",
					multiple: true,
					label: "client.page.productStockControl.manufacturerTradingName",
					dataSource: FormConstants.DataSources.BackEnd.Manufacturer(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "productList",
					dependencyName: "productId",
					multiple: true,
					label: "client.page.productStockControl.product",
					dataSource: FormConstants.DataSources.BackEnd.Product(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "manufacturerProductList",
					multiple: true,
					label: "client.page.productStockControl.manufacturerProduct",
					dataSource: FormConstants.DataSources.BackEnd.ManufacturerProduct(BackEndSelectDataSource, $translate, 'barCodeOrName',
						function(optionsList) {
							optionsList.forEach((option) => {
								option.defaultDescription  += " (COD: " + option.barCode + ")";
							});
							return optionsList;
						}
					)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "stockPlace",
					multiple: true,
					label: "client.page.productStockControl.stockPlace",
					dataSource: FormConstants.DataSources.BackEnd.StockPlace(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "manufacturerProductBatch",
					multiple: true,
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductBatchCode",
					dataSource: FormConstants.DataSources.BackEnd.ManufacturerProductBatch(BackEndSelectDataSource)
				})
			])
			.endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("client.page.productStockControl.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5 },
			{ headerName: $translate.instant("client.page.productStockControl.category"), field: "categoryId", cellStyle: { alignItems: "center", textAlign: "center" }, width: 10 ,
				 cellRenderer: function(p_param) { return renderManufacturerProductOrProduct(null, "category.name", p_param) }
			},
			{ headerName: $translate.instant("client.page.productStockControl.subcategory"), field: "subcategoryId", cellStyle: { alignItems: "center", textAlign: "center" }, width: 10 ,
				cellRenderer: function(p_param) { return renderManufacturerProductOrProduct(null, "subcategory.name", p_param) }
	   		},
			{ headerName: $translate.instant("client.page.productStockControl.productDescription"), field: "description", cellStyle: { alignItems: "center", textAlign: "center" }, width: 25 ,
				cellRenderer: function(p_param) { return renderManufacturerProductOrProduct("defaultDescription", "defaultDescription", p_param) }
		   	},
			{ headerName: $translate.instant("client.page.productStockControl.barCode"), field: "barCode", cellStyle: { alignItems: "center", textAlign: "center" }, width: 10 },
			{ headerName: $translate.instant("client.page.productStockControl.manufacturerTradingName"), field: "manufacturerTradingName", cellStyle: { alignItems: "center", textAlign: "center" }, width: 15 ,
				cellRenderer: function(p_param) { return renderManufacturerProductOrProduct("manufacturer.defaultDescription", null, p_param) }
		   	},
			{ headerName: $translate.instant("client.page.productStockControl.amountOnStock"), field: "amountOnStock", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5 },
			{ headerName: $translate.instant("client.page.productStockControl.reservedAmount"), field: "reservedAmount", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5 },
			{ headerName: $translate.instant("client.page.productStockControl.availableAmount"), field: "availableAmount", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5,
				cellRenderer: function(p_param) { return renderAvailableAmount(p_param) }
		   	},
			UcGridService.field.MONEY({headerName: $translate.instant("client.page.productStockControl.averageUnitValue"), field: "currentAverageUnitValue", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5}),
			{ headerName: $translate.instant("client.page.productStockControl.totalAverageCost"), field: "totalAverageCost", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5,
				cellRenderer: function(p_param) { return renderTotalAverageCost(p_param) }
			},
			UcGridService.field.BOOLEAN({field: "active", title: "client.page.productStockControl.active", width: 6, suppressSizeToFit: false})

		]);

		var grid = UcGridService.create(
			{
				gridId: "PRODUCT",
				stateBaseName: "productStockControl",
				account: account,
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
				autoAmount: true,
				onRowClicked: function(p_params)
				{
					if(p_params && p_params.data) {
						if(p_params.data.isManufacturerProduct) {
							console.log(p_params.data);
							vm.openManufacturerProductDetails(p_params.data.id);
						}
					}
				},
				getRowClass: function(p_params)
				{
					if(p_params && p_params.data) {
						if(p_params.data.isManufacturerProduct) {
							return "grid-product-stock-control-row-manufacturer-product";
						} else {
							return "grid-row-accent";
						}
					}
					return "";
				},
				fetchResultTransform: function(p_data) {
					var content = p_data.content;
					var result = [];
					content.forEach((product) => {
						var manufacturerProducts = [];
						manufacturerProducts = manufacturerProducts.concat(product.manufacturerProductSet);
						product.manufacturerProductSet = [];
						manufacturerProducts = filterManufacturerProducts(manufacturerProducts, product);
						result.push(product);
						manufacturerProducts.forEach((manufacturerProduct) => {
							manufacturerProduct.isManufacturerProduct = true;
						});
						result = result.concat(manufacturerProducts);
					})
					p_data.content = result;
					return p_data;
				},
				gridData: function(p_pageParams) {
					var filter = vm.formHandler.readForm();

					Object.assign(filter, p_pageParams);
					filter = generateProductUnitValueParams(filter);
					// Quando o parâmetro gridData é informado o UcGridService ignora a chamada padrão do getFilterData
					return Product.getAllProductsCompleteData(filter);
				}
			});



		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
			setTimeout(function(){
				getPriceTotalAmountOnStock();
			}, 1);

		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};

		function getReportCriteria(data) {
			delete data["productCategoryList"];
			delete data["productSubcategoryList"];
			delete data["productList"];
			delete data["manufacturerProductList"];
			delete data["stockPlace"];
			delete data["manufacturerProductBatch"];
			return generateProductUnitValueParams(data);
		}
		/*private*/ function generateProductStockControlReport() {
			var data = vm.formHandler.readForm();
			console.log(data);
			data = getReportCriteria(data);
			data.generateReport = true;
			DownloadUtil.download('api/manufacturer-product-stock-flow/generate-product-stock-control-report', data);			
		}

		/*private*/ function generateManufacturerProductStockControlReport() {
			var data = vm.formHandler.readForm();
			console.log(data);
			data = getReportCriteria(data);
			data.generateReport = true;
			DownloadUtil.download('api/manufacturer-product-stock-flow/generate-manufacturer-product-stock-control-report', data);			
		}

		function generateProductUnitValueParams(criteria) {
			if(criteria["stockPlaceId"]) {
				criteria["flowParam_stockPlaceId"] = criteria["stockPlaceId"];

			}
			if(criteria["manufacturerProductBatchId"]) {
				criteria["flowParam_manufacturerProductBatchId"] = criteria["manufacturerProductBatchId"];
			}
			return criteria;
		}
		function filterManufacturerProducts(manufacturerProducts, product) {
			let arr = [];
			let totalValue = 0;
			let amount = 0;
			var criteria = vm.formHandler.readForm();
			manufacturerProducts.forEach((manufacturerProduct) => {
				var isToAdd = true;
				if(criteria["manufacturerProductId"] && ("" + manufacturerProduct.id) != criteria["manufacturerProductId"]) {
					isToAdd = false;
				}
				if(criteria["manufacturerProductListId"] != "") {
					var ids = [];
					ids = criteria["manufacturerProductListId"].split(";");
					if(!ids.includes(""+manufacturerProduct.id)) {
						isToAdd = false;
					}
				}
				if(criteria["manufacturerTradingName"] && !manufacturerProduct.manufacturer.tradingName.includes(criteria["manufacturerTradingName"]) ) {
					isToAdd = false;
				}
				if(isToAdd) {
					arr.push(manufacturerProduct);
					amount += manufacturerProduct.amountOnStock;
					totalValue += manufacturerProduct.totalValue;
				}
			});
			product.averageUnitValue = totalValue/amount;
			product.amountOnStock = amount;
			return arr;
		};

		function getPriceTotalAmountOnStock() {
			var criteria = parseCriteriaToManufacturerProductStockFlow(vm.formHandler.readForm());
			ManufacturerProductStockFlow.totalPrice(criteria).$promise.then(function(res) {
				vm.stockTotalPrice = res.value;
			})
		};

		vm.openManufacturerProductDetails = function(manufacturerProductId) {
			ManufacturerProductStockInfoDialogInvoker.invoke({manufacturerProductId: manufacturerProductId});
		}

		function parseCriteriaToManufacturerProductStockFlow(criteria) {
			if(criteria["id"]) {
				criteria["productId"] = criteria["id"];
				criteria["id"] = null;
			}
			return criteria;
		};

		function renderManufacturerProductOrProduct(manufacturerProductAttribute, productAttribute, p_param) {
			if(p_param && p_param.data) {
				if(p_param.data.isManufacturerProduct) {
					if(manufacturerProductAttribute)
						return accessPath(manufacturerProductAttribute, p_param.data);
				} else if(productAttribute) {
						return accessPath(productAttribute, p_param.data);
				}
			}
			return "";
		};

		function accessPath(path, object) {
			var path = path.split(".");

			path.forEach((attribute) => {
				object = object[attribute];
				if(!object) {
					return "";
				}
			})
			return object;
		};

		function renderAvailableAmount(p_param) {
			if (p_param && p_param.data && !p_param.data.isManufacturerProduct) {
				var amountOnStock = p_param.data.amountOnStock;
				var reservedAmount = p_param.data.reservedAmount;
				return amountOnStock - reservedAmount;
			}
			else {
				return "";
			}
		};
		function renderExtraField(p_param) {
				return $sce.trustAsHtml(
					"<div style='margin: 10px;font-size:13pt; color: white;'><span style='font-weight:bold; margin-right: 10px;'>Soma do Custo Total Estoque dos itens filtrados:</span>R$ " + $rootScope.formatMoney(vm.stockTotalPrice) + "</div>");
		};
		function renderTotalAverageCost(p_param) {
			if (p_param && p_param.data) {
				return "R$ " + $rootScope.formatMoney(p_param.data.totalValue || 0);
			}
			else {
				return "";
			}
		}
	}
})();
