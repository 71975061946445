(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ProductController', ProductController);

	ProductController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'Principal', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Product', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'DownloadUtil','TabStatePersister', 'categoriesList'];

	function ProductController(FormFilter, UcGridService, $translate, account, Principal, filterPageConfig, gridDefaultSort, $rootScope, Product, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, DownloadUtil,TabStatePersister, categoriesList) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		vm.buttonsModel =
			{
				stateRoot: "product",
				onFilter: function () { doFilter(); },
				onClear: function () { doClear(); },
				extraButtonList: [
					{
						order: 90,
						cssClass: 'btn btn-primary',
						cssClassIcon: 'fa fa-file-excel-o',
						label: 'client.global.label.export',
						type: 'button',
						isDisabled: function () {
							return !Principal.hasAnyAuthority(['REPORT_PRODUCT']);
						},
						isToAdd: function () { return true; },
						click: function () { generateReport(); }
					},
					/*{
						order: 100,
						cssClass: 'btn btn-primary',
						label: 'produtos adicionar',
						type: 'button',
						isToAdd: function () { return true; },
						click: function () { addProdutos(); }
					}*/
				]
			};

		$rootScope.initialFocus("description");

		var formOptions = {
			formStatePersisterId: "Product" 
		};

		var categoriesFormField = new FormField({
			cellCssClass: "col-sm-5",
			type: "Select",
			id: "category",
			dependencyName: 'categoryListId',
			multiple: true,
			label: "common.domain.product.category",
			dataSource: FrontEndSelectDataSource.create(categoriesList)
		});
		vm.formHandler = FormFilter.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "String",
					id: "description",
					label: "common.domain.product.description"
				}),
				new FormField({
		       		cellCssClass: "col-sm-2",
					type: "Select",
					id: "active",
					label: "common.domain.product.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
				})
				
			]).add([
				categoriesFormField,
				new FormField({
					cellCssClass: "col-sm-5",
					type: "Select",
					id: "subcategory",
					multiple: true,
					label: "common.domain.product.subcategory",
					dataSource: FormConstants.DataSources.BackEnd.SupplierSubcategory(BackEndSelectDataSource, $translate, categoriesFormField)
				})
				
			])
			.endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.product.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 1 },
			{ headerName: $translate.instant("common.domain.product.description"), field: "description", cellStyle: { alignItems: "center", textAlign: "center" }, width: 40 },
			{ headerName: $translate.instant("common.domain.product.category"), field: "categoryName", width: 25  },
			{ headerName: $translate.instant("common.domain.product.subcategory"), field: "subcategoryName", width: 25  },
			UcGridService.field.ACTIVE()
		]);

		var grid = UcGridService.create(
			{
				gridId: "PRODUCT",
				stateBaseName: "product",
				account: account,
				getFilterData: function () { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
				beforeOnRowClicked: function() { return clearEditTabState(); },
			});

		
		/*
		 * Limpa o tab da página de edição
		 */
		/*private*/ function clearEditTabState()
		{
			TabStatePersister.reset("ProductDomainController");
			
			// Retorna true para não interromper o fluxo da operação
			return true;
		};
		
		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};
		
		function generateReport() {
		/* 	var data = vm.formHandler.readForm();
			delete data["categoryList"];
			delete data["subcategoryList"];
			
			data.generateReport = true;
			DownloadUtil.download('api/product/generate-report', data); */
		}

		function addProdutos() {
var str = `Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Fitometadiona - Injetável - 10 mg/mL (vitamina K1) 	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676454345	Kavit fitomenadiona - Solução injetável - 10 mg/mL - 1 ML						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Azul de Metileno	01.640.262/0001-83	CITOPHARMA MANIPULAÇÃO DE MED. ESPECIAIS LTDA.	CITOPHARMA	NC	Azul de Metileno 10 mL	2	 R$  1,00 	21089883	8/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Azul de Metileno	01.640.262/0001-83	CITOPHARMA MANIPULAÇÃO DE MED. ESPECIAIS LTDA.	CITOPHARMA	NC	Azul de Metileno 10 mL	3	 R$  1,00 	21056705	6/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Cefalotina Sodica 1000mg - Caixa com 50	58.430.828/0013-01	BLAU FARMACEUTICA S.A	BLAU	7896014686964	Cefalotina 1000 mg						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Cefalotina Sodica 1000mg - Caixa com 50	33.258.401/0001-03	INSTITUTO BIOCHIMICO INDUSTRIA FARMACEUTICA	BIOCHIMICO	7897406119060	Cefalotina 1000 mg	71	 R$  1,00 	9213	2/28/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Cetoprofeno 100 mg – pó liofilizado c/ 50	60.665.981/0001-18	UNIÃO QUIMICA FARMACEUTICA NACIONAL S.A	UNIAO QUIM.	7896006252160	Artrinid IV- Cetoprofeno 100mg - Uso EV 	22	 R$  1,00 	2118144	5/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Citrato de fentanila 78,5 mcg/mL – ampola de 2 mL 	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676428889	Fentanest- Citrato de Fentanila 0,0785 mcg/mL	45	 R$  1,00 	21060152	6/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Clindamicina, Fosfato 150mg/mL - 4 mL - Caixa com 100 ampolas	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898123905028	Fosfato de Clindamicina 150mg/ml - 4 mL	97	 R$  1,00 	AA-005/21	3/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Frasco	Sim	Cloreto de Suxametônio 100 mg - Caixa com 10 frascos	58.430.828/0013-01	BLAU FARMACEUTICA S.A	BLAU	7896014682287	Succitrat- Cloreto de Suxametônio 100 mg	10	 R$  1,00 	20080080	6/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Cloridrato de Clonidina 150mg/mL – ampola de 1mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676406979	Clonidin - Cloridrato de clonidina 150mcg/mL-1mL	29	 R$  1,00 	20080366	8/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Sim	Cloridrato de Dextrocetamina 50mg/mL - 2mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676429350	Ketamin  - Cloridrato de Dextrocetamina 50mg/mL -2mL	18	 R$  1,00 	21060884	6/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Sim	Cloridrato de Dextrocetamina 50mg/mL - 2mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676429329	Ketamin NP - Cloridrato de Dextrocetamina 50mg/mL -2mL	50	 R$  1,00 	21070578	7/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Sim	Cloridrato de Dexmedetomidina 100 mcg/mL - ampola 2 mL c/ 5	14.665.928/0001-08	VOLPHARMA DISTRIBUIDORA DE PROD. FARMAC.	GLANDPHARMA	7898958337049	Cloridrato de Dexmedetomidina 100mcg/mL-2mL						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Sim	Cloridrato de Dexmedetomidina 100 mcg/mL - ampola 2 mL c/ 5	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676431858	Dex- Cloridrato de Dexmedetomidina 100mcg/mL						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Sim	Citrato de Fentanila 50 mcg/mL – ampola de 10 mL 	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898470680791	Citrato de fentanila 50 mcg/mL – ampola de 10 mL 	1	 R$  1,00 	AS 232/20	7/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Cloridrato de Ondansetrona 2mg/mL – ampola de 2 mL	56.994.502/0001-30	NOVARTIS BIOCIÊNCIAS S.A	NOVARTIS	7896261020801	 Zofran - Cloridrato de Ondansetrona 4mg/2mL	26	 R$  1,00 	HJ0562A	4/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Comprimido	Não	Cloridrato de Ondansetrona 4 mg - Caixa com 10 cpr	49.475.833/0014-12	BIOLAB SANUS FARMACEUTICA LTDA	BIOLAB	7896241296523	Vonau Flash - 4mg (Clorid. de Ondansetrona) 10 cpr	4	 R$  1,00 	1050651	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Cloridrato de Prometazina 25 mg/mL – ampola de 2mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676403503	Pamergan- Cloridrato de Prometazina 25mg/mL						
Controláveis	Medicamento	Med. Cirurgia	Comprimido	Sim	Diazepam 10 mg - comprimido 	05.161.069/0001-10	BRAINFARMA INDUSTRIA QUIMICA E FARMACEUTICA 	BRAINFARMA	7896714232980	Diazepam 10mg - Cpr - Med. Genérico	98	 R$  1,00 	B21F0607	6/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Diazepam 5 mg/mL – ampola de 2 mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676400533	Compaz - Diazepam 10mg/2mL 	50	 R$  1,00 	21060886	6/30/2024	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Dipirona 500mg/mL – ampola de 2 mL	17.159.229/0001-76	LABORATORIO TEUTO BRASILEIRO S/A	TEUTO	7896112126584	 Dipirona Monoidratada-  500mg/mL- 2mL	120	 R$  1,00 	26584405	6/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Dipirona 500mg/mL – ampola de 2 mL	04.099.395/0001-82	SANTISA LABORATORIO FARMACEUTICO	SANTISA	7898404220147	Santidor - Dipirona Monoidratada- 500mg/mL	27	 R$  1,00 	11511921	7/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Cloridrato de Petidina 50mg/mL - 2 mL - Caixa com 25 ampolas	60.665.981/0005-41	UNIÃO QUIMICA FARMACEUTICA NACIONAL S.A	UNIAO QUIM.	7896006248910	Dipropionato de Betametasona+Fosfato de Betamet.	30	 R$  1,00 	2123741	6/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Diprospan	05.161.069/0001-10	BRAINFARMA INDUSTRIA QUIMICA E FARMACEUTICA 	BRAINFARMA	7898095343125	Dipropionato de Betametasona+Fosfato de Betamet.	10	 R$  1,00 	B20H2376	10/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Efedrina 50 mg/mL – ampola de 1 mL	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898470682825	Sulfato de Efedrina 50mg/mL- IM/IV/SC	38	 R$  1,00 	AM-003/21	12/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Cloridrato de Petidina 50mg/mL - 2 mL - Caixa com 25 ampolas	60.665.981/0005-41	UNIÃO QUIMICA FARMACEUTICA NACIONAL S.A	UNIAO QUIM.	7896006264811	Cloridrato de Petidina 50mg/mL - IM/IV/SC	25	 R$  1,00 	2006835	2/28/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Epinefrina 1mg/mL – ampola de 1 mL	17.174.657/0001-78	INSTITUTO DE HYPODERMIA E FARMACIA LTDA	HYPOFARMA	7898122914595	Hyfren- Epinefrina 1mg/ml  - 1mL	100	 R$  1,00 	21030547	3/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Furosemida 20 mg  Injetável	06.628.333/0001-46	FARMACE - INDUSTRIA QUIMICO-FARMACEUTICA CEARENSE LTDA	FARMACE	7898166042766	Furosefarma- Furosemida 10mg/ml- 2mL	90	 R$  1,00 	FS21E029	5/1/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Midazolam 1mg/mL – ampola de 5 ml	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676426120	Dormire  (Midazolan) 5mg/5mL	6	 R$  1,00 	21060785	6/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Midazolam 5mg/mL – ampola de 3 mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676426144	Dormire  (Midazolan) 15mg/3 mL	50	 R$  1,00 	21090636	9/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Neocaina 0,5% s/ vaso (Bupivacaína)	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676400588	Neocaína - Cloridrato de Bupivacaína  0,5 %- 20 ml	50	 R$  1,00 	21040142	4/30/2024	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Nitroprusseto de sódio 25 mg/mL – ampola de 2 mL	17.174.657/0001-78	INSTITUTO DE HYPODERMIA E FARMACIA LTDA	HYPOFARMA	789812910955	Nitrop - Nitroprusseto de Sódio 25mg/ml- 2 mL						
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Norepinefrina  2mg/ml- 4 mL	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898123907817	Hemitartarato de Norepinefrina 2mg/mL- 4mL						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Omeprazol 40 mg- Injetável	58.430.828/0001-60	BLAU FARMACEUTICA S.A	BLAU	7896014688630	Oprazon - Omeprazol 40 mg - Injetável	8	 R$  1,00 	21020029B	1/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Procedimento	Ampola	Não	PillFood - 2mL	04.238.160/0001-24	HEALTH TECH FARMACIA DE MANIPULAÇÃO LTDA	HEALTH TECH	NC	PillFood - 2mL 	220	 R$  1,00 	29708	8/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Sim	Propofol 10 mg/mL – frasco-ampola de 20 mL 	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676425956	Propovan- Propofol 10mg/mL- 20 mL						
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Propofol 10mg/mL – Ampola de 20 ml 	17.159.229/0001-76	LABORATORIO TEUTO BRASILEIRO S/A	TEUTO	18904240104087	Nirfol 1% - 20 mL - Profofol Injection BP 1%						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Ropivacaína, cloridrato  7,5 mg/mL - 20 mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676415575	Ropi- Cloridrato de Ropivacaína 7,5mg/mL- 20 mL						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Ropivacaína, cloridrato  10 mg/mL - 20 mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676415568	Ropi- Cloridrato de Ropivacaína 10 mg/mL- 20 mL	40	 R$  1,00 	21070756	7/31/2024	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Ropivacaína, cloridrato  10 mg/mL - 20 mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676415568	Ropi- Cloridrato de Ropivacaína 10 mg/mL- 20 mL	10	 R$  1,00 	21030042	3/31/2024	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Frasco	Não	Shampoo Triclosan 1% 300 ml							 				10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Frasco	Não	Succinato Sódico de Hidrocortisona – 100mg -IM/EV	17.159.229/0001-76	LABORATORIO TEUTO BRASILEIRO S/A	TEUTO	7896112125952	Androcortil - Succinato de Hidrocortisona 10mg						
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Sulfato de atropina 0,5 mg/mL – ampola de 1 mL	17.174.657/0001-78	INSTITUTO DE HYPODERMIA E FARMACIA LTDA	HYPOFARMA	7898122910993	Hytropin- Sulfato de Atropina 0,5mg/mL - 1 mL	30	 R$  1,00 	20030448	3/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Tartarato de metoprolol 1 mg/mL – ampola de 5mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676432145	Betacris -Tartarato de Metoprolol - 1mg/mL - 5mL						
Controláveis	Medicamento	Med. Procedimento	Frasco	Não	Tonico Capilar 200 ml							 				10/16/2021
Controláveis	Medicamento	Med. Procedimento	Comprimido	Não	Trometanol Cetorolaco 10mg- cpr Sublingual	00.923.140/0001-31	NOVAMED FABRICAÇÃO DE PRODUTOS FARMACEUTICOS	NOVAMED	7894916501415	Toragesic -Trometamol cetorolaco 10 mg- cpr subling.	7	 R$  1,00 	2F0108	2/28/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Ampola	Não	Triancil  20mg/mL- 1mL - Intra Articular	62.462.015/0001-29	APSEN FARMACÊUTICA S/A	APSEN	7896637022811	Triancil- Triancinolona hexacetonida 20mg/ml- 1mL	5	 R$  1,00 	21050002	5/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Ampola	Não	Triancil  20mg/mL- 1mL - Intra Articular	62.462.015/0001-29	APSEN FARMACÊUTICA S/A	APSEN	7896637022811	Triancil- Triancinolona hexacetonida 20mg/ml- 1mL	1	 R$  1,00 	21040001	4/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Hypocaína (Lidocaína)  2 %-  com Epinefrina	17.174.657/0001-78	INSTITUTO DE HYPODERMIA E FARMACIA LTDA	HYPOFARMA	7898122911785	Hypocaína (Lidocaína)  2 %-  Com Epinefrina - 20 mL	15	 R$  1,00 	21091666	9/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Xylestesin (Lidocaína)  2 %-  com Epinefrina	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676400663	Xylestesin - Lidocaína 2% - Com  Epinefrina - 20 mL	40	 R$  1,00 	21010140	1/31/2024	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Xylestesin  (Lidocaina) 2%  - sem Epinefrina  	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676400670	Xylestesin - Lidocaína 2% - Sem  Epinefrina - 20 mL						
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Xylestesin (Lidocaína)  2 %-  sem Epinefrina (Tubete)	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676401967	Xylestesin - Lidocaína 2% - Sem  Epinefrina -Tubete 1,8ml	31	 R$  1,00 	20220686	11/30/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Comprimido	Sim	Clonazepam 0,25mg - Comprimidos	33.009.945/0023-39	PRODUTOS ROCHE QUIMICOS E FARMACEUTICOS S.A	ROCHE	7896226504742	Rivotril 0,25mg - comprimidos 						
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Dobutamina 250 mg/20 ml							 				10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Comprimido	Sim	Midazolan 15mg - Comprimido	33.009.945/0023-39	PRODUTOS ROCHE QUIMICOS E FARMACEUTICOS S.A	ROCHE	7896226502465	Dormonid- Midazolam - 15 mg - Comprimidos	74	 R$  1,00 	RJ0460	9/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Comprimido	Sim	Cloridrato de Tramadol 100 mg - Comprimido	10.555.143/0001-13	GRUNENTHAL DO BRASIL FARMACEUTICA LTDA	GRUNENTHAL	7898582380312	Tramal Retard 100 mg -Tramadol - Comprimidos						
Controláveis	Medicamento	Med. Cirurgia	Comprimido	sim	Cloridrato de Tramadol 50 mg - Comprimido	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898123909446	Cloridrato de Tramadol 50mg - Comprimidos	498	 R$  1,00 	0637/21	7/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Citrato de Fentanila 50 mcg/mL – ampola de 10 mL 	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898470680791	Citrato de fentanila 50 mcg/mL – ampola de 10 mL 						
Controláveis	Medicamento	Med. Procedimento	Comprimido	Sim	Codeína 30mg + Paracetamol 500mg- Comprimidos	51.780.468/0001-87	JANSSEN-CILAG FARMACEUTICA LTDA	JANSSEN	7896212425556	Tylex 30 mg - Comprimidos						
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Flumazenil 0,1mg/mL- ampola 5 mL	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676427950	Flumazil 0,1mg/ml - Flumazenil - 5 mL	7	 R$  1,00 	20040069	4/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Ampola	Sim	Etomidato 2mg/ml- 10 Ml	44.734.671/0001-51	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676408041	Etomidato 2mg/mL - 10 mL - Med. Genérico						
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Cloridarto de Metoclopramida 10 mg/2mL	17.159.229/0001-76	LABORATÓRIO TEUTO BRASILEIRO S/A	TEUTO	7896112183693	Cloridrato de Metoclopramida 10mg/2 mL	64	 R$  1,00 	NAO INFORMADO	1/1/2100	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Sim	Droperidol 2,5mg/ml 	44.734.671/0008-28	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676420333	Droperdal - Droperidol 2,5mg/mL 	51	 R$  1,00 	21020157	2/28/2024	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Ampola	Não	Àcido Tranexâmico 50 mg/mL	05.254.971/0001-81	ZYDUS NIKKHO FARMACÊUTICA LTDA	ZYDUS	7897460400364	Transamin- Ácido Tranexâmico 50mg/ml	6	 R$  1,00 	1.220.009	12/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Ampola	Não	Amiodarona 50 mg/ml	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898123907749	Cloridrato de Amiodarona 50mg/mL - Genérico	100	 R$  1,00 	AD-023/20	8/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Frasco	Não	Salbutamol 100mcg/dose - 200 doses	33.247.743/0001-10	GLAXO SMITHKLAINE BRASIL LTDA	GLAXO	789626900150	Aerolin Spray- Sulfato de Salbutamol 100 mcg/dose						
Controláveis	Medicamento	Carrinho Urgência	Comprimido	Não	Isordil Comprimido 10mg	00.923.140/0001-31	NOVAMED FABRICAÇÃO DE PRODUTOS FARMACEUTICOS	NOVAMED	7894916550086	Isordil (Isossorbida) 10 mg - Cpr	15	 R$  1,00 	1V1255	7/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Comprimido	Não	Captopril 25mg  -Cpr	10.588.595/0010-92	SANOFI MEDLEY FARMACEUTICA LTDA	MEDLEY	7896422503709	Captopril 25mg- Cpr - Medicamento Genérico	29	 R$  1,00 	4KP09427	7/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Tubo	Não	Neomicia + Bacitracina - Pomada  15 g	05.044.984/0001-26	LEGRAND PHARMA INDUSTRIA FARMACEUTICA LTDA	LEGRAND	7896004811574	Neotricin (Neomicina + Bacitracina) 15g- Pomada	4	 R$  1,00 	1M5843	2/28/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Carrinho Urgência	Tubo	Não	Lidocaína Gel 5%	57.507.378/0003-65	E.M.S S/A	E.M.S	7896004722467	Lidocaína 50mg/g - 25gr						
Controláveis	Medicamento	Med. Procedimento	Frasco	Não	Lidocaína 23% + Tetracaína 7% + Creme Base qsp - 100 mL	23.271.638/0010-53	TOKARSKI & CIA. LTDA - (FARMACIA ARTESANAL)	ARTESANAL	NC	Lidocaína 23% +Tetracaína 7% +Creme Base qsp 100 mg						
Controláveis	Medicamento	Med. Procedimento	Tubo	Não	Lidocaina 4% Creme Dermatológico - 30 g	60.659.463/0001-91	ACHE LABORATÓRIOS FARMACEUTICOS	ACHE	7896181909088	DERMOMAX (Lidocaína) 4% -30 gr- Creme 	1	 R$  1,00 	1915695	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Frasco	Não	ATA 70% - 10 mL	23.271.638/0010-53	TOKARSKI & CIA. LTDA - (FARMACIA ARTESANAL)	ARTESANAL	NC	ATA 70% ; água qsp - 10 mL	1	 R$  1,00 	6146	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Frasco	Não	ATA 35% - 10 mL	23.271.638/0010-53	TOKARSKI & CIA. LTDA - (FARMACIA ARTESANAL)	ARTESANAL	NC	ATA 35% ; água qsp - 10 mL	1	 R$  1,00 	6147	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Tubo	Não	Acido Retinóico  5% - 10 g	23.271.638/0010-53	TOKARSKI & CIA. LTDA - (FARMACIA ARTESANAL)	ARTESANAL	NC	Ácido Retinóico 5%; Color qsp - 10 g	1	 R$  1,00 	6148	7/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Tubo	Não	Clobetasol 0,5mg - Creme - 50 Gr	23.271.638/0010-53	TOKARSKI & CIA. LTDA - (FARMACIA ARTESANAL)	ARTESANAL	NC	Clobetasol 0,5mg - Creme - 50 Gr	1	 R$  1,00 	NAO INFORMADO	1/1/2100	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Frasco	Não	Solução para Peeling - 10 mL	23.271.638/0010-53	TOKARSKI & CIA. LTDA - (FARMACIA ARTESANAL)	ARTESANAL	NC	Ácido Salicílico 14% ; Resorcina 14%; Ác. Lático 14%	1	 R$  1,00 	6149	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Comprimido	Não	Omeprazol 20 mg - Cápsulas duras	17.159.229/0001-76	LABORATORIO TEUTO BRASILEIRO S/A	TEUTO	7896112148715	Omeprazol 20mg - Cápsulas duras - 28 cps.	25	 R$  1,00 	8711000	9/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Cloridrato de Nafazolina 0,5mg/mL - 30 mL	05.161.069/0001-10	BRAINFARMA INDUSTRIA QUIMICA E FARMACEUTICA 	BRAINFARMA	7896714231143	Neosoro (Clorid. Nafazolina) 0,5mg/mL - 30 mL	1	 R$  1,00 	B20M0010	12/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Tubo	Não	Retinol + Aminoácidos+Metionina+Cloranfenicol -3,5g -Oftalmico	44.734.671/0023-67	CRISTALIA PRODUTOS QUIMICOS FARMACEUTICOS LTDA	CRISTALIA 	7896676432886	Regencel- Pomada Oftálmica Estéril - 3,5 g	2	 R$  1,00 	21000400	10/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Comprimido	Não	Prednisolona 20 mg - Caixa com 10 comprimidos	05.161.069/0001-10	BRAINFARMA INDUSTRIA QUIMICA E FARMACEUTICA 	BRAINFARMA	7891142177056	Predsim 20mg (Prednisolona) - Cxa. 10 cpr 	16	 R$  1,00 	B20D2902	5/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Frasco	Não	Dexpantenol 50 mL- Solução Regeneradora 	25.859.018/0001-74	NUTRACOM INDUSTRIA E COMÉRCIO LTDA	NUTRACOM	7897947612730	Bepantriz Derma - Solução Regeneradora 50 mL	1	 R$  1,00 	2022756	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Tubo	Não	Benzocaína 200mg/g - 12 g- Uso tópico	33.112.665/0001-46	DFL INDÚSTRIA E COMÉRCIO S.A	DFL	7894590503910	Benzotop 200mg/g - Anestésico Tópico Local-  12g	1	 R$  1,00 	2010T0086	10/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Procedimento	Frasco	Não	Tetracaína1%  + Fenilefrina 0,1% - 10 mL - Colirio Anestésico	43.426.626/0001-77	ALLERGAN PRODUTOS FARMACÊUTICOS LTDA 	ALLERGAN	7897316801079	Anestésico Colirio - 10 ml 	1	 R$  1,00 	F67081	11/30/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Trometamol Cetorolaco 30mg/mL - 1mL  - IM/IV	19.570.720/0001-10	HIPOLABOR FARMACEUTICA LTDA	HIPOLABOR	7898470684218	Trometamol Cetorolaco 30mg/ml - 1mL- Genérico	50	 R$  1,00 	BH-001/20	3/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Glicose 50% 20 ml	04.459.117/0001-99	SAMTEC BIOTECNOLOGIA LTDA.	SAMTEC	7898415823122	Glicose Hipertônica 50% - 20 mL	30	 R$  1,00 	GTX	3/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Glicose 50% 20 ml	04.459.117/0001-99	SAMTEC BIOTECNOLOGIA LTDA.	SAMTEC	7898415823122	Glicose Hipertônica 50% - 20 mL	25	 R$  1,00 	GTX	3/31/2022	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Glicose 50% 20 ml	02.281.006/0001-00	ISOFARMA INDUSTRIAL FARMACEUTICA LTDA	ISOFARMA	7898361700218	Glicose Hipertônica 50% - 20 Ml	50	 R$  1,00 	1030231	3/31/2023	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Gliconato de Calcio 10% 10 ml	01.571.702/0001-98	HALEX ISTAR INDUSTRIA FARMACEUTICA S.A	HALEX ISTAR	7898361700485	Gliconato de Cálcio 10% - 10 mL	200	 R$  1,00 	90070	9/30/2022	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Soro Fisiológico 0,9%- 10ml	06.628.333/0001-46	FARMACE - INDUSTRIA QUIMICO-FARMACEUTICA CEARENSE LTDA	FARMACE	7898166040342	Soro Fisiológico 0,9% - 10 mL	190	 R$  1,00 	21E8354C	5/31/2023	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Soro Fisiológico 0,9% - 500ml	32.673.254/0001-02	LABORATÓRIOS B.BRAUN S.A	BBRAUN	7891543000076	Soro Fisiológico 0,9% - 500ml	24	 R$  1,00 	21135040D1	4/30/2024	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Soro Fisiológico 0,9% - 250ml							 				10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Soro Fisiológico 0,9% - 100 ml	01.784.792/0001-03	EQUIPLEX INDUSTRIA BRASILEIRA	EQUIPLEX	789800767157	Soro Fisiológico 0,9% - 100 ml	45	 R$  1,00 	2114093	7/31/2023	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	ABD 10 ml 	06.628.333/0001-46	FARMACE - INDUSTRIA QUIMICO-FARMACEUTICA CEARENSE LTDA	FARMACE	7898166040328	ABD 10 mL	145	 R$  1,00 	20J9136	10/31/2022	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	ABD 10 ml 	06.628.333/0001-46	FARMACE - INDUSTRIA QUIMICO-FARMACEUTICA CEARENSE LTDA	FARMACE	7898166040328	ABD 10 mL	52	 R$  1,00 	20J9136	10/31/2022	Farmácia	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	ABD 500 ml	49.324.221/0001-04	FRESENIUS KABI BRASIL LTDA	FRESENIUS	7897947706699	ABD 500 mL	10	 R$  1,00 	2113932	6/30/2023	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Frasco	Não	Ringer Lactato 500 ml	49.324.221/0001-04	FRESENIUS KABI BRASIL LTDA	FRESENIUS	7897947706644	Ringer com Lactato - 500 mL						
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Bicarbonato de Sodio 8,4% 10 ml	17.174.657/0001-78	INSTITUTO DE HYPODERMIA E FARMACIA LTDA	HYPOFARMA	17898415826014	Bicarbonato de Sódio 8,4%- 10 mL	200	 R$  1,00 	BIY	6/30/2023	Estoque	10/16/2021
Controláveis	Medicamento	Med. Cirurgia	Ampola	Não	Bicarbonato de Sodio 8,4% 10 ml	04.459.117/0001-99	SAMTEC BIOTECNOLOGIA LTDA.	SAMTEC	7898415826017	Bicarbonato de Sódio 8,4%- 10 mL	9	 R$  1,00 	BGR	9/30/2022	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Monocryl 3.0 - Ref. Y936	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)47891338050352	Monocryl 3.0 - Ref. 936	20	 R$  1,00 	AN4633	3/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Monocryl 5.0 - Ref. Y495G	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)278913380503065(10)AK6225	Fio Monocryl 5.0 - Ref. Y495G	23	 R$  1,00 	AK6225	4/30/2023	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 2.0 - 1215	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338320416(17)260331(10)AP5313	Fio Mononylon 2.0 - 1215	3	 R$  1,00 	AP5313	3/31/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 3.0 - P1163 -Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338360061(17)240331(10)AL9543	Fio Mononylon 3.0 - P1163 -Plástica	11	 R$  1,00 	AL9543	3/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 3.0 - P1163 -Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338360061(17)240331(10)AL9543	Fio Mononylon 3.0 - P1163 -Plástica	24	 R$  1,00 	AM4797	7/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 4.0 - P1662 - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338360016 - B	Mononylon 4-0 - Ref. P1662 - Plástica	24	 R$  1,00 	AM5507	8/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 4.0 - P1111T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338360016	Mononylon 4-0 - Ref. P1111T- Plástica	18	 R$  1,00 	AN1277	12/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 4.0 - P1111T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338360016	Mononylon 4-0 - Ref. P1111T- Plástica	4	 R$  1,00 	AL8685	2/28/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 4.0 - P1111T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)1789	Mononylon 4-0 - Ref. 13502	3	 R$  1,00 	AP3210	1/31/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 5.0 - P1668T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)1789133832028	Mononylon 5-0 - Ref. P1668T -Plástica	13	 R$  1,00 	AM2832	5/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 5.0 - P1668T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)1789133832028	Mononylon 5-0 - Ref. P1668T -Plástica	24	 R$  1,00 	AM6621	8/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 5.0 - 13501T - Cuticular	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338320270 - B	Mononylon 5-0 - Ref. 13501 T - Cuticular	7	 R$  1,00 	AM2025	4/30/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 6.0 - 13500T- Cuticular	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338320270	Mononylon 6-0 - Ref. 13500T - Cuticular	3	 R$  1,00 	AN8922	8/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 6.0 - 13500T- Cuticular	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338320270	Mononylon 6-0 - Ref. 13500T - Cuticular	7	 R$  1,00 	AN7010	5/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Mononylon 6.0 - 13500T- Cuticular	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)17891338320270	Mononylon 6-0 - Ref. 13500T - Cuticular	4	 R$  1,00 	AL7343	1/31/2024	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Fio Nylon 4.0 - 45cm- 3/8 - NY44CT20	02.400.787/0001-04	ACE INDUSTRIA E COMERCIO LTDA	TECHNOFIO	7898483780723	Fio Nylon 4.0 - 45cm- 3/8 - Ref. NY44CT20	12	 R$  1,00 	32191015	8/31/2024	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Fio Nylon 5.0 - 45cm - 3/8 - NY54CT20	02.400.787/0001-04	ACE INDUSTRIA E COMERCIO LTDA	TECHNOFIO	7898433780730	Fio Nylon 5.0 - 45cm - 3/8 -Ref. NY54CT20	24	 R$  1,00 	6210178	2/28/2026	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Fio Nylon 6.0 - 45cm - 3/8 - NY64CT20	02.400.787/0001-04	ACE INDUSTRIA E COMERCIO LTDA	TECHNOFIO	7898483780747	Fio Nylon 6.0 - 45cm - 3/8 -Ref. NY64CT20	23	 R$  1,00 	1210027	1/31/2026	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Fio Nylon 6.0 - 45cm - 3/8 - NY64CT20	02.400.787/0001-04	ACE INDUSTRIA E COMERCIO LTDA	TECHNOFIO	7898483780747	Fio Nylon 6.0 - 45cm - 3/8 -Ref. NY64CT20	11	 R$  1,00 	16180628	5/31/2023	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Vicryl 3.0 -  70 cm - VR9360G- Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)27891338070370	Fio Vicryl 3.0 -  70 cm - Ref. VR9360G- Plástica	3	 R$  1,00 	AJ2430	6/30/2022	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Vicryl 5.0 (Rapid) - VR4930G - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)27891338070356	Fio Vicryl 5.0 (Rapid) - Ref. VR4930G - Plástica	22	 R$  1,00 	AM2573	5/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Prolene Azul 5.0 - 45cm -  P8686T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)37891338220642	Fio Prolene Azul 5.0 - 45cm -Ref.  P8686T - Plástica	24	 R$  1,00 	AN8525	7/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fio Prolene Azul 5.0 - 45cm -  P8686T - Plástica	54.516.661/0002-84	JOHNSON & JOHNSON DO BRASIL	J & J	(01)37891338220642	Fio Prolene Azul 5.0 - 45cm -Ref.  P8686T - Plástica	9	 R$  1,00 	AN2110	1/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fita Dupla Face - 3M	08.014.346/0001-50	3M do BRASIL LTDA	3M	7891040121403	Fita Dupla Face - Fixa Forte	3	 R$  1,00 	NAO INFORMADO	12/31/2027	Estoque	10/16/2021
Material	Perfumaria	Material Cirurgia	Unidade	Não	Fralda Geriátrica EGX  c/ 7	22.010.816/0001-39	ACTIVE IND. DE COSMÉTICOS S.A	BIGFRAL	NC	Fralda Descartável EXG - c/ 7	2	 R$  1,00 	S21G0102	7/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fronha Descartavel							 				10/16/2021
Material	Material Médico	Material Cirurgia	Pacote	Não	Gaze Hidrófila Estéril - 7,5 x 7,5 -Pacote c/ 10	24.039.668/0001-00	Medpack Industria e Comercio de Produtos Cirurgicos e Hospitalares LTDA	STELLAFLEX	7908172601717	Gazinha 7,5 x 7,5 - Pacote com 10 Unidades	50	 R$  1,00 	2103	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Gola Higienica	57.407.397/0001-58	SANTA CLARA MANUFATURA E COSMETICOS LTDA	SANTA CLARA	7897169221765	Gola Higiênica - Rolo com 100 Unidades	3	 R$  1,00 	NAO INFORMADO	12/31/2030	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi n° 10  - Southmedic							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi nº 10 	00.000.000/0000-00	INTEGRA YORK PA INC - Importado Reg. Num. 2523190	MILTEX	*+H83446101%*	Lâmina de Bisturi nº 10	50	 R$  1,00 	A11B01	2/28/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi nº 10 	84.966.498/0001-31	MAXICOR PRODUTOS MÉDICOS	MAXICOR	7898931988015	Lâmina de Bisturi nº 10	123	 R$  1,00 	1811003	10/31/2023	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi nº 11 	84.966.498/0001-31	MAXICOR PRODUTOS MÉDICOS	MAXICOR	7898931988244	Lâmina de Bisturi nº 11	100	 R$  1,00 	171202	12/31/2022	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi nº 11 	84.966.498/0001-31	MAXICOR PRODUTOS MÉDICOS	MAXICOR	7898931988244	Lâmina de Bisturi nº 11	100	 R$  1,00 	201102	11/30/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi nº 15  	01.005.728/0011-40	LABOR IMPORT COM. IMP. EXP. LTDA	SOLIDOR	(01)17898157722933	Lâmina de Bisturi nº 15	36	 R$  1,00 	23120031	3/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lâmina Bisturi nº 15  	01.005.728/0011-40	LABOR IMPORT COM. IMP. EXP. LTDA	SOLIDOR	(01)17898157722933	Lâmina de Bisturi nº 15	36	 R$  1,00 	23120031	3/31/2025	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Lapis Branco	59.596.908/0007-48	FABER CASTEL S.A	FABERCASTEL	NC	Lapiseira de Demarcação Corporal- Restylane	2	 R$  1,00 	NAO INFORMADO	1/1/2100	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Latex/silicone							 				10/16/2021
Material	Material Médico	Mat. Procedimento	Pacote	Não	Lenço Umedecido	02.290.277/0019-50	KIMBERLY CLARK BRASIL INDUSTRIA E COMERCIO 	KIMBERLY	7896018703636	Lenço Umedecido 	2	 R$  1,00 	NAO INFORMADO	12/31/2030	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Pacote	Não	Lençol de Papel 50x50	10.235.337/0001-31	HNDESC PAPER	HNDESC	7898567862062	Lençol Rolo Hospitalar	10	 R$  1,00 	119043001	3/31/2030	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Pacote	Não	Lençol de Papel 50x50	07.327.320/0001-08	PLUMAX LENÇOIS HOSPITALARES	PLUMAX	7899048330012	Lençol Rolo Hospitalar	6	 R$  1,00 	1502211071	2/28/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lençol com elástico	69.348.936/0001-04	ANADONA COM. E CONF. LTDA	ANADONA	7898433200875	Lençol Descartável com elástico						
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica  Esteril SEM PÓ - 6,5	00.157.774/0005-54	TARGA S.A	LEMGRUBER	7898538970512	Luva Cirurgica Estéril - 6,5 (SEM PÓ)	170	 R$  1,00 	LUPF012K	7/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica Esteril SEM PÓ - 6,5	12.936.659/0001-33	KAVENOLL DO BRASIL PRODUTOS 	NUZONE	7898949349099	Luva Cirurgica Estéril - 6,5 (SEM PÓ)	11	 R$  1,00 	3271065	4/30/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica Esteril SEM PÓ - 7,0	12.936.659/0001-33	KAVENOLL DO BRASIL PRODUTOS 	NUZONE	789899349105	Luva Cirurgica Estéril - 7,0 (SEM PÓ)	34	 R$  1,00 	3508170	6/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica  Esteril COM PÓ - 6,5	12.918.336/0001-17	BECARE COM. PROD. HOSPITALARES	BECARE	7899767469068	Luva Cirurgica Estéril- 6,5 (COM PÓ)	160	 R$  1,00 	300421	4/30/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica  Esteril COM PÓ - 7,0	70.939.574/0001-05	FÁBRICA DE ARTEFATOS DE LATEX SÃO ROQUE LTDA	SANRO	7896243101153	Luva Cirurgica Estéril - 7,0 ( COM PÓ)	150	 R$  1,00 	90211	3/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirugica  Esteril COM PÓ - 7,5 	70.939.574/0001-05	FÁBRICA DE ARTEFATOS DE LATEX SÃO ROQUE LTDA	SANRO	7896243101177	Luva Cirurgica Estéril - 7,5 ( COM PÓ)	70	 R$  1,00 	169211	6/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica  Esteril SEM PÓ -  7,5	15.107.246/0001-34	MUCAMBO S.A - 	MUCAMBO	7896098811535	Luva Cirurgica Estéril - 7,5 ( SEM PÓ)	57	 R$  1,00 	2110	4/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica  Esteril SEM PÓ -  7,5	12.936.659/0001-33	KAVENOLL DO BRASIL PRODUTOS 	NUZONE	7898949349112	Luva Cirurgica Estéril - 7,5 (SEM PÓ)	5	 R$  1,00 	3508175	6/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirurgica Esteril SEM LÁTEX -7,5	15.107.246/0001-34	MUCAMBO S.A - 	MUCAMBO	7896098805626	Luva Cirúrgica Estéril - 7,5 (SEM LÁTEX)	37	 R$  1,00 	2114	4/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirúrgica Estéril nº 8,0	15.107.246/0001-34	MUCAMBO S.A - 	MUCAMBO	7896098810637	Luva Cirúrgica Estéril - 8,0 (COM PÓ)	30	 R$  1,00 	2102	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Cirúrgica Estéril nº 8,5	12.936.659/0001-33	KAVENOLL DO BRASIL PRODUTOS 	NUZONE	7898949349068	Luva Cirúrgica Estéril - 8,5 (COM PÓ)	30	 R$  1,00 	3529285	7/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Luva Estéril Plástica c/  100	07.147.013/0001-37	FABRILAST 	LUPLATEX	7898941475017	Luva Plástica - Descartável - Estéril - Toque 	44	 R$  1,00 	709	10/31/2022	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Procedimento P   - Látex	12.936.659/0001-33	KAVENOLL DO BRASIL PRODUTOS 	NUGARD	7898994049821	Luva de Procedimento P- Látex- Caixa com 100 Unid.	50	 R$  1,00 	2142B20	7/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Procedimento M  - Látex	00.157.774/0005-54	TARGA S.A	LEMGRUBER	7898538970314	Luva de Procedimento M- Látex- Caixa com 100 Unid.	45	 R$  1,00 	PR006L	3/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Procedimento M  - Látex	01.057.428/0002-14	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283814673 - B	Luva de Procedimento M- Látex- Caixa com 100 Unid.	100	 R$  1,00 	SLTCAA586	1/1/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Procedimento M  - Vinil	10.268.780/0001-09	MEDIX BRASIL PRODUTOS HOSP.	MEDIX	7898947170473	Luva de Procedimento M-Vinil -  Caixa com 100 Unid.	250	 R$  1,00 	20107539	12/31/2021	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Luva Procedimento G  - Vinil	01.057.428/0002-14	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283814673	Luva de Procedimento G - Vinil  -Caixa com 100 Unid.						
Material	Material Médico	Mat. Procedimento	Par	Não	Luva Procedimento M  - Nitrílica	10.268.780/0001-09	 MEDIX BRASIL PRODUTOS HOSPITALARES E ODONT. LTDA	MEDIX	7898652371394	Luva de Procedimento M-Nitrílica -Caixa com 100 Und						
Material	Material Médico	Material Cirurgia	Unidade	Não	Malha Tubular 	72.819.980/0001-60	MSO MATERIAL HOSPITALAR LTDA	MSO	7898466770079	Malha Tubular Ortopédica	3	 R$  1,00 	100572	1/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Máscara Descartável com elástico	11.671.812/0001-85	DESCARBOX INDUSTRIA E COMÉRCIO LTDA	DESCARBOX	7898951599949	Máscara Descartável com Elástico	400	 R$  1,00 	5673	4/30/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Máscara  PFF2	08.025.426/0001-01	DELTA PLUS BRASIL IND. COM. LTDA	DELTA 	7898554013958	Máscara PFF2 sem válvula 	60	 R$  1,00 	1234	5/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Par	Não	Propé  Descartável	87.395.844/0001-67	RASKALO PRODUTOS DE BELEZA LTDA	RASKALO	NC	Propé Descartável	180	 R$  1,00 	NAO INFORMADO	12/31/2030	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Protetor Braço Cadeira							 				10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Pulseira de Identificação							 				10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Scalp Coleta Vacuo 21G	48.740.849/0001-28	CRAL ARTIGOS PARA LABORATÓRIO LTDA	CRAL	7898599570553	Scalp Coleta Vácuo - 21G	53	 R$  1,00 	20190920	9/30/2022	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Seringa Botox 100UI 8 X 0,33  	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	382908328012	Serina Ultra Fine II - 100 UI	10	 R$  1,00 	9308726	11/30/2024	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Seringa Botox 100UI 8 X 0,33  	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	382908328012	Serina Ultra Fine II - 100 UI	37	 R$  1,00 	9308726	11/30/2024	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Seringa Botox 30UI 8 X 0,33 -Curta	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	382908322010	Seringa Ultra Fine II - 30 UI 						
Material	Material Médico	Mat. Procedimento	Unidade	Não	Seringa Botox 50UI 8X0,33 - Curta	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	382908325011	Seringa Ultra Fine II - 50 UI 	10	 R$  1,00 	9308326	11/30/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 1 ml -Luer Slip - Sem Agulha -Insulina	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000729	Seringa 1 ml -Luer Slipe - Sem Agulha - Insulina	15	 R$  1,00 	113236	6/30/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 1 ml -Luer Slip - Sem Agulha -Insulina	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000729	Seringa 1 ml -Luer Slipe - Sem Agulha - Insulina	1272	 R$  1,00 	113236	6/30/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 1 ml- Luer Slip - Sem Agulha - Insulina	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000729	Seringa 1 ml -Luer Slipe - Sem Agulha - Insulina	29	 R$  1,00 	93803	4/30/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 3mL - Luer Lock - Sem Agulha 	03.426.484/0001-23	SALDANHA RODRIGUES LTDA	SR	7898259495301	Seringa 3 ml - Luer Lock Sem agulha	9	 R$  1,00 	4362N4	10/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 3mL - Luer Lock - Sem Agulha 	03.426.484/0001-23	SALDANHA RODRIGUES LTDA	SR	7898259495424	Seringa 3 ml - Luer Lock - COM  agulha	15	 R$  1,00 	4490N4	10/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 3mL - Luer Lock - Sem Agulha 	03.426.484/0001-23	SALDANHA RODRIGUES LTDA	SR	7898259495424	Seringa 3 ml - Luer Lock - COM  agulha	10	 R$  1,00 	4380N4	10/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa  3 ml - Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000347	Seringa  3 ml - Luer Lock - Sem agulha	90	 R$  1,00 	106311	5/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa  5 ml - Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463001771	Seringa  5 ml - Luer Lock - Sem agulha	45	 R$  1,00 	7054651	3/31/2022	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa  5 ml - Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463001771	Seringa  5 ml - Luer Lock - Sem agulha	5	 R$  1,00 	7228833	9/30/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa  5 ml - Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463001771	Seringa  5 ml - Luer Lock - Sem agulha	60	 R$  1,00 	7228833	9/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa  5 ml - Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463001771	Seringa  5 ml - Luer Lock - Sem agulha	655	 R$  1,00 	300204	5/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 10 ml -Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000378	Seringa 10 ml -Luer Lock - Sem agulha	110	 R$  1,00 	1132408	6/30/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 10 ml -Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000378	Seringa 10 ml -Luer Lock - Sem agulha	13	 R$  1,00 	1105319	5/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 10 ml -Luer Lock - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000378	Seringa 10 ml -Luer Lock - Sem agulha	270	 R$  1,00 	1105319	5/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 10 ml Terumo	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017501747	Seringa 10 ml -Luer Lock - Sem agulha	52	 R$  1,00 	18040	8/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 20 ml -Luer Slip - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463000408	Seringa 20 ml - Luer  Slip- Sem agulha	7	 R$  1,00 	9190480F	8/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 20 ml -Luer Slip - Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7891463003782	Seringa 20 ml - Luer  Lock- Sem agulha	50	 R$  1,00 	1054299	3/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 20 ml - Luer Lock - Sem agulha	01.057.428/0001-33	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283815113	Seringa 20 ml - Luer Lock - Sem agulha	16	 R$  1,00 	SSLLAA0278	1/31/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 20 ml - Luer Lock - Sem agulha	01.057.428/0001-33	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283815113	Seringa 20 ml - Luer Lock - Sem agulha	16	 R$  1,00 	SSLLAA0278	1/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 60 Ml - Luer Slip- Sem agulha	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903028290	Seringa 60 mL - Luer Slip- Sem agulha	5	 R$  1,00 	9010705	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 60 Ml - Luer Lock- Sem agulha	01.057.428/0001-33	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283813621	Seringa 60 mL - Luer Slip- Sem agulha	2	 R$  1,00 	SSSAAA030	5/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 60 Ml - Luer Lock- Sem agulha	01.057.428/0001-33	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283813638	Seringa 60 mL - Luer Lock- Sem agulha	4	 R$  1,00 	SSSAAA037	5/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 60 Ml - Luer Lock- Sem agulha	01.057.428/0001-33	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283813638	Seringa 60 mL - Luer Lock- Sem agulha	5	 R$  1,00 	SSSAAA046	5/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Seringa 60 Ml - Luer Lock- Sem agulha	01.057.428/0001-33	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283813638	Seringa 60 mL - Luer Lock- Sem agulha	32	 R$  1,00 	SSSAAA046	5/31/2025	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Sonda Uretral 8	60.383.338/0001-00	EMBRAMED IND. E COM. DE PRODUTOS HOSPITALARES	EMBRAMED	7898468477570	Sonda Uretral nº 08	2	 R$  1,00 	1900001628	3/31/2022	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Sonda Uretral 8	60.383.338/0001-00	EMBRAMED IND. E COM. DE PRODUTOS HOSPITALARES	EMBRAMED	7898468477570	Sonda Uretral nº 08	40	 R$  1,00 	1900001628	3/31/2022	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Sonda Uretral 14	60.383.338/0001-00	EMBRAMED IND. E COM. DE PRODUTOS HOSPITALARES	EMBRAMED	7898468477617	Sonda Uretral nº 14	18	 R$  1,00 	2100010542	3/31/2024	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Sondar Uretral 16	60.383.338/0001-00	EMBRAMED IND. E COM. DE PRODUTOS HOSPITALARES	EMBRAMED	7898468477600	Sonda Uretral nº16	40	 R$  1,00 	2100008332	2/28/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Tegaderm Film 6x7	08.014.346/0001-50	3M do BRASIL LTDA	3M	711428065510	Tegaderm Film - 6 x 7 cm - Ref. 1624W	84	 R$  1,00 	334WHC	7/31/2022	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Tegaderm Film 10x10 -Rolo	28.215.470/0001-91	GOLDMED IMPORTAÇÃO LTDA	GOLDMED	6224000549862	Tegaderm Film - 10 x10  cm - Rolo	2	 R$  1,00 	10l19	8/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Tiras Teste Glicose Free Style Optium 							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Tree Way (Torneira de 3 vias)	10.268.780/0001-09	 MEDIX BRASIL PRODUTOS HOSPITALARES E ODONT. LTDA	MEDIX	7898652371714	Torneira 3 vias - Tree Way	25	 R$  1,00 	20349	10/31/2023	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Tree Way (Torneira de 3 vias)	10.268.780/0001-09	 MEDIX BRASIL PRODUTOS HOSPITALARES E ODONT. LTDA	MEDIX	7898652371714	Torneira 3 vias - Tree Way	50	 R$  1,00 	20349	10/31/2023	Estoque	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Adaptador para Agulha Vacutainer	48.740.849/0001-28	CRAL ARTIGOS PARA LABORATÓRIO LTDA	CRAL	NC	Adaptador para agulha Vacutainer	4	 R$  1,00 	NAO INFORMADO	12/31/2030	Farmácia	10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Adaptador para Agulha Vacutainer	48.740.849/0001-28	CRAL ARTIGOS PARA LABORATÓRIO LTDA	CRAL	NC	Adaptador para agulha Vacutainer	191	 R$  1,00 	NAO INFORMADO	12/31/2030	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica  -  20G - Amarela TE 	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017510435	Agulha Hipodérmica  -  20G - Amarela TE 	47	 R$  1,00 	190201C	1/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica  -  20G - Amarela TE 	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017510435	Agulha Hipodérmica  -  20G - Amarela TE 	200	 R$  1,00 	190201C	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica - 18G -  Verde  TE 	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017507565	Agulha Hipodérmica - 18G -  Rosa  TE 	24	 R$  1,00 	1410238	9/30/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica - 18G -  Verde  TE 	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017507565	Agulha Hipodérmica - 18G -  Rosa  TE 	1300	 R$  1,00 	1410238	9/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica - 19G - Branca TE 	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017510251	Agulha Hipodérmica - 19G - Branca TE 	94	 R$  1,00 	160215D	1/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica - 19G - Branca TE 	03.129.105/0001-33	TERUMO MEDICAL DO BRASIL	TERUMO	4806017510251	Agulha Hipodérmica - 19G - Branca TE 	1000	 R$  1,00 	160215D	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Atadura Crepom - 6cm	33.839.828/0001-97	Neve Premium Industria e Comércio Ltda	NEVE	7898172860026	Atadura de Crepom - 6 cm 	57	 R$  1,00 	2001000178	12/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Avental Cirúrgico Estéril 	00.252.903/0001-60	Esterili-Med Produtos Médicos Hospitalares 	ESTERILI-MED	908080200187	Avental Cirúrgico Estéril 	5	 R$  1,00 	13660	5/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Capa Encosto Cadeira							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Colcão Caixa de Ovo							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fronha Laminada							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fronha Formato U							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	IV Fix	08.014.346/0001-50	3M do BRASIL LTDA	3M	NC	Curativo IV Fix	35	 R$  1,00 	1718500364	7/31/2022	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Lençol TNT Cobrir							 				10/16/2021
Material	Material Médico	Mat. Procedimento	Unidade	Não	Swab Nasal Esteril	11.462.456/0001-90	DIAGNÓSTICA INDUSTRIA E COMÉRCIO LTDA	BIOCON	NC	Swab Estéril para Coleta 						
Material	Perfumaria	Material Cirurgia	Pacote	Não	Absorvente Noturno 	61.101.895/0001-45	SANTHER FABRICA DE PAPEL S.A	SYM	7896110002255	Absorvente Noturno	32	 R$  1,00 	NAO INFORMADO	9/30/2022	Estoque	10/16/2021
Material	Perfumaria	Material Cirurgia	Pacote	Não	Absorvente Pos Parto  c/ 20	22.010.816/0001-39	ACTIVE IND. DE COSMÉTICOS S.A	BIGFRAL	7896012802045	Absorvente Pos Parto  	60	 R$  1,00 	NAO INFORMADO	9/30/2023	Estoque	10/16/2021
Material	Perfumaria	Material Cirurgia	Frasco	Não	Agua Oxigenada 10 Volumes 1 Litro	21.759.758/0001-88	FARMAX -DISTRIBUIDORA AMARAL LTDA	FARMAX	7896902214002	Agua Oxigenada -10 Volumes - 1000 mL	3	 R$  1,00 	153	12/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 40x12 -  Rosa 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	789143009180	Agulha Hipodermica - 40 x 12 	54	 R$  1,00 	1211599	8/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 40x12 -  Rosa 	01.005.728/0001-79	LABOR IMPORT COM. IMP. EXP. LTDA	LABOR	7898157720888	Agulha Hipodermica - 40 x 12 	300	 R$  1,00 	17082020	8/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 30x10 - Branca	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909748	Agulha Hipodermica - 30 x 10	25	 R$  1,00 	9165775	6/30/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 30x10 - Branca	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909748	Agulha Hipodermica - 30 x 10	300	 R$  1,00 	9165775	6/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 25x8  -Verde 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909793	Agulha Hipodermica - 25 x 8 	15	 R$  1,00 	1181951	5/31/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 25x8  -Verde 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909793	Agulha Hipodermica - 25 x 8 	200	 R$  1,00 	1181951	5/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 25x7  -  Cinza 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	7898259498678	Agulha Hipodermica  - 25 x 7	51	 R$  1,00 	22225	1/31/2021	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 25x7  -  Cinza 	03.426.484/0091-23	SALDANHA RODRIGUES LTDA	SR	7898259498678	Agulha Hipodermica  - 25 x 7	100	 R$  1,00 	265N5	8/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica  13x3  -  Amarela 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909717	Agulha Hipodermica - 30 x 13	18	 R$  1,00 	274805	9/30/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica  13x3  -  Amarela 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909717	Agulha Hipodermica - 30 x 13	100	 R$  1,00 	274805	9/30/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica  13x3  -  Amarela 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909717	Agulha Hipodermica - 30 x 13	200	 R$  1,00 	303179	10/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 13x4,5 - Marrom 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909823	Agulha Hipodermica - 13 x 4,5	200	 R$  1,00 	8302544	10/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 13x4,5 - Marrom 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909823	Agulha Hipodermica - 13 x 4,5	90	 R$  1,00 	8270762	9/30/2023	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Hipodérmica 13x4,5 - Marrom 	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	78909823	Agulha Hipodermica - 13 x 4,5	300	 R$  1,00 	8270762	9/30/2023	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Agulha Gengival 30G Injex	59.349.342/0001-49	INJEX INDUSTRIA CIRURGICAS LTDA	INJEX	7897889103129	Agulha Gengival Curta - 30 G	91	 R$  1,00 	654/20	8/31/2025	Farmácia	10/16/2021
Material	Mat. Assepsia	Procedimento	Frasco	Não	Álcool 70%     1 Litro	02.116.183/0001-30	HERVA PLUS INTERNACIONAL IMPORTAÇÃO LTDA	LANGOLD	7898994968320	Alcool  70 - 1000  mL	1	 R$  1,00 	22135	7/31/2022	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Frasco	Não	Alcool Gel 1 Litro	04.654.861/0001-44	INDALABOR LABORATÓRIO FARMACEUTICO LTDA	INDALABOR	7898355995194	Indagel - Alcool Gel - 1000 mL	2	 R$  1,00 	115.04/21	4/30/2023	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Unidade	Não	Alcool PAD Sachê	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	382903269150	Alcool PAD 	72	 R$  1,00 	1077175	2/28/2026	Farmácia	10/16/2021
Material	Mat. Assepsia	Procedimento	Unidade	Não	Alcool PAD Sachê	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	382903269150	Alcool PAD 	100	 R$  1,00 	1077175	2/28/2026	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Pacote	Não	Algodão Bolas	21.172.673/0001-07	ERS INDUSTRIA E COMERCIO DE PRODUTOS DE HIGIENE 	NATY	7898136150286	Algodão em bolas	2	 R$  1,00 	1020	10/31/2025	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Pacote	Não	Algodão Rolete	82.641.325/0001-18	CREMER S.A	CREMER	7891800219814	Algodão rolete	400	 R$  1,00 	96214194	10/31/2024	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Pacote	Não	Algodão Rolete	82.641.325/0001-18	CREMER S.A	CREMER	7891800219814	Algodão rolete	300	 R$  1,00 	101230	10/31/2024	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Frasco	Não	Almotolia Alcool 70% -250 ml - Manipulado	23.271.638/0010-53	FARMÁCIA ARTESANAL	ARTESANAL	NC	Alcool  70 - 250 ml - Manipulado	11	 R$  1,00 	50721	1/31/2022	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Frasco	Não	Almotolia Alcool 70% 100 ml	39.032.974/0001-92	VICPHARMA INDUSTRIA E COMERCIO LTDA 	VIC PHARMA	7897877702112	Alcool  70 - 100 mL	16	 R$  1,00 	29088	4/30/2023	Estoque	10/16/2021
Material	Mat. Assepsia	Procedimento	Frasco	Não	Almotolia Alcool Gel -250 mL	04.654.861/0001-44	INDALABOR LABORATÓRIO FARMACEUTICO LTDA	INDALABOR	7898355995149	Alcool Gel - 250 mL - Indagel 	5	 R$  1,00 	267.05/20	5/31/2022	Estoque	10/16/2021
Material	Material Médico	Procedimento	Frasco	Não	Almotolia Água Oxigenada 10 Volumes 100 ml	21.759.758/0001-88	FARMAX -DISTRIBUIDORA AMARAL LTDA	FARMAX	7896902213661	Agua Oxigenada -10 Volumes - 100 mL						
Material	Mat. Assepsia	Procedimento	Frasco	Não	Almotolia Clorexidine  2% - Degermante -100 ml	39.032.974/0001-92	VICPHARMA INDUSTRIA E COMERCIO LTDA 	VIC PHARMA	7897877708725	Clorexidine 2% -Degermante - 100 mL	16	 R$  1,00 	M27281	4/30/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Avental Manga Longa Branco c/ 10							 				10/16/2021
Material	Mat. Assepsia	Material Cirurgia	Unidade	Não	Blood Stop 	82.641.325/0013-51	CREMER S.A	CREMER	7891800345377	Curativo redondo - Blood Stop	200	 R$  1,00 	537792110	3/31/2023	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Caneta Marcação							 				10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Cânula 22G 	09.570.371/0001-83	ACCR COMERCIO DE COSMÉTICOS LTDA 	DERMADEEP	6930197838602	Cânula 22 G	50	 R$  1,00 	210402-1	3/31/2026	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Cânula 22G 	09.570.371/0001-83	ACCR COMERCIO DE COSMÉTICOS LTDA 	FILL FLEX	6930197838589	Cânula 22 G	16	 R$  1,00 	20200615-3	6/30/2025	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Cânula 25G 	09.570.371/0001-83	ACCR COMERCIO DE COSMÉTICOS LTDA 	DERMADEEP	6930197838664	Cânula 25 G	23	 R$  1,00 	210502-2	4/30/2026	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Cânula 27G 	06.172.459/0001-59	NUTRIEX IMP. EXP. DE PRODUTOS FARM. LTDA	SOFTFIL	NC	Cânula 27 G	8	 R$  1,00 	1801W-51	12/31/2022	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Capa Travesseiro							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 18G   - verde	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883325	Jelco 18G - Cateter Intravenoso - Verde	38	 R$  1,00 	2005545	8/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 20G -   rosa	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883332	 Jelco 20G-   Cateter Intravenoso - Rosa	33	 R$  1,00 	9210073	8/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 22G -    azul	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883356	Jelco 22G- Cateter Intravenoso - Azul	81	 R$  1,00 	1057567	3/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 22G -    azul	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883356	Jelco 22G- Cateter Intravenoso - Azul						
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 22G -    azul	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883356	Jelco 22G- Cateter Intravenoso - Azul	16	 R$  1,00 	213599	8/31/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 24G - amarelo	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883363	Jelco 24G- Cateter Intravenoso - Amarelo	10	 R$  1,00 	9320052	3/31/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Intravenoso 24G - amarelo	21.551.379/0013-31	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	(01)00382903883363	Jelco 24G- Cateter Intravenoso - Amarelo	12	 R$  1,00 	5200139	11/30/2022	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Nasal Tipo Óculos 	82.641.325/0013-51	CREMER S.A	CREMER	7898468470373	Cateter Nasal tipo Óculos 						
Material	Material Médico	Material Cirurgia	Unidade	Não	Cateter Nasal Tipo Óculos 	82.641.325/0013-51	CREMER S.A	CREMER	7898468470373	Cateter Nasal tipo Óculos 	50	 R$  1,00 	2100016083	5/31/2024	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Cavilon Spray 28 ml	45.985.371/0001-08	3M do BRASIL LTDA	3M	7891040075546	Cavilon 	1	 R$  1,00 	2112400892	5/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Coletor Perfuro-cortante 13 L Descarpack							 				10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Compressa (Campo Operatório)  Esteril c/ 5	24.039.668/0001-00	Medpack Industria e Comercio de Produtos Cirurgicos e Hospitalares LTDA	STELLAFLEX	7898172601755	Compressa Campo Operatório Estéril						
Material	Material Médico	Material Cirurgia	Unidade	Não	Compressa (Campo Operatório)  Esteril c/ 5	24.039.668/0001-00	Medpack Industria e Comercio de Produtos Cirurgicos e Hospitalares LTDA	STELLAFLEX	7898172601755	Compressa Campo Operatório Estéril	67	 R$  1,00 	2125	6/30/2024	Estoque	10/16/2021
Material	Perfumaria	Procedimento	Unidade	Não	Cotonete  							 				10/16/2021
Material	Cosmético 	Procedimento	Unidade	Não	Dersani (Vitaminas Ae E; Ácidos Graxos Essenciais)	33.026.055/0001-20	LABORATÓRIO DAUDT OLIVEIRA LTDA	DAUDT	7898087340019	Dersani 200 mL - Vitaminas A e E; AGE						
Material	Material Médico	Material Cirurgia	Unidade	Não	Equipo c/ injetor lateral	01.005.728/0001-79	LABOR IMPORT COM. IMP. EXP. LTDA	LABOR	7898157722707	Equipo Macrogotas com Injetor Lateral	1	 R$  1,00 	27012021	1/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Equipo c/ injetor lateral	01.005.728/0001-79	LABOR IMPORT COM. IMP. EXP. LTDA	LABOR	7898157722707	Equipo Macrogotas com Injetor Lateral	50	 R$  1,00 	27012021	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Equipo c/ injetor lateral	01.057.428/0002-14	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283815724	Equipo Macrogotas com Injetor Lateral	13	 R$  1,00 	SEMAAA0405	3/31/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Equipo c/ injetor lateral	01.057.428/0002-14	DESCARPACK DESCARTÁVEIS DO BRASIL LTDA	DESCARPACK	7898283815724	Equipo Macrogotas com Injetor Lateral	18	 R$  1,00 	SEMAAA0405	3/31/2026	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Eletrodos	45.985.371/0001-08	3M do BRASIL LTDA	3M	7891040146468	Eletrodos Descartáveis	250	 R$  1,00 	2112700153	5/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Encosto Mocho							 				10/16/2021
Material	Mat. Higienização	Material Cirurgia	Unidade	Não	Escova Degermação Clorexidine	39.032.974/0001-92	VICPHARMA INDUSTRIA E COMERCIO LTDA 	VIC PHARMA	7897877708770	Escova Degermação 2%	38	 R$  1,00 	29758	8/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Esparadrapo 10x45	82.641.325/0013-51	CREMER S.A	CREMER	7891800276534	Esparadrapo Impermeável 10 x 4,5	1	 R$  1,00 	1993040	10/31/2024	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Especulo Descartavel	59.231.530/0005-17	KOLPLAST C I S.A	KOLPLAST	7898027906015	Espéculo Vaginal Tam. M	10	 R$  1,00 	1201014064	10/31/2022	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Extensor 120 cm	82.641.325/0013-51	CREMER S.A	CREMER	7898468471929	Extensor 120 cm	16	 R$  1,00 	2100021719	7/31/2024	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Extensor 120 cm	82.641.325/0013-51	CREMER S.A	CREMER	7898468471929	Extensor 120 cm	160	 R$  1,00 	2100021719	7/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Micropore  25X10 - Bege	45.985.371/0001-08	3M do BRASIL LTDA	3M	7891040034659	Fita Micropore 25 x 10 - Bege	3	 R$  1,00 	539663	5/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Micropore  25X10 - Bege	82.641.325/0013-51	CREMER S.A	CREMER	7891800644647	Fita Micropore 25 x 10 - Bege	2	 R$  1,00 	6475113	3/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Micropore  25X10 - Bege	03.225411/0001-73	MISSNER & MISSNER LTDA	MISSNER	7896544900233	Fita Micropore 25 x 10 - Bege	5	 R$  1,00 	PAM11801	5/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Micropore  12,5 x 10 - Bege	45.985.371/0001-08	3M do BRASIL LTDA	3M	7891040034697	Fita Micropore 12,5 x 10- Bege	3	 R$  1,00 	210195	7/31/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Micropore  12,5 x 10 - Bege	45.985.371/0001-08	3M do BRASIL LTDA	3M	7891040034697	Fita Micropore 12,5 x 10- Bege	2	 R$  1,00 	210195	7/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Micropore 3M Azul	45.985.371/0001-08	3M do BRASIL LTDA	3M	4054596633056	Fita Micropore 2,5 x 5 - Azul	18	 R$  1,00 	2107600698	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Fita Adesiva Hospitalar - 19 x 50	82.641.325/0013-51	CREMER S.A	CREMER	7891800001808	Fita Adesiva Hospitalar -  19 x 50 	2	 R$  1,00 	246274134H	8/31/2025	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 3 Agulhas Linear CHEYENNE	02.996.143/0001-20	A.D. DESENVOLVIMENTO INTERNACIONAL LTDA	MT DERM	7898431200280	Ponteira com Agulha Cheyenne - 3- Linear - 0,25	2	 R$  1,00 	2020-48-009	12/31/2025	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 3 Agulhas Linear CHEYENNE	02.996.143/0001-20	A.D. DESENVOLVIMENTO INTERNACIONAL LTDA	MT DERM	7898431200280	Ponteira com Agulha Cheyenne - 3- Linear - 0,25	50	 R$  1,00 	2020-48-009	12/31/2025	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 3 Agulhas Linear Teste	22.276.490/0001-96	HORNET TATTOO IMP. COM. LTDA	HORNET	6903307210058	Ponteira 3 Agulhas Linear Teste	12	 R$  1,00 	21007	7/31/2025	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 3 Agulhas Linear Teste	22.276.490/0001-96	HORNET TATTOO IMP. COM. LTDA	HORNET	6903307210058	Ponteira 3 Agulhas Linear Teste	40	 R$  1,00 	21007	7/31/2025	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 27 Agulhas - MMP CHEYENNE	02.996.143/0001-20	A.D. DESENVOLVIMENTO INTERNACIONAL LTDA	MT DERM	7898431199577	Ponteira 27 Agulhas - MMP CHEYENNE	5	 R$  1,00 	2020-47-001	12/31/2025	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 27 Agulhas - MMP 	24.579.215/0001-77	MEDIPRO COMERCIO DE PRODUTOS ESTÉTICOS	MEDIPRO	NC	Ponteira 27 Agulhas - MMP 	20	 R$  1,00 	2021-01	12/31/2025	Farmácia	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Ponteira 27 Agulhas - MMP 	24.579.215/0001-77	MEDIPRO COMERCIO DE PRODUTOS ESTÉTICOS	MEDIPRO	NC	Ponteira 27 Agulhas - MMP 	40	 R$  1,00 	2021-01	12/31/2025	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Touca Descartavel	07.318.036/0001-67	DESCARTEE I.C.R. DESC LTDA	DESCARTEE	7898964627097	Touca Descartável Sanfonada	100	 R$  1,00 	2004DC	5/31/2025	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Touca Descartavel	69.348.936/0001-04	ANADONA COM. E CONF. LTDA	ANADONA	7898433202565	Touca Descartável Sanfonada	600	 R$  1,00 	408-21	8/31/2023	Estoque	10/16/2021
Material	Perfumaria	Procedimento	Unidade	Não	Tintura Grecin 5 M110 Preta	24.769.185/0001-61	COMBE PRODUCTS INC. PORTO RICO	COMBE	11509031334	Grecin Barba e Bigode - M110- Preto	1	 R$  1,00 	131758	4/30/2023	Farmácia	10/16/2021
Material	Perfumaria	Procedimento	Unidade	Não	Tintura Grecin 5 M110 Preta	24.769.185/0001-61	COMBE PRODUCTS INC. PORTO RICO	COMBE	11509031334	Grecin Barba e Bigode - M110- Preto	1	 R$  1,00 	131758	4/30/2023	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Adaptador para Agulha Vacutainer							 				10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Agulha Vacutainer  - Coleta Verde	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	303882903602101	Agulha  Vacutainer - Coleta Verde	100	 R$  1,00 	6330660	11/30/2024	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Agulha Vacutainer -  Coleta Preta	21.551.379/0001-06	BECTON DICKINSON INDUSTRIAS CIRURGICAS LTDA	BD	303882903602105	Agulha Vacutainer -  Coleta Preta	100	 R$  1,00 	7004766	1/31/2024	Estoque	10/16/2021
Material	Material Médico	Procedimento	Unidade	Não	Tubo p/ Coleta c/ Citrato	11.462.456/0001-90	DIAGNÓSTICA INDUSTRIA E COMERCIO 	BIOCON	7898645980275	Tubo para Coleta de Sangue 	276	 R$  1,00 	72426	10/31/2023	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Implanter Azul	06.696.246/0001-26	DOCTUS EQUIPAMENTOS MÉDICOS LTDA	DOCTUS	NC	Agulha para Implanter - Azul	9	 R$  1,00 	19084573	9/30/2024	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Implanter Azul	06.696.246/0001-26	DOCTUS EQUIPAMENTOS MÉDICOS LTDA	DOCTUS	NC	Agulha para Implanter - Azul	60	 R$  1,00 	19084573	9/30/2024	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Implanter Verde	06.696.246/0001-26	DOCTUS EQUIPAMENTOS MÉDICOS LTDA	DOCTUS	NC	Agulha para Implanter - Verde	1	 R$  1,00 	HN200907	9/30/2025	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Implanter Verde	06.696.246/0001-26	DOCTUS EQUIPAMENTOS MÉDICOS LTDA	DOCTUS	NC	Agulha para Implanter - Verde	30	 R$  1,00 	HN200907	9/30/2025	Estoque	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Implanter Vermelho	06.696.246/0001-26	DOCTUS EQUIPAMENTOS MÉDICOS LTDA	DOCTUS	NC	Agulha para Implanter - Vermelho	4	 R$  1,00 	20210421-1	4/30/2026	Farmácia	10/16/2021
Material	Material Médico	Material Cirurgia	Unidade	Não	Agulha Implanter Vermelho	06.696.246/0001-26	DOCTUS EQUIPAMENTOS MÉDICOS LTDA	DOCTUS	NC	Agulha para Implanter - Vermelho	20	 R$  1,00 	20210421-1	4/30/2026	Estoque	10/16/2021
Controláveis	Cosmético 	Procedimento	UI	Não	Dyspor	07.718.721/0001-80	IPSEN FARMACEUTICA LTDA	IPSEN	7898932171010	Dysport - Toxina Botulinica A 500 U	3	 R$  1,00 	U13417	1/31/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Frasco	Não	Sculptra	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930775657	SCULPTRA 	5	 R$  1,00 	OJ34D2	5/31/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Radiesse	11.681.446/0001-45	MERZ FARMACEUTICA COMERCIAL LTDA	MERZ	7899449200187	Radiesse Duo						
Controláveis	Cosmético 	Procedimento	Unidade	Não	Radiesse	11.681.446/0001-45	MERZ FARMACEUTICA COMERCIAL LTDA	MERZ	7899449200187	Radiesse Duo						
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Lidocaine	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776159	Restylane LIDOCAINE						
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Lidocaine	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776159	Restylane LIDOCAINE	2	 R$  1,00 	19069-3	8/31/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Lidocaine	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776159	Restylane LIDOCAINE	1	 R$  1,00 	18956-1	6/30/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Lyft	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776135	Restylane LYFT LIDOCAINE	3	 R$  1,00 	19111-1	12/31/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Lyft	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776135	Restylane LYFT LIDOCAINE	2	 R$  1,00 	19115-2	10/31/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Kysse	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776210	Restylane KYSSE	1	 R$  1,00 	18615-1	9/30/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Kysse	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776210	Restylane KYSSE	1	 R$  1,00 	S2201920008	7/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Defyne	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776203	Restylane DEFYNE	2	 R$  1,00 	19180-1	2/28/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Defyne	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776203	Restylane DEFYNE	3	 R$  1,00 	S2210150035	1/31/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Refyne	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776197	Restylane REFYNE	1	 R$  1,00 	S2200910010	5/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Refyne	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776197	Restylane REFYNE	1	 R$  1,00 	18290-1	5/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Refyne	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776197	Restylane REFYNE	1	 R$  1,00 	S2201970001	8/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Refyne	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776197	Restylane REFYNE	3	 R$  1,00 	S2200720009	3/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Volyme 	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897903077618	Restylane VOLYME						
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Volyme 	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897903077618	Restylane VOLYME	6	 R$  1,00 	18760-1	10/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Skinboosters Vital	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776128	Restylane SKINBOOSTERS VITAL LIDOCAINE	3	 R$  1,00 	18831-1	7/31/2022	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Skinboosters Vital	00.317.372/0001-46	GALDERMA BRASIL LTDA	GALDERMA	7897930776128	Restylane SKINBOOSTERS VITAL LIDOCAINE	3	 R$  1,00 	19249-1	2/28/2023	Farmácia	10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Restylane Skinboosters Vital Light							 				10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Pigmento Dermopigmentação 							 				10/16/2021
Controláveis	Cosmético 	Procedimento	Unidade	Não	Pigmento Dermopigmentação Alivio 							 				10/16/2021
Controláveis	Cosmético 	Procedimento	Kit	Não	Hialuronidase	07.783.875/0001-56	BIOMETIL LABORATÓRIO FARMACEUTICO	BIOMETIL	NC	HIALURONIDASE  	5	 R$  1,00 	125050	5/31/2022	Farmácia	10/16/2021`
str = str.replaceAll('	', '#').replaceAll('R$', '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
console.log(str);
Product.insertProducts(str, function(res, putResponseHeaders) {
});
		}

	}
})();
