(function () {
    'use strict';

    angular
        .module('sgmApp')
        .factory('User', User);

    User.$inject = ['$resource', 'URL', 'SERVICES'];

    function User ($resource, URL, SERVICES) {

        var url = URL[SERVICES.environment].api;

        var service = $resource(url + 'api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'roles': {url: url + 'api/roles', method: 'GET', isArray: true},
            'changePassword': {url: url + 'api/users/changePassword', method: 'PUT'}
        });

        return service;
    }
})();
