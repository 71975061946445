(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('SalesRequest', SalesRequest);

    SalesRequest.$inject = ['$resource', 'URL', 'SERVICES'];

    function SalesRequest ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/sales-request/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getFull': {
                method: 'GET',
                url: url + 'api/sales-request/full/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listStatus': { method: 'GET', url: url + 'api/sales-request/list-status', isArray: true },
            'getForAttendanceCreate': { method: 'GET', url: url + 'api/sales-request/get-for-attendance-create/:id' }
        });
    }
})();
