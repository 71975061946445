(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('IndicationsCategoryController', IndicationsCategoryController);

	IndicationsCategoryController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'IndicationsCategoryService', 'FrontEndSelectDataSource', 'FormConstants'];

	function IndicationsCategoryController(FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, IndicationsCategoryService, FrontEndSelectDataSource, FormConstants) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		$rootScope.initialFocus("defaultDescription");

		vm.buttonsModel =
		{
			stateRoot: "indicationsCategory",
			onFilter: function() { doFilter(); },
			onClear: function() { doClear(); }
		};

		var formOptions = {
			formStatePersisterId: "indicationsCategory"
			//        	checkFormChange: function() { doFilter(); }
		};
		vm.formHandler = FormFilter.create(formOptions)
			.add([
//				new FormField({
//				cellCssClass: "col-sm-4",
//				type: "String",
//				id: "id",
//				readOnly: true,
//				label: "common.domain.indicationsCategory.id"
//			}),
			new FormField({
				cellCssClass: "col-sm-6",
				type: "String",
				id: "defaultDescription",
				label: "common.domain.indicationsCategory.name"
			})
			])
			.endConfig();



		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.indicationsCategory.id"), field: "id", width: 25 },
			{ headerName: $translate.instant("common.domain.indicationsCategory.name"), field: "defaultDescription", width: 60 }
			//            UcGridService.field.ACTIVE(),	// width: 5
		]);

		var grid = UcGridService.create(
			{
				gridId: "INDICATIONS_CATEGORY",
				//        	permission: vm.pageConfig.editPermission,
				stateBaseName: "indicationsCategory",
				account: account,				
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
				gridData: function() {
					// Quando o parâmetro gridData é informado o UcGridService ignora a chamada padrão do getFilterData				
					return IndicationsCategoryService.listAll(vm.formHandler.readForm());
				}
			});

		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};
	}
})();
