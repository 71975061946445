(function(){
	'use strict';
	angular.module('sgmApp').controller('ProntuaryFormGridDialogController', ProntuaryFormGridDialogController);
	
	ProntuaryFormGridDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FrontEndSelectDataSource', 'pageConfig','$translate'];
	
	
	function ProntuaryFormGridDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FrontEndSelectDataSource, pageConfig, $translate){
		
		var vm = this;
		
        


        
		vm.pageConfig = pageConfig;
		var LIST_INTERVAL = [
            { id: 5, defaultDescription: "5 min" },
            { id: 10, defaultDescription: "10 min" },
            { id: 15, defaultDescription: "15 min" },
            { id: 20, defaultDescription: "20 min" },
            { id: 30, defaultDescription: "30 min" },
            { id: 60, defaultDescription: "1 hora" },
        ];
        var LIST_HOUR = [];
        var LIST_END_HOUR = [];
        for(var i = 0; i < 24; i++) {
            LIST_HOUR.push({
                id: i,
                defaultDescription: i + ''
            });
            LIST_END_HOUR.push({
                id: i,
                defaultDescription: i + ''
            })
        }
        vm.entity = {
            interval: null,
            startHour: LIST_HOUR[0],
            startMinute: null,
            endHour: LIST_END_HOUR[LIST_END_HOUR.length-1],
            endMinute: null,
        };
        var MINUTES = [];
        var endDataSource = FrontEndSelectDataSource.create(LIST_END_HOUR);
        var minuteDataSource = FrontEndSelectDataSource.create(MINUTES);
        
		var formOptions = {
				model: vm.entity,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormProcedureProduct; }
	        };

        

		vm.formHandler = FormDomain.create(formOptions)
            .add([
                new FormField({
                    type: "Select",
                    id: "interval",
                    required: true,
                    label: "client.page.prontuaryModel.prontuaryModelGridDialog.interval",
                    dataSource: FrontEndSelectDataSource.create(LIST_INTERVAL),
                    onChange: function(p_objModel, p_fieldModel, p_value) {
                        console.log(p_value);
                        updateMinuteSelect(p_value.id);
                    }
                })
            ])
			.add([
				new FormField({
                    cellCssClass: "col-sm-2",
                    type: "Select",
                    id: "startHour",
                    label: "client.page.prontuaryModel.prontuaryModelGridDialog.startHour",
                    required: true,
                    hide: true,
                    style: "width: 100%;",
                    dataSource: FrontEndSelectDataSource.create(LIST_HOUR),
                    onChange: function(p_objModel, p_fieldModel, p_value) {
                        updateEndHourSelect(p_value.id);
                    }
                }),
                new FormField({
                    type: "Select",
                    id: "startMinute",
                    cellCssClass: "col-sm-2",
                    required: true,
                    hide: true,
                    style: "width: 100%;",
                    label: "client.page.prontuaryModel.prontuaryModelGridDialog.minute",
                    dataSource: minuteDataSource
                }),
                new FormField({
                    cellCssClass: "col-sm-2",
                    type: "Select",
                    id: "endHour",
                    hide: true,
                    style: "width: 100%;",
                    dataSource: endDataSource,
                    label: "client.page.prontuaryModel.prontuaryModelGridDialog.endHour",
                    required: true,
                }),
                new FormField({
                    type: "Select",
                    id: "endMinute",
                    style: "width: 100%;",
                    cellCssClass: "col-sm-2",
                    required: true,
                    hide: true,
                    label: "client.page.prontuaryModel.prontuaryModelGridDialog.minute",
                    dataSource: minuteDataSource
                }),
			]);
		
		 /*public*/ vm.closeModal = function(obj)
		 {
			$uibModalInstance.close(obj);
        };
        
        vm.confirm = function(){
        	
			//if is save needs to validate and return the object
			FormUtil.validateAll($scope.prontuaryGridDialogForm, vm.formHandler.getRowList());
			if($scope.prontuaryGridDialogForm.$valid) {
				var domain = vm.formHandler.readForm();
                console.log(domain);
                var startDate = moment().utcOffset(0);
                startDate.set({hour: domain.startHour.id, minute: domain.startMinute.id,second:0,millisecond:0})

                var endDate = moment().utcOffset(0);
                endDate.set({hour: domain.endHour.id, minute: domain.endMinute.id,second:0,millisecond:0})

                console.log(endDate);
                console.log(startDate);
                if(endDate > startDate) {
                    var columns = [];
                    var diffMinutes = moment.duration(endDate.diff(startDate)).asMinutes();
                    console.log(diffMinutes);
                    var order = 0;
                    while(startDate <= endDate) {
                        var title = startDate.format("HH") + ":\n" + startDate.format("mm");
                        columns.push({
                            title: title,
                            order: order
                        });
                        order++;
                        startDate.add('minutes', domain.interval.id);
                    }
                    console.log(columns);
                    vm.closeModal(columns);
                }
				//vm.closeModal(domain);
			}
        	
        }
        function updateEndHourSelect(start) {
            LIST_END_HOUR = [];
            for(var i = start; i < 24; i++) {
                LIST_END_HOUR.push({
                    id: i,
                    defaultDescription: i + ''
                })
            }
            endDataSource.setOptionsList(LIST_END_HOUR);
        }
        function updateMinuteSelect(interval) {
            MINUTES = [];
            vm.entity.startMinute = null;
            vm.entity.endMinute = null;
            if(interval) {
                console.log(interval);
                for(var min = 0; min < 60; min += interval) {
                    MINUTES.push({
                        id: min,
                        defaultDescription: min + " min" 
                    });
                    console.log(min);
                };
            }
            minuteDataSource.setOptionsList(MINUTES);
            vm.formHandler.findField("startHour").hide = !interval;
            vm.formHandler.findField("startMinute").hide = !interval;
            vm.formHandler.findField("endHour").hide = !interval;
            vm.formHandler.findField("endMinute").hide = !interval;

        }
	       
	}
})();
