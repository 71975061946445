(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('Procedure', Procedure);

    Procedure.$inject = ['$resource', 'URL', 'SERVICES'];

    function Procedure ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/procedure/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getFamilyProcedureByProcedure': { method: 'GET', url: url + 'api/procedure/get-family-procedure-by-procedure/:id' },
            'addProntuaryModel': { method: 'GET', url: url + 'api/procedure/add-prontuary-model/:procedureId/:prontuaryModelId' },
            'removeProntuaryModel': { method: 'GET', url: url + 'api/procedure/remove-prontuary-model/:procedureId/:prontuaryModelId'},
            'updateLoad': { method: 'PUT', url: url + 'api/procedure/update-load'}
            
        });
    }
})();
