(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PrintProntuaryFormDialogController', PrintProntuaryFormDialogController);

    PrintProntuaryFormDialogController.$inject = ['prontuaries', '$uibModalInstance', '$timeout', '$translate', 'clientName'];

    function PrintProntuaryFormDialogController(prontuaries, $uibModalInstance, $timeout, $translate, clientName)
    {
        var vm = this;
        
        var MAX_DIV_SIZE = 600;
        
        var BREAK_LINE_TAGS = ["</div>", "</p>", "</li>", "</tr>", "<br>", "<br/>", "<br />"]
		
        $timeout(function()
        {
			
        	
        	var pw = frames["toPrintFrame"];
        	pw.document.write('<html moznomarginboxes mozdisallowselectionprint><head><title> | ' + clientName  + '</title>');
            pw.document.write(`<style>
			@media print
			{
				table { page-break-inside:auto }
				tr    { page-break-inside:avoid; page-break-after:auto }
				thead { display:table-header-group }
				tfoot { display:table-footer-group }
			}
		</style>`);
            pw.document.write('</head>');
            pw.document.write(generatePrintCssStyle());
            pw.document.write('<body>');
            pw.document.write('<div id="print-area"></div> <div id="itemsize"></div> <div id="pagesize"></div>');
            pw.document.write('</body>');
            pw.document.write('</html>');
            try
            {
	            pw.document.close(); // necessary for IE >= 10
	            pw.focus(); // necessary for IE >= 10*/
            }
            catch(exc)
            {
            	console.error("Error on prepare document to print: " + exc.message);
            }
			var html = '';
			if (prontuaries && prontuaries.length > 0) {
				prontuaries.forEach(function (prontuary, index) {
					html += vm.buildPrintContent(prontuary) 
				});
			}else {
				html += vm.buildPrintContent(prontuaries);
			}
        	html = "<div>" + html + "</div>";
            $timeout(function()
            {
            	preparePrint(html)
            }, 500)
            
//            $timeout(function()
//            {
//            	frames["toPrintFrame"].print();
//            }, 500)
        }, 100);
		function generateTable(grid, c, limitColumns) {
			var prontuaryItemHtml = "";
			prontuaryItemHtml += `<table style="width: auto; font-size: 8.5pt; border-collapse: collapse;">
							<tr>
								<th style="width: 50px; border: 1px solid;"></th>
								`;
								for(var j = c; j < limitColumns; j++) {
									var column = grid.columns[j];
									prontuaryItemHtml += '<th style="width: ' 
									+ (column.title.length < 10 ? '50' : column.title.length*8) 
									+ 'px; border: 1px solid;">' +column.title + '</th>'
								}
								
							prontuaryItemHtml += '</tr>';

							for(var j = 0; j < grid.rows.length; j++) {
								var row = grid.rows[j];
								prontuaryItemHtml += '<tr>'
								prontuaryItemHtml += '<td style="text-align:center; border: 1px solid;">'  + row.title + '</td> ';
								if(row.cells != null) {

									for(var k = c; k < limitColumns; k++) {
										var cell = row.cells[k];
										console.log(cell);
										prontuaryItemHtml += `
										<td style="border: 1px solid">
											<div style="text-align: center;">`;
												prontuaryItemHtml += row.textType ? `
													<span>`+ (cell.text ? cell.text : '')+`</span>
												` : `
												<input type="checkbox" onclick="return false;" name="option-`+cell.id+`" `+ (cell.selected ? 'checked' : 'disabled')+` readonly>
												`;
											prontuaryItemHtml += `</div>
										</td>
										`
	
									}
								}

								prontuaryItemHtml += '</tr>'
								
							}
							prontuaryItemHtml += '</table>';
							return prontuaryItemHtml;
		}

		function checkPageSize(accum, prontuaryItemHtml) {
			var pageSizeContainer = $("#toPrintFrame").contents().find("#pagesize");
			var pageSizeContainer = pageSizeContainer.html(accum);

			var itemSizeContainer = $("#toPrintFrame").contents().find("#itemsize");
			var itemSizeContainer = itemSizeContainer.html(prontuaryItemHtml);

			return (pageSizeContainer.height() + itemSizeContainer.height() > MAX_DIV_SIZE);
		}
		/*public*/ vm.buildPrintContent = function (prontuary) 
		{
			var accum = "";
			var content = "";
			var accum = `<h2>`+prontuary.name+`</h2>`;
			accum += `
			<div>
				<br><span class="header-title">Cliente:</span>&nbsp;&nbsp;`+(clientName)+`
				<br><span class="header-title">Data de Criação:</span>&nbsp;&nbsp;`+(moment(prontuary.createdDate).format('DD-MM-YYYY HH:mm'))+`
				<br><span class="header-title">Usuário Criador:</span>&nbsp;&nbsp;`+prontuary.userName+`&nbsp;&nbsp;
				<br><span class="header-title">Médico:</span>&nbsp;&nbsp;`+(prontuary.doctorName != null ? prontuary.doctorName : "-")+`&nbsp;&nbsp;
				<br><span class="header-title">Atendimento:</span>&nbsp;&nbsp;`+(prontuary.attendanceIdFk != null ? prontuary.attendanceIdFk : "-")+`&nbsp;&nbsp;
				<br><span class="header-title">Procedimento:</span>&nbsp;&nbsp;`+(prontuary.procedureName != null ? prontuary.procedureName : "-")+`&nbsp;&nbsp;
				<br><span class="header-title">Última modificação:</span>&nbsp;&nbsp;`+(moment(prontuary.lastModifiedDate).format('DD-MM-YYYY HH:mm'))+` - `+prontuary.lastChangeUserName+`
			</div>
			<br><br>
			`;
			var prontuaryItems = prontuary.prontuaryItems;
			if(prontuaryItems) {
				
				for(var i = 0; i < prontuaryItems.length; i++) {
					var prontuaryItemHtml = "";
					var prontuaryItem = prontuaryItems[i];
					if(prontuaryItem.type.id != 'TEXT') {
						prontuaryItemHtml += '<span>'+prontuaryItem.description+'</span><br>';
					}
					if(prontuaryItem.type.id == 'TEXTAREA') {
						prontuaryItemHtml += `
							<div style="border: 1px solid black; padding: 20px;">`+ (prontuaryItem.text)+`</div>
						`;
					} else if(prontuaryItem.type.id == 'TEXT') {
						prontuaryItemHtml += `
						<span style="`+(prontuaryItem.styleCss)+`">`+ (prontuaryItem.description)+`</span><br>
					`;
					} else if(prontuaryItem.type.id == 'GRID') {
						var grid = prontuaryItem.grid;
						if(grid.columns && grid.columns.length > 0) {
							var c = 0;
							var maxColumns = 12;
							while(c + maxColumns < grid.columns.length) {
								prontuaryItemHtml += generateTable(grid, c, c + maxColumns);
								if(checkPageSize(accum, prontuaryItemHtml)) {
									content += "<div class='print-page print-page-fixed-height'>" + accum + "</div>";
									accum = prontuaryItemHtml;
									prontuaryItemHtml = "";
								}
								c += maxColumns;
							}
							prontuaryItemHtml += generateTable(grid, c, grid.columns.length);
						} else {
							prontuaryItemHtml += "<span>Tabela não gerada</span>"
						}						
					} else {
						var checkType = prontuaryItem.type.id == 'RADIO' ? 'radio' : 'checkbox';
						for(var j = 0; j < prontuaryItem.prontuaryItemOptions.length; j++) {
							var itemOption = prontuaryItem.prontuaryItemOptions[j];
							prontuaryItemHtml += `
								<input type="`+checkType+`" onclick="return false;" name="option-`+itemOption.id+`" `+ (itemOption.selected ? 'checked' : 'disabled')+` readonly>
								<label for="option-`+itemOption.id+`">`+(itemOption.description +(itemOption.points ? " / " + itemOption.points + " pontos" : "") )+`</label><br>
							`;
						}
						
					}

					if(prontuaryItem.hasObservation) {
						prontuaryItemHtml += `<br>
						<span>Observação:</span>
						<br>
						<div style="border: 1px solid black; padding: 20px;">`
						+ (prontuaryItem.text != null ? prontuaryItem.text : "") 
						+`</div>`;
					}
					
					var pageSizeContainer = $("#toPrintFrame").contents().find("#pagesize");
					var pageSizeContainer = pageSizeContainer.html(accum);

					var itemSizeContainer = $("#toPrintFrame").contents().find("#itemsize");
					var itemSizeContainer = itemSizeContainer.html(prontuaryItemHtml);
					console.log(itemSizeContainer);
					console.log(pageSizeContainer);
					if(pageSizeContainer.height() + itemSizeContainer.height() > MAX_DIV_SIZE)
					{
						content += "<div class='print-page print-page-fixed-height'>" + accum + "</div>";
						accum = "";
					}
					accum += prontuaryItemHtml + "<br>";
				}
			}
			if(prontuary.totalSumPoints) {
				accum += "<h2>Total: " + prontuary.totalSumPoints + " pontos</h2>";
			}
			content += "<div class='print-page print-page-fixed-height'>" + accum + "</div>";
			return content;
		}

        /*public*/ vm.print = function()
        {
        	frames["toPrintFrame"].print();
        };
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        


        /*private*/ function preparePrint(p_str) 
        {
        	
			$("#toPrintFrame").contents().find("#print-area").html(p_str);
			$("#toPrintFrame").contents().find("#pagesize").html("");
			$("#toPrintFrame").contents().find("#itemsize").html("");
        }
	}
})();