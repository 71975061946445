(function(){
	'use strict';
	angular.module('sgmApp').controller('ProntuaryFormDialogController', ProntuaryFormDialogController);
	
	ProntuaryFormDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'ConfirmDialogInvoker','$translate', 'prontuaryFormConfig'];
	
	
	function ProntuaryFormDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, ConfirmDialogInvoker, $translate, prontuaryFormConfig){
		
		var vm = this;
		
		vm.prontuaryFormConfig = prontuaryFormConfig;
		/*public*/ vm.closeModal = function()
		{
			if(vm.prontuaryFormConfig.pageMode != 'READONLY') {
				ConfirmDialogInvoker.invoke(
					{
						callback: function(res) { 
							if(res) {
								$uibModalInstance.close();
							} 
						}, 
						text: $translate.instant("client.page.prontuaryFormDialog.confirmClose")
					}
				);
			} else {
				$uibModalInstance.close();
			}

        };
        
	       
	}
})();
