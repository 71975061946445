(function(){
	'use strict';
	angular.module('sgmApp').controller('ProcedureProductKitItemsDialogController', ProcedureProductKitItemsDialogController);
	
	ProcedureProductKitItemsDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'entity'];
	
	
	function ProcedureProductKitItemsDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, entity){
		
		var vm = this;
		console.log(entity);
		vm.productKitItems = entity != null && entity.productKit != null && entity.productKit.productKitItems ?  entity.productKit.productKitItems : [];
		
		/*public*/ vm.closeModal = function()
		{
			$uibModalInstance.close();
        };
        	       
	}
})();
