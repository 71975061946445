(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientDomainController', ClientDomainController);

    ClientDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'pageConfig', '$stateParams', 'genderList', 'maritalStatusList', 'colorList', 'contactTypeList', 'netAccessTypeList', 'indicationTypeList', 'medicNoteInfo', 'isToShowRecordsButton'];

    function ClientDomainController(FormDomain, $scope, $state, entity, pageConfig, $stateParams, genderList, maritalStatusList, colorList, contactTypeList, netAccessTypeList, indicationTypeList, medicNoteInfo, isToShowRecordsButton)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
        vm.injectedResources = {
        	colorList: colorList,
        	contactTypeList: contactTypeList,
			genderList: genderList,
			indicationTypeList: indicationTypeList,
			maritalStatusList: maritalStatusList,
			netAccessTypeList: netAccessTypeList,
			medicNoteInfo: medicNoteInfo,
			isToShowRecordsButton: isToShowRecordsButton
        };

    };
})();
