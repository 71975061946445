(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('FamilyProcedure', FamilyProcedure);

    FamilyProcedure.$inject = ['$resource', 'URL', 'SERVICES'];

    function FamilyProcedure ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/family-procedure/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'listAllIncludeInactive': { method: 'GET', url: url + 'api/family-procedure/list-all-include-inactive', isArray: true },
            'getForTherapyPlace': { method: 'GET', url: url + 'api/family-procedure/get-for-therapy-place' }
        });
    }
})();
