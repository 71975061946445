/**
 * Funcionalidade comuns a página de edição de Producte e do Modal
 * 
 */
(function() {
    'use strict';

	angular
		.module('sgmApp')
		.service('ProductCommon', ProductCommon);
	
	ProductCommon.$inject = ['$translate', '$injector'];
	
	function ProductCommon($translate, $injector)
	{
		var vm = this;
		
		/**
		 * Configuração para as abas da página
		 */
		/*public*/ vm.tabList = [
    		{code: "manufacturerProduct", icon: "fa fa-file-text-o", label: "client.page.product.tab.manufacturer.title"},
    	];
		
	};
	
})();

function ProductCommonStatic()
{
	function f_()
	{
		var baseResolve = {
		};
		
		/*public*/ this.extendResolve = function(p_param)
		{
			var resolve = {};
			
			// Carrega os valores base
			angular.extend(resolve, baseResolve);
			
			// Sobreescreve/Adiciona só os passados como parâmetro
			angular.extend(resolve, (p_param) ? p_param : {});
			return resolve;
		}
	};
	return new f_();
}
