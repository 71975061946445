(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProcedureDomainController', ProcedureDomainController);
    
    ProcedureDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'Procedure', 'pageConfig', '$rootScope', 'FormConstants', 'FrontEndSelectDataSource', 'familyProcedureList', '$timeout', '$translate', 'defaultDurationList', 'ProcedureCommon', 'TabStatePersister', 'PermissionSet', 'Principal'];

    function ProcedureDomainController(FormDomain, $scope, $state, entity, Procedure, pageConfig, $rootScope, FormConstants, FrontEndSelectDataSource, familyProcedureList, $timeout, $translate, defaultDurationList, ProcedureCommon, TabStatePersister, PermissionSet, Principal)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		vm.productTabIsReadonly = true; 
		vm.hasProductEditPermission = Principal.hasAnyAuthority([PermissionSet.PRODUCT.edit]);
		vm.procedureProducts = vm.domain != null && vm.domain.procedureProducts ?  vm.domain.procedureProducts : [];
		vm.procedureProductKits = vm.domain != null && vm.domain.procedureProductKits ?  vm.domain.procedureProductKits : [];
		vm.prontuaryModels = vm.domain != null && vm.domain.prontuaryModels ?  vm.domain.prontuaryModels : [];
		console.log(vm.procedureProductKits);	
		vm.tabList = ProcedureCommon.tabList; 
		vm.tabHandler = {
				disabledMap: {},
				active: TabStatePersister.init('ProcedureDomainController', 'procedureProducts'),
				isActive: function (p_code) {
					return (p_code === vm.tabHandler.active);
				},
				isDisabled: function (p_code) {
					if ((p_code === "procedureProducts") && !vm.domain.id) {
						return true;
					}
					return false;
				},
				isShow: function (p_code) {
					return true;
				},
				select: function (p_id) {
					if (vm.tabHandler.isDisabled(p_id)) {
						return;
					}

					vm.tabHandler.active = TabStatePersister.set("ProcedureDomainController", p_id);
					vm.tabHandler.active = p_id;
				}
			};


        $rootScope.initialFocus("code");

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "PROCEDURE",
        	stateRoot: "procedure",
        	previousState: {name: $state.current.parent},
        	onClear: function() { vm.formHandler.clear(); }
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: Procedure,
        	historyConfig: {historyId: "PROCEDURE"},
        	getForm: function(){ return $scope.editForm; },
        	beforeInvokeService: function(p_json)
        	{
        		// Adicona os valores-padrão das parcelas no json
        		p_json.installmentsStandardValueList = getInstallmentsStandardValuesList();
        		p_json.installmentsObsList = getInstallmentsObsList();
        	}
        };
        vm.formHandler = FormDomain.create(formOptions)
    	.add([new FormField({
    			formTarget: "main",
           		cellCssClass: "col-sm-6",
				type: "String",
				id: "id",
				required: true,
				label: "client.global.label.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})
    	])
    	.add([new FormField({
    			formTarget: "main",
				cellCssClass: "col-sm-6",
				type: "String",
				id: "code",
				required: true,
				label: "common.domain.procedure.code",
				maxLength: 30
			}),
			new FormField({
    			formTarget: "main",
				cellCssClass: "col-sm-6",
				type: "Select",
				id: "familyProcedure",
				required: true,
				label: "common.domain.procedure.familyProcedure",
				dataSource: FrontEndSelectDataSource.create(familyProcedureList)
			})
    	])
		.add([new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-12",
				type: "String",
				id: "description",
				required: true,
				label: "common.domain.procedure.description",
				maxLength: 300
			})
    	])
    	.add([
	    		new FormField({
	    		formTarget: "main",
	    		cellCssClass: "col-sm-2",
	    		type: "Select",
	    		id: "defaultDurationTime",
	    		label: "common.domain.procedure.defaultDurationTime",
	    		dataSource: FrontEndSelectDataSource.create(defaultDurationList)
	    	})
    	])
		.add([new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-3",
				type: "Boolean",
				id: "hasAssistant",
				label: "common.domain.procedure.hasAssistant"
			}),
			new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-3",
				type: "Boolean",
				id: "hasAnesthetist",
				label: "common.domain.procedure.hasAnesthetist"
			}),
			new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-3",
				type: "Boolean",
				id: "hasPaymentAdvance",
				label: "common.domain.procedure.hasPaymentAdvance"
			}),
			new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-3",
				type: "Boolean",
				id: "hasTherapy",
				label: "common.domain.procedure.hasTherapy"
			})
		])
		.add([new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-6",
				type: "Long",
				id: "installmentsNumber",
				required: true,
				label: "common.domain.procedure.installmentsNumber",
				maxLength: 3,
				onChange: function(p_objModel, p_fieldModel, p_currentValue)
				{
					refreshInstallmentTable(p_currentValue)
				}
			}),
			new FormField({
    			formTarget: "main",
    			cellCssClass: "col-sm-6",
				type: "Long",
				id: "sessionsQuantity",
				label: "common.domain.procedure.sessionsQuantity",
				maxLength: 5
			})
    	])
		.add([new FormField({
				formTarget: "not-in-form", // Será adicionado manualmente
				type: "Boolean",
				id: "active",
				label: "client.global.label.active",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})
		])
		.endConfig();
        
        vm.activeFormField = vm.formHandler.findField("active");
        
        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
      
		vm.changeProductTabEdit = function() {
			if(Principal.hasAnyAuthority([PermissionSet.PRODUCT.edit])) {
				vm.productTabIsReadonly = false; 
			}
		}

        vm.installmentTable = {
        	show: false,
        	rows: [],
        	model: {}
        };
        /*private*/ function refreshInstallmentTable(p_installmentNumber)
        {
        	// Limpa a componente
        	vm.installmentTable.rows = [];
        	
        	if(!p_installmentNumber)
        	{
        		p_installmentNumber = 0;
        	}
        	
        	// Recria
        	for(var i = 0 ; i < p_installmentNumber ; i++)
        	{
        		vm.installmentTable.rows.push({
        			label: (i == 0) ? $translate.instant("client.global.label.uniqueInstallment") : i + 1,
        			fieldModel: new FormField({
        				type: "Money",
        				id: "installment" + i,
        				noLabel: true
        			}),
        			obsFieldModel: new FormField({
        				type: "String",
        				id: "obs" + i,
        				noLabel: true,
        				maxLength: 500
        			})
        		});
        	}
        	
        	// Se for o primeiro refresh, copia os valores das parcelas do model, caso existam
        	if(!vm.installmentTable.show)
        	{
        		if(vm.domain.procedureStandardValueSet)
        		{
        			var temp;
        			for(var i = 0 ; i < vm.domain.procedureStandardValueSet.length; i++)
        			{
        				temp = vm.domain.procedureStandardValueSet[i];
        				vm.installmentTable.model["installment" + (temp.installment - 1)] = temp.value;
        				vm.installmentTable.model["obs" + (temp.installment - 1)] = temp.obs;
        			}
        		}
        	}
        	
        	// Habilita a visualização no primeiro item
        	vm.installmentTable.show = true;
        };
        
        /*private*/ function getInstallmentsStandardValuesList()
        {
        	var list = [];
        	var val;
        	for(var i = 0 ; i < vm.installmentTable.rows.length ; i++)
        	{
        		val = vm.installmentTable.model["installment" + i];
        		if(!val && val !== 0)
        		{
        			val = null;
        		}
        		list.push(val);
        	}
        	return list;
        };
        
        /*private*/ function getInstallmentsObsList()
        {
        	var list = [];
        	var val;
        	for(var i = 0 ; i < vm.installmentTable.rows.length ; i++)
        	{
        		val = vm.installmentTable.model["obs" + i];
        		if(!val && val !== 0)
        		{
        			val = null;
        		}
        		list.push(val);
        	}
        	return list;
        };
        
        // Inicializa a tabela
        $timeout(function()
        {
        	refreshInstallmentTable(vm.domain.installmentsNumber);
        }, 1);
    };
})();
