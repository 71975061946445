(function(){
	'use strict';
	angular.module('sgmApp').controller('ManufacturerProductDialogController', ManufacturerProductDialogController);
	
	ManufacturerProductDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'ManufacturerProduct', 'entity'];
	
	
	function ManufacturerProductDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, ManufacturerProduct, entity){
		
		var vm = this;
		vm.pageConfig = pageConfig;
				
		if(!entity) {
			entity = {};
		}
		var generateBarCode = false;
		
		var formOptions = {
				model: entity,
				service: ManufacturerProduct,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormManufacturerProduct; }
	        };
		
		vm.formHandler = FormDomain.create(formOptions).
			add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.product.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
			]).add([
				new FormField({
					type: "Select",
					id: "manufacturer",
					required: true,
					label: "client.page.product.tab.manufacturer.dialog.selectManufacturer",
					cellCssClass: "col-sm-6",
					dataSource: FormConstants.DataSources.BackEnd.Manufacturer(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "name",
					required: true,
					label: "common.domain.manufacturerProduct.name"
				})
			]).add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "barCode",
					required: true,
					label: "common.domain.manufacturerProduct.barCode"
				}),
			])
			.add([
				new FormField({
					type: "Boolean",
					id: "generateBarCode",
					cellCssClass: "col-sm-12",
					onChange: function(value) {
						generateBarCode = value.generateBarCode;
						vm.formHandler.findField("barCode").hide = generateBarCode;
						vm.formHandler.findField("barCode").required = !generateBarCode;
						vm.formHandler.reloadValidation($scope.editFormManufacturerProduct);
					},
					label: "client.page.product.tab.manufacturer.dialog.generateBarCode",
				})
			])
			.add([new FormField({
				cellCssClass: "col-sm-12",
				type: "Boolean",
				id: "active",
				label: "common.domain.product.active",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})
		])
		
		 /*public*/ vm.closeModal = function(manufacturerProduct)
		 {
			$uibModalInstance.close(manufacturerProduct);
        };
		console.log(entity.id);
        vm.formHandler.findField("generateBarCode").hide = entity.id != undefined;
        vm.onSaveManufacturerProduct = function(){
        	
        	var domain = null;
			//if is save needs to validate and return the object
			console.log($scope.editFormManufacturerProduct)
			console.log(vm.formHandler.getRowList());
			FormUtil.validateAll($scope.editFormManufacturerProduct, vm.formHandler.getRowList());
			if($scope.editFormManufacturerProduct.$valid) {
				domain = vm.formHandler.readForm();
				console.log(domain);
				if(domain.generateBarCode) {
					domain.barCode = "";
				}
				vm.closeModal(domain);
			}
        	
        }
	       
	}
})();
