(function() {
    'use strict';

	angular
		.module('sgmApp')
		.controller('HistoryModalController', HistoryModalController);
	
	HistoryModalController.$inject = ['$uibModalInstance', 'trackChangesData', '$translate'];
	
	function HistoryModalController($uibModalInstance, trackChangesData, $translate)
	{
		var vm = this;
		
		vm.entityName = trackChangesData.entityName;
		
		vm.justificationEnabled = !!(trackChangesData.justificationEnabled);
		vm.changeColWidth = (vm.justificationEnabled) ? 322 : 496;
		
		vm.rowList = [];
		if(trackChangesData.changesList)
		{
			vm.rowList = trackChangesData.changesList;
		}
		
		/*public*/ vm.closeModal = function()
		{
			$uibModalInstance.dismiss();
		}

	}
})();
