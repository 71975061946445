(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', /*'LoginService',*/ '$state', '$injector'];

    function HomeController ($scope, Principal, /*LoginService,*/ $state, $injector)
    {
//    	var LoginModalInvoker = $injector.get('LoginModalInvoker');
//    	LoginModalInvoker.invoke();

        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
//        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();
        
        function getAccount()
        {
            Principal.identity().then(function(account)
            {
                vm.account = account;
            });
        }
        function register () {
            $state.go('register');
        }
        
        /**************************************/
        
    }
})();
