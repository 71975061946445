(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ConfirmationMessageGenericTemplateDialogController', ConfirmationMessageTemplateDialogController);

    ConfirmationMessageTemplateDialogController.$inject = ['$scope', '$uibModalInstance', 'FormDomain', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', 'Attendance', '$rootScope', '$filter', '$sce', 'UcAlertService'];

    function ConfirmationMessageTemplateDialogController($scope, $uibModalInstance, FormDomain, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, Attendance, $rootScope, $filter, $sce, UcAlertService)
    {
        var vm = this;
        
        vm.pageConfig = new PageConfig({
    		pageMode: "CUSTOM_EDIT" // Para não ser inseridos buttons
    	});
        
        
        /*private*/ var copyButton = {
        	order: 0,
        	cssClass: 'btn btn-success',
        	cssClassIcon: 'fa fa-whatsapp',
        	label: 'client.page.confirmationMessageTemplate.copyToWhatsapp',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function()
        	{
        		var msg = $.trim(vm.messageDto.whatsapp);
        		msg = unescapeHtml(msg);
        		copyToClipboard(msg,
        			function()
        			{
        				UcAlertService.success("client.page.confirmationMessageTemplate.messageCopiedToClipboard");
        				vm.closeModal();
        			},
        			function(p_errorMessage)
        			{
        				console.error("Error at copy message to clipboard: " + ((p_errorMessage) ? p_errorMessage : "-"));
        				UcAlertService.error($translate.instant("client.page.confirmationMessageTemplate.failCopiedToClipboard"));
        			});
        	}
        };

        /*private*/ var closeButton = {
        	order: 10,
        	cssClass: 'btn btn-default',
        	cssClassIcon: 'fa fa-times',
        	label: 'client.global.label.close',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function()
        	{
        		vm.closeModal();
        	}
        };
        
        vm.buttonsModel = {
	    	customButtonList: [ copyButton, closeButton ]
	    }; 

        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
    }
})();