(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(config);
    
    config.$inject = ['blockUIConfig'];



    function config(blockUIConfig)
    {
		blockUIConfig.message = " ";
		blockUIConfig.template = '<div class=\"block-ui-overlay\"></div><div class=\"block-ui-message-container\" aria-live=\"assertive\" aria-atomic=\"true\"><div class=\"block-ui-message\" ng-class=\"$_blockUiMessageClass\"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;&nbsp;{{ "Aguarde" }}</div></div>';
		blockUIConfig.requestFilter = function(config)
		{
			if(config.url.match(/api\/grid\/fetch/) ||
				config.url.match(/api\/uc\/fetch-select/) ||
				config.url.match(/api\/uc\/load-avatar/) ||
				config.url.match(/api\/uc\/load-photo/) )
			{
			    return false;
			}

/*			
			if(config.url.match(/^api\/dashboard\/loadValues/)) {
			    return false;
			}
			
			if(config.url.match(/^api\/dashboard\/updateNumberSensorsPerGroupSensor/) ||
				config.url.match(/^api\/dashboard\/updateNumberColumns/) ||
				config.url.match(/^api\/dashboard\/numberColumns/) ||
				config.url.match(/^api\/dashboard\/updateCollapsedFlag/) ||
				config.url.match(/^api\/dashboard\/updateFullscreenFlag/) ||
				config.url.match(/^api\/dashboard\/removeGroupSensor/) )
			{
			    return false;
			}
			*/
			

		};
    }
    
    /*****************************************************/
    
    angular
    	.module('sgmApp')
    	.filter('selectFilter', function()
        {
    		return function(p_items, p_filter, p_isString)
    		{
    			var out = [];
    			if (angular.isArray(p_items))
    			{
					if(!p_isString)
    					var keys = Object.keys(p_filter);

    				p_items.forEach(function(p_item)
    				{
    					var itemMatches = false;
						if(p_isString) {
							var text = p_filter.toLowerCase();
							if (p_item.toString().toLowerCase().indexOf(text) !== -1)
							{
								itemMatches = true;
							}
						} else {
							for (var i = 0; i < keys.length; i++) {
								var prop = keys[i];
								var text = p_filter[prop].toLowerCase();
								if (p_item[prop].toString().toLowerCase().indexOf(text) !== -1)
								{
									itemMatches = true;
									break;
								}
							}
						}
    					if (itemMatches)
    					{
    						out.push(p_item);
    					}
    				});
    			}
    			else
    			{
    				// Let the output be the input untouched
    				out = p_items;
    			}
    		return out;
    	};
    });
    
    
})();

