(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .controller('UcStaticController', UcStaticController);

    UcStaticController.$inject = ['Agenda', '$translate', 'UcAlertService'];

    function UcStaticController(Agenda, $translate, UcAlertService)
    {
		var vm = this;

		/*public*/ vm.alertService = function(p_message, p_config)
		{
			UcAlertService.message(p_message, p_config.level, p_config.type);
		}
		
		/*public*/ vm.showAgendaObs = function(p_attendanceId)
		{
			Agenda.getAttendanceObs({attendanceId: p_attendanceId}, function(p_dto)
			{
				var content = "<ul>";
				if(p_dto.salesRequestObs)
				{
					content += "<li><b>" + $translate.instant("client.page.attendance.tabs.notes.salesRequestObservation") + "</b>: " + p_dto.salesRequestObs + "</li>";
				}
				if(p_dto.clientObs)
				{
					content += "<li><b>" + $translate.instant("common.domain.client.clientObs") + "</b>: " + p_dto.clientObs + "</li>";
				}
				content += "</ul>";
				UcAlertService.info(content)
			});
		}
    }
})();
