(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceStatusSelectorModalController', AttendanceStatusSelectorModalController);

    AttendanceStatusSelectorModalController.$inject = ['$scope', 'pageConfig', '$uibModalInstance', '$rootScope', 'Attendance', 'entity', 'account', 'FormDomain', 'FormConstants', 'userPermissions', '$translate', 'UcAlertService'];

    function AttendanceStatusSelectorModalController($scope, pageConfig, $uibModalInstance, $rootScope, Attendance, entity, account, FormDomain, FormConstants, userPermissions, $translate, UcAlertService)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        vm.domain = entity;
        
        vm.pageConfig.pageMode = (userPermissions.hasEvents) ? "EDIT" : "READONLY"; // Seleciona o PAGE_MODE de acordo com a permissão
        vm.showSaveButton = userPermissions.hasEvents;
        
        if(entity.salesRequest)
        {
        	if(entity.salesRequest.client)
        	{
        		entity.clientName = entity.salesRequest.client.name;
        	}

        	if(entity.salesRequest.procedure)
        	{
        		entity.procedureDescription = entity.salesRequest.procedure.description;
        	}
        	if(entity.medic)
        	{
        		entity.medicName = entity.medic.name;
        	}
        }

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: null,
        	showTabWithError: true,
        	getForm: function(){ return $scope.editForm; }
        };
        
        vm.formHandler = FormDomain.create(formOptions)
    	.add([new FormField({
    			cellCssClass: "col-sm-8",
				type: "String",
				id: "clientName",
				label: "client.page.attendance.client",
				readOnly: true
			}),
			new FormField({
    			cellCssClass: "col-sm-4",
				type: "String",
				id: "id",
				label: "common.domain.attendance.id",
				readOnly: true
			})
    	])
    	.add([new FormField({
    			cellCssClass: "col-sm-4",
				type: "String",
				id: "procedureDescription",
				label: "client.page.attendance.procedure",
				readOnly: true
			}),
			new FormField({
    			cellCssClass: "col-sm-4",
				type: "String",
				id: "medicName",
				label: "client.page.attendance.medic",
				readOnly: true
			}),
			new FormHtmlRender({
    			cellCssClass: "col-sm-4",
    			label: "client.global.label.status",
    			hasLabel: true,
				render: function(p_objModel, p_fieldModel, p_pageMode, p_editMode)
				{
					var html = "<div style='display:table'>" +  PillRenderer.rendererEnum(p_objModel["status"]);
					if(p_objModel.reschedulingNumber > 0)
					{
						html += '<span class="attendance-rescheduling-flag-separator"></span>';
						html += '<span class="attendance-rescheduling-flag" title="' + $translate.instant("client.page.attendance.attendanceRescheduledP0TimesLastOperationByP1", {number: p_objModel.reschedulingNumber, user: (p_objModel.userReschedulingUserId) ? p_objModel.userReschedulingUserId.defaultDescription : ""}) + '" onclick="staticAlertService(this.title, {level: \'INFO\', type: \'ALERT\'})"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></span>';
					}
					html += '</div>';
					return html;
				}
			})
    	])
    	
    	/**************************************************/

    	/*private*/ function findField(p_fieldsList, p_fieldId)
	    {
	    	for (var i = 0; i < p_fieldsList.length; i++)
	    	{
	    		if(p_fieldsList[i].id == p_fieldId)
	    		{
	    			return p_fieldsList[i];
	    		}
			}
	    	return null;
	    }
    	
        /**
         * Retorna só os campos referentes a paineis ativos não readOnly
         */
        /*private*/ function extracActiveFields()
        {
        	var activeFieldList = [];
    	    for (var i = 0; i < vm.dateTimePanelList.length; i++)
    	    {
    	    	if(!vm.dateTimePanelList[i].readOnly && vm.dateTimePanelList[i].state)
    	    	{
    	    		activeFieldList.push(vm.dateTimePanelList[i].formField);
    	    	}
    		}
    	    return activeFieldList;
        };

    	
	    function AttendanceDateTimePanel(p_code, p_id, p_label, p_iconCssClass, p_initialState, p_readOnly, p_border)
	    {
	    	var self = this;
	    	
	    	this.state = p_initialState;
	    	this.border = p_border;
	    	
	    	// Agendamento sempre readonly
	    	if(p_id == "plannedDate")
	    	{
	    		this.readOnly = true;
	    	}
	    	else
	    	{
	    		this.readOnly = !userPermissions.hasEvents;
	    	}
   	
	        this.formField = new FormField({
				formTarget: "no-bind",
				type: "DateTime",
				id: p_id,
				noLabel: true,
				required: false,
				readOnly: (this.readOnly || !p_initialState),
				vertical: true
			});
	        
	        this.stateButton = {
	        	label: $translate.instant(p_label),
	        	iconCssClass: p_iconCssClass,
	        	readOnly: this.readOnly,
	        	classCss: "w100",
	        	onClick: function()
	        	{
	        		self.formField.readOnly = self.state;
	        		$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly(p_id), {readOnly: !self.state, absolute: true});
	        		
	        		// Se for ativação e ambos os campos estiverem vazios, sugere a data corrente
	        		if(self.state)
	        		{
	        			if((entity[p_id + "_date"] === null || entity[p_id + "_date"] === "") && (entity[p_id + "_time"] === null || entity[p_id + "_time"] === ""))
	        			{
	        				var now = new Date();
	        				var date = createNDate(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
	        				var time = completeLeftWithZero(now.getHours(), 2) + ":" + completeLeftWithZero(now.getMinutes(), 2);
	        				entity[p_id] = date;
	        				entity[p_id + "_date"] = date;
	        				entity[p_id + "_time"] = time;
	        			}
	        		}
	        	}
	        };
	    };
    	
	    var statusCode = (entity.status) ? entity.status.id : null;
	    vm.dateTimePanelList = [];

    
		if(entity.plannedDateNoTimezone)
		{
			entity.plannedDateAsDate = moment(entity.plannedDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d;
		}
		if(entity.plannedEndDateNoTimezone)
		{
			entity.plannedEndDateAsDate = moment(entity.plannedEndDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d;
		}
	    
	    vm.dateTimePanelList.push(new AttendanceDateTimePanel("AGE", "plannedDate", "client.page.attendance.tabs.scheduling.plannedDate", "pe pe-7s-date", true, true, "border-right:solid 1px #d0d0d0;"));
    	if(entity.id)
    	{
	    	vm.dateTimePanelList.push(new AttendanceDateTimePanel("CHE", "arrivalDate", "client.page.attendance.tabs.scheduling.arrivalDate", "fa fa-map-marker", (statusCode == 'CHE' || statusCode == 'INI' || statusCode == 'FIM'), !entity.status.canPerformArrival, "border-right:solid 1px #d0d0d0;"));
	    	vm.dateTimePanelList.push(new AttendanceDateTimePanel("INI", "startConsultationDate", "client.page.attendance.tabs.scheduling.startAttendanceDate", "fa fa-sign-in", (statusCode == 'INI' || statusCode == 'FIM'), !entity.status.canStartConsultation, "border-right:solid 1px #d0d0d0;"));
	    	vm.dateTimePanelList.push(new AttendanceDateTimePanel("FIM", "endConsultationDate", "client.page.attendance.tabs.scheduling.endAttendanceDate", "fa fa-sign-out", (statusCode == 'FIM'), !entity.status.canEndConsultation, null));
    	}

	    // Adiciona todos no formHandler
	    var formFieldList = [];
	    for (var i = 0; i < vm.dateTimePanelList.length; i++)
	    {
	    	formFieldList.push(vm.dateTimePanelList[i].formField)
		}
	    
        vm.formHandler
        	.add(formFieldList)
        	.endConfig();
        
    
        /*public*/ vm.save = function()
        {
        	var json = {
        		id: entity.id,
        		pageMode: pageConfig.pageMode
        	};
        	var activeFieldsList = extracActiveFields();
        	
	    	// Preenche o json validando
	    	var eventsDateIdArray = ["arrivalDate", "startConsultationDate", "endConsultationDate"]; // A data principal (plannedDate) não é considerada
	    	for(var i = 0 ; i < eventsDateIdArray.length ; i++)
	    	{
	    		var dateId = eventsDateIdArray[i];
	    		
	    		// Está selecionado?
	    		var selectedField = findField(activeFieldsList, dateId); 
	    		if(selectedField)
	    		{
	    			// Seta a data no json
	    			FormConstants.ValTransforms.DATE_TIME(json, selectedField, entity[selectedField.id]);
	    			
	    			// Data preenchida corretamente?
	    			if(json[selectedField.id])
	    	    	{
	    				// Seta tambem a flag
	        			json[selectedField.id + "Flag"] = true;
	    	    	}
	    			else // Lança o erro
	    			{
	    	            UcAlertService.message(
	                		$translate.instant("client.global.messages.error.nullOrInvalidDateP0",
	                		{
	                			field: $translate.instant("client.page.attendance.tabs.scheduling." + selectedField.id  + "FullDescription")
	                		}), "WARNING", "BOX");
	    	            return;
	    	            
	    	    	}
	    		}
	    		else
	    		{
	    			// Limpa o campo, case tenha sido preenchido errôneamente
	    			json[dateId + "Flag"] = false;
	    			json[dateId] = null;
	    		}
	    	}        	
	    	
	    	// Tudo ok, salva o json
	    	Attendance.updateAttendanceStatus(json, function()
	    	{
	    		$uibModalInstance.close(true);
	    	});
        };
        
        
		/**
		 * Cancel or top-icon close
		 */
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();