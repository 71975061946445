(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('CameraHandlerDirectiveController', CameraHandlerDirectiveController);
    
    CameraHandlerDirectiveController.$inject = ['$scope', '$translate', '$timeout', 'blockUI'];
    function CameraHandlerDirectiveController($scope, $translate, $timeout, blockUI)
    {
    	var vm = this;

    	vm.id = $scope.id;
    	vm.width = $scope.width;
    	vm.height = $scope.height;

    	var cameraHandler = new CameraHandler(new CameraHandlerParam($scope.id, blockUI))
    	
    	var resolution = cameraHandler.getResolution();
		vm.imageWidth = resolution[0];
		vm.imageHeight = resolution[1];
		
		$timeout(function()
		{
			cameraHandler.init();
		}, 100);
		
		
		/*private*/ function block(p_)
		{
			$("#" + vm.id + "-loading").css({display: !p_ ? "none" : "block"});
			$("#" + vm.id + "-video").css({visibility: !p_ ? "visible" : "hidden"});
		}
		
		var listener = $scope.$on(CameraHandler.Events.TakePicture, function()
		{
			block(true);
			
			$timeout(function() // Próximo ciclo
			{
				try
				{
					$("#" + vm.id + "-input").val(cameraHandler.snapshot());
					block(false);
					$scope.$emit(CameraHandler.Events.TakePictureEnd, {result: true});
				}
				catch(ee)
				{
					block(false);
					$scope.$emit(CameraHandler.Events.TakePictureEnd, {result: false, exception: ee});
				}
			}, 100);
		});
		
		toDestroy($scope, listener);
		
		
		/*
		 * Fecha a câmera no destroy do contexto
		 */
		$scope.$on('$destroy', function()
    	{
			cameraHandler.close();
    	});
    };
})();