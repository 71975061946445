(function() {
    'use strict';
    
    angular.module('sgmApp').service('BackEndSelectDataSource', BackEndSelectDataSource);

    BackEndSelectDataSource.$inject = ['UcService', 'UcAlertService', '$translate', '$timeout', '$sce', '$filter', 'FormConstants'];
    
    function BackEndSelectDataSource(UcService, UcAlertService, $translate, $timeout, $sce, $filter, FormConstants)
    {
    	var vm = this;
    
    	 /* implements ISelectDataSource*/
    	
    	/*public*/ vm.create = function(p_id, p_params)
    	{
    		return new _f(p_id, p_params);
    	};
    	
    	function _f(p_id, p_params)
		{
			var self = this;
			
			// Setados no init
			var multiple = false;
			var formField = null;
			
	    	var selectPlaceHolder = $translate.instant("client.global.label.loading") + "...";
	    	
	    	// @Override
			/*public*/ this.optionsList = [];
			
			var defaultParams =
			{
				cssClass: "",
				isDescriptionSelect: false,
				minCharToSearch: 0,
				parseSelectOptionText: null,
				searchActiveOnly: false
			};
			
    		var params = defaultParams;
    		angular.extend(params, (p_params) ? p_params : {});

			// @Override
			/*public*/ this.init = function(p_multiple, p_formField, p_objModel)
			{
				multiple = p_multiple;
				formField = p_formField;

				// Agenda a busca para o próximo ciclo
				$timeout(function()
				{
					checkFormFieldDependency();	
					selectFetch(null, p_objModel, params.searchActiveOnly);
				}, 1);
			};

			// @Override
			/*public*/ this.getOptionList = function()
			{
				return this.optionsList;
			}

			// @Override
			/*public*/ this.onSelect = function(p_item, p_objModel, p_fieldModelId)
			{
				// Opção que representa a opção vazia
				if(p_item && p_item.noResultNode)
				{
					if(multiple)
					{
						var arr = p_objModel[p_fieldModelId];
						for(var i = 0 ; i < arr.length ; i++)
						{
							if(arr[i].noResultNode)
							{
								arr.splice(i, 1);
							}
						}
					}
					else
					{
						p_objModel[p_fieldModelId] = null;
					}
					return;
				}
			};

			// @Override
			/*public*/ this.refresh = function(p_search, p_objModel, p_searchActiveOnly)
			{
				selectFetch(p_search, p_objModel, p_searchActiveOnly);
			};
			
			// @Override
	    	/*public*/ this.parseSelectOptionText = function(p_opt, p_search)
	    	{
	    		// id nulo implica em nenhum resultado encontrado
	    		if(!p_opt || p_opt.id === null)
	    		{
	    			return $sce.trustAsHtml(p_opt.defaultDescription);
	    		}
	    		
	    		var text;
	    		// Se for explicitado no params, é preferencial
	    		if(params.parseSelectOptionText)
    			{
	    			text = params.parseSelectOptionText(p_opt, p_search, $filter("highlight"));
	    			return $sce.trustAsHtml(text);
    			}
	    		else
	    		{
		    		text = (p_opt && p_opt.defaultDescription) ? p_opt.defaultDescription : "";
		    		return $sce.trustAsHtml($filter("highlight")(text, p_search));
	    		}
	    	};
	    	
	    	// @Override
	    	/*public*/ this.parseSelectedOption = function(p_opt)
	    	{
				var text = "";
				if(p_opt) {
					if(params.parseSelectedOption) {
						text = params.parseSelectedOption(p_opt);
						text = text ? text : "";
					} else {
						text = (p_opt.defaultDescription) ? p_opt.defaultDescription : "";
					}
				}
	    		return $sce.trustAsHtml(text);
	    	};

	    	// @Override
	    	/*public*/ this.resolveSelectPlaceHolder = function()
	    	{
	    		return selectPlaceHolder;
	    	};
	    	
	    	/*public*/ this.getCssClass = function()
	    	{
	    		var cssClass = params.cssClass;
	    		if(params.isDescriptionSelect)
	    		{
	    			cssClass += " " + FormConstants.CssClass.DescriptionSelect;
	    		}
	    		return cssClass;
	    	};

			function fetchFromDependent(p_dependentField, selectParams, p_objModel) {
				var dependentValue = null;
				dependentValue = p_objModel[p_dependentField.id];
				// Vai sempre como array
				if(!dependentValue)
				{
					dependentValue = [];
				}
				else if(!angular.isArray(dependentValue))
				{
					var temp = []
					temp.push(dependentValue);
					dependentValue = temp;
				}
				
				// Se for dependente e não houver seleção, verifica a flag 
				if(dependentValue.length == 0 && !params.fetchAllForEmptyDependent)
				{
					setOptionsList([]);
					selectPlaceHolder = $translate.instant("client.global.label.selectOne", {"item": "[" + $translate.instant(p_dependentField.label) + "]"});
					return true;
				}
				const dependencyName = p_dependentField.dependencyName || p_dependentField.id;
				selectParams[dependencyName] = serializeList(extractIdList(dependentValue));
				return false;
			}

			/*private*/ function selectFetch(p_search, p_objModel, p_searchActiveOnly)
			{

				// Se for dependente, busca os valores do outro form-field
				var selectParams = {};
				if(params.dependsOn)
				{
					if(params.dependsOn instanceof Array) {
						var hasEmptyDependency = false;
						for(var i = 0; i < params.dependsOn.length; i++) {
							var field = params.dependsOn[i];
							hasEmptyDependency = fetchFromDependent(field, selectParams, p_objModel);
							if(hasEmptyDependency) {
								return;
							}
						}
						if(hasEmptyDependency) {
							return;
						}
					} else {
						if(fetchFromDependent(params.dependsOn, selectParams, p_objModel)) {
							return;
						}
					}
					
				}
				
				if(!p_search)
				{
					p_search = "";
				}
				if(p_search.length >= params.minCharToSearch)
				{
		    		// Se não tem id p datasource definido, erro e aborta a operação
		    		if(!p_id)
		    		{
		    			UcAlertService.error($translate.instant("client.global.messages.error.undefinedSelectDatasourceId"));
		    			return;
		    		}
					
					
					if(params.criteria) {
						if(params.criteria instanceof Function) {
							selectParams = Object.assign(selectParams, params.criteria());
						} else {
							selectParams = Object.assign(selectParams,params.criteria);
						}
						if(selectParams['searchActiveOnly'] == null) {
							selectParams['searchActiveOnly'] = p_searchActiveOnly;
						}
					} else {
						selectParams['searchActiveOnly'] = p_searchActiveOnly;
					}
					selectParams['id'] = p_id;
					selectParams['search'] = p_search;
					selectParams['searchKey'] = params.searchKey;
		    		UcService.fetchSelect(
		    			selectParams,
		    			function(p_list) // Success
		    			{
		    				setOptionsList(p_list);
		    			},
		    			function() // error
		    			{
		    				setOptionsList([]);
		    			}
		    		);
				}
				else
				{
					var placholderList = [];
					placholderList.push({id: null, noResultNode: true, defaultDescription: "<span class='no-result'>" + $translate.instant("client.global.label.selectMinChars", {value: 3}) + "</span>", disabled:true});
					setOptionsList(placholderList);
				}
			};
			
			/*private*/ function setOptionsList(p_list)
			{
				if(params.transformOptionsList)
					p_list = params.transformOptionsList(p_list);
				self.optionsList = p_list;
		    	selectPlaceHolder = $translate.instant("client.global.label.select");
		    	// Não se aplica para o componente de select com checkbox
//				if(self.optionsList.length == 0)
//				{
//					self.optionsList.push({id: null, noResultNode: true, defaultDescription: "<span class='no-result'>" + $translate.instant("client.global.label.noResult") + "</span>"});
//				}
			}
			
			/*private*/ function checkFormFieldDependency()
			{
	    		// Se depende de outro formField, deve adicionar um evento de onChange para atualizar este componente
	    		if(params.dependsOn)
	    		{
					if(params.dependsOn instanceof Array) {
						params.dependsOn.forEach(element => {
							addFormOnChangeDependencyToField(element);
						});
					} else {
						addFormOnChangeDependencyToField(params.dependsOn);
					}
	    			
	    		}
			};

			function addFormOnChangeDependencyToField(dependsOnField) {
				var currentOnChange = dependsOnField.onChange;
				dependsOnField.onChange = function(p_objModel, p_fieldModel, p_currentValue)
				{
					// Chama o original, caso exista
					if(currentOnChange)
					{
						try
						{
							currentOnChange(p_objModel, p_fieldModel, p_currentValue);
						}
						catch(eee)
						{
							console.error("Error at call currentOnChange: " + p_id + " / " + dependsOnField.id + " / " + eee.message)
						}
					}
					// Limpa a seleção
					if(angular.isArray(p_objModel[formField.id]))
					{
						p_objModel[formField.id] = [];
					}
					else
					{
						delete p_objModel[formField.id];
					}
					selectFetch(null, p_objModel, params.searchActiveOnly);
				}
			}

    	};
    }
})();
