(function() {
    'use strict';

	angular
		.module('sgmApp')
		.controller('ClientMinManagerModalController', ClientMinManagerModalController);
	
	ClientMinManagerModalController.$inject = ['$scope', 'pageConfig', 'genderList', '$uibModalInstance', '$rootScope', 'FormDomain', 'FrontEndSelectDataSource', 'UcAlertService', '$translate', 'Client'];
	
	function ClientMinManagerModalController($scope, pageConfig, genderList, $uibModalInstance, $rootScope, FormDomain, FrontEndSelectDataSource, UcAlertService, $translate, Client)
	{
		var vm = this;

		vm.pageConfig = pageConfig;
		vm.uibModalInstance = $uibModalInstance;

		var entity = [];
		
		UcAlertService.setForceAlertType($scope);
		
        $rootScope.initialFocus("name");

        vm.buttonsModel = {
        	isSaving: false,
        	onClear: function() { vm.formHandler.clear(); },
        	customButtonList: [{
        		order: 0,
	        	cssClass: 'btn btn-success',
	        	cssClassIcon: 'glyphicon glyphicon-save',
	        	label: 'client.global.label.save',
	        	type: 'submit',
	        	isToAdd: function() { return true; },
	        	click: function()
	        	{
	        		/* void - por ser submit, a ação está no ng-submit do form */
	        	}	        
	        },
	        {
	        	order: 91,
	        	cssClass: 'btn btn-default',
	        	cssClassIcon: 'fa fa-times',
	        	label: 'client.global.label.close',
	        	type: 'button',
	        	isToAdd: function() { return true; },
	        	click: function()
	        	{
	        		vm.closeModal(); 
	        	}
	        }]
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	    			cellCssClass: "col-sm-6",
					type: "String",
					id: "name",
					required: true,
					label: "client.global.label.name",
				}),
	        	new FormField({
	           		cellCssClass: "col-sm-6",
					type: "CPF",
					id: "cpf",
					label: "common.domain.client.cpf",
					maxLength: 14
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "gender",
					label: "common.domain.client.gender",
					required: true,
					dataSource: FrontEndSelectDataSource.create(genderList)
				}),new FormField({
	    			cellCssClass: "col-sm-6",
					type: "Date",
					id: "birthDate",
					label: "common.domain.client.birthDate",
					required: false
				})
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "Phone",
					id: "whatsapp",
					label: "common.domain.client.whatsapp",
					addonCssClass: "fa fa-whatsapp",
					onChange: function(p_objModel, p_fieldModel, p_currentValue)
					{
						// Se o whatsapp preenchido e o cel não, copia o valor
						if(entity.whatsapp && (!entity.cel || entity.whatsapp.indexOf(entity.cel) > -1))
						{
							entity.cel = entity.whatsapp;
						}
					}
				}),new FormField({
	           		cellCssClass: "col-sm-6",
					type: "Phone",
					required: true,
					id: "cel",
					label: "common.domain.client.cel",
					addonCssClass: "glyphicon glyphicon-phone"
				})
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "Phone",
					id: "cel2",
					label: "common.domain.client.cel2",
					addonCssClass: "glyphicon glyphicon-phone"
				}),new FormField({
	       			cellCssClass: "col-sm-6",
					type: "String-Group",
					id: "email",
					label: "common.domain.client.email",
					addonCssClass: "fa fa-envelope-o"
				})
			])
			.endConfig();
        
        /*public*/ vm.save = function()
        {
        	vm.formHandler.preSave(
        		function(p_json, p_onSaveSuccess, p_onSaveError)
        		{
            		Client.save(p_json, 
            			function(p_result, p_headers)
            			{
	            			formOptions.buttonsModel.isSaving = false;
	            	    	
	            	    	var newEntityId = (p_headers) ? p_headers('X-UC-New-Entity-Id') : null;
	            	    	Client.get({id: newEntityId}, function(p_obj)
	            	    	{
	            	    		// Finalizando chamando o callback
	            	    		$uibModalInstance.close(p_obj);
	            	    	})
            	    	}, p_onSaveError);
        		},
        		function() // Erro de validação de formulário
        		{
        			UcAlertService.dismissBox();
        			UcAlertService.error($translate.instant("client.global.messages.error.thereAreErrorsOnForm"));
        		}
        	);
        }

		/*public*/ vm.closeModal = function()
		{
			$uibModalInstance.dismiss();
		}
	}
})();
