(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('prontuaryItemForm', ProntuaryItemFormDirective);
    
    ProntuaryItemFormDirective.$inject = [];
    function ProntuaryItemFormDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "ProntuaryItemFormDirectiveController",
            templateUrl: "app/components/prontuary-form/prontuary-item-form/prontuary-item-form.directive.template.html",
            scope:
            {
            	prontuaryItem: '=',
            	pageConfig: '=',
                pageMode: '=',
            	isAttendance: '=',
                onDeleteClick: "=",
                onEditItem: "=",
                onSelectOption: "=",
                notInProcedure: "="
            }
    	}
    };
})();
