(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('TextDialogController', TextDialogController);

    TextDialogController.$inject = ['$scope', '$uibModalInstance', 'text', 'title', 'pageConfig', '$translate', 'FormDomain'];

    function TextDialogController ($scope, $uibModalInstance, text, title, pageConfig, $translate, FormDomain)
    {
        var vm = this;
        var entity = {
            text: text
        };
        vm.pageConfig = pageConfig;
        vm.title = title;
        console.log(pageConfig);
        var formOptions = {
            model: entity,
            pageConfig: vm.pageConfig,
            getForm: function(){ return $scope.textDialogForm; }
        };
    
        vm.formHandler = FormDomain.create(formOptions).
        add([
            new FormField({
                cellCssClass: "col-sm-12",
                type: "TextArea",
                id: "text"
            }),
        ]);

        vm.closeModal = function()
        {
            $uibModalInstance.dismiss();
        }

        vm.confirm = function()
        {
            var domain;
            FormUtil.validateAll($scope.textDialogForm, vm.formHandler.getRowList());
			if($scope.textDialogForm.$valid) {
                domain = vm.formHandler.readForm();
                $uibModalInstance.close(domain.text);
            }
            
        }
    }
})();
