(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PaymentAttendanceDomainController', PaymentAttendanceDomainController);
    
    PaymentAttendanceDomainController.$inject = ['FormDomain', '$scope', '$state', 'Attendance', '$rootScope', 'FormConstants', '$translate', 'UcAlertService', 'FrontEndSelectDataSource', '$filter', '$timeout', 'SalesRequest'];

    function PaymentAttendanceDomainController(FormDomain, $scope, $state, Attendance, $rootScope, FormConstants, $translate, UcAlertService, FrontEndSelectDataSource, $filter, $timeout, SalesRequest)
    {
        var vm = $scope.vm;
        
        var entity = vm.domain;
        var formHandler = vm.formHandler;
        var paymentOptionList = $scope.vm.paymentOptionList;
        var pageConfig = vm.pageConfig;
        var tabHandler = vm.tabHandler;
        var permissions = vm.permissions;
        
        var procedureStandardValueList = [];
        
        var hasAnesthetist = (entity.procedure && entity.procedure.hasAnesthetist);

        /**
         * Default é false. Só seta para true se for buscar o SalesReques no backend
         */
        vm.loadingPaymentAttendanceData = false;
        
        /**
         * Só deve exibir após o cálculo 
         */
        vm.showCalculateLabel = false;
        
        /**
         * Valor total calculado 
         */
        vm.totalAmountCalculated = 0;
        
        /**
         * Flag quando há erro no cálculo 
         */
        vm.totalAmountCalculatedError = true;
        
        /*
         * Para os campos do formulário principal que podem ser editados após a criação.
         * Atendimentos gratuitos também NÃO são editados
         */
        var fieldReadOnly = (!permissions.hasPaymentEditPermission || (entity.salesRequest && entity.salesRequest.free));
        
        /*
         * Para os campos do Adiantamento, além dos campos estarem habilitados, o procedimento deve permitir o lançamento 
         */
        var advanceAllowed = (entity.salesRequest && entity.salesRequest.procedure && entity.salesRequest.procedure.hasPaymentAdvance);
        var advanceFieldReadOnly = (fieldReadOnly || !advanceAllowed);

        /**
         * Ajusta as flags de pagamento
         */
        /*public*/ vm.paymentInstallmentsIconMap = {};
        
        if(entity.salesRequest && entity.salesRequest.salesRequestPaymentSet)
        {
        	var paymentSet = entity.salesRequest.salesRequestPaymentSet;
        	var paymentTemp;
        	for(var i = 0 ; i < paymentSet.length ; i++)
        	{
        		paymentTemp = paymentSet[i];

    			// A flag só se aplica se o valor estiver preenchido
    			if(paymentTemp.value)
    			{
    				var suffix;
    				if(paymentTemp.paymentType == "PAYMENT_ADVANCE")
    				{
    					suffix = "Advance";
    				}
    				else if(paymentTemp.paymentType == "PAYMENT_ANESTHETIST")
    				{
    					suffix = "Anesthetist";
    				}
    				else
    				{
    					suffix = paymentTemp.installment;
    				}
    				vm.paymentInstallmentsIconMap["icon" + suffix] = (paymentTemp.paymentDate) ? "OK" : "PENDING";
    			}
        	}
        }
        
       	$timeout(function()
       	{
       		initTab();
       	}, 1);
        
        formHandler.reopenConfig()
    	.add([new FormField({
    			formTarget: "payment",
	        	cellCssClass: "col-sm-3",
				type: "Select",
				id: "procedureStandardValue",
				required: true,
				readOnly: fieldReadOnly,
				label: "client.page.attendance.tabs.payment.procedureStandardValue",
				dataSource: FrontEndSelectDataSource.create(procedureStandardValueList),
				onChange: function(p_objModel, p_fieldModel, p_value)
				{
					procedureStandardValueChange(p_value);
				}
			}),
			new FormField({
				formTarget: "payment",
				cellCssClass: "col-sm-3",
				type: "Money",
				id: "standardValue",
				label: "client.page.attendance.tabs.payment.standardValue",
				readOnly: true
			}),
			
			new FormField({
    			formTarget: "payment",
    			cellCssClass: "col-sm-3",
    			type: "Percentage",
    			id: "discount",
    			readOnly: true,
    			label: "client.page.attendance.tabs.payment.discount",
			}),
			new FormField({
    			formTarget: "payment",
    			cellCssClass: "col-sm-3",
    			type: "Money",
    			id: "finalValue",
    			label: "client.page.attendance.tabs.payment.finalValue",
    			readOnly: fieldReadOnly,
				onChange: function(p_objModel, p_fieldModel, p_value)
				{
					refreshPaymentData();
				}
			})
    	])
		.endConfig();
        
        // @Override
        /*public*/ vm.syncProcedure = function(p_procedureId, p_resetValue)
        {
        	if(p_resetValue)
        	{
        		entity.procedureStandardValue = null;
        	}
        	
        	// Limpa a lista
        	procedureStandardValueList.splice(0, procedureStandardValueList.length);
        	
        	if(p_procedureId)
        	{
	        	Attendance.getProcedureStandardValueList({procedureId: (p_procedureId) ? p_procedureId : null}, function(p_procedureStandardValueList)
	        	{
	        		if(p_procedureStandardValueList.length > 0)
	        		{
		        		for (var i = 0; i < p_procedureStandardValueList.length; i++)
		        		{
		        			procedureStandardValueList.push(p_procedureStandardValueList[i]);
						}
	        		}
	        		else
	        		{
	        			procedureStandardValueList.push({id: null, defaultDescription: $translate.instant("client.page.attendance.tabs.payment.noOptionsConfiguredForThisProcedure")});
	        		}
	        	});
        	}
        	else
        	{
        		procedureStandardValueList.push({id: null, defaultDescription: $translate.instant("client.page.attendance.tabs.payment.noProcedureSelected")});
        	}
        };
        
        // @Override
        /*public*/ vm.savePayment = function(p_json)
        {
        	if(validateForm())
        	{
	    		// Adiciona as informações específicas da página
        		p_json.installmentsNumber = entity.installmentsNumber;
        		
	        	p_json.paymentAdvance = entity.paymentAdvance;
	        	p_json.paymentAdvancePaymentOptionId = (entity.paymentOptionAdvance) ? entity.paymentOptionAdvance.id : null;
	        	p_json.paymentAdvanceEffectiveDate = readPaymentDate("effectiveDateAdvance");
	        	p_json.paymentAdvanceDate = readPaymentDate("dateAdvance");
	        	p_json.paymentAdvanceObs = entity.obsAdvance;
	        	
	        	p_json.paymentAnesthetist = entity.paymentAnesthetist;
	        	p_json.paymentAnesthetistPaymentOptionId = (entity.paymentOptionAnesthetist) ? entity.paymentOptionAnesthetist.id : null;
	        	p_json.paymentAnesthetistEffectiveDate = readPaymentDate("effectiveDateAnesthetist");
	        	p_json.paymentAnesthetistDate = readPaymentDate("dateAnesthetist");
	        	p_json.paymentAnesthetistObs = entity.obsAnesthetist;
	        	
	        	p_json.installmentList = getInstallmentList();
	        	p_json.paymentOptionIdList = getPaymentOptionIdList();
	        	p_json.paymentDateList = getPaymentDateList("date");
	        	p_json.paymentEffectiveDateList = getPaymentDateList("effectiveDate");
	    		p_json.paymentDetaisObsList = getPaymentDetaisObsList();
	    		
	    		return true;
        	}
        	else
        	{
        		return false;
        	}
        };

        /*public*/ vm.showCalculateInstallmentButton = function()
        {
        	return (pageConfig.pageMode == "EDIT" && !fieldReadOnly);
        };
        
        /**
         * Adiantamento, Anestesista e installments (quando se aplica)
         */
        /*public*/ vm.getAllPaymentRowsList = function()
        {
        	var list = [];
        	if(gridCreated)
        	{
	        	list.push(vm.paymentAdvanceRow);
	        	
	        	if(hasAnesthetist)
	        	{
	        		list.push(vm.paymentAnesthetistRow);
	        	}
	
	        	list = list.concat(vm.paymentDetailsRows);
        	}
        	return list;
        }

	    /**
	     * Checa se o formulário pode ser salvo. Se não poder, retorna uma mensagem de erro
	     */
	    /*private*/ function validateForm()
	    {
	    	var errorMessage = "";
	    	var row;
	    	// Apenas quando é edição e há permissão
	    	if(pageConfig.pageMode == "EDIT" && !fieldReadOnly)
	    	{
//				// Adiantamento preenchido e o tipo de pagamento
//				if(pageConfig && entity.paymentAdvance && !entity.paymentOptionAdvance)
//				{
//					errorMessage += vm.paymentAdvanceRow.installment;
//				}
//	        	if(hasAnesthetist)
//	    		{
//					// Adiantamento preenchido e o tipo de pagamento
//					if(pageConfig && entity.paymentAnesthetist && !entity.paymentOptionAnesthetist)
//					{
//						if(errorMessage != "")
//						{
//							errorMessage += ", ";
//						}
//						errorMessage += vm.paymentAnesthetistRow.installment;
//					}
//	    		}
//
//		    	// Verifica se a forma de pagamento para todos os valores preenchidos está preenchido
//		    	for(var i = 0; i < vm.paymentDetailsRows.length; i++)
//		    	{
//					row = vm.paymentDetailsRows[i];
//
//					// Parcela com valor precisa de forma de pagamento
//					if(entity["installment" + (i + 1)] && !entity["paymentOption" + (i + 1)])
//					{
//						if(errorMessage != "")
//						{
//							errorMessage += ", ";
//						}
//						errorMessage += $translate.instant("client.global.label.installment") + " " +  row.installment;
//					}
//				}
//		    	// Se tem erro, mosta a mensagem e alterna para a aba
//		    	if(errorMessage != "")
//		    	{
//		            UcAlertService.message($translate.instant('client.page.attendance.tabs.payment.paymentOptionsNotSetForFollowingSetFieldsP0', {"itemList": errorMessage}), "WARNING", "BOX");
//	
//		            // Alterna para a aba, caso não esteja nela
//		            tabHandler.select("payment");
//		    		
//		    		return false;
//		    	}
		    	
		    	// O total deve ser igual ao valor cobrado
		    	if(vm.totalAmountCalculatedError)
		    	{
		    		var msgParams = {totalAmountCalculated: $rootScope.formatMoney(vm.totalAmountCalculated), finalValue: $rootScope.formatMoney(vm.domain.finalValue)};
		            UcAlertService.message($translate.instant("client.page.attendance.tabs.payment.totalAmountCalculatedP0MustBeEqualToFinalValueP1", msgParams), "WARNING", "BOX");
		        	
		            // Alterna para a aba, caso não esteja nela
		            tabHandler.select("payment");
		    		
		    		return false;
		    	}
	    	}
    		return true;
	    };

	    /*private*/ function procedureStandardValueChange(p_procedureStandardValue)
        {
        	entity.standardValue = (p_procedureStandardValue) ? p_procedureStandardValue.value : null;
        	entity.installmentsNumber = (p_procedureStandardValue) ? p_procedureStandardValue.installment : null;

        	refreshPaymentData();
        	refreshPaymentDetailsRows();
        };

        var gridCreated = false;
        vm.paymentDetailsRows = [];
        vm.paymentAdvanceRow = null;
        vm.paymentAnesthetistRow = null;
        
        /**
         * Atualiza a tabela de acordo com a quandidade de parcelas 
         */
        /*private*/ function refreshPaymentDetailsRows()
        {
        	var numInstallment = (entity.procedureStandardValue && entity.procedureStandardValue.installment) ? entity.procedureStandardValue.installment : 0;

        	vm.paymentDetailsRows = [];
        	
        	// A primeira linha é do Adiantamento
        	vm.paymentAdvanceRow = createSinglePaymentRow("Advance", "client.page.attendance.tabs.payment.paymentAdvance", advanceFieldReadOnly);
        	
        	// Possui adiantamento?
        	if(hasAnesthetist)
    		{
        		vm.paymentAnesthetistRow = createSinglePaymentRow("Anesthetist", "client.page.attendance.tabs.payment.paymentAnesthetist", fieldReadOnly);
    		}
        	
        	var row;
        	for (var i = 0; i < numInstallment; i++)
        	{
        		row = createPaymentDetailsRows(i + 1);
        		vm.paymentDetailsRows.push(row);
			}
        	
        	gridCreated = true;
        	
        	// Atualiza os totalizadores
        	checkAmountConsistency();
        };
        
        /**
         * Recalcula o valor das parcelas descontando o valor do adiantamento
         */
        /*public*/ vm.calculateInstallment = function()
        {
        	var numInstallment = (entity.procedureStandardValue && entity.procedureStandardValue.installment) ? entity.procedureStandardValue.installment : 0;
        	var paymentAdvance = (entity.paymentAdvance) ? (entity.paymentAdvance) : 0;
        	var paymentAnesthetist = (entity.paymentAnesthetist) ? (entity.paymentAnesthetist) : 0;

        	var tempVal = (entity.finalValue) ? ((entity.finalValue - paymentAdvance - paymentAnesthetist) / numInstallment) : 0;
        	// Fixa em duas casas decimais
        	entity.installmentValue = parseFloat(parseFloat(tempVal).toFixed(2));
        	
        	// Somente valor positivo
        	if(entity.installmentValue < 0)
        	{
        		entity.installmentValue = 0;
        	}
        	
        	for (var i = 0; i < numInstallment; i++)
        	{
        		entity["installment" + (i + 1)] = entity.installmentValue;
			}
        	
        	checkAmountConsistency();
        };
        
        /**
         * Fórmula:
         * 	 Valor Final = Valor Padrão - (Valor Padrão * Desconto)
         *   Atualiza também os totais
         */
        /*private*/ function refreshPaymentData()
        {
        	var paymentValues = calculatePaymentValues();
        
        	var standardValue = paymentValues.standardValue;
        	var numInstallment = paymentValues.installment;
        	
        	var finalValue = (entity.finalValue) ? entity.finalValue : 0;
        	
        	entity.discount = (standardValue == 0) ? 0 : (100 - ((100 * finalValue) / standardValue));

        	// Base decimal e fica a precisão
        	entity.discount = (entity.discount / 100).toFixed(4);        	
        	
        	// Atualiza os totalizadores
        	checkAmountConsistency();
        };
        
        /**
         * Calcula os valores da requisição.
         * A preferência é sempre p combo, caso exista
         */
        /*private*/ function calculatePaymentValues()
        {
        	var standardValue = 0;
        	var installment = 0;
        	
        	// Se existir procedureStandardValue, usa
        	if(entity.procedureStandardValue && (entity.procedureStandardValue.value || entity.procedureStandardValue.value == 0))
        	{
        		standardValue = entity.procedureStandardValue.value;
        		installment = entity.procedureStandardValue.installment;
        	}
        	else // Erro, loga
        	{
        		console.error("calculatePaymentValues params not defined");
        	}
        	return {
        		standardValue: (standardValue) ? standardValue : 0,
        		installment: (installment) ? installment : 0
        	};
        }
        
        /*
         * Recalcula o total para exibir ao usuário se há erro nos cáculos
         */
        /*private*/ function checkAmountConsistency()
        {
        	vm.showCalculateLabel = true;

        	var total = 0;

        	// Anestesista?
    		if(hasAnesthetist)
    		{
    			temp = entity["paymentAnesthetist"];
    			if(temp && angular.isNumber(temp))
    			{
    				total += temp;
    			}
    		}

    		// Adiantamento
    		if(vm.paymentAdvanceRow)
    		{
    			temp = entity["paymentAdvance"];
    			if(temp && angular.isNumber(temp))
    			{
    				total += temp;
    			}
    		}

        	
        	if(vm.paymentDetailsRows)
        	{
        		var temp;
        		for (var i = 1; i <= vm.paymentDetailsRows.length; i++)
        		{
        			temp = entity["installment" + i];
        			if(temp && angular.isNumber(temp))
        			{
        				total += temp;
        			}
				}
        	}
        	vm.totalAmountCalculated = total;

        	// Verificar se há erros
        	vm.totalAmountCalculatedError = (compareDouble(total, entity.finalValue) != 0);
        }
        
        /*private*/ function initTab()
        {
        	// Se é edição
        	if(entity.salesRequest)
        	{
        		initFromSalesRequest();
        	}
        	// Carrega o objeto SalesRequest à partir da segunda sessão (é necessariamente criado na primeira sessão)
        	else if(entity.sessionIndex && entity.sessionIndex > 1 && entity.salesRequestId)
        	{
        		// Exibe o loading para a aba
        		vm.loadingPaymentAttendanceData = true;
        		
        		// Busca o objeto no backend
        		SalesRequest.getFull({id: entity.salesRequestId}, function(p_salesRequest)
        		{
        			entity.salesRequest = p_salesRequest;
        			initFromSalesRequest();
        			forceReadOnly();
        			
        			// Remove o loading
        			vm.loadingPaymentAttendanceData = false;

        			// Atualiza a aba invoice
        			// vm.refreshInvoiceForm(p_salesRequest.salesRequestInvoiceSet);
        		});
        		
        	}
        	else // Criação, primeira sessão
        	{
        		refreshPaymentDetailsRows();
        	}
        };
        

        /*private*/ function createPaymentDetailsRows(p_index)
        {
        	return {
	        	installment: p_index,
	        	installmentRaw: p_index,
	        	valueFormField: new FormField({
	    			type: "Money",
	    			id: "installment" + p_index,
	    			noLabel: true,
	    			readOnly: fieldReadOnly,
	    			extraCssClass: "width-140",
	    			onChange: function(p_objModel, p_fieldModel, p_currentValue) { checkAmountConsistency(); }
	        	}),
				paymentOption: new FormField({
					type: "Select",
					id: "paymentOption" + p_index,
					noLabel: true,
					dataSource: FrontEndSelectDataSource.create(paymentOptionList),
					style: "width:100%",					
					readOnly: fieldReadOnly,
					onChange: function(p_objModel, p_fieldModel, p_value)
	    			{
						checkEffectiveDateReadOnly(p_value, p_index);
	    			}
				}),
				dateFieldModel: new FormField({
					type: "Date",
					id: "date" + p_index,
					noLabel: true,
					readOnly: fieldReadOnly
				}),
				effectiveDateFieldModel: new FormField({
					type: "Date",
					id: "effectiveDate" + p_index,
					noLabel: true,
					readOnly: true
				}),
				obsFieldModel: new FormField({
					type: "String",
					id: "obs" + p_index,
					noLabel: true,
					maxLength: 500,
					readOnly: fieldReadOnly
				})
        	};
		}
		
		/*private*/ function createSinglePaymentRow(p_id, p_label, p_readOnly)
		{
        	return {
	        	installment: $translate.instant(p_label),
	        	installmentRaw: p_id,
	        	valueFormField: new FormField({
	    			type: "Money",
	    			id: "payment" + p_id,
	    			noLabel: true,
	    			readOnly: p_readOnly,
	    			onChange: function(p_objModel, p_fieldModel, p_value)
	    			{
	    				checkAmountConsistency();
//	    				refreshPaymentDetailsValues();
	    			}
	        	}),
				paymentOption: new FormField({
					type: "Select",
					id: "paymentOption" + p_id,
					noLabel: true,
					dataSource: FrontEndSelectDataSource.create(paymentOptionList),
					style: "width:100%",
					readOnly: p_readOnly,
					onChange: function(p_objModel, p_fieldModel, p_value)
	    			{
						checkEffectiveDateReadOnly(p_value, p_id);
	    			}
				}),
				dateFieldModel: new FormField({
					type: "Date",
					id: "date" + p_id,
					noLabel: true,
					readOnly: p_readOnly
				}),
				effectiveDateFieldModel: new FormField({
					type: "Date",
					id: "effectiveDate" + p_id,
					noLabel: true,
					readOnly: true
				}),
				obsFieldModel: new FormField({
					type: "String",
					id: "obs" + p_id,
					noLabel: true,
					maxLength: 500,
					readOnly: p_readOnly
				})
        	};
		}

        /*private*/ function readPaymentDate(p_fieldKey)
        {
        	var val = entity[p_fieldKey];
        	if(!val && val !== "")
        	{
        		entity[p_fieldKey] = null;
        	}
        	var tempJson = {};
        	FormConstants.ValTransforms.DATE_TIME(tempJson, {id: p_fieldKey}, val);
        	return tempJson[p_fieldKey];
        }

		/*private*/ function getInstallmentList()
        {
        	var list = [];
        	var val;
        	for(var i = 1 ; i <= vm.paymentDetailsRows.length ; i++)
        	{
        		val = entity["installment" + i];
        		if(!val && val !== 0)
        		{
        			val = null;
        		}
        		list.push(val);
        	}
        	return list;
        }

		/*private*/ function getPaymentOptionIdList()
        {
        	var list = [];
        	var val;
        	for(var i = 1 ; i <= vm.paymentDetailsRows.length ; i++)
        	{
        		val = entity["paymentOption" + i];
        		list.push((val) ? val.id : null);
        	}
        	return list;
        }

        /*private*/ function getPaymentDetaisObsList()
        {
        	var list = [];
        	var val;
        	for(var i = 1 ; i <= vm.paymentDetailsRows.length ; i++)
        	{
        		val = entity["obs" + i];
        		if(!val && val !== 0)
        		{
        			val = null;
        		}
        		list.push(val);
        	}
        	return list;
        }
        
        /*private*/ function getPaymentDateList(p_fieldPreffix)
        {
        	var list = [];
        	for(var i = 1 ; i <= vm.paymentDetailsRows.length ; i++)
        	{
        		list.push(readPaymentDate(p_fieldPreffix + i));
        	}
        	return list;
        }
        
        /*private*/ function extractPaymentItemList(p_paymentList)
        {
        	// Remove Adiantamento e Anestesita
        	var filterList = [];
        	if(p_paymentList)
       		{
	        	for (var i = 0; i < p_paymentList.length; i++)
	        	{
	        		if(p_paymentList[i].paymentType == "INSTALLMENT")        	
	        		{
	        			filterList.push(p_paymentList[i]);
	        		}
				}
       		}
        	return filterList;
        }
        
        /*private*/ function extractSinglePaymentItem(p_paymentList, p_paymentType)
        {
        	if(p_paymentList)
       		{
	        	for (var i = 0; i < p_paymentList.length; i++)
	        	{
	        		if(p_paymentList[i].paymentType == p_paymentType)        	
	        		{
	        			return p_paymentList[i];
	        		}
				}
       		}
        	return null;
        }
        /*private*/ function initFromSalesRequest()
        {
    		var paymentList = entity.salesRequest.salesRequestPaymentSet;

    		entity.procedureStandardValue = {
    			installment: entity.salesRequest.installmentsNumber,
    			value: entity.salesRequest.standardValue,
    			defaultDescription: "" + entity.salesRequest.installmentsNumber
    		};
    		
    		entity.discount = entity.salesRequest.discount;
    		entity.finalValue = entity.salesRequest.finalValue;
    		entity.standardValue = entity.salesRequest.standardValue;
    		entity.installmentsNumber = entity.salesRequest.installmentsNumber;

    		// Ordena a lista pela parcela
    		paymentList.sort(function(p_0, p_1)
    		{
    			return p_0.installment - p_1.installment;
    		});
    		
    		// Tem Adiantamento e se aplica (pode estar preenchido e não se aplicar - erro ou alteração no Procedimento)?
    		var paymentAdvanceItem = extractSinglePaymentItem(paymentList, "PAYMENT_ADVANCE");
    		if(!advanceFieldReadOnly && paymentAdvanceItem)
    		{
    			entity.paymentAdvance = paymentAdvanceItem.value;
    			entity.paymentOptionAdvance = paymentAdvanceItem.paymentOption;
    			entity.dateAdvance = paymentAdvanceItem.paymentDate;
    			entity.effectiveDateAdvance = paymentAdvanceItem.effectiveDate;
    			entity.obsAdvance = paymentAdvanceItem.obs;
    		}

    		// Tem Anestesita
    		var paymentAnesthetistItem = null;
    		if(hasAnesthetist)
    		{
    			paymentAnesthetistItem = extractSinglePaymentItem(paymentList, "PAYMENT_ANESTHETIST");
    			
    			if(paymentAnesthetistItem)
    			{
	    			entity.paymentAnesthetist = paymentAnesthetistItem.value;
	    			entity.paymentOptionAnesthetist = paymentAnesthetistItem.paymentOption;
	    			entity.dateAnesthetist = paymentAnesthetistItem.paymentDate;
	    			entity.effectiveDateAnesthetist = paymentAnesthetistItem.effectiveDate;
	    			entity.obsAnesthetist = paymentAnesthetistItem.obs;
    			}
    		}

    		// Preenche as parcelas
    		var paymentItemList = extractPaymentItemList(paymentList);
        	for (var i = 0; i < paymentItemList.length; i++)
        	{
    			entity["installment" + (i + 1)] = paymentItemList[i].value;
    			entity["paymentOption" + (i + 1)] = paymentItemList[i].paymentOption;
    			entity["date" + (i + 1)] = paymentItemList[i].paymentDate;
    			entity["effectiveDate" + (i + 1)] = paymentItemList[i].effectiveDate;
    			entity["obs" + (i + 1)] = paymentItemList[i].obs;
        	}
        	
        	refreshPaymentData();
        	refreshPaymentDetailsRows();

        	// Checa se os campos de data effetiva devem ser readOnly - aguarda uns ciclos
        	$timeout(function()
        	{
        		if(paymentAdvanceItem)
        		{
        			checkEffectiveDateReadOnly(paymentAdvanceItem.paymentOption, "Advance");
        		}
        		if(paymentAnesthetistItem)
        		{
        			checkEffectiveDateReadOnly(paymentAnesthetistItem.paymentOption, "Anesthetist");
        		}
        		for (var i = 0; i < paymentItemList.length; i++)
        		{
        			checkEffectiveDateReadOnly(paymentItemList[i].paymentOption, i + 1)
        		}
        	}, 10);
       	}
        
        /*private*/ function checkEffectiveDateReadOnly(p_value, p_index)
        {
        	var isReadonly = (pageConfig.pageMode != "EDIT") || fieldReadOnly || (p_value == null || !p_value.hasEffectiveDate);
        	$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly("effectiveDate" + p_index), {absolute: true, readOnly: isReadonly});
        }

       	/*private*/ function forceReadOnly()
       	{
			// Campos do formulário
			var rowList = formHandler.getRowList();
			for(var i = 0 ; i < rowList.length ; i++)
			{
				if(rowList[i])
				{
					for(var j = 0 ; j < rowList[i].length ; j++)
					{
						var field = rowList[i][j];
						// Somente os campos dessa aba
						if(field.formTarget == "payment")
						{
							field.readOnly = true;
						}
					}
				}
			}

			
			
			// Grid de parcelas
			setPaymentRowReadOnly(vm.paymentAdvanceRow);
			setPaymentRowReadOnly(vm.paymentAnesthetistRow);

        	for(var i = 0 ; i < vm.paymentDetailsRows.length ; i++)
        	{
        		setPaymentRowReadOnly(vm.paymentDetailsRows[i])
        	}
       	};
       	
       	/*private*/ function setPaymentRowReadOnly(p_row)
       	{
       		if(p_row)
       		{
	    		p_row.obsFieldModel.readOnly = true;
	    		p_row.dateFieldModel.readOnly = true;
	    		p_row.effectiveDateFieldModel.readOnly = true;
	    		p_row.paymentOption.readOnly = true;
	    		p_row.valueFormField.readOnly = true;
       		}
       	}
    };    
})();
