(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ExpenseManagerTabPaymentController', ExpenseManagerTabPaymentController);
    
    ExpenseManagerTabPaymentController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'FormFieldValidate'];
    function ExpenseManagerTabPaymentController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, FormFieldValidate)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        var fieldProvidedValue = new FormField({
			cellCssClass: "col-sm-2",
			formTarget: "payment",
			type: "Money",
			readOnly: true,
			id: "providedValue",
			required: true,
			label: "common.domain.expense.providedValue",
			onChange: function(p_objModel, p_fieldModel, p_currentValue){
				vm.recalcEffectiveValueFields();
			}
		});
        
        var fieldInterest = new FormField({
			cellCssClass: "col-sm-2",
			formTarget: "payment",
			type: "Money",
			readOnly: vm.readOnlyFinish,
			id: "interest",
			label: "common.domain.expense.interest",
			onChange: function(p_objModel, p_fieldModel, p_currentValue){
				vm.recalcEffectiveValueFields();
			}
		});
        
        var fieldPenalty = new FormField({
			cellCssClass: "col-sm-2",
			formTarget: "payment",
			type: "Money",
			readOnly: vm.readOnlyFinish,
			id: "penalty",
			label: "common.domain.expense.penalty",
			onChange: function(p_objModel, p_fieldModel, p_currentValue){
				vm.recalcEffectiveValueFields();
			}
		});
        
        var fieldDiscount = new FormField({
			cellCssClass: "col-sm-2",
			formTarget: "payment",
			type: "Money",
			readOnly: vm.readOnlyFinish,
			id: "discount",
			label: "common.domain.expense.discount",
			onChange: function(p_objModel, p_fieldModel, p_currentValue){
				vm.recalcEffectiveValueFields();
			}
		});
        
        var fieldPaymentOption = new FormField({
			cellCssClass: "col-sm-3",
			formTarget: "payment",
			type: "Select",
			readOnly: vm.readOnlyFinish,
			id: "expensePaymentOption",
			label: "common.domain.expense.paymentOption",
			dataSource: FormConstants.DataSources.BackEnd.ExpensePaymentOption(BackEndSelectDataSource, $translate)
		});
        
        var fieldCompetenceDate = new FormField({
			cellCssClass: "col-sm-3",
			formTarget: "payment",
			type: "Month",
			readOnly: vm.readOnlyFinish,
			id: "competenceDate",
			label: "common.domain.expense.competenceDate"
		});
        
        var fieldNfDoc = new FormField({
			cellCssClass: "col-sm-4",
			formTarget: "payment",
			label: "common.domain.expense.nfDoc",
			id: "nfDoc",
			readOnly: vm.readOnlyFinish,
			type: "String",
			required: false,
			maxLength: 50,
		});
        
        
        
        formHandler.reopenConfig()
        	.add([fieldProvidedValue,
        		fieldInterest,
        		fieldPenalty,
        		fieldDiscount,
				new FormField({
					cellCssClass: "col-sm-2",
					formTarget: "payment",
					type: "Money",
					readOnly: true,
					id: "effectiveValue",
					label: "common.domain.expense.effectiveValue"
					
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					formTarget: "payment",
					readOnly: true,
					type: "Money",
					id: "differenceValue",
					label: "common.domain.expense.differenceValue"
				})]).add([
					fieldPaymentOption,
				new FormField({
					cellCssClass: "col-sm-3",
					formTarget: "payment",
					type: "Date",
					readOnly: vm.readOnlyFinish,
					id: "plannedDatePayment",
					label: "common.domain.expense.plannedDatePayment"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					formTarget: "payment",
					type: "Date",
					readOnly: vm.readOnlyFinish,
					id: "effectiveDatePayment",
					label: "common.domain.expense.effectiveDatePayment",
					onChange: function(p_objModel, p_fieldModel, p_currentValue){
						if(vm.domain.effectiveDatePayment){
							vm.setRequiredToClose(true);
						}
						else{
							vm.setRequiredToClose(false);
						}
						vm.recalcEffectiveValueFields();
					}
				}),
					fieldCompetenceDate
				]).add([
					fieldNfDoc,
				new FormField({
					cellCssClass: "col-sm-8",
					formTarget: "payment",
					label: "common.domain.expense.obs",
					id: "obs",
					readOnly: vm.readOnlyFinish,
					type: "String",
					required: false,
					maxLength: 300,
				})
			])
			.endConfig();
        
        
        /* public */ vm.setRequiredToClose = function (isToClose){
        	if(isToClose){
        		fieldNfDoc.required = true;
        		fieldInterest.required = true;
        		fieldPenalty.required = true;
        		fieldDiscount.required = true;
        		fieldCompetenceDate.required = true;
        		fieldPaymentOption.required = true;
        		fieldNfDoc.required = true;
//        		vm.fieldResponsibleUser.required = true;
        	}else{
//        		vm.fieldResponsibleUser.required = false;
        		fieldNfDoc.required = false;
        		fieldInterest.required = false;
        		fieldPaymentOption.required = false;
        		fieldPenalty.required = false;
        		fieldDiscount.required = false;
        		fieldCompetenceDate.required = false;
        		fieldNfDoc.required = false;
        	}
        }

    };
    
    
    
    
})();