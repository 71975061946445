/**
 * Funcionalidade comuns a página de edição de Expensee e do Modal
 * 
 */
(function() {
    'use strict';

	angular
		.module('sgmApp')
		.service('ExpenseCommon', ExpenseCommon);
	
	ExpenseCommon.$inject = ['$translate', '$injector'];
	
	function ExpenseCommon($translate, $injector)
	{
		var vm = this;
		
		/**
		 * Configuração para as abas da página
		 */
		/*public*/ vm.tabList = [
    		{code: "attachment", icon: "fa fa-file-text-o", label: "client.page.expense.tab.attachment.title"},
    		{code: "payment", icon: "fa fa-usd", label: "client.page.expense.tab.payment.title"},
    	];
		
		
		/**
		 * Cria o ícone para abrir o modal dentro de um grid
		 * 
		 * {
		 * 	icon:
		 * 	hint:
		 * 	account:
		 * 	callback:
		 * }
		 * 
		 */
		/*public*/ vm.createOpenModalGridIcon = function(p_)
		{
        	return {
        		headerName: "", 
        		field: "id", 
        		width: 50, 
                suppressSizeToFit: true,
                suppressMenu: true,
                suppressSorting: true,
                cellStyle: {alignItems: "center", textAlign: "center", padding: "0px"},
        		cellRenderer: function (p_params)
                {
        			var hint = $translate.instant(p_.hint);
        			return '<a class="btn btn-primary" style="border:solid 0px transparent !important;background-color:transparent !important;font-size:18px;" title="' + hint + '"><i class="' + p_.icon + '" aria-hidden="true"></i></a>';
                }, 
                onCellClicked: function(p_params)
                {
                	$injector.get("ExpenseManagerModalInvoker").invoke(new ExpenseManagerModalInvokerParam({
                		expenseId: p_params.data[p_.field],
                		account: p_.account,
                		pageMode: "READONLY",
                		callback: p_.callback
                	}));
                	
                	try {p_params.event.preventDefault(); } catch(ee) { /* silent */ }
                }
        	}
		};
		
	};
	
})();

function ExpenseCommonStatic()
{
	function f_()
	{
		var baseResolve = {
			listExpenseStatusType: ['Expense', function(Expense)
			{
				return Expense.listExpenseStatusType();
			}],
			listExpenseTimeStatusType: ['Expense', function(Expense)
			{
				return Expense.listExpenseTimeStatusType();
			}],
			listInstallmentsquantity: ['Expense', function(Expense)
			{
				return Expense.listInstallmentsquantity();
			}],
			listSupplier : ['Supplier', function(Supplier)
        	{
	    		return Supplier.listAllActive();
            }],
			
		};
		
		/*public*/ this.extendResolve = function(p_param)
		{
			var resolve = {};
			
			// Carrega os valores base
			angular.extend(resolve, baseResolve);
			
			// Sobreescreve/Adiciona só os passados como parâmetro
			angular.extend(resolve, (p_param) ? p_param : {});
			return resolve;
		}
	};
	return new f_();
}
