(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProductDomainController', ProductDomainController);
    
    ProductDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'Product', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$translate', 'categoriesList', 'ProductCommon', 'TabStatePersister', 'ManufacturerProductStockFlowDialogInvoker'];

    function ProductDomainController(FormDomain, $scope, $state, entity, Product, pageConfig, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $translate, categoriesList, ProductCommon, TabStatePersister, ManufacturerProductStockFlowDialogInvoker)
    {
        var vm = this;

        vm.domain = entity;
		if(vm.domain){
			vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
		}
        vm.pageConfig = pageConfig;
		var isSaving = false;
		vm.tabList = ProductCommon.tabList;
		vm.manufacturerProductsList = vm.domain != null && vm.domain.manufacturerProductSet ?  vm.domain.manufacturerProductSet : [];
		
		vm.tabHandler = {
				disabledMap: {},
				active: TabStatePersister.init('ProductDomainController', 'manufacturerProduct'),
				isActive: function (p_code) {
					return (p_code === vm.tabHandler.active);
				},
				isDisabled: function (p_code) {
					if ((p_code === "manufacturerProduct") && !vm.domain.id) {
						return true;
					}
					return false;
				},
				isShow: function (p_code) {
					return true;
				},
				select: function (p_id) {
					if (vm.tabHandler.isDisabled(p_id)) {
						return;
					}

					vm.tabHandler.active = TabStatePersister.set("ProductDomainController", p_id);
					vm.tabHandler.active = p_id;
				}
			};

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "PRODUCT",
        	stateRoot: "product",
			previousState: { name: "product" },
			onClear: function()
        	{
        		vm.formHandler.clear();
        	},
        };
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: Product,
			afterCreateState: "product.detail",
        	historyConfig: {historyId: "PRODUCT"},
        	getForm: function(){ return $scope.editForm; },
			afterUpdateState: "product.detail"
        };
		var categoriesFormField = new FormField({
			cellCssClass: "col-sm-3",
			type: "Select",
			id: "category",
			required: true,
			label: "common.domain.product.category",
			dependencyName: 'categoryId',
			dataSource: FrontEndSelectDataSource.create(categoriesList),
		});
        vm.formHandler = FormDomain.create(formOptions)
		.add([new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.product.id",
					readOnly: true
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "userName",
					multiple: false,
					readOnly: true,
					label: "common.domain.product.user"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "createdDate",
					label: "common.domain.product.createdDate"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "description",
					label: "common.domain.product.description",
					required: true,
					maxLength: 255
				}),
				categoriesFormField,
	        	new FormField({
		       		cellCssClass: "col-sm-3",
		       		type: "Select",
					id: "subcategory",
					required: true,
					label: "common.domain.product.subcategory",
					searchActiveOnly: true,
					dataSource: FormConstants.DataSources.BackEnd.SupplierSubcategory(BackEndSelectDataSource, $translate, categoriesFormField, true)
	        	}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "measureUnit",
					required: true,
					label: "common.domain.product.measureUnit",
					searchActiveOnly: true,
					dataSource: FormConstants.DataSources.BackEnd.MeasureUnit(BackEndSelectDataSource, $translate)
				}),
	    	])    
			.add([new FormField({
				cellCssClass: "col-sm-3",
				type: "Boolean",
				id: "active",
				label: "common.domain.product.active",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Boolean",
					id: "concierge34498",
					label: "common.domain.product.concierge34498",
				})
			])
			.endConfig();
			
			
//        /*private*/ var editor = null;
//        $timeout(function()
//        {
//        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
//        }, 100);


        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("product");
        }

		vm.addManufacturerProductStockFlow = function(manufacturerProduct, isInput) {
			var entity = {
				manufacturerProduct: manufacturerProduct
			};
			entity.manufacturerProduct.product =  vm.formHandler.readForm();
			ManufacturerProductStockFlowDialogInvoker.invoke({
				isInput: isInput,
				entity: entity,
			})
		}
    }
})();
