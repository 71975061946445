(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProductKitDomainController', ProductKitDomainController);
    
    ProductKitDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'ProductKit', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$translate', 'ProductKitCommon', 'TabStatePersister'];

    function ProductKitDomainController(FormDomain, $scope, $state, entity, ProductKit, pageConfig, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $translate, ProductKitCommon, TabStatePersister)
    {
        var vm = this;

        vm.domain = entity;
		if(vm.domain){
			vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
		}
        vm.pageConfig = pageConfig;
		var isSaving = false;
		vm.tabList = ProductKitCommon.tabList;
		vm.productKitItems = vm.domain != null && vm.domain.productKitItems ?  vm.domain.productKitItems : [];
		
		vm.tabHandler = {
				disabledMap: {},
				active: TabStatePersister.init('ProductKitDomainController', 'productKitItem'),
				isActive: function (p_code) {
					return (p_code === vm.tabHandler.active);
				},
				isDisabled: function (p_code) {
					if ((p_code === "productKitItem") && !vm.domain.id) {
						return true;
					}
					return false;
				},
				isShow: function (p_code) {
					return true;
				},
				select: function (p_id) {
					if (vm.tabHandler.isDisabled(p_id)) {
						return;
					}

					vm.tabHandler.active = TabStatePersister.set("ProductKitDomainController", p_id);
					vm.tabHandler.active = p_id;
				}
			};

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "PRODUCT_KIT",
        	stateRoot: "productKit",
			previousState: { name: "productKit" },
			onClear: function()
        	{
        		vm.formHandler.clear();
        	},
        };
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: ProductKit,
			afterCreateState: "productKit.detail",
        	historyConfig: {historyId: "PRODUCT_KIT"},
        	getForm: function(){ return $scope.editForm; },
			afterUpdateState: "productKit.detail"
        };
        vm.formHandler = FormDomain.create(formOptions)
		.add([new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.productKit.id",
					readOnly: true
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "userName",
					readOnly: true,
					label: "common.domain.productKit.user"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "createdDate",
					label: "common.domain.productKit.createdDate"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-9",
					type: "String",
					id: "description",
					label: "common.domain.productKit.description",
					required: true,
					maxLength: 255
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Boolean",
					id: "active",
					label: "common.domain.productKit.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
	    	])    
			.endConfig();
			
			
//        /*private*/ var editor = null;
//        $timeout(function()
//        {
//        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
//        }, 100);


        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("productKit");
        }

    }
})();
