(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('PhotoUploadDirectiveController', PhotoUploadDirectiveController);
    
    PhotoUploadDirectiveController.$inject = ['$scope', 'PhotoUploadModalInvoker', 'UcService', 'PhotoManager', '$rootScope', '$translate'];
    function PhotoUploadDirectiveController($scope, PhotoUploadModalInvoker, UcService, PhotoManager, $rootScope, $translate)
    {
    	var vm = this;

    	vm.pageMode = $scope.pageMode;
    	vm.title = $scope.photoTitle;
    	
    	vm.domain = $scope.domain;
    	
    	var photoId = $scope.photoIdentifier + "-" + vm.domain.id;
    	
        /*public*/ vm.openUploadPhotoModal = function()
        {
        	PhotoUploadModalInvoker.invoke(new PhotoUploadModalInvokerParam({
        		photoId: photoId,
        		okCallback: function()
        		{
        			PhotoManager.reset();
        			refreshPhoto();
        		}
        	}));
        };

        /*public*/ vm.removePhoto = function()
        {
			$rootScope.confirm(
			{
				message: $translate.instant("client.component.uploadPhoto.confirmRemoveUserPhoto"),
				callback: function()
				{
					UcService.removePhoto(
					{
						photoId: photoId
					},
					function()
					{
						PhotoManager.reset();
						refreshPhoto();
					});
				} 
			});
        };
        
        
        /*private*/ function refreshPhoto()
        {
			$("#photoFrame").html("");
			$("#photoFrame").addClass("loading");
			
			UcService.loadPhoto({photoId: photoId, photoType: "RAW"}, function(p_imageDto)
        	{
    			var img = null;
    			
				// Não tem imagem
        		if (p_imageDto.image == null)
        		{
        			img = "https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/profile-" + (p_imageDto.gender.id == "M" ? "male" : "female") + ".png";
        		}
        		else
        		{
					img = p_imageDto.image.imageLink;
        			// img = "data:" + p_imageDto.image.contentType + ";base64," + p_imageDto.image.bytes;
        		}
        		img = "<img src='" + img + "' class='img-thumbnail' />";
        		$("#photoFrame").html(img);
        		$("#photoFrame").removeClass("loading");
        	});
        };
        
        var refreshListener = $scope.$on('sgmApp.photoUpload.refresh', function(p_event)
        {
        	refreshPhoto();
        });
        
        $scope.$on('$destroy', function()
        {
        	if(angular.isDefined(refreshListener) && refreshListener !== null)
        	{
        		refreshListener();
        	}
        });
    };
})();