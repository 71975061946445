(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('expense', {
            parent: 'entity',
            url: '/expense?page&sort',
            data: {
                authorities: [PermissionSet.EXPENSE.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'ExpenseController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	listExpenseStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseStatusType();
                }],
                listExpenseTimeStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseTimeStatusType();
                }],
                listInstallmentsquantity: ['Expense', function(Expense)
                {
                    return Expense.listInstallmentsquantity();
                }],
                listSupplier : ['Supplier', function(Supplier)
            	{
            		return Supplier.listAllActive();
                }],
                gridDefaultSort: function()
                {
                	return {
	            		"name": "name", 
	            		"type": "asc"
                	};
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.expense.title',
	                	icon: $rootScope.menuIcons.main.expense,
	                	formValidateTarget: 'Expense',
	                	permission: PermissionSet.EXPENSE
                	})
                }]
            }
        })
        .state('expense.base-domain', {
            parent: 'expense',
            abstract: true,
            url: '/domain/:activeTab',
            resolve: ExpenseCommonStatic().extendResolve()
        })
        .state('expense.detail', {
            parent: 'expense.base-domain',
            url: '/{id}/detail',
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/expense/expense-domain.html',
                    controller: 'ExpenseDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['$stateParams', 'Expense', function($stateParams, Expense)
                {
                    return Expense.get({id : $stateParams.id}).$promise;
                }],
                listExpenseStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseStatusType();
                }],
                listExpenseTimeStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseTimeStatusType();
                }],
                listInstallmentsquantity: ['Expense', function(Expense)
                {
                    return Expense.listInstallmentsquantity();
                }],
                listSupplier : ['Supplier', function(Supplier)
            	{
            		return Supplier.listAllActive();
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]
            }
        })
        .state('expense.edit', {
            parent: 'expense.base-domain',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/expense/expense-domain.html',
                    controller: 'ExpenseDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['Expense', '$stateParams', function(Expense, $stateParams)
                {
                    return Expense.get({id : $stateParams.id}).$promise;
                }],
                listExpenseStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseStatusType();
                }],
                listExpenseTimeStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseTimeStatusType();
                }],
                listInstallmentsquantity: ['Expense', function(Expense)
                {
                    return Expense.listInstallmentsquantity();
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('expense.new', {
            parent: 'expense.base-domain',
            url: '/new',                    
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/expense/expense-domain.html',
                    controller: 'ExpenseDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {}
                },
                listExpenseStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseStatusType();
                }],
                listExpenseTimeStatusType: ['Expense', function(Expense)
                {
                    return Expense.listExpenseTimeStatusType();
                }],
                listInstallmentsquantity: ['Expense', function(Expense)
                {
                    return Expense.listInstallmentsquantity();
                }],
                listInstallmentsquantity: ['Expense', function(Expense)
                {
                    return Expense.listInstallmentsquantity();
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }
})();
