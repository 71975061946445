(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierSubcategoryDomainController', SupplierSubcategoryDomainController);
    
    SupplierSubcategoryDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'SupplierSubcategory', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout', 'BackEndSelectDataSource', '$translate'];

    function SupplierSubcategoryDomainController(FormDomain, $scope, $state, entity, SupplierSubcategory, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout, BackEndSelectDataSource, $translate)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "SUPPLIER_SUBCATEGORY",
        	stateRoot: "supplierSubcategory",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: SupplierSubcategory,
        	historyConfig: {historyId: "SUPPLIER_SUBCATEGORY"},
        	getForm: function(){ return $scope.editForm; },
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
	           		cellCssClass: "col-sm-4",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
	    	])        
        	.add([new FormField({
           		cellCssClass: "col-sm-2",
				type: "String",
				id: "code",
				label: "common.domain.supplierSubcategory.code",
				required: true,
				maxLength: 30
			}),
        		new FormField({
				cellCssClass: "col-sm-6",
				type: "Select",
				id: "category",
				required: true,
				label: "common.domain.supplierSubcategory.category",
				dataSource: FormConstants.DataSources.BackEnd.SupplierCategory(BackEndSelectDataSource, $translate)
			})
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "String",
					id: "name",
					label: "common.domain.supplierSubcategory.name",
					required: true,
					maxLength: 255
				})
        	])	
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "common.domain.supplierSubcategory.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();
			
			

        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("supplierSubcategory");
        }
    }
})();
