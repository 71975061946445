(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('HintAttendanceConfirmationDirectiveController', HintAttendanceConfirmationDirectiveController);

    HintAttendanceConfirmationDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$translate', '$rootScope', 'UcAlertService', '$timeout', 'Attendance', '$uibModal', 'Client', 'AttendanceWhatsappModalInvoker'];
    function HintAttendanceConfirmationDirectiveController($scope, $state, $stateParams, $translate, $rootScope, UcAlertService, $timeout, Attendance, $uibModal, Client, AttendanceWhatsappModalInvoker)
    {
    	var BOX_WIDTH = 360;
    	var BOX_HEIGHT = 275;
    	
    	var vm = this;

    	var entity = {};
		vm.clientName = null;
    	vm.clientAttr = [];
    	
    	var whatsappNumber = "";
    	
    	vm.attendanceDynamicButtonCode = null;
    	vm.justification = null;
    	
        /*public*/ vm.confirmationAttendence = function(p_code)
        {
        	// Se CNP, a justificativa é obrigatório
        	if(p_code == "CNP")
        	{
        		if(vm.justification == null)
        		{
        			UcAlertService.error($translate.instant("client.page.attendance.justificationRequiredForP0", {text: $translate.instant("client.page.attendance.attendanceConfirmationNotPossible")}));
        			return;
        		}
        	}
        	else
        	{
        		vm.justification = null;
        	}

			var message = $translate.instant("client.page.attendance.attendanceConfirmationMessage-" + p_code);
			if(p_code == 'CNF' && entity.status.id == 'REA') {
				message += "<br />" + $translate.instant("client.page.attendance.attendanceConfirmationMessage-CNFRescheduleWarn")
			}
			message = message + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");

			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					Attendance.attendanceConfirmation({ 
						id: entity.id, 
						code: p_code,
						noConfirmationJustification: vm.justification
					},
					function(p_confirmationStatus)
					{
						vm.close(true, p_confirmationStatus);
					});
				} 
			});
        };
	
    	
    	/*public*/ vm.close = function(p_refreshEntity, p_confirmationStatus)
    	{
    		if(p_refreshEntity)
    		{
    			entity.confirmationStatus = p_confirmationStatus;
				Attendance.getAttendanceStatus({id: entity.id}, function(attendanceStatus)
				{
					entity.status = attendanceStatus;
					$("#attendance-confirmated-flag-" + entity.id).find("IMG").attr("src", "https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/icons/" + entity.confirmationStatus.icon);
        			$("#attendance-confirmated-flag-" + entity.id).find("IMG").attr("title", entity.confirmationStatus.hint);
					$rootScope.$emit("reload-clicked-attendance-row");
					closeCallback()
				});
			} else {
				closeCallback()
			}
    	}

		function closeCallback() {
			entity = null;
        	vm.clientAttr = [];
        	vm.attendanceDynamicButtonCode = null;
        	vm.justification = null;

    		$("#hint-attendance-confirmation-tooltip-box").css({display: "none"});
		}
    	
    	/*public*/ vm.showConfirmationMessageTemplate = function()
    	{
        	// Seta o currentAttendance com o domain corrente
        	$scope.currentAttendance = entity;
        	
			$uibModal.open({
                templateUrl: 'app/pages/managers/attendance/message/confirmation-message-template-dialog.html',
                controller: 'ConfirmationMessageTemplateDialogController',
                controllerAs: 'vm',
                scope: $scope
            });
    	}
    	
    	/*public*/ vm.clickDynamicButton = function(p_attr)
    	{
    		if(p_attr.code == "W")
    		{
    			invokeWhatsAppWeb(p_attr);
    		}
    	}
    	
    	/*private*/ function open(p_event)
    	{
    		Client.get({id: entity.clientId}, function(p_dto)
    		{
    			whatsappNumber = p_dto.whatsapp;
    			vm.clientName = p_dto.name;
    			vm.clientAttr = [];
    			vm.clientAttr.push({code: "W", iconCss: "fa fa-whatsapp", value: whatsappNumber, title: $translate.instant("client.global.messages.info.clickToOpenWhatsappWeb"),  divClassCss: "whatsapp-style"});
    			vm.clientAttr.push({code: "P1", iconCss: "fa fa-phone", value: p_dto.cel, divClassCss: "cursor-default"});
    			vm.clientAttr.push({code: "P2", iconCss: "fa fa-phone", value: p_dto.cel2, divClassCss: "cursor-default"});
    			vm.clientAttr.push({code: "E", iconCss: "fa fa-envelope-o", value: p_dto.email, divClassCss: "cursor-default"});
    			
    			vm.attendanceDynamicButtonCode = entity.confirmationStatus.id;
    			
    			var top = 0;
    			var left = 0;
    			
    			try { top =  $(p_event.target).offset().top - (BOX_HEIGHT / 2) + ($(p_event.target).height() / 2); } catch(exc) { /*silent*/ }
    			try { left =  $(p_event.target).offset().left - BOX_WIDTH - 4; } catch(exc) { /*silent*/ }

    			$("#hint-attendance-confirmation-tooltip-box").css({top: top + "px", left: left + "px", display: "block"});
    		});
    	}
    	
    	/*private*/ function invokeWhatsAppWeb(p_attr)
    	{
    		var entityComplete = angular.copy(entity);
    		entityComplete.whatsappNumber = whatsappNumber; 
    		
    		AttendanceWhatsappModalInvoker.invoke({
    			entity: entityComplete,
    			callback: function(p_whatsappNumber)
    			{
    				// Atualiza o número do whatsapp
    				whatsappNumber = p_whatsappNumber;
    			}
    		});
    	}

    	$rootScope.$on("hint-attendance-confirmation.close", function(p_)
    	{
    		vm.close(false, null);
    	});
    	
    	$rootScope.$on("hint-attendance-confirmation.invoke", function(p_, p_param)
    	{
    		entity = p_param.entity;
    		open(p_param.event);
    	});
    	
    };
})();
