(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('DeleteDialogController', DeleteDialogController);

    DeleteDialogController.$inject = ['$uibModalInstance', 'obj', 'service'];

    function DeleteDialogController ($uibModalInstance, obj, service)
    {
        var vm = this;
        
        vm.obj = obj;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear()
        {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id)
        {
        	service.delete({id: id},
                function ()
                {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
