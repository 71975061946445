(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsAttachmentModalController', RecordsAttachmentModalController);

    RecordsAttachmentModalController.$inject = ['$scope', '$uibModalInstance', 'entity', 'pageConfig', 'recordType', 'client', 'attendanceId', '$timeout', 'Records', 'UcAlertService', 'Upload', '$translate'];

    function RecordsAttachmentModalController($scope, $uibModalInstance, entity, pageConfig, recordType, client, attendanceId, $timeout, Records, UcAlertService, Upload, $translate)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        vm.recordType = recordType;
        vm.entity = entity;
        
        vm.showLoadingCameraSave = false;
        
        vm.pageTitle = recordType.label + " - " + client.name;
        
        
        /*public*/ vm.tabList = [
    		{code: "file", icon: "fa fa-upload", label: "client.page.records.attachment.file"},
    		{code: "camera", icon: "fa fa-camera", label: "client.page.records.attachment.camera"}
    	];
        
        /*public*/ vm.tabHandler = {
        	active: 'file',
        	disabledMap: {},
        	isActive: function(p_code)
        	{
        		return (p_code === vm.tabHandler.active);
        	},
        	isDisabled: function(p_code) { return false },
        	isShow: function(p_code) { return true },
        	select: function(p_id)
        	{
       			vm.tabHandler.active = p_id;
        	}
        };
        
        
        /*public*/ vm.save = function()
        {
        	UcAlertService.dismissBox();
        	
        	if(!vm.entity.name)
        	{
        		UcAlertService.error($translate.instant("client.global.messages.error.requiredFieldP0", {field: $translate.instant("client.global.label.name")}));
        		return;
        	}
        	
        	if(vm.tabHandler.active == "file")
        	{
	        	// Verifica se tem arquivo selecionado
	        	if(!entity.content)
	        	{
	        		UcAlertService.error($translate.instant('client.page.records.attachment.noSelectedFile'));
	        		return;
	        	}
	
	        	entity.content.upload = Upload.upload({
	        		url: 'api/records/update-attach',
	        		data:
	        		{
	        			id: entity.id,
	        			type: recordType.id,
	        			attendanceId: attendanceId,
	        			clientId: client.id,
	        			name: entity.name,
	        			file: entity.content
	        		}
	        	});
	        	
	        	entity.content.upload.then(function (p_response) // ok
	        	{
	        		$uibModalInstance.close(true);	
	        	});
        	}
        	else if(vm.tabHandler.active == "camera") // Camera
        	{
       			$scope.$broadcast(CameraHandler.Events.TakePicture);
        		
        		// O evento continua no listener do callback
        	}

        }
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };

        /**
         * Callback da camera
         */
    	var listener = $scope.$on(CameraHandler.Events.TakePictureEnd, function(p_event, p_result)
    	{
    		if(p_result.result)
    		{
    			vm.showLoadingCameraSave = true;
        		try
        		{
		        	Records.saveImageAttach({
	        			id: entity.id,
	        			type: recordType.id,
	        			attendanceId: attendanceId,
	        			clientId: client.id,
	        			name: entity.name,
	        			imageSer: $("#cameraImageSer-input").val()
		        	},
		    		function()
		    		{
		        		vm.showLoadingCameraSave = false;
		    			$uibModalInstance.close(true);
		    		});
        		}
        		catch(exc)
        		{
        			vm.showLoadingCameraSave = false;
        			throw exc;
        		}
    		}
    		else // Erro
    		{
    			UcAlertService.error(p_result.exception.message);
    		}
    	});
    	toDestroy($scope, listener);

	}
})();