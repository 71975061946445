(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('TherapyPlaceDomainController', TherapyPlaceDomainController);
    
    TherapyPlaceDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'TherapyPlace', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout'];

    function TherapyPlaceDomainController(FormDomain, $scope, $state, entity, TherapyPlace, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "THERAPY_PLACE",
        	stateRoot: "therapyPlace",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: TherapyPlace,
        	historyConfig: {historyId: "THERAPY_PLACE"},
        	getForm: function(){ return $scope.editForm; },
        	beforeInvokeService: function(p_json)
        	{
        		// Adiciona o campo de Obs Disponibilidae
        		try
        		{
        			p_json.availabilityObs = editor.getData();
        		}
        		catch(exc)
        		{
        			console.error("Error at read availabilityObs: " + exc.message);
        		}
        	}
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
	    	])        
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "code",
					label: "common.domain.therapyPlace.code",
					required: true,
					readOnly: !!(entity && entity.id)
				})
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "String",
					id: "description",
					label: "common.domain.therapyPlace.description",
					required: true
				})
        	])	
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "common.domain.therapyPlace.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();
			
			
        /*private*/ var editor = null;
        $timeout(function()
        {
        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
        }, 100);


        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("therapyPlace");
        }
    }
})();
