(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AgendaReminderDialogController', AgendaReminderDialogController);

    AgendaReminderDialogController.$inject = ['$scope', '$uibModalInstance', 'FormDomain', 'entity', 'schedulableList', 'pageConfig', 'FrontEndSelectDataSource', 'AgendaReminder', 'UcAlertService', 'Agenda', '$translate', '$rootScope', 'repeatOptionsList', '$timeout', 'FormConstants'];

    function AgendaReminderDialogController($scope, $uibModalInstance, FormDomain, entity, schedulableList, pageConfig, FrontEndSelectDataSource, AgendaReminder, UcAlertService, Agenda, $translate, $rootScope, repeatOptionsList, $timeout, FormConstants)
    {
        var vm = this;
        
    	var LIST_REPEAT_VALUE = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30];
    	var listRepeatValue = [];
    	for (var i = 0; i < LIST_REPEAT_VALUE.length; i++)
    	{
    		listRepeatValue.push({id: LIST_REPEAT_VALUE[i], defaultDescription: "" + LIST_REPEAT_VALUE[i]});
		}
        vm.domain = entity;
        vm.pageConfig = pageConfig;
        
        // Se for edição, ajusta os campos
        if(entity.id)
        {
    		entity.date = (entity.referenceDateNoTimezone) ? moment(entity.referenceDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d : null;
    		entity.time = (entity.referenceDateNoTimezone) ? moment(entity.referenceDateNoTimezone, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : null;
    		
    		if(entity.repetitionNumber)
    		{
    			entity.repetitionNumberSelect = {id: entity.repetitionNumber, defaultDescription: "" + entity.repetitionNumber}
    		}
    		entity.schedulable = {};
    		Agenda.getSchedulable({schedulableId: entity.idSchedulable}, function(p_schedulable) {
				entity.schedulable[entity.idSchedulable+''] = p_schedulable;
    		});
			synchItemList();
			console.log(entity);
        }
        else
        {
        	// Default Não
        	entity.repetitionType = repeatOptionsList[0];
        }
        
        vm.buttonsModel = {
        	isSaving: false,
        	onClear: function() { vm.formHandler.clear(); },
        	extraButtonList: [{
	        	order: 90,
	        	cssClass: 'btn btn-success',
	        	cssClassIcon: 'glyphicon glyphicon-save',
	        	label: 'client.global.label.save',
	        	type: 'submit',
	        	isToAdd: function() { return true; }
	        },
	        {
	        	order: 91,
	        	cssClass: 'btn btn-danger',
	        	cssClassIcon: 'fa fa-times',
	        	label: 'client.global.label.delete',
	        	type: 'button',
	        	isToAdd: function() { return (entity.id); },
	        	click: function()
	        	{
	        		deleteReminder();
	        	}
	        },
	        {
	        	order: 92,
	        	cssClass: 'btn btn-default',
	        	cssClassIcon: 'fa fa-ban',
	        	label: 'client.global.label.cancel',
	        	type: 'button',
	        	isToAdd: function() { return true; },
	        	click: function()
	        	{
	        		vm.closeModal();
	        	}
	        }]
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	service: AgendaReminder,
        	buttonsModel: vm.buttonsModel,
        	historyConfig: {historyId: "AGENDA_REMINDER"},
        	getForm: function(){ return $scope.editForm; },
        	beforeInvokeService: function(p_json)
        	{
        		adjustJSonBeforeSend(p_json);
        	},
        	onSaveSuccess: function(p_result, p_headers)
        	{
        		$uibModalInstance.close({
        			success: true
        		});
        	}
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
        	])        
        	.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "Select",
					id: "schedulable",
					required: true,
					multiple: true,
					readOnly: !!(entity.id), // Edição é readonly
					label: "client.page.agendaReminderDialog.agendaTo",
					dataSource: FrontEndSelectDataSource.create(schedulableList),
					jsonValTransform: (p_json, p_formField, p_value) => FormConstants.ValTransforms.DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM(p_json, p_formField, p_value, true)
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "String",
					id: "description",
					required: true,
					label: "client.global.label.description",
					maxLength: 1000
				})
        	])
			.add([new FormField({
					cellCssClass: "col-sm-6",
		       		type: "Date",
					id: "date",
					required: true,
					label: "client.global.label.date",
					onChange: function()
					{
						synchItemList();
					}
				}),
				new FormField({
					cellCssClass: "col-sm-6",
		       		type: "Time",
					id: "time",
					required: true,
					label: "client.global.label.time",
					onChange: function()
					{
						synchItemList();
					}
				})
        	])
			.add([new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "repetitionType",
					required: true,
					label: "client.global.label.repeat",
					dataSource: FrontEndSelectDataSource.create(repeatOptionsList),
					onChange: function()
					{
						synchItemList();
					}
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "repetitionNumberSelect",
					label: "client.page.agendaReminderDialog.timesRepeat",
					dataSource: FrontEndSelectDataSource.create(listRepeatValue),
					onChange: function()
					{
						synchItemList();
					},
					addFieldListener: function()
		        	{
		        		return (entity.repetitionType != null && entity.repetitionType.id != "U");
		        	},
				})
			])
        	.add([new FormHtmlRender({
	    			cellCssClass: "col-sm-12",
	    			label: "client.page.agendaReminderDialog.preview",
	    			hasLabel: true,
					render: function(p_objModel, p_fieldModel, p_pageMode, p_editMode)
					{
						return "<div id='ard-preview'></div>";
					}
				})
        	])
			.endConfig();
        
        /*private*/ function synchItemList()
        {
        	$timeout(function()
        	{
        		renderPreview();
        	}, 100);
        }
        synchItemList();
        
        
        /*private*/ function renderPreview()
        {
        	var html = "";
        	if(entity.date && entity.time && entity.repetitionType)
        	{
        		var repeatType = entity.repetitionType.id;
        		var repetitionNumber = (entity.repetitionNumberSelect && entity.repetitionNumberSelect.id) ? entity.repetitionNumberSelect.id : 0;
        		if(repetitionNumber > 0)
        		{
        			repetitionNumber--;
        		}
        		

        		var date = new Date(entity.date.getTime());
        		html += generatePreviewDiv(date);
        		
        		if(repeatType != "U" && repetitionNumber > 0)
        		{
        			for (var i = 0; i < repetitionNumber; i++)
        			{
        				if(repeatType == "D")
        				{
        					date.setDate(date.getDate() + 1);
        				}
        				else if(repeatType == "W")
        				{
        					date.setDate(date.getDate() + 7);
        				}
        				else if(repeatType == "M")
        				{
        					date.setMonth(date.getMonth() + 1);
        				}
        				html += generatePreviewDiv(new Date(date.getTime()));
					}
        		}
        	}
        	if(!html)
        	{
        		html = "<span style='font-style:italic'>" + $translate.instant('client.page.agendaReminderDialog.setFormAbove') + "</span>";
        	}
			console.log(html);
        	$("#ard-preview").html(html);
        }
        
        /*private*/ function generatePreviewDiv(p_date)
        {
        	var mnt = moment(p_date);
        	var weekIndex = mnt.weekday() + 1;
        	if(weekIndex == 7)
        	{
        		weekIndex = 0;
        	}
        	return "<div class='date-preview'>" + mnt.format('DD/MM/YY') + " (" + $translate.instant('client.global.daysWeek.w' + weekIndex) + ")</div>";
        }
        
        /*private*/ function adjustJSonBeforeSend(p_json)
        {
        	p_json.referenceDate = moment(p_json.date).format('YYYY-MM-DD') + " " + p_json.time + ":00";
			console.log(p_json);
        	if(p_json.repetitionTypeId == "U")
        	{
        		p_json.repetitionNumber = 0;
        	}
        	else
        	{
        		p_json.repetitionNumber = (entity.repetitionNumberSelect && entity.repetitionNumberSelect.id) ? entity.repetitionNumberSelect.id : 0;
        	}
        }

		/*private*/ function customSave(p_jsonList, p_onSaveSuccess, p_onSaveError)
		{
			console.log(p_jsonList);
			AgendaReminder.saveReminderList(p_jsonList, p_onSaveSuccess, p_onSaveError);
		};

        function schedulableEntriesRepeat(entries, index, jsonList, p_json, p_onSaveSuccess, p_onSaveError) {
			if(index < entries.length) {
				let schedulableEntry = entries[index];
				let schedulable = schedulableEntry[1];
				let json = {};
				Object.assign(json, p_json);
				json.idSchedulable = schedulable.id;
				json.schedulableId = schedulable.id;
				json.schedulable = schedulable;
				jsonList.push(json);
				schedulableEntriesRepeat(entries, ++index, jsonList, p_json, p_onSaveSuccess, p_onSaveError);
			} else {
				customSave(jsonList, p_onSaveSuccess, p_onSaveError);
			}
		}

        /*public*/ vm.save = function()
        {
       		var repeatType = (entity.repetitionType) ? entity.repetitionType.id : null;
       		var repetitionNumber = (entity.repetitionNumberSelect && entity.repetitionNumberSelect.id) ? entity.repetitionNumberSelect.id : 0;
       		
       		if(repeatType && repeatType != "U" && repetitionNumber == 0)
       		{
       			UcAlertService.error($translate.instant("client.page.agendaReminderDialog.repetitionNumberRequiredAndDifferentOfZeroForThisRepetitionType"));
       		}
       		else
       		{
				vm.formHandler.save(function(p_json, p_onSaveSuccess, p_onSaveError)
				{
					let schedulableEntries = Object.entries(p_json.schedulable);
					adjustJSonBeforeSend(p_json);
					p_json.id = entity.id;
					let jsonList = [];
					schedulableEntriesRepeat(schedulableEntries, 0, jsonList, p_json, p_onSaveSuccess, p_onSaveError);
				});
       		}
        }
        
        /*private*/ function deleteReminder()
        {
        	var message = $translate.instant("client.global.messages.info.deleteRecordP0", {item: ":p0"}) + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
        	
			// Atribui o valor por replace porque o parametro do $translate está escaping
			message = message.replace(":p0", UcUtil.b(entity.description));
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					AgendaReminder.delete({id: entity.id}, 
						function()
						{
			        		$uibModalInstance.close({
			        			success: true
			        		});
						});
				} 
			});
		}
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss('cancel');
        }
    }
})();