(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ClientAttendanceDomainController', ClientAttendanceDomainController);

	ClientAttendanceDomainController.$inject = ['FormDomain', '$scope', 'Attendance', '$translate', '$timeout', 'ClientManagerModalInvoker', 'Client'];

	function ClientAttendanceDomainController(FormDomain, $scope, Attendance, $translate, $timeout, ClientManagerModalInvoker, Client) {
		var vm = $scope.vm;
		var entity = $scope.vm.domain;

		var clientFormOptions = {
			model: vm.clientData,
			pageConfig: vm.pageConfig,
			buttonsModel: null
		};


		// Esses inputs são SEMPRE readOnly
		vm.clientFormHandler = FormDomain.create(clientFormOptions)
			.add([
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "id",
					label: "common.domain.client.id",
					readOnly: true
				}),
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "cadclinId",
					label: "common.domain.client.cadclinId",
					readOnly: true
				}),
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "genderLabel",
					label: "client.page.attendance.tabs.client.gender",
					readOnly: true
				}),
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-3",
					type: "String",
					id: "birthDateFormatted",
					label: "client.page.attendance.tabs.client.birthDate",
					readOnly: true
				}),
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-3",
					type: "String",
					id: "age",
					label: "client.global.label.age",
					readOnly: true
				}),
			])
			.add([

				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "phone",
					label: "client.page.attendance.tabs.client.phone",
					readOnly: true
				}),
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "cel",
					label: "client.page.attendance.tabs.client.cel",
					readOnly: true
				}),
				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-2",
					type: "String",
					id: "districtAndCity",
					label: "client.page.attendance.tabs.client.districtCity",
					readOnly: true
				}),

				new FormField({
					formTarget: "client",
					cellCssClass: "col-sm-3",
					type: "String",
					id: "email",
					label: "client.page.attendance.tabs.client.email",
					readOnly: true
				}),
				new FormField({ 
					formTarget: "client",	
					cellCssClass: "col-sm-3",
		        	type: "String",
		        	id: "profession",
		        	label: "common.domain.client.profession",
		        	readOnly: true
				})

			])
			.endConfig();


        /*public*/ vm.openClientModal = function () {
			ClientManagerModalInvoker.invoke(new ClientManagerModalInvokerParam({
				clientId: vm.clientData.id,
				account: vm.account,
				pageMode: "READONLY",
				callback: function () {
					vm.syncClient(vm.clientData.id);
				}
			}));
		}
	};




})();