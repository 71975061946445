(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('ProcedureMotivation', ProcedureMotivation);

    ProcedureMotivation.$inject = ['$resource', 'URL', 'SERVICES'];

    function ProcedureMotivation ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/procedure-motivation/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'listAll': { method: 'GET', url: url + 'api/procedure-motivation/list-all', isArray: true }
        });
    }
})();
