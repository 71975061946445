(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProntuaryModelDomainController', ProntuaryModelDomainController);
    
    ProntuaryModelDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'ProntuaryModel', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$translate', 'prontuaryTabList', 'TabStatePersister', 'ProntuaryModelItemDialogInvoker'];

    function ProntuaryModelDomainController(FormDomain, $scope, $state, entity, ProntuaryModel, pageConfig, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $translate, prontuaryTabList, TabStatePersister, ProntuaryModelItemDialogInvoker)
    {
        var vm = this;

        vm.domain = entity;
		if(vm.domain.id){
			vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
		}
		
        vm.pageConfig = pageConfig;
		if(vm.domain.prontuaryItems == null) {
			vm.domain.prontuaryItems = [];
		}

		vm.prontuaryItems = vm.domain.prontuaryItems;
		var isSaving = false;
        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "PRONTUARY_MODEL",
        	stateRoot: "prontuaryModel",
			previousState: { name: "prontuaryModel" },
			onClear: function()
        	{
        		vm.formHandler.clear();
        	},
			canDelete: vm.domain.canDelete,
			extraButtonList: [
				{
					order: 99,
					cssClass: 'btn btn-success',
					cssClassIcon: 'fa fa-plus',
					label: 'client.page.prontuaryModel.addProntuaryModelItem',
					type: 'button',
					isDisabled: function()
					{
						return false;
					},
					isToAdd: function()
					{
						return vm.pageConfig.pageMode != 'READONLY';
					},
					click: function()
					{
						vm.addItem();
					}
				}
			],
        };
        var formOptions = {
        	model: vm.domain,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: ProntuaryModel,
			afterCreateState: "prontuaryModel.detail",
        	historyConfig: {historyId: "PRONTUARY_MODEL"},
        	getForm: function(){ return $scope.editForm; },
			afterUpdateState: "prontuaryModel.detail"
        };
	
        vm.formHandler = FormDomain.create(formOptions)
		.add([new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.prontuaryModel.id",
					readOnly: true
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "userName",
					readOnly: true,
					label: "common.domain.prontuaryModel.user"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "createdDate",
					label: "common.domain.prontuaryModel.createdDate"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "name",
					label: "common.domain.prontuaryModel.name",
					required: true,
					maxLength: 255
				}),
	    	])
			.add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Select",
					id: "prontuaryTab",
					required: true,
					label: "common.domain.prontuaryModel.prontuaryTab",
					dataSource: FrontEndSelectDataSource.create(prontuaryTabList),
				})
			])
			.add([new FormField({
				cellCssClass: "col-sm-3",
				type: "Boolean",
				id: "active",
				label: "common.domain.prontuaryModel.active",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
			])
			.endConfig();
			
			
//        /*private*/ var editor = null;
//        $timeout(function()
//        {
//        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
//        }, 100);
		console.log($scope.editForm);

        /*public*/ vm.save = function()
        {
			this.formHandler.validateAll();
			
			if($scope.editForm.$valid) {
				var p_json = vm.prontuaryFormConfig.parseJson(vm.domain);
				if(!p_json.active) {
					p_json.active = false;
				}
				console.log(p_json);
				if(p_json.id) {
					ProntuaryModel.update(p_json, function(res, putResponseHeaders) {
						$state.go('prontuaryModel.detail', {id: res.id});
						Object.assign(vm.prontuaryFormConfig.prontuary, res);
						vm.domain = vm.prontuaryFormConfig.prontuary;
						vm.prontuaryItems = vm.prontuaryFormConfig.prontuary.prontuaryItems;
						vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
					});
				} else {
					ProntuaryModel.save(p_json, function(res, putResponseHeaders) {
						$state.go('prontuaryModel.detail', {id: res.id});
						Object.assign(vm.prontuaryFormConfig.prontuary, res);
						vm.domain = vm.prontuaryFormConfig.prontuary;
						vm.prontuaryItems = vm.prontuaryFormConfig.prontuary.prontuaryItems;
						vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
					});
				}
			}
				
        };

        
        /*public*/ vm.goBack = function()
        {
        	$state.go("prontuaryModel");
        }

		/*public*/ vm.addItem = function() {
			ProntuaryModelItemDialogInvoker.invoke(
				{
					callback: function(res) {
						if(res) {
							vm.prontuaryItems.push(res);
							vm.sortItems(res);
						}
					},
					entity: {
						order: vm.prontuaryItems.length
					}
				}
			);
			
		}

		/*public*/ vm.onDeleteClick = function(index) {
			vm.prontuaryItems.splice(index, 1);
			for(var i = 0; i < vm.prontuaryItems.length; i++) {
				vm.prontuaryItems[i]['order'] = i;
			}
		}

		function _sortItems() {
			vm.prontuaryItems.sort(function(p_1, p_2)
			{
				return (p_1.order) - p_2.order;
			});
		}
		/*public*/ vm.sortItems = function(obj) {
			_sortItems();
			console.log(obj);
 			var foundDifferent = false;
			var foundEqual = false;
			for(var i = 0; i < vm.prontuaryItems.length; i++) {
				var prontuaryItem = vm.prontuaryItems[i];
				if(prontuaryItem.order == obj.order) {
					if(prontuaryItem != obj) {
						prontuaryItem.order = obj.order + (foundEqual ? -1 : 1);
						foundDifferent = true;
					} else {
						foundEqual = true;
					}				
				} else {
					prontuaryItem.order = i + (foundDifferent ? 1 : 0);
				}
				
			}
			_sortItems();
			for(var i = 0; i < vm.prontuaryItems.length; i++) {
				vm.prontuaryItems[i].order = i;
			}

		}

		vm.prontuaryFormConfig = {
			pageConfig: vm.pageConfig,
			onDeleteClick: vm.onDeleteClick,
			onEditItem: vm.sortItems,
			prontuary: vm.domain
		};
    }
})();
