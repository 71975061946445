(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientManagerTabClassificationController', ClientManagerTabClassificationController);
    
    ClientManagerTabClassificationController.$inject = ['$scope', 'FrontEndSelectDataSource'];
    function ClientManagerTabClassificationController($scope, FrontEndSelectDataSource)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        formHandler.reopenConfig()
	        	
		.endConfig();
    };
})();