(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceConfirmationFreeScheduleDialogController', AttendanceConfirmationFreeScheduleDialogController);

    AttendanceConfirmationFreeScheduleDialogController.$inject = ['$scope', '$filter', '$uibModalInstance', 'FormDomain', 'Agenda', 'dataScheduling', '$translate', '$rootScope', '$timeout', 'FormConstants', 'rescheduleAttendance', 'UcAlertService', 'defaultDurationList'];

    function AttendanceConfirmationFreeScheduleDialogController($scope, $filter, $uibModalInstance, FormDomain, Agenda, dataScheduling, $translate, $rootScope, $timeout, FormConstants, rescheduleAttendance, UcAlertService, defaultDurationList)
    {

        var vm = this;

        vm.startDateFieldModel = new FormField({
			type: "Date",
			id: "startDate",
			noLabel: true,
			required: true,
			readOnly: true,
			style: "border-radius:6px"
		});

        vm.pageConfig = new PageConfig({
    		pageMode: "EDIT"
    	});

        vm.dataScheduling = dataScheduling;
        vm.rowData = dataScheduling.rowData
        vm.filterData = dataScheduling.filterData;
        vm.listDuration = defaultDurationList;
		vm.dataScheduling.startDate = $filter('date')(vm.dataScheduling.startDate, 'dd/MM/yyyy');
		vm.dataScheduling.selectDuration = vm.filterData.defaultDuration;

		// Hora 00:00, deixar vazio
		if(vm.dataScheduling.startTime == "00:00")
		{
			vm.dataScheduling.startTime = "";
		}

		vm.rescheduleAttendance = rescheduleAttendance;

		function parseTime(s) {
			var c = s.split(':');
			return parseInt(c[0]) * 60 + parseInt(c[1]);
		}

        vm.isTimeBlock = false;
//        if (vm.dataScheduling.startTime !== '') {
//            vm.isTimeBlock = true;
//		}

        // Edição (Reagendamento)
		if (vm.dataScheduling.startTime !== '' && vm.dataScheduling.endTime !== '')
		{
			var duration = parseTime(vm.dataScheduling.endTime) - parseTime(vm.dataScheduling.startTime);
			vm.dataScheduling.selectDuration = { id: duration, defaultDescription: duration };
			vm.selectDurationEmpty = false;
        }

		// Foco inicial no input de hora
		$timeout(function()
		{
			$("#startTime").focus();
		}, 100)

		/*public*/ vm.reschedulingAttendanceLabel = function()
		{
			var text = $translate.instant("client.page.attendance.reschedulingAttendance", {client: "$client$", date: "$date$", procedure: "$procedure$"});
			try
			{
				text = text.replace("$client$", "<div class='text-accent display-inline-block'>" + rescheduleAttendance.salesRequest.client.defaultDescription + "</div>")
				text = text.replace("$date$", "<div class='text-accent display-inline-block'>" + rescheduleAttendance.plannedDateFormatted + "</div>")
				text = text.replace("$procedure$", "<div class='text-accent display-inline-block'>" + rescheduleAttendance.salesRequest.procedure.defaultDescription + "</div>")
			}
			catch(exc) { console.error(exc.message); }
			return $rootScope.toTrustedHtml(text);
		}

        /*public*/
        vm.closeModal = function() {
            $uibModalInstance.close();
		};

		var timeStringToMin = function(time)
		{
			if (angular.isUndefined(time) || time === 0)
			{
				return 0;
			}
			else
			{
				var hoursMinutes = time.split(':');
				var hours = parseInt(hoursMinutes[0], 10);
				var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
				return hours * 60 + minutes;
			}
		};

		function validateSchedulesAvailable(day, startTime, endTime)
		{
			var rd = vm.rowData;
			var result = false;
			var startTime = timeStringToMin(startTime);
			var endTime = timeStringToMin(endTime);
			if (startTime >= timeStringToMin(rd.beginInterval) && endTime <= timeStringToMin(rd.endInterval))
			{
				result = true;
			}

			return result;
		};


        /*public*/ vm.save = function()
		{
			if (!vm.formConfirmationSchedule.$valid) {
				return false;
			}

			if (vm.dataScheduling.selectDuration.id === '') {
				vm.selectDurationEmpty = true;
				return false;
			}

			checkConflict();
		}

        /*public*/ vm.checkOutOfTime = function()
        {
			var startTime = calculateStartTime();
			var endTime = calculateEndTime(startTime);

			vm.outTime = false;
			if (startTime && endTime && !validateSchedulesAvailable(moment(vm.dataScheduling.startDate, 'DD/MM/YYYY').day(), vm.dataScheduling.startTime, endTime.split(' ')[1]))
			{
				vm.outTime = true;
			}
        }

        /*private*/ function calculateStartTime()
        {
        	if(!vm.dataScheduling.startDate || !vm.dataScheduling.startTime)
        	{
        		return null;
        	}
        	else
        	{
        		return moment(vm.dataScheduling.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') + ' ' + vm.dataScheduling.startTime + ':00';
        	}
        }

        /*private*/ function calculateEndTime(p_startTime)
        {
        	if(!p_startTime || !vm.dataScheduling.selectDuration || !vm.dataScheduling.selectDuration.id)
        	{
        		return null;
        	}
        	else
        	{
				var endTime = moment(p_startTime).add('m', vm.dataScheduling.selectDuration.id);
				return moment(endTime).format('YYYY-MM-DD HH:mm:ss');
        	}
        }

		function execute(startTime, endTime)
		{
			$uibModalInstance.close({
				attendanceInput:
				{
					schedulableId: vm.rowData.schedulableId,
					familyProcedureId: vm.filterData.familyProcedure ? vm.filterData.familyProcedure.id : null,
					procedureId: vm.filterData.procedure ? vm.filterData.procedure.id : null,
					procedurePlaceId: vm.rowData.procedurePlaceId,
					equipmentId: vm.rowData.equipmentId,
					start: startTime,
					end: endTime,
				}
			});
		}

		function checkConflict()
        {
			var startTime = calculateStartTime();
			var endTime = calculateEndTime(startTime);

			if(!startTime || !endTime)
			{
				UcAlertService.error($translate.instant("client.page.attendance.dateOrDurationNullOrInvalid"));
				return;
			}

			var paramId = vm.dataScheduling.idSchedule === 0 ? null : vm.dataScheduling.idSchedule;

			// Para reagendamento
			if(vm.rescheduleAttendance && vm.rescheduleAttendance.id)
			{
				paramId = vm.rescheduleAttendance.id;
			}

            var param = {
        		id: paramId,
        		schedulingType: "A",
        		procedurePlaceId: vm.rowData.procedurePlaceId,
        		schedulableId: vm.dataScheduling.idResource,
        		startDate: startTime,
        		endDate: endTime,
        		fullDay: false
        	};
        	Agenda.checkScheduling(param,
				function(p_feedback)
				{
					if(p_feedback.ok)
					{
						execute(startTime, endTime);
					}
					else
					{
						var message = $translate.instant("client.page.attendance.currentMarkingIsInConflictWithEventsBelow");
						message += "<ul>";
						for (var i = 0; i < p_feedback.conflictList.length; i++)
						{
							if(p_feedback.conflictList)
							{
								for (var i = 0; i < p_feedback.conflictList.length; i++)
								{
									message += '<li>' + p_feedback.conflictList[i] + '</li>';
								}
							}
						}
						message += "</ul>" + $translate.instant("client.global.messages.info.confirmOperation")

						$rootScope.confirm(
						{
							message: message,
							callback: function()
							{
								execute(startTime, endTime);
							}
						});
            		}
            	});
        }


        vm.clearTime = function() {
            vm.dataScheduling.startTime = '';
        };
    }
})();
