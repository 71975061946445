(function() {
    'use strict';

    angular
        .module('sgmApp')
        .service('MessageListener', MessageListener);

    MessageListener.$inject = ['$rootScope', '$interval', 'MessageListenerService'];

    function MessageListener($rootScope, $interval, MessageListenerService)
    {
		var vm = this;
		
		var start = false;
		
		var loggedUser = null;

		/*public*/ vm.start = function()
		{
//			if(!start)
//			{
//				start = true;
//				$interval(function()
//				{
//					exec();
//				}, 2000)
//			}
		}

		
		/*private*/ function exec()
		{
//			MessageListenerService.get({}, function(p_)
//			{
//				console.log(p_);
//				
//			});
		}
    }
})();