(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('eventFocus', eventFocus);
        
        eventFocus.$inject = ['focus'];

        function eventFocus(focus) {
            return function(scope, elem, attr) {
                elem.on(attr.eventFocus, function() {
                    focus(attr.eventFocusId);
                });

                // Removes bound events in the element itself
                // when the scope is destroyed
                scope.$on('$destroy', function() {
                    elem.off(attr.eventFocus);
                });
            };
        };
})();
