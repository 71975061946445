(function () {
    'use strict';
    angular
        .module('sgmApp')
        .constant('PermissionSet', {

        	ADMIN: uniquePermission("ROLE_ADMINISTRACAO"),

        	THERAPY_PLACE: {
        		access: "ROLE_LOCAL_TERAPIA_ACESSO",
        		create: "ROLE_LOCAL_TERAPIA_CRIACAO",
        		edit: "ROLE_LOCAL_TERAPIA_EDICAO",
        		"delete": "ROLE_LOCAL_TERAPIA_EXCLUSAO"
        	},

        	USER: {
        		access: "ROLE_USUARIO_ACESSO",
        		create: "ROLE_USUARIO_CRIACAO",
        		edit: "ROLE_USUARIO_EDICAO",
        		"delete": "ROLE_USUARIO_EXCLUSAO"
        	},

        	FAMILY_PROCEDURE: uniquePermission("ROLE_CADASTRO_FAMILIA_PROCEDIMENTO"),
        	PAYMENT_INSTALLMENTS: uniquePermission("ROLE_CADASTRO_PARCELAS_PAGAMENTO"),
        	PAYMENT_OPTION: uniquePermission("ROLE_CADASTRO_FORMA_PAGAMENTO"),
        	PROCEDURE: uniquePermission("ROLE_CADASTRO_PROCEDIMENTO"),
        	PROCEDURE_PLACE: uniquePermission("ROLE_CADASTRO_LOCAL_PROCEDIMENTO"),
        	SPECIALTY: uniquePermission("ROLE_CADASTRO_ESPECIALIDADE"),
			MEASURE_UNIT: uniquePermission("ROLE_CADASTRO_UNIDADE_MEDIDA"),
			STOCK_FLOW_MOTIVE: uniquePermission("ROLE_CADASTRO_FLUXO_ESTOQUE_MOTIVO"),
			SIGNATURE: uniquePermission("ROLE_ASSINATURA"),
        	AGENDA: {
        		locking: "ROLE_AGENDA_BLOQUEIO"
        	},
        	ATTENDANCE: {
        		access: "ROLE_ATENDIMENTO_ACESSO",
           		create: "ROLE_ATENDIMENTO_CRIACAO"
        	},
        	CLIENT: {
        		access: "ROLE_CLIENTE_ACESSO",
        		create: "ROLE_CLIENTE_CRIACAO",
        		edit: "ROLE_CLIENTE_EDICAO",
        		"delete": "ROLE_CLIENTE_EXCLUSAO"
        	},
        	EXPENSE: {
        		access: "ROLE_DESPESA_ACESSO",
				create: "ROLE_DESPESA_CRIACAO",
				edit: "ROLE_DESPESA_EDICAO",
				"delete": "ROLE_DESPESA_EXCLUSAO"
        	},
        	EXPENSE_TYPE: {
        		access: "ROLE_TIPO_DESPESA_ACESSO",
				create: "ROLE_TIPO_DESPESA_CRIACAO",
				edit: "ROLE_TIPO_DESPESA_EDICAO",
				"delete": "ROLE_TIPO_DESPESA_EXCLUSAO"
        	},
        	EXPENSE_COST_CENTER: {
        		access: "ROLE_CENTRO_CUSTO_DESPESA_ACESSO",
				create: "ROLE_CENTRO_CUSTO_DESPESA_CRIACAO",
				edit: "ROLE_CENTRO_CUSTO_DESPESA_EDICAO",
				"delete": "ROLE_CENTRO_CUSTO_DESPESA_EXCLUSAO"
        	},
        	EXPENSE_PAYMENT_OPTION: {
        		access: "ROLE_FORMA_PAGAMENTO_DESPESA_ACESSO",
				create: "ROLE_FORMA_PAGAMENTO_DESPESA_CRIACAO",
				edit: "ROLE_FORMA_PAGAMENTO_DESPESA_EDICAO",
				"delete": "ROLE_FORMA_PAGAMENTO_DESPESA_EXCLUSAO"
        	},
        	SUPPLIER: {
        		access: "ROLE_FORNECEDOR_ACESSO",
				create: "ROLE_FORNECEDOR_CRIACAO",
				edit: "ROLE_FORNECEDOR_EDICAO"
        	},
        	SUPPLIER_CATEGORY: {
        		access: "ROLE_CATEGORIA_FORNECEDOR_ACESSO",
        		create: "ROLE_CATEGORIA_FORNECEDOR_CRIACAO",
        		edit: "ROLE_CATEGORIA_FORNECEDOR_EDICAO",
        		"delete": "ROLE_CATEGORIA_FORNECEDOR_EXCLUSAO"
        	},
        	SUPPLIER_SUBCATEGORY: {
        		access: "ROLE_SUBCATEGORIA_FORNECEDOR_ACESSO",
        		create: "ROLE_SUBCATEGORIA_FORNECEDOR_CRIACAO",
        		edit: "ROLE_SUBCATEGORIA_FORNECEDOR_EDICAO",
        		"delete": "ROLE_SUBCATEGORIA_FORNECEDOR_EXCLUSAO"
        	},
        	EQUIPMENT: {
        		access: "ROLE_EQUIPAMENTO_ACESSO",
        		create: "ROLE_EQUIPAMENTO_CRIACAO",
        		edit: "ROLE_EQUIPAMENTO_EDICAO",
        		"delete": "ROLE_EQUIPAMENTO_EXCLUSAO"
        	},
            INDICATION_TYPE: {
                access: "ROLE_TIPO_INDICACAO_ACESSO",
                create: "ROLE_TIPO_INDICACAO_CRIACAO",
                edit: "ROLE_TIPO_INDICACAO_EDICAO",
                "delete": "ROLE_TIPO_INDICACAO_EXCLUSAO"
            },
            INDICATIONS: {
                access: "ROLE_INDICACOES_ACESSO",
                create: "ROLE_INDICACOES_CRIACAO",
                edit: "ROLE_INDICACOES_EDICAO",
                "delete": "ROLE_INDICACOES_EXCLUSAO"
            },
            INDICATIONS_MANAGER: {
                access: "ROLE_PARCEIRO_ACESSO",
                create: "ROLE_PARCEIRO_CRIACAO",
                edit: "ROLE_PARCEIRO_EDICAO",
                "delete": "ROLE_PARCEIRO_EXCLUSAO"
            },
            RECORDS: {
                NOTES: {
					access: "ROLE_PRONTUARIO_NOTAS",
                    edit: "ROLE_PRONTUARIO_NOTAS_EDICAO",
                },
                NOTES_ASSISTENT: {
					access: "ROLE_PRONTUARIO_NOTAS_ASSISTENTE",
                    edit: "ROLE_PRONTUARIO_NOTAS_ASSISTENTE_EDICAO",
                },
                EXAM_REQUEST: {
					access: "ROLE_PRONTUARIO_SOLICITACAO_EXAMES",
                    edit: "ROLE_PRONTUARIO_SOLICITACAO_EXAMES_EDICAO",
                },
                EXAM_RESULT: {
					access: "ROLE_PRONTUARIO_RESULTADO_EXAMES",
                    edit: "ROLE_PRONTUARIO_RESULTADO_EXAMES_EDICAO",
                },
                PRESCRIPTION: {
					access: "ROLE_PRONTUARIO_PRESCRICOES",
                    edit: "ROLE_PRONTUARIO_PRESCRICOES_EDICAO",
                },
                EMAIL_CONTACT: {
					access: "ROLE_PRONTUARIO_CONTATO_EMAIL",
                    edit: "ROLE_PRONTUARIO_CONTATO_EMAIL_EDICAO",
                },
                PHOTOS: {
					access: "ROLE_PRONTUARIO_FOTOS",
                    edit: "ROLE_PRONTUARIO_FOTOS_EDICAO",
                },
                BUDGET: {
					access: "ROLE_PRONTUARIO_ORCAMENTO",
                    edit: "ROLE_PRONTUARIO_ORCAMENTO_EDICAO",
                },

            },
			MANUFACTURER: {
        		access: "ROLE_FABRICANTE_ACESSO",
				create: "ROLE_FABRICANTE_CRIACAO",
				edit: "ROLE_FABRICANTE_EDICAO",
				"delete": "ROLE_FABRICANTE_EDICAO"
        	},
			PRODUCT: {
        		access: "ROLE_PRODUTO_ACESSO",
				create: "ROLE_PRODUTO_CRIACAO",
				edit: "ROLE_PRODUTO_EDICAO",
				"delete": "ROLE_PRODUTO_EDICAO"
        	},
			MANUFACTURER_PRODUCT_STOCK_FLOW: {
        		access: "ROLE_PRODUTO_FABRICANTE_FLUXO_ESTOQUE_ACESSO",
				create: "ROLE_PRODUTO_FABRICANTE_FLUXO_ESTOQUE_CRIACAO",
				edit: "ROLE_PRODUTO_FABRICANTE_FLUXO_ESTOQUE_EDICAO",
				report: "REPORT_PRODUTO_FABRICANTE_FLUXO_ESTOQUE"
        	},
			PRONTUARY_MODEL: {
        		access: "ROLE_MODELO_PRONTUARIO_ACESSO",
				create: "ROLE_MODELO_PRONTUARIO_CRIACAO",
				edit: "ROLE_MODELO_PRONTUARIO_EDICAO",
				"delete": "ROLE_MODELO_PRONTUARIO_EDICAO"
        	},
			PRONTUARY_ATTENDANCE: {
				access: "ROLE_PRONTUARIO_ATENDIMENTO_ACESSO",
				create: "ROLE_PRONTUARIO_ATENDIMENTO_CRIACAO",
				edit: "ROLE_PRONTUARIO_ATENDIMENTO_EDICAO",
				"delete": "ROLE_PRONTUARIO_ATENDIMENTO_EDICAO"
			},
			SALES_REQUEST_INVOICE: {
				access: "ROLE_REQUISICAO_VENDA_NF_ACESSO",
				create: "ROLE_REQUISICAO_VENDA_NF_CRIACAO",
				"cancel": "ROLE_REQUISICAO_VENDA_NF_CANCELAMENTO"
			}
        })
        .constant('SearchIcons', {
        	client: "fa fa-users"
        })
;
})();

function uniquePermission(p_)
{
	return {
		access: p_,
		create: p_,
		"delete": p_,
		edit: p_
	};

};
