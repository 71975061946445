(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierSubcategoryController', SupplierSubcategoryController);

    SupplierSubcategoryController.$inject = ['FormFilter', 'UcGridService', '$translate', 'filterPageConfig', 'gridDefaultSort', 'FrontEndSelectDataSource', 'FormConstants', '$rootScope', 'BackEndSelectDataSource' ];

    function SupplierSubcategoryController(FormFilter, UcGridService, $translate, filterPageConfig, gridDefaultSort, FrontEndSelectDataSource, FormConstants, $rootScope, BackEndSelectDataSource)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;
        
        $rootScope.initialFocus("name");
        
        vm.buttonsModel =
        {
        	stateRoot: "supplierSubcategory",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
        };

        var formOptions = {
        	formStatePersisterId: "SupplierSubcategory"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([
        		new FormField({
		       		cellCssClass: "col-sm-3",
					type: "String",
					id: "code",
					label: "common.domain.supplierSubcategory.code"
		        }),
        		new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "categoryList",
					multiple: true,
					label: "common.domain.supplierSubcategory.category",
					dataSource: FormConstants.DataSources.BackEnd.SupplierCategory(BackEndSelectDataSource, $translate)
				}),
    			new FormField({
		       		cellCssClass: "col-sm-3",
					type: "String",
					id: "name",
					label: "common.domain.supplierSubcategory.name"
		        }),
		        new FormField({
		       		cellCssClass: "col-sm-3",
					type: "Select",
					id: "active",
					label: "common.domain.supplierSubcategory.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
		    })])
			.endConfig();
        
        function renderSupplyType(p_param) {
			if (p_param.data.supplyType) {
				return p_param.data.supplyType.defaultDescription;
			}
			else {
				return "";
			}
		}
        
        vm.gridFieldList = UcGridService.createFieldList([
        	{headerName: $translate.instant("common.domain.supplierSubcategory.code"), field: "code", width: 7},
        	{headerName: $translate.instant("common.domain.supplierSubcategory.category"), field: "categoryName", width: 25},
        	{headerName: $translate.instant("common.domain.supplierSubcategory.name"), field: "name", width: 70},
            UcGridService.field.ACTIVE()	// width: 5
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "SUPPLIER_SUBCATEGORY",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "supplierSubcategory",
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList, 
        	sort: vm.sort,
        });
        
        vm.gridOptions = grid.getGridOptions();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        
        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};
	}
})();
