(function(){
	'use strict';
	angular.module('sgmApp').controller('InstallmentCancelDialogController', InstallmentCancelDialogController);
	
	InstallmentCancelDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'installmentCancelData', 'Expense', '$rootScope'];
	
	
	function InstallmentCancelDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, installmentCancelData, Expense, $rootScope){
		
		var vm = this;
		vm.pageConfig = pageConfig;
		
		vm.installmentCancelData = installmentCancelData;
		
		 /*public*/ vm.closeModal = function(isToSave)
        {
			$uibModalInstance.close(isToSave);
        };
        var formCancelOptions = {
    			model: vm.installmentCancelData,
    			pageConfig: vm.pageConfig,
    			service: Expense,
    			getForm: function () { return vm.cancelExpenseForm; }
        };
        
        vm.cancelForm = FormDomain.create(formCancelOptions).add([
        	new FormField({
        		type: "TextArea",
				readOnly: vm.readOnlyFinish,
				id: "cancelExpenseReason",
				required: true,
				label: "client.page.expense.cancelExpenseReason",
				cellCssClass: "col-sm-12",
        	})
        ]);
        
        vm.onConfirmCancelExpense = function(){
        	if(vm.installmentCancelData.cancelExpenseReason){
        		vm.closeModal(true);
        	}
        }
	       
	}
})();
