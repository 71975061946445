(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PhotoUploadModalController', PhotoUploadModalController);

    PhotoUploadModalController.$inject = ['$scope', 'PhotoUploadParam', '$uibModalInstance', 'Upload', 'UcGridService', '$translate', 'UcAlertService', 'UcService'];

    function PhotoUploadModalController($scope, PhotoUploadParam, $uibModalInstance, Upload, UcGridService, $translate, UcAlertService, UcService)
    {
        var vm = this;
        
        /*public*/ vm.tabList = [
    		{code: "file", icon: "fa fa-upload", label: "client.page.photoUploadModalDialog.tab.file"},
    		{code: "camera", icon: "fa fa-camera", label: "client.page.photoUploadModalDialog.tab.camera"}
    	];
        
        /*public*/ vm.tabHandler = {
        	active: 'file',
        	disabledMap: {},
        	isActive: function(p_code)
        	{
        		return (p_code === vm.tabHandler.active);
        	},
        	isDisabled: function(p_code) { return false },
        	isShow: function(p_code) { return true },
        	select: function(p_id)
        	{
       			vm.tabHandler.active = p_id;
        	}
        };
        
        /*public*/ vm.uploadPhoto = function(p_photo)
        {
        	UcAlertService.dismissBox();
        	
        	// Verifica se tem arquivo selecionado
        	if(!p_photo || !p_photo.name)
        	{
        		UcAlertService.message($translate.instant('client.component.uploadPhoto.noSelectedImage'), "WARNING", "GROWL");
        		return;
        	}

        	p_photo.upload = Upload.upload({
        		url: 'api/uc/update-image',
        		data: {photoId: PhotoUploadParam.photoId, file: p_photo, cameraImageSer: $("#cameraImageSer-input").val()}
        	});

        	p_photo.upload.then(function (p_response) // ok
        	{
        		closeOk();
        	});
        };

		/**
		 * Cancel or top-icon close
		 */
        /*public*/ vm.closeModal = function()
        {
        	UcAlertService.dismissBox();
        	$uibModalInstance.dismiss();
        };
        
        /*public*/ vm.takePicture = function()
        {
        	$scope.$broadcast(CameraHandler.Events.TakePicture);
        };
        
        /*private*/ function closeOk()
        {
    		PhotoUploadParam.okCallback();
    		$uibModalInstance.dismiss();
        }
        
    	var listener = $scope.$on(CameraHandler.Events.TakePictureEnd, function(p_event, p_result)
    	{
        	UcService.uploadCamera({
        		photoId: PhotoUploadParam.photoId, 
        		imageSer: $("#cameraImageSer-input").val()
    		},
    		function()
    		{
    			closeOk();
    		});
    	});
    	toDestroy($scope, listener);
	}
})();