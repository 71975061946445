(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsPrintModalController', RecordsPrintModalController);

    RecordsPrintModalController.$inject = ['recordList', 'recordListHeaders', 'record', 'recordType', 'hasSignCallback', 'LoginModalInvoker', 'clientName', '$uibModalInstance', '$timeout', '$translate'];

    function RecordsPrintModalController(recordList, recordListHeaders, record, recordType, hasSignCallback, LoginModalInvoker, clientName, $uibModalInstance, $timeout, $translate)
    {
        var vm = this;
        
        var MAX_DIV_SIZE = 600;
        
        var BREAK_LINE_TAGS = ["</div>", "</p>", "</li>", "</tr>", "<br>", "<br/>", "<br />"]

        vm.recordType = recordType;
		vm.hasSignCallback = hasSignCallback;
        vm.createPage = function() {
			$timeout(function()
			{
				var content = "";
				if (recordList && recordList.length > 0) {
					recordList.forEach(function (recordItem, index) {
						content += '<div>' + vm.buildPrintContent(recordItem, recordListHeaders[index]) + '</div>';
					});
				}else {
					content += vm.buildPrintContent(record);
				}
				content = "<div class='print-page'>" + content + "<div>";
				
				var pw = frames["toPrintFrame"];
				pw.document.innerHTML = "";
				pw.document.write('<html moznomarginboxes mozdisallowselectionprint><head><title>' + recordType.label + " | " + clientName  + '</title>');	
				pw.document.write('</head>');
				pw.document.write(generatePrintCssStyle());
				pw.document.write('<body>');
				pw.document.write('<div id="records-print-area"></div>');
				pw.document.write('</body>');
				pw.document.write('</html>');
				
				try
				{
					pw.document.close(); // necessary for IE >= 10
					pw.focus(); // necessary for IE >= 10*/
				}
				catch(exc)
				{
					console.error("Error on prepare document to print: " + exc.message);
				}
				
				$timeout(function()
				{
					preparePrint(content)
				}, 500)
				
	//            $timeout(function()
	//            {
	//            	frames["toPrintFrame"].print();
	//            }, 500)
			}, 100);
		};

		vm.createPage();

		/*public*/ vm.buildPrintContent = function (record, recordHeader) 
		{
			var content = record.content.contentResolvedHtml ? record.content.contentResolvedHtml : "";
			if(record.content.contentType == "text/plain")
        	{
        		content = "<div class='textContent'>" + content + "</div>";
        	}
        	if(record.type.id == "EXS")
        	{
        		content = "<div class='records-print-header'>" +  
        				"	<div>" + $translate.instant("client.global.label.to") + ": <b>" + clientName + "</b></div>" +
        				"	<div class='service-request'>" + $translate.instant("client.global.messages.info.serviceRequest") + ",</div>" + 
        				"</div>" +
        				content;
        	}
        	else if(record.type.id == "PRE")
        	{
        		content = "<div class='records-print-header'>" +  
						"	<div>" + $translate.instant("client.global.label.to") + ": <b>" + clientName + "</b></div>" +
						"</div>" +
						content;
			}
			else if (record.type.id == "NOT")
			{
				debugger
				if (!record.content.contentType.includes('image/')) {
					content = "<div class='records-print-header'>" + recordHeader + "</div>" 
							+ "<br>" + content + '<br><br>';
				}else {
					content = "";
				}
			}
			if(record.signUser != null && record.signUser.signatureImage != null) {
				var p_imageDto = record.signUser.signatureImage;
				content += "${"+record.id+"}";
			}
			
			return content;
		};

        /*public*/ vm.print = function()
        {
        	frames["toPrintFrame"].print();
        };

		function replaceSignature(s,  recordItem) {
			if(s.includes("${"+recordItem.id+"}")) {
				return s.replace("${"+recordItem.id+"}",
				"<br><b>Assinatura</b><br>"
				+"<img style='max-height: 80px' src='data:" 
				+ recordItem.signUser.signatureImage.contentType + ";base64," 
				+ recordItem.signUser.signatureImage.bytes 
				+ "' /><br>")
			}
			return s;
			
		}

		vm.sign = function() {
			LoginModalInvoker.invoke({
                callback: function(res) { $uibModalInstance.close(res) }
            });
		}
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        
        /*private*/ function preparePrint(p_str) 
        {
        	var s = p_str;
        	s = s.replace(new RegExp("\n", 'ig'), "");
        	s = s.replace(new RegExp("\r", 'ig'), "");
        	for (var i = 0; i < BREAK_LINE_TAGS.length; i++)
        	{
        		// adiciona as quebras de linha para algumas tags
        		s = s.replace(new RegExp(BREAK_LINE_TAGS[i], 'ig'), BREAK_LINE_TAGS[i] + "\n");
			}
        	// listas
        	s = s.replace(new RegExp(/<li.*?>/gi), "<li>• ");
        	
        	// remove as tags html
        	s = $(s).text();
        	
        	if(!s && s !== "0")
        	{
        		s = "";
        	}
			if (recordList && recordList.length > 0) {
				recordList.forEach(function (recordItem, index) {
					s = replaceSignature(s, recordItem);
				})
			} else {
				s = replaceSignature(s, record);
			}
        	
        	// adiciona em um array fazendo trim
        	var tks = s.split("\n");
        	var lines = [];
        	for (var i = 0; i < tks.length; i++)
        	{
        		tks[i] = $.trim(tks[i]);
        		if(tks[i].length > 0 && tks[i].charAt(0) == '•')
        		{
        			tks[i] = "   " + tks[i];
        		}
        		lines[i] = tks[i] + "<br/>";
			}

        	var seedId = (new Date()).getTime() + "-";
        	var currId;
        	
        	$("#toPrintFrame").contents().find("#records-print-area").html("<div class='print-page'><div id='"+ seedId + "'></div></div>");
        	
        	var accum = "";
        	var contentList = [];
        	for (var i = 0; i < lines.length; i++)
        	{
        		accum += lines[i];
        		$("#toPrintFrame").contents().find("#" + seedId).html(accum);
        		
        		if($("#toPrintFrame").contents().find("#" + seedId).height() > MAX_DIV_SIZE)
        		{
        			contentList.push("<div class='print-page print-page-fixed-height'>" + accum + "</div>");
        			accum = "";
        		}
			}
        	contentList.push("<div class='print-page print-page-fixed-height'>" + accum + "</div>");
        	
        	$("#toPrintFrame").contents().find("#records-print-area").html("");
        	
        	// Resultado final
        	var finalHtml = "";
        	for (var i = 0; i < contentList.length; i++)
        	{
        		finalHtml += contentList[i];
			}
        	$("#toPrintFrame").contents().find("#records-print-area").html(finalHtml);
        }
	}
})();