(function() {
    'use strict';

    angular.module('sgmApp').service('TabStatePersister', TabStatePersister);

    TabStatePersister.$inject = [];
    
    function TabStatePersister()
    {
    	var vm = this;
    	
    	var map = {};
    	
    	/*public*/ vm.init = function(p_controllerName, p_defaultInitialState)
    	{
    		var persistedTab = map[p_controllerName];
    		
    		// Se existe, retorna
    		if(persistedTab)
    		{
    			return persistedTab;
    		}
    		else // Se não, seta o default e o retorna
    		{
    			return vm.set(p_controllerName, p_defaultInitialState);
    		}
    	};

    	/*public*/ vm.set = function(p_controllerName, p_state)
    	{
    		map[p_controllerName] = p_state;
    		return p_state;
    	};

    	/*public*/ vm.reset = function(p_controllerName)
    	{
    		delete map[p_controllerName];
    	};

    }
})();
