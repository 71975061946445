(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('SalesRequestFormFieldDirectiveController', SalesRequestFormFieldDirectiveController);
    
    SalesRequestFormFieldDirectiveController.$inject = ['$scope', '$translate', '$rootScope', 'FormConstants', 'SalesRequestModalInvoker'];
    function SalesRequestFormFieldDirectiveController($scope, $translate, $rootScope, FormConstants, SalesRequestModalInvoker)
    {
    	var vm = this;
    	
    	vm.fieldModel = $scope.fieldModel;
    	vm.objModel = $scope.objModel;
    	vm.editMode = $scope.editMode;
    	
    	vm.readOnly = vm.fieldModel.readOnly || !vm.editMode;
    	
    	/*public*/ vm.selectModalTitle = function()
    	{
    		if(!vm.readOnly)
    		{
    			return $translate.instant("client.global.label.clickToSelect");
    		}
    		else
    		{
    			return "";
    		}
    	};
    	
    	/*public*/ vm.clearSelect = function(p_event)
    	{
    		if(!vm.readOnly)
    		{
    			vm.objModel[vm.fieldModel.id] = null;
    		}
    		p_event.stopPropagation();
    	};
    	
    	/*public*/ vm.displaySelectedValue = function()
    	{
    		return (vm.objModel[vm.fieldModel.id]) ? vm.objModel[vm.fieldModel.id].defaultDescription: null;
    	}
    	
    	/*public*/ vm.selectModalClick = function()
    	{
    		if(!vm.readOnly)
    		{
    			var filter = vm.fieldModel.getPageFilter();
				SalesRequestModalInvoker.invoke({
					clientList: filter["clientList"],
					procedureList: filter["procedureList"],
					selectCallback: function(p_salesRequest)
					{
						vm.objModel[vm.fieldModel.id] = (p_salesRequest) ? p_salesRequest : null;
						// TODO: A mensagem de campo não preenchido não está sendo atualizada quando setado valor
					}
				});

    		}
    	};


    	
    	// Destroy os listeners
        $scope.$on('$destroy', function()
        {
//            if(angular.isDefined(resetLister) && resetLister !== null)
//            {
//            	resetLister();
//            }
//            if(angular.isDefined(readOnlyListener) && readOnlyListener !== null)
//            {
//            	readOnlyListener();
//            }
        });
    };
})();