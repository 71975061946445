(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsEditModalController', RecordsEditModalController);

    RecordsEditModalController.$inject = ['$uibModalInstance', 'pageConfig', 'recordType', 'client', 'attendanceId', '$timeout', 'Records', 'RecordsTemplateModalInvoker', 'initialContent', 'recordId', '$rootScope', '$translate'];

    function RecordsEditModalController($uibModalInstance, pageConfig, recordType, client, attendanceId, $timeout, Records, RecordsTemplateModalInvoker, initialContent, recordId, $rootScope, $translate)
    {
        var vm = this;
        
        vm.pageConfig = pageConfig;
        vm.recordType = recordType;
        
        vm.pageTitle = recordType.label + " - " + client.name;
        
        vm.showDeleteButton = !!recordId;
        
        /*private*/ var editor = null;
        /*private*/ var initialContentHtml = (initialContent && initialContent.contentResolved) ? initialContent.contentResolved : null;
        
        
        $timeout(function()
        {
        	editor = initCKEditor("recordsEditor", 480, initialContentHtml);
        }, 100);
        
        /*public*/ vm.save = function()
        {
        	// Create
        	if(!recordId)
        	{
	        	Records.save(
	    		{
	    			type: recordType.id,
	    			attendanceId: attendanceId,
	    			clientId: client.id,
	    			content: editor.getData()
	        	}, 
	        	function()
	        	{
	        		$uibModalInstance.close(true);	
	        	});
        	}
        	else // Update
        	{
	        	Records.update(
	    		{
	    			id: recordId,
	    			content: editor.getData()
	        	}, 
	        	function()
	        	{
	        		$uibModalInstance.close(true);	
	        	});
        	}
        }
        
        /*public*/ vm.clear = function()
        {
        	editor.setData("");
        }
        
        /*public*/ vm.openTemplateModal = function()
        {
        	RecordsTemplateModalInvoker.invoke({
        		recordType: recordType,
        		callback: function(p_template)
        		{
        			// Se há template válido, o usa
        			if(p_template)
        			{
        				// Concatena no final
        				var text = editor.getData();
        				if(!text)
        				{
        					text = "";
        				}
        				editor.setData(text + p_template);
        			}
        		}
        	});
        }
        
        /*public*/ vm.deleteObj = function()
        {
			$rootScope.confirm(
			{
				message: $translate.instant("client.page.records.main.deleteRecordConfirmation"),
				callback: function()
				{
					Records.delete(
					{
						id: recordId
					},
					function()
					{
						$uibModalInstance.close(true);
					});
				} 
			});
        }

        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();