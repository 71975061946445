(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('Records', Records);

    Records.$inject = ['$resource', 'URL', 'SERVICES'];

    function Records ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url +'api/records/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
        	'getClientRecords': { method: 'GET', url: url + 'api/records/get-client-records/:clientId'},
        	'saveImageAttach': { method: 'POST', url: url + 'api/records/save-image-attach'},
        	'getContent': { method: 'GET', url: url + 'api/records/get-content/:id'},
            'sign': { method: 'PUT', url: url + 'api/records/sign'},
        	'listContentEditHistory': { method: 'GET', url: url + 'api/records/list-content-edit-history/:id', isArray: true}
        });
    }
})();
