(function() {
	'use strict';

	/**
	 * Controla os estados da tela como NEW, EDIT e DETAIL
	 *
	 *  Cada state é um estado da tela e utiliza os service para fazer consultas e persistência nas APIs
	 *  OBS: Módulo copiado de managers/client em caso de dúvidas
	 */
	angular
		.module('sgmApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider', 'PermissionSet'];


	function stateConfig($stateProvider, PermissionSet, Client) {
		$stateProvider
			.state('indicationsManager', {
				parent: 'entity',
				url: '/indicationsManager?page&sort',
				data: {
					authorities: [PermissionSet.INDICATIONS_MANAGER.access],
				},
				views: {
					'content@': {
						templateUrl: 'app/common/page-filter/page-filter.html',
						controller: 'IndicationsManagerController',
						controllerAs: 'vm'
					}
				},
				resolve: {

					// busca pelas origens de indicações
					indicationsList: ['IndicationsService', function(IndicationsService) {
						return IndicationsService.listAllActive().$promise;
					}],
					gridDefaultSort: function() {
						return {
							"name": "name",
							"type": "asc"
						};
					},
					filterPageConfig: ['$rootScope', 'account', function($rootScope, account) {
						return new PageConfig({
							pageMode: "FILTER",
							account: account,
							title: 'client.page.indicationsManager.title',
							icon: $rootScope.menuIcons.main.indicationsManager,
							formValidateTarget: 'IndicationsManager',
							permission: PermissionSet.INDICATIONS_MANAGER
						})
					}]
				}
			})
			.state('indicationsManager.base-domain', {
				parent: 'indicationsManager',
				abstract: true,
				url: '/domain/:activeTab',
				resolve: IndicationsManagerCommonStatic().extendResolve()
			})
			.state('indicationsManager.detail', {
				parent: 'indicationsManager.base-domain',
				url: '/{id}/detail',
				views: {
					'content@': {
						templateUrl: 'app/pages/managers/indications/indications-manager-domain.html',
						controller: 'IndicationsManagerDomainController',
						controllerAs: 'vm'
					}
				},
				resolve:
				{
					// IndicationsManagerService busca os dados do parceiro
					entity: ['$stateParams', 'IndicationsManagerService', function($stateParams, IndicationsManagerService) {
						return IndicationsManagerService.get({ id: $stateParams.id }).$promise;
					}],

					// Client Service: Busca os clientes que foram indicados pelo parceiro
					client: ['$stateParams', 'Client', function($stateParams, Client) {
						return Client.getClientByIndicationsManager({ id: $stateParams.id }).$promise;
					}],

					// Executa uma busca na api grid
//					clientIndications: ['$stateParams', 'Client', function($stateParams, Client) {
//						//                    return UcDatasourceService.fetch({gridId: "INDICATIONS_MANAGER_ATTENDANCE",indicationsManagerId: $stateParams.id,page:1,size:50,sort:"name,desc"}).$promise;
//						return Client.getClientByIndicationsManager({ id: $stateParams.id }).$promise;
//					}],

					pageConfig: ['filterPageConfig', function(filterPageConfig) {
						return filterPageConfig.clone().set(
							{
								pageMode: "READONLY"
							});
					}]
				}
			})
			.state('indicationsManager.edit', {
				parent: 'indicationsManager.base-domain',
				url: '/{id}/edit',
				views: {
					'content@': {
						templateUrl: 'app/pages/managers/indications/indications-manager-domain.html',
						controller: 'IndicationsManagerDomainController',
						controllerAs: 'vm'
					}
				},
				resolve:
				{
					// IndicationsManagerService busca os dados do parceiro
					entity: ['IndicationsManagerService', '$stateParams', function(IndicationsManagerService, $stateParams) {
						return IndicationsManagerService.get({ id: $stateParams.id }).$promise;
					}],

					pageConfig: ['filterPageConfig', function(filterPageConfig) {
						return filterPageConfig.clone().set(
							{
								pageMode: "EDIT"
							});
					}]
				}
			})
			.state('indicationsManager.new', {
				parent: 'indicationsManager.base-domain',
				url: '/new',
				views: {
					'content@': {
						templateUrl: 'app/pages/managers/indications/indications-manager-domain.html',
						controller: 'IndicationsManagerDomainController',
						controllerAs: 'vm'
					}
				},
				resolve:
				{
					entity: function() {
						return {}
					},

					pageConfig: ['filterPageConfig', function(filterPageConfig) {
						return filterPageConfig.clone().set(
							{
								pageMode: "CREATE"
							});
					}]
				}
			});
	}
})();
