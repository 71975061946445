(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsTemplateDirectoryModalController', RecordsTemplateDirectoryModalController);

    RecordsTemplateDirectoryModalController.$inject = ['$uibModalInstance', 'pageConfig', 'recordType', '$translate', 'RecordsTemplateDir', 'entity', 'UcAlertService', '$rootScope'];

    function RecordsTemplateDirectoryModalController($uibModalInstance, pageConfig, recordType, $translate, RecordsTemplateDir, entity, UcAlertService, $rootScope)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        vm.recordType = recordType;
        
        vm.entity = entity;
        
        $rootScope.initialFocus("records-template-directory-name");
        
        /*public*/ vm.save = function()
        {
        	if(!vm.entity.name)
        	{
        		UcAlertService.error($translate.instant("client.global.messages.error.requiredFieldP0", {field: $translate.instant("client.global.label.name")}));
        		return;
        	}
        	if(!vm.entity.id)
        	{
        		RecordsTemplateDir.save(
				{
					type: recordType.id,
					name: vm.entity.name
				}, function()
				{
					$uibModalInstance.close(true);	
				}); 
        		
        	}
        	else
        	{
        		RecordsTemplateDir.update(
				{
					id: vm.entity.id,
					type: recordType.id,
					name: vm.entity.name
				}, function()
				{
					$uibModalInstance.close(true);	
				});
        	}
        }
        
        /*public*/ vm.deleteObj = function()
        {
			$rootScope.confirm(
			{
				message: $translate.instant("client.page.records.templatesDir.deleteConfirmationMessage"),
				callback: function()
				{
					RecordsTemplateDir.delete(
					{
						id: vm.entity.id
					},
					function()
					{
						$uibModalInstance.close(true);	
					});
				} 
			});
        }

        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();