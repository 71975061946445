(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('photoUpload', photoUploadDirective);
    
    photoUploadDirective.$inject = [];
    function photoUploadDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "PhotoUploadDirectiveController",
            templateUrl: "app/components/photo-upload/photo-upload.directive.template.html",
            scope:
            {
            	photoTitle: '@',
            	pageMode: '=',
            	domain: '=',
            	photoIdentifier: '@'
            }
    	}
    };
})();
