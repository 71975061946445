(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider)
    {
        $stateProvider.state('test2', {
            parent: 'app',
            url: '/test2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/test2/test2.html',
                    controller: 'Test2Controller',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader)
                {
                	try
                	{
	                    $translatePartialLoader.addPart('global');
	                    return $translate.refresh();
                	}
                	catch(exc)
                	{
                		console.error("Error at test2.state: " + exc.message);
                		return null;
                	}
                }]            	
            }
        });
    }
})();
