(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('SearchModalIndicationsManagerController', SearchModalIndicationsManagerController);

	SearchModalIndicationsManagerController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'UcGridService', '$translate', '$timeout', 'ControllerListener', 'UcAlertService', 'SearchIcons', 'ClientCommon', 'account', 'IndicationsManagerService'];

	function SearchModalIndicationsManagerController($scope, $uibModalInstance, $rootScope, UcGridService, $translate, $timeout, ControllerListener, UcAlertService, SearchIcons, ClientCommon, account, IndicationsManagerService) {
		var vm = this;

		var config = $scope.searchModalConfig;
		if (!config) {
			UcAlertService.error("Undefined searchModalConfig");
			$uibModalInstance.dismiss();
			return;
		}

		$rootScope.initialFocus("search");

		vm.extraButtons = config.extraButtons;

		vm.SearchIcons = SearchIcons;
		vm.filter = {
			search: null
		};

		var currentSearchValue = vm.filter;
		var refreshSelectedRows = false;

		vm.searchIdLowerCase = config.searchId.substring(1, 0).toLowerCase() + config.searchId.substr(1);

		vm.selectedMap = {};
		// Incializa o mapa de selecionados com os usuários passados como parâmetro
		if (config.currentValue) {
			var item = config.currentValue;
			vm.selectedMap["k" + item.id] = item;
			//			for (var i = 0; i < config.currentValue.length; i++) {
			//				console.log(item);
			//				item = config.currentValue[i];
			//				vm.selectedMap["k" + item.id] = i;
			//				vm.selectedMap["k" + item.id] = { id: item.id, name: item.name, minimal: true };
			//			}
		}

		vm.noItemsSelected = (Object.keys(vm.selectedMap).length == 0);

		var selectedIcon = {
			headerName: null,
			field: null,
			width: 50,
			suppressSizeToFit: true,
			suppressSorting: true,
			cellStyle: { alignItems: "center", textAlign: "center" },
			cellRenderer: function(p_) {

				// Se o item estiver selecionado
				var lineMarkClass = "";

				if (p_.data && p_.data.id && vm.selectedMap["k" + p_.data.id]) {
					lineMarkClass = "line-mark-selected"
				}

				var lineMarkId = "";
				if (p_.data) {
					lineMarkId = "line-mark-k" + p_.data.id;
				}
				return '<i id="' + lineMarkId + '" class="' + lineMarkClass + ' fa fa-check" aria-hidden="true" style="font-size:20px"></i>';
			}
		};

		vm.gridFieldList = UcGridService.createFieldList([
			selectedIcon,
            { headerName: $translate.instant("common.domain.indicationsManager.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5 },
            { headerName: $translate.instant("common.domain.indicationsManager.indicatorName"), field: "indicatorName", width: 27 },
            { headerName: $translate.instant("common.domain.indicationsManager.indicatorPhone"), field: "indicatorPhone", width: 11 },
            { headerName: $translate.instant("common.domain.indicationsManager.indicatorAddress"), field: "indicatorAddress", cellStyle: { alignItems: "center", textAlign: "center" }, width: 4 },
            { headerName: $translate.instant("common.domain.indicationsManager.indicatorEmail"), field: "indicatorEmail", width: 13 },
            { headerName: $translate.instant("common.domain.indicationsManager.indicationTypeDescription"), field: "indicationTypeDescription", width: 13 },
            { headerName: $translate.instant("common.domain.indicationsManager.indicationDescription"), field: "indicationDescription", width: 13 },

		]);

		var gridParams = {
			gridId: "INDICATIONS_MANAGER",
			startOnLoad: true,
			detailsIcon: false,
			responsiveHeight: false,
			authenticate: false,
			getFilterData: function() {return {'generalSearch': vm.filter.search };},
			gridFieldList: vm.gridFieldList,
			rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
			sort: { "name": "indicatorName", "type": "asc" },
			onRowClicked: function(p_data) {

				onRowClicked(p_data);
			},
			onViewportChanged: function() { refreshSelectedRows = true; },

		};
		var grid = UcGridService.create(gridParams);

		vm.gridOptions = grid.getGridOptions();

        /**
         * Escuta o filtro para atualizar o grid (filtro e selecionados)
         */
		ControllerListener.create($scope, function() {
			// Só faz a query quando os parâmetros mudam
			if (vm.filter.search != currentSearchValue) {
				currentSearchValue = vm.filter.search;
				grid.refresh();
			}

			// Só atualiza os selecionado quando a flag indica
			if (refreshSelectedRows) {
				updateRowCssClass();
			}
		});

        /*public*/ vm.closeModal = function() {
			$uibModalInstance.dismiss();
		};

        /**
         * Remove o item direto da lista de seleção
         */
        /*public*/ vm.removeItem = function(p_item) {
			toogleRow(p_item.id, null, false);
		};

        /**
         * Chama o callback do requester e fecha a janela
         */
        /*public*/ vm.closeAndReturn = function() {
			var list = [];
			var keys = Object.keys(vm.selectedMap);
			var obj;
			for (var i = 0; i < keys.length; i++) {
				obj = vm.selectedMap[keys[i]];
				obj.defaultDescription = obj.name;
				list.push(obj);
			}
			// Ordena
			list.sort(function(p_1, p_2) {
				var v1 = (p_1.defaultDescription) ? p_1.defaultDescription.toUpperCase() : "";
				var v2 = (p_2.defaultDescription) ? p_2.defaultDescription.toUpperCase() : "";
				if (v1 > v2) {
					return 1;
				}
				else if (v1 < v2) {
					return -1;
				}
				else {
					return 0;
				}
			});
			$uibModalInstance.close(list);
		};

        /**
         * Limpa os selecionados
         */
        /*public*/ vm.clear = function() {
			var keys = Object.keys(vm.selectedMap);
			for (var i = 0; i < keys.length; i++) {
				delete vm.selectedMap[keys[i]];
				setRowCss(keys[i], false);
			}
			updateRowCssClass();
		};

        /*public*/ vm.clickExtraButton = function(p_btn) {
			try {
				p_btn.callback();
			}
			catch (exc) {
				console.error("Error at clickExtraButton: " + exc.message);
			}
		}

        /**
         * Representa o clique na linha
         */
        /*private*/ function onRowClicked(p_data) {
			if (!p_data.event.defaultPrevented) {
				if (p_data && p_data.data && p_data.data.id) {
					toogleRow(p_data.data.id, p_data.data.indicatorName, true, p_data.data);
				}
			}
		}

        /*private*/ function toogleRow(p_id, p_name, p_scopeDigest, data) {
			var key = "k" + p_id;
			var flag;

			// Se já existir, desmarca. Se não, marca
			if (vm.selectedMap[key]) {
				flag = false;
				delete vm.selectedMap[key];
			}
			else {
				// Se não for multiplo, desmarca os que estiverem marcados
				if (!config.multiple) {
					var keys = Object.keys(vm.selectedMap);
					for (var i = 0; i < keys.length; i++) {
						delete vm.selectedMap[keys[i]];
						setRowCss(keys[i], false);
					}
				}

				flag = true;

				vm.selectedMap[key] = data
				//				vm.selectedMap[key] = { id: p_id, name: p_name, minimal: true }
			}
			setRowCss(p_id, flag);

			vm.noItemsSelected = (Object.keys(vm.selectedMap).length == 0);

			if (p_scopeDigest) {
				$scope.$digest();
			}

			// Se não é multiplo e tem seleção, retorna o valor
			if (!config.multiple && !vm.noItemsSelected) {
				vm.closeAndReturn();
			}
		};


        /**
         * Configura para o próximo ciclo a seleção (só css da linha)
         */
        /*private*/ function scheduleSelectRow(p_id) {
			$timeout(function() {
				setRowCss(p_id, true);
			}, 1)
		};

        /*private*/ function setRowCss(p_id, p_flag) {
			if (p_id && ("" + p_id).indexOf("k") != 0) {
				p_id = "k" + p_id;
			}

			// Atualiza o estilo da linha
			if (p_flag) {
				$("#line-mark-" + p_id).removeClass("line-mark-remove");
				$("#line-mark-" + p_id).addClass("line-mark-selected");
			}
			else {
				$("#line-mark-" + p_id).removeClass("line-mark-selected");
				$("#line-mark-" + p_id).addClass("line-mark-remove");
			}
			updateRowCssClass();
		};

        /*private*/ function updateRowCssClass() {
			// Adiciona e seta a classe conforme classes auxiliares
			$(".line-mark-selected").closest(".ag-row").addClass("ag-row-selected")
			$(".line-mark-remove").closest(".ag-row").removeClass("ag-row-selected")
			$(".line-mark-remove").removeClass("line-mark-remove");
			refreshSelectedRows = false;
		};

		var closeListener = $rootScope.$on('search-modal.close.' + config.searchId, function(p_event, p_list) {
			$uibModalInstance.close(p_list)
		});

		$scope.$on('$destroy', function() {
			closeListener();
		});

		// Limpa o filtro
		vm.clear=function(){
			vm.filter.search='';
		}
	}
})();
