(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('AdminTask', AdminTask);

    AdminTask.$inject = ['$resource', 'URL', 'SERVICES'];

    function AdminTask ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/admin-task/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'exec': { method:'GET', url: url + 'api/admin-task/exec' }
        });
    }
})();
