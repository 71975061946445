(function() {
    'use strict';

	angular.module('sgmApp').service('HistoryModalInvoker', HistoryModalInvoker);
	
	HistoryModalInvoker.$inject = ['$uibModal'];
	
	function HistoryModalInvoker($uibModal)
	{
		var vm = this;
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(/*HistoryModalInvokerParam*/ p_param)
		{
            $uibModal.open({
                templateUrl: "app/components/history/modal/history-modal.template.html",
                controller: 'HistoryModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: 
                {
                	trackChangesData: ['UcService', function(UcService)
                    {
                        return UcService.getTrackChanges({objectId : p_param.id, trackChangesId: p_param.historyId}).$promise;
                    }]
                }
            });
		}
		
	};
})();


function HistoryModalInvokerParam(p_param)
{
	var _DEFAULT_PARAM =
	{
		id: null,
		historyId: null
	};
	
	// Carrega os valores default
	angular.extend(this, _DEFAULT_PARAM);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_param) ? p_param : {});
}

