(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('Sessions', Sessions);

    Sessions.$inject = ['$resource', 'URL', 'SERVICES'];

    function Sessions ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        return $resource(url + 'api/account/sessions/:series', {}, {
            'getAll': { method: 'GET', isArray: true}
        });
    }
})();
