(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['FormDomain', '$scope', 'User', 'pageConfig', 'UcAlertService', '$translate', '$rootScope'];

    function PasswordController(FormDomain, $scope, User, pageConfig, UcAlertService, $translate, $rootScope)
    {
        var vm = this;
        
        var obj = {};

        vm.pageConfig = pageConfig;

        $rootScope.initialFocus("currentPassword");

        // Não utiliza  asugestão de botões já que o 'Voltar' não se aplicaa 
        var saveButton = {
        	order: 0,
        	cssClass: 'btn btn-success',
        	cssClassIcon: 'glyphicon glyphicon-save',
        	label: 'client.global.label.save',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function() { save(); }
        };
        
        vm.buttonsModel = {
        	useSuggestedButton: false,
        	extraButtonList: [saveButton]
        };
        
        var formOptions = {
        	model: obj,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: User,
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add(new FormField({
           		cellCssClass: "col-sm-12",
				type: "Password",
				id: "currentPassword",
				required: true,
				label: "client.page.password.currentPassword"
			}))
        	.add(new FormField({
           		cellCssClass: "col-sm-12",
				type: "Password",
				id: "newPassword",
				required: true,
				label: "client.page.password.newPassword"
			}))
        	.add(new FormField({
           		cellCssClass: "col-sm-12",
				type: "Password",
				id: "newPassword2",
				required: true,
				label: "client.page.password.newPasswordConfirmation"
			}))
			.endConfig();
        
        
        var isSaving = false;
        
        /*private*/ function save()
        {
	    	UcAlertService.dismissBox();
	    	
	    	FormUtil.validateAll($scope.editForm, vm.formHandler.getRowList());
	    	
	    	if(FormUtil.validateField('Password', $scope.editForm, UcAlertService, $translate))
	    	{
	        	// Se a senha nova for diferente da confirmação, erro
	        	if(!obj.newPassword || obj.newPassword != obj.newPassword2)
	        	{
	        		UcAlertService.message($translate.instant('client.page.password.passwordConfirmationError'), "ERROR", "BOX");
	        	}
	        	else
	        	{
	    			isSaving = true;
	    			User.updatePassword(obj, saveCallback, saveCallback);
	        	}
	    	}
        };
        
        /*private*/ function saveCallback()
        {
        	isSaving = false;
        };
    }
})();
