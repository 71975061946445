(function(){
	'use strict';
	angular.module('sgmApp').controller('SalesRequestInvoiceEmailDialogController', SalesRequestInvoiceEmailDialogController);
	
	SalesRequestInvoiceEmailDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', '$translate', 'pageConfig','FormDomain', 'SalesRequestInvoice', 'userName', 'email'];
	
	function SalesRequestInvoiceEmailDialogController($scope, $uibModalInstance, $uibModal, $translate, pageConfig, FormDomain, SalesRequestInvoice, userName, email){
		
		var vm = this;
	
		vm.pageConfig = {
			pageMode: 'CREATE'
		};

		vm.entity = {
			userName: userName,
			email: email
		};

		var formOptions = {
				model: vm.entity,
				service: SalesRequestInvoice,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormSalesRequestInvoiceEmail; }
	        };
		
		vm.formHandler = FormDomain.create(formOptions)
			.add([
				new FormField({
					type: "String",
					id: "userName",
					required: true,
					cellCssClass: "col-sm-12",
					label: "client.page.salesRequestInvoiceEmailDialog.name",
				})
			])
			.add([
				new FormField({
					type: "String",
					id: "email",
					required: true,
					cellCssClass: "col-sm-12",
					label: "client.page.salesRequestInvoiceEmailDialog.email",
				})
			]);
		
		/*public*/ vm.closeModal = function(salesRequestInvoice)
		{
			$uibModalInstance.close(salesRequestInvoice);
        };

        vm.onSendEmail = function(){
        	var domain = null;
			FormUtil.validateAll($scope.editFormSalesRequestInvoiceEmail, vm.formHandler.getRowList());
			if($scope.editFormSalesRequestInvoiceEmail.$valid) {
				domain = vm.formHandler.readForm();
				console.log(domain);
				vm.closeModal(domain)
			}
        	
        }
	       
	}
})();
