(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('AgendaReminderDialogInvoker', AgendaReminderDialogInvoker);

    AgendaReminderDialogInvoker.$inject = ['$uibModal'];

    function AgendaReminderDialogInvoker($uibModal)
    {
		var vm = this;
		
		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/agenda/reminder/agenda-reminder-dialog.html',
				controller: 'AgendaReminderDialogController',
				controllerAs: 'vm',
				resolve: {
					pageConfig: ['$rootScope', function($rootScope)
					{
	                	return new PageConfig({
	                		pageMode: (p_param.id) ? "CUSTOM_SAVE" : "SAVE",
		                	title: 'client.page.attendance.remindersTitle',
		                	icon: $rootScope.menuIcons.managers.agenda.agendaReminder,
		                	formValidateTarget: 'AgendaReminder'
	                	})
	                }],
                    schedulableList: ['Attendance', function(Attendance)
                    {
                    	return Attendance.listSchedulable({}).$promise;
                    }],
                    repeatOptionsList: ['AgendaReminder', function(AgendaReminder)
                    {
                    	return AgendaReminder.listRemindersRepeatOptions({}).$promise;
                    }],
                    entity: ['AgendaReminder', function(AgendaReminder)
                    {
                    	// Edição?
                    	if(p_param.id)
                    	{
                    		return AgendaReminder.get({id: p_param.id}).$promise;
                    	}
                    	else
                    	{
                    		return {};
                    	}
                    }]
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();