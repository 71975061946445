(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('StateButtonDirectiveController', StateButtonDirectiveController);

    StateButtonDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$translate', '$rootScope', 'UcDatasourceService', 'UcAlertService', '$timeout', '$uibModal'];
    function StateButtonDirectiveController($scope, $state, $stateParams, $translate, $rootScope, UcDatasourceService, UcAlertService, $timeout, $uibModal)
    {
    	var vm = this;

    	var defaultParam = 
    	{
    		label: "-",
    		iconCssClass: "fa fa-check",
    		readOnly: false,
    		classCss: null,
    		onClick: null
    	};
    	
    	vm.model = $scope.model;
    	
    	vm.param = {};
    	angular.extend(vm.param, defaultParam);
    	angular.extend(vm.param, $scope.param);
    	    	
    	/*public*/ vm.resolveClass = function()
    	{
    		var classCss = "state-button";
    		if(vm.param.classCss)
    		{
    			classCss += " " + vm.param.classCss;
    		}
    		return classCss;
    	};
    	
    	/*public*/ vm.toogle = function(p_event)
    	{
    		$(p_event.currentTarget).blur();
    		
    		if(!vm.param.readOnly)
    		{
    			vm.model = !vm.model;
    			$scope.model = vm.model;
    		
    			$timeout(function()
    			{
    				if(vm.param.onClick)
    				{
    					vm.param.onClick();
    				}
    			}, 1);
    		}
    	};    	
    };
})();