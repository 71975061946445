(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceWhatsappModalController', AttendanceWhatsappModalController);

    AttendanceWhatsappModalController.$inject = ['$scope', 'pageConfig', '$uibModalInstance', '$rootScope', '$translate', '$timeout', 'entity', 'UcAlertService', 'FormDomain', 'Attendance', 'Client'];

    function AttendanceWhatsappModalController($scope, pageConfig, $uibModalInstance, $rootScope, $translate, $timeout, entity, UcAlertService, FormDomain, Attendance, Client)
    {
        var vm = this;
        
        vm.pageConfig = pageConfig;

        UcAlertService.setForceAlertType($scope);
        
        entity.whatsappNumberOriginal = entity.whatsappNumber;

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: null,
        	getForm: function(){ return $scope.editForm; },
        	afterUpdate: function() { $uibModalInstance.close(null); }
        };

        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
					cellCssClass: "col-sm-12",
		       		type: "String",
					id: "client",
					readOnly: true,
					label: "client.page.attendanceWhatsappDialog.client"
				})
		    ])
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Phone",
					id: "whatsappNumber",
					label: "client.page.attendanceWhatsappDialog.number",
					required: true,
					addonCssClass: "fa fa-whatsapp"
				})
		    ])
			.endConfig();
        
        /*public*/ vm.saveAndSent = function()
        {
        	// número obrigatório
        	if(!entity.whatsappNumber)
        	{
        		UcAlertService.error($translate.instant("client.page.attendanceWhatsappDialog.nullOrInvalidNumber"));
        	}
        	else
        	{
        		var value = entity.whatsappNumber;
        		var rawValue = "" + entity.whatsappNumber;
        		
        		
        		// Atualiza o número, caso se aplique
        		Client.updateWhatsAppNumber({id: entity.clientId, whatsapp: entity.whatsappNumber}, function(p_permissionFail)
        		{
        			if(p_permissionFail.value)
        			{
        				UcAlertService.info($translate.instant("client.page.attendanceWhatsappDialog.updateWhatsappPermissionFail"));
        			}
        			else
        			{
        				$uibModalInstance.close(rawValue);
        			}
        		});
        		
        		// Se não tem o 55, inclui
        		if(value.indexOf("55") != 0)
        		{
        			value = 55 + value;
        		}
        		Attendance.getAttendanceConfirmationMessage({id: entity.id}, function(p_messageDto)
        		{
        			var msg = $.trim((p_messageDto) ? p_messageDto.whatsapp : "");
        			msg = unescapeHtml(msg);
        			window.open("https://web.whatsapp.com/send?phone=" + value + "&text=" + encodeURI(msg), "_blank");
        		});	
        	}
        };
        
    	/*public*/ vm.toClipboard = function()
    	{
    		var value = entity.whatsappNumberOriginal;
    		// Remove a máscara, caso exista
    		if(value)
    		{
    			value = value.replace(/[^0-9]/g, '');
    			
    			// Limita em 8 caracteres
    			if(("" + value).length > 8)
    			{
    				value = value.substr(value.length - 8);
    			}
    		}
    		
    		copyToClipboard(value,
    			function()
    			{
    				UcAlertService.success($translate.instant("client.global.messages.info.contentCopiedToClipboard"));
    			},
    			function(p_errorMessage)
    			{
    				console.error("Error at copy message to clipboard: " + ((p_errorMessage) ? p_errorMessage : "-"));
    				UcAlertService.error($translate.instant("client.global.messages.info.failCopiedToClipboard"));
    			});

    	}

		/**
		 * Cancel or top-icon close
		 */
         /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();