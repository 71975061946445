(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('IndicationsCategoryDomainController', IndicationsCategoryDomainController);
    
    IndicationsCategoryDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'IndicationsCategoryService', 'pageConfig', '$rootScope', 'FormConstants'];

    function IndicationsCategoryDomainController(FormDomain, $scope, $state, entity, IndicationsCategoryService, pageConfig, $rootScope, FormConstants)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;

        $rootScope.initialFocus("code");

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "INDICATIONS_CATEGORY",
        	stateRoot: "indicationsCategory",
        	previousState: {name: $state.current.parent},
        	onClear: function() { vm.formHandler.clear(); }
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: IndicationsCategoryService,
        	historyConfig: {historyId: "INDICATIONS_CATEGORY"},
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "defaultDescription",
					required: true,
					label: "common.domain.indicationsCategory.name",
					maxLength: 100
				})
        	])			
			.endConfig();
        
        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        }
    }
})();
