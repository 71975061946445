(function(){
	'use strict';
	angular.module('sgmApp').controller('ProductKitItemDialogController', ProductKitItemDialogController);
	
	ProductKitItemDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'ProductKitItem', 'entity'];
	
	
	function ProductKitItemDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, ProductKitItem, entity){
		
		var vm = this;
		vm.pageConfig = pageConfig;
				
		if(!entity) {
			entity = {};
		}
		
		var formOptions = {
				model: entity,
				service: ProductKitItem,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormProductKitItem; }
	        };
		
		vm.formHandler = FormDomain.create(formOptions).
			add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.productKitItem.id",
					readOnly: true
				}),
			]).add([
				new FormField({
					type: "Select",
					id: "product",
					required: true,
					label: "client.page.productKit.tab.productKitItem.dialog.selectProduct",
					cellCssClass: "col-sm-6",
					dataSource: FormConstants.DataSources.BackEnd.Product(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Double",
					id: "amount",
					required: true,
					label: "common.domain.productKitItem.amount"
				})
			]);
		
		 /*public*/ vm.closeModal = function(productKitItem)
		 {
			$uibModalInstance.close(productKitItem);
        };
        
        vm.onSaveProductKitItem = function(){
        	
        	var domain = null;
			//if is save needs to validate and return the object
			console.log($scope.editFormProductKitItem);
			console.log(vm.formHandler.getRowList());
			FormUtil.validateAll($scope.editFormProductKitItem, vm.formHandler.getRowList());
			if($scope.editFormProductKitItem.$valid) {
				domain = vm.formHandler.readForm();
				vm.closeModal(domain);
			}
        	
        }
	       
	}
})();
