'use strict';

/**
 * Componente que encapsula o ag-grid customizado
 */
var UC_GRID_DEFAULT_PAGE_SIZE = 50;
var UC_GRID_DEFAULT_ROW_HEIGHT = 40;
function UcGrid(UcGridService, $ngBootbox, $translate, $rootScope, UcDatasourceService, UcAlertService, $state, p_paramsJson, $timeout, GridConstants, $window) {
	var self = this;

	var gridFieldList = p_paramsJson.gridFieldList;
	var sort = p_paramsJson.sort;
	var pageSize = p_paramsJson.pageSize;

	// Se não definido, usa o default
	if (!pageSize) {
		pageSize = UC_GRID_DEFAULT_PAGE_SIZE;
	}

	var amountItems = 0;
	// Adiciona os botões, caso se aplique
	handleGridFieldList(gridFieldList);
	var rows = [];
	var gridOptions =
	{
		gridWrapperId: "gwrap_" + (p_paramsJson.gridWrapperId ? p_paramsJson.gridWrapperId : p_paramsJson.gridId),
		showGrid: false,
		paginationPageSize: p_paramsJson.autoAmount ? null : pageSize,
		columnDefs: gridFieldList,
		enableServerSideSorting: true,
		enableServerSideFilter: true,
		enableColResize: true,
		virtualPaging: true, // this is important, if not set, normal paging will be done
		rowModelType: 'virtual',
		groupHeaderHeight: 40,
		headerHeight: 40,
		rowStyle: {lineHeight: (p_paramsJson.rowHeight ? p_paramsJson.rowHeight : UC_GRID_DEFAULT_ROW_HEIGHT) + "px"},
		rowHeight: (p_paramsJson.rowHeight ? p_paramsJson.rowHeight : UC_GRID_DEFAULT_ROW_HEIGHT),
		suppressDragLeaveHidesColumns: true,
		overlayLoadingTemplate: '<div class="uc-grid-loading"><div></div></div>',
		getRowClass: p_paramsJson.getRowClass,
		fetchResultTransform: p_paramsJson.fetchResultTransform,
		onRowClicked: function(p_data) {
			if (p_paramsJson.beforeOnRowClicked) {
				if (!p_paramsJson.beforeOnRowClicked(p_data)) {
					return
				}
			}

			if (p_paramsJson.onRowClicked) {
				p_paramsJson.onRowClicked(p_data);
			}
			else {
				defaultOnRowClicked(p_data);
			}
		},
		onGridReady: function(gridOptions) {
			console.log(p_paramsJson);
			gridOptions.api.setSortModel([
				{
					colId: sort.name, sort: sort.type
				}]);
			self.refresh(true);

			$rootScope.$broadcast(GridConstants.Events.OnGridReady + ":" + p_paramsJson.gridId);

			$timeout(function() {
				// Não se aplic mais pois só exibe após chamar o refresh (botão filttar) 
				//refreshGridHeight();

				// TODO: Otimizar!
				angular.element($window).bind('resize', function() {
					refreshGridHeight();

					$rootScope.$digest();
				});

			}, 100);
		}
	};

	if (p_paramsJson.onViewportChanged) {
		gridOptions.onViewportChanged = function() {
			p_paramsJson.onViewportChanged();
		};
	};


	/*public*/ this.refresh = function(p_isLoad) {
		var isToShowGrid = (!p_isLoad || p_paramsJson.startOnLoad);

		// Verifica se está alternando para exibir
		var toogleToShow = (!gridOptions.showGrid && isToShowGrid);
		amountItems = 0;
		rows = [];
		gridOptions.showGrid = isToShowGrid;
		gridOptions.api.setDatasource(new UcGridDatasource(self, isToShowGrid));

		if (toogleToShow) {
			$timeout(function() {
				refreshGridHeight();
			}, 1);
		}
	};


	/*public*/ this.getGridOptions = function() {
		return gridOptions;
	};

	/*private*/ function doQuery(p_params, p_pageParams) {
		gridOptions.api.showLoadingOverlay();
		gridOptions.gridStateFlag = GridConstants.State.Loading;

		// Adiciona o id do grid
		p_pageParams["gridId"] = p_paramsJson.gridId;



		/**
		 * Filtro antigo e genérico onde todas os grids chamam a mesma API passando o gridId		 *
		 */
		// Adiciona o filtro externo
		var externalFilter = {};
		if (p_paramsJson.getFilterData) {
			externalFilter = p_paramsJson.getFilterData();
		}
		angular.extend(p_pageParams, externalFilter);
		
		/**
		 * IMPORTANTE: Nova implementação aceita os dados de uma API externa, possibilitando alimentar o grid de uma API
		 * diferente do original.
	     *
		 * Lembrando que o retorno da API precisa ser compatível com o Pageable utilizado par anão quebrar o grid	
		 */
		if (p_paramsJson.gridData) {
			/**
			 * Chama o callback passando os parâmetros padrão para que se possa fazer o controle de paginação de fora do serviço	
			 */
			p_paramsJson.gridData(p_pageParams).$promise.then(function(p_data) {
					amountItems += p_data.content.length;
					if(p_paramsJson.fetchResultTransform) {
						p_data = p_paramsJson.fetchResultTransform(p_data);
					}
					
					if(p_paramsJson.autoAmount) {
						rows = rows.concat(p_data.content);
						var pageLastIndex = p_params.endRow;
						var totalElements = null;
						if(pageLastIndex > rows.length) {
							pageLastIndex = rows.length;
							totalElements = rows.length;
						}
						var pageContent = rows.slice(p_params.startRow, pageLastIndex);
						p_params.successCallback(pageContent, totalElements);
					} else {
						p_params.successCallback(p_data.content, p_data.totalElements);
					}
					
					
					

					$rootScope.$broadcast(GridConstants.Events.AfterFetchOnServer + ":" + p_paramsJson.gridId, p_data.content);

					gridOptions.gridStateFlag = GridConstants.State.Complete;

					gridOptions.api.sizeColumnsToFit();
					gridOptions.api.hideOverlay();
					
					
			});
		} else {
			// Implementação antiga: Onde toda chamada é feita pela API 
			UcDatasourceService.fetch(
				p_pageParams,
				function(p_data) // Ok!
				{
					if(p_paramsJson.fetchResultTransform) {
						p_data = p_paramsJson.fetchResultTransform(p_data);
					}
					p_params.successCallback(p_data.content, p_data.totalElements);
					$rootScope.$broadcast(GridConstants.Events.AfterFetchOnServer + ":" + p_paramsJson.gridId, p_data.content);

					gridOptions.gridStateFlag = GridConstants.State.Complete;

					gridOptions.api.sizeColumnsToFit();
					gridOptions.api.hideOverlay();
				},
				function() // Fails
				{
					gridOptions.api.hideOverlay();
					p_params.failCallback();
				}
			);
		}

	};

	/*private*/ function handleGridFieldList(p_gridFieldList) {
		/*
		 * Adiciona o botão de delete, caso tenha permissão
		 * [0] - Edição
		 * [1] - Delete
		 */
		//		var permissions = checkPermissions();
		//		
		//		// EDIT
		//		if(permissions[0])
		//		{
		//			p_gridFieldList.push(UcGridService.button.ACTION(
		//			{
		//				btnClass: "btn-success",
		//				fontIcon: "glyphicon glyphicon-pencil",
		//				hint: "client.global.label.editRecord",
		//				action: function(p_row)
		//				{
		//					$state.go(p_paramsJson.stateBaseName + '.edit', {id: p_row.data.id});
		//				}
		//			}));
		//		}
		//		// DELETE
		//		if(permissions[1])
		//		{
		//			p_gridFieldList.push(UcGridService.button.ACTION(
		//			{
		//				btnClass: "btn-danger",
		//				fontIcon: "glyphicon glyphicon-remove",
		//				hint: "client.global.label.deleteRecord",
		//				action: function(p_row)
		//				{
		//					var message = $translate.instant("client.global.messages.info.deleteRecordP0", {item: ":p0"}) + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
		//	
		//					// Atribui o valor por replace porque o parametro do $translate está escaping
		//					message = message.replace(":p0", UcUtil.b(p_row.data.defaultDescription));
		//					$rootScope.confirm(
		//					{
		//						message: message,
		//						callback: function()
		//						{
		//							UcDatasourceService.delete(
		//							{
		//								id: p_row.data.id,
		//								gridId: p_paramsJson.gridId
		//							},
		//							function(p_customMessage)
		//							{
		//								// custom message nula, usa a default
		//								var successMessage = (p_customMessage.value) ? p_customMessage.value : $translate.instant("client.global.messages.info.operationSuccessfuly");
		//								UcAlertService.success(successMessage);
		//								self.refresh();
		//							});
		//						} 
		//					});
		//				}
		//			}));
		//		}
		//
		//		// Botão de detalhes do item
		//		if(p_paramsJson.detailsIcon)
		//		{
		//			p_gridFieldList.splice(0, 0, UcGridService.button.ACTION(
		//			{
		//				btnClass: "btn-primary",
		//				fontIcon: "fa fa-info-circle",
		//				hint: "client.global.label.recordDetails",
		//				action: function(p_row)
		//				{
		//					$state.go(p_paramsJson.stateBaseName + '.detail', {id: p_row.data.id});
		//				}
		//			}));
		//		}
	};

	/*private*/ function defaultOnRowClicked(p_row) {
		$state.go(p_paramsJson.stateBaseName + '.detail', { id: p_row.data.id });
	};

//	// Checa se o usuário tem permissão para as operações de delete e edit
//	/*private*/ function checkPermissions()
//	{
//		var hasPermissions = [false, false];
//		
//		// Tem que ter usuário logado
//		if(p_paramsJson.account && p_paramsJson.account.login)
//		{
//			// Tem que ter permissão definida
//			if(p_paramsJson.permission)
//			{
//				var permissions = [null, null];
//				
//				if(angular.isArray(p_paramsJson.permission))
//				{
//					permissions[0] = p_paramsJson.permission[0];
//					permissions[1] = p_paramsJson.permission[1];
//				}
//				else
//				{
//					permissions[0] = permissions[1] = p_paramsJson.permission;
//				}
//				// Procura as permissões
//				for(var i = 0 ; i < 2 ; i++)
//				{
//					hasPermissions[i] = userHasPermission(permissions[i]);
//				}
//			}
//		}
//		return hasPermissions;
//	}
//	/*private*/ function userHasPermission(p_permission)
//	{
//		if(p_paramsJson.account && p_paramsJson.account.authorities)
//		{
//			var arr = p_paramsJson.account.authorities;
//			for(var i = 0 ; i < arr.length ; i++)
//			{
//				if(arr[i] == p_permission)
//				{
//					return true;
//				}
//			}
//		}
//		return false;
//	}

	/**
	 * Atualiza a altura do grid de acordo com o tamanho da página
	 */
	/*private*/ function refreshGridHeight() {
		var divId = "#" + gridOptions.gridWrapperId;
		var gridHeight = GridConstants.Height.DefaultGridHeight;

		try {
			// Se o grid não estiver visível, nada faz
			if (!$(divId).is(":visible")) {
				return;
			}

			// Pega a distância do grid para o começo da página
			var offsetTop = $(divId).offset().top;
			var documentHeight = $(document).height();

			gridHeight = documentHeight - offsetTop - GridConstants.Height.FooterHeight - GridConstants.Height.AdjustGridHeightGap;
		}
		catch (eee) {
			console.error("Error at refreshGridHeight: " + eee.message);
		}
		if (p_paramsJson.responsiveHeight) {
			if (!gridHeight) {
				gridHeight = GridConstants.Height.DefaultGridHeight;
			}
			else {
				gridHeight = Math.max(gridHeight, GridConstants.Height.DefaultGridHeight);
			}
			var gridDiv = document.querySelector(divId);
			gridDiv.style.height = gridHeight + "px";
		}

		gridOptions.api.sizeColumnsToFit();
		gridOptions.api.hideOverlay();
	};

	// Registra o listener do evento
	$rootScope.$on(GridConstants.Events.RefreshGrid, function(p_event, p_gridId) {
		$timeout(function() {
			refreshGridHeight();
		}, 2000);
	});

	this.getSortParams = function() {
		return {
			sort: convertSort(gridOptions.api.getSortModel())
		};
	}

	/*public*/ function convertSort(sortModel) {
		if (sortModel && sortModel.length > 0) {
			return sortModel[0].colId + "," + sortModel[0].sort;
		}
		return null;
	};

    /*private*/ function UcGridDatasource(p_grid, p_isToShowGrid) {
		var self = this;

    	/*public*/ this.getRows = function(p_params) {
			if (p_isToShowGrid) {
				var v_pageParams = createPageParams(p_params);
				doQuery(p_params, v_pageParams);
			}
		};

		

		/*private*/ function convertPage(p_params) {
			return p_paramsJson.autoAmount ? ((amountItems / pageSize) + 1) : ((p_params.startRow / pageSize) + 1);
		};

		/*private*/ function createPageParams(p_params) {
			return {
				page: convertPage(p_params),
				sort: convertSort(p_params.sortModel),
				size: pageSize
			};
		};
	};
};
