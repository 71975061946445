(function() {
	'use strict';
	angular
		.module('sgmApp')
		.factory('IndicationsCategoryService', IndicationsCategoryService);

	IndicationsCategoryService.$inject = ['$resource', 'URL', 'SERVICES'];

	function IndicationsCategoryService($resource, URL, SERVICES) {
		var url = URL[SERVICES.environment].api;
		var resourceUrl = url + 'api/indications-category/:id';

		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true },
			'get': {
				method: 'GET',
				transformResponse: function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update': { method: 'PUT' },
			'listAll': { method: 'GET', url: url + 'api/indications-category/list-all' },
			// Retorna um Array para os campos select
			'listAllInList': { method: 'GET', url: url + 'api/indications-category/list-all-in-list', isArray: true }
		});
	}
})();
