(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('AttendanceConfirmationScheduleDialogController', AttendanceConfirmationScheduleDialogController);

	AttendanceConfirmationScheduleDialogController.$inject = ['$scope', '$filter', '$uibModalInstance', 'FormDomain', 'Agenda', 'dataScheduling', '$translate', '$rootScope', '$timeout', 'FormConstants', 'rescheduleAttendance', 'UcAlertService', 'BackEndSelectDataSource'];

	function AttendanceConfirmationScheduleDialogController($scope, $filter, $uibModalInstance, FormDomain, Agenda, dataScheduling, $translate, $rootScope, $timeout, FormConstants, rescheduleAttendance, UcAlertService, BackEndSelectDataSource) {
		var LIST_DURATION = [
                { value: 10, name: "10 min" },
                { value: 15, name: "15 min" },
                { value: 20, name: "20 min" },
                { value: 30, name: "30 min" },
                { value: 40, name: "40 min" },
                { value: 45, name: "45 min" },
                { value: 50, name: "50 min" },
                { value: 55, name: "55 min" },
                { value: 60, name: "1 hora" },
                { value: 70, name: "1 hora e 10 min" },
                { value: 75, name: "1 hora e 15 min" },
                { value: 80, name: "1 hora e 20 min" },
                { value: 90, name: "1 hora e 30 min" },
                { value: 105, name: "1 hora e 45 min" },
                { value: 120, name: "2 horas" },
                { value: 150, name: "2 horas e 30 min" },
                { value: 180, name: "3 horas" },
                { value: 195, name: "3 horas e 15 min" },
                { value: 210, name: "3 horas e 30 min" },
                { value: 240, name: "4 horas" },
                { value: 270, name: "4 horas e 30 min" },
                { value: 300, name: "5 horas" },
                { value: 330, name: "5 horas e 30 min" },
                { value: 360, name: "6 horas" },
                { value: 390, name: "6 horas e 30 min" },
                { value: 420, name: "7 horas" },
                { value: 450, name: "7 horas e 30 min" },
                { value: 480, name: "8 horas" },
                { value: 510, name: "8 horas e 30 min" }
            ];
		var DEFAULT_VALUE = { value: 40, name: "40 min" };

		var vm = this;
		vm.dataScheduling = dataScheduling;

		vm.startDateFieldModel = new FormField({
			type: "Date",
			id: "startDate",
			noLabel: true,
			required: true,
			style: "border-radius:6px",
			readOnly: dataScheduling.changeDuration ? dataScheduling.changeDuration : false
		});

		let familyProcedureCriteria = {};

		familyProcedureCriteria["therapyPlaceCode"] = "";

		if(vm.dataScheduling.schedulableType == "THERAPY"){
			familyProcedureCriteria.therapyPlaceCode = vm.dataScheduling.idResource;
		}

		vm.familyProcedureFieldModel = new FormField({
        	formTarget: "vm.formConfirmationSchedule",
       		type: "Select",
			id: "familyProcedure",
			required: true,
			dependencyName: "familyProcedureListId",
			label: "client.page.attendance.familyProcedure",
			dataSource: FormConstants.DataSources.BackEnd.FamilyProcedure(BackEndSelectDataSource, $translate, familyProcedureCriteria),
    	});

		vm.procedureFieldModel = new FormField({
			formTarget: "vm.formConfirmationSchedule",
			type: "Select",
			id: "procedure",
			required: true,
			label: "client.page.attendance.procedure",
			searchActiveOnly: true,
			onChange: function(p_objModel, p_fieldModel, p_value)
			{
				var duration;
				if(p_value.defaultDurationTime) {
					duration = LIST_DURATION.find(obj => ""+obj.value == p_value.defaultDurationTime.id)
				}
				vm.dataScheduling.selectDuration = duration || DEFAULT_VALUE;
			},
			dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, vm.familyProcedureFieldModel, null, true)
		});

		vm.pageConfig = new PageConfig({
			pageMode: "EDIT"
		});

		
		vm.listDuration = LIST_DURATION;
		vm.dataScheduling.startDate = $filter('date')(vm.dataScheduling.startDate, 'dd/MM/yyyy');
		vm.dataScheduling.selectDuration = DEFAULT_VALUE;

		// Hora 00:00, deixar vazio
		if (vm.dataScheduling.startTime == "00:00") {
			vm.dataScheduling.startTime = "";
		}


		vm.rescheduleAttendance = rescheduleAttendance;

		function parseTime(s) {
			var c = s.split(':');
			return parseInt(c[0]) * 60 + parseInt(c[1]);
		}

		vm.isTimeBlock = false;
		//        if (vm.dataScheduling.startTime !== '') {
		//            vm.isTimeBlock = true;
		//		}

		// Edição (Reagendamento)
		if (vm.dataScheduling.startTime !== '' && vm.dataScheduling.endTime !== '') {
			var duration = parseTime(vm.dataScheduling.endTime) - parseTime(vm.dataScheduling.startTime);
			vm.dataScheduling.selectDuration = { value: duration, name: duration };
			vm.selectDurationEmpty = false;
		}

		// Foco inicial no input de hora
		$timeout(function() {
			$("#startTime").focus();
		}, 100)

		/*public*/ vm.reschedulingAttendanceLabel = function() {
			var text = $translate.instant("client.page.attendance.reschedulingAttendance", { client: "$client$", date: "$date$", procedure: "$procedure$" });
			try {
				text = text.replace("$client$", "<div class='text-accent display-inline-block'>" + rescheduleAttendance.salesRequest.client.defaultDescription + "</div>")
				text = text.replace("$date$", "<div class='text-accent display-inline-block'>" + rescheduleAttendance.plannedDateFormatted + "</div>")
				text = text.replace("$procedure$", "<div class='text-accent display-inline-block'>" + rescheduleAttendance.salesRequest.procedure.defaultDescription + "</div>")
			}
			catch (exc) { console.error(exc.message); }
			return $rootScope.toTrustedHtml(text);
		}

		/*public*/
		vm.closeModal = function() {
			$uibModalInstance.close();
		};

		var timeStringToMin = function(time) {
			if (angular.isUndefined(time) || time === 0) {
				return 0;
			}
			else {
				var hoursMinutes = time.split(':');
				var hours = parseInt(hoursMinutes[0], 10);
				var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
				return hours * 60 + minutes;
			}
		};

		function validateSchedulesAvailable(day, startTime, endTime) {
			var auxList = _.filter(vm.dataScheduling.listDaysWeek, function(e) { return e.weekDay === day; });

			var result = false;
			if (auxList.length > 0) {
				var startTime = timeStringToMin(startTime);
				var endTime = timeStringToMin(endTime);

				_.each(auxList, function(l) {
					if (startTime >= timeStringToMin(l.startTime) && endTime <= timeStringToMin(l.endTime)) {
						result = true;
					}
				});
			}

			return result;
		};


        /*public*/ vm.save = function() {
			if (!vm.formConfirmationSchedule.$valid) {
				return false;
			}

			if (vm.dataScheduling.selectDuration.value === '') {
				vm.selectDurationEmpty = true;
				return false;
			}

			checkConflict();
		}

        /*public*/ vm.checkOutOfTime = function() {
			var startTime = calculateStartTime();
			var endTime = calculateEndTime(startTime);

			vm.outTime = false;
			if (startTime && endTime && !validateSchedulesAvailable(moment(vm.dataScheduling.startDate, 'DD/MM/YYYY').day(), vm.dataScheduling.startTime, endTime.split(' ')[1])) {
				vm.outTime = true;
			}
		}

        /*private*/ function calculateStartTime() {
			if (!vm.dataScheduling.startDate || !vm.dataScheduling.startTime) {
				return null;
			}
			else {
				return moment(vm.dataScheduling.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') + ' ' + vm.dataScheduling.startTime + ':00';
			}
		}

        /*private*/ function calculateEndTime(p_startTime) {
			if (!p_startTime || !vm.dataScheduling.selectDuration || !vm.dataScheduling.selectDuration.value) {
				return null;
			}
			else {
				var endTime = moment(p_startTime).add('m', vm.dataScheduling.selectDuration.value);
				return moment(endTime).format('YYYY-MM-DD HH:mm:ss');
			}
		}

		function execute(startTime, endTime) {
			console.log(vm.dataScheduling);
			$uibModalInstance.close({
				attendanceInput:
				{
					schedulableId: vm.dataScheduling.idResource,
					start: startTime,
					end: endTime,
					familyProcedureId: vm.dataScheduling.familyProcedure ? vm.dataScheduling.familyProcedure.id : null,
					procedureId: vm.dataScheduling.procedure ? vm.dataScheduling.procedure.id : null,
				}
			});
		}

		function checkConflict() {
			var startTime = calculateStartTime();
			var endTime = calculateEndTime(startTime);

			if (!startTime || !endTime) {
				UcAlertService.error($translate.instant("client.page.attendance.dateOrDurationNullOrInvalid"));
				return;
			}

			var paramId = vm.dataScheduling.idSchedule === 0 ? null : vm.dataScheduling.idSchedule;

			// Para reagendamento
			if (vm.rescheduleAttendance && vm.rescheduleAttendance.id) {
				paramId = vm.rescheduleAttendance.id;
			}

			// Para alteração da duração do atendimento
			if (vm.dataScheduling.changeDuration) {
				paramId = vm.dataScheduling.idAttendance;
			}

			var param = {
				id: paramId,
				schedulingType: "A",
				procedurePlaceId: null,
				schedulableId: vm.dataScheduling.idResource,
				startDate: startTime,
				endDate: endTime,
				fullDay: false
			};
			Agenda.checkScheduling(param,
				function(p_feedback) {
					if (p_feedback.ok) {
						execute(startTime, endTime);
					}
					else {
						var message = $translate.instant("client.page.attendance.currentMarkingIsInConflictWithEventsBelow");
						message += "<ul>";
						for (var i = 0; i < p_feedback.conflictList.length; i++) {
							if (p_feedback.conflictList) {
								for (var i = 0; i < p_feedback.conflictList.length; i++) {
									message += '<li>' + p_feedback.conflictList[i] + '</li>';
								}
							}
						}
						if(!p_feedback.hasAttendanceAtDate) {
							message += "</ul>" + $translate.instant("client.global.messages.info.confirmOperation")
						}
						$rootScope.confirm(
							{
								message: message,
								hideSuccessButton: p_feedback.hasAttendanceAtDate,
								callback: function() {
									execute(startTime, endTime);
								}
							});
					}
				});
		}


		vm.clearTime = function() {
			vm.dataScheduling.startTime = '';
		};
	}
})();
