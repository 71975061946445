(function() {
    'use strict';

	angular.module('sgmApp').service('FormDomain', FormDomain);
	
	FormDomain.$inject = ['FormHandler', 'UcAlertService', 'FormFieldValidate', '$state', '$translate', '$rootScope', 'FormConstants', '$timeout'];
	
	function FormDomain(FormHandler, UcAlertService, FormFieldValidate, $state, $translate, $rootScope, FormConstants, $timeout)
	{
		var vm = this;
		
		vm.create = function(p_formOptions)
		{
			return new _t(p_formOptions);
		};
		
		function _t(p_formOptions)
		{
			var self = this;
			
	        var defaultFormOptions = {
	        	model: null,							// model do Domain
	        	pageConfig: null,						// pageConfig
	        	buttonsModel: null,						// Para dar o "lock" nos botões enquanto salva
	        	service: null,							// Serviço rest do Angular para a operação de dave
	        	getForm: null,							// Função que retorna o objeto form do angular
	        	onSaveSuccess: defaultOnSaveSuccess,	// Função que pode ser sobrescrita
	        	onSaveError: defaultOnSaveError,		// Função que pode ser sobrescrita
	        	afterCreateState: null,					// Rota setada explicitamente após a criação 
	        	beforeInvokeService: null,				// Função invocada antes de postar o JSon para o backend. Útil para adicionar parâmetros que não vêm do formulário
	        	showTabWithError: false,				// Alterna para a tab com erro (acso se aplique), após erro na validação do formulario,
	        	historyConfig: null,					// Configuração do modal de Histórico -> {id / },
				afterUpdateState: null,					// Rota setada explicitamente após update,
				extraFieldRenderer: null,				// Campo extra entre filtro e tabela
	        };
	        
	        var formOptions = defaultFormOptions;
			this.onSaveSuccessMethod = formOptions.onSaveSuccess;
	        angular.extend(formOptions, (p_formOptions) ? p_formOptions : {});
	        
	        var formValidateTarget = (defaultFormOptions.pageConfig) ? defaultFormOptions.pageConfig.formValidateTarget : null;
	        
			/*private*/ var model = (formOptions.model) ? formOptions.model : {};
			
			/*private*/ var formHandler = FormHandler.create(model, formOptions);
			
			// Cria a configuração para o histórico
			/*public*/ this.historyConfig = formOptions.historyConfig;
			if(this.historyConfig)
			{
				this.historyConfig.getId = function()
				{
					return (model) ? model.id: null;
				};
			}

			/**
			 * Método chamado ao fim da configuração
			 */
			/*public*/ this.endConfig = function()
			{
				return this;
			};
			
			/**
			 * Reabre a configuração (desfazer o que for feito no endConfig)
			 */
			/*public*/ this.reopenConfig = function()
			{
				return this;
			};
			
			/**
			 * DELEGATED METHODS
			 */
			/*public*/ this.getRowList = function()
    		{
    			return formHandler.getRowList();
    		}
			/*public*/ this.getModel = function()
    		{
    			return formHandler.getModel();
    		}
    		/*public*/ this.add = function(p_)
    		{
    			// Adiciona no formHandler 
    			formHandler.add(p_);
    			return this;
    		};
    		// @Override
    		/*public*/ this.clear = function()
    		{
    			formHandler.clear();
				
	   			// Tira a classe css do formulário (caso exista)
    			var form = getForm();
    			if(form)
    			{
    				if(form.ffvId)
    				{
    					$("#" + form.ffvId).removeClass("form-validator-form-error");
    				}

    				try
    				{
    					form.$setPristine();
    				}
    				catch(ee)
    				{
    					console.error("Error at call form.$setPristine(): " + ee.message)
    				}
    			}
    		};

    		/*public*/ this.readForm = function()
    		{
    			return formHandler.readForm();
    		};


			/*public*/ this.reloadValidation = function(formController)
    		{
    			formHandler.reloadValidation(formController);
    		};
    		
    		
    		/**
    		 * Adiciona o campo justifica, caso seja edição
    		 */
    		/*public*/ this.addJustificationField = function(p_formTarget)
    		{
    			var pageMode = (formOptions.pageConfig) ? formOptions.pageConfig.pageMode : null;
    			if(pageMode == "EDIT")
    			{
    				var field = new FormField({
    					formTarget: (p_formTarget) ? p_formTarget : null,
    	           		cellCssClass: "col-sm-12",
    					type: "TextArea",
    					id: "justification",
    					required: true,
    					label: "client.global.label.justification"
    				});
    				this.add(field);
    				
     				if(!formValidateTarget)
    				{
    					UcAlertService.error("FormHandler.addJustificationField for undefined pageConfig.formValidateTarget");
    				}
    			}
    			return this;
    		};
    		
    		/**
    		 * Procura um campo dentre os adicionados pelo id
    		 */
    		/*public*/ this.findField = function(p_fieldId)
    		{
    	        if(p_fieldId)
    	        {
	    	        var rowList = formHandler.getRowList();
	    	        var cellList;
	    	        for(var i = 0 ; i < rowList.length ; i++)
		        	{
	    	        	cellList = rowList[i];
	    	        	for(var j = 0 ; j < cellList.length ; j++)
	    	        	{
	    	        		if(cellList[j].id == p_fieldId)
	    	        		{
	    	        			return cellList[j];
	    	        		}
	    	        	}
		        	}
    	        }
    	        return null;
    		};
    		
       		/**
    		 * Chama a validação de todos os input's
    		 */
    		/*public*/ this.validateAll = function()
    		{
    			var form = getForm();
    			if(form)
    			{
    				FormUtil.validateAll(form, this.getRowList());
    			}
    		};
    		
    		/**
    		 * Função padrão para save: chama o pré-save com as validações.
    		 * 
    		 *  O defaultInternalSave pode ser sobrescrito pelo usuário
    		 */
    	    /*public*/ this.save = function(p_customSave)
    	    {
    	    	this.preSave((p_customSave) ? p_customSave : defaultInternalSave);
    	    };

			/*public*/ this.saveJson = function(p_json) {
				this.preSave(defaultInternalSave, null, p_json);
			}

    		/**
    		 * Faz as validações comuns e depois chama a função que faz o save efetivo
    		 */
    	    /*public*/ this.preSave = function(p_saveFunction, p_validadeFailFunction, p_json)
    	    {
    	    	var form = getForm();
    	    	if(!form)
    	    	{
    	    		throwCriticalError("[FormDomain.save] Form not defined");
    	    	}

    	    	UcAlertService.dismissBox();
    	    	
    	    	this.validateAll();
    	    	
    	    	if(FormUtil.validateField(formValidateTarget, form, UcAlertService, $translate))
    	    	{
    	    		if(!p_saveFunction)
    	    		{
    	    			throwCriticalError("[FormDomain.preSave] saveFunction not defined");
    	    		}
    	    		
    	    		var json = p_json ? p_json : this.readForm();
    	    		p_saveFunction(json, formOptions.onSaveSuccess, formOptions.onSaveError);
    	    	}
    	    	else
    	    	{
    	    		if(p_validadeFailFunction)
    	    		{
    	    			p_validadeFailFunction();
    	    		}
    	    		else
    	    		{
    	    			onValidadeFail();
    	    		}
    	    	}
    	    };
    	    
    	    /**
    	     * Função default para o save efetivo
    	     */
    	    /*private*/ function defaultInternalSave(p_json)
    	    {
	    		if(p_formOptions.buttonsModel)
	    		{
	    			p_formOptions.buttonsModel.isSaving = true;
	    		}
	    		else
	    		{
	    			// buttonsModel not defined
	    		}
	    		
	    		var service = p_formOptions.service;
	    		if(!service)
	    		{
	    			throwCriticalError("[FormDomain.save] Service not defined");
	    		}
	    		
	    		// Chama a função, caso seja definida
	    		if(p_formOptions.beforeInvokeService)
	    		{
	    			p_formOptions.beforeInvokeService(p_json);
	    		}
	    		if (p_json.id)
	    		{
	    			service.update(p_json, formOptions.onSaveSuccess, formOptions.onSaveError);
	            }
	    		else
	    		{
	    			service.save(p_json, formOptions.onSaveSuccess, formOptions.onSaveError);
	            }
    	    };

     		/*private*/ function getForm()
    		{
    			return (formOptions.getForm) ? formOptions.getForm() : null
    		};

    		/*private*/ function onValidadeFail()
    		{
    			if(formOptions.showTabWithError)
    			{
    				/*
    				 * Recupera a tab com erro, caso se aplique
    				 * Ver comentário do método
    				 */
    				$timeout(function()
    				{
    					// O timeout é para dar tempo das classes serem adicionadas no DOM
        				var errorTab = findTabWithError();
        				if(errorTab)
        				{
        					$rootScope.$broadcast(FormConstants.Events.Runtime.ShowTabError, errorTab);
        				}
    				}, 200);
    			}
    		}
    		
    		/******************************************************************************/


    		/*private*/ function defaultOnSaveSuccess(p_result, p_headers)
    		{
    			if(p_formOptions.buttonsModel)
    			{
    				p_formOptions.buttonsModel.isSaving = false;
    			}
    			else
    			{
    				// buttonsModel not defined
    			}
    			
    			// Limpa o campo Justificativa, caso exista
    			if(p_formOptions.model && p_formOptions.model.justification)
    			{
    				p_formOptions.model.justification = null;
    			}
    	    	
    	    	// Se for criação, o id da nova entidade virá setado: encaminha para a página de detalhes
    	    	var newEntityId = (p_headers) ? p_headers('X-UC-New-Entity-Id') : null;
    	    	if(newEntityId)
    	    	{
    	    		var stateTarget = $state.current.parent + ".detail";
    	    		if(p_formOptions.afterCreateState != null)
    	    		{
    	    			stateTarget = p_formOptions.afterCreateState;
    	    		}
    	    		$state.go(stateTarget, {id: newEntityId});
    	    	}
    	    	else
    	    	{
    	    		// Se tiver um handler definido, utiliza
    	    		if(p_formOptions.afterUpdate)
    	    		{
    	    			p_formOptions.afterUpdate();
    	    		}
					if(p_formOptions.afterUpdateState) 
					{
						$state.go(stateTarp_formOptions.afterUpdateStateget);
					}
    	    	}
    		};
    		
    		/*private*/ function defaultOnSaveError()
    		{
    			if(p_formOptions.buttonsModel)
    			{
    				p_formOptions.buttonsModel.isSaving = false;
    			}
    			else
    			{
    				// buttonsModel not defined
    			}
    		};


		};
	};
})();