(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceProductTabController', AttendanceProductTabController);
    
        AttendanceProductTabController.$inject = ['FormDomain', '$scope', '$state', 'Attendance', 'AttendanceProduct', 'PrintAttendanceProductDialogInvoker', 'AttendanceProductDialogInvoker', '$rootScope', 'FormConstants', '$translate', 'UcAlertService', 'Principal', 'PermissionSet', '$timeout', 'ConfirmDialogInvoker', 'DownloadUtil'];

    function AttendanceProductTabController(FormDomain, $scope, $state, Attendance, AttendanceProduct, PrintAttendanceProductDialogInvoker, AttendanceProductDialogInvoker, $rootScope, FormConstants, $translate, UcAlertService, Principal, PermissionSet, $timeout, ConfirmDialogInvoker, DownloadUtil)
    {
        var vm = $scope.vm;
        vm.canEditConfirmedAttendanceProduct = Principal.hasAnyAuthority(['ROLE_FARMACIA_ADMINISTRACAO']);
        var attendance = vm.domain;
        vm.hasOpenAttendanceProduct = false;
        
        function splitAttendanceProductSet(attendanceProductSet) {

            if(attendanceProductSet) {
                vm.attendanceProductsManual = attendanceProductSet.filter((res) => !res.autoGenerated) || [];
                vm.attendanceProductsAutoGenerated = attendanceProductSet.filter((res) => res.autoGenerated) || [];
            }
            checkOpenAttendanceProduct(attendanceProductSet);
        }

        function checkOpenAttendanceProduct(attendanceProductSet) {
            vm.hasOpenAttendanceProduct = false;
            if(attendanceProductSet) {
                for(var i = 0; i < attendanceProductSet.length && !vm.hasOpenAttendanceProduct; i++) {
                    var attendanceProduct = attendanceProductSet[i];
                    vm.hasOpenAttendanceProduct = !attendanceProduct.finalTotalExpectedAmount;
                }
            }
        }

       
        vm.generateAttendanceProductsReport = function()
        {          
            if(vm.domain.id)
                DownloadUtil.download('api/attendance/generate-attendance-products-report/' + vm.domain.id);
        };


        vm.generateAttendanceProductsPdf = function()
        {          
            if(vm.domain.id && vm.domain.totalProductsCost) {
                PrintAttendanceProductDialogInvoker.invoke({
                    attendances: vm.domain,
                    accountName: vm.account.name
                });
            }
        };


        splitAttendanceProductSet(attendance.attendanceProductSet);

        /*checkIfFieldsValid(attendanceProduct) {
            if(attendanceProduct.product.active && 
                (attendanceProduct.productKit == null || attendanceProduct.productKit.active || attendanceProduct.expectedAmount >= 0)
                    ) {

                    }
        }*/

        vm.confirmAttendanceProducts = function() {
            if(vm.productTabIsReadonly) {
                return;
            }
            ConfirmDialogInvoker.invoke(
                {
                    callback: function(res) { 
                        if(res) {
                            callbackConfirmAttendanceProducts();
                        } 
                    }, 
                    text: $translate.instant("client.page.attendance.tabs.attendanceProduct.confirmProductsDialog.text")
                }
            );
           
        }
        
        function callbackConfirmAttendanceProducts() {
            Attendance.confirmAttendanceProducts({id: attendance.id}, function(res, putResponseHeaders) {
                console.log(res);
                attendance.totalProductsCost = res.value;
                updateAttendanceProductsSet()
            });
        }

        function updateAttendanceProductsSet() {
            AttendanceProduct.getByAttendanceId({attendanceId: attendance.id}, function(res, putResponseHeaders) {
                console.log(res);
                attendance.attendanceProductSet = res;
                splitAttendanceProductSet(attendance.attendanceProductSet);
            });
        }

        vm.formatToCurrency = function(value) {
            return "R$ " + $rootScope.formatMoney(value || 0);
        }

        vm.refreshAutomaticProcedureProducts = function(){
            if(vm.productTabIsReadonly) {
                return;
            }
            if(vm.attendanceProductsAutoGenerated.length > 0) {
                ConfirmDialogInvoker.invoke(
                    {
                        callback: function(res) { 
                            if(res) {
                                callRefreshAutomaticProcedureProducts();
                            } 
                        }, 
                        text: $translate.instant("client.page.attendance.tabs.attendanceProduct.refreshDialog.text")
                    }
                );
            } else {
                callRefreshAutomaticProcedureProducts();
            }
        }

        
        function callRefreshAutomaticProcedureProducts() {
            Attendance.refreshAutomaticProcedureProducts({id: attendance.id}).$promise.then(function(data){
    			console.log(data);
        		attendance.attendanceProductSet = data;
        		splitAttendanceProductSet(attendance.attendanceProductSet);
        	});
        }


        function saveAddAttendanceProduct(attendanceProduct){
        	if(attendanceProduct){
                attendanceProduct.attendanceId = attendance.id;
                if(!attendanceProduct.id) {
                    AttendanceProduct.save(attendanceProduct, function(res, putResponseHeaders) {
                        attendance.attendanceProductSet.push(res);
                        splitAttendanceProductSet(attendance.attendanceProductSet);
                    })
                }
        	}
        }

        function saveUpdateAttendanceProduct(attendanceProduct){
        	if(attendanceProduct){
                attendanceProduct.attendanceId = attendance.id;
                AttendanceProduct.update(attendanceProduct, function(res, putResponseHeaders) {
                    const index = attendance.attendanceProductSet.findIndex((obj) => obj.id == attendanceProduct.id);
                    if(index > -1) {
                        attendance.attendanceProductSet[index] = res;
                        splitAttendanceProductSet(attendance.attendanceProductSet);
                    }
                });
        	}
        }


        vm.editAttendanceProduct = function(attendanceProduct, index, autoGenerated){
            if(vm.productTabIsReadonly) {
                return;
            }
            var attendanceProductList;
            if(autoGenerated) {
                attendanceProductList = vm.attendanceProductsAutoGenerated;
            } else {
                attendanceProductList = vm.attendanceProductsManual;
            }
        	AttendanceProductDialogInvoker.invoke({
                save: function(res) { 
                    saveUpdateAttendanceProduct(res)
                }, 
                entity: attendanceProduct, 
                isUpdate: true, 
                index: index,
                length: attendanceProductList.length,
                callback: function(next) {
                    if(next == -1 || next == 1) {          
                        vm.editAttendanceProduct(attendanceProductList[index + next], index + next, autoGenerated);
                    }
                }
            });
        }

        vm.attendanceProductNotUsed = function(attendanceProduct) {
            if(attendanceProduct.usedAmount > 0) {
                ConfirmDialogInvoker.invoke(
                    {
                        callback: function(res) { 
                            if(res) {
                                attendanceProduct.usedAmount = 0;
                                saveUpdateAttendanceProduct(attendanceProduct);
                            } 
                        }, 
                        text: $translate.instant("client.page.attendance.tabs.attendanceProduct.confirmNotUsedProductsDialog")
                    }
                );
            } else {
                attendanceProduct.usedAmount = 0;
                saveUpdateAttendanceProduct(attendanceProduct);
            }
        }

        vm.addAttendanceProduct = function(){
            if(vm.productTabIsReadonly) {
                return;
            }
        	AttendanceProductDialogInvoker.invoke({save: saveAddAttendanceProduct, closeOnSave: true});
        }

        vm.removeAttendanceProduct = function(index) {
            if(vm.productTabIsReadonly) {
                return;
            }
            $uibModal.open({
				templateUrl: 'app/common/delete-dialog/delete-dialog-template.html',
				controller: 'DeleteDialogController',
				controllerAs: 'vm',
				size: 'sm',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
                    service: 'AttendanceProduct',
                    obj: vm.attendanceProducts[index]
                }
			}).result.then(function(p_)
			{
				if(p_) {
                    vm.attendanceProducts.splice(index, 1);
                }
			}, function() {
				/*void*/
			});
		}
    };    
})();
