(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('stateButton', stateButtonDirective);
    
    stateButtonDirective.$inject = [];
    function stateButtonDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "StateButtonDirectiveController",
            templateUrl: "app/components/state-button/state-button.directive.template.html",
            scope:
            {
            	model: '=',
            	param: '='
            }
    	}
    };
})();
