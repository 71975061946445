(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('supplier', {
            parent: 'entity',
            url: '/supplier?page&sort',
            data: {
                authorities: [PermissionSet.EXPENSE.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'SupplierController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                gridDefaultSort: function()
                {
                	return {
	            		"name": "name", 
	            		"type": "asc"
                	};
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.supplier.title',
	                	icon: $rootScope.menuIcons.main.supplier,
	                	formValidateTarget: 'Supplier',
	                	permission: PermissionSet.SUPPLIER
                	})
                }]
            }
        })
        .state('supplier.base-domain', {
            parent: 'supplier',
            abstract: true,
            url: '/domain/:activeTab'
//            resolve: SupplierCommonStatic().extendResolve()
        })
        .state('supplier.detail', {
            parent: 'supplier.base-domain',
            url: '/{id}/detail',
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/supplier/supplier-domain.html',
                    controller: 'SupplierDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
            	categoryTreeDataSource: function(SupplierCategory){
        			return SupplierCategory.getTreeCategoriesActive().$promise;
            	},
                entity: ['$stateParams', 'Supplier', function($stateParams, Supplier)
                {
                    return Supplier.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]
            }
        })
        .state('supplier.edit', {
            parent: 'supplier.base-domain',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/supplier/supplier-domain.html',
                    controller: 'SupplierDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
            	categoryTreeDataSource: function(SupplierCategory){
        			return SupplierCategory.getTreeCategoriesActive().$promise;
            	},
                entity: ['Supplier', '$stateParams', function(Supplier, $stateParams)
                {
                    return Supplier.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('supplier.new', {
            parent: 'supplier.base-domain',
            url: '/new',                    
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/supplier/supplier-domain.html',
                    controller: 'SupplierDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {
                    	subcategoryIdList:[],
                    	subcategoryEquipmentSet:[],
                    	subcategoryMaterialSet:[],
                    	subcategoryServiceSet:[]
                    }
                },
                categoryTreeDataSource: function(SupplierCategory){
        			return SupplierCategory.getTreeCategoriesActive().$promise;
            	},
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }
})();
