(function() {
    'use strict';

    angular
        .module('sgmApp')
        .directive('ucAlertDirective', ucAlertDirective)
        .controller('UcAlertDirectiveController', UcAlertDirectiveController);

    UcAlertDirectiveController.$inject = ['$scope', '$sce', '$timeout'];

    function UcAlertDirectiveController ($scope, $sce, $timeout)
    {
        var vm = this;
        
        vm.boxShow = false;
        vm.boxCssClass = null;
        vm.boxContent = null;
        
        vm.boxClose = function()
        {
        	vm.boxShow = false;
            vm.boxCssClass = null;
            vm.boxContent = null;
        };
        
        $scope.$on('sgmApp.ucAlert', function(p_event, p_alertObj)
        {
        	if(p_alertObj)
        	{
        		// Verifica se a mensagem já foi exibida (pode ter mais de uma diretiva injetada)
        		if(p_alertObj.registerOnDirectiveCount == 0)
        		{
        			debug("Exibido na diretiva");
        			if(p_alertObj.alertType == "TOAST")
        			{
        				showToast(p_alertObj);
        			}
        			else // BOX é o falback tb
        			{
        				showBox(p_alertObj)
        			}
        		}
        		// registra na diretiva. Não só para evitar que outra execute, mas tambpem para registrar o núnero de diretivas passou (debug)
        		p_alertObj.registerReceivedOnDirective();
        	}
        });

        // Fecha o alerta BOX, caso esteja aberto
        $scope.$on('sgmApp.ucAlert.dismissBox', function(p_event, p_alertObj)
        {
        	vm.boxClose();
        });

        
        function showBox(p_alertObj)
        {
        	var level = p_alertObj.alertLevel;
        	
            vm.boxShow = true;
            vm.boxCssClass = "uc-alert alert alert-block alert-box-anchor alert-" + level.cssClass;
            vm.boxContent = $sce.trustAsHtml('<i class="' + level.cssIcon + '" aria-hidden="true"></i>&nbsp;&nbsp;' + p_alertObj.msg);
            
            // Dá o foco para o BOX (rolagem da tela) - próximo ciclo
            $timeout(function()
            {
            	try
            	{
            		var scrollTo = Math.max(0, $(".alert-box-anchor").offset().top - $(".alert-box-anchor").height() - 60);
            		$('html, body').animate( { scrollTop: scrollTo } );
            	}
            	catch(ee)
            	{
            		console.error("Error at scrollTo showBox");
            	}
            }, 100);
        }

        function showToast(p_alertObj)
        {
        	// por enquanto desabilitado
        	alert(removeHTML(p_alertObj.msg));
        };
    };

    ucAlertDirective.$inject = [];
    function ucAlertDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "UcAlertDirectiveController",
            templateUrl: "app/components/alert/uc-alert-directive.template.html",
            scope:
            {
            }
    	}
    };
})();
