(function(){
	'use strict';
	angular.module('sgmApp').controller('InstallmentSummaryDialogController', InstallmentSummaryDialogController);
	
	InstallmentSummaryDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'installment','Expense', '$rootScope'];
	
	
	function InstallmentSummaryDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, installment, Expense, $rootScope){
		
		var vm = this;
		vm.pageConfig = pageConfig;
		
		
		vm.installment = installment;
		
		vm.providedValueFormatted = $rootScope.formatMoney(installment.providedValue); 
		
		 /*public*/ vm.closeModal = function(recalc)
        {
			$uibModalInstance.close(recalc);
        };
        
        vm.onConfirmSave = function(){
        	vm.closeModal(true);
        }
	       
	}
})();
