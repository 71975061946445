(function(){
	'use strict';
	angular.module('sgmApp').controller('ProntuaryModelItemDialogController', ProntuaryModelItemDialogController);
	
	ProntuaryModelItemDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'entity', 'prontuaryItemTypesList', 'FrontEndSelectDataSource'];
	
	
	function ProntuaryModelItemDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, entity, prontuaryItemTypesList, FrontEndSelectDataSource){
		
		var vm = this;
		vm.pageConfig = pageConfig;
				
		if(!entity) {
			entity = {};
		}
		vm.prontuaryItem = {};
		Object.assign(vm.prontuaryItem, entity);
		vm.prontuaryItem.type = vm.prontuaryItem.type || prontuaryItemTypesList[0];
		vm.prontuaryItemOptionsForms = null;
		vm.prontuaryItemForm = null;
		vm.prontuaryItemOptions = vm.prontuaryItem.prontuaryItemOptions;
		vm.prontuaryItem.styleCss = vm.prontuaryItem.styleCss || "";
		vm.prontuaryItem.fontSize = vm.prontuaryItem.fontSize || 12;
		vm.grid = vm.prontuaryItem.grid;
		vm.gridHourFormField = new FormField({
				cellCssClass: "col-sm-6",
				type: "Boolean",
				id: "hour",
				formTarget: "vm.gridHourForm",
				label: "Tem hora no eixo X?",
				onChange: function(p_objModel, p_fieldModel, p_value) {
					if(p_value) {
						vm.grid.columns = [];
					} else {
						vm.addColumn();
					}
					console.log(p_value);
					regenerateItemChildren();
				}
		});
		vm.gridForms = {};
		vm.isBooleanType = function() {
			return vm.prontuaryItem.type != null && (vm.prontuaryItem.type.id == 'RADIO' || vm.prontuaryItem.type.id == 'CHECK');
		}

		vm.isGrid = function() {
			return vm.prontuaryItem.type != null && vm.prontuaryItem.type.id == 'GRID';
		}

		function addAndOrder(array) {
			array.push({
				order: array.length
			});
			regenerateItemChildren();
		}

		vm.addOption = function() {
			addAndOrder(vm.prontuaryItemOptions);
		}

		vm.addRow = function() {
			addAndOrder(vm.grid.rows);
		}

		vm.addColumn = function() {
			addAndOrder(vm.grid.columns);
		}

		vm.changeType = function(obj) {
			vm.prontuaryItem.styleCss = "";
			if(vm.isBooleanType()) {
				if(vm.prontuaryItemOptions == null || vm.prontuaryItem.prontuaryItemOptions.length == 0) {
					vm.prontuaryItem.prontuaryItemOptions = [{
						order: 0
					}];
					vm.prontuaryItemOptions = vm.prontuaryItem.prontuaryItemOptions;
				}

			} else {
				if(vm.isGrid()) {
					vm.prontuaryItem.grid = {
						rows: [{
							order: 0,
							textType: false,
						}],
						columns: [{
							order: 0
						}],
						hour: false
					}
					vm.grid = vm.prontuaryItem.grid;
				} else {
					vm.grid = null; 
					vm.prontuaryItem.grid = null;
				}
				vm.prontuaryItemOptions = null;
				vm.prontuaryItem.prontuaryItemOptions = null;
				
			}
			if(vm.prontuaryItem.type.id == 'TEXT') {
				vm.prontuaryItem.styleCss = 'font-size: 12pt;';
			}
			regenerateItems();
		}


		function deleteAndOrder(array, index) {
			array.splice(index, 1);
			for(var i = 0; i < vm.prontuaryItemOptions.length; i++) {
				array[i]['order'] = i;
			}
			regenerateItemChildren();
		}

		vm.deleteOption = function(index) {
			deleteAndOrder(vm.prontuaryItemOptions, index);
		}

		vm.deleteRow = function(index) {
			deleteAndOrder(vm.grid.rows, index);
		}

		vm.deleteColumn = function(index) {
			deleteAndOrder(vm.grid.columns, index);
		}


		function sortArray(array) {
			if(array) {
				array.sort(function(p_1, p_2)
				{
					return p_1.order - p_2.order;
				});
			}
		}

		
		function generateRowColumnForm(child, isRow, i) {
			var childRow = [new FormField({
				cellCssClass: "col-sm-8",
				type: "String",
				id: "title",
				label: "Título",
				required: true,
				maxLength: 10
			})];
			var formValidateTargetName = 'grid' + (isRow ? 'Row' : 'Column')+i;
			if(isRow) {
				childRow.push(new FormField({
					cellCssClass: "col-sm-4",
					type: "Boolean",
					id: "textType",
					required: true,
					label: "É tipo texto?",
					inputId: formValidateTargetName
				}));
			}
			
			var childFormDomain = FormDomain.create({
				model: child,
				pageConfig: vm.pageConfig,
				getForm: function(){ return $scope.$$childTail.ctrl[formValidateTargetName]; },
			})
			.add([])
			.add(childRow)
			.endConfig();
			console.log(child);
			console.log(childFormDomain.getModel());
			childFormDomain['index'] = i;
			return childFormDomain;
		}

		function regenerateItemChildren() {
			vm.prontuaryItemOptionsForms = null;
			vm.gridForms = null;
			if(vm.prontuaryItem.type == null) {
				return;
			}
			if(vm.isBooleanType()) {
				sortArray(vm.prontuaryItemOptions);
				vm.prontuaryItemOptionsForms = [];
				for(var i = 0; i < vm.prontuaryItemOptions.length; i++) {
					var option = vm.prontuaryItemOptions[i];

					var optionRow = [new FormField({
						cellCssClass: "col-sm-10",
						type: "String",
						id: "description",
						label: "Descrição da Opção",
						required: true,
					})];
					if(vm.prontuaryItem.hasPoints) {
						optionRow.push(new FormField({
							cellCssClass: "col-sm-2",
							type: "Double",
							id: "points",
							required: true,
							label: "Pontos",
						}));
					}
					var optionFormDomain = FormDomain.create({
						model: option,
						pageConfig: vm.pageConfig,
						getForm: function(){ return $scope.$$childTail.ctrl['prontuaryOption'+i]; },
					})
					.add([])
					.add(optionRow)
					.endConfig();
					optionFormDomain['index'] = i;
					vm.prontuaryItemForm['formValidateTarget'] ='prontuaryOption'+i;
					vm.prontuaryItemOptionsForms.push(
						optionFormDomain
					);
				}
			} else if(vm.isGrid()) {
				sortArray(vm.grid.rows);
				sortArray(vm.grid.columns);
				vm.gridForms = {
					rows: [],
					columns: []
				}
			
				for(var i = 0; i < vm.grid.rows.length; i++) {
					var option = vm.grid.rows[i];
					vm.gridForms.rows.push(
						generateRowColumnForm(option, true, i)
					);
				}
				for(var i = 0; i < vm.grid.columns.length; i++) {
					var option = vm.grid.columns[i];
					vm.gridForms.columns.push(
						generateRowColumnForm(option, false, i)
					);
				}
			}
		}

		
		function regenerateItems() {
			var prontuaryItemFormArray = [
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Select",
					style: "min-width: auto;",
					id: "type",
					required: true,
					label: "Tipo de Item",
					dataSource: FrontEndSelectDataSource.create(prontuaryItemTypesList),
					onChange: function(obj) {
						vm.changeType(obj);
					}
				}),
				new FormField({
					cellCssClass: "col-sm-8",
					type: "String",
					id: "description",
					required: true,
					label: "Descrição de Item",
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Long",
					id: "order",
					label: "Ordem",
					required: true
				}),
			];
			console.log(vm.prontuaryItem.type);

			if(vm.prontuaryItem.type && vm.prontuaryItem.type.id == 'TEXT') {
				prontuaryItemFormArray.push(
					new FormField({
						cellCssClass: "col-sm-2",
						type: "Boolean",
						id: "isBold",
						label: "Negrito",
						onChange: function(obj) {
							console.log(vm.prontuaryItem.styleCss);
							if(obj.isBold) {
								vm.prontuaryItem.styleCss += "font-weight: bold;";
							} else {
								vm.prontuaryItem.styleCss = vm.prontuaryItem.styleCss.replace("font-weight: bold;", "");
							}
							console.log(vm.prontuaryItem.styleCss);
						}
					})
				);
				prontuaryItemFormArray.push(
					new FormField({
						cellCssClass: "col-sm-2",
						type: "Long",
						id: "fontSize",
						label: "Tamanho da fonte",
						onChange: function(obj) {
							var fontSizeStr = 'font-size: '+(obj.fontSize||12)+'pt;';
							console.log(vm.prontuaryItem.styleCss);
							if(vm.prontuaryItem.styleCss.indexOf('font-size') >= 0) {
								vm.prontuaryItem.styleCss = vm.prontuaryItem.styleCss.replace(/font-size: \d*pt;/g, fontSizeStr); 
							} else {
								vm.prontuaryItem.styleCss += fontSizeStr;
							}
							console.log(vm.prontuaryItem.styleCss);
						}
					})
				)
			}

			vm.prontuaryItemForm = FormDomain.create({
				model: vm.prontuaryItem,
				getForm: function(){ return $scope.$$childTail.ctrl.prontuaryItemEditForm; },
			})
			.add([])
			.add(prontuaryItemFormArray)
			.endConfig();
			vm.prontuaryItemForm['formValidateTarget'] = "prontuaryItemEditForm";
			if(vm.isBooleanType()) {
				vm.prontuaryItemForm.add([
					new FormField({
						cellCssClass: "col-sm-6",
						type: "Boolean",
						id: "hasObservation",
						label: "Tem observação?",
					}),
					new FormField({
						cellCssClass: "col-sm-6",
						type: "Boolean",
						id: "hasPoints",
						label: "Tem pontuação?",
						onChange: function(obj) {
							regenerateItemChildren();
						}
					}),
				]);
			}

			regenerateItemChildren();
		}		
		
		 /*public*/ vm.closeModal = function(prontuaryItem)
		 {
			$uibModalInstance.close(prontuaryItem);
        };

        vm.prontuaryController = function() {

		}

        vm.saveItem = function(){
			console.log($scope);
			//if is save needs to validate and return the object
			var scopeCtrl = $scope.$$childTail.ctrl;
			console.log(scopeCtrl);
			FormUtil.validateAll(scopeCtrl.prontuaryItemEditForm, vm.prontuaryItemForm.getRowList());
			var valid = scopeCtrl.prontuaryItemEditForm.$valid;
			console.log(scopeCtrl.prontuaryItemEditForm);
			if(vm.prontuaryItemOptionsForms != null) {
				for(var i = 0; i < vm.prontuaryItemOptionsForms.length; i++) {
					FormUtil.validateAll(scopeCtrl['prontuaryOption'+i], vm.prontuaryItemOptionsForms[i].getRowList());
					valid = valid && scopeCtrl['prontuaryOption'+i].$valid
				}
			}
			
			
			if(valid) {
				if(vm.prontuaryItem != entity) {
					vm.prontuaryItem.lastTimeItemUpdated = moment()._d;
					vm.prontuaryItem.updated = true;
				}
				console.log(vm.prontuaryItem);
				vm.closeModal(vm.prontuaryItem);
			}
        	
        }

		regenerateItems();
	       
	}
})();
