(function() {
    'use strict';

	angular.module('sgmApp').service('FormFilter', FormFilter);
	
	FormFilter.$inject = ['FormHandler', 'FormStatePersister'];
	
	function FormFilter(FormHandler, FormStatePersister)
	{
		var vm = this;
		
		vm.create = function(p_formOptions)
		{
			return new _t(p_formOptions);
		};
		
		function _t(p_formOptions)
		{
			var self = this;
			
	        var defaultFormOptions = {
            	formStatePersisterId: null,
            	checkFormChange: null
	        };
	        
	        var formOptions = defaultFormOptions;
	        angular.extend(formOptions, (p_formOptions) ? p_formOptions : {});
	        
			/*private*/ var model = {};
			
			/*private*/ var form = FormHandler.create(model, formOptions);
			
			/**
			 * Método chamado ao fim da configuração
			 */
			/*public*/ this.endConfig = function()
			{
				if(formOptions.formStatePersisterId)
				{
					// Inicia o model com os valores do FormStatePersister
					form.setModel(FormStatePersister.get(formOptions.formStatePersisterId, form.getDefaultValueJson()));
				}
				return this;
			};
			
			/**
			 * DELEGATED METHODS
			 */
			/*public*/ this.getRowList = function()
    		{
    			return form.getRowList();
    		}
			/*public*/ this.getModel = function()
    		{
    			return form.getModel();
    		}
    		/*public*/ this.add = function(p_)
    		{
    			// Se o listener tiver preenchido, "pendura" no form-field
    			if(formOptions.checkFormChange)
    			{
	    			var arr = (angular.isArray(p_)) ? p_ : [p_];
	    			for(var i = 0 ; i < arr.length ; i++)
	    			{
	    				arr[i].checkFormChange = function()
	    				{
	    					formOptions.checkFormChange();		
	    				}
	    			}
    			}

    			// Adiciona no form 
    			form.add(p_);
    			return this;
    		};
    		// @Override
    		/*public*/ this.clear = function()
    		{
    			form.clear(function()
    			{
        			// Aplica os valores default
    				if(formOptions.formStatePersisterId)
    				{
    					FormStatePersister.reset(formOptions.formStatePersisterId);
    				}
    			});
    		};
    		/*public*/ this.readForm = function()
    		{
    			return form.readForm();
    		};

			/*public*/ this.reloadValidation = function(formController)
    		{
    			form.reloadValidation(formController);
    		};
		};
	};
})();