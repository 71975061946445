(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('AttendanceTagDialogInvoker', AttendanceTagDialogInvoker);

    AttendanceTagDialogInvoker.$inject = ['$uibModal'];

    function AttendanceTagDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/attendance/attendance-tag/attendance-tag-dialog.html',
				controller: 'AttendanceTagDialogController',
				controllerAs: 'vm',
				backdrop: 'static',
				size: 'lg',
				resolve: {
					pageConfig: [function()
					{
						return {
							formValidateTarget: 'AttendanceTag',
						};
					}],
					entity: function()
                    {
                    	return p_param.entity;
                    },
					clientData: function() {
						console.log(p_param);
						return p_param.clientData;
					}
                }
			}).result.then(function(event)
			{
				if(p_param.callback)
				{
					p_param.callback(event);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();