(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SchedulingAttendanceDomainController', SchedulingAttendanceDomainController);
    
    SchedulingAttendanceDomainController.$inject = ['FormDomain', '$scope', '$state', 'Attendance', '$rootScope', 'FormConstants', '$translate', 'UcAlertService', 'NotificationService', '$timeout', '$stateParams', 'ScheduleAttendanceDialogInvoker', '$uibModal'];

    function SchedulingAttendanceDomainController(FormDomain, $scope, $state, Attendance, $rootScope, FormConstants, $translate, UcAlertService, NotificationService, $timeout, $stateParams, ScheduleAttendanceDialogInvoker, $uibModal)
    {
        var vm = $scope.vm;
        var entity = vm.domain;
        var formHandler = vm.formHandler;
        var pageConfig = vm.pageConfig;
        var tabHandler = vm.tabHandler;

        var permissions = vm.permissions;
        
        /**
         * Page config exclusivo para o controle dos campos de data
         */
        vm.schedulePageConfig = new PageConfig({
    		pageMode: "CUSTOM_EDIT",
        	formValidateTarget: 'SchedulingAttendance'
    	});
        
        // Para testes
        $timeout(function()
        {
//        	vm.doReschedule();
        }, 100);
        
        
        // @Override
	    /*public*/ vm.validatePlannedDate = function()
	    {
	    	// Data de agendamento sempre obrigatório
	    	if(!entity.plannedDate)
	    	{
	            UcAlertService.message(
            		$translate.instant("client.global.messages.error.nullOrInvalidDateP0",
            		{
            			field: $translate.instant("client.page.attendance.tabs.scheduling.plannedDateFullDescription")
            		}), "WARNING", "BOX");
	            
	            // Alterna para a aba, caso não esteja nela
	            tabHandler.select("scheduling")
	    		return false;
	    	}
	    	else
	    	{
	    		return true;
	    	}
	    };

	    // @Override
	    /*public*/ vm.getPlannedEndDate = function()
	    {
	    	return (entity.plannedEndDateAsDate) ? moment(entity.plannedEndDateAsDate).format("YYYY-MM-DD HH:mm:ss") : null;
	    }
	    
        // @Override
	    /*public*/ vm.saveScheduling = function(p_json)
	    {
			p_json.rescheduleFlag = entity.rescheduleFlag;
			p_json.changeAttendanceDurationFlag = entity.changeAttendanceDurationFlag;
	    	
        	var activeFieldsList = extracActiveFields();
        	
	    	// Preenche o json validando
	    	var eventsDateIdArray = ["arrivalDate", "startConsultationDate", "endConsultationDate"]; // A data principal (plannedDate) é avaliada em outro método
	    	for(var i = 0 ; i < eventsDateIdArray.length ; i++)
	    	{
	    		var dateId = eventsDateIdArray[i];
	    		
	    		// Está selecionado?
	    		var selectedField = findField(activeFieldsList, dateId); 
	    		if(selectedField)
	    		{
	    			// Seta  adata no json
	    			FormConstants.ValTransforms.DATE_TIME(p_json, selectedField, entity[selectedField.id]);
	    			
	    			// Data preenchida corretamente?
	    			if(p_json[selectedField.id])
	    	    	{
	    				// Seta tambem a flag
	        			p_json[selectedField.id + "Flag"] = true;
	    	    	}
	    			else // Lança o erro
	    			{
	    	            UcAlertService.message(
	                		$translate.instant("client.global.messages.error.nullOrInvalidDateP0",
	                		{
	                			field: $translate.instant("client.page.attendance.tabs.scheduling." + selectedField.id  + "FullDescription")
	                		}), "WARNING", "BOX");
	    	            
	    	            // Alterna para a aba, caso não esteja nela
	    	            tabHandler.select("scheduling")
	    	    		return false;
	    	    	}
	    		}
	    		else
	    		{
	    			// Limpa o campo, case tenha sido preenchido errôneamente
	    			p_json[dateId + "Flag"] = false;
	    			p_json[dateId] = null;
	    		}
	    	}
	    	return true;
		};
		

		/*private*/ function createDisplayListDayWeek(p_listDaysWeek)
		{
			var map = {};
			var itm, temp;
			for (var i = 0; i < p_listDaysWeek.length; i++)
			{
				itm = p_listDaysWeek[i];
				temp = map[itm.weekDay];
				if(temp == null)
				{
					temp = {
						weekDay: itm.weekDay,
						list: []
					};
					map[itm.weekDay] = temp;
				}
				// Considera-se que a lista já vem ordenada pelos horários 
				temp.list.push(itm);
			}

			// Cria a lista de dias
			var list = [];
			var keys = Object.keys(map);
			for (var i = 0; i < keys.length; i++)
			{
				list.push(map[keys[i]]);
			}
			list.sort(function(p_1, p_2)
			{
				return p_1.weekDay - p_2.weekDay;
			});
			return list;
		}

		/*public*/ vm.changeAttendanceDuration = function ()
		{
			var isTherapy = (entity.therapyPlace);
	    	var tempSchedule = isTherapy ? entity.therapyPlace : entity.medic;
			var idResource = ((isTherapy) ? tempSchedule.code : tempSchedule.id);

			var timeZone = (moment.tz('America/Sao_Paulo')._offset/60)*-1;
			// var currentTimeZone = (moment.tz('America/Sao_Paulo')._d.getTimezoneOffset() / 60);
			var currentTimeZone = (moment.tz('America/Sao_Paulo')._offset/60)*-1;
			var listDaysWeek = [];
			Attendance.listAvailabilitySchedule({ id: idResource }, function(response)
				{
					listDaysWeek = [];
					angular.forEach(response, function(item) {
						listDaysWeek.push({ id: item.id, weekDay: item.weekDay, startTime: item.startTime, endTime: item.endTime });
					});

					var objData = {
						idResource: idResource, // Id médico
						idSchedule: 0,
						startDate: moment(entity.plannedDateAsDate)._d, // Data sem horas (00:00)
						startTime: moment(entity.plannedDateAsDate).format("HH:mm"), // Hora início no formato "HH:mm"
						endTime: moment(entity.plannedEndDateAsDate).format("HH:mm"), // Hora fim
						name: tempSchedule.defaultDescription, // Nome do médico
						listDaysWeek: listDaysWeek, 
						reschedule: true, // Reagendamento
						changeDuration: true,
						idAttendance: entity.id 
					};
					openModalToChangeDuration(objData);
				});
		}

		/*private*/ var openModalToChangeDuration = function (p_objData)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/attendance/confirmation/attendance-confirmation-schedule-dialog.html',
				controller: 'AttendanceConfirmationScheduleDialogController',
				controllerAs: 'vm',
				scope: $scope,
				resolve:
				{
					dataScheduling: function()
					{
						return p_objData;
					},
					rescheduleAttendance: function()
					{
						return null;
					}
				}
			}).result.then(function(p_)
			{
				if(p_ && p_.attendanceInput)
				{
					var startDate = moment(p_.attendanceInput.start, "YYYY-MM-DD HH:mm:ss");
					var endDate = moment(p_.attendanceInput.end, "YYYY-MM-DD HH:mm:ss");

					if(!isValidDate(startDate._d) || !isValidDate(endDate._d))
					{
						var msg = $translate.instant("client.page.attendance.tabs.scheduling.errorAtLoadAgendaDates", {start: p_.attendanceInput.start, end: p_.attendanceInput.end});
						UcAlertService.error(msg);
					}
					else
					{
						// Seta a nova data e seta o css destacando a data
						// $rootScope.$broadcast('form.setValue.plannedDate', startDate._d);
						// $("#datetime-wrapper-plannedDate").addClass("datetime-wrapper-rescheduled-date");
						
						entity.changeAttendanceDurationFlag = true;
						entity.plannedDateAsDate = startDate._d;
						entity.plannedEndDateAsDate = endDate._d;
						
						UcAlertService.message($translate.instant("client.page.attendance.tabs.scheduling.plannedDateSetSaveAttendanceToCompleteOperation"), "WARNING", "BOX");
					}
				}
			}, function() {
				/*void*/
			});
		}


	    
	    /*public*/ vm.doReschedule = function()
	    {
	    	var isTherapy = (entity.therapyPlace);
	    	var tempSchedule = isTherapy ? entity.therapyPlace : entity.medic;
			var objData =
			{
				resource: {
					value: ((isTherapy) ? tempSchedule.code : tempSchedule.id),
					name: tempSchedule.defaultDescription,
					availabilityObs: tempSchedule.availabilityObs
				}
            }
				
			ScheduleAttendanceDialogInvoker.invoke(new ScheduleAttendanceDialogInvokerParam({
				objData: objData,
				rescheduleAttendanceId: entity.id,
				hasAttendancePermissions: vm.hasAttendancePermissions,
				callback: function(p_)
				{
					if(p_ && p_.attendanceInput)
					{
						var startDate = moment(p_.attendanceInput.start, "YYYY-MM-DD HH:mm:ss");
						var endDate = moment(p_.attendanceInput.end, "YYYY-MM-DD HH:mm:ss");

						if(!isValidDate(startDate._d) || !isValidDate(endDate._d))
						{
							var msg = $translate.instant("client.page.attendance.tabs.scheduling.errorAtLoadAgendaDates", {start: p_.attendanceInput.start, end: p_.attendanceInput.end});
							UcAlertService.error(msg);
						}
						else
						{
							// Seta a nova data e seta o css destacando a data
							$rootScope.$broadcast('form.setValue.plannedDate', startDate._d);
							$("#datetime-wrapper-plannedDate").addClass("datetime-wrapper-rescheduled-date");
							
							entity.rescheduleFlag = true;
							entity.plannedDateAsDate = startDate._d;
							entity.plannedEndDateAsDate = endDate._d;
							
							UcAlertService.message($translate.instant("client.page.attendance.tabs.scheduling.plannedDateSetSaveAttendanceToCompleteOperation"), "WARNING", "BOX");
						}
					}
				}
			}));
	    	
	    }
	    
	    /*public*/ vm.getAttendanceDuration = function()
	    {
	    	try
	    	{
		    	if(entity.plannedDateAsDate && entity.plannedEndDateAsDate)
		    	{
		    		var diff = entity.plannedEndDateAsDate.getTime() - entity.plannedDateAsDate.getTime();
		    		diff = diff / (1000 * 60);
		    		return $translate.instant("client.global.label.duration") + ": " + diff + " " + $translate.instant("client.global.label.minutesSmall");
		    	}
	    	}
	    	catch(exc)
	    	{
	    		console.error("Erro at getAttendanceDuration: " + exc.message);
	    	}
	    	return "";
	    }
	    
	    /*private*/ function findField(p_fieldsList, p_fieldId)
	    {
	    	for (var i = 0; i < p_fieldsList.length; i++)
	    	{
	    		if(p_fieldsList[i].id == p_fieldId)
	    		{
	    			return p_fieldsList[i];
	    		}
			}
	    	return null;
	    }
	    

        /**
         * Retorna só os campos referentes a paineis ativos não readOnly
         */
        /*private*/ function extracActiveFields()
        {
        	var activeFieldList = [];
    	    for (var i = 0; i < vm.dateTimePanelList.length; i++)
    	    {
    	    	if(!vm.dateTimePanelList[i].readOnly && vm.dateTimePanelList[i].state)
    	    	{
    	    		activeFieldList.push(vm.dateTimePanelList[i].formField);
    	    	}
    		}
    	    return activeFieldList;
        };

	    function AttendanceDateTimePanel(p_code, p_id, p_label, p_iconCssClass, p_initialState, p_readOnly, p_rescheduleMode)
	    {
	    	var self = this;
	    	
	    	this.state = p_initialState;
	    	
	    	// Agendamento sempre readonly
	    	if(p_id == "plannedDate")
	    	{
	    		this.readOnly = true;
	    	}
	    	// Tiver permissão e EDIT, ok - Para remover eventos
	    	else if(pageConfig.pageMode == "EDIT" && permissions.hasEventsEditPermission)
	    	{
	    		this.readOnly = false;
	    	}
	    	// Se for pageConfig.pageMode != CREATE, só se tiver a permissão
	    	else if(pageConfig.pageMode != "CREATE" && !permissions.hasEventsEditPermission)
	    	{
	    		this.readOnly = true;
	    	}
	    	else
	    	{
	    		this.readOnly = p_readOnly;
	    	}
   	
	        this.formField = new FormField({
				formTarget: "no-bind",
				type: "DateTime",
				id: p_id,
				noLabel: true,
				required: false,
				readOnly: (this.readOnly || !p_initialState),
				vertical: true
			});
	        
	        this.rescheduleMode = p_rescheduleMode;
	        
	        this.stateButton = {
	        	label: $translate.instant(p_label),
	        	iconCssClass: p_iconCssClass,
	        	readOnly: this.readOnly,
	        	classCss: "w100",
	        	onClick: function()
	        	{
	        		self.formField.readOnly = self.state;
	        		$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly(p_id), {readOnly: !self.state, absolute: true});
	        		
	        		// Se for ativação e ambos os campos estiverem vazios, sugere a data corrente
	        		if(self.state)
	        		{
	        			if((entity[p_id + "_date"] === null || entity[p_id + "_date"] === "") && (entity[p_id + "_time"] === null || entity[p_id + "_time"] === ""))
	        			{
	        				var now = new Date();
	        				var date = createNDate(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
	        				var time = completeLeftWithZero(now.getHours(), 2) + ":" + completeLeftWithZero(now.getMinutes(), 2);
	        				entity[p_id] = date;
	        				entity[p_id + "_date"] = date;
	        				entity[p_id + "_time"] = time;
	        			}
	        			
	        			// No cliente chegou, mostrar a notificação // TODO_CODE
	        			if(self.formField.id == "arrivalDate")
	        			{
	        				var clientName = "-";
	        				try
	        				{
	        					clientName = entity.salesRequest.client.name;
	        				}
	        				catch(eee)
	        				{
	        					console.error(error.message)
	        				}
        		        	NotificationService.send("SGR - Aviso de Chegada", "O cliente " + clientName + " chegou");
	        			}
	        		}
	        	}
	        };
	    };
	    var statusCode = (entity.status) ? entity.status.id : null;
	    vm.dateTimePanelList = [];

	    // Se for criação, seta a data inicial e a final a data da rota
	    if(!entity.id)
	    {
	    	var start = moment($stateParams.start, "YYYY-MM-DD HH:mm:ss").toDate();
	    	if(isValidDate(start))
	    	{
	    		entity.plannedDate = start; // Para o input
	    		entity.plannedDateAsDate = start;
	    	}
	    	else
	    	{
	    		UcAlertService.error($translate.instant("client.page.attendance.tabs.scheduling.invalidPlannedDateMessage"));
	    	}
	    	var end = moment($stateParams.end, "YYYY-MM-DD HH:mm:ss").toDate();
	    	if(isValidDate(end))
	    	{
	    		entity.plannedEndDateAsDate = end;
	    	}
	    	else
	    	{
	    		UcAlertService.error($translate.instant("client.page.attendance.tabs.scheduling.invalidPlannedEndDateMessage"));
	    	}

	    }
	    else // Detail/Edit
	    {
    		if(entity.plannedDateNoTimezone)
    		{
    			entity.plannedDateAsDate = moment(entity.plannedDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d;
    		}
    		if(entity.plannedEndDateNoTimezone)
    		{
    			entity.plannedEndDateAsDate = moment(entity.plannedEndDateNoTimezone, "YYYY-MM-DD HH:mm:ss")._d;
    		}
	    }
	    vm.dateTimePanelList.push(new AttendanceDateTimePanel("AGE", "plannedDate", "client.page.attendance.tabs.scheduling.plannedDate", "pe pe-7s-date", true, true, permissions.hasRescheduleEditPermission));
    	if(entity.id)
    	{
	    	vm.dateTimePanelList.push(new AttendanceDateTimePanel("CHE", "arrivalDate", "client.page.attendance.tabs.scheduling.arrivalDate", "fa fa-map-marker", (statusCode == 'CHE' || statusCode == 'INI' || statusCode == 'FIM'), !entity.status.canPerformArrival, false));
	    	vm.dateTimePanelList.push(new AttendanceDateTimePanel("INI", "startConsultationDate", "client.page.attendance.tabs.scheduling.startAttendanceDate", "fa fa-sign-in", (statusCode == 'INI' || statusCode == 'FIM'), !entity.status.canStartConsultation, false));
	    	vm.dateTimePanelList.push(new AttendanceDateTimePanel("FIM", "endConsultationDate", "client.page.attendance.tabs.scheduling.endAttendanceDate", "fa fa-sign-out", (statusCode == 'FIM'), !entity.status.canEndConsultation, false));
    	}

	    // Adiciona todos no formHandler
	    var formFieldList = [];
	    for (var i = 0; i < vm.dateTimePanelList.length; i++)
	    {
	    	formFieldList.push(vm.dateTimePanelList[i].formField)
		}
	    
        formHandler.reopenConfig()
    	.add(formFieldList)
		.endConfig();
    };
})();
