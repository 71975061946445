(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('cameraHandler', cameraHandlerDirective);
    
    cameraHandlerDirective.$inject = [];
    function cameraHandlerDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "CameraHandlerDirectiveController",
            templateUrl: "app/components/camera/camera-handler.directive.template.html",
            scope:
            {
            	id: '@',
            	width: '@'
            }
    	}
    };
})();
