(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('AgendaLockingDialogInvoker', AgendaLockingDialogInvoker);

    AgendaLockingDialogInvoker.$inject = ['$uibModal'];

    function AgendaLockingDialogInvoker($uibModal)
    {
		var vm = this;
		
		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/agenda/locking/agenda-locking-dialog.html',
				controller: 'AgendaLockingDialogController',
				controllerAs: 'vm',
				resolve: {
					pageConfig: ['$rootScope', function($rootScope)
					{
	                	return new PageConfig({
	                		pageMode: "CUSTOM_EDIT",
		                	title: 'client.page.attendance.blockSchededuleTitle',
		                	icon: $rootScope.menuIcons.managers.agenda.agendaLockingTime,
		                	formValidateTarget: 'AgendaLockingTime'
	                	})
	                }],
                    schedulableList: ['Attendance', function(Attendance)
                    {
                    	return Attendance.listSchedulable({}).$promise;
                    }],
					repeatOptionsList: ['AgendaReminder', function(AgendaReminder)
                    {
                    	return AgendaReminder.listRemindersRepeatOptions({}).$promise;
                    }],
                    entity: ['AgendaLockingTime', function(AgendaLockingTime)
                    {
                    	// Edição?
                    	if(p_param.agendaLockingTimeId)
                    	{
                    		return AgendaLockingTime.get({id: p_param.agendaLockingTimeId}).$promise;
                    	}
                    	else
                    	{
                    		return {};
                    	}
                    }]
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();