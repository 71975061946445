(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('InvoiceAttendanceDomainController', InvoiceAttendanceDomainController);

    InvoiceAttendanceDomainController.$inject = ['FormDomain', '$scope', '$timeout', 'FormConstants', 'SalesRequestInvoiceDetailDialogInvoker', 'ConfirmDialogInvoker', '$translate', 'SalesRequestInvoice', 'SalesRequestInvoiceCancelDialogInvoker', 'SalesRequestInvoiceEmailDialogInvoker'];

    function InvoiceAttendanceDomainController(FormDomain, $scope, $timeout, FormConstants, SalesRequestInvoiceDetailDialogInvoker, ConfirmDialogInvoker, $translate, SalesRequestInvoice, SalesRequestInvoiceCancelDialogInvoker, SalesRequestInvoiceEmailDialogInvoker)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        var pageConfig = $scope.vm.pageConfig;
        var permissions = vm.permissions;
		if(entity && entity.salesRequest && entity.salesRequest.salesRequestInvoiceSet) {
			entity.salesRequest.activeSalesRequestInvoice = entity.salesRequest.salesRequestInvoiceSet.find(obj => obj.invoiceStatus.id != 'ERROR');
		}
        /*
         * Campos podem ser editados após a criação.
         * Atendimentos gratuitos também NÃO são editados (Igual Payment)
         */
        var fieldReadOnly = (!permissions.hasPaymentEditPermission || (entity.salesRequest && entity.salesRequest.free));

		vm.replaceSalesRequestInvoice = function(salesRequestInvoice) {
			var invoice = {};
			angular.extend(invoice, salesRequestInvoice);
			invoice.replacedSalesRequestInvoiceIdFk = salesRequestInvoice.id;
			invoice.id = null;
			invoice.createdDate = null;
			invoice.createdDateWithoutTimezone = null;
			invoice.userCreatedName = null;
			ConfirmDialogInvoker.invoke(
				{
					callback: function(res) {
						if(res) {
							invokeDetailModel(invoice);
						}
					},
					text: $translate.instant("client.page.attendance.tabs.invoice.replaceWarn")
				}
			);
		}
	    /*public*/ vm.createInvoice = function(manual)
	    {
			manual = manual ? true : false;
			var invoice = {
				manual: manual,
				clientName: entity.salesRequest.client.name,
				clientCpf: entity.salesRequest.client.cpf,
				clientAddressCep: entity.salesRequest.client.zipCode,
				clientAddressNumber: entity.salesRequest.client.addressNumber,
				clientAddressComplement: entity.salesRequest.client.addressComplement,
				salesRequestIdFk: [entity.salesRequest.id],
				totalValue: entity.salesRequest.finalValue - entity.salesRequest.anesthetistValue,
				invoiceDetail: `ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS, EXCETO MATERNIDADES
				DR JOSE ROGERIO REGIS JUNIOR CPF: 004.797.696-99 CRM-35060
				DR LUCIANA ALBERGARIA IAMIM REGIS CPF: 091.360.837-80 CRM-35012
				DR RUBEM MATEUS CAMPOS MIRANDA CPF: 010.757.966-95 CRM-37674
				DR MARCOS FELIPE FONSECA ALVES CPF: 049.874.846-41 CRM-42466
				DR ISABELA VIEIRA GOMIDE CPF: 013.568.316-56 CRM-47633
				DR CAROLINA VIEIRA ALVES RESENDE LAMOUNIER CPF: 012.630.046-10 CRM-55045          
				DR MARINA RODRIGUES COSTA LAGES CPF: 072.885.376-09 CRM-68372
				DR JULIA LAGES MOTTA HERMONT CPF: 063.071.096-10 CRM-80388
				DR GUILHERME HENRIQUE SILVEIRA TEIXEIRA CPF: 015.635.476-43 CRM-68240

				Valor Aproximado dos Tributos: 11,33%`,
			};
			if(!manual && entity.salesRequest.activeSalesRequestInvoice && entity.salesRequest.activeSalesRequestInvoice.manual) {
				ConfirmDialogInvoker.invoke(
					{
						callback: function(res) {
							if(res) {
								invokeDetailModel(invoice);
							}
						},
						text: $translate.instant("client.page.attendance.tabs.invoice.createNfseWarn")
					}
				);
			} else {
				invokeDetailModel(invoice);
			}

	    }

		function invokeDetailModel(invoice) {

	    	SalesRequestInvoiceDetailDialogInvoker.invoke(
				{
					entity: invoice,
					callback: reloadSalesRequest,
					hasCreatePermission: vm.canCreateInvoice
				}
			);
		}

		vm.addInvoiceConsult = function(invoice) {
			SalesRequestInvoice.addConsult({id: invoice.id}, (res) => {
				$timeout(function()
				{
					reloadSalesRequest(true);
				}, 2000);
			})
		}

		function reloadSalesRequest(result) {
			if(result) {
				SalesRequestInvoice.getBySalesRequestId({id: entity.salesRequest.id}, (res) => {
					console.log(res);
					if(res) {
						entity.salesRequest.salesRequestInvoiceSet = res;
						entity.salesRequest.activeSalesRequestInvoice = res.find(obj => obj.invoiceStatus.id != 'ERROR');
					}
				})
			}
		}

		vm.pdfSalesRequestInvoice = function(invoice) {
			window.open(invoice.urlPdf, "_blank");
		}


		vm.sendEmail = function(invoice) {
			console.log(invoice);
			SalesRequestInvoiceEmailDialogInvoker.invoke(
				{
					userName: invoice.clientName,
					email: entity.salesRequest.client.email,
					callback: (res) => {
						if(res) {
							SalesRequestInvoice.sendEmail(
								{id: invoice.id, attendanceId: entity.id},
								res
							, () => {});
						}
					},
				}
			);
		}

		/*public*/ vm.openSalesRequestInvoice = function(invoice)
	    {
			console.log(entity)
	    	invokeDetailModel(invoice);
	    }

		/*public*/ vm.cancelSalesRequestInvoice = function(invoice)
	    {
			SalesRequestInvoiceCancelDialogInvoker.invoke(
				{
					entity: invoice,
					callback: reloadSalesRequest,
				}
			);
	    }
    };
})();
