(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ExpenseAttachmentModalController', ExpenseAttachmentModalController);

    ExpenseAttachmentModalController.$inject = ['$scope', '$uibModalInstance', 'entity', 'expense', 'pageConfig', 'UcAlertService', 'Upload', '$translate'];

    function ExpenseAttachmentModalController($scope, $uibModalInstance, entity, expense, pageConfig, UcAlertService, Upload, $translate)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        vm.entity = entity;
        
        vm.showLoadingCameraSave = false;
        
        vm.pageTitle = "";
        
        
        /*public*/ vm.tabList = [
    		{code: "file", icon: "fa fa-upload", label: "client.page.expense.tab.attachment.title"}
    	];
        
        /*public*/ vm.tabHandler = {
        	active: 'file',
        	disabledMap: {},
        	isActive: function(p_code)
        	{
        		return (p_code === vm.tabHandler.active);
        	},
        	isDisabled: function(p_code) { return false },
        	isShow: function(p_code) { return true },
        	select: function(p_id)
        	{
       			vm.tabHandler.active = p_id;
        	}
        };
        
        
        /*public*/ vm.save = function()
        {
        	UcAlertService.dismissBox();
        	
        	if(!vm.entity.description)
        	{
        		UcAlertService.error($translate.instant("client.global.messages.error.requiredFieldP0", {field: $translate.instant("client.global.label.name")}));
        		return;
        	}
        	
        	if(vm.tabHandler.active == "file")
        	{
	        	// Verifica se tem arquivo selecionado
	        	if(!entity.content)
	        	{
	        		UcAlertService.error($translate.instant('client.page.expense.attachment.noSelectedFile'));
	        		return;
	        	}
	
	        	entity.content.upload = Upload.upload({
	        		url: 'api/expense/update-attach',
	        		data:
	        		{
	        			id: expense,
						description: entity.description,
	        			file: entity.content
	        		}
	        	});
	        	
	        	entity.content.upload.then(function (p_response) // ok
	        	{
	        		$uibModalInstance.close(true);	
	        	});
        	}
        	

        }
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };

     
    	

	}
})();