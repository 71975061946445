(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierManagerTabGeneralInformation', SupplierManagerTabGeneralInformation);
    
    SupplierManagerTabGeneralInformation.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'FormDomain'];
    function SupplierManagerTabGeneralInformation($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, FormDomain)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        var formOptions = {
				model: vm.domain,
				pageConfig: vm.pageConfig
        };
        
        formHandler.reopenConfig()
		.add([new FormField({
			formTarget: "generalInformationForm",
			cellCssClass: "col-sm-12",
			type: "TextArea",
			id: "obs",
			maxLength: 1000,
			label: "common.domain.supplier.obs",
		})]);
        
    };
})();