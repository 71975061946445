(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceDomainController', AttendanceDomainController);

    AttendanceDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'Attendance', 'pageConfig', '$rootScope', 'FormConstants', 'BackEndSelectDataSource', 'FrontEndSelectDataSource', '$translate', 'procedurePlaceList', '$stateParams', 'salesRequestForCreate', '$uibModal', 'UcAlertService', '$timeout', 'paymentOptionList', 'Procedure', 'therapyPlaceList', 'userPermissions', 'TabStatePersister', 'attendanceCostOptionList', 'anesthetistList', 'isToShowRecordsButton', 'RecordsModalInvoker', 'medicNoteInfo', 'RecordsPrintModalInvoker', 'schedulable', 'Agenda', 'TherapyPlace', 'FamilyProcedure', 'Records', 'ClientMinManagerModalInvoker', 'account', 'Principal', 'firstAttendanceStatusList', 'SalesRequestEditModalInvoker', 'equipment', 'procedurePlace', 'familyProcedure', 'procedure', 'AttendanceTagDialogInvoker', 'PermissionSet'];

    function AttendanceDomainController(FormDomain, $scope, $state, entity, Attendance, pageConfig, $rootScope, FormConstants, BackEndSelectDataSource, FrontEndSelectDataSource, $translate, procedurePlaceList, $stateParams, salesRequestForCreate, $uibModal, UcAlertService, $timeout, paymentOptionList, Procedure, therapyPlaceList, userPermissions, TabStatePersister, attendanceCostOptionList, anesthetistList, isToShowRecordsButton, RecordsModalInvoker, medicNoteInfo, RecordsPrintModalInvoker, schedulable, Agenda, TherapyPlace, FamilyProcedure, Records, ClientMinManagerModalInvoker, account, Principal, firstAttendanceStatusList, SalesRequestEditModalInvoker, equipment, procedurePlace, familyProcedure, procedure, AttendanceTagDialogInvoker, PermissionSet)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
        vm.paymentOptionList = paymentOptionList;
        vm.medicNoteInfo = medicNoteInfo;
        vm.account = account;
		vm.hasAttendancePermissions = Principal.hasAnyAuthority(objectKeysToArray(PermissionSet.ATTENDANCE));
		vm.productTabIsReadonly = true;
		vm.hasProductEditPermission = Principal.hasAnyAuthority([PermissionSet.PRODUCT.edit]);
        var salesRequest = (salesRequestForCreate) ? salesRequestForCreate.salesRequest : null;
        var newAttendanceTemplate = (salesRequestForCreate) ? salesRequestForCreate.newAttendanceTemplate : null;
		vm.canCreateInvoice =  Principal.hasAnyAuthority([PermissionSet.SALES_REQUEST_INVOICE.create]);
		vm.canCancelInvoice =  Principal.hasAnyAuthority([PermissionSet.SALES_REQUEST_INVOICE.cancel]);
		vm.canSeeInvoice =  Principal.hasAnyAuthority([PermissionSet.SALES_REQUEST_INVOICE.access]);
		vm.hasAdminPermission = Principal.hasAnyAuthority(['ROLE_ADMINISTRACAO']);
        // Indica que é criação e foi selecionado um SalesRequest
        vm.selectSalesRequestOnCreate = $stateParams.selectSalesRequestOnCreate;
		vm.clientData = {};
		// Seleciona o clientId inicial
		vm.initalClientId = extractInitalClientId(entity, vm.selectSalesRequestOnCreate);
		vm.hasClientSelected = false;
		// Busca os dados do cliente, caso haja cliennte selecionado (agenda para executar no próximo ciclo)
		$timeout(function () {
			vm.syncClient(vm.initalClientId);
		}, 1);

        /*public*/ vm.syncClient = function (p_clientId) {
			// Flag que indica qual layout exibir na aba
			vm.hasClientSelected = !!p_clientId;
			vm.clientId = p_clientId;
			// Há cliente selecionado?
			if (p_clientId != null) {
				vm.syncMedicNotes(p_clientId);


				// TODO Melhorar trazer todos os dados na mesma consulta
				// Busca dados do cliente 
				Attendance.getClientData({ clientId: p_clientId }, function (p_clientData) {
					angular.extend(vm.clientData, p_clientData);
					vm.syncClientNotes((p_clientData) ? p_clientData.clientObs : null);
					if(p_clientData.indicationsManager) {
						vm.clientData.indicationsManagerName = p_clientData.indicationsManager.indicatorName;
						vm.clientData.originIndications = p_clientData.indicationsManager.indication.description;
						vm.clientData.indicationsCategory = p_clientData.indicationsManager.indication.indicationType.description;
						vm.clientData.indicationsManagerId = p_clientData.indicationsManager.id;
					}
					console.log(vm.clientData);
				});
			}
			else // Não tem, limpa o modal
			{
				angular.copy({}, vm.clientData);
				vm.syncClientNotes(null);
			}
		};


        var databaseProcedureId = (entity && entity.id) ? entity.salesRequest.procedureIdFk : null;
        var databaseSessionsQuantity = (entity && entity.id) ? entity.sessionsQuantity : null;
        var databaseSessionIndex = (entity && entity.id) ? entity.sessionIndex : null;

        // Gerencia o SalesRequest de acordo com o valor da rota e do modo da página (criação ou não)
        var manager = new AttendanceSalesRequestManager(pageConfig, entity, salesRequest);

        // Gerencia as permissões
        vm.permissions = new PermissionManager(userPermissions, entity);

        // Para ser usado nas tabs, caso se aplique
        vm.salesRequestParam = salesRequest;

        // Tabs
        vm.tabList = [
    		{code: "scheduling", icon: "pe-7s-date", label: "client.page.attendance.scheduling"},
    		{code: "confirmation", icon: "fa fa-check", label: "client.page.attendance.confirmation"},
    		{code: "client", icon: "fa fa-user", label: "client.page.attendance.client"},
			{code: "attendanceIndication", icon: "pe-7s-speaker", label: "client.page.client.tab.indication.title"},
       	];
        // Essas abas só serão habilitadas após a criação do pagamento
        if(vm.pageConfig.pageMode != 'CREATE')
        {
        	vm.tabList.push({code: "payment", icon: "fa fa-usd", label: "client.page.attendance.payment"});
			if(vm.canSeeInvoice) {
				vm.tabList.push({code: "invoice", icon: "fa fa-sticky-note-o", label: "client.page.attendance.invoice"});
			}
        	vm.tabList.push({code: "summary", icon: "fa fa-book", label: "client.page.attendance.summary"});
			vm.tabList.push({code: "attendanceProduct", icon: "fa fa-medkit", label: "client.page.attendance.attendanceProduct"})
        }
        vm.tabList.push({code: "notes", icon: "fa fa-file-text-o", label: "client.page.attendance.notes", extraIcon: {cssClass: "fa fa-circle display-none blink", style: "margin-left:6px;color:#00FF00", id:"notesSignal"} });

        // Ajusta o domain, caso se aplique
        if(entity && entity.userAssistantId)
        {
        	entity.assistant = entity.userAssistantId;
        }

        /*
         * Para os campos do formulário principal que podem ser editados após a criação
         */
        var editableFieldReadOnly;
        var costOptionReadOnly;
        if(pageConfig.pageMode == "CREATE") // Criação
        {
        	editableFieldReadOnly = false;

        	// Se já existe SalesRequest, não deve ser editável
        	costOptionReadOnly = !!(salesRequestForCreate);
        }
        else
        {
        	editableFieldReadOnly = (!vm.permissions.hasMainEditPermission);
        	costOptionReadOnly = (!vm.permissions.hasPaymentEditPermission);
        }

        vm.tabHandler = {
        	disabledMap: {},
        	active: TabStatePersister.init("AttendanceDomainController", "scheduling"),
        	isActive: function(p_code)
        	{
        		return (p_code === vm.tabHandler.active);
        	},
        	isDisabled: function(p_code)
        	{
        		return false;
        	},
        	isShow: function(p_code)
        	{
        		return true;
        	},
        	select: function(p_id)
        	{
        		vm.tabHandler.active = TabStatePersister.set("AttendanceDomainController", p_id);
        		if(p_id == "summary")
        		{
        			$timeout(vm.onSummarySelect(), 500);
        		}
        	}
        };

        var tabErrorListner = $scope.$on(FormConstants.Events.Runtime.ShowTabError, function(p_, p_arg)
        {
        	Indif(p_arg)
        	{
        		vm.tabHandler.select(p_arg);
        	}
        });
        $scope.$on('$destroy', function ()
        {
            if(angular.isDefined(tabErrorListner) && tabErrorListner !== null)
            {
            	tabErrorListner();
            }
        });

		if(Principal.hasAnyAuthority([PermissionSet.PRODUCT.edit])) {
			vm.productTabIsReadonly = false; 
		}		

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: null,
        	service: Attendance,
        	afterCreateState: "attendance.detail",
        	historyConfig: {historyId: "ATTENDANCE"},
        	showTabWithError: true,
        	getForm: function(){ return $scope.editForm; },
        	afterUpdate: function() { $state.reload(); }
        };
		let familyProcedureCriteria = {};
		familyProcedureCriteria["therapyPlaceCode"] = "";
		
        var readOnlyMedic = false;
        /*
         * Se a requisição veio da agenda:
         *  - Médico: seta o médico e bloqueia e coloca readonly
         *  - Local Terapia: seta a família procedimento como "Terapia" e seta o "Local Terapia"
         */
	    if(!entity.id)
	    {
	    	if(schedulable)
	    	{
				if(schedulable.type == "USER")
				{
					entity.medic = schedulable;
					readOnlyMedic = true;
				}
				else if(schedulable.type == "THERAPY")
				{
					familyProcedureCriteria.therapyPlaceCode = schedulable.id;
					$timeout(function()
					{
						TherapyPlace.getByCode({code: schedulable.id}, function(p_dto)
						{
							entity.therapyPlace = p_dto;
							entity.therapyPlaceId = (p_dto) ? p_dto.id : null;
						});
						FamilyProcedure.getForTherapyPlace({}, function(p_dto)
						{
							entity.familyProcedure = p_dto;
						});

					}, 100);
				}
	    	}
	    	if(equipment)
	    	{
	    		entity.equipment = equipment;
	    	}
	    	if(procedurePlace)
	    	{
	    		entity.procedurePlace = procedurePlace;
	    	}
	    	if(familyProcedure)
	    	{
	    		entity.familyProcedure = familyProcedure;
	    	}
	    	if(procedure)
	    	{
	    		entity.procedure = procedure;
	    		$timeout(function(){
	    			refreshDynamicData(null, entity.procedure);
	    			synchProcedureData(entity.procedure, true);
	    		}, 10);
	    	}
	    }
        var familyProcedureFormField = new FormField({
        	formTarget: "main",
       		cellCssClass: "col-sm-4",
       		type: "Select",
			id: "familyProcedure",
			required: true,
			dependencyName: "familyProcedureListId",
			readOnly: manager.readOnly,
			label: "client.page.attendance.familyProcedure",
			dataSource: FormConstants.DataSources.BackEnd.FamilyProcedure(BackEndSelectDataSource, $translate, familyProcedureCriteria),
			onChange: function(p_objModel, p_fieldModel, p_value)
			{
				synchProcedureData(null, true);
			}
    	});

        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
        			formTarget: "main",
        			cellCssClass: "col-sm-4",
					type: "String",
					id: "id",
					required: true,
					label: "common.domain.attendance.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
				new FormHtmlRender({
        			formTarget: "main",
        			cellCssClass: "col-sm-4",
        			label: "client.global.label.status",
        			hasLabel: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull,
					render: function(p_objModel, p_fieldModel, p_pageMode, p_editMode)
					{
						var html = "<div style='display:table'>" +  PillRenderer.rendererEnum(p_objModel["status"]);
						if(p_objModel.reschedulingNumber > 0)
						{
							html += '<span class="attendance-rescheduling-flag-separator"></span>';
							html += '<span class="attendance-rescheduling-flag" title="' + $translate.instant("client.page.attendance.attendanceRescheduledP0TimesLastOperationByP1", {number: p_objModel.reschedulingNumber, user: (p_objModel.userReschedulingUserId) ? p_objModel.userReschedulingUserId.defaultDescription : ""}) + '" onclick="staticAlertService(this.title, {level: \'INFO\', type: \'ALERT\'})"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></span>';
						}
						html += '</div>';
						return html;
					}
				}),
				new FormField({
        			formTarget: "main",
        			cellCssClass: "col-sm-3",
					type: "Button",
					hasLabel: false,
					id: "editSalesRequest",
					addFieldListener: function(p_domain)
	        		{
						// Edição e ter permissão
	        			return (p_domain && p_domain.id) && (vm.permissions.hasMainEditPermission);
	        		},
					buttonModel:
					{
						btnClass: "btn btn-accent",
						iconClass: "glyphicon glyphicon-pencil",
						label: $translate.instant("client.page.attendance.editClientProcedure"),
						disabledHint: (vm.domain.sessionsQuantity == 1) ? $translate.instant("client.page.attendance.editClientProcedureDisabled") : $translate.instant("client.page.attendance.onlySingleSessionAttendanceCanBeEdited"),
						disabled: function(p_domain)
		        		{
							// Somente para procedimentos de apenas 1 sessão
		        			return !(p_domain.salesRequest.sessionsQuantity == 1);
		        		},
						click: function(p_domain)
						{
							SalesRequestEditModalInvoker.invoke({
				        		attendanceId: entity.id,
				        		salesRequestId: entity.salesRequest.id,
				        		callback: function() { $state.reload(); }
							});
						}
					}
				})
        	])
			.add([new FormField({ // Sempre ReadOnly e carregado via manager AttendanceSalesRequestManager
					formTarget: "main",
					cellCssClass: "col-sm-4",
		        	type: "String",
		        	id: "salesRequestLabel",
		        	label: "common.domain.attendance.salesRequestId",
		        	readOnly: true
				}),
				familyProcedureFormField,
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-4",
		       		type: "Select",
					id: "procedure",
					required: true,
					readOnly: manager.readOnly,
					label: "client.page.attendance.procedure",
					searchActiveOnly: true,
					onChange: function(p_objModel, p_fieldModel, p_value)
					{
						refreshDynamicData(null, p_value);
						synchProcedureData(p_value, true);
					},
					dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, null, true)
				})
		    ]);
        vm.formHandler
		    .add([new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-4",
					type: "SelectModal",
					id: "client",
					required: true,
					readOnly: manager.readOnly,
					label: "client.page.attendance.client",
					onChange: function(p_objModel, p_fieldModel, p_value)
					{
						onChangeClient(p_objModel.client);
					},
					modalConfig: {
						searchId: "Client",
						bindLabel: "name",
				        extraButtons: [{
				        	buttonClass: "btn btn-info",
				        	iconClass: "glyphicon glyphicon-plus",
				        	label: 'client.global.label.create',
				        	disabled: !Principal.hasAnyAuthority(['ROLE_CLIENTE_CRIACAO']),
				        	callback: function()
				        	{
				        		ClientMinManagerModalInvoker.invoke({
				        			account: account,
				        			callback: function(p_client)
				        			{
				        				$rootScope.$broadcast("search-modal.close.Client", [p_client]);
				        			}
				        		});
				        	}
				        }]
					}
				}),
		        new FormField({
		        	formTarget: "main",
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "procedurePlace",
					required: true,
					readOnly: editableFieldReadOnly,
					label: "common.domain.attendance.procedurePlace",
					dataSource: FrontEndSelectDataSource.create(procedurePlaceList)
		        }),
		        new FormField({
		        	formTarget: "main",
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "medic",
					required: true,
					readOnly: editableFieldReadOnly || readOnlyMedic,
					label: "client.page.attendance.professional",
					dataSource: FormConstants.DataSources.BackEnd.Professional(BackEndSelectDataSource, $translate)
				})
		    ])
		    .add([new FormField({
		        	formTarget: "main",
		       		cellCssClass: "col-sm-4",
		       		type: "Select",
					id: "costOption",
					readOnly: costOptionReadOnly,
					required: true,
					label: "client.page.attendance.attendanceCost",
					dataSource: FrontEndSelectDataSource.create(attendanceCostOptionList)
		    	}),
				new FormField({
		        	formTarget: "main",
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "anesthetistOption",
					readOnly: true,
					label: "common.domain.attendance.anesthetist",
					dataSource: FrontEndSelectDataSource.create(anesthetistList)
				}),
		    	new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-2",
					type: "Long",
					id: "sessionsQuantity",
					readOnly: true,
					label: "client.page.attendance.sessionsQuantity"
				}),
		        new FormField({
		        	formTarget: "main",
		        	cellCssClass: "col-sm-2",
					type: "Long",
					id: "sessionIndex",
					readOnly: true,
					label: "client.page.attendance.sessionIndex"
		        })
			])
			.add([new FormField({
		    		formTarget: "main",
	           		cellCssClass: "col-sm-4",
	           		type: "Select",
					id: "firstAttendance",
					readOnly: editableFieldReadOnly,
					label: "common.domain.attendance.firstAttendance",
					required: true,
					dataSource: FrontEndSelectDataSource.create(firstAttendanceStatusList)
				}),
//				new FormField({
//		        	formTarget: "main",
//		       		cellCssClass: "col-sm-4",
//		       		type: "Select",
//					id: "therapyPlace",
//					readOnly: true,
//					label: "common.domain.attendance.therapyPlace",
//					dataSource: FrontEndSelectDataSource.create(therapyPlaceList)
//		    	}),
				new FormField({
		        	formTarget: "main",
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "assistant",
					readOnly: true,
					label: "common.domain.attendance.assistant",
					dataSource: FormConstants.DataSources.BackEnd.Assistent(BackEndSelectDataSource, $translate)
				}),
				new FormField({
		        	formTarget: "main",
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "equipment",
					readOnly: editableFieldReadOnly,
					label: "common.domain.attendance.equipment",
					dataSource: FormConstants.DataSources.BackEnd.Equipment(BackEndSelectDataSource, $translate)
				}),
			])
			.endConfig();

        // Agenda para o próximo ciclo
        $timeout(function()
		{
//        	RecordsModalInvoker.invoke({
//        		recordType: {id: 'NOT'},
//       		clientName:"EVELYN JERMANI",
//        		record: recordTarget
//        		client: entity.salesRequest.client,
//        		attendanceId: 19916
//        	});


			syncCostOptionField();
			synchProcedureData(null, false);
		}, 1);

        /*
         * Para Atendimentos criados à partir de uma requsição já existente, será sugerido
         * o médico e a motivação do primeiro atendimento da série, caso exista
         */
        $timeout(function()
		{
        	if(newAttendanceTemplate)
        	{
        		// Agenda sobrepõem
        		if(!readOnlyMedic)
        		{
        			if(newAttendanceTemplate.medic) { entity.medic = newAttendanceTemplate.medic; }
        		}
        		if(newAttendanceTemplate.procedureMotivation) { entity.procedureMotivation = newAttendanceTemplate.procedureMotivation; }
        	}
		}, 200);

        /*public*/ vm.save = function()
        {
        	UcAlertService.dismissBox();

        	// Se for criação, usar o save padrão do form
    		vm.formHandler.save(function(p_json, p_onSaveSuccess, p_onSaveError)
    		{
    			if(!vm.validatePlannedDate())
    			{
    				return;
    			}
    			p_json.plannedEndDate = vm.getPlannedEndDate();
    			p_json.therapyPlaceId = entity.therapyPlaceId;

    	    	var isTherapy = (entity.therapyPlace);
    	    	var tempSchedule = isTherapy ? entity.therapyPlace : p_json.medic;
				console.log(p_json);
                var param = {
            		id: p_json.id,
            		schedulingType: "A",
            		procedurePlaceId: p_json.procedurePlaceId,
            		schedulableId: ((isTherapy) ? tempSchedule.code : tempSchedule.id),
            		equipmentId: p_json.equipmentId,
            		startDate: p_json.plannedDate,
            		endDate: p_json.plannedEndDate,
            		fullDay: false
            	};
				console.log(param);
               	Agenda.checkScheduling(param,
    	        	function(p_result)
    	        	{
    	        		if(p_result.ok)
    	        		{
    	        			customSave(p_json, p_onSaveSuccess, p_onSaveError);
    	        		}
    	        		else
    	        		{
    		    			var message = $translate.instant("client.page.attendance.currentMarkingIsInConflictWithEventsBelow");
    		    			message += "<ul>";
    		    			for (var i = 0; i < p_result.conflictList.length; i++)
    		    			{
    		    				message += "<li>" + p_result.conflictList[i] + "</li>";
							}
    		    			
							if(!p_result.hasAttendanceAtDate) {
								message += "</ul>" + $translate.instant("client.global.messages.info.confirmOperation")
							}
    		    			$rootScope.confirm(
    		    			{
    		    				message: message,
								hideSuccessButton: p_result.hasAttendanceAtDate,
    		    				callback: function()
    		    				{
    		    					customSave(p_json, p_onSaveSuccess, p_onSaveError);
    		    				}
    		    			});
    	        		}
    	        	}
               	);

    		});
        };

        /*public*/ /*abstract*/ // vm.validatePlannedDate(); -> implementar na aba Scheduling
        /*public*/ /*abstract*/ // vm.getPlannedEndDate(); -> implementar na aba Scheduling
        /*public*/ /*abstract*/ // vm.saveScheduling(p_json); -> implementar na aba Scheduling

        /*public*/ /*abstract*/ // vm.syncClient(p_clientId); -> implementar na aba Client

        /*public*/ /*abstract*/ // vm.saveConfirmation(p_json); -> implementar na aba Confirmation

        /*public*/ /*abstract*/ // vm.syncProcedure(p_procedure, p_resetValue); -> implementar na aba Payment
        /*public*/ /*abstract*/ // vm.savePayment(p_json); -> implementar na aba Payment

        /*public*/ /*abstract*/ // vm.saveNotes(p_json); -> Implementar na aba Notes
        /*public*/ /*abstract*/ // vm.syncMedicNotes(p_clientId) -> Implementar na aba Notes
        /*public*/ /*abstract*/ // vm.syncClientNotes(p_clientObs); -> Implementar na aba Notes / chamada da aba cliente

        /*public*/ /*abstract*/ // vm.saveInvoice(p_json); -> implementar na aba Invoice
        /*public*/ /*abstract*/ // vm.refreshInvoiceForm(p_salesRequestInvoiceSet); -> implementar na aba Invoice

        /*public*/ /*abstract*/ // vm.onSummarySelect(); -> implementar na aba Summary

        /*private*/ function customSave(p_json, p_onSaveSuccess, p_onSaveError)
        {
        	p_json.pageMode = vm.pageConfig.pageMode;

			/*
   			 * Chama SEMPRE o método da aba Notes para preencher o json com suas informações
			 */
			vm.saveNotes(p_json);

        	if(vm.pageConfig.pageMode != 'CREATE')
        	{
				/*
				 * Chama o método da aba Confirmation
				 */
	    		vm.saveConfirmation(p_json);

				/*
				 * Chama o método da aba Payment para preencher o json com suas informações
				 * Também faz as validações necessárias
				 */
	    		if(!vm.savePayment(p_json))
	    		{
	    			return;
	    		}
        	}

    		if (p_json.id)
    		{
    			/*
    			 * Chama o método da aba Scheduling para preencher o json com suas informações
    			 * Também faz as validações necessárias
    			 */
    			if(!vm.saveScheduling(p_json))
    			{
    				return;
    			}

    			Attendance.update(p_json, p_onSaveSuccess, p_onSaveError);
            }
    		else
    		{
                // Seta o valor so SalesRequest passado como parâmetro, caso exista
        		p_json.salesRequestId = ($stateParams.salesRequestId) ? $stateParams.salesRequestId : null;

    			Attendance.save(p_json, p_onSaveSuccess, p_onSaveError);
            }
        };

        /*public*/ vm.canCancel = function()
        {
        	// Deve ser edição, o status deve permitir e o usuário deve ter permissão
        	if(vm.domain.id && vm.domain.status.canCancel && (vm.permissions && vm.permissions.hasCancelEditPermission))
        	{
        		return true;
        	}
        	else
        	{
        		return false;
        	}
        };

		/*public*/ vm.canReopen = function()
		{
			// Deve ser edição, o status deve permitir e o usuário deve ter permissão
			if(vm.domain.id && vm.domain.status.canReopen)
			{
				return true;
			}
			else
			{
				return false;
			}
		};


        /*public*/ vm.canReschedule = function()
        {
        	// Deve ser edição, o status deve permitir e o usuário deve ter permissão
        	if(vm.domain.id && vm.domain.status.canReschedule && (vm.permissions && vm.permissions.hasRescheduleEditPermission))
        	{
        		return true;
        	}
        	else
        	{
        		return false;
        	}
        };

        /**
         * Sincroniza o valor do domain com o field, caso exista valor no domain
         */
        /*private*/ function syncCostOptionField()
        {
        	// Para edição/readOnly
        	if(entity && entity.id)
        	{
        		setCostOption(entity.salesRequest);
        	}
        	// Para criação à partir de Requisição existente
        	else if(salesRequest)
        	{
        		setCostOption(salesRequest);
        	}
        };

        /**
         * Ataualiza se ReadOnly/limpa valor de acodo com o procedimento selecionado
         */
        /*private*/ function synchProcedureData(p_explicitProcedure, p_clearField)
        {
        	$timeout(function()
        	{
        		var editAssistantFlag = false;
        		var editAnesthetistFlag = false;
//        		var editTherapyFlag = false;

        		// Só avalia se a flag de edição for true
        		if(!editableFieldReadOnly)
        		{
        			if(vm.pageConfig.pageMode == 'CREATE')
        			{
        				// Procedimento Explicito?
        				if(p_explicitProcedure)
        				{
            				editAssistantFlag = p_explicitProcedure.hasAssistant;
            				editAnesthetistFlag = p_explicitProcedure.hasAnesthetist;
//            				editTherapyFlag = p_explicitProcedure.hasTherapy;
        				}
        				// Criação a partir de uma solictação já existente?
        				else if(salesRequest)
        				{
            				editAssistantFlag = salesRequest.procedure.hasAssistant;
            				editAnesthetistFlag = salesRequest.procedure.hasAnesthetist;
//            				editTherapyFlag = salesRequest.procedure.hasTherapy;
        				}
        			}
        			else
        			{
        				editAssistantFlag = entity.procedure.hasAssistant;
        				editAnesthetistFlag = entity.procedure.hasAnesthetist;
//        				editTherapyFlag = entity.procedure.hasTherapy;
        			}
        		}

        		if(p_clearField && entity)
        		{
        			if(!editAssistantFlag)
        			{
        				entity["assistant"] = null;
        			}
        			if(!editAnesthetistFlag)
        			{
        				entity["anesthetistOption"] = null;
        			}
//        			if(!editTherapyFlag)
//        			{
//        				entity["therapyPlace"] = null;
//        			}
        		}

        		$scope.$emit(FormConstants.Events.Runtime.ReadOnly("anesthetistOption"), {readOnly: !editAnesthetistFlag});
        		$scope.$emit(FormConstants.Events.Runtime.ReadOnly("assistant"), {readOnly: !editAssistantFlag});
//        		$scope.$emit(FormConstants.Events.Runtime.ReadOnly("therapyPlace"), {readOnly: !editTherapyFlag});
        	}, 10);
        };

        /**
         * Seta os valores de CostOption
         */
        /*private*/ function setCostOption(p_salesRequest)
        {
    		var selectVal = null;
    		if(p_salesRequest.free === true)
    		{
    			selectVal = "" + p_salesRequest.freePaymentOptionIdFk; // Porque no Select é String
    		}
    		else // Pago, utiliza o código fico DtoAttendanceCostOption.PAID_CODE
    		{
    			selectVal = "paid"; // Porque no Select é String
    		}

    		if(selectVal)
    		{
    			for(var i = 0; i < attendanceCostOptionList.length; i++)
    			{
					if(attendanceCostOptionList[i].id == selectVal)
					{
						entity.costOption = attendanceCostOptionList[i];
						break;
					}
				}
    		}
        };

        /**
         * Atualiza os campos sesionIndex e sessionsQuantity seguindo a ordem de prioridade das fontes
         * Também atualiza a aba Pagamento
         */
    	/*private*/ function refreshDynamicData(p_currentSalesRequest, p_currentProcedure)
    	{
    		// Procedure
    		{
    			var procedureId;
    			var resetValue;
	    		// A preferência é para o valor que esteja no Banco de Dados (para edição, readOnly).
	    		if(databaseProcedureId)
	    		{
	    			procedureId = databaseProcedureId;
	    			resetValue = false;
	    		}

	    		// Próximo, procedimento do SalesRequest, caso exista
	    		else if(p_currentSalesRequest && p_currentSalesRequest.procedure)
	    		{
	    			procedureId = p_currentSalesRequest.procedure.id;
	    			resetValue = false;
	    		}

	    		// Próximo, procedimento explicitamente selecionado
	    		else if(p_currentProcedure)
	    		{
	    			procedureId = p_currentProcedure.id;
	    			resetValue = true;
	    		}
	    		// Se nenhum valor encontrado, vazio
	    		else
	    		{
	    			procedureId = null;
	    			resetValue = true;
	    		}

	    		// Ajusta o campo Família Procedimento
	    		if(!procedureId)
	    		{
	    			vm.domain['familyProcedure'] = null;
	    		}
	    		else
	    		{
	    			Procedure.getFamilyProcedureByProcedure({id: procedureId}, function(p_familyProcedure)
	    			{
	    				vm.domain['familyProcedure'] = p_familyProcedure;
	    			});
	    		}

	    		// No próximo cíclo para dar tempo de criar a função
	    		if(vm.pageConfig.pageMode != 'CREATE')
	    		{
		    		$timeout(function()
		    		{
		    			vm.syncProcedure(procedureId, resetValue);
		    		}, 1);
	    		}
    		}

    		// SessionsQuantity
    		{
    			var quantity;
	    		// A preferncia é para o valor que esteja no Banco de Dados (para edição, readOnly). Se for edição, este campo deve ser anulado
	    		if(databaseSessionsQuantity)
	    		{
	    			quantity = databaseSessionsQuantity;
	    		}

	    		// Próximo, procedimento do SalesRequest, caso exista
	    		else if(p_currentSalesRequest && p_currentSalesRequest.procedure)
	    		{
	    			quantity = p_currentSalesRequest.procedure.sessionsQuantity;
	    		}

	    		// Próximo, procedimento explicitamente selecionado
	    		else if(p_currentProcedure)
	    		{
	    			quantity = p_currentProcedure.sessionsQuantity;
	    		}
	    		// Se nenhum valor encontrado, vazio
	    		else
	    		{
	    			quantity = null;
	    		}
	    		vm.domain.sessionsQuantity = quantity;
    		}
    		// SessionIndex
    		{
    			var index;
    			// Se o objeto existe, usa-o
	    		if(databaseSessionIndex)
	    		{
	    			index = databaseSessionIndex;
	    		}

	    		// Próximo, SalesRequest existe, usa  a quantidade + 1
	    		else if(p_currentSalesRequest)
	    		{
	    			index = p_currentSalesRequest.numRealizedSessions + 1;
	    		}
	    		// Se não, 1
	    		else
	    		{
	    			index = 1;
	    		}
	    		vm.domain.sessionIndex = index;
    		}
    	};

    	/**
    	 * Evento chamado no onChange do componente de seleção de CLiente, quando habilitado
    	 */
    	/*private*/ function onChangeClient(p_clientSelectedArray)
    	{
    		try
    		{
	    		var clientId = null;
	    		if(p_clientSelectedArray && p_clientSelectedArray.length > 0 && p_clientSelectedArray[0].id)
	    		{
	    			clientId = p_clientSelectedArray[0].id;
	    		}
	    		vm.syncClient(clientId);
    		}
    		catch(eee)
    		{
    			console.log("Errot at onChangeClient: " + eee.message);
    		}
    	};

	    /*private*/ function createSaveButton()
	    {
	    	return {
	        	order: 0,
	        	cssClass: 'btn btn-success',
	        	cssClassIcon: 'glyphicon glyphicon-save',
	        	label: 'client.global.label.save',
	        	type: 'submit',
	        	isDisabled: function()
	        	{
	        		var flag = true;
	        		if(pageConfig.pageMode == "CREATE")
	        		{
	        			// Se for criação, habilitado
	        			flag = !vm.permissions.hasCreatePermission;
	        		}
	        		else if(pageConfig.pageMode == "READONLY")
	        		{
	        			// Readonly, só para salvar os eventos
	        			flag = !vm.permissions.hasEventsEditPermission;
	        		}
	        		else if(pageConfig.pageMode == "EDIT")
	        		{
	        			// Readonly, só para salvar os eventos
	        			flag = !vm.permissions.hasEditPermission;
	        		}
        			return flag || vm.buttonsModel.isSaving;
	        	},
	        	isToAdd: function(){ return true; },
	        	click: function() { /* void - por ser submit, a ação está no ng-submit do form */}
	        };
	    };

	    /*private*/ function createEditButton()
	    {
	    	return {
	        	order: 5,
	        	cssClass: 'btn btn-accent',
	        	cssClassIcon: 'glyphicon glyphicon-pencil',
	        	label: 'client.global.label.edit',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		return !vm.permissions.hasEditPermission || vm.buttonsModel.isSaving;
	        	},
	        	isToAdd: function()
	        	{
	        		// Só para READONLY
	        		return (pageConfig && pageConfig.pageMode == "READONLY");
	        	},
	        	click: function()
	        	{
	        		$state.go('attendance.edit', {id: $stateParams.id});
	        	}
	        };
	    };


	    /*private*/ function createCancelButton()
	    {
	       	return {
	        	order: 20,
	        	cssClass: 'btn btn-danger',
	        	cssClassIcon: 'fa fa-times',
	        	label: 'client.page.attendance.cancelAttendance',
	        	type: 'button',

	        	isDisabled: function()
	        	{
	        		return !vm.canCancel();
	        	},
	        	isToAdd: function()
	        	{
	        		return true;
	        	},
	        	click: function()
	        	{
	        		$scope.currentAttendance = entity;
	    			$uibModal.open({
	                    templateUrl: 'app/pages/managers/attendance/cancel/cancel-attendance-dialog.html',
	                    controller: 'CancelAttendanceDialogController',
	                    controllerAs: 'vm',
	                    scope: $scope,
	    				resolve: {
	    					cancelAttendanceReasonList: ['CancelAttendanceReason', function(CancelAttendanceReason)
	                        {
	                        	return CancelAttendanceReason.listAllActive().$promise;
	                        }]
	    				}
	                }).result.then(function()
	                {
	                	$state.reload();
	                }, function() {
	                	/*void*/
	                });

	        	}
	    	};
	    };

		 /*private*/ function createReopenButton()
		 {
			return {
			 order: 20,
			 cssClass: 'btn btn-success',
	         cssClassIcon: 'glyphicon glyphicon-refresh',
	         label: 'client.page.attendance.reopenAttendance',
			 type: 'button',
			 isToAdd: function()
			 {
				return vm.canReopen();
			 },
			 click: function()
			 {
				Attendance.reopen({id: entity.id}, function(res, putResponseHeaders) {
					console.log(res);
					$state.reload();
				});
			 }
		 };
	 };
	 /*private*/ function createAttendanceTagButton()
	 {
		return {
			order: 401,
			cssClass: 'btn btn-default',
			cssClassIcon: 'glyphicon glyphicon-print',
			label: 'client.page.attendance.attendanceTag.title',
			type: 'button',
			isDisabled: function()
			{
				return !vm.permissions.hasEditPermission || vm.buttonsModel.isSaving;
			},
			isToAdd: function()
			{
				return (vm.pageConfig.pageMode != "CREATE");
			},
			click: function()
			{
				console.log(vm.clientData);
				AttendanceTagDialogInvoker.invoke({entity: vm.domain, clientData: vm.clientData});
			}
		};
	};

	    /*private*/ function createRecordsButton()
	    {
	       	return {
	        	order: 400,
	        	cssClass: 'btn btn-info',
	        	cssClassIcon: 'fa fa fa-user-md',
	        	label: 'client.page.attendance.records',
	        	type: 'button',

	        	isDisabled: function()
	        	{
	        		return false;
	        	},
	        	isToAdd: function()
	        	{
	        		// Criação não se aplica
	        		if(vm.pageConfig.pageMode != 'CREATE')
	        		{
	        			// Busca do backend
	        			return (isToShowRecordsButton.value === true);
	        		}
	        		else
	        		{
	        			return false;
	        		}
	        	},
	        	click: function()
	        	{
	            	RecordsModalInvoker.invoke({
	            		client: entity.salesRequest.client,
	            		attendanceId: entity.id,
						attendanceProcedureId: databaseProcedureId
	            	});
	        	}
	    	};
	    };

	    /*private*/ function createBackButton()
	    {
	       	return {
	        	order: 500,
	        	cssClass: 'btn btn-default',
	        	cssClassIcon: 'glyphicon glyphicon-arrow-left',
	        	label: 'client.global.label.back',
	        	type: 'button',

	        	isDisabled: function()
	        	{
	        		return false;
	        	},
	        	isToAdd: function()
	        	{
	        		return true;
	        	},
	        	click: function()
	        	{
	        		$state.go('attendance');
	        	}
	    	};
	    };

	    /*private*/ function createSendConfirmationButton()
	    {
	       	return {
	        	order: 100,
	        	cssClass: 'btn btn-accent',
	        	cssClassIcon: 'fa fa-envelope',
	        	label: 'client.page.attendance.sendConfirmationEmail',
	        	type: 'button',

	        	isDisabled: function()
	        	{
	        		return !vm.hasAttendancePermissions || (entity && entity.confirmated);
	        	},
	        	isToAdd: function()
	        	{
	        		return !!$stateParams.id;
	        	},
	        	click: function()
	        	{
	    			$rootScope.confirm(
	    			{
	    				message: $translate.instant("client.page.attendance.sendConfirmationEmailConfirmation"),
	    				callback: function()
	    				{
	    					Attendance.sendConfirmationEmail({id: $stateParams.id});
	    				}
	    			});
	        	}
	    	};
	    };

	    /*private*/ function createWhatsappConfirmationMessageButton()
	    {
	       	return {
	        	order: 110,
	        	cssClass: 'btn btn-success',
	        	cssClassIcon: 'fa fa-whatsapp',
	        	label: 'client.page.attendance.whatsappConfirmationMessage',
	        	type: 'button',

	        	isDisabled: function()
	        	{
	        		return !vm.hasAttendancePermissions || (entity && entity.confirmated);
	        	},
	        	isToAdd: function()
	        	{
	        		return !!$stateParams.id;
	        	},
	        	click: function()
	        	{
	            	// Seta o currentAttendance com o domain corrente
	            	$scope.currentAttendance = entity;

	    			$uibModal.open({
	                    templateUrl: 'app/pages/managers/attendance/message/confirmation-message-template-dialog.html',
	                    controller: 'ConfirmationMessageTemplateDialogController',
	                    controllerAs: 'vm',
	                    scope: $scope
	                });
	        	}
	    	};
	    };

        vm.buttonsModel = {
        	useSuggestedButton: false,
        	gridId: "ATTENDANCE",
        	stateRoot: "attendance",
        	extraButtonList: [
        		createSaveButton(),
        		createEditButton(),
        		createCancelButton(),
				createReopenButton(),
        		createSendConfirmationButton(),
        		createWhatsappConfirmationMessageButton(),
        		createRecordsButton(),
				createAttendanceTagButton(),
        		createBackButton()
        	]
        };

        /*********************************************************/

	    /*private*/ /*class*/ function AttendanceSalesRequestManager(p_pageConfig, p_entity, p_salesRequest)
	    {
	    	var self = this;

	    	/*public*/
	    	this.readOnly = false;

	    	/**
	    	 * Inicializa o objeto
	    	 */
	    	/*private*/ function init()
	    	{
		    	// Se for criação...
		    	if(p_pageConfig.pageMode == "CREATE")
		    	{
		    		// ... e o SalesRequest existir
		    		if(p_salesRequest)
		    		{
		    			// Seta o saleRequest e deixa os campos readonly
		    			setSalesRequest(p_salesRequest);
		    		}
		    		else // Sem SalesRequest, seta o label informado
		    		{
		    			p_entity.salesRequestLabel = $translate.instant("client.page.attendance.noSalesRequestSelected");
		    		}
		    	}
		    	else // Se não for criação, utiliza o SalesRequest do objeto
		    	{
		    		setSalesRequest(p_entity.salesRequest);
		    	}
	    	};

	    	/**
	    	 * Carrega os objetos controlados pelo salesRequest e seta o componente como readOnly
	    	 */
	    	/*private*/ function setSalesRequest(p_currentSalesRequest)
	    	{
	    		self.readOnly = true;

	    		if(p_currentSalesRequest)
	    		{
	    			p_entity.salesRequestId = p_currentSalesRequest.id;
		    		p_entity.salesRequestLabel = p_currentSalesRequest.id;
		    		p_entity.client = [p_currentSalesRequest.client];
		    		p_entity.procedure = p_currentSalesRequest.procedure;
	    		}
	    		else
	    		{
	    			p_entity.salesRequestId = null;
		    		p_entity.salesRequestLabel = null;
		    		p_entity.client = null;
		    		p_entity.procedure = null;
	    		}
	    		refreshDynamicData(p_currentSalesRequest, null);
	    	};

		    // Chamado no final para dar tempo da classe ser criada
		    init();
		}
    };
    /*private*/ /*static*/ function extractInitalClientId(p_entity, p_selectSalesRequestOnCreate) {
		try {
			// Se for edição, busca do domain...
			if (p_entity.id) {
				return p_entity.salesRequest.clientIdFk;
			}
			// Se for criação, utilizando um salesRequest, busca do parâmetro do componente
			if (p_selectSalesRequestOnCreate) {
				return p_entity.client[0].id;
			}
			// Se não é edição e é criação sem template: nulo
			return null;
		}
		catch (eee) {
			console.log("Erro at extract initial client id: " + eee.message);
			return null;
		}
	}
    /*private*/ /*static*/ function PermissionManager(p_userPermissions, p_attendance)
    {
    	this.hasCreatePermission = p_userPermissions.hasCreate;

        // Admin - Seta todas as outras como true no DTO
        this.hasAdminPermission = p_userPermissions.hasAdmin;

        // Para o botão Editar
        this.hasEditPermission = (p_userPermissions.hasMain || p_userPermissions.hasEvents || p_userPermissions.hasPayment || p_userPermissions.hasAdmin);

        // Para os campos do formulário principal que são editáveis
        this.hasMainEditPermission = (p_userPermissions.hasMain);

        // Para os campos de pagamento editáveis
        this.hasPaymentEditPermission = (p_userPermissions.hasPayment);

        // Para os eventos que já ocorreram
        this.hasEventsEditPermission = (p_userPermissions.hasEvents);

        // Pode confirmar
        this.hasCanConfirmationEditPermission = (p_userPermissions.hasCanConfirmation);

        // Pode desconfirmar
        this.hasCanUndoConfirmationEditPermission = (p_userPermissions.hasCanUndoConfirmation);

        // Pode reagendar
        this.hasRescheduleEditPermission = (p_userPermissions.hasReschedule);

        // Pode cancelar
        this.hasCancelEditPermission = (p_userPermissions.hasCancel || !!(p_userPermissions.hasCancelCurrentDay && p_attendance && p_attendance.canBeCanceledCurrentDay));
    }
})();
