var DEBUG_MODE = false;

var SERIALIZED_LIST_SEPARATOR_TOKEN = ";";

var DEFAULT_CK_EDITOR_HEIGHT = 400;

function debug(p_)
{
	if(DEBUG_MODE)
	{
		console.log(">>> " + p_);
	}
};

function removeHTML(p_)
{
	if(!p_)
	{
		return p_;
	}
	return String(p_).replace(/<[^>]+>/gm, '')
};

function handleAuthentication(p_vm, $scope, Principal, $state)
{
    p_vm.account = null;
    p_vm.isAuthenticated = null;
    p_vm.register = register;
    
    
    $scope.$on('authenticationSuccess', function()
    {
        getAccount();
    });

    getAccount();

    function getAccount()
    {
        Principal.identity().then(function(account)
        {
        	p_vm.account = account;
        	p_vm.isAuthenticated = Principal.isAuthenticated;
            
            if(account === null)
            {
            	$state.go('login');
            }
        });
    }
    function register ()
    {
        $state.go('register');
    }
};

function copyJson(p_source, p_attrList, p_customOperationFunction)
{
	if(p_source === null)
	{
		return null;
	}
	else
	{
		var json = {};
		if(p_source && p_attrList)
		{
			for(var i = 0 ; i < p_attrList.length ; i++)
			{
				json[p_attrList[i]] = p_source[p_attrList[i]];
			}
		}
		if(p_customOperationFunction)
		{
			p_customOperationFunction(json);
		}
		return json;
	}
};

function throwCriticalError(p_message)
{
	alert(p_message);
	throw p_message;
};

var UcUtil = 
{
	b: function(p_)
	{
		return "<b>" + p_ + "</b>";
	},
	randomString: function(p_length)
	{
		var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var str = '';
        for (var i = 0; i < p_length; i++) {
            var randomPoz = Math.floor(Math.random() * charSet.length);
            str += charSet.substring(randomPoz, randomPoz + 1);
        }
        return str;
	}
};

function nvlNull(p_)
{
	if(p_ || p_ === false || p_ === 0)
	{
		return p_;
	}
	else
	{
		return null;
	}
};

function nvlNotNull(p_)
{
	if(p_ || p_ === false || p_ === 0)
	{
		return p_;
	}
	else
	{
		return "";
	}
};

function isNull(p_)
{
	return (nvlNull(p_) === null);
};

function serializeArray(p_)
{
	var str = "";
	if(p_)
	{
		for(var i = 0 ; i < p_.length ; i++)
		{
			if(i > 0)
			{
				str += ",";
			}
			str += p_[i];
		}
	}
	return str;
};


function objectKeysToArray(p_set)
{
	var arr = [];

	var keyArray = Object.keys(p_set);
	for(var i = 0 ; i < keyArray.length ; i++)
	{
		arr.push(p_set[keyArray[i]]);
	}
	return arr;
};

function isValidDate(p_date)
{
	if(p_date)
	{
		if(Object.prototype.toString.call(p_date) === "[object Date]")
		{
			// it is a date
			if(isNaN(p_date.getTime()))  // p_date.valueOf() could also work
			{
				return false; // date is not valid
			}
			else
			{
				return true; // date is valid
			}
		}
		else
		{
			return false; // not a date
		}
	}
	return false; // null, 0, empty, undefined, etc
};

/**
 * Completa o tamanho da String com '0's a esquerda
 */
var WATCHDOG_COMPLETE_LEFT_WITH_ZERO = 100;
function completeLeftWithZero(p_val, p_length)
{
	p_val = (!p_val) ? "" : p_val.toString();
	
	while(p_val.length < p_length && p_val.length < WATCHDOG_COMPLETE_LEFT_WITH_ZERO)
	{
		p_val = "0" + p_val;
	}
	return p_val;
}


function createNDate(y, mo, d, h, mi, s)
{
	return new Date(y, mo, d, (h ? h : 0), (mi ? mi : 0), (s ? s : 0));
//	var date = new Date(y, mo, d, (h ? h : 0), (mi ? mi : 0), (s ? s : 0));
//	return new Date(date.valueOf() - (date.getTimezoneOffset() * 60*1000));
};

/**
 * Retorna a primeira hora do dia
 */
function getFirstTimeDay(p_refDate)
{
	// Se não tiver definido uma data referência, utiliza a corrente
	if(!p_refDate)
	{
		p_refDate = new Date();
	}
	return createNDate(p_refDate.getFullYear(), p_refDate.getMonth(), p_refDate.getDate(), 0, 0, 0);
};

/**
 * Retorna a última hora do dia
 */
function getLastTimeDay(p_refDate)
{
	// Se não tiver definido uma data referência, utiliza a corrente
	if(!p_refDate)
	{
		p_refDate = new Date();
	}
	return createNDate(p_refDate.getFullYear(), p_refDate.getMonth(), p_refDate.getDate(), 23, 59, 59);
}

function userHasPermission(p_account, p_permission)
{
	if(p_account && p_permission && p_account.authorities)
	{
		var arr = p_account.authorities;
		for(var i = 0 ; i < arr.length ; i++)
		{
			if(arr[i] == p_permission)
			{
				return true;
			}
		}
	}
	return false;
}

function userHasAnyPermission(p_account, p_permissionArray)
{
	if(p_account && p_permission && p_account.authorities && p_permissionArray)
	{
		var arr = p_account.authorities;
		for(var i = 0 ; i < arr.length ; i++)
		{
			for(var j = 0 ; j < p_permissionArray.length ; j++)
			{
				if(arr[i] == p_permissionArray[j])
				{
					return true;
				}
			}
		}
	}
	return false;
}

function toCamelCase(p_str)
{
	return p_str.toLowerCase().replace("_", " ")
	    .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
	    .replace(/\s/g, '')
	    .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
}

/**
 * Extrai os ids e retorna como lista.
 * Se o parâmetro for único, retorna lista tamanho 1
 */
function extractIdList(p_value)
{
	var idList = [];
	if(p_value)
	{
		// É array?
		if(angular.isArray(p_value))
		{
			for(var i = 0; i < p_value.length; i++)
			{
				if(p_value[i].id)
				{
					idList.push(p_value[i].id);
				}
			}
		}
		else
		{
			if(p_value.id)
			{
				idList.push(p_value.id);
			}
		}
	}
	return idList;
};

/**
 * Serializa a lista separada por ';'
 */
function serializeList(p_list)
{
	var ser = "";
	if(p_list)
	{
		for(var i = 0; i < p_list.length; i++)
		{
			if(i > 0)
			{
				ser += SERIALIZED_LIST_SEPARATOR_TOKEN;
			}
			ser += p_list[i];
		}
	}
	return ser;
};

/**
 * Para funcionar é neccesário que o elemento da tab (class="tab-pane") tenho o atributo tab-id
 * @returns
 */
function findTabWithError()
{
	return $(".form-validator-message div").closest(".tab-pane").attr("tab-id");
}

/**
 * Para destruir os listener
 */
function toDestroy(p_scope, p_listener)
{
	if(p_scope)
	{
		p_scope.$on('$destroy', function()
    	{
    		if(p_listener)
    		{
    			p_listener();
    		}
    	});
	}
};

function cloneObj(p_)
{
	if(!p_)
	{
		return p_
	}
	else
	{
		// TODO_CODE: implementar
		return p_;
	}
};

/**
 * Compara 2 Doubles com precisão decimal
 */
function compareDouble(p_0, p_1)
{
	if(!p_0)
	{
		p_0 = 0;
	}
	if(!p_1)
	{
		p_1 = 0;
	}
	return (Math.round(p_0 * 100) - Math.round(p_1 * 100));
};

function unescapeHtml(p_msg)
{
	return $("#js-textarea-util").html(p_msg).text();
}

function copyToClipboard(p_text, p_okCallback, p_failCallback)
{
	$("#js-textarea-util").val(p_text);
	$("#js-textarea-util").css({display: "block"})
	$("#js-textarea-util").select();
	try
	{
		var successful = document.execCommand('copy');
		$("#js-textarea-util").css({display: "none"});
		if(successful)
		{
			if(p_okCallback){ p_okCallback(); }
		}
		else
		{
			if(p_failCallback){ p_failCallback(); }
		}
	}
	catch (err)
	{
		$("#js-textarea-util").css({display: "block"});
		if(p_failCallback){ p_failCallback(err.message); }
	}
}

function generatePrintCssStyle()
{
	var code = '';
	code += '<style>';
	code += '	@page \n';
	code += '	{ \n';   
	code += '		size: 19.2cm 26.44cm; \n';
	code += '		margin: 0mm; \n';
	code += '	} \n';
	code += '	@media print \n';
	code += '	{ \n';
	code += '		BODY \n';
	code += '		{ \n';
	code += '			background-color: #FFFFFF; \n';
	code += '		} \n';
	code += '	} \n';
	code += '	@media screen \n';
	code += '	{ \n';
	code += '		BODY \n';
	code += '		{ \n';
	code += '			background-color: #525659; \n';
	code += '			padding-bottom: 15px; \n';
	code += '		} \n';
	code += '		DIV.print-page \n';
	code += '		{ \n';
	code += '			background-image:url(https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/printer/print1.jpg); \n';
	code += '			background-repeat: no-repeat; \n';
	code += '			background-size: 19.2cm 26.44cm; \n';
	code += '			margin: auto; \n';
	code += '			margin-top: 15px; \n';
	code += '			zoom: 75%; \n';
	code += '		} \n';
	code += '	} \n';
	code += '	BODY \n';
	code += '	{ \n';
	code += '		background-size: 19.2cm 26.44cm; \n';
	code += '		-webkit-print-color-adjust: exact; \n';
	code += '		font-family: sans-serif, Arial, Verdana, "Trebuchet MS"; \n';
	code += '		font-size: 13px; \n';
	code += '		color: #333; \n';
	code += '		margin:0px; \n';
	code += '		word-break: break-all; \n';
	code += '	} \n';
	code += '	DIV.print-page \n';
	code += '	{ \n';
	code += '		width: 13.2cm; \n';
	code += '		line-height: 18px; \n';
	code += '		padding-left: 3cm; \n';
	code += '		padding-right: 3cm; \n';
	code += '		min-height:	16.44cm; \n';
	code += '		padding-top: 5cm; \n';
	code += '		padding-bottom: 3.8cm; \n';
	code += '		page-break-after: always; \n';
	code += '	} \n';
	code += '	DIV.print-page-fixed-height \n';
	code += '	{ \n';
	code += '		height:	16.44cm;  \n';
	code += '	} \n';
	code += '	DIV.textContent \n';
	code += '	{ \n';
	code += '		line-height: 25px; \n';
	code += '	} \n';
	code += '	DIV.records-print-header \n';
	code += '	{ \n';
	code += '		padding-top: 10px; \n';
	code += '		padding-bottom: 10px; \n';
	code += '	} \n';
	code += '	DIV.records-print-header DIV.service-request \n';
	code += '	{ \n';
	code += '		padding-top: 10px; \n';
	code += '	} \n';
	code += '	A \n';
	code += '	{ \n';
	code += '		color: #333; \n';
	code += '	} \n';
	code += `.header-title {
		font-weight: bold;
	}`
	code += '</style>';
	return code;
}

function generateTemplateCssStyle()
{
	var code = '';
	code += '<style>';
	code += '	@page '; 
	code += '	{ ';
	code += '	    size: auto; ';
	code += '	    margin: 0mm; ';
	code += '	} ';
	code += '	@media print { ';
	code += '		BODY ';
	code += '		{ ';
	code += '			margin-top: 264px !important; ';
	code += '			margin-left: 120px !important; ';
	code += '			margin-right: 120px !important; ';
	code += '		} ';
	code += '	} ';
	code += '	BODY ';
	code += '	{ ';
	code += '		background-color: #FFFFFF; ';
	code += '		font-family: sans-serif, Arial, Verdana, "Trebuchet MS"; ';
	code += '		font-size: 12px; ';
	code += '		color: #333; ';
	code += '		margin: 20px; ';
	code += '		padding: 30px;';
	code += '	} ';
	code += '	DIV.textContent ';
	code += '	{ ';
	code += '		line-height: 25px; ';
	code += '	} ';
	code += '	DIV.records-print-header ';
	code += '	{ ';
	code += '		padding-top: 10px; ';
	code += '		padding-bottom: 10px; ';
	code += '	} ';
	code += '	DIV.records-print-header DIV.service-request';
	code += '	{ ';
	code += '		padding-top: 10px; ';
	code += '	} ';
	code += '	A ';
	code += '	{ ';
	code += '		color: #333; ';
	code += '	} ';
	code += '	.template-selected-record-area .template-selected-record-item ';
	code += '	{ ';
	code += '		border:dashed 1px #DDDDDD;';
	code += '	} ';
	code += '</style>';
	return code;
}

function isSameDay(p_1, p_2)
{
	if(isValidDate(p_1) && isValidDate(p_2))
	{
		return (p_1.getFullYear() == p_2.getFullYear() &&
		p_1.getMonth() == p_2.getMonth() &&
		p_1.getDate() == p_2.getDate());
	}
	return false;
}

function getStaticController()
{
	return angular.element("#uc-static-controller").controller();
}

function staticAlertService(p_message, p_config)
{
	getStaticController().alertService(p_message, p_config);
}

function safeExec(p_function)
{
	try
	{
		return p_function();
	}
	catch(exc)
	{
		console.error("Error at safeExec: " + exc.message);
		return null;
	}
}

var _CKEditorInitialized = false;
/**
 * @returns instance of editorElement (use setHtml to set content) 
 */
function initCKEditor(p_componentId, p_height, p_initialContent)
{
	if(!p_height)
	{
		p_height = DEFAULT_CK_EDITOR_HEIGHT;
	}
	
	
	if ( CKEDITOR.env.ie && CKEDITOR.env.version < 9 )
	{
		CKEDITOR.tools.enableHtml5Elements( document );
	}
	// Já iniciado?
	if(!_CKEditorInitialized)
	{
		_CKEditorInitialized = true;
		
		CKEDITOR.config.toolbar = [
			['Bold','Italic','Underline','StrikeThrough','-','Undo','Redo','-','Cut','Copy','Paste','Find','Replace','-','Outdent','Indent','-','Print'],
			['NumberedList','BulletedList','-','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
			['Image','Table','-','Link','Flash','Smiley','TextColor','BGColor','Source']
		] ;
		
		
		  // Set defaults for tables
		  CKEDITOR.on( 'dialogDefinition', function( ev )
		  {
		     // Take the dialog name and its definition from the event
		     // data.
		     var dialogName = ev.data.name;
		     var dialogDefinition = ev.data.definition;

		     // Check if the definition is from the dialog we're
		     // interested on (the "Table" dialog).
		     if ( dialogName == 'table' )
		     {
		         // Get a reference to the "Table Info" tab.
		         var infoTab = dialogDefinition.getContents( 'info' );
		         txtWidth = infoTab.get( 'txtWidth' );
		         txtWidth['default'] = "100%";
//		         cmbWidthType = infoTab.get( 'cmbWidthType' );
//		         cmbWidthType['default'] = 'percents';
//		         txtCellPad = infoTab.get( 'txtCellPad' );
//		         txtCellPad['default'] = 4;
		     }
		   });		
	}
	

	// The trick to keep the editor in the sample quite small
	// unless user specified own height.
	CKEDITOR.config.height = p_height;
	CKEDITOR.config.width = 'auto';

	var wysiwygareaAvailable;
	
	if ( CKEDITOR.revision == ( '%RE' + 'V%' ) )
	{
		wysiwygareaAvailable = true;
	}
	else
	{
		wysiwygareaAvailable = !!CKEDITOR.plugins.get( 'wysiwygarea' );
	}

	// Depending on the wysiwygare plugin availability initialize classic or inline editor.
	var ckEditor;
	if ( wysiwygareaAvailable )
	{
		ckEditor = CKEDITOR.replace(p_componentId,
		{
			on:
			{
				'instanceReady': function(evt)
				{
					//Set the focus to your editor
					ckEditor.focus();
				}
			}
		});
	}
	else
	{
		editorElement.setAttribute( 'contenteditable', 'true' );
		ckEditor = CKEDITOR.inline( p_componentId );
	}		
	
	var editorElement = CKEDITOR.document.getById(p_componentId);
	if(p_initialContent)
	{
		editorElement.setHtml(p_initialContent);
	}

//	setTimeout(function()
//	{
//		ckEditor.resize('100%', p_height);
//	}, 500);
//
	return ckEditor;
}		
