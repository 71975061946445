(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Client', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'familyProcedureList'];

    function ClientController(FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, Client, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, familyProcedureList)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;
        
        vm.buttonsModel =
        {
        	stateRoot: "client",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
        };

        $rootScope.initialFocus("id");
        
        var formOptions = {
        	formStatePersisterId: "Client"
        };
        
        var familyProcedureFormField = new FormField({
       		cellCssClass: "col-sm-4",
       		type: "Select",
			id: "familyProcedureList",
			dependencyName: "familyProcedureListId",
			label: "client.page.attendance.familyProcedure",
			multiple: true,
			dataSource: FrontEndSelectDataSource.create(familyProcedureList)
    	});
        
        vm.formHandler = FormFilter.create(formOptions)
		    .add([familyProcedureFormField,
	    		new FormField({
		       		cellCssClass: "col-sm-4",
		       		type: "Select",
					id: "procedureList",
					label: "common.domain.salesRequest.procedure",
					multiple: true,
					dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, true)
	        	}),
		        new FormField({
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "medicList",
					multiple: true,
					label: "client.page.client.medic",
					dataSource: FormConstants.DataSources.BackEnd.Professional(BackEndSelectDataSource, $translate)
				})		    		
		    ])
        	.add([new FormField({
	           		cellCssClass: "col-sm-4",
					type: "String",
					id: "id",
					label: "common.domain.client.id"
				}),
	        	new FormField({
	           		cellCssClass: "col-sm-4",
					type: "String",
					id: "cpf",
					label: "common.domain.client.cpf"
				}),
	        	new FormField({
	           		cellCssClass: "col-sm-4",
					type: "String",
					id: "ci",
					label: "common.domain.client.ci"
				})
		    ])
		    .add([new FormField({
		       		cellCssClass: "col-sm-4",
					type: "String",
					id: "name",
					label: "common.domain.client.name"
		        }),
	        	new FormField({
		       		cellCssClass: "col-sm-4",
					type: "Select",
					id: "active",
					label: "client.global.label.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
	        	}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "phones",
					label: "common.domain.client.phones"
			 }),
		    ])
			.endConfig();
        
        vm.gridFieldList = UcGridService.createFieldList([
        	UcGridService.field.AVATAR(),
        	{headerName: $translate.instant("common.domain.client.id"), field: "id", cellStyle: {alignItems: "center", textAlign: "center"}, width: 5},
        	{headerName: $translate.instant("common.domain.client.cpf"), field: "cpf", cellStyle: {alignItems: "center", textAlign: "center"}, width: 11},
        	{headerName: $translate.instant("client.global.label.name"), field: "name", width: 27},
        	{headerName: $translate.instant("client.global.label.cel"), field: "cel", width: 11},
        	{headerName: $translate.instant("client.global.label.age"), field: "age", cellStyle: {alignItems: "center", textAlign: "center"}, width: 4},
        	{headerName: $translate.instant("client.global.label.city"), field: "city", width: 13},
        	{headerName: $translate.instant("client.global.label.medic"), field: "medic", width: 16, suppressSorting: true},
        	{headerName: $translate.instant("client.page.client.clientClassification"), field: "classification", cellStyle: {alignItems: "center", textAlign: "center"}, width: 6},
        	UcGridService.field.BOOLEAN({field: "active", title: "client.global.label.active", width: 6, suppressSizeToFit: false})
        ]);
        
        

        var grid = UcGridService.create(
        {
        	gridId: "CLIENT",
        	stateBaseName: "client",
        	account: account,
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList,
        	rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
        	sort: vm.sort
        });
        
        vm.gridOptions = grid.getGridOptions();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        
        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};

    }
})();
