(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('SalesRequestInvoiceDetailDialogInvoker', SalesRequestInvoiceDetailDialogInvoker);

    SalesRequestInvoiceDetailDialogInvoker.$inject = ['$uibModal'];

    function SalesRequestInvoiceDetailDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/entities/sales-request-invoice/sales-request-invoice-detail-dialog/sales-request-invoice-detail-dialog.html',
				controller: 'SalesRequestInvoiceDetailDialogController',
				controllerAs: 'vm',
				size: 'lg',
				backdrop: 'static',
				resolve: {
					pageConfig: [function()
					{
						return {
						};
					}],
					listNaturezaOperacao: ['SalesRequestInvoice', function(SalesRequestInvoice)
					{
						return SalesRequestInvoice.listNaturezaOperacao();
					}],
					entity: p_param.entity,
					hasCreatePermission: p_param.hasCreatePermission
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();