(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('SearchModal', SearchModal);

    SearchModal.$inject = ['$resource', 'URL', 'SERVICES'];

    function SearchModal ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/search-modal';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
