/**
 * Classe que encapsula uma mensagem de alerta do sistema
 */
function UcAlert(p_response, p_msg, p_isErrorMessage)
{
	'use strict';
	var ALERT_TYPE_HEADER = "X-Alert-Type-Header";
	var ALERT_LEVEL_HEADER = "X-Alert-Level-Header";
	
	var self = this;
	
	// Contador de em quantas diretivar o objeto passou
	this.registerOnDirectiveCount = 0;
  
	// Tipo de alerta
	this.alertType = retrieveAlertType();

	// Nível do alerta
	this.alertLevel = retrieveAlertLevel();

	
	this.msg = p_msg;
	
	/**
	 * Registra a passagem do objeto por uma diretiva, mesmo que não seja exibido
	 */
	this.registerReceivedOnDirective = function()
	{
		this.registerOnDirectiveCount++;
	};
    
	/**
	 * Tipo da mensagem: BOX, GROWL, ALERT ou TOAST. 
	 * Default: ALERT para erro e GROWL para aviso
	 */
    /*private*/ function retrieveAlertType()
    {
    	var token = null;
    	// Primeiro procura pelo valor explicito (mockResponse)
    	if(p_response && p_response.explicitType)
		{
    		token = p_response.explicitType;
		}
    	else
    	{
	    	try
	    	{
	    		token = p_response.headers(ALERT_TYPE_HEADER);
	    	}
	    	// Falhou em algum ponto
	    	catch(e){ /* silent */ }
    	}
		// Valor válido?
		if(token == "ALERT" || token == "BOX" || token == "GROWL" || token == "TOAST")
		{
			return token;
		}
    	
    	return p_isErrorMessage ? "ALERT" : "GROWL";
    }
    
    /**
     * Tipo da mensagem: ERROR, WARNING, INFO ou SUCCESS. 
     * Default: ERROR para erro e INFO para aviso
     */
	// 
    /*private*/ function retrieveAlertLevel()
    {
    	var ERROR = new UcAlertLevel("ERROR", "danger", "fa fa-exclamation-triangle", "btn btn-danger", "Erro");
    	var INFO = new UcAlertLevel("INFO", "info", "fa fa-info-circle", "btn btn-primary", "Aviso");
    	
    	var token = null;
    	
    	// Primeiro procura pelo valor explicito (mockResponse)
    	if(p_response && p_response.explicitLevel)
		{
    		token = p_response.explicitLevel;
		}
    	else
    	{
        	try
        	{
        		token = p_response.headers(ALERT_LEVEL_HEADER);
        	}
        	// Falhou em algum ponto ou é INFO
        	catch(e){ /* silent */ }
    	}

		if(token == "ERROR")
		{
			return ERROR;
		}
		else if(token == "WARNING")
		{
			return new UcAlertLevel("WARNING", "warning", "fa fa-exclamation-triangle", "btn btn-warning", "Alerta");
		}
		else if(token == "SUCCESS")
		{
			return new UcAlertLevel("SUCCESS", "success", "fa fa-check", "btn btn-primary", "Aviso");
		}
		else if(token == "INFO")
		{
			return INFO;
		}
    	
    	return p_isErrorMessage ? ERROR : INFO;
    }
};

function UcAlertLevel(p_id, p_cssClass, p_cssIcon, p_buttonCssClass, p_alertTitle)
{
	this.id = p_id;
	this.cssClass = p_cssClass;
	this.cssIcon = p_cssIcon;
	this.buttonCssClass = p_buttonCssClass;
	this.alertTitle = p_alertTitle;
}

