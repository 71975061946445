(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('Account', Account);

    Account.$inject = ['$resource', 'URL', 'SERVICES'];

    function Account ($resource, URL, SERVICES) {

        var url = URL[SERVICES.environment].api;

        var service = $resource( url + 'api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });

        return service;
    }
})();
