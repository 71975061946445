(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('Expense', Expense);

    Expense.$inject = ['$resource', 'URL', 'SERVICES'];

    function Expense ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/expense/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'cancel': { method: 'GET', url: url + 'api/expense/cancel'},
            'listExpenseStatusType': { method: 'GET', url: url + 'api/expense/list-expense-status', isArray: true },
            'listExpenseTimeStatusType': { method: 'GET', url: url + 'api/expense/list-expense-time-status', isArray: true },
            'listInstallmentsquantity': { method: 'GET', url: url + 'api/expense/list-installments-quantity', isArray: true },
        });
    }
})();
