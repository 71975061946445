(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('LoginModalInvoker', LoginModalInvoker);

    LoginModalInvoker.$inject = ['$uibModal'];

    function LoginModalInvoker($uibModal)
    {
		var vm = this;
		
		var open = false;
		
		/*public*/ vm.invoke = function(p_param)
		{
			// Somente um aberto de cada vez
			if(open)
			{
				return;
			}
			
			open = true;
			try
			{
				$uibModal.open({
					templateUrl: 'app/components/login/login-modal.html',
					controller: 'LoginModalController',
					controllerAs: 'vm',
					backdrop  : 'static',
					keyboard  : false,
					resolve: {
						hasCallback: function()	{
							return p_param.callback != null;
						},
	                }
				}).result.then(function(credentials)
				{
					open = false;
					if(p_param.callback)
					{
						p_param.callback(credentials);
					}
				}, function() {
					open = false;
				});
			}
			catch(exc)
			{
				open = false;
				console.error("Error at open LoginModalController: " + exc.message);
			}
		}
    }
})();