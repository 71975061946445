(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ManufacturerDomainController', ManufacturerDomainController);
    
    ManufacturerDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'pageConfig', '$stateParams'];

    function ManufacturerDomainController(FormDomain, $scope, $state, entity, pageConfig, $stateParams)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
        vm.injectedResources = { 
        	
        };
    };
})();
