(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('TherapyPlaceController', TherapyPlaceController);

    TherapyPlaceController.$inject = ['FormFilter', 'UcGridService', '$translate', 'filterPageConfig', 'gridDefaultSort', 'FrontEndSelectDataSource', 'FormConstants', '$rootScope', 'TherapyPlace'];

    function TherapyPlaceController(FormFilter, UcGridService, $translate, filterPageConfig, gridDefaultSort, FrontEndSelectDataSource, FormConstants, $rootScope, TherapyPlace)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;
        
        $rootScope.initialFocus("name");
        
        vm.buttonsModel =
        {
        	stateRoot: "therapyPlace",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
        };

        var formOptions = {
        	formStatePersisterId: "TherapyPlace"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([new FormField({
		       		cellCssClass: "col-sm-4",
					type: "String",
					id: "code",
					label: "common.domain.therapyPlace.code"
		        }),
		        new FormField({
		       		cellCssClass: "col-sm-4",
					type: "String",
					id: "description",
					label: "common.domain.therapyPlace.description"
		        }),
		        new FormField({
		       		cellCssClass: "col-sm-4",
					type: "Select",
					id: "active",
					label: "common.domain.therapyPlace.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
		    })])
			.endConfig();
        
        vm.gridFieldList = UcGridService.createFieldList([
        	{headerName: $translate.instant("common.domain.therapyPlace.code"), field: "code", width: 25},
        	{headerName: $translate.instant("common.domain.therapyPlace.description"), field: "description", width: 70},
            UcGridService.field.ACTIVE()	// width: 5
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "THERAPY_PLACE",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "therapyPlace",
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList, 
        	sort: vm.sort,
        });
        
        vm.gridOptions = grid.getGridOptions();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        
        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};
	}
})();
