(function() {
'use strict';

angular.module('sgmApp')
	.constant('URL', {
    local : {
 //     api : 'http://192.168.56.1:8089/'
 //     api : 'http://52.179.187.112:8080/dev/'
//    	api : 'http://192.168.1.102:8089/'
		api : 'http://localhost:8089/'
    },
    dev : {
        //api : 'https://sgrsis.com/dev/'
		api : 'http://localhost:8089/'
    },
    uc : {
      //api : 'https://sgrsis.com/uc/'
		api : 'http://sgm-docker.sa-east-1.elasticbeanstalk.com/'
    },
    prod : {
    //  api : 'https://easy-clinic-hom.sa-east-1.elasticbeanstalk.com/'
      api : 'https://sgrsis.com/'
	  //api : 'http://sgm-docker.sa-east-1.elasticbeanstalk.com/'
      // api:''
    },
    hom : {
       //api : 'http://easyclinic.sgrsis.com/sgr_hom/'
		  api : 'https://hml.sgrsis.com/'
    }
  })

})();
