(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('procedurePlace', {
            parent: 'entity',
            url: '/procedurePlace?page&sort',
            data: {
                authorities: [PermissionSet.PROCEDURE_PLACE.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'ProcedurePlaceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                gridDefaultSort: function()
                {
                	return {
	            		"name": "description", 
	            		"type": "asc"
                	};
            	},
            	tabList: function()
                {
                	return [
                		{code: "main", icon: "glyphicon glyphicon-home", label: "client.global.label.registrationData"},
                        {code: "schedulesAvailable", icon: "glyphicon glyphicon-calendar", label: "client.page.procedurePlace.schedulesAvailable"}
                	];
                },
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.procedurePlace.title',
	                	icon: $rootScope.menuIcons.cadastre.procedurePlace,
	                	formValidateTarget: 'ProcedurePlace',
	                	permission: PermissionSet.PROCEDURE_PLACE
                	})
                }]
            }
        })
        .state('procedurePlace.detail', {
            parent: 'procedurePlace',
            url: '/{id}/detail',
            views: {
                'content@': {
                	templateUrl: 'app/entities/procedure-place/procedure-place-domain.html',
                    controller: 'ProcedurePlaceDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['$stateParams', 'ProcedurePlace', function($stateParams, ProcedurePlace)
                {
                    return ProcedurePlace.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]            
            }
        })
        .state('procedurePlace.edit', {
            parent: 'procedurePlace',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/entities/procedure-place/procedure-place-domain.html',
                    controller: 'ProcedurePlaceDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['ProcedurePlace', '$stateParams', function(ProcedurePlace, $stateParams)
                {
                    return ProcedurePlace.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('procedurePlace.new', {
            parent: 'procedurePlace',
            url: '/new',
            views: {
                'content@': {
                	templateUrl: 'app/entities/procedure-place/procedure-place-domain.html',
                    controller: 'ProcedurePlaceDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {}
                },
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }

})();
