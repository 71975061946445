(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('historyModalIcon', historyModalIconDirective);
    
    historyModalIconDirective.$inject = [];
    function historyModalIconDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "HistoryModalIconDirectiveController",
            templateUrl: "app/components/history/directive/history-modal-icon.directive.template.html",
            scope:
            {
            	config: '='
            }
    	}
    };
})();
