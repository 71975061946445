(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('UserController', UserController);

    UserController.$inject = ['FormFilter', 'UcGridService', '$translate', 'roleList', 'filterPageConfig', 'gridDefaultSort', 'FrontEndSelectDataSource', 'FormConstants', '$rootScope', 'User'];

    function UserController(FormFilter, UcGridService, $translate, roleList, filterPageConfig, gridDefaultSort, FrontEndSelectDataSource, FormConstants, $rootScope, User)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;
        
        $rootScope.initialFocus("name");
        
        vm.buttonsModel =
        {
        	stateRoot: "user",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
        };

        var formOptions = {
        	formStatePersisterId: "User"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([new FormField({
		       		cellCssClass: "col-sm-4",
					type: "String",
					id: "name",
					label: "common.domain.user.name"
		        }),
		        new FormField({
		       		cellCssClass: "col-sm-4",
					type: "Select",
					id: "role",
					label: "client.global.label.roles",
					multiple: true,
					dataSource: FrontEndSelectDataSource.create(roleList)
		        }),
		        new FormField({
		       		cellCssClass: "col-sm-4",
					type: "Select",
					id: "active",
					label: "common.domain.user.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
		    })])
			.endConfig();
        
        
        
        vm.gridFieldList = UcGridService.createFieldList([
        	UcGridService.field.AVATAR(),
        	{headerName: $translate.instant("common.domain.user.cpf"), field: "cpf", width: 10},
        	{headerName: $translate.instant("common.domain.user.name"), field: "name", width: 25},
            {headerName: $translate.instant("common.domain.user.email"), field: "email", width: 20},
            {headerName: $translate.instant("client.global.label.roles"), field: "rolesName", width: 20},
            UcGridService.field.ACTIVE()	// width: 5
//            UcGridService.button.ACTION({
//				btnClass: "btn-primary",
//				fontIcon: "fa fa-lock",
//				hint: "client.page.user.resetPassword",
//				action: function(p_row)
//				{
//					resetPasword(p_row.data);
//				}
//            })
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "USER",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "user",
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList, 
        	rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
        	sort: vm.sort,
        });
        
        vm.gridOptions = grid.getGridOptions();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        
        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};
		
//		/*private*/ function resetPasword(p_data)
//		{
//			var message = $translate.instant("client.page.user.resetPasswordMessageP0", {user: ":p0"}) + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
//			
//			// Atribui o valor por replace porque o parametro do $translate está escaping
//			message = message.replace(":p0", UcUtil.b(p_data.defaultDescription));
//			$rootScope.confirm(
//			{
//				message: message,
//				callback: function()
//				{
//					User.resetPassword(
//						{
//							id: p_data.id
//						},
//						function()
//						{
//							doFilter()
//						}
//					);
//				} 
//			});
//		}
	}
})();
