(function () {
    'use strict';
    angular
        .module('sgmApp')
        .constant('SgmConstants', {
        	Profile:
        	{
        		Medical: "MED"
        	}
        })
        .constant('menuIcons', {
        	myAccount: "fa fa-user",
        	password: "fa fa-lock",
        	cadastre:
        	{
        		familyProcedure: "pe page-header-icon pe-7s-network",
        		paymentInstallments: "pe page-header-icon pe-7s-cash",
        		paymentOption: "pe page-header-icon pe-7s-credit",
        		procedure: "page-header-icon fa fa-medkit",
        		procedurePlace: "page-header-icon fa fa-hospital-o",
        		specialty: "pe page-header-icon pe-7s-note2",
        		therapyPlace: "fa fa-map-marker",
				user: "pe page-header-icon pe-7s-users",
				expenseType: "pe page-header-icon fa fa-sticky-note-o",
				expenseCostCenter:"pe page-header-icon fa fa-toggle-off",
				supplierCategory:"pe page-header-icon fa fa-level-up",
				supplierSubcategory:"pe page-header-icon fa fa-level-down",
				equipment:"pe page-header-icon fa fa-laptop",
				measureUnit:"pe page-header-icon fa fa-balance-scale",
				manufacturer:"pe page-header-icon fa fa-truck ",
				product:"pe page-header-icon fa fa-medkit",
				productKit:"pe page-header-icon fa fa-medkit",
				stockFlowMotive:"pe page-header-icon fa fa-medkit",
				stockPlace: "fa fa-map-marker",
				prontuaryModel: "pe page-header-icon pe-7s-note2",
				role: "pe page-header-icon pe-7s-users",
        	},
        	main:
        	{
        		attendance: "pe page-header-icon pe-7s-note2",
				client: "pe page-header-icon fa fa-users",
				expense: "pe page-header-icon fa fa-usd",
				supplier:"pe page-header-icon fa fa-truck ",
				manufacturerProductStockFlow:"pe page-header-icon fa fa-medkit",
				productStockControl:"pe page-header-icon fa fa-medkit"
        	},
        	managers:
        	{
        		agenda:
        		{
        			agendaLockingTime: "fa fa-ban",
        			agendaReminder: "fa fa-bell-o"
        		}
        	}
        })
       .constant('SearchIcons', {
        	client: "pe page-header-icon fa fa-users"
        })
    ;
})();