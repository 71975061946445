/**
 * Gerencia as fotos carregadas do Backend.
 * As fotos são carregadas em cache local para evitar tráfego de rede desnecessário
 * O cache é limpado de tempos em tempos para otimizar o uso da memória
 */

(function() {
    'use strict';

    angular
        .module('sgmApp')
        .service('PhotoManager', PhotoManager);

    PhotoManager.$inject = ['UcService', '$timeout', '$interval'];
    
    /**
     * Tempo para o objeto expirar (recolhido pelo GarbageColector)
     * Em millissegundos
     */
    var EXPIRED_TIME_IN_MILLIS = 5 * 60 * 1000; // 5 minutos
    
    /**
     * Intervalo para a execução do GC em millis
     */
    var GC_INTERVAL_IN_MILLIS = 5 * 60 * 1000; // 5 minutos
    
    function PhotoManager (UcService, $timeout, $interval)
    {
    	var vm = this;
    	
    	/*
    	 * Cache local
    	 */
    	/*private*/ var cache = {};
    	
    	/*
    	 * Garbage Collector
    	 * Monitora o cache a cada 5 minutos e verifica se tem item a ser excluído 
    	 */
    	/*private*/var gc = $interval(function()
    	{
    		debug("Disparou GC");
    		
    		var nowInMillis = (new Date()).getTime();

    		// Percorre todos os objetos no map e verifica se estão expirados
    		angular.forEach(cache, function(p_value, p_key)
    		{
    			if(!p_value || !p_value.expiredAt || (p_value.expiredAt < nowInMillis))
    			{
	    			debug("EXCLUIU do cache " + p_key)
	    			delete cache[p_key];
    			}
    		});
    		
    	}, GC_INTERVAL_IN_MILLIS);

    	/*public*/ vm.reset = function()
    	{
    		cache = {};
    	};
    	
    	/*public*/ vm.loadAvatar = function(p_imageId, p_targetId, p_extraClazz)
    	{
    		internalLoad("AVATAR", p_imageId, p_targetId, false, p_extraClazz);
    	};

    	/*public*/ vm.loadImage = function(p_imageId, p_targetId, p_extraClazz)
    	{
    		internalLoad("SQUARE300", p_imageId, p_targetId, true, p_extraClazz);
    	};

    	
    	/*private*/ function internalLoad(p_imageType, p_imageId, p_targetId, p_replace, p_extraClazz)
    	{
    		// Procura no cache
    		var cacheKey = createCacheKey(p_imageId, p_imageType);
    		var imageDto = cache[cacheKey];
    		
    		// Achou?
    		if(imageDto)
    		{
    			debug("Recuperou do cache: " + cacheKey);
    			applyImage(imageDto, p_targetId, p_replace, p_extraClazz);
    		}
    		else // Não acho. Busca no Backend e guarda no cache
    		{
    			debug("Não encontrado no cache: " + cacheKey);
        		UcService.loadPhoto({photoId: p_imageId, photoType: p_imageType}, function(p_imageDto)
	    		{
        			debug("Adicionou ao cache: " + cacheKey);
        			cache[cacheKey] = p_imageDto;
        			p_imageDto.expiredAt = createExpiresTime();
        			applyImage(p_imageDto, p_targetId, p_replace, p_extraClazz);
	    		});
    		}
    	};
    	
    	/*private*/ function applyImage(p_imageDto, p_targetId, p_replace, p_extraClazz)
    	{
    		var imageSource;
			var AWS_S3_URL="https://s3-sa-east-1.amazonaws.com/sgrsis.com/client/";
    		if(p_imageDto.image)
    		{
    			//imageSource = "data:" + p_imageDto.image.contentType + ";base64," + p_imageDto.image.bytes;
				imageSource = p_imageDto.image.imageLink;

    		}
    		else
    		{

    			imageSource = "https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/profile-" + (p_imageDto.gender.id == "M" ? "male" : "female") + ".png";
    		}
			if(p_replace)
			{
				var closeButton = '<button type="button" class="bootbox-close-button close" aria-hidden="true" onclick="$(\'.' + p_targetId + '\').closest(\'.photo-popover-wrapper\').remove();">×</button>';
				$("." + p_targetId).html("<div class='photo-close'>" + closeButton + "</div><div><img class='" + p_extraClazz + "' src='" + imageSource + "'/></div>");
			}
			else
			{
				$("." + p_targetId).attr("src", imageSource);
			}
    	};
    	
    	/**
    	 * Período em que o objeto expira do cache. Sempre em relação ao tempo atual
    	 */
    	/*private*/ function createExpiresTime()
    	{
    		// soma o intervalo a data corrent
    		var nowInMillis = (new Date()).getTime();
    		return nowInMillis + EXPIRED_TIME_IN_MILLIS;
    	}

    	/*private*/ function createCacheKey(p_imageId, p_imageType)
    	{
    		return p_imageId + ";" + p_imageType;
    	};
    }
})();
