(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('ProntuaryFormDialogInvoker', ProntuaryFormDialogInvoker);

    ProntuaryFormDialogInvoker.$inject = ['$uibModal'];

    function ProntuaryFormDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/components/prontuary-form/prontuary-form-dialog/prontuary-form-dialog.html',
				controller: 'ProntuaryFormDialogController',
				controllerAs: 'vm',
				size: 'lg',
				backdrop: 'static',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
					prontuaryFormConfig: p_param.prontuaryFormConfig,
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();