(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('ManufacturerProductStockFlow', ManufacturerProductStockFlow);

    ManufacturerProductStockFlow.$inject = ['$resource', 'URL', 'SERVICES'];

    function ManufacturerProductStockFlow ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/manufacturer-product-stock-flow/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'count': { 
                method: 'GET', 
                url: url + 'api/manufacturer-product-stock-flow/count',
                transformResponse: function(data) {
                    return {value: data};
                }
            },
            'totalPrice': { 
                method: 'GET', 
                url: url + 'api/manufacturer-product-stock-flow/total-price',
                transformResponse: function(data) {
                    return {value: data};
                }
            },
            'sum': { 
                method: 'GET', 
                url: url + 'api/manufacturer-product-stock-flow/sum',
                transformResponse: function(data) {
                    return {value: data};
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'createInStockFlow': { method: 'POST', url: url + 'api/manufacturer-product-stock-flow/create-type-in'},
            'createOutStockFlow': { method: 'POST', url: url + 'api/manufacturer-product-stock-flow/create-type-out'},
            'update': { method:'PUT' },
            'listAllActive': {method: 'GET', url: url + 'api/manufacturer-product-stock-flow/list-all-active', isArray: true},
            'listStockFlowTypes': {method: 'GET', url: url + 'api/manufacturer-product-stock-flow/list-stock-flow-types', isArray: true},
            'getManufacturerProductStockInfo': {method: 'GET', url: url + 'api/manufacturer-product-stock-flow/manufacturer-product/stock-info/:manufacturerProductId', isArray: true},
            'updateOldFlows': {method: 'GET', url: url + 'api/manufacturer-product-stock-flow/update-old-flows' },
        });
    }
})();
