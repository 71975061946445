(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ExpenseCostCenterDomainController', ExpenseCostCenterDomainController);

	ExpenseCostCenterDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'ExpenseCostCenter', 'pageConfig', '$rootScope', 'FormConstants', 'FrontEndSelectDataSource'];

	function ExpenseCostCenterDomainController(FormDomain, $scope, $state, entity, ExpenseCostCenter, pageConfig, $rootScope, FormConstants, FrontEndSelectDataSource) {
		var vm = this;

		vm.domain = entity;
		vm.pageConfig = pageConfig;

		$rootScope.initialFocus("code");

		vm.buttonsModel = {
			isSaving: false,
			gridId: "EXPENSE_COST_CENTER",
			stateRoot: "expenseCostCenter",
			previousState: { name: $state.current.parent },
			onClear: function () { vm.formHandler.clear(); }
		};

		var formOptions = {
			model: entity,
			pageConfig: pageConfig,
			buttonsModel: vm.buttonsModel,
			service: ExpenseCostCenter,
			historyConfig: { historyId: "EXPENSE_COST_CENTER" },
			getForm: function () { return $scope.editForm; }
		};
		vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
				cellCssClass: "col-sm-6",
				type: "String",
				id: "id",
				required: true,
				label: "client.global.label.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})
			])
			.add([new FormField({
				cellCssClass: "col-sm-12",
				type: "String",
				id: "code",
				required: true,
				label: "common.domain.expenseCostCenter.code",
				maxLength: 30
			})
			])
			.add([new FormField({
				cellCssClass: "col-sm-12",
				type: "String",
				id: "description",
				required: true,
				label: "common.domain.expenseCostCenter.description",
				maxLength: 300
			})
			])
			.add([new FormField({
				cellCssClass: "col-sm-12",
				type: "Boolean",
				id: "active",
				label: "client.global.label.active",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})
			])
			.endConfig();

        /*public*/ vm.save = function () {
			vm.formHandler.save();
		}
	}
})();
