(function() {
	'use strict';

	/**
	 * Exibe as indicações feitas pelo contato da empresa
	 */
	angular
		.module('sgmApp')
		.controller('IndicationsManagerTabIndicationsController', IndicationsManagerTabIndicationsController);

	IndicationsManagerTabIndicationsController.$inject = ['$scope', '$translate', '$timeout', 'UcGridService', 'UcAlertService', '$uibModal', 'Client'];
	function IndicationsManagerTabIndicationsController($scope, $translate, $timeout, UcGridService, UcAlertService, $uibModal, Client) {
		var vm = $scope.vm;
		var entity = $scope.vm.domain;
		var formHandler = vm.formHandler;

		var injectedResources = vm.injectedResources;





		if (entity && entity.id) {
			var sort = {
				"name": "name",
				"type": "desc"
			};


			var gridFieldList = UcGridService.createFieldList([
				{ headerName: $translate.instant("common.domain.client.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5 },
				{ headerName: $translate.instant("common.domain.client.cpf"), field: "cpf", cellStyle: { alignItems: "center", textAlign: "center" }, width: 11 },
				{ headerName: $translate.instant("client.global.label.patient"), field: "name", width: 27 },
				{ headerName: $translate.instant("client.global.label.cel"), field: "cel", width: 11 },
				UcGridService.field.MONEY({ headerName: $translate.instant("client.global.label.firstAttendance"), field: "salesRequestFinalValue", cellStyle: { alignItems: "left" }, width: 4 }),
			]);


			var grid = UcGridService.create(
				{
					gridId: "INDICATIONS_MANAGER_ATTENDANCE",
					//					getFilterData: function() { return { indicationsManagerId: entity.id }; },
					gridFieldList: gridFieldList,
					rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
					sort: sort,
					onRowClicked: function(client) {
						showAttendanceAlert(client.data.id);
					},
					gridData: function(data) {
						// Quando o parâmetro gridData é informado o UcGridService ignora a chamada padrão do getFilterData				
						return Client.getClientByIndicationsManager({ id: entity.id,page:data.page-1,size:50 });
					}
				});

			vm.indicationsGridOptions = grid.getGridOptions();


			// Busca o total de indicações
			injectedResources.Client.countSalesByIndicationsManager({ id: entity.id }).$promise.then(function(data) {
				vm.ammountClient = data.value;
			});

			// Busca o valor total de indicações "Somente primeiro atendimento"
			injectedResources.Client.totalClientByIndicationsManager({ id: entity.id }).$promise.then(function(data) {
				vm.firstAttendance = data.value;
			});
		}

        /*private*/ function onSummarySelect() {
			if (entity && entity.id) {
				$timeout(function() {
					grid.refresh();
				}, 1);
			}
		};

		// Para o load na aba
		onSummarySelect();

		$scope.$on('client-manager.refreshTab.indications', function() {
			onSummarySelect();
		});

		/**
		 * Abre o modal de atendimentos do cliente
		 */
		var showAttendanceAlert = function(clientId) {

			UcAlertService.dismissBox();
			$uibModal.open({
				templateUrl: 'app/pages/managers/indications/modal/client-attendance-list.html',
				controller: 'ClientAttendanceListController',
				controllerAs: 'vm',
				size: 'lg',
				resolve:
				{
					entidade: ['Client', function(Client) {
						return Client.get({ id: clientId }).$promise;
					}]
				}
			});
		}
	
	};
})();