(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProcedureProntuaryModelsTabController', ProcedureProntuaryModelsTabController);
    
    ProcedureProntuaryModelsTabController.$inject = ['$scope', 'FormConstants', 'ConfirmDialogInvoker', '$translate', '$timeout', 'ProcedureProntuaryModelDialogInvoker', 'Procedure', '$uibModal', 'ProntuaryFormDialogInvoker'];
    function ProcedureProntuaryModelsTabController($scope, FormConstants, ConfirmDialogInvoker, $translate, $timeout, ProcedureProntuaryModelDialogInvoker, Procedure, $uibModal, ProntuaryFormDialogInvoker)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        function callBackAddProntuaryModel(prontuaryModel) {
        	if(prontuaryModel){
                Procedure.addProntuaryModel({procedureId: entity.id, prontuaryModelId : prontuaryModel.id}, function(res, putResponseHeaders) {
                    vm.prontuaryModels.push(prontuaryModel);
                })
        	}
        }



        function callBackRemoveProntuaryModel(prontuaryModel, index){
            Procedure.removeProntuaryModel({procedureId: entity.id, prontuaryModelId : prontuaryModel.id}, function(res, putResponseHeaders) {
                vm.prontuaryModels.splice(index,1);
            });
        }


        vm.removeProntuaryModel = function(prontuaryModel, index){
            ConfirmDialogInvoker.invoke(
                {
                    callback: function(res) { 
                        if(res) {
                            callBackRemoveProntuaryModel(prontuaryModel, index);
                        } 
                    }, 
                    text: $translate.instant("client.page.procedure.tab.prontuaryModel.confirmRemoveProntuary")
                }
            );
        }

        vm.viewProntuaryModel = function(prontuaryModel) {
            var prontuaryFormConfig = {
                pageMode: 'READONLY',
                prontuary: prontuaryModel
            }
            
            ProntuaryFormDialogInvoker.invoke(
                {
                    prontuaryFormConfig: prontuaryFormConfig
                }
            )
        }

        vm.addProntuaryModel = function(){
            
        	ProcedureProntuaryModelDialogInvoker.invoke({callback: callBackAddProntuaryModel});
        }

    };
})();