(function() {
    'use strict';

	angular.module('sgmApp').service('SalesRequestModalInvoker', SalesRequestModalInvoker);
	
	SalesRequestModalInvoker.$inject = ['$uibModal'];
	
	function SalesRequestModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(/*SalesRequestModalInvokerParam*/ p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/modal/sales-request/sales-request-modal-dialog.html',
                controller: 'SalesRequestModalController',
                controllerAs: 'vm',
                size: 'lg',
                windowClass: 'modal-dialog-90-percent',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "FILTER",
    	                	formValidateTarget: 'SelectSalesRequest'
                    	})
                    }],
                    familyProcedureList: ['FamilyProcedure', function(FamilyProcedure)
                    {
                    	return FamilyProcedure.listAllIncludeInactive();
                    }],
                    salesRequestStatusList: ['SalesRequest', function(SalesRequest)
                    {
                    	return SalesRequest.listStatus();
                    }],
                    clientList: function()
                    {
                    	return p_param.clientList;
                    },
                    procedureList: function()
                    {
                    	return p_param.procedureList;
                    },
                    showGoWithoutSelection: function()
                    {
                    	return p_param.showGoWithoutSelection;
                    },
                    attendanceInput: function()
                    {
                    	return p_param.attendanceInput;
                    },
                    onlyOpen: function()
                    {
                    	return p_param.onlyOpen;
                    },
					schedulable: ['Attendance', function(Attendance)
					{
						if(p_param.attendanceInput.schedulableId)
						{
							return Attendance.getSchedulable({schedulableId: p_param.attendanceInput.schedulableId}).$promise;
						}
						else
						{
							return null;
						}
					}],
                    procedure: ['Procedure', function (Procedure){
						if(p_param.attendanceInput.procedureId){
	            			return Procedure.get({id:p_param.attendanceInput.procedureId}).$promise;
						}else{
							return null;
	            		}
					}],
					familyProcedure: ['FamilyProcedure', function (FamilyProcedure){
						if(p_param.attendanceInput.familyProcedureId){
	            			return FamilyProcedure.get({id:p_param.attendanceInput.familyProcedureId}).$promise;
						}else{
							return null;
	            		}
					}]
                }
            }).result.then(function(p_salesRequest)
            {
            	if(p_param.selectCallback)
            	{
            		p_param.selectCallback(p_salesRequest);
            	}
            }, function()
            {
            	if(p_param.cancelCallback)
            	{
            		p_param.cancelCallback();
            	}
            });
		};
		
	};

	var _DEFAULT_PARAM =
	{
		clientList: null,
		procedureList: null,
		showGoWithoutSelection: false,
		onlyOpen: false,
		selectCallback: null,
		cancelCallback: null
	};

	function SalesRequestModalInvokerParam(p_param)
	{
		// Carrega os valores default
		angular.extend(this, _DEFAULT_PARAM);
		
		// Sobreescreve só os passados como parâmetro
		angular.extend(this, (p_param) ? p_param : {});

		
	}
})();
