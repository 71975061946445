(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('Test3Controller', Test3Controller);

    Test3Controller.$inject = ['$scope', 'Test3Service', 'UcDatasourceService', '$timeout', 'Attendance', 'UcAlertService', '$translate', '$rootScope'];
    
    

    function Test3Controller ($scope, Test3Service, UcDatasourceService, $timeout, Attendance, UcAlertService, $translate, $rootScope)
    {
        var vm = this;
        
        vm.list = [];
        
        var medicoId = 10008; // José Rogério
        var sala1 = "SL1";

        /**
         * Exemplo verificação de conflito de agenda
         */
        $timeout(function()
        {
        	checkConflictExample();
        }, 1);
        
        
        
        /**
         * agenda-locking - Para bloqueios recorrrentes - Análogo ao availability-schedule
         * list-agenda-time - Para bloqueios por horário - Análogo ao attendance
         */
        
        $timeout(function()
        {
//        	testAgendaLocking();

        	
        }, 1);
        
        
        function checkConflictExample()
        {
            var param = {
            	id: null, // Passar o ID quando for reagendamento, para não dar conflito com ele mesmo
            	schedulableId: medicoId,
            	startDate: '2017-10-05 14:09:00', // Sempre como String
            	durationMinutes: 40
            };
            Attendance.checkSchedulingConflict(param, function(p_feedback)
            {
            	if(p_feedback.ok)
            	{
            		excute();
            	}
            	else
            	{
        			var message = $translate.instant("client.page.attendance.currentAttendanceIsInConflictWithSchedulesBelow");
        			message += "<ul>";
        			for (var i = 0; i < p_feedback.conflictList.length; i++)
        			{
                		if(p_feedback.conflictList)
                		{
                			for (var i = 0; i < p_feedback.conflictList.length; i++)
                			{
                				message += '<li>' + p_feedback.conflictList[i] + '</li>';
        					}
                		}
    				}
        			message += "</ul>" + $translate.instant("client.global.messages.info.confirmOperation")

        			$rootScope.confirm(
        			{
        				message: message,
        				callback: function()
        				{
        					excute();
        				} 
        			});
            	}
            });
        }
        
        function excute()
        {
        	alert("Criar Atendimento aqui")
        }
        
        function testAgendaLocking()
        {
        	/*
        	 * Criar - id nulo
        	 */
//        	refreshAgendaLocking([
//	        	{
//	        		id: null, 
//	        		idSchedulable: medicoId,
//	        		weekDay: 6,  
//	        		startTime: '11:10', 
//	        		endTime: '11:20', 
//	        		description: "Título 1"
//	        	}
//	        ]);
//        	refreshAgendaLocking([
//	        	{
//	        		id: null, 
//	        		idSchedulable: sala1,
//	        		weekDay: 2,  
//	        		startTime: '07:00', 
//	        		endTime: '8:45', 
//	        		description: "Título Sala"
//	        	}
//        	]);

        	
        	/*
        	 * Update - id preenchido
        	 */
//        	refreshAgendaLocking([
//	        	{
//	        		id: 5, 
//	        		idSchedulable: sala1,
//	        		weekDay: 2,  
//	        		startTime: '07:30', 
//	        		endTime: '8:00', 
//	        		description: "Título Sala2"
//	        	}
//	    	]);        	

        	
        	/*
        	 * Delete
        	 */
        	deleteAgendaLocking(5);         	
        	
        	/**
        	 * Lista todas as reservas recorrentes do agendável
        	 */
        	listAgendaLocking(sala1);
        }
        
        function listAgendaLocking(p_schedulableId)
        {
        	Test3Service.listAgendaLocking( {id: p_schedulableId}, function(p_list)
        	{
        		if(!p_list.length)
        		{
        			console.log("Lista vazia")
        		}
        		vm.list = p_list;
        	});
        }
        
        function refreshAgendaLocking(p_listJson)
        {
        	Test3Service.refreshAgendaLocking(p_listJson, function(p_idList)
        	{
        	});
        }
        
        function deleteAgendaLocking(p_id)
        {
        	Test3Service.deleteAgendaLocking({id: p_id}, function() {
        	});
        }
        
        /****************************************************************
         * **************************************************************
         * **************************************************************
         */
        
        function testAgendaLocking()
        {
        	/*
        	 * Criar - id nulo
        	 */
//        	refreshAgendaLockingTime([
//	        	{
//	        		id: null, 
//	        		idSchedulable: medicoId,
//	        		start: "2017-08-26 18:00:00",  
//	        		end: "2017-08-26 19:00:00",
//	        		allDay: false,
//	        		description: "Bloqueio por horário 1"
//	        	}
//	        ]);
//        	refreshAgendaLockingTime([
//	        	{
//	        		id: null, 
//	        		idSchedulable: sala1,
//	        		start: "2017-08-22 02:00:00",  
//	        		end: "2017-08-22 0:00",  
//	        		description: "Bloqueio por horário 2"
//	        	}
//	        ]);

        	
        	/*
        	 * Update - id preenchido
        	 */
//        	refreshAgendaLockingTime([
//	        	{
//	        		id: 4, 
//	        		idSchedulable: sala1,
//	        		start: "2017-08-22 04:00:00",  
//	        		end: "2017-08-22 05:00:00",  
//	        		description: "Bloqueio por horário 2 alt"
//	        	}
//	        ]);

        	
        	/*
        	 * Delete
        	 */
        	 deleteAgendaLockingTime(4);         	
        	
        	/**
        	 * Lista todas as reservas recorrentes do agendável
        	 */
        	listAgendaLockingTime(sala1);
        }
        
        function listAgendaLockingTime(p_schedulableId)
        {
        	Test3Service.listAgendaLockingTime( {id: p_schedulableId}, function(p_list)
        	{
        		if(!p_list.length)
        		{
        			console.log("Lista vazia")
        		}
        		vm.list2 = p_list;
        	});
        }
        
        function refreshAgendaLockingTime(p_listJson)
        {
        	Test3Service.refreshAgendaLockingTime(p_listJson, function(p_idList)
        	{
        	});
        }
        
        function deleteAgendaLockingTime(p_id)
        {
        	Test3Service.deleteAgendaLockingTime({id: p_id}, function() {
        	});
        }
    }
    
})();
