(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('ProcedurePlace', ProcedurePlace);

    ProcedurePlace.$inject = ['$resource', 'URL', 'SERVICES'];

    function ProcedurePlace ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/procedure-place/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'listAllActive': { method: 'GET', url:  url + 'api/procedure-place/list-all-active', isArray: true },
            //AvailabilitySchedule
            'delete': { method: 'DELETE', url: url + 'api/availability-schedule-procedure-place/:id'},
            'listAvailabilitySchedule': { method: 'GET', url: url + 'api/availability-schedule-procedure-place/list-availability-schedule/:id', isArray: true},
            'refreshAvailabilitySchedule': {  method: 'POST', url: url + 'api/availability-schedule-procedure-place/refresh-availability-schedule', isArray: true},
            'getSchedulable': {url: url + 'api/availability-schedule-procedure-place/get-schedulable/:schedulableId', method: 'GET'},
        });
    }
})();
