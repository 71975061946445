(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('MeasureUnitController', MeasureUnitController);

    MeasureUnitController.$inject = ['FormFilter', 'UcGridService', '$translate', 'filterPageConfig', 'gridDefaultSort', 'FrontEndSelectDataSource', 'FormConstants', '$rootScope', 'MeasureUnit'];

    function MeasureUnitController(FormFilter, UcGridService, $translate, filterPageConfig, gridDefaultSort, FrontEndSelectDataSource, FormConstants, $rootScope, MeasureUnit)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;

        $rootScope.initialFocus("name");

        vm.buttonsModel =
        {
        	stateRoot: "measureUnit",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
        };

        var formOptions = {
        	formStatePersisterId: "MeasureUnit"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([
		        new FormField({
		       		cellCssClass: "col-sm-4",
					type: "String",
					id: "description",
					label: "common.domain.measureUnit.description"
		        })
			])
			.endConfig();

        vm.gridFieldList = UcGridService.createFieldList([
        	{headerName: $translate.instant("common.domain.measureUnit.id"), field: "id", width: 20},
        	{headerName: $translate.instant("common.domain.measureUnit.description"), field: "description", width: 80},
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "MEASURE_UNIT",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "measureUnit",
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList,
        	sort: vm.sort,
        });

        vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter()
        {
        	grid.refresh();
        };

        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};
	}
})();
