(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('user', {
            parent: 'entity',
            url: '/user?page&sort',
            data: {
                authorities: [PermissionSet.USER.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'UserController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                roleList: ['Role', function(Role)
                {
                    return Role.listAllIncludeInactive().$promise;
                }],
                specialtyList: ['Specialty', function(Specialty)
                {
                    return Specialty.listAllIncludeInactive();
                }],
                tabList: function()
                {
                	return [
                		{code: "main", icon: "glyphicon glyphicon-user", label: "client.global.label.registrationData"},
                        {code: "signature", icon: "fa fa-user-md", label: "client.page.user.signature"},
                		{code: "professional", icon: "fa fa-user-md", label: "client.page.user.professionalData"},
                        {code: "photo", icon: "fa fa-camera", label: "client.page.user.photo"},
                        {code: "schedulesAvailable", icon: "glyphicon glyphicon-calendar", label: "client.page.user.schedulesAvailable"}
                	];
                },
                gridDefaultSort: function()
                {
                	return {
	            		"name": "name", 
	            		"type": "asc"
                	};
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.user.title',
	                	icon: $rootScope.menuIcons.cadastre.user,
	                	formValidateTarget: 'User',
	                	permission: PermissionSet.USER
                	})
                }]
            }
        })
        .state('user.detail', {
            parent: 'user',
            url: '/{id}/detail',
            views: {
                'content@': {
                    templateUrl: 'app/entities/user/user-domain.html',
                    controller: 'UserDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['$stateParams', 'User', function($stateParams, User)
                {
                    return User.get({id : $stateParams.id}).$promise;
                }],
                genderList:['User', function(User)
                {
                    return User.listGender().$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }],
                permissionsList:['Role', function(Role)
                {
                    return Role.listAllPermissions().$promise;
                }],       
            }
        })
        .state('user.edit', {
            parent: 'user',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/entities/user/user-domain.html',
                    controller: 'UserDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['User', '$stateParams', function(User, $stateParams)
                {
                    return User.get({id : $stateParams.id}).$promise;
                }],
                genderList:['User', function(User)
                {
                    return User.listGender().$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }],
                permissionsList:['Role', function(Role)
                {
                    return Role.listAllPermissions().$promise;
                }],
            }            
        })
        .state('user.new', {
            parent: 'user',
            url: '/new',
            views: {
                'content@': {
                	templateUrl: 'app/entities/user/user-domain.html',
                    controller: 'UserDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {}
                },
                genderList:['User', function(User)
                {
                    return User.listGender().$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }],
                permissionsList:['Role', function(Role)
                {
                    return Role.listAllPermissions().$promise;
                }],        
            }
        });
    }

})();
