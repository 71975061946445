(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        
        .state('attendanceConfirmation', {
            parent: 'account',
            url: '/attendanceConfirmation/{uniqueId}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/external/attendance/attendance-confirmation.html',
                    controller: 'AttendanceConfirmationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                confirmationData: ['AttendanceConfirmation', '$stateParams', function (AttendanceConfirmation, $stateParams)
                {
                    return AttendanceConfirmation.get({uniqueId: $stateParams.uniqueId}).$promise;
                }] 
            }
        })
        // Sem o ID, apenas para evidenciar o erro
        .state('attendanceConfirmationNoUniqueId', {
            parent: 'account',
            url: '/attendanceConfirmation',
            data: {
                authorities: [],
                pageTitle: 'client.page.forgetPassword.title123'
            },
            views: {
                'content@': {
                    templateUrl: 'app/external/attendance/attendance-confirmation.html',
                    controller: 'AttendanceConfirmationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                confirmationData: function()
                {
                	return {noParam: true};
                }
            }
        });
  
    }
})();
