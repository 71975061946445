(function () {
    'use strict';

    angular
        .module('sgmApp')
        .factory('Test3Service', Test3Service);

    Test3Service.$inject = ['$resource', 'URL', 'SERVICES'];

    function Test3Service ($resource, URL, SERVICES)
    {
        var url = URL[SERVICES.environment].api;
        var service = $resource(url + 'api/agenda-locking/:id', {}, {
        	'listAgendaLocking': {url: url + 'api/agenda-locking/list-agenda-locking/:id', method: 'GET', isArray: true},
        	'refreshAgendaLocking': {url: url + 'api/agenda-locking/refresh-agenda-locking', method: 'POST', isArray: true},
        	'deleteAgendaLocking': {url: url + 'api/agenda-locking/:id', method: 'DELETE'},
        	
        	'listAgendaLockingTime': {url: url + 'api/agenda-locking/list-agenda-locking-time/:id', method: 'GET', isArray: true},
        	'refreshAgendaLockingTime': {url: url + 'api/agenda-locking/refresh-agenda-locking-time', method: 'POST', isArray: true},
        	'deleteAgendaLockingTime': {url: url + 'api/agenda-locking-time/:id', method: 'DELETE'},
        	
        });

        return service;
    }
})();
