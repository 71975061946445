(function() {
    'use strict';

    angular
        .module('sgmApp')
        .filter('phoneNumber', phoneNumber);

    function phoneNumber() {
        return phoneNumber;

        function phoneNumber (tel) {
        	if (!tel) { return ''; }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            var  city, number, end, begin;

            city = value.slice(0, 2);
            number = value.slice(2);
            
            switch (value.length) {
                case 10: //(##)#####-####
                    begin = value.slice(2,6);
                    end = value.slice(6);
                    break;

                case 11: // (##)#####-####
                	begin = value.slice(2,7);
                    end = value.slice(7);
                    break;
                default:
                    return tel;
            }

            number = begin + '-' + end;

            return (" (" + city + ") " + number).trim();
        }
    }
})();
