'use strict';

var ___STATIC_MAP = {};

function getStaticMap(p_key, p_func)
{
	var val = ___STATIC_MAP[p_key];
	if(!val)
	{
		val = p_func();
		___STATIC_MAP[p_key] = val;
	}
	
	return val;
};
