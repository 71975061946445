(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('ForgetPassword', ForgetPassword);

    ForgetPassword.$inject = ['$resource', 'URL', 'SERVICES'];

    function ForgetPassword ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'forgetPassword';

        return $resource(resourceUrl, {}, {
            'resetPasswordExternal': { method: 'PATCH', url: url + 'forgetPassword/resetPasswordExternal' },
        });
    }
})();
