(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('HistoryModalIconDirectiveController', HistoryModalIconDirectiveController);
    
    HistoryModalIconDirectiveController.$inject = ['$scope', 'HistoryModalInvoker', 'Principal', 'PermissionSet'];
    function HistoryModalIconDirectiveController($scope, HistoryModalInvoker, Principal, PermissionSet)
    {
    	var vm = this;
    	
    	vm.config = $scope.config;

/*    	var hasPermission = false;
    	
    	Principal.hasAuthority(PermissionSet.ADMIN).then(
    		function (result)
        	{
    			hasPermission = result;
        	});
  */  	
    	/*public*/ vm.showHistory = function()
    	{
//    		// Tem permissão?
//    		if(!hasPermission)
//    		{
//    			return false;
//    		}
    		
    		return !!vm.config.getId();
    	};
    	
    	/*public*/ vm.openHistory = function()
    	{
    		HistoryModalInvoker.invoke(
    		{
    			id: vm.config.getId(),
    			historyId: vm.config.historyId
    		});
    	};

    };
})();