(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('CancelAttendanceReason', CancelAttendanceReason);

    CancelAttendanceReason.$inject = ['$resource', 'URL', 'SERVICES'];

    function CancelAttendanceReason ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/cancel-attendance-reason/:id';

        return $resource(resourceUrl, {}, {
            'listAllActive': { method: 'GET', url: url + 'api/cancel-attendance-reason/list-all-active', isArray: true }
        });
    }
})();
