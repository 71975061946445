(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('AttendanceProductDialogInvoker', AttendanceProductDialogInvoker);

    AttendanceProductDialogInvoker.$inject = ['$uibModal'];

    function AttendanceProductDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/attendance/tabs/attendance-product/dialog/attendance-product-dialog.html',
				controller: 'AttendanceProductDialogController',
				controllerAs: 'vm',
				size: 'lg',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
					isUpdate: p_param.isUpdate,
					entity: p_param.entity,
					save: function() { 
						return p_param.save 
					},
					closeOnSave: p_param.closeOnSave,
					index: p_param.index,
					length: p_param.length
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();