(function()
{
    'use strict';
    angular
        .module('sgmApp')
        .factory('UcDatasourceService', UcDatasourceService);

    UcDatasourceService.$inject = ['$resource', 'URL', 'SERVICES'];

    function UcDatasourceService ($resource, URL, SERVICES)
    {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/grid';

        return $resource(resourceUrl, {}, {
            'fetch': { method: 'GET', url: url + 'api/grid/fetch' }
        });
    }
})();
