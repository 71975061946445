(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('FormHtmlRenderDirectiveController', FormHtmlRenderDirectiveController);
    
    FormHtmlRenderDirectiveController.$inject = ['$scope', '$translate', 'UcAlertService', '$timeout', '$uibModal', '$rootScope', '$sce'];
    function FormHtmlRenderDirectiveController($scope, $translate, UcAlertService, $timeout, $uibModal, $rootScope, $sce)
    {
    	var vm = this;
    	
    	vm.objModel = $scope.objModel;
    	vm.fieldModel = $scope.fieldModel;
    	var pageMode = $scope.pageConfig.pageMode;
    	// Edição para criação e edição
    	vm.editMode = (pageMode === 'EDIT' || pageMode === 'CREATE' || pageMode === 'FILTER');
    	
    	
    	/**
    	 * Função para decidir se o componente deve ou não ser adicionado ao DOM (ng-if)
    	 * 
    	 * Redundante com FormDynamicDirective
    	 */
    	/*public*/ vm.addField = function()
    	{
    		// Verifica se tem um listenter definido
    		if(vm.fieldModel.addFieldListener)
    		{
    			// Ele que decidi
    			return vm.fieldModel.addFieldListener(vm.objModel); 
    		}
    		else // default é adicionar
    		{
    			return true;
    		}
    	};
    	
    	/**
    	 * Resolve a classe para o input
    	 */
    	/*public*/ vm.resolveClass = function()
    	{
    		var cssClass = vm.fieldModel.cellCssClass;
    		
    		if(vm.fieldModel.hasLabel)
    		{
    			cssClass += " form-group ";
    		}
    		return cssClass; 
    	};
    	
    	
    	/*public*/ vm.resolveLabelClass = function()
    	{
    		//return (vm.fieldModel.required) ? "required_field" : "";
    		return "";
    	};
    	

    	/**
    	 * Resolve a classe para o input
    	 */
    	/*public*/ vm.renderHtml = function()
    	{
    		var code = vm.fieldModel.render(vm.objModel, vm.fieldModel, pageMode, vm.editMode);
    		return vm.fieldModel.compile ? code : $sce.trustAsHtml(code); 
    	};
    };
})();