(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('AgendaReminder', AgendaReminder);

    AgendaReminder.$inject = ['$resource', 'URL', 'SERVICES'];

    function AgendaReminder ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/agenda-reminder/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'saveReminderList': {url: url + 'api/agenda-reminder/save-reminder-list', method:'PUT' },
            'listRemindersRepeatOptions': {url: url + 'api/agenda-reminder/list-reminders-repeat-options', method: 'GET', isArray: true},
            'listByScheduler': {url: url + 'api/agenda-reminder/list-by-scheduler', method: 'GET', isArray: true}
        });
    }
})();
