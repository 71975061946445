(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ManufacturerProductStockFlowDomainController', ManufacturerProductStockFlowDomainController);
    
    ManufacturerProductStockFlowDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'ManufacturerProductStockFlow', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$translate', 'ManufacturerProductStockFlowCommon', 'TabStatePersister'];

    function ManufacturerProductStockFlowDomainController(FormDomain, $scope, $state, entity, ManufacturerProductStockFlow, pageConfig, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $translate, ManufacturerProductStockFlowCommon, TabStatePersister)
    {
        var vm = this;

        vm.domain = entity;
		if(vm.domain){
			vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
		}
        vm.pageConfig = pageConfig;
		var isSaving = false;
	

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "MANUFACTURER_PRODUCT_STOCK_FLOW",
        	stateRoot: "manufacturerProductStockFlow",
			previousState: { name: "manufacturerProductStockFlow" },
			onClear: function()
        	{
        		vm.formHandler.clear();
        	},
        };
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: ManufacturerProductStockFlow,
			afterCreateState: "manufacturerProductStockFlow",
        	historyConfig: {historyId: "MANUFACTURER_PRODUCT_STOCK_FLOW"},
        	getForm: function(){ return $scope.editForm; },
			afterUpdateState: "manufacturerProductStockFlow"
        };
        vm.formHandler = FormDomain.create(formOptions)
		.add([new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.manufacturerProductStockFlow.id",
					readOnly: true
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "createdDate",
					label: "common.domain.manufacturerProductStockFlow.createdDate"
				}),
			])
			.endConfig();
			
			
//        /*private*/ var editor = null;
//        $timeout(function()
//        {
//        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
//        }, 100);


        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("manufacturerProductStockFlow");
        }
    }
})();
