(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceFreeScheduleDialogController', AttendanceFreeScheduleDialogController);

    AttendanceFreeScheduleDialogController.$inject = ['schedulableList', '$scope', '$uibModalInstance', '$uibModal', 'pageConfig', 'FormFilter', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', 'Attendance', '$rootScope', '$filter', 'AgendaReminderDialogInvoker', 'UcGridService', '$timeout', 'UcAlertService', 'defaultDurationList', '$sce'];

    function AttendanceFreeScheduleDialogController(schedulableList, $scope, $uibModalInstance, $uibModal, pageConfig, FormFilter, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, Attendance, $rootScope, $filter, AgendaReminderDialogInvoker, UcGridService, $timeout, UcAlertService, defaultDurationList, $sce)
    {
    	var vm = this;
    	moment.locale('pt-br');
    	vm.filterData = {};
    
    	vm.validationMessage = null;
    	
		var timeZone = (moment.tz('America/Sao_Paulo')._offset/60)*-1;
		
    	vm.pageConfig = pageConfig;
    	var familyProcedureFormField = new FormField({
       		cellCssClass: "col-sm-5",
       		type: "Select",
			id: "familyProcedure",
			dependencyName: "familyProcedureListId",
			label: "client.page.attendance.familyProcedure",
			required: true,
			dataSource: FormConstants.DataSources.BackEnd.FamilyProcedure(BackEndSelectDataSource, $translate),
			onChange: function (p_objModel, p_fieldModel, p_currentValue){
				//Limpa o Tempo padrão!
				refreshDefaultDuration(null);
			}
    	});
    	
    	
    	vm.listSchedulable = [];
		angular.forEach(schedulableList, function(item)
		{
			vm.listSchedulable.push({id: item.id, defaultDescription: item.name, });
		});
		
    	var formOptions = {
    		
    	};
    	
    	var searchButton = {
            	order: 0,
            	cssClass: 'btn btn-success',
            	cssClassIcon: 'glyphicon glyphicon-default',
            	label: 'client.global.label.search',
            	type: 'button',
            	isDisabled: function() { return false; },
            	isToAdd: function() { return true; },
            	click: function() { doFilter(); }
            };
        
        vm.buttonsModel = {
        	useSuggestedButton: false,
        	extraButtonList: [searchButton]
        };
    	
        vm.formHandler = FormFilter.create(formOptions)
        	.add([familyProcedureFormField,
        		new FormField({
		       		cellCssClass: "col-sm-5",
		       		required: true,
		       		type: "Select",
					id: "procedure",
					label: "common.domain.salesRequest.procedure",
					dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, true),
					onChange: function(p_objModel, p_fieldModel, p_currentValue){
						refreshDefaultDuration(p_currentValue);
					}
	        	}),
		    ])
		    .add([
		    	new FormField({
		        	cellCssClass: "col-sm-4",
					type: "Select",
					id: "schedulable",
					label: "client.page.attendance.blockSchededuleSelect",
					dataSource:  FrontEndSelectDataSource.create(vm.listSchedulable)
		        }),
		        new FormField({
		       		cellCssClass: "col-sm-4",
		       		type: "Select",
					id: "procedurePlace",
					label: "common.domain.attendance.procedurePlace",
					dataSource: FormConstants.DataSources.BackEnd.ProcedurePlace(BackEndSelectDataSource, $translate)
	        	}),
		        new FormField({
		        	cellCssClass: "col-sm-4",
		        	type: "Select",
		        	id: "equipment",
		        	label: "common.domain.attendance.equipment",
		        	dataSource: FormConstants.DataSources.BackEnd.Equipment(BackEndSelectDataSource, $translate)
		        }),
		    ]).add([
		    	new FormField({
		       		cellCssClass: "col-sm-4",
		       		type: "Date",
					id: "plannedDateStart",
					label: "client.page.attendanceFreeSchedule.plannedDateStart",
					required: true,
					jsonValTransform: function (p_json, p_model, p_val)
					{
						if(p_val)
						{
							// Primeira hota do dia
							p_val.setHours(0);
							p_val.setMinutes(0);
							p_val.setSeconds(0);
							p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
						}
					}
	        	}),
	        	new FormField({
		       		cellCssClass: "col-sm-4",
		       		type: "Date",
					id: "plannedDateEnd",
					label: "client.page.attendanceFreeSchedule.plannedDateEnd",
					required: true,
					jsonValTransform: function (p_json, p_model, p_val)
					{
						if(p_val)
						{
							// Primeira hota do dia
							p_val.setHours(23);
							p_val.setMinutes(59);
							p_val.setSeconds(59);
							p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
						}
					}
	        	}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Select",
					id: "defaultDuration",
					label: "common.domain.procedure.defaultDurationTime",
					dataSource: FrontEndSelectDataSource.create(defaultDurationList)
				})
		    ])
			.endConfig();
    	
    	
        vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.dto.attendanceAvailability.day"), field: "day", cellStyle: { alignItems: "center", textAlign: "center" }, width: 3, suppressSorting: true,},
			{ headerName: $translate.instant("common.dto.attendanceAvailability.weekDay"), field: "weekDay", cellStyle: { alignItems: "center", textAlign: "center" }, width: 3, suppressSorting: true,cellRenderer: function(p_param) {
				return moment(p_param.data.day, 'DD-MM-YYYY').format('dddd'); }
			},
			{ headerName: $translate.instant("common.dto.attendanceAvailability.intervalDescription"), field: "intervalDescription", cellStyle: { alignItems: "center", textAlign: "center" }, width: 3, suppressSorting: true },
			{ headerName: $translate.instant("common.dto.attendanceAvailability.intervalInMinutes"), field: "intervalInMinutes", cellStyle: { alignItems: "center", textAlign: "center" }, width: 3, suppressSorting: true, cellRenderer: function(params){
				return moment.duration({"minutes":params.data.intervalInMinutes}).format("h [hrs], m [min]", {trim: "large both"});
				}
			},
			{ headerName: $translate.instant("common.dto.attendanceAvailability.schedulableName"), field: "schedulableName", cellStyle: { alignItems: "center", textAlign: "center" }, width: 11 , suppressSorting: true,cellRenderer: function(p_param){ return renderSchedulableGridCell(p_param)}},
			{ headerName: $translate.instant("common.dto.attendanceAvailability.procedurePlaceName"), field: "procedurePlaceName", width: 3, cellStyle:{alignItems: "center", textAlign: "center"}, suppressSorting: true,cellRenderer: function(p_param){ return renderLocalProcedureGridCell(p_param)}},
			{ headerName: $translate.instant("common.dto.attendanceAvailability.equipmentName"), field: "equipmentName", width: 11, cellStyle: {alignItems: "center", textAlign: "center"}, suppressSorting: true,cellRenderer: function(p_param){ return renderEquipmentGridCell(p_param)}},
			{ headerName: "", field: "goToSchedule", width: 11, cellStyle: {alignItems: "center", textAlign: "center"}, cellRenderer: function(params){return createGoToScheduleButton(params.data)},suppressSorting: true, 
				onCellClicked: function(params){
					if(params.data.canSchedule){
						eventOnClick(params.data);
					}
				}
			}
		]);

		var grid = UcGridService.create(
			{
				gridId: "ATTENDANCE_FREE_SCHEDULE",
				getFilterData: function () { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				showGrid: true,
				virtualPaging: false,
				sort: 'day', 
				onRowClicked: function(p_data){},
				pageSize: 1000
			});

		vm.gridOptions = grid.getGridOptions();
		
		/*
		 *Se tiver preenchido o médico e o local, exibe o botão.
		 */
		function createGoToScheduleButton(rowData){
			if(rowData.canSchedule){
				return "<div stop-propagation='true' style='height:100%;background-color:transparent'><span class='uc-pills " + 'uc-pills-blue' + "'> Agendar </span></div>";
			}
			return "";
		}
		
		
		/*private*/ function refreshDefaultDuration(p_currentValue){
			var model = vm.formHandler.getModel();
			if(p_currentValue && p_currentValue.defaultDurationTime){
				model.defaultDuration = p_currentValue.defaultDurationTime;
			}else{
				model.defaultDuration = null
			}
		}
		
		/*private*/ function renderSchedulableGridCell(p_param)
		{
			return renderAvailableFieldGridCell(p_param.data.schedulableName);
		}
		/*private*/ function renderLocalProcedureGridCell(p_param)
		{
			return renderAvailableFieldGridCell(p_param.data.procedurePlaceName);
		}
		/*private*/ function renderEquipmentGridCell(p_param)
		{
			return renderAvailableFieldGridCell(p_param.data.equipmentName);
		}
		
		
		/*private*/ function renderAvailableFieldGridCell(value){
			if(value === null)
			{
				return "";
			}
			else
			{
				return "<div stop-propagation='true' style='height:100%;background-color:transparent'><span class='uc-pills " + 'uc-pills-green' + "'>" + value + "</span></div>";
			}
		}
		
		function openModal(objData) 
		{
			$uibModal.open({
                templateUrl: 'app/pages/managers/attendance/attendance-free-interval/confirmation/attendance-confirmation-free-schedule-dialog.html',
                controller: 'AttendanceConfirmationFreeScheduleDialogController',
                controllerAs: 'vm',
                scope: $scope,
                size: 'lg',
                resolve:
                {
                    dataScheduling: function()
                    {
                        return objData;
                    },
                    rescheduleAttendance: function()
                    {
                    	return false;
                    }, 
                    defaultDurationList: ['Attendance', function(Attendance)
	                    {
	                    	return Attendance.listDefaultDuration().$promise;
	                    }
                    ]
                }
            }).result.then(function(p_)
            {
            	if(p_ && p_.attendanceInput)
            	{
            		$uibModalInstance.close(p_);
            	}
            }, function() {
                /*void*/
			});
		}
		
		function eventOnClick(rowData) 
		{
			var objData = {
				idResource: rowData.schedulableId,
				startDate: moment(rowData.day, 'DD-MM-YYYY')._d,
				startTime: moment(rowData.day +' ' + rowData.beginInterval, 'DD-MM-YYYY HH:mm').format("HH:mm"),
				endTime: '',
				rowData: rowData,
				filterData: vm.filterData
			};
			openModal(objData);
		}
		
        
    	/*public*/ vm.closeModal = function()
        {
    		$uibModalInstance.close();
        };

        /*private*/ function todaysAgenda(p_pageLoad)
		{
			
        	var model = vm.formHandler.getModel();
			
			var startDate = moment()._d;
			var endDate = moment().add(60,'days')._d;
			model.plannedDateStart = startDate;
			model.plannedDateEnd = endDate;
		};
        
        
        /*private*/ function doFilter()
        {
        	vm.filterData = angular.extend({}, vm.formHandler.getModel());
        	var fd = vm.filterData;
        	
        	if(validateFilterData()){
        		grid.refresh();
        	}
        };
        
        
        /*private*/ function validateFilterData(){
        	var fd = vm.filterData;
        	
        	var msg = null;
        	var isValid = true;
        	
        	if(!fd.familyProcedure|| !fd.procedure || !fd.plannedDateStart || !fd.plannedDateEnd)
        	{
        		msg =  $translate.instant('client.page.attendanceFreeSchedule.messages.selectRequiredFilterFields');
        		isValid = false;
        	}else
        	if(!fd.schedulable && !fd.procedurePlace && !fd.equipment)
        	{
        		msg =  $translate.instant('client.page.attendanceFreeSchedule.messages.selectScheduleProcedurePlaceOrEquipment');
        		isValid = false;
        	}
        	
        	var begin = moment(fd.plannedDateStart, "YYYY-MM-DD HH:mm:ss");
        	var end = moment(fd.plannedDateEnd, "YYYY-MM-DD HH:mm:ss");
        	
        	if(moment.duration(end.diff(begin)).asDays() > 60)
        	{
        		msg =  $translate.instant('client.page.attendanceFreeSchedule.messages.maxDateRange60Days');
        		isValid = false;
        	}
        	
        	vm.validationMessage = $sce.trustAsHtml(msg);
        	return isValid;
        }
        
        $timeout(function(){
        	todaysAgenda(true);
        }, 500);
    }
})();