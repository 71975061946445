/**
 * Funcionalidade comuns a página de edição de Suppliere e do Modal
 * 
 */
(function() {
    'use strict';

	angular
		.module('sgmApp')
		.service('SupplierCommon', SupplierCommon);
	
	SupplierCommon.$inject = ['$translate', '$injector'];
	
	function SupplierCommon($translate, $injector)
	{
		var vm = this;
		
		/**
		 * Configuração para as abas da página
		 */
		/*public*/ vm.tabList = [
    		{code: "contact", icon: "fa fa-file-text-o", label: "client.page.supplier.tab.contact.title"},
    		{code: "category", icon: "fa fa-level-down", label: "client.page.supplier.tab.category.title"},
    		{code: "generalInformation", icon: "fa fa-question", label: "client.page.expense.tab.generalInformation.title"},
    		{code: "address", icon: "pe-7s-map-marker", label: "client.page.supplier.tab.address.title"},
    	];
		
	};
	
})();

function SupplierCommonStatic()
{
	function f_()
	{
		var baseResolve = {
		};
		
		/*public*/ this.extendResolve = function(p_param)
		{
			var resolve = {};
			
			// Carrega os valores base
			angular.extend(resolve, baseResolve);
			
			// Sobreescreve/Adiciona só os passados como parâmetro
			angular.extend(resolve, (p_param) ? p_param : {});
			return resolve;
		}
	};
	return new f_();
}
