/**
 * Classe para gerenciar a câmera do dispositivo
 */

var CAMERA_RESOLUTION = {
	width: 1280,
	height: 720
}

CameraHandler.Events = 
{
	TakePicture: "CameraHandler.Events.TakePicture",
	TakePictureEnd: "CameraHandler.Events.TakePictureEnd"
};

function CameraHandler(/*CameraHandlerParam*/ p_param)
{
	var self = this;
	
	/*private*/ var localMediaStream;
	/*private*/ var video, canvas, context;
	
	var localMediaStream = null;
    
	/*private*/ var videoConfig = {
		mandatory:
		{
			minWidth: CAMERA_RESOLUTION.width,
			minHeight: CAMERA_RESOLUTION.height
		}
	};
    
    /*public*/ this.getResolution = function()
    {
    	return [CAMERA_RESOLUTION.width, CAMERA_RESOLUTION.height];
    };
    
	/**
	 * Tira uma "foto" do stream
	 */
	/*public*/ this.snapshot = function()
	{
		if(localMediaStream)
		{
			var videoObj = video.get(0);
			context.drawImage(videoObj, 0, 0);
			var dataURL = canvas.get(0).toDataURL();

			return dataURL;
		}
	};

    
	/*public*/ this.init = function()
    {
		video = $("#" + p_param.id + "-video");
		canvas = $("#" + p_param.id + "-canvas");
		
		context = null;
		canvas.each(function(a, b)
		{
			context = b.getContext("2d");
		});

		// Prefer camera resolution nearest to 1280x720.
		var localConstraints = { audio: false, video: { width: CAMERA_RESOLUTION.width, height: CAMERA_RESOLUTION.height } }; 
		
		
		
		// Older browsers might not implement mediaDevices at all, so we set an empty object first
		if (navigator.mediaDevices === undefined)
		{
			navigator.mediaDevices = {};
		}

		// Some browsers partially implement mediaDevices. We can't just assign an object
		// with getUserMedia as it would overwrite existing properties.
		// Here, we will just add the getUserMedia property if it's missing.
		if (navigator.mediaDevices.getUserMedia === undefined)
		{
			navigator.mediaDevices.getUserMedia = function(constraints)
			{

			    // First get ahold of the legacy getUserMedia, if present
			    var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
	
			    // Some browsers just don't implement it - return a rejected promise with an error
			    // to keep a consistent interface
			    if(!getUserMedia)
			    {
			    	return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
			    }
	
			    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
			    return new Promise(function(resolve, reject)
			    {
			    	getUserMedia.call(navigator, constraints, resolve, reject);
			    });
			}
		}

		navigator.mediaDevices.getUserMedia(localConstraints)
			.then(function(stream)
			{
				localMediaStream = stream;
				var video = document.querySelector("#" + p_param.id + "-video");
				// Older browsers may not have srcObject
				if ("srcObject" in video)
				{
					video.srcObject = stream;
				}
				else
				{
				    // Avoid using this in new browsers, as it is going away.
				    video.src = window.URL.createObjectURL(stream);
				}
				video.onloadedmetadata = function(e)
				{
					video.play();
				};
			})
			.catch(function(err)
			{
				console.log(err.name + ": " + err.message);
			});		
		
    };

    /*public*/ this.close = function()
	{
		try
		{
			var track = localMediaStream.getTracks()[0];  // if only one media track
			track.stop()
		}
		catch(eee)
		{
			console.error("Error on close Camera: " + eee.message);			
		}
    };
};


function CameraHandlerParam(p_id, p_blockUI)
{
	this.id = p_id;
	this.blockUI = p_blockUI;
}