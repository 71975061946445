(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('IndicationTypeDomainController', IndicationTypeDomainController);

    IndicationTypeDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'IndicationType', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout'];

    function IndicationTypeDomainController(FormDomain, $scope, $state, entity, IndicationType, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "INDICATION_TYPE",
        	stateRoot: "indicationType",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: IndicationType,
        	historyConfig: {historyId: "INDICATION_TYPE"},
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
	    	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					label: "common.domain.indicationType.description",
					required: true
				})
        	])
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "common.domain.indicationType.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();

        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };

        /*public*/ vm.goBack = function()
        {
        	$state.go("indicationType");
        }
    }
})();
