(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceShowScheduleDialogController', AttendanceShowScheduleDialogController);

    AttendanceShowScheduleDialogController.$inject = ['$scope', '$filter', '$translate', '$rootScope', '$uibModalInstance', 'FormDomain', 'Attendance', 'dataBlockSchedule'];

    function AttendanceShowScheduleDialogController($scope, $filter, $translate, $rootScope, $uibModalInstance, FormDomain, Attendance, dataBlockSchedule) {
        
        var vm = this;
        vm.dataBlockSchedule = dataBlockSchedule;
        vm.dataBlockSchedule.startDate = $filter('date')(vm.dataBlockSchedule.startDate, 'dd/MM/yyyy'); 
        vm.dataBlockSchedule.endDate = $filter('date')(vm.dataBlockSchedule.endDate, 'dd/MM/yyyy'); 
		vm.dataBlockSchedule.description = vm.dataBlockSchedule.title;

        /*private*/
        var saveButton = {
            order: 0,
            cssClass: 'btn btn-success',
            cssClassIcon: 'glyphicon glyphicon-save',
            label: 'client.global.label.save',
            type: 'submit',
            isDisabled: function() { return false; },
            isToAdd: function() { return true; },
            click: function() { /* void - por ser submit, a ação está no ng-submit do form */ }
        };

        /*private*/
        var cancelButton = {
            order: 10,
            cssClass: 'btn btn-danger',
            cssClassIcon: 'fa fa-times',
            label: 'client.global.label.cancel',
            type: 'button',
            isDisabled: function() { return false; },
            isToAdd: function() { return true; },
            click: function() {
                vm.closeModal();
            }
        };

        vm.buttonsModel = {
            customButtonList: [saveButton, cancelButton]
        };

        /*public*/
        vm.closeModal = function() {
            $uibModalInstance.dismiss();
        };

        
        vm.delete = function()
		{
			var message = $translate.instant("client.global.messages.info.deleteRecord") + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
			
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
                    Attendance.deleteAgendaLockingTime({ id: vm.dataBlockSchedule.idSchedule },
                    function() {
                        $uibModalInstance.close();
                    });
				} 
			});
    	};

    }
})();