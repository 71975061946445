
//Não está funcionando ainda!
//Comentado no http.config.js
(function(){
	'use strict';
	
	angular.
		module('sgmApp').
		factory('withoutCredentialsRequestInterceptor', withoutCredentialsRequestInterceptor);
	
	withoutCredentialsRequestInterceptor.$inject = ['$q'];
	
	
	function withoutCredentialsRequestInterceptor($q)
	{
		var service = {
			request : request
		};
		
		return service;
		
		
		function request (requestConfig)
        {
			 requestConfig.transformRequest = function(request){
				 delete requestConfig.headers;
				 return requestConfig;
			 };
			 return requestConfig; 
        }
		
}
})();