(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
	            'navbar-right@': {
	                templateUrl: 'app/layouts/navbar/navbar-right.html',
	                controller: 'NavbarRightController',
	                controllerAs: 'vm'
	            }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }],
                clientCode: ['UcService', function (UcService)
                {
                    return UcService.getClientCode().$promise;
                }]
            }
        });
    }
})();
