/**
 * Funcionalidade comuns a página de edição de Cliente e do Modal
 *
 */
(function() {
    'use strict';

	angular
		.module('sgmApp')
		.service('ClientCommon', ClientCommon);

	ClientCommon.$inject = ['$translate', '$injector'];

	function ClientCommon($translate, $injector)
	{
		var vm = this;

		/**
		 * Configuração para as abas da página
		 */
		/*public*/ vm.tabList = [
    		{code: "personal", icon: "pe-7s-id", label: "client.page.client.tab.personal.title"},
    		{code: "contacts", icon: "pe-7s-call", label: "client.page.client.tab.contacts.title"},
    		{code: "indication", icon: "pe-7s-speaker", label: "client.page.client.tab.indication.title"},
    		{code: "address", icon: "pe-7s-map-marker", label: "client.page.client.tab.address.title"},
    		{code: "lastAttendence", icon: "pe-7s-note2", label: "client.page.client.tab.lastAttendence.title"},
    		{code: "classification", icon: "pe-7s-medal", label: "client.page.client.tab.classification.title"},
    		{code: "photo", icon: "fa fa-camera", label: "client.page.client.tab.photo.title"},
    		{code: "summary", icon: "fa fa-book", label: "client.page.client.tab.summary.title"},
    		{code: "notes", icon: "fa fa-file-text-o", label: "client.page.client.tab.notes.title", extraIcon: {cssClass: "fa fa-circle display-none blink", style: "margin-left:6px;color:#00FF00", id:"notesSignal"} },
    	];


		/**
		 * Cria o ícone para abrir o modal dentro de um grid
		 *
		 * {
		 * 	icon:
		 * 	hint:
		 * 	account:
		 * 	callback:
		 * }
		 *
		 */
		/*public*/ vm.createOpenModalGridIcon = function(p_)
		{
        	return {
        		headerName: "",
        		field: "id",
        		width: 50,
                suppressSizeToFit: true,
                suppressMenu: true,
                suppressSorting: true,
                cellStyle: {alignItems: "center", textAlign: "center", padding: "0px"},
        		cellRenderer: function (p_params)
                {
					var blinkIconClass = "";
					var iconColor = "#1a4663";
					if (p_.blinkIcon && p_.blinkIcon(p_params)) {
						blinkIconClass = "blink";
						iconColor = "#00263e";
					}
					var icon = '<i class="' + p_.icon + ' ' + blinkIconClass + '" style="color:' + iconColor + '" aria-hidden="true"></i>';
        			var hint = $translate.instant(p_.hint);
        			return '<a class="btn btn-primary" style="border:solid 0px transparent !important;background-color:transparent !important;font-size:18px;" title="' + hint + '">' + icon + '</a>';
                },
                onCellClicked: function(p_params)
                {
                	$injector.get("ClientManagerModalInvoker").invoke(new ClientManagerModalInvokerParam({
                		clientId: p_params.data[p_.field],
                		account: p_.account,
                		pageMode: "READONLY",
                		callback: p_.callback
                	}));

                	try {p_params.event.preventDefault(); } catch(ee) { /* silent */ }
                }
        	}
		};

	};

})();

function ClientCommonStatic()
{
	function f_()
	{
		var baseResolve = {
			colorList:['Client', function(Client)
			{
				return Client.listColor().$promise;
			}],
			contactTypeList:['ContactType', function(ContactType)
			{
				return ContactType.listAll().$promise;
			}],
			genderList:['User', function(User)
            {
                return User.listGender().$promise;
            }],
            indicationTypeList:['IndicationType', function(IndicationType)
            {
                return IndicationType.listAll().$promise;
            }],
            maritalStatusList:['Client', function(Client)
            {
                return Client.listMaritalStatus().$promise;
            }],
            netAccessTypeList:['NetAccessType', function(NetAccessType)
            {
            	return NetAccessType.listAll().$promise;
            }],
            isToShowRecordsButton: ['Attendance', function(Attendance)
            {
                return Attendance.isToShowRecordsButton().$promise;
            }]
		};

		/*public*/ this.extendResolve = function(p_param)
		{
			var resolve = {};

			// Carrega os valores base
			angular.extend(resolve, baseResolve);

			// Sobreescreve/Adiciona só os passados como parâmetro
			angular.extend(resolve, (p_param) ? p_param : {});
			return resolve;
		}
	};
	return new f_();
}
