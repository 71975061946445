'use strict';

/**
 * Json de configuração dos campos do formulário
 * 
 *  IMPORTANTE: Toda nova configuração DEVE ser criada aqui com uma descrição 
 */
var _DEFAULT_PAGE_CONFIG =
{
	// REQUIRED
	id: null,						// Id do componente
	cellCssClass: null,				// String cellCssClass -
	type: "String",					// Tipo do campo
	required: false,				// Se o campo é obrigatório
	
	// Opcionais
	label: null,					// Label no formato de chave i18n
	noLabel: false,					// Omite o Label
	inputId: null,					// Id do input no DOM
	placeholder: null,				// Placeholder (Implementar on demand)
	readOnly: false,				// Tb pode ser setado para a pagina
	maxLength: null,				// Tamanho máximo do campo
	minValue: null,					// Valor mínimo
    maxValue: null,					// Valor máximo
	defaultValue: null,				// Valor default do campo, quando iniciado ou resetado - Exemplo: true para boolean
									// -> pode ser objeto ou função
	precision: null,           		// Quantidade de casas decimais permitidas caso tipo Double
	
	addonCssClass: null,			// Classe css para os inputs com addon (type="String-Group")
	formTarget: null,				// Formulário "target" do campo. Se esse atributo for preenchido, o formField só será adicionado no formulário que tiver este form-id. 
									// Util quando houver mais de um form-dynamic na página. Exemplo: página com abas
	extraCssClass: null,			// Classes extra para o componente (implementar sob demanda)
	style: null,					// Style do elemento Implementar sob demanda
	hide: false,						// Define se o campo irá aparecer ou não na tela
	dependencyName: null,           // Nome do criteria que será enviado quando tiver um dependente (dependencyName: 'id', gera a query id=*valor* no campo dependente)
	displayProp: null,              //Nome da propriedade para mostrar no select
	isString: false,				//Se select é uma array de strings
	// [function]
	addFieldListener: null,			// (objModel) ->  Checa se o form-field deve ou não ser adicionado no formulário
	jsonValTransform: null,			// Converter o valor do model para ser incluido no json do método read. Para form-field do tipo Select, utiliza o default
	onChange: null,					// (p_objModel, p_fieldModel, p_currentValue) -> Implementar on-demand
	onClick: null,					// (p_objModel, p_fieldModel, p_currentValue) -> Implementar on-demand
	isRadio: false,
	// [events]
	/* FormConstants.Events.Runtime.ReadOnly.<field_name> */ 		// Set readOnly para o form-field em tempo de execução
																	// Implemetar para os que faltam. 
																	// Já existe para DateTime, Date, Time

	
	// [Específicos por tipo]
	// CEP
	searchEngine: null,				// {handleResult: function(p_data){}} 
	doSearchEngine: null,
	// SELECT
	dataSource: null,				// DataSource do Select
	
	//SelectModal
	modalConfig: null,				// Configuração do SelectModal: {searchId: String}
	vertical: false,				// Disposição vertical dos componentes
	
	// SalesRequestModal
	getPageFilter: function(){ return {}; },	//() -> Retorna o formulário do filtro

	// Button
	buttonModel: {},					// { btnClass: "", iconClass: "", label: "", click: function(), disabled: function(p_domain), disabledHint: ""}

	//Date, DateTime e Time
	maxDate: null, 					//Data máxima disponível para seleção
	nowAsMaxDate: false,            //Usar agora como data máxima disponível para seleção (checa com um novo Date() toda hora que o valor muda)
};

function FormField(p_config)
{
	// Carrega os valores default
	angular.extend(this, _DEFAULT_PAGE_CONFIG);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_config) ? p_config : {});
	
	this.isField = true;
};