(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('RecordsTemplateDir', RecordsTemplateDir);

    RecordsTemplateDir.$inject = ['$resource', 'URL', 'SERVICES'];

    function RecordsTemplateDir ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/records-template-dir/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'listByType': { method: 'GET', url: url + 'api/records-template-dir/list-by-type/:recordType', isArray: true }
        });
    }
})();
