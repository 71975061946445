(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('NavbarRightController', NavbarRightController);

    NavbarRightController.$inject = ['$state', 'Auth', 'Principal', 'PermissionSet', 'ProfileService', 'MenuHighlight', 'VERSION', 'VERSION_DATE', 'UcService', '$sce', '$translate'/*, 'LoginService'*/];

    function NavbarRightController ($state, Auth, Principal, PermissionSet, ProfileService, MenuHighlight, VERSION, VERSION_DATE, UcService, $sce, $translate/*, LoginService*/)
    {
    	var vm = this;
    	vm.menu = [
    		{
    			id: "cadastres",
    			initOpen: false,
    			list: [
    				{id: "familyProcedure", permissions: permissionSetToArray(PermissionSet.FAMILY_PROCEDURE)},
    				{id: "procedure", permissions: permissionSetsToArray([PermissionSet.PROCEDURE, PermissionSet.PRODUCT])},
    				{id: "paymentInstallments", permissions: permissionSetToArray(PermissionSet.PAYMENT_INSTALLMENTS)},
    				{id: "paymentOption", permissions: permissionSetToArray(PermissionSet.PAYMENT_OPTION)},
    				{id: "procedurePlace", permissions: permissionSetToArray(PermissionSet.PROCEDURE_PLACE)},
    				{id: "specialty", permissions: permissionSetToArray(PermissionSet.SPECIALTY)},
    				{id: "therapyPlace", permissions: permissionSetToArray(PermissionSet.THERAPY_PLACE)},
					{id: "user", permissions: permissionSetToArray(PermissionSet.USER)},
					{id: "equipment", permissions: permissionSetToArray(PermissionSet.EQUIPMENT)},
					{id: "expenseType", permissions: permissionSetToArray(PermissionSet.EXPENSE_TYPE)},
					{id: "expenseCostCenter", permissions: permissionSetToArray(PermissionSet.EXPENSE_COST_CENTER)},
					{id: "expensePaymentOption", permissions: permissionSetToArray(PermissionSet.EXPENSE_PAYMENT_OPTION)},
					{id: "indicationType", permissions: permissionSetToArray(PermissionSet.INDICATION_TYPE)},
					{id: "indications", permissions: permissionSetToArray(PermissionSet.INDICATIONS)},
					{id: "supplierCategory", permissions: permissionSetToArray(PermissionSet.SUPPLIER_CATEGORY)},
					{id: "supplierSubcategory", permissions: permissionSetToArray(PermissionSet.SUPPLIER_SUBCATEGORY)},
					{id: "manufacturer", permissions: permissionSetToArray(PermissionSet.MANUFACTURER)},
					{id: "measureUnit", permissions: permissionSetToArray(PermissionSet.MEASURE_UNIT)},
					{id: "product", permissions: permissionSetToArray(PermissionSet.PRODUCT)},
					{id: "productKit", permissions: permissionSetToArray(PermissionSet.PRODUCT)},
					{id: "stockFlowMotive", permissions: permissionSetToArray(PermissionSet.STOCK_FLOW_MOTIVE)},
					{id: "stockPlace", permissions: permissionSetToArray(PermissionSet.PRODUCT)},
					{id: "prontuaryModel", permissions: permissionSetToArray(PermissionSet.PRONTUARY_MODEL)},
					{id: "role", permissions: permissionSetToArray(PermissionSet.ADMIN)},
    			]
    		},
    		{
    			id: "main",
    			initOpen: true,
    			list: [
    				{id: "attendance", permissions: permissionSetsToArray([PermissionSet.ATTENDANCE, PermissionSet.PRODUCT])},
					{id: "client", permissions: permissionSetToArray(PermissionSet.CLIENT)},
					{id: "expense", permissions: permissionSetToArray(PermissionSet.EXPENSE)},
					{id: "supplier", permissions: permissionSetToArray(PermissionSet.SUPPLIER)},
					{id: "indicationsManager", permissions: permissionSetToArray(PermissionSet.INDICATIONS_MANAGER)},
					{id: "manufacturerProductStockFlow", permissions: permissionSetToArray(PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW)},
					{id: "productStockControl", permissions: permissionSetToArray(PermissionSet.MANUFACTURER_PRODUCT_STOCK_FLOW)},
    			]
    		},
    	];

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;

        vm.systemInfo = $sce.trustAsHtml($translate.instant("client.global.label.version") + ": " + VERSION +"<br/>" + VERSION_DATE);
        UcService.getEnvInfo({}, function(p_)
        {
        	if(p_.showVersion)
        	{
        		vm.systemInfo = $sce.trustAsHtml($translate.instant("client.global.label.version") + ": " + VERSION +"<br/>" + VERSION_DATE + "<br/>" + p_.description);
        	}
        });

        vm.serializeArray = function(p_)
        {
        	return serializeArray(p_);
        };

        vm.clickMenu = function(p_event)
        {
        	MenuHighlight.refresh(p_event);
        	collapseNavbar();
        };

        vm.extractAllPermissions = function(p_node)
        {
        	var permissions = [];
        	if(p_node && p_node.list)
        	{
        		for(var i = 0 ; i < p_node.list.length ; i++)
        		{
        			if(p_node.list[i])
        			{
        				permissions = permissions.concat(p_node.list[i].permissions);
        			}
        		}
        	}
        	return permissions;
        };

        Principal.identity().then(function(account)
        {
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
        });


        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

//        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

//        function login() {
//            collapseNavbar();
//            LoginService.open();
//        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            //$state.go('dashboard');
            $state.go('login');

        }

        function toggleNavbar()
        {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar()
        {
            vm.isNavbarCollapsed = true;
        }

    	/**
    	 * Toggle nos paineis
    	 */
    	vm.clickMenuToggle = function($event)
    	{
    		var link = $($event.currentTarget)
    		var icon = link.find('i');
    		if(icon.hasClass("fa-chevron-down"))
			{
    			icon.removeClass("fa-chevron-down");
    			icon.addClass("fa-chevron-up");
			}
    		else
			{
    			icon.removeClass("fa-chevron-up");
    			icon.addClass("fa-chevron-down");
			}
    		var ul = $("#" + link.attr("id") + "-ul");
    		ul.slideToggle(200);
    	};
    };

    /*private*/ /*static*/ function permissionSetToArray(p_set)
    {
    	var arr = [];

    	var keyArray = Object.keys(p_set);
    	for(var i = 0 ; i < keyArray.length ; i++)
    	{
    		arr.push(p_set[keyArray[i]]);
    	}
    	return arr;
    };

	/*private*/ /*static*/ function permissionSetsToArray(p_sets)
    {
    	var arr = [];
		for(var j = 0; j < p_sets.length; j++) {
			var p_set = p_sets[j];
			var keyArray = Object.keys(p_set);
			for(var i = 0 ; i < keyArray.length ; i++)
			{
				arr.push(p_set[keyArray[i]]);
			}
		}
    	return arr;
    };
})();
