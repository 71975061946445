(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ConfirmationAttendanceDomainController', ConfirmationAttendanceDomainController);
    
    ConfirmationAttendanceDomainController.$inject = ['FormDomain', '$scope', '$state', 'Attendance', '$rootScope', '$translate', '$uibModal', '$timeout'];

    function ConfirmationAttendanceDomainController(FormDomain, $scope, $state, Attendance, $rootScope, $translate, $uibModal, $timeout)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var pageConfig = $scope.vm.pageConfig;
        var permissions = vm.permissions;
        
        var confirmationData = {};
        
        // Se o objeto estiver confirmado, busca as informações de confirmação
        if(entity.confirmated)
        {
        	Attendance.getConfirmationData({id: entity.id}, function(p_confirmationData)
        	{
        		angular.extend(confirmationData, p_confirmationData);
        		confirmationData.confirmationUserName = (confirmationData.confirmationUser) ? confirmationData.confirmationUser.name : "";
        	});
        }
        
        /*public*/ vm.canAttendanceConfirmation = function()
        {
        	// Deve ser edição
        	if(vm.domain.id)
        	{
        		// O atendimento não deve estar confirmado O estado deve permitir a confimação e o usuário deve ter a permissão
        		return (!vm.domain.confirmated && 
        				vm.domain.status.canConfirmation &&
        				permissions.hasCanConfirmationEditPermission);
        	}
        	return false;
        };

        
        /**
         * O usuário pode desfazer a confirmação?
         */
        /*public*/ vm.canUndoConfirmation  = function()
        {
        	// Deve ser edição
        	if(vm.domain.id)
        	{
        		// O atendimento não deve estar confirmado, o estado deve permitir a confimação e o usuário deve ter a permissão
        		return (vm.domain.confirmated && 
        				vm.domain.status.canConfirmation && 
        				permissions.hasCanUndoConfirmationEditPermission);
        	}
        	return false;
        };

        var confirmationFormOptions = {
			model: confirmationData,
			pageConfig: vm.pageConfig,
			buttonsModel: null
        };

        // Esses inputs são SEMPRE readOnly
        vm.confirmationFormHandler = FormDomain.create(confirmationFormOptions)
	    	.add([new FormField({ 
				formTarget: "confirmation",	
				cellCssClass: "col-sm-4",
	        	type: "String",
	        	id: "confirmationDateFormatted",
	        	label: "client.page.attendance.tabs.confirmation.operationDate",
	        	readOnly: true	
			}),
			new FormField({ 
				formTarget: "confirmation",	
				cellCssClass: "col-sm-4",
	        	type: "String",
	        	id: "confirmationUserName",
	        	label: "client.page.attendance.tabs.confirmation.operationUser",
	        	readOnly: true	
			}),
			new FormField({ 
				formTarget: "confirmation",	
				cellCssClass: "col-sm-4",
	        	type: "String",
	        	id: "confirmationTypeLabel",
	        	label: "client.page.attendance.tabs.confirmation.operationType",
	        	readOnly: true	
			})
		]);

        if(entity && entity.confirmationStatus && entity.confirmationStatus.id == 'CAN')
        {
			 vm.confirmationFormHandler.add([new FormField({ 
					formTarget: "confirmation",	
					cellCssClass: "col-sm-12",
		        	type: "String",
		        	id: "externalCancelationJustification",
		        	label: "common.domain.attendance.externalCancelationJustification",
		        	readOnly: true	
				})
			 ]);
        }

		if(entity && entity.confirmedNotPossible)
		{
			var noConfirmationJustificationReadOnly = true;
	        if(vm.pageConfig.pageMode == "EDIT" && permissions.hasAdminPermission) 
	        {
	        	noConfirmationJustificationReadOnly = false;
	        }
			
			 vm.confirmationFormHandler.add([new FormField({ 
					formTarget: "confirmation",	
					cellCssClass: "col-sm-12",
		        	type: "TextArea",
		        	id: "noConfirmationJustification",
		        	label: "common.domain.attendance.noConfirmationJustification",
		        	required: !noConfirmationJustificationReadOnly,
		        	readOnly: noConfirmationJustificationReadOnly	
				})
			 ]);
		}
		vm.confirmationFormHandler.endConfig();
		
        /*public*/ vm.confirmationAttendence = function(p_code)
        {
        	if(p_code == "CNP")
        	{
            	// Seta o currentAttendance com o domain corrente
            	$scope.currentAttendance = entity;
            	
    			$uibModal.open({
                    templateUrl: 'app/pages/managers/attendance/confirmation/attendance-confirmation-not-possible-dialog.html',
                    controller: 'AttendanceConfirmationNotPossibleDialogController',
                    controllerAs: 'vm',
                    scope: $scope
                }).result.then(function(p_newAttendance)
                {
                	$state.reload();
                }, function() {
                	/*void*/
                });
        	}
        	else
        	{
        		var message = $translate.instant("client.page.attendance.attendanceConfirmationMessage-" + p_code);
				if(p_code == 'CNF' && entity.status.id == 'REA') {
					message += "<br />" + translate.instant("client.page.attendance.attendanceConfirmationMessage-CNFRescheduleWarn")
				}
        		message = message + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
        		
        		$rootScope.confirm(
				{
					message: message,
					callback: function()
					{
						Attendance.attendanceConfirmation({ 
							id: entity.id, 
							code: p_code,
							noConfirmationJustification: vm.justification
						},
						function(p_confirmationStatus)
						{
							$state.reload();
						});
					} 
				});
        	}

        };
        
        /*public*/ vm.undoConfirmation = function()
        {
			var message = $translate.instant("client.page.attendance.tabs.confirmation.attendanceConfirmationWillBeReverted") +  ".<br/>" + $translate.instant("client.global.messages.info.confirmOperation")

			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					Attendance.undoAttendanceConfirmation({id: entity.id}, function()
					{
						$state.reload();
					});
				} 
			});
        };
        
        // @Override
        /*public*/ vm.saveConfirmation = function(p_json)
        {
        	p_json.noConfirmationJustification = confirmationData.noConfirmationJustification;
        };
    };
})();
