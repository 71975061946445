(function() {
    'use strict';

    /**
     * Classe base do formulário
     */
    angular.module('sgmApp').service('FormHandler', FormHandler);

    FormHandler.$inject = ['$translate', 'FormConstants', 'FormFieldValidate', '$rootScope'];
    
    function FormHandler($translate, FormConstants, FormFieldValidate, $rootScope)
    {
    	var vm = this;
    	
    	var internalId = "form-" + (new Date()).getTime();
    	
    	vm.create = function(p_model, p_formOptions)
    	{
    		return new _t(p_model, p_formOptions);
    	};

    	function _t(p_model, p_formOptions)
    	{
    		var self = this;
    		
    		var defaultFormOptions = {
    		};
    		
    		var formOptions = defaultFormOptions;
    		angular.extend(formOptions, (p_formOptions) ? p_formOptions : {});
    		
    		var model = (!p_model) ? {} : p_model;
    		if(formOptions.internalId) {
				internalId = formOptions.internalId;
			}
    		/**
    		 * Lista de row's. 
    		 * Cada row representa uma linha da tabela (array de form-field) 
    		 */
    		/*private*/ var rowList = [];

    		/**
    		 * Map de form-field pelo id 
    		 */
    		/*private*/ var map = {};

    		
    		/*public*/ this.getRowList = function()
    		{
    			return rowList;
    		}
    		
			/*public*/ this.getModel = function()
    		{
    			return model;
    		}
			
			/*public*/ this.setModel = function(p_model)
			{
				model = p_model;
			}

    		/**
    		 * Adiciona campo
    		 */
    		/*public*/ this.add = function(/*FormField ou FormHtmlRender*/ p_)
    		{
    			var arr;
    			if(angular.isArray(p_))
    			{
    				arr = p_;
    			}
    			else
    			{
    				arr = [p_];
    			}
    			var formField;
    			for(var i = 0 ; i < arr.length ; i++)
    			{
    				formField = arr[i];

    				// HTML Render
    				if(!formField.isField)
    				{
    					continue;
    				}
    				formField.formInternalId = internalId;
    				map[formField.id] = formField;
    				

    				// Se o tipo for Select ou SelectModal ou SalesRequestModal... 
    				if(formField.type == "Select" || formField.type == "SelectModal" || formField.type == "SalesRequestModal" || formField.type == "SelectModalIndicationsManager")
    				{
    					// Procura por algum jsonValTransform setado
    					var tempTransform = null; 
    					
    					// Primeiro, no próprio formField
    					if(formField.jsonValTransform)
    					{
    						tempTransform = formField.jsonValTransform;
    					}
    					 // Depois no datasource
    					else if(formField.dataSource && formField.dataSource.dsJsonValTransform)
    					{
    						tempTransform = formField.dataSource.dsJsonValTransform;
    					}
    					// Se não tiver nenhum setado, usa o Default
    					else
    					{
    						tempTransform = (formField.multiple || formField.type == "SelectModal" || formField.type == "SelectModalIndicationsManager") ? FormConstants.ValTransforms.DEFAULT_SELECT_MULTIPLE_VAL_TRANSFORM : FormConstants.ValTransforms.DEFAULT_SELECT_VAL_TRANSFORM
    					}
    					formField.jsonValTransform = tempTransform; 
    				}
    				
    				// Se for Select múltiplo e não tiver valor default setado, cria função para novo array vazio - necessário para não dar erro com o componente angularjs-dropdown-multiselect
    		    	if(formField.type == 'Select' && formField.multiple === true)
    		    	{
    		    		if(!formField.defaultValue)
    		    		{
    		    			formField.defaultValue = function() { return [] };
    		    		}
    		    	}
    				
    				// Se for do tipo DateTime, cria os dois componentes nested
    				if(formField.type == "DateTime" || formField.type == 'Month')
    				{
    					// Seta o jsonValTransform para mandar a data no formato esperado
    					formField.jsonValTransform = FormConstants.ValTransforms.DATE_TIME; 
    					
    			    	/**
    			    	 * Cria dois field-forms: uma para a data e um para a hora
    			    	 * 
    			    	 * IMPORTANTE: Esses formFields não serão adicionados diretamente pelo formulário.
    			    	 * Esses serão adicionados indiretamente pela diretiva do DateTime. Logo, não serão 
    			    	 * adiconados diretamente na lista. A validação também é feita de forma indireta para
    			    	 * o tipo DateTime
    			    	 */
    			    	var dateFieldId = formField.id + "_date";
    			    	(function(p_formField, p_id)
    			    	{
    			    		p_formField.dateFieldModel = {
	    			    		id: p_id, 
	    			    		type: "Date",
								maxDate: formField.maxDate || (formField.nowAsMaxDate ? new Date(): null),
	    			    		required: p_formField.required,
	    			    		readOnly: p_formField.readOnly,
	    			    		onChange: function(){ if(p_formField.onChange){ p_formField.onChange() } }
    			    	}})(formField, dateFieldId);
    			    	
    			    	var timeFieldId = formField.id + "_time";
    			    	(function(p_formField, p_id)
    			    	{
    			    		p_formField.timeFieldModel = {
	    			    		id: p_id, 
	    			    		type: "Time",
								maxDate: formField.maxDate,
	    			    		required: p_formField.required,
	    			    		readOnly: p_formField.readOnly,
	    			    		onChange: function(){ if(p_formField.onChange){ p_formField.onChange() } }
    			    	}})(formField, timeFieldId);
        				
    				}
					//Adiciona as validações pro tipo de campo
					this.addValidation(formField);
    			}
    			rowList.push(arr);
    			return this;
    		};


			/*public*/ this.remove = function(p_fieldId) {
				for(var i = 0 ; i < rowList.length; i++) {
					cellList = rowList[i];
					for(var j = 0 ; j < cellList.length ; j++)	{
						if(cellList[j].id == p_fieldId) {
							rowList[i].splice(j, 1);
							delete map[p_fieldId];
						}
					}
				}
			}
    		/**
    		 * Limpa os campos adicionados e chama o pristine do FormControl
    		 */
    		/*public*/ this.clear = function(p_complementClear)
    		{
    			// Reseta o model
    			var model = self.getModel();
    			if(model)
    			{
    				var keys = Object.keys(model);
    				for(var i = 0 ; i < keys.length ; i++)
    				{
    					model[keys[i]] = null;
    				}
    			}
    			if(p_complementClear)
    			{
    				p_complementClear();
    			}
    			
        		$rootScope.$broadcast('form.reset.' + internalId);
    		};
    		
    		/**
    		 * Cria um JSon copiando os valores do model
    		 */
    		/*public*/ this.readForm = function()
    		{
    			var json = {};
				var tempVal;
				var ids = Object.keys(map);
				for(var i = 0 ; i < ids.length ; i++)
				{
					tempVal = model[ids[i]];
					if(!tempVal && tempVal !== 0)
					{
						tempVal = null;
					}
					if(tempVal && typeof tempVal === 'object')
					{
						// É data?
						if(Object.prototype.toString.call(tempVal) == "[object Date]")
						{
							json[ids[i]] = new Date(tempVal.getTime());
						}
						else
						{
							// Copia o valor utilizando o extend
							json[ids[i]] = {};
							angular.extend(json[ids[i]], tempVal);
						}
					}
					else
					{
						json[ids[i]] = tempVal;
					}
					
					// Verifica se tem transformador de Resultado
					var jsonValTransform = map[ids[i]].jsonValTransform;
					if(jsonValTransform)
					{
						jsonValTransform(json, map[ids[i]], json[ids[i]]);
					}
				}
    			return json;
    		};
    		

			this.addValidation = function(formField, formController) {
				// Se for required, seta no componente
				if(formField.type != "DateTime")
				{
					if(formController) {
						FormFieldValidate.clearValidations(formOptions.pageConfig.formValidateTarget, formField.id, formController[formField.id]);
					}
					// Se tiver formValidateTarget setado
					if(formField.required && formOptions.pageConfig && formOptions.pageConfig.formValidateTarget)
					{
						FormFieldValidate.addValidation(formOptions.pageConfig.formValidateTarget, formField.id, FormFieldValidate.validators.NOT_NULL);
					}
				}
				if(formField.type == "DateTime" || formField.type == 'Month')
				
				{
					if(formController) {
						FormFieldValidate.clearValidations(formOptions.pageConfig.formValidateTarget, formField.dateFieldModel.id, formController[formField.dateFieldModel.id]);
						FormFieldValidate.clearValidations(formOptions.pageConfig.formValidateTarget, formField.timeFieldModel.id, formController[formField.timeFieldModel.id]);
					}
					if(formField.required)
					{
						// Se tiver formValidateTarget setado
						if(formOptions.pageConfig && formOptions.pageConfig.formValidateTarget)
						{
							FormFieldValidate.addValidation(formOptions.pageConfig.formValidateTarget, formField.dateFieldModel.id, FormFieldValidate.validators.NOT_NULL);
							FormFieldValidate.addValidation(formOptions.pageConfig.formValidateTarget, formField.timeFieldModel.id, FormFieldValidate.validators.NOT_NULL);
						}
					}
				}
			}

    		/*public*/ this.reloadValidation = function(formController) 
			{
				let formField;
				let arr;
				for(var j = 0; j < rowList.length; j++) {
					arr = rowList[j];
					for(var i = 0; i < arr.length; i++)
					{
						formField = arr[i];
						if(!formField.isField) {
							continue;
						}
						this.addValidation(formField, formController);
					}
				}
			}

    		/**
    		 * Cria um Json utilizando apenas os valores default's do campo
    		 */
    		/*protected*/ this.getDefaultValueJson = function()
    		{
    			var json = {};
				var tempObj;
				var ids = Object.keys(map);
				for(var i = 0 ; i < ids.length ; i++)
				{
					tempObj = map[ids[i]];
					if(tempObj && !isNull(tempObj.defaultValue))
					{
						json[ids[i]] = tempObj.defaultValue;
					}
					else
					{
						json[ids[i]] = null;
					}
				}
    			return json;
    		};
    	};
    }
})();