(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProntuaryFormDirectiveController', ProntuaryFormDirectiveController);
        
	ProntuaryFormDirectiveController.$inject = ['$scope', '$translate', 'PrintProntuaryFormDialogInvoker', 'ProntuaryAttendance', 'ConfirmDialogInvoker'];
    function ProntuaryFormDirectiveController($scope, $translate, PrintProntuaryFormDialogInvoker, ProntuaryAttendance, ConfirmDialogInvoker)
    {
    	var vm = this;
		vm.prontuaryFormConfig = $scope.prontuaryFormConfig;
		if(!vm.prontuaryFormConfig) {
			vm.prontuaryFormConfig = {
				pageConfig: {
					pageMode: 'READONLY'
				}
			};
		}
		if($scope.prontuary) {
			vm.prontuaryFormConfig['prontuary'] = $scope.prontuary;
		}
    	vm.attendanceId = vm.prontuaryFormConfig.attendanceId;
		console.log(vm.attendanceId);
		
    	vm.prontuaryFormConfig.pageConfig = vm.prontuaryFormConfig.pageConfig || {
			pageMode: vm.prontuaryFormConfig.pageMode || 'READONLY'
		};

		vm.onDeleteClick = vm.prontuaryFormConfig.onDeleteClick;
		console.log(vm.prontuaryFormConfig);
		vm.prontuaryFormConfig.parseJson = vm.prontuaryFormConfig.parseJson || parseJson;
		vm.itemHasPoints = false;
		vm.isAttendance = vm.prontuaryFormConfig.attendanceId || vm.prontuaryFormConfig.isAttendance;
		vm.calculateTotalSumPoints = function() {
			var totalSumPoints = null;
			if(vm.itemHasPoints) {
				var domain = vm.prontuaryFormConfig.prontuary;
				totalSumPoints = 0;
				for(var i = 0; i < domain.prontuaryItems.length; i++) {
					var item = domain.prontuaryItems[i];
					if(item.hasPoints) {
						for(var j = 0; j < item.prontuaryItemOptions.length; j++) {
							var option = item.prontuaryItemOptions[j];
							if(option.points && option.selected) {
								totalSumPoints = (totalSumPoints||0) + option.points;
							}
						}
					}
				}
			}
			vm.prontuaryFormConfig.prontuary.totalSumPoints = totalSumPoints;
		}
		if(vm.isAttendance) {
			var tempFunction;
			if(vm.prontuaryFormConfig.onSelectOption) {
				tempFunction = vm.prontuaryFormConfig.onSelectOption;
			}
			vm.prontuaryFormConfig.onSelectOption = function() {
				if(tempFunction) {
					tempFunction();
				}
				vm.calculateTotalSumPoints();
			}

			
			var domain = vm.prontuaryFormConfig.prontuary;
			vm.itemHasPoints = false;
			for(var i = 0; i < domain.prontuaryItems.length; i++) {
				var item = domain.prontuaryItems[i];
				vm.itemHasPoints = item.hasPoints;
				if(vm.itemHasPoints) {
					break;
				}
			}
			
			 /*public*/ vm.prontuaryFormConfig.saveProntuaryAttendance = function()
			 {
					
				var p_json = vm.prontuaryFormConfig.parseJson(vm.prontuaryFormConfig.prontuary);
				if(vm.prontuaryFormConfig.attendanceId) {
					p_json.attendanceIdFk = vm.prontuaryFormConfig.attendanceId;
				}
				if(vm.prontuaryFormConfig.client) {
					p_json.clientIdFk = vm.prontuaryFormConfig.client.id;
				}
				console.log(p_json);
	
				if(p_json.id) {
					ProntuaryAttendance.update(p_json, function(res, putResponseHeaders) {
						console.log(res);
						res.prontuaryType = 'ATTENDANCE';
						vm.prontuaryFormConfig.prontuary = res;
					});
				} else {
					ProntuaryAttendance.save(p_json, function(res, putResponseHeaders) {
						console.log(res);
						res.prontuaryType = 'ATTENDANCE';
						vm.prontuaryFormConfig.prontuary = res;
					});
				}
					
			};
			
			function callbackDeleteProntuaryAttendance() {
				
				ProntuaryAttendance.delete({id: vm.prontuaryFormConfig.prontuary.id}, function(res, putResponseHeaders) {
					if(vm.prontuaryFormConfig.onDeleteProntuaryAttendance)
						vm.prontuaryFormConfig.onDeleteProntuaryAttendance()
				})
				
			}
			
			vm.prontuaryFormConfig.deleteProntuaryAttendance = function() {
				ConfirmDialogInvoker.invoke(
					{
						callback: function(res) { 
							if(res) {
								callbackDeleteProntuaryAttendance();
							} 
						}, 
						text: $translate.instant("client.page.records.prontuaries.deleteConfirmationMessage")
					}
				);
			}
		}

		vm.print = function() {
			PrintProntuaryFormDialogInvoker.invoke({
				clientName: (vm.prontuaryFormConfig.client) ? vm.prontuaryFormConfig.client.name : "-",
				prontuaries: vm.prontuaryFormConfig.prontuary
			});
		}

		function parseJson(prontuary) {
			var domain = {};
			angular.extend(domain, prontuary);
			if(domain.prontuaryType == 'MODEL' && vm.isAttendance) {
				domain.prontuaryModelIdFk = domain.id;
				domain.id = null;
				for(var i = 0; i < domain.prontuaryItems.length; i++) {
					var item = domain.prontuaryItems[i];
					item.prontuaryModelItemIdFk = item.id;
					item.id = null;
					for(var j = 0; j < item.prontuaryItemOptions.length; j++) {
						var option = item.prontuaryItemOptions[j];
						option.prontuaryModelItemOptionIdFk = option.id;
						option.id = null;
					}
					if(item.grid) {
						item.grid.prontuaryModelGridIdFk = item.grid.id;
						item.grid.id = null;
						if(item.grid.rows) {
							for(var j = 0; j < item.grid.rows.length; j++) {
								var row = item.grid.rows[j];
								row.prontuaryModelGridRowIdFk = row.id;
								row.id = null;
								if(row.cells) {
									var validCells = [];
									for(var k = 0; k < row.cells.length; k++) {
										var cell = row.cells[k];
										if(cell.selected || cell.text) {
											validCells.push(cell);
										}
									}
									row.cells = validCells;
								}
								
							}
						}
						if(item.grid.columns) {
							for(var j = 0; j < item.grid.columns.length; j++) {
								var column = item.grid.columns[j];
								column.prontuaryModelGridColumnIdFk = column.id;
								column.id = null;
							}
						}
					}
				}
			}
			var json = {};
			var tempVal;
			console.log(domain);
			var ids = Object.keys(domain);
			for(var i = 0 ; i < ids.length ; i++)
			{
				tempVal = domain[ids[i]];
				if(!tempVal && tempVal !== 0)
				{
					tempVal = null;
				}
				if(tempVal && typeof tempVal === 'object')
				{
					if(ids[i] == 'type' || ids[i] == 'prontuaryTab') {
						json[ids[i]] = tempVal.id;
					} else {
						if(Object.prototype.toString.call(tempVal) == "[object Date]") {
							json[ids[i]] = new Date(tempVal.getTime());
						}
						else if(Array.isArray(tempVal)) {
							json[ids[i]] = [];
							for(var j = 0; j < tempVal.length; j++) {
								var obj = parseJson(tempVal[j]);
								if(obj) {
									json[ids[i]].push(obj);
								}
							}
						} else {
							json[ids[i]] = {};
							angular.extend(json[ids[i]], tempVal);
						}
					}
					
				}
				else
				{
					json[ids[i]] = tempVal;
				}
			}
			return json;
		}
     };
})();