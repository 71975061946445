(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('attendance', {
            parent: 'entity',
            url: '/attendance?page&sort',
            data: {
                authorities: [PermissionSet.ATTENDANCE.access, PermissionSet.PRODUCT.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/managers/attendance/attendance.html',
                    controller: 'AttendanceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                gridDefaultSort: function()
                {
                	return {
	            		"name": "plannedDate", 
	            		"type": "asc"
                	};
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.attendance.title',
	                	icon: $rootScope.menuIcons.main.attendance,
	                	formValidateTarget: 'Attendance',
	                	permission: PermissionSet.ATTENDANCE
                	})
                }],
                attendanceStatusList: ['Attendance', function(Attendance)
                {
                	return Attendance.listStatus().$promise;
                }],
                attendanceProductStatusList: ['Attendance', function(Attendance)
                {
                	return Attendance.listAttendanceProductStatus().$promise;
                }],
                procedurePlaceList: ['ProcedurePlace', function(ProcedurePlace)
                {
                	return ProcedurePlace.listAllActive().$promise;
                }],
                familyProcedureList: ['FamilyProcedure', function(FamilyProcedure)
                {
                    return FamilyProcedure.listAllIncludeInactive().$promise;
                }],
                requestTypeList: ['Attendance', function(Attendance)
                {
                    return Attendance.listRequestType().$promise;
                }]
            }
        })
        .state('attendance.base-domain', {
            parent: 'attendance',
            abstract: true,
            url: '/domain/:salesRequestId/:selectSalesRequestOnCreate/:start/:end/:schedulableId/:procedurePlaceId/:equipmentId/:familyProcedureId/:procedureId',
            resolve:
            {
            	salesRequestForCreate: ['SalesRequest', '$stateParams', function(SalesRequest, $stateParams)
                {
            		var salesRequestId = $stateParams.salesRequestId;

            		// Se tiver id definido, busca o objeto
            		if(salesRequestId)
            		{
            			return SalesRequest.getForAttendanceCreate({id: salesRequestId}).$promise;
            		}
            		else
            		{
            			return null;
            		}
                }],
                paymentOptionList: ['PaymentOption', function(PaymentOption)
                {
                	return PaymentOption.listAllPaidActive().$promise;
                }],
                therapyPlaceList: ['TherapyPlace', function(TherapyPlace)
                {
                    return TherapyPlace.listAll().$promise;
                }],
                userPermissions: ['Attendance', function(Attendance)
                {
                    return Attendance.getPermissions().$promise;
                }],
                attendanceCostOptionList: ['Attendance', function(Attendance)
                {
                    return Attendance.listAttendanceCostOptions().$promise;
                }],
                anesthetistList: ['Attendance', function(Attendance)
                {
                    return Attendance.listAnesthetistSelectOptions().$promise;
                }],
                isToShowRecordsButton: ['Attendance', function(Attendance)
                {
                    return Attendance.isToShowRecordsButton().$promise;
                }],
                account: ['Principal', function(Principal)
                {
                    return Principal.identity();
                }],
                schedulable: ['Attendance', '$stateParams', function(Attendance, $stateParams)
                {
                	if($stateParams.schedulableId)
                	{
                		return Attendance.getSchedulable({schedulableId: $stateParams.schedulableId}).$promise;
                	}
                	else
                	{
                		return null;
                	}
                }],
                equipment: ['Equipment', '$stateParams', function(Equipment, $stateParams){
                	if($stateParams.equipmentId)
                	{
                		return Equipment.get({id: $stateParams.equipmentId}).$promise;
                	}else{
                		return null;
                	}
                }],
                procedurePlace: ['ProcedurePlace', '$stateParams', function(ProcedurePlace, $stateParams){
                	if($stateParams.procedurePlaceId)
                	{
                		return ProcedurePlace.get({id: $stateParams.procedurePlaceId}).$promise;
                	}else{
                		return null;
                	}
                }],
                familyProcedure: ['FamilyProcedure', '$stateParams', function(FamilyProcedure, $stateParams){
                	if($stateParams.familyProcedureId)
                	{
                		return FamilyProcedure.get({id: $stateParams.familyProcedureId}).$promise;
                	}else{
                		return null;
                	}
                }],
                procedure: ['Procedure', '$stateParams', function(Procedure, $stateParams){
                	if($stateParams.procedureId)
                	{
                		return Procedure.get({id: $stateParams.procedureId}).$promise;
                	}else{
                		return null;
                	}
                }],
                firstAttendanceStatusList: ['Attendance', function(Attendance)
                {
                    return Attendance.listFirstAttendanceStatus().$promise;
                }],
            }
        })
        .state('attendance.detail', {
            parent: 'attendance.base-domain',
            url: '/{id}/detail',
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/attendance/attendance-domain.html',
                    controller: 'AttendanceDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['$stateParams', 'Attendance', function($stateParams, Attendance)
                {
                    return Attendance.get({id : $stateParams.id}).$promise;
                }],
                medicNoteInfo: ['$stateParams', 'Attendance', function($stateParams, Attendance)
                {
                	return Attendance.getMedicNotesInfo({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]            
            }
        })
        .state('attendance.edit', {
            parent: 'attendance.base-domain',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/attendance/attendance-domain.html',
                    controller: 'AttendanceDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['Attendance', '$stateParams', function(Attendance, $stateParams)
                {
                    return Attendance.get({id : $stateParams.id}).$promise;
                }],
                medicNoteInfo: ['$stateParams', 'Attendance', function($stateParams, Attendance)
                {
                	return Attendance.getMedicNotesInfo({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('attendance.new', {
            parent: 'attendance.base-domain',
            url: '/new',                    
            views: {
                'content@': {
                	templateUrl: 'app/pages/managers/attendance/attendance-domain.html',
                    controller: 'AttendanceDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {}
                },
                medicNoteInfo: ['Attendance', function(Attendance)
                {
                	return Attendance.getMedicNotesInfo({id : null}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            },
            params: {
                dataSchedule: null
            }
        });
/*        .state('select-sales-request', {
            parent: 'attendance',
            url: '/select-sales-request',
            params: {
            	clientList: null,
            	procedure: null
            },                    
            data: {
                authorities: [PermissionSet.ATTENDANCE.access]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/managers/attendance/select-sales-request/select-sales-request-dialog.html',
                    controller: 'SelectSalesRequestController',
                    controllerAs: 'vm',
                    size: 'lg',
                    windowClass: 'modal-dialog-90-percent',
                    resolve: {
                    	pageConfig: ['$rootScope', function($rootScope)
                        {
                        	return new PageConfig({
                        		pageMode: "FILTER",
        	                	formValidateTarget: 'SelectSalesRequest'
                        	})
                        }]
                    }
                }).result.then(function(p_salesRequest) {
                	$state.go('attendance.new', {
                		salesRequestId: (p_salesRequest) ? p_salesRequest.id : null,
                		selectSalesRequestOnCreate: true
                	});
                }, function() {
                    $state.go('^');
                });
            }]
        });*/
    }
})();
