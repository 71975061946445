(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('TherapyPlace', TherapyPlace);

    TherapyPlace.$inject = ['$resource', 'URL', 'SERVICES'];

    function TherapyPlace ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/therapy-place/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'listAll': { method: 'GET', url: url + 'api/therapy-place/list-all', isArray: true },
            'getByCode': { method: 'GET', url: url + 'api/therapy-place/get-by-code/:code'}
        });
    }
})();
