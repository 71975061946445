(function () {
    'use strict';

    angular
        .module('sgmApp')
        .factory('Test2Service', Test2Service);

    Test2Service.$inject = ['$resource', 'URL', 'SERVICES'];

    function Test2Service ($resource, URL, SERVICES)
    {
        var url = URL[SERVICES.environment].api;
        var service = $resource(url + 'api/attendance-temp/:id', {}, {
        	'update': { method:'PUT' }
//            'listSchedulable': {url: url + 'api/availability-schedule/list-schedulable', method: 'GET', isArray: true},
//            'listAvailabilitySchedule': {url: url + 'api/availability-schedule/list-availability-schedule/:id', method: 'GET', isArray: true},
//            'refreshAvailabilitySchedule': {url: url + 'api/availability-schedule/refresh-availability-schedule', method: 'POST', isArray: true},
//            'delete': {url: url + 'api/availability-schedule/:id', method: 'DELETE'}
        });

        return service;
    }
})();
