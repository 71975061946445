(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('MeasureUnitDomainController', MeasureUnitDomainController);

    MeasureUnitDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'MeasureUnit', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout'];

    function MeasureUnitDomainController(FormDomain, $scope, $state, entity, MeasureUnit, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "MEASURE_UNIT",
        	stateRoot: "measureUnit",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: MeasureUnit,
        	historyConfig: {historyId: "MEASURE_UNIT"},
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
				cellCssClass: "col-sm-6",
				type: "String",
				id: "id",
				required: true,
				label: "client.global.label.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})])
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					label: "common.domain.measureUnit.description",
					required: true
				})
        	])
			.endConfig();

        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };

        /*public*/ vm.goBack = function()
        {
        	$state.go("measureUnit");
        }
    }
})();
