(function () {
    'use strict';

    angular
        .module('sgmApp')
        .factory('Register', Register);

    Register.$inject = ['$resource', 'URL', 'SERVICES'];

    function Register ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        return $resource(url + 'api/register', {}, {});
    }
})();
