(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('DateTimeFormFieldDirectiveController', DateTimeFormFieldDirectiveController);
    
    DateTimeFormFieldDirectiveController.$inject = ['$scope', '$translate', '$rootScope', 'FormConstants'];
    function DateTimeFormFieldDirectiveController($scope, $translate, $rootScope, FormConstants)
    {
    	var vm = this;
    	
    	vm.fieldModel = $scope.fieldModel;
    	vm.objModel = $scope.objModel;
    	vm.editMode = $scope.editMode;
    	
    	vm.readOnly = vm.fieldModel.readOnly || !vm.editMode;
    	
    	var dateFieldId = vm.fieldModel.id + "_date";
    	var timeFieldId = vm.fieldModel.id + "_time";
    	
    	/**
    	 * Procura no model pelo valor <nome_campo>NoTimezone. Isso indica que é um modelo que veio
    	 * do banco de dados e deve ser inicializado a partir deste valor. O formato será SEMPRE: yyyy-MM-dd HH:mm:ss
    	 */
    	if(vm.objModel[vm.fieldModel.id + 'NoTimezone'])
    	{
    		trySetValueNoTimezone();
    	}
    	
    	/**
    	 * Incializa os valores com base no valor deste model
    	 * 
    	 * É esperado o valor como um objeto Date
    	 */
    	/*private*/ function init()
    	{
	    	vm.objModel[dateFieldId] = DateTimeUtil.extractDate(vm.objModel[vm.fieldModel.id]);
	    	vm.objModel[timeFieldId] = DateTimeUtil.extractTime(vm.objModel[vm.fieldModel.id]);
    	};
    	init();
    	
    	/**
    	 * É disparado pelos formFields nested.
    	 * Deve recalcular o valor principal 
    	 */
		var onChange = vm.fieldModel.onChange;
    	vm.fieldModel.onChange = function()
    	{
    		var val = null;
    		try
    		{
	    		var date = vm.objModel[vm.fieldModel.dateFieldModel.id]; 
	    		var time = vm.objModel[vm.fieldModel.timeFieldModel.id];

	    		// Se a data existe...
	    		if(isValidDate(date))
	    		{
	    			// Se o time não existe ou é inválido, usa 00:00
	    			if(!DateTimeUtil.isValidTime(time))
	    			{
	    				time = "00:00";
	    			}
	    			
	    			var timeTokens = $.trim(time).split(":");
	    			val = createNDate(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(timeTokens[0], 10), parseInt(timeTokens[1], 10), 0);
					const maxDate = vm.fieldModel.maxDate || new Date();
					if(vm.fieldModel.maxDate || vm.fieldModel.nowAsMaxDate) {
						const timeFieldModelId = vm.fieldModel.timeFieldModel.id;
						if(val > maxDate) {
							$scope.form.$error[timeFieldModelId + "_dateOverMaxLimit"] =  $scope.form[timeFieldModelId];
						} else if($scope.form.$error[timeFieldModelId + "_dateOverMaxLimit"]) {
							delete $scope.form.$error[timeFieldModelId + "_dateOverMaxLimit"];
						}
					}
					
					
	    		}
    		}
    		catch(eee)
    		{
    			console.error("Error at calculate value for DateTimeFormField " + vm.fieldModel.id + ": " + eee.message);
    		}
    		
    		// Verifica se houve mudanças
    		if(vm.objModel[vm.fieldModel.id] != val)
    		{
    			checkFormChange();
    		}
    		vm.objModel[vm.fieldModel.id] = val;
			if(onChange) {
				onChange();
			}
    	};
    	
    	/*public*/ vm.clear = function()
    	{
    		var isClear = (!vm.objModel[vm.fieldModel.id] && !vm.objModel[vm.fieldModel.id + "_date"] && !vm.objModel[vm.fieldModel.id + "_time"]);
    		
    		vm.objModel[vm.fieldModel.id] = null;
    		vm.objModel[vm.fieldModel.id + "_date"] = null;
    		vm.objModel[vm.fieldModel.id + "_time"] = null;
    		
    		if(!isClear)
    		{
    			checkFormChange();
    		}
    	};
    	
    	/*private*/ function checkFormChange()
    	{
    		// Se tiver setado o listener checkFormChange
    		if(vm.fieldModel.checkFormChange)
    		{
    			vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
    		}
    	};
    	
    	/**
    	 * Tenta setar o valor com base no campo <nome_do_campo>NoTimezone
    	 * 
    	 * O formato será SEMPRE: yyyy-MM-dd HH:mm:ss
    	 */
    	/*private*/ function trySetValueNoTimezone()
    	{
    		var temp = vm.objModel[vm.fieldModel.id + 'NoTimezone'];
    		if(temp)
    		{
	    		try
	    		{
	    			var tokens = temp.split(" ");
	    			var dateTokens = tokens[0].split("-");
	    			var timeTokens = tokens[1].split(":");
	    			vm.objModel[vm.fieldModel.id] = createNDate(parseInt(dateTokens[0], 10), parseInt(dateTokens[1], 10) - 1, parseInt(dateTokens[2], 10),
	    											parseInt(timeTokens[0], 10), parseInt(timeTokens[1], 10), parseInt(timeTokens[2], 10)); 
	    			// Limpa o campo para não ser carregado mais de uma vez
	    			delete vm.objModel[vm.fieldModel.id + 'NoTimezone']
	    			
	    		}
	    		catch(eee)
	    		{
	    			console.log("Error at parse field " + vm.fieldModel.id + "NoTimezone: " + vm.objModel[vm.fieldModel.id + 'NoTimezone'] + " / " + eee.message);
	    		}
    		}    		
    	}

    	/**
    	 * Chamado ao reset do FORM
    	 */
    	var resetListener = $rootScope.$on('form.reset.' + vm.fieldModel.formInternalId, function()
    	{
	    	/**
	    	 * Incializa os valores com base no valor deste model
	    	 * 
	    	 * É esperado o valor como um objeto Date
	    	 */
    		init();

			vm.objModel[dateFieldId] = DateTimeUtil.initDate(vm.objModel[dateFieldId]);
			vm.objModel[timeFieldId] = DateTimeUtil.initTime(vm.objModel[timeFieldId]);
    	});
    	
    	/**
    	 * Chamado para setar o valor dos campos programaticamente
    	 * 
    	 * O parâmetro p_date deve ser um Date
    	 */
    	var setValueListener = $rootScope.$on('form.setValue.' + vm.fieldModel.id, function(p_event, /*Date*/ p_date)
    	{
    		vm.objModel[vm.fieldModel.id] = (p_date) ? p_date : null;
	    	vm.objModel[dateFieldId] = (p_date) ? p_date : null;
	    	vm.objModel[timeFieldId] = (p_date) ? DateTimeUtil.extractTime(p_date) : null;
    	});
    	
   	
    	/**
    	 * Chamado quando o model é alternado por fora e os componentes aninhados tem q ser atualizados
    	 * 
    	 * TODO: Unificar com o reset 
    	 */
    	var refreshListener = $rootScope.$on('form.refresh.' + vm.fieldModel.id, function()
    	{
	    	/**
	    	 * Inicializa os valores com base no valor deste model
	    	 * 
	    	 * É esperado o valor como um objeto Date
	    	 */
    		init();

			vm.objModel[dateFieldId] = DateTimeUtil.initDate(vm.objModel[dateFieldId]);
			vm.objModel[timeFieldId] = DateTimeUtil.initTime(vm.objModel[timeFieldId]);
    	});
    	
    	// Evento runtime ReadOnly
    	vm.currentReadOnly = vm.readOnly
    	var readOnlyListener = $rootScope.$on(FormConstants.Events.Runtime.ReadOnly(vm.fieldModel.id), function(p_event, p_readOnlyParam)
    	{
    		if(p_readOnlyParam.absolute)
    		{
    			vm.readOnly = p_readOnlyParam.readOnly;
    		}
    		else
    		{
    			vm.readOnly = (p_readOnlyParam.readOnly) ? true : vm.currentReadOnly;
    		}

    		// Repassa para os filhos
    		$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly(dateFieldId), p_readOnlyParam);
    		$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly(timeFieldId), p_readOnlyParam);
    	});
    	
    	// Destroy os listeners
        $scope.$on('$destroy', function()
        {
        	if(angular.isDefined(setValueListener) && setValueListener != null)
            {
        		setValueListener();
            }
            if(angular.isDefined(resetListener) && resetListener !== null)
            {
            	resetListener();
            }
            if(angular.isDefined(readOnlyListener) && readOnlyListener !== null)
            {
            	readOnlyListener();
            }
            if(angular.isDefined(refreshListener) && refreshListener !== null)
            {
            	refreshListener();
            }
        });
    };
})();