(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SearchModalController', SearchModalController);

    SearchModalController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'UcGridService', '$translate', '$timeout', 'ControllerListener', 'UcAlertService', 'SearchIcons', 'ClientCommon', 'account', 'searchModalConfig'];

    function SearchModalController($scope, $uibModalInstance, $rootScope, UcGridService, $translate, $timeout, ControllerListener, UcAlertService, SearchIcons, ClientCommon, account, searchModalConfig)
    {
        var vm = this;
		vm.config = searchModalConfig || $scope.searchModalConfig;
		vm.config.bindLabel = vm.config.bindLabel || "defaultDescription";
        if(!vm.config)
        {
        	UcAlertService.error("Undefined searchModalConfig");
        	$uibModalInstance.dismiss();
        	return;
        }
		vm.searchPageConfig = new PageConfig({
			pageMode: "FILTER",
		})
        
        $rootScope.initialFocus("search");
        vm.buttonsModel = {
			onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
		}
        vm.extraButtons = vm.config.extraButtons;
        
        vm.SearchIcons = SearchIcons;
        vm.filter = {
        	search: null
        };
        var currentSearchValue = null;
        var refreshSelectedRows = false;
        vm.searchIdLowerCase = vm.config.searchId.substring(1, 0).toLowerCase() + vm.config.searchId.substr(1);
        
        vm.selectedMap = {};
        // Incializa o mapa de selecionados com os usuários passados como parâmetro
        if(vm.config.currentValue)
        {
        	var item;
        	for (var i = 0; i < vm.config.currentValue.length; i++)
        	{
        		item = vm.config.currentValue[i];
        		var obj = {id: item.id, name: item[vm.config.bindLabel], minimal: true, item: item};
				vm.selectedMap["k" + item.id] = obj;
			}
        }
        
        vm.noItemsSelected = (Object.keys(vm.selectedMap).length == 0);
        
        var selectedIcon = {
    		headerName: null, 
    		field: null, 
    		width: 50,
            suppressSizeToFit: true,
            suppressSorting: true,
            cellStyle: {alignItems: "center", textAlign: "center"},
    		cellRenderer: function (p_)
            {
    			
    			// Se o item estiver selecionado
    			var lineMarkClass = "";
    			
    			if(p_.data && p_.data.id && vm.selectedMap["k" + p_.data.id])
    			{
    				lineMarkClass = "line-mark-selected"
    			}
    			
    			var lineMarkId = "";
    			if(p_.data)
    			{
    				lineMarkId = "line-mark-k" + p_.data.id;
    			}
            	return '<i id="' + lineMarkId + '" class="' + lineMarkClass + ' fa fa-check" aria-hidden="true" style="font-size:20px"></i>';
            }
    	};
        vm.gridFieldList = UcGridService.createFieldList([selectedIcon].concat(vm.config.fieldList || [
        	UcGridService.field.AVATAR(),
        	{headerName: $translate.instant("client.global.label.id"), field: "id", cellStyle: {alignItems: "center", textAlign: "center"}, width: 10},
        	{headerName: $translate.instant("common.domain.client.name"), field: "name", width: 30},
        	{headerName: $translate.instant("common.domain.client.cpf"), field: "cpf", cellStyle: {alignItems: "center", textAlign: "center"}, width: 25},
        	{headerName: $translate.instant("common.domain.client.birthDate"), field: "birthDate", cellStyle: {alignItems: "center", textAlign: "center"}, width: 25},
			{headerName: $translate.instant("common.domain.client.cel"), field: "cel", cellStyle: {alignItems: "center", textAlign: "center"}, width: 25, 
				cellRenderer: function(p_param) { 
					let str = "";
					let i = 0;
					let attributes = [
						'cel',
						'cel2',
						'whatsapp',
						'phone',
						'phoneBussiness'
					]
					attributes.forEach((r) => {
						
						if(p_param.data && p_param.data[r] != null && p_param.data[r].length > 0) {
							if(i != 0) {
								str += "\n";
							}
							if(r == 'whatsapp') {
								str += "<div class='phone-number-container whatsapp-style'>"
							} else {
								str += "<div class='phone-number-container'>";
							}
							str += p_param.data[r]+"</div>";
							i++;
						}
					});
					return  "<div style='display: flex; align-items: center;flex-wrap: wrap;'>" + str +"</div>";
				}  
			},
        	{headerName: $translate.instant("client.global.label.age"), field: "age", cellStyle: {alignItems: "center", textAlign: "center"}, width: 10},
        	ClientCommon.createOpenModalGridIcon({
        		field: "id",
        		icon: "fa fa-info-circle",
        		hint: "client.global.label.details",
        		account: account,
        		callback: function()
            	{
            		grid.refresh();	
            	}
        	})
        ]));

        var gridParams = {
        	gridId: vm.config.gridId || "CLIENT_MODAL",
			gridWrapperId: vm.config.gridWrapperId,
        	startOnLoad: true,
        	detailsIcon: false,
        	responsiveHeight: false,
        	authenticate: false,
        	getFilterData: function() { 
				var criteria = {};
				if(vm.config.criteria) {
					Object.assign(criteria, vm.config.criteria)
				}
				if(vm.config.formHandler) {
					Object.assign(criteria, vm.config.formHandler.readForm());
				} else {
					var key = vm.config.searchKey || 'search';
					criteria[key] = vm.filter.search;
				}
				return criteria;
			},
        	gridFieldList: vm.gridFieldList, 
        	rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
        	sort: vm.config.sort || {"name": "name", "type": "asc"},
        	onRowClicked: function(p_data){ onRowClicked(p_data); },
        	onViewportChanged: function() { refreshSelectedRows = true; }
        };
        var grid = UcGridService.create(gridParams);
        
        vm.gridOptions = grid.getGridOptions();

        /**
         * Escuta o filtro para atualizar o grid (filtro e selecionados)
         */
        ControllerListener.create($scope, function()
        {
        	// Só faz a query quando os parâmetros mudam
        	if(vm.filter.search != currentSearchValue)
        	{
        		currentSearchValue = vm.filter.search;
        		grid.refresh();
        	}
        	
        	// Só atualiza os selecionado quando a flag indica
        	if(refreshSelectedRows)
        	{
        		updateRowCssClass();
        	}
        });

        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        
        /**
         * Remove o item direto da lista de seleção
         */
        /*public*/ vm.removeItem = function(p_item)
        {
        	toogleRow(p_item.id, null, false);
        };
        
        /**
         * Chama o callback do requester e fecha a janela
         */
        /*public*/ vm.closeAndReturn = function()
        {
			var list = [];
			var keys = Object.keys(vm.selectedMap);
			if(vm.config.closeAndReturn) {
				list = vm.config.closeAndReturn(vm.selectedMap, keys);
			} else {
				
				var obj;
				for (var i = 0; i < keys.length; i++)
				{
					obj = vm.selectedMap[keys[i]];
					obj.defaultDescription = obj[vm.config.bindLabel];
					list.push(obj);
				}
				// Ordena
				list.sort(function(p_1, p_2)
				{
					var v1 = (p_1.defaultDescription) ? p_1.defaultDescription.toUpperCase() : "";
					var v2 = (p_2.defaultDescription) ? p_2.defaultDescription.toUpperCase() : "";
					if(v1 > v2)
					{
						return 1;
					}
					else if(v1 < v2)
					{
						return -1;
					}
					else
					{
						return 0;
					}
				});
			}
        	$uibModalInstance.close(list);
        };
        
        /**
         * Limpa os selecionados
         */
        /*public*/ vm.clear = function()
        {
			var keys = Object.keys(vm.selectedMap);
			for (var i = 0; i < keys.length; i++)
			{
        		delete vm.selectedMap[keys[i]];
        		setRowCss(keys[i], false);
			}
			updateRowCssClass();
        };
        
        /*public*/ vm.clickExtraButton = function(p_btn)
        {
        	try
        	{
        		p_btn.callback($uibModalInstance);
        	}
        	catch(exc)
        	{
        		console.error("Error at clickExtraButton: " + exc.message);
        	}
        }
        
        /**
         * Representa o clique na linha 
         */
        /*private*/ function onRowClicked(p_data)
        {
        	if(!p_data.event.defaultPrevented)
        	{
	        	if(p_data && p_data.data && p_data.data.id)
	        	{
					if(vm.config.customToggleRowCondition && !vm.config.customToggleRowCondition(p_data.data)) {
						return
					}
	        		toogleRow(p_data.data, true);
	        	}
        	}
        }

        /*private*/ function toogleRow(p_data, p_scopeDigest)
        {
			var p_id = p_data.id;
			var p_name = p_data[vm.config.bindLabel];
        	var key = "k" + p_id;
        	var flag;
        	// Se já existir, desmarca. Se não, marca
        	if(vm.selectedMap[key])
        	{
        		flag = false;
        		delete vm.selectedMap[key];
        	}
        	else
        	{
        		// Se não for multiplo, desmarca os que estiverem marcados
        		if(!vm.config.multiple)
        		{
        			var keys = Object.keys(vm.selectedMap);
        			for (var i = 0; i < keys.length; i++)
        			{
                		delete vm.selectedMap[keys[i]];
                		setRowCss(keys[i], false);
					}
        		}
        		
        		flag = true;
        		vm.selectedMap[key] = {id: p_id, name: p_name, minimal: true, item: p_data}
        	}
        	setRowCss(p_id, flag);
        	
        	vm.noItemsSelected = (Object.keys(vm.selectedMap).length == 0);
        	
        	if(p_scopeDigest)
        	{
        		$scope.$digest();
        	}
        	
        	// Se não é multiplo e tem seleção, retorna o valor
        	if(!vm.config.multiple && !vm.noItemsSelected)
        	{
        		vm.closeAndReturn();
        	}
        };

        
        /**
         * Configura para o próximo ciclo a seleção (só css da linha)
         */
        /*private*/ function scheduleSelectRow(p_id)
        {
        	$timeout(function()
        	{
        		setRowCss(p_id, true);
        	}, 1)
        };
        
        /*private*/ function setRowCss(p_id, p_flag)
        {
        	if(p_id && ("" + p_id).indexOf("k") != 0)
        	{
        		p_id = "k" + p_id;
        	}

        	// Atualiza o estilo da linha
        	if(p_flag)
        	{
        		$("#line-mark-" + p_id).removeClass("line-mark-remove");
        		$("#line-mark-" + p_id).addClass("line-mark-selected");
        	}
        	else
        	{
        		$("#line-mark-" + p_id).removeClass("line-mark-selected");
        		$("#line-mark-" + p_id).addClass("line-mark-remove");
        	}
        	updateRowCssClass();
        };
        
        /*private*/ function updateRowCssClass()
        {
    		// Adiciona e seta a classe conforme classes auxiliares
    		$(".line-mark-selected").closest(".ag-row").addClass("ag-row-selected")
    		$(".line-mark-remove").closest(".ag-row").removeClass("ag-row-selected")
    		$(".line-mark-remove").removeClass("line-mark-remove");
    		refreshSelectedRows = false;
        };
		/*private*/ function doFilter()
		{
			grid.refresh();
		};
		
		/*private*/ function doClear()
		{
			vm.config.formHandler.clear();
			doFilter();
		};
    	var closeListener = $rootScope.$on('search-modal.close.' + vm.config.searchId, function(p_event, p_list)
    	{
    		$uibModalInstance.close(p_list)
    	});
    	
    	$scope.$on('$destroy', function()
    	{
    		closeListener();
    	});
		
	}
})();