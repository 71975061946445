(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('AttendanceFreeScheduleDialogInvoker', AttendanceFreeScheduleDialogInvoker);

    AttendanceFreeScheduleDialogInvoker.$inject = ['$uibModal'];

    function AttendanceFreeScheduleDialogInvoker($uibModal)
    {
		var vm = this;
		
		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/attendance/attendance-free-interval/attendance-free-schedule-dialog.html',
				controller: 'AttendanceFreeScheduleDialogController',
				controllerAs: 'vm',
				size: 'lg',
				windowClass: 'modal-dialog-90-percent modal-dialog-schedule',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
					defaultDurationList: ['Attendance', function (Attendance){
	            			return Attendance.listDefaultDuration().$promise;
	            		}	
	        		],
                    schedulableList: ['Attendance', function(Attendance)
                    {
                    	return Attendance.listSchedulable({}).$promise;
                    }]
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();
