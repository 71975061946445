(function() {
    'use strict';

    angular.module('sgmApp').service('CepSearchEngine', CepSearchEngine);

    CepSearchEngine.$inject = ['viaCep', '$log', 'UcAlertService', '$translate', 'GeneralService', '$http'];
    
    function CepSearchEngine(viaCep, $log, UcAlertService, $translate, GeneralService, $http)
    {
    	var vm = this;
    	
    	var DEFAULT_FORM = 
    	{
    		value: null,
    		showError: true
    	};
    	
    	/*public*/ vm.search = function(p_form, p_callback)
    	{
    		UcAlertService.dismissAll();
    		
    		var form = createForm(p_form);
    		
    		// Callback obrigatório
    		if(!p_callback)
    		{
    			UcAlertService.error("Undefined callback for cep-search-engine");
    			return;
    		}

    		if(!form.value || form.value === '')
    		{
    			$log.warn("Null value for cep-search-engine");
    			returnNull(form, p_callback);
    			return;
    		}
    		
    		//TODO:SNK - Terminar o interceptor withoutCredentials
    		$http.get('https://viacep.com.br/ws/'+p_form.value+'/json/', {withCredentials: false}).then(
    			function(p_response)
	    		{
	    			if(p_response && p_response.data)
	    			{
	    				var data = p_response.data;
	    				var ret = new SearchEngineReturn({
	    					address: data.logradouro,
	    					district: data.bairro,
	    					zipCode: data.cep,
	    					complement: data.complemento,
	    					gia: data.gia,
	    					ibge: data.ibge,
	    					city: data.localidade,
	    					uf: data.uf,
	    					unity: data.unidade
	    				});
	    			
	    				GeneralService.getCityByIBGECode({code: data.ibge}, 
	    					function(p_cityDto)
	    					{
	    						ret.cityDto = p_cityDto;
	    						p_callback(ret);
	    					},
	    					function()
	    					{
	    						p_callback(ret);
	    					});
	    			}
	    			else
	    			{
	    				returnNull(form, p_callback);	    				
	    			}
	    		},
	    		function(p_)
	    		{
	    			returnNull(form, p_callback);
	    		});
    		
    	};

    	/*private*/ function returnNull(p_form, p_callback)
    	{
			showError(p_form);
			p_callback(null);
    	};

    	
    	/*private*/ function showError(p_form)
    	{
    		if(p_form.showError)
    		{
    			UcAlertService.warning($translate.instant("client.global.messages.warning.emptyOrInvalidZipCode"));
    		}
    	};

    	/*private*/ function createForm(p_form)
    	{
    		var form = {};
    		
    		// Carrega os valores default
    		angular.extend(form, DEFAULT_FORM);
    		
    		// Sobreescreve só os passados como parâmetro
    		angular.extend(form, (p_form) ? p_form : {});

    		return form;
    	};
    }
    
	/**************************************************/
	/**************************************************/
	
    var _DEFAULT_SEARCH_ENGINE_RETURN = {
		address: null,
		district: null,
		zipCode: null,
		complement: null,
		gia: null,
		ibge: null,
		city: null,
		uf: null,
		unity: null
    };
    
	/*public*/ /*class*/ function SearchEngineReturn(p_)
	{
		// Carrega os valores default
		angular.extend(this, _DEFAULT_SEARCH_ENGINE_RETURN);
		
		// Sobreescreve só os passados como parâmetro
		angular.extend(this, (p_) ? p_ : {});
	}
    
})();
