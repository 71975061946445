(function() {
    'use strict';
    
    angular.module('sgmApp').service('FormStatePersister', FormStatePersister);

    FormStatePersister.$inject = [];
    
    function FormStatePersister()
    {
    	var vm = this;
    	
    	/*private*/ var map = {};
    	
    	/*public*/ vm.resetAll = function()
    	{
    		map = {};
    	};
    	
    	/*public*/ vm.get = function(p_formId, p_defaultValue)
    	{
    		var val = map[p_formId];

    		// Se não existe, cria
    		if(val == null)
    		{
    			if(!p_defaultValue)
    			{
    				p_defaultValue = {};
    			}
    			val = {state: {}, defaultValue: p_defaultValue};
    			map[p_formId] = val;
    			vm.reset(p_formId);
    		}

    		return val.state;
    	};
    	
    	/*public*/ vm.reset = function(p_formId)
    	{
    		var val = map[p_formId];

    		// Se não existe, cria
    		if(val != null)
    		{
    			var keys = Object.keys(val.defaultValue);
    			for(var i = 0; i < keys.length; i++)
    			{
    				// Se for função, invoca
    				if(val.defaultValue[keys[i]] && angular.isFunction(val.defaultValue[keys[i]]))
    				{
    					val.state[keys[i]] = val.defaultValue[keys[i]]();
    				}
    				else
    				{
    					val.state[keys[i]] = val.defaultValue[keys[i]];
    				}
    			}
    		}
    	};
    	
    };
})();
