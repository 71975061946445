(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SalesRequestModalController', SalesRequestModalController);

    SalesRequestModalController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'UcGridService', '$translate', '$timeout', 'ControllerListener', 'UcAlertService', 'SearchIcons', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', 'FormFilter', 'FormConstants', 'pageConfig', 'clientList', 'procedureList', 'showGoWithoutSelection', 'familyProcedureList', 'salesRequestStatusList', 'onlyOpen', 'attendanceInput', 'familyProcedure', 'procedure', 'schedulable'];

    function SalesRequestModalController($scope, $uibModalInstance, $rootScope, UcGridService, $translate, $timeout, ControllerListener, UcAlertService, SearchIcons, FrontEndSelectDataSource, BackEndSelectDataSource, FormFilter, FormConstants, pageConfig, clientList, procedureList, showGoWithoutSelection, familyProcedureList, salesRequestStatusList, onlyOpen, attendanceInput, familyProcedure, procedure, schedulable)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        
        vm.showGoWithoutSelection = !!showGoWithoutSelection;
        vm.onlyOpen = !!onlyOpen;
        
        //var formFieldClassCss = (vm.onlyOpen) ? "col-xs-6 col-sm-4" : "col-xs-6 col-sm-3";
        var formFieldClassCss = "col-xs-6 col-sm-4";

        var formOptions = {
        	formStatePersisterId: "SalesRequestModal",
        	//checkFormChange: function() { doFilter(); }
        };
		let salesRequestCriteria = {}
        let familyProcedureCriteria = {};

		if(schedulable) {
			console.log(schedulable);
			if(schedulable.type == "USER") {
				salesRequestCriteria.medicId = schedulable.id;
				familyProcedureCriteria.hasTherapyPlace = false;
			}
			else if(schedulable.type == "THERAPY") {
				salesRequestCriteria.therapyPlaceCode = schedulable.id;
				familyProcedureCriteria.hasTherapyPlace = true;
			}
		}
        var familyProcedureFormField = new FormField({
       		cellCssClass: formFieldClassCss,
       		type: "Select",
			id: "familyProcedure",
			dependencyName: "familyProcedureListId",
			label: "common.domain.salesRequest.familyProcedure",
			multiple: true,
			dataSource: FormConstants.DataSources.BackEnd.FamilyProcedure(BackEndSelectDataSource, $translate, familyProcedureCriteria),
    	});
        
        var procedureFormField = new FormField({
       		cellCssClass: formFieldClassCss,
       		type: "Select",
			id: "procedure",
			label: "common.domain.salesRequest.procedure",
			multiple: true,
			searchActiveOnly: true,
			dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, true, true)
    	});
        
        var firstLine = [new FormField({
	       		cellCssClass: formFieldClassCss,
	       		type: "NumberRaw",
				id: "salesRequestId",
				label: "client.global.label.id"
        	}),
        	familyProcedureFormField,
        	procedureFormField,        	
        ];
        
        var secondLine = [new FormField({
	       		cellCssClass: formFieldClassCss,
				type: "SelectModal",
				id: "clientList",
				label: "common.domain.salesRequest.client",
				multiple: true,
				modalConfig: {
					bindLabel: "name",
					searchId: "Client"
				}
	    	})
        ];
        
        if(!vm.onlyOpen)
        {
        	secondLine.push(new FormField({
        		cellCssClass: formFieldClassCss,
				type: "Select",
				id: "status",
				label: "common.domain.salesRequest.status",
				dataSource: FrontEndSelectDataSource.create(salesRequestStatusList)
	        }));
        }
        else
        {
        	secondLine.push(new FormField({
				type: "Hidden",
				id: "statusId"
	        }));
        }
        
        vm.formHandler = FormFilter.create(formOptions)
    	.add(firstLine)
    	.add(secondLine)
		.endConfig();
        
        vm.gridFieldList = UcGridService.createFieldList([
        	{headerName: $translate.instant("client.global.label.id"), field: "id", cellStyle: {alignItems: "center", textAlign: "center"}, width: 10},
        	{headerName: $translate.instant("common.domain.salesRequest.client"), field: "client", width: 25},
        	{headerName: $translate.instant("common.domain.salesRequest.procedure"), field: "procedure", width: 35},
        	{headerName: $translate.instant("common.domain.salesRequest.sessions"), field: "sessions", cellStyle: {alignItems: "center", textAlign: "center"}, width: 15},
        	{headerName: $translate.instant("common.domain.salesRequest.status"), field: "status", cellStyle: {alignItems: "center", textAlign: "center"}, width: 15}
        ]);

        var gridParams = {
        	gridId: "SALES_REQUEST_MODAL",
        	startOnLoad: true,
        	detailsIcon: false,
        	responsiveHeight: false,
        	authenticate: false,
        	getFilterData: function() {
				var filter = {};
				Object.assign(filter, vm.formHandler.readForm(), salesRequestCriteria);
				delete filter['procedure'];
				delete filter['familyProcedure'];
				return filter; 
			},
        	gridFieldList: vm.gridFieldList, 
        	sort: {"name": "client", "type": "asc"},
        	onRowClicked: function(p_data){ onRowClicked(p_data); }
        };
        var grid = UcGridService.create(gridParams);
        
        vm.gridOptions = grid.getGridOptions();

        // Seta os valores vindos da página pai
		var filter = vm.formHandler.getModel();
		if(filter)
		{
			if(clientList)
			{
				filter["clientList"] = clientList;
			}
			if(procedureList)
			{
				filter["procedure"] = procedureList;
			}
			
			if(familyProcedure){
				filter['familyProcedure'] = [familyProcedure];
				familyProcedureFormField.readOnly = true;
			}
			if(procedure){
				filter['procedure'] = [procedure];
				procedureFormField.readOnly = true;
			}
			
		}

		/**
		 * Cancel or top-icon close
		 */
         /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        
        /*public*/ vm.goWithNoSelection = function()
        {
        	$uibModalInstance.close(null);
        };
        
        /*public*/ vm.clear = function()
        {
        	vm.formHandler.clear();
        	checkFixedValues();
    		doFilter();
        };
        
        /*private*/ function checkFixedValues()
        {
			var filter = vm.formHandler.getModel();
			if(filter)
			{
				// Seta quando for apenas status open
				if(vm.onlyOpen)
				{
					filter["statusId"] = "ABE";
				}
			}
        };
        checkFixedValues();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        vm.doFilter = doFilter;
        /**
         * Representa o clique na linha 
         */
        /*private*/ function onRowClicked(p_row)
        {
        	$uibModalInstance.close(p_row.data);
        }
	}
})();