(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsTemplateItemModalInvoker', RecordsTemplateItemModalInvoker);
	
	RecordsTemplateItemModalInvoker.$inject = ['$uibModal'];
	
	function RecordsTemplateItemModalInvoker($uibModal)
	{
		var vm = this;
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/template/item/records-template-item-modal-dialog.html',
                controller: 'RecordsTemplateItemModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: 'modal-dialog-70-percent',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT"
                    	})
                    }],
                    recordsTemplateDirList: ['RecordsTemplateDir', function(RecordsTemplateDir)
                    {
                   		return RecordsTemplateDir.listByType({recordType: p_param.recordType.id}).$promise;
                    }],
                    entity: ['RecordsTemplate', function(RecordsTemplate)
                    {
                    	if(p_param.templateId)
                    	{
                    		return RecordsTemplate.getComplete({id: p_param.templateId}).$promise;
                    	}
                    	else
                    	{
                    		return {name: null};
                    	}
                    }],
                }
            }).result.then(function(p_flag)
            {
            	if(p_param.callback)
            	{
            		p_param.callback(p_flag);
            	}

            });
		};
		
	};
})();
