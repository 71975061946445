(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('SalesRequestInvoice', SalesRequestInvoice);

    SalesRequestInvoice.$inject = ['$resource', 'URL', 'SERVICES'];

    function SalesRequestInvoice ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/sales-request-invoice/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getBySalesRequestId': {
                method: 'GET',
                url: url + 'api/sales-request-invoice/request/:id',
                isArray: true
            },
            'addConsult': {
                method: 'GET',
                url: url + 'api/sales-request-invoice/add-consult/:id',
            },
            'update': { method:'PUT' },
            'listStatus': { method: 'GET', url: url + 'api/sales-request-invoice/list-status', isArray: true },
            'listCancelCodes': { method: 'GET', url: url + 'api/sales-request-invoice/list-cancel-code', isArray: true },
            'listNaturezaOperacao': { method: 'GET', url: url + 'api/sales-request-invoice/list-natureza-operacao', isArray: true },
            'cancel': { method: 'PUT', url: url + 'api/sales-request-invoice/cancel' },
            'sendEmail': { method: 'POST', url: url + 'api/sales-request-invoice/email/:id/:attendanceId' }
        });
    }
})();
