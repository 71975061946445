/**
 * Funcionalidade comuns a página de edição de IndicationsManagere e do Modal
 * 
 * OBS: Módulo copiado de managers/client em caso de dúvidas 
 * 
 */
(function() {
    'use strict';

	angular
		.module('sgmApp')
		.service('IndicationsManagerCommon', IndicationsManagerCommon);
	
	IndicationsManagerCommon.$inject = ['$translate', '$injector'];
	
	function IndicationsManagerCommon($translate, $injector)
	{
		var vm = this;
		
		/**
		 * Configuração para as abas da página
		 */
		/*public*/ vm.tabList = [
    		{code: "indications", icon: "pe-7s-speaker", label: "client.page.indicationsManager.tab.indications.title"},
    	];
		
		
		/**
		 * Cria o ícone para abrir o modal dentro de um grid
		 * 
		 * {
		 * 	icon:
		 * 	hint:
		 * 	account:
		 * 	callback:
		 * }
		 * 
		 */
		/*public*/ vm.createOpenModalGridIcon = function(p_)
		{
        	return {
        		headerName: "", 
        		field: "id", 
        		width: 50, 
                suppressSizeToFit: true,
                suppressMenu: true,
                suppressSorting: true,
                cellStyle: {alignItems: "center", textAlign: "center", padding: "0px"},
        		cellRenderer: function (p_params)
                {
					var blinkIconClass = "";
					var iconColor = "";
					if (p_.blinkIcon && p_.blinkIcon(p_params)) {
						blinkIconClass = "blink";
						iconColor = "#ff0000";
					}
					var icon = '<i class="' + p_.icon + ' ' + blinkIconClass + '" style="color:' + iconColor + '" aria-hidden="true"></i>';
        			var hint = $translate.instant(p_.hint);
        			return '<a class="btn btn-primary" style="border:solid 0px transparent !important;background-color:transparent !important;font-size:18px;" title="' + hint + '">' + icon + '</a>';
                }, 
                onCellClicked: function(p_params)
                {
                	$injector.get("IndicationsManagerModalInvoker").invoke(new IndicationsManagerModalInvokerParam({
                		indicationsManagerId: p_params.data[p_.field],
                		account: p_.account,
                		pageMode: "READONLY",
                		callback: p_.callback
                	}));
                	
                	try {p_params.event.preventDefault(); } catch(ee) { /* silent */ }
                }
        	}
		};
		
	};
	
})();


// Configuração de APIs usados estaticamente como campos select
function IndicationsManagerCommonStatic()
{
	function f_()
	{
		var baseResolve = {
// 			clientList: ['IndicationsManagerService', function(IndicationsManagerService)
//            {
//				console.log(IndicationsManagerService.getClientByIndicationsManager({id:1}).$promisse);
//                return IndicationsManagerService.getClientByIndicationsManager({id:1}).$promisse;
//            }]
		};
		
		/*public*/ this.extendResolve = function(p_param)
		{
			var resolve = {};
			
			// Carrega os valores base
			angular.extend(resolve, baseResolve);
			
			// Sobreescreve/Adiciona só os passados como parâmetro
			angular.extend(resolve, (p_param) ? p_param : {});
			return resolve;
		}
	};
	return new f_();
}
