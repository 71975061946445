(function(){
	'use strict';
	angular.module('sgmApp').controller('ManufacturerProductStockInfoController', ManufacturerProductStockInfoController);
	
	ManufacturerProductStockInfoController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'BackEndSelectDataSource','$translate', 'manufacturerProductBatchList', 'stockPlaceList', 'stockInfo'];
	
	
	function ManufacturerProductStockInfoController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, BackEndSelectDataSource, $translate, manufacturerProductBatchList, stockPlaceList, stockInfo){
		
		var vm = this;
		vm.stockPlaceList = [];
		vm.manufacturerProductBatchListMap = {};
		if(stockInfo) {
			console.log(stockInfo);
			stockInfo.forEach(element => {
				console.log(element);
				var amount = element[0];
				var manufacturerProductBatchId = element[1];
				var manufacturerProductBatchCode = element[2];
				var stockPlaceId = element[3];
				var stockPlaceCode = element[4];
				if(!vm.stockPlaceList.includes(stockPlaceCode)) {
					vm.stockPlaceList.push(stockPlaceCode);
				}
				if(vm.manufacturerProductBatchListMap[manufacturerProductBatchId]) {
					vm.manufacturerProductBatchListMap[manufacturerProductBatchId].total += amount;
					vm.manufacturerProductBatchListMap[manufacturerProductBatchId].stockPlaces[stockPlaceCode] = amount;
				} else {
					var stockPlaces = {};
					stockPlaces[stockPlaceCode] = amount;
					vm.manufacturerProductBatchListMap[manufacturerProductBatchId] =  {
						manufacturerProductBatchId,
						manufacturerProductBatchCode,
						stockPlaces,
						total: amount,
					};
				}
				//vm.stockPlaceList.includes(element)
			})
		}
		console.log(vm.manufacturerProductBatchListMap);
		 /*public*/ vm.closeModal = function(attendanceProduct)
		{
			$uibModalInstance.close(attendanceProduct);
        };
       

	       
	}
})();
