(function() {
    'use strict';
    
    angular
	    .module('sgmApp')
	    .service('FormFieldValidate', FormFieldValidate);
    
    FormFieldValidate.$inject = ['$translate'];
    function FormFieldValidate($translate)
    {
    	var vm = this;
    	
    	var map = {};
    	
    	/*public*/ vm.addValidation = function(p_formTarget, p_fieldName, p_validationObject)
    	{
    		// Pega o validador por Formulário
    		var field = getField(p_formTarget, p_fieldName, false);
			const foundValidator = field.validators.find(function (validator) {return validator.name == p_validationObject.name});
			if(!foundValidator) {
				field.validators.push(p_validationObject);
			}
		}
		
		/*public*/ vm.clearValidations = function(p_formTarget, p_fieldName, p_ngController)
		{
			// Pega o validador por Formulário
			var field = getField(p_formTarget, p_fieldName, false);
			for(let i = 0; i < field.validators.length; i++) {
				let validator = field.validators[i];
				this.removeValidation(p_formTarget, p_fieldName, p_ngController, validator, field);
			}
		}

		/*public*/ vm.removeValidation = function(p_formTarget, p_fieldName, p_ngController, p_validationObject, p_field)
    	{
			// Pega o validador por Formulário
			var field;
			if(p_field) {
				field = p_field;
			} else {
				field = getField(p_formTarget, p_fieldName, false)
			}
			// Removendo validor do campo
			var foundValidator = field.validators.find(function (validator) {return validator.name == p_validationObject.name});
			if (foundValidator) {
				field.validators.splice(field.validators.indexOf(foundValidator), 1);
			}

			if (p_ngController)
			{
				var validationUniqueId = p_formTarget + "_" + p_fieldName + "_" + p_validationObject.name;
				p_ngController.$setValidity(validationUniqueId, true);
				var formCtrl = p_ngController.$$parentForm;
				if (formCtrl.formValidatorMap)
				{
					delete formCtrl.formValidatorMap[validationUniqueId];
				}
				if (p_ngController.ucErrorList)
				{
					delete p_ngController.ucErrorList[validationUniqueId];
				}
			}
		}
    	
    	/*public*/ vm.hasValidation = function(p_formTarget, p_fieldName)
    	{
    		return (getField(p_formTarget, p_fieldName, true) != null);
    	}

    	/*public*/ vm._directiveBind = function(p_value, p_ngController, p_formTarget, p_fieldName)
    	{
        	if(p_ngController && p_ngController.$$parentForm)
        	{
	    		var field = getField(p_formTarget, p_fieldName, false);
	    		if(field == null)
	    		{
	    			console.error("Unexpected form-field-validate at _directiveBind: " + p_formTarget + ", " + p_fieldName);
	    		}
	    		else
	    		{
	    			if(field.validators)
	    			{
						
	    				var formCtrl = p_ngController.$$parentForm;
	    				
	    				/**
	    				 * Map apenas para contar os erros por formulário 
	    				 */
	            		if(!formCtrl.formValidatorMap)
	            		{
	            			formCtrl.formValidatorMap = {};
	            		}
	            		
    					/**
    					 * Map para armazenar e listar as msgs de erro, POR field
    					 * O map por validationUniqueId.
    					 * Pode ter mais de um por field
    					 */
    					if(!p_ngController.ucErrorList)
    					{
    						p_ngController.ucErrorList = {};
    					}


		    			for(var i = 0 ; i < field.validators.length ; i++)
		    			{
		    				var validationObject = field.validators[i];
		    				
		    				var validationUniqueId = p_formTarget + "_" + p_fieldName + "_" + validationObject.name; 
		    				
		    				var ok = validationObject.exec(p_value, p_ngController);
		    				if(ok)
		    				{
    	    					p_ngController.$setValidity(validationUniqueId, true);
    	    					delete formCtrl.formValidatorMap[validationUniqueId];
    	    					delete p_ngController.ucErrorList[validationUniqueId];
		    				}
		    				else
		    				{
    	    					p_ngController.$setValidity(validationUniqueId, false);
    	    					formCtrl.formValidatorMap[validationUniqueId] = true;
    	    					
    	    					var errorMessage;
    	    					if(validationObject.messageFormatter)
    	    					{
    	    						errorMessage = validationObject.messageFormatter(validationObject.message, p_value, p_ngController);
    	    					}
    	    					else
    	    					{
    	    						errorMessage = $translate.instant(validationObject.message);
    	    					}
    	    					p_ngController.ucErrorList[validationUniqueId] = errorMessage;
		    				}
		    			}
	    			}
	    			else
	    			{
	    				console.error("form-field-validate binded with no validators: " + p_formTarget + ", " + p_fieldName);
	    			}
	    		}
    		}
    		return p_value;
    	};
    	
    	/*private*/ function getForm(p_formTarget, p_readOnly)
    	{
    		var frm = map[p_formTarget];
    		if(!frm && !p_readOnly)
    		{
    			frm = {};
    			map[p_formTarget] = frm;
    		}
    		return frm;
    	};
    	/*private*/ function getField(p_formTarget, p_fieldName, p_readOnly)
    	{
    		var frm = getForm(p_formTarget, p_readOnly);
    		if(frm == null)
    		{
    			return null;
    		}
    		var field = frm[p_fieldName];
    		if(!field && !p_readOnly)
    		{
    			field = {
    				validators: []
    			};
    			frm[p_fieldName] = field;
    		}
    		return field;
    	};    	
    };
    FormFieldValidate.prototype.validators = 
    {
    	NOT_NULL:
    	{
        	name: "notNull",
        	message: "{javax.validation.constraints.NotNull.message}",
        	exec: function(p_value, p_ngController)
    		{
   				if (!p_value && p_value !== 0)
   				{
   					return false;
   				}
   				// Array vazio também falha
   				else if (angular.isArray(p_value) && p_value.length == 0)
   				{
   					return false;
   				}
   				else
   				{
   					return true;
   				}
        	}
        },
        EXAMPLE:
        {
        	name: "validaE",
        	message: "client.teste123",
        	messageFormatter: function(p_text, p_value, p_ngController)
        	{
        		return $translate.instant(p_text, {p1: p_value, p_2: p_ngController.$name});
        	},
        	exec: function(p_value, p_ngController)
    		{
   				if (p_value.indexOf("e") > -1)
   				{
   					return true;
   				}
   				else
   				{
   					return false;
   				}
        	}
        }
	}
	
	
})();
