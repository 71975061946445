(function() {
	'use strict';

	/**
	 * Módulo de serviço para fazer requisições as APIS do sistema
	 *
	 * OBS: Módulo copiado de managers/client em caso de dúvidas
	 */
	angular
		.module('sgmApp')
		.factory('IndicationsManagerService', IndicationsManagerService);

	IndicationsManagerService.$inject = ['$resource', 'URL', 'SERVICES'];

	function IndicationsManagerService($resource, URL, SERVICES) {
		var url = URL[SERVICES.environment].api;
		var resourceUrl = url + 'api/indications-manager/:id';

		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true },
			'get': {
				method: 'GET',
				transformResponse: function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update': { method: 'PUT' },

			// lista tododas os Parceiros "IndicationsManager"
			'listAll': { method: 'GET', url: url + 'api/indications-manager/list-all', isArray: true },

			// Busca os clientes passando o id do parceiro "indicationsManager"
			'listAllInList': { method: 'GET', url: url + 'api/indications-manager/list-all-in-list', isArray: true },

		});
	}
})();
