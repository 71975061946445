(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PaymentReportDialogController', PaymentReportDialogController);

    PaymentReportDialogController.$inject = ['$scope', '$uibModalInstance', 'FormDomain', 'DownloadUtil'];

    function PaymentReportDialogController($scope, $uibModalInstance, FormDomain, DownloadUtil)
    {
        var vm = this;
        
        vm.pageConfig = new PageConfig({
    		pageMode: "CUSTOM_EDIT", // Para não ser inseridos buttons
    		formValidateTarget: "PaymentReportDialog"
    	});
        
        // Sugestão de período> mês anterior perído
        var startDate = new Date();
        startDate.setDate(1);
        startDate.setMonth(startDate.getMonth() - 1)
        
        var endDate = new Date();
        endDate.setDate(1);
        endDate.setDate(endDate.getDate() - 1);

        var entity = {
        	start: startDate,
        	end: endDate
        };
        
        var formOptions = {
        	model: entity,
        	pageConfig: vm.pageConfig,
        	buttonsModel: vm.buttonsModel,
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
	        .add([new FormField({
		        	cellCssClass: "col-sm-6",
					type: "Date",
					id: "start",
					required: true,
					label: "client.page.paymentReportDialog.start"
				}),
	        	new FormField({
		        	cellCssClass: "col-sm-6",
					type: "Date",
					id: "end",
					required: true,
					label: "client.page.paymentReportDialog.end"
	        	})
			])	        
			.endConfig();
        
        /*private*/ var generateButton = {
        	order: 0,
        	cssClass: 'btn btn-success',
        	cssClassIcon: 'fa fa-play',
        	label: 'client.global.label.generateReport',
        	type: 'submit',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function() { /* void - por ser submit, a ação está no ng-submit do form */ }
        };

        /*private*/ var closeButton = {
        	order: 10,
        	cssClass: 'btn btn-default',
        	cssClassIcon: 'fa fa-times',
        	label: 'client.global.label.close',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function()
        	{
            	$uibModalInstance.dismiss();
        	}
        };
        
        vm.buttonsModel = {
	    	customButtonList: [ generateButton, closeButton ]
	    };
        
		/*public*/ vm.closeModal = function()
		{
			$uibModalInstance.dismiss();
		}
		
        /*public*/ vm.generate = function()
        {
        	vm.formHandler.preSave(internalGenerate)
        }
        
        /*private*/ function internalGenerate(p_json)
        {
        	var formData = {
        		end: DateTimeUtil.extractDate(p_json.end),
        		start: DateTimeUtil.extractDate(p_json.start)
        	};
        	DownloadUtil.download('api/attendance/generate-payment-report', formData);
        	$uibModalInstance.dismiss();
        };
    }
})();