(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierDomainController', SupplierDomainController);
    
    SupplierDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'pageConfig', '$stateParams', 'categoryTreeDataSource'];

    function SupplierDomainController(FormDomain, $scope, $state, entity, pageConfig, $stateParams, categoryTreeDataSource)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
        vm.injectedResources = { 
        	categoryTreeDataSource: categoryTreeDataSource
        };
    };
})();
