(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProntuaryItemFormDirectiveController', ProntuaryItemFormDirectiveController);
        
    ProntuaryItemFormDirectiveController.$inject = ['$scope', '$translate', 'ProntuaryModelItemDialogInvoker', 'FormDomain', 'UpdateProntuaryItemDialogInvoker', 'TextDialogInvoker', 'ProntuaryFormGridDialogInvoker'];
    function ProntuaryItemFormDirectiveController($scope, $translate, ProntuaryModelItemDialogInvoker, FormDomain, UpdateProntuaryItemDialogInvoker, TextDialogInvoker, ProntuaryFormGridDialogInvoker)
    {
    	var vm = this;
    	vm.isAttendance = $scope.isAttendance;
		vm.prontuaryItem = $scope.prontuaryItem;
		vm.notInProcedure = $scope.notInProcedure;
    	vm.pageConfig = $scope.pageConfig || {
			pageMode: $scope.pageMode
		};
		vm.onSelectOption = function(selectedOption) {
			if(selectedOption && vm.prontuaryItem.type.id == 'RADIO') {
				console.log(selectedOption);
				changeRadioOption(selectedOption);
			}
			if($scope.onSelectOption) {
				$scope.onSelectOption();
			}
		};
		vm.prontuaryItemOptions = vm.prontuaryItem.prontuaryItemOptions;
		vm.prontuaryItemOptionsForms = null;
		vm.prontuaryItemForm = null;
		vm.grid = vm.prontuaryItem.grid;
		vm.onDeleteClick = function() {
			if($scope.onDeleteClick)
				$scope.onDeleteClick(vm.prontuaryItem.order);
		}
		vm.isBooleanType = function() {
			return vm.prontuaryItem.type != null && (vm.prontuaryItem.type.id == 'RADIO' || vm.prontuaryItem.type.id == 'CHECK');
		}
		
		vm.isGrid = function() {
			return vm.prontuaryItem.type != null && vm.prontuaryItem.type.id == 'GRID';
		}

		regenerateItems();

		function changeRadioOption(selectedOption) {
			if(selectedOption.selected) {
				for(var i = 0; i < vm.prontuaryItemOptions.length; i++) {
					var option = vm.prontuaryItemOptions[i];
					if(option.id != selectedOption.id) {
						option.selected = false;
					}
				}
			}
		}

		 /*public*/ vm.editItem = function() {
			ProntuaryModelItemDialogInvoker.invoke(
				{
					callback: callbackEditItem, 
					entity: vm.prontuaryItem,
				}
			);
		}

		vm.updateItem = function() {
			UpdateProntuaryItemDialogInvoker.invoke(
				{
					prontuaryItem: vm.prontuaryItem,
					deleteCallback: function() {
						vm.onDeleteClick()
					},
					updateCallback: updateItemCallback
				}
			);
		}
		function updateItemCallback(updatedItem) {
			if(updatedItem) {
				Object.assign(vm.prontuaryItem, updatedItem);
				console.log(vm.prontuaryItem);
				vm.prontuaryItemOptions = vm.prontuaryItem.prontuaryItemOptions;
				vm.grid = vm.prontuaryItem.grid;
				regenerateItems();
			}
		}

		function sortArray(array) {
			if(array) {
				array.sort(function(p_1, p_2)
				{
					return p_1.order - p_2.order;
				});
			}
		}

		function callbackEditItem(obj) {
			if(obj != null) {
				Object.assign(vm.prontuaryItem, obj);
				if($scope.onEditItem) {
					$scope.onEditItem(vm.prontuaryItem);
				}
				regenerateItems();
			}
		}

		function generateCellsForms(row, columnsAmount) {
			row.cellsForms = [];
			for(var i = 0; i < columnsAmount; i++) {
				var cellObj = row.cells[i];
				var cellFormField;
				if(row.textType) {
					cellFormField = {
						cellCssClass: "col-sm-12",
						type: "String",
						id: "text",
						readOnly: true,
						noLabel: true,
						inputId: vm.isAttendance ? UcUtil.randomString(10) : null,
						onClick: function(p_objModel, p_fieldModel, p_currentValue) {
							if(vm.isAttendance) {
								TextDialogInvoker.invoke(
									{
										text: p_objModel.text,
										pageConfig: vm.pageConfig,
										callback: function(text) {
											p_objModel.text = text;
										}
									}
								);
							}

						}
					};
				} else {
					cellFormField = {
						cellCssClass: "col-sm-12",
						type: "Boolean",
						id: "selected",
						noLabel: true,
						readOnly: !vm.isAttendance,
						inputId: vm.isAttendance ? UcUtil.randomString(10) : null,
					};
				}
				row.cellsForms.push({
					formField: new FormField(cellFormField),
					model: cellObj
				});
			}
		}

		vm.generateHourGrid = function() {
			ProntuaryFormGridDialogInvoker.invoke(
				{
					pageConfig: vm.pageConfig,
					callback: function(obj) {
						if(obj) {
							vm.grid.columns = obj;
							for(var i = 0; i < vm.grid.rows.length; i++) {
								var row = vm.grid.rows[i];
								row.cells = null;
							}
							regenerateItems();
						}

					}
				}
			);
		}

		function regenerateItems() {
			vm.prontuaryItemOptions = vm.prontuaryItem.prontuaryItemOptions;
			vm.prontuaryItemForm = null;
			vm.prontuaryItemOptionsForms = null;
			if(vm.prontuaryItem.type == null) {
				return;
			}
			if(vm.isBooleanType()) {
				sortArray(vm.prontuaryItemOptions);
				vm.prontuaryItemOptionsForms = [];
				for(var i = 0; i < vm.prontuaryItemOptions.length; i++) {
					var option = vm.prontuaryItemOptions[i];
					var optionField = {
						cellCssClass: "col-sm-10",
						type: vm.prontuaryItem.type.id == 'RADIO' ? "Boolean-Radio" : "Boolean",
						id: "selected",
						label: option.description,
						readOnly: !vm.isAttendance,
						inputId: vm.isAttendance ? option.id : null,
						onChange: vm.onSelectOption
					};
					var optionRow = [new FormField(optionField)];
					if(option.points) {
						optionRow.push(new FormHtmlRender({
							cellCssClass: "col-sm-2",
							label: "Pontos",
							hasLabel: true,
							render: function(p_objModel, p_fieldModel, p_pageMode, p_editMode)
							{
								var html = "<span >" + p_objModel.points + '</span>';
								return html;
							}
						}));
					}
					var optionFormDomain = FormDomain.create({
						model: option,
						pageConfig: vm.pageConfig,
					})
					.add([])
					.add(optionRow);
					vm.prontuaryItemOptionsForms.push(
						optionFormDomain
					);
				}
			} else if(vm.isGrid()) {
				if(vm.grid.columns && vm.grid.columns.length > 0) {
					for(var i = 0; i < vm.grid.rows.length; i++) {
						var cellsArray = [];
						var row = vm.grid.rows[i];
						for(var j = 0; j < vm.grid.columns.length; j++) {
							var cell = null;
							if(row.cells && row.cells.length > 0 && vm.isAttendance) {
								cell = row.cells.find(obj => obj.order == j);
							}
							if(!cell) {
								cell = {
									order: j,
									text: null,
									selected: null
								};
							}
							cellsArray.push(cell);
						}
						row.cells = cellsArray;
						console.log(row.cells);
						generateCellsForms(row, vm.grid.columns.length);
					}	
				}
			} else if(vm.prontuaryItem.type.id == 'TEXTAREA' || vm.prontuaryItem.hasObservation) {
				vm.prontuaryItemForm = 
					FormDomain.create({
						model: vm.prontuaryItem,
						pageConfig: vm.pageConfig,
					})
					.add([])
					.add([new FormField({
						cellCssClass: "col-sm-12",
						type: "TextArea",
						id: "text",
						label: vm.prontuaryItem.hasObservation ? "Observação" : null,
						readOnly: !vm.isAttendance
					})]);
			}
			vm.onSelectOption();
		}

    };
})();