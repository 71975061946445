(function() {
    'use strict';

	angular.module('sgmApp').service('AttendanceWhatsappModalInvoker', AttendanceWhatsappModalInvoker);
	
	AttendanceWhatsappModalInvoker.$inject = ['$uibModal'];
	
	function AttendanceWhatsappModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/attendance/attendance-whatsapp/attendance-whatsapp-modal-dialog.html',
                controller: 'AttendanceWhatsappModalController',
                controllerAs: 'vm',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT",
    	                	formValidateTarget: 'AttendanceWhatsapp'
                    	})
                    }],
                    entity: function()
                    {
                    	return p_param.entity;
                    }
                }
            }).result.then(function(p_)
            {
            	if(p_param.callback)
            	{
            		p_param.callback(p_);
            	}
            }, function()
            {
            	if(p_param.cancelCallback)
            	{
            		p_param.cancelCallback();
            	}
            });
		};
		
	};

})();
