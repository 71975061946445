(function() {
    'use strict';

    angular
        .module('sgmApp')
        .directive('onLastRepeat', onLastRepeat);
    
    function onLastRepeat()
    {
        return function(scope, element, attrs)
	    {
            if (scope.$last)
            {
            	setTimeout(function()
            	{
            		scope.$emit('onRepeatLast', element, attrs);
            	}, 1);
            }
        };
    }

    angular
	    .module('sgmApp')
	    .directive('onlyDigits', onlyDigits);
    
    function onlyDigits()
    {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (inputValue) {
                    if (inputValue == undefined) return '';
                    var transformedInput = inputValue.replace(/[^0-9]/g, '');
                    if (transformedInput !== inputValue) {
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                    }
                    return transformedInput;
                });
            }
        };
    }

})();


