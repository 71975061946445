(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SummaryAttendanceDomainController', SummaryAttendanceDomainController);
    
    SummaryAttendanceDomainController.$inject = ['$scope', '$translate', '$timeout', 'UcGridService', '$state', 'TabStatePersister' ];

    function SummaryAttendanceDomainController($scope, $translate, $timeout, UcGridService, $state, TabStatePersister)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var account = $scope.vm.account;
        
        if(entity && entity.id)
        {
	        var sort = {
	    		"name": "plannedDate", 
	    		"type": "desc"
	       	};
	        
	        var gridFieldList = UcGridService.createFieldList([
	        	{headerName: $translate.instant("client.page.attendance.tabs.summary.plannedDate"), field: "plannedDate", cellStyle: {alignItems: "center", textAlign: "center"}, width: 15},
	        	{headerName: $translate.instant("client.page.attendance.tabs.summary.procedure"), field: "procedureDescription", width: 31},
	        	{headerName: $translate.instant("client.page.attendance.tabs.summary.medic"), field: "medicName", width: 30},
	        	{headerName: $translate.instant("client.page.attendance.tabs.summary.status"), field: "status", width: 12, cellStyle: {alignItems: "center", textAlign: "center"}, cellRenderer: PillRenderer.renderer},
	        	{headerName: $translate.instant("client.page.attendance.tabs.summary.finalValue"), field: "finalValue", width: 12, cellStyle: {alignItems: "center", textAlign: "center"}},
                {headerName: $translate.instant("client.global.label.pendency"), field: "hasPaymentPending", width: 12, cellStyle: {alignItems: "center", textAlign: "center"}, cellRenderer: function(p_param) { return renderHasPaymentPending(p_param); }}
	        ]);

	        var grid = UcGridService.create(
	        {
	        	gridId: "CLIENT_ATTENDANCE_HISTORY",
	        	getFilterData: function() { return {clientId: entity.client[0].id}; },
	        	gridFieldList: gridFieldList, 
	        	sort: sort,
				onRowClicked: function(p_data){ 
					clearEditTabState(); 
					$state.go('attendance.detail', {id: p_data.data.id});
				 },
	        });
	                
	        vm.summaryGridOptions = grid.getGridOptions();
        }

		/*
		 * Limpa o tab da página de edição
		 */
		/*private*/ function clearEditTabState()
		{
			TabStatePersister.reset("AttendanceDomainController");
			
			// Retorna true para não interromper o fluxo da operação
			return true;
		};
        /*private*/ function renderHasPaymentPending(p_param)
		{
			var code = "";
			
			// Tem pendência de pagamento
			if(p_param.data && p_param.data.hasPaymentPending)
			{
				code += '<span class="uc-pills uc-pills-red uc-pills-small" style="cursor:default" title="' + $translate.instant("client.page.attendance.thereArePaymentPendenciesWithThisAttendance") + '">$ ' + $translate.instant("client.global.label.pendency") + '</span>';
			}

			return code;
		}
        
        /* @Override */
        /*public*/ vm.onSummarySelect = function()
        {
            if(entity && entity.id)
            {
            	grid.refresh();            	
            }
        };
    };
})();
