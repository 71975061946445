(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider)
    {
        $stateProvider.state('password',
        {
            parent: 'authenticatedPage',
            url: '/password',
            views: {
                'content@': {
                    templateUrl: 'app/common/page-domain/page-domain.html',
                    controller: 'PasswordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pageConfig: ['$rootScope', function($rootScope)
                {
                	return {
                		pageMode: "EDIT",
	                	title: 'client.page.password.title',
	                	subTitle: '',
	                	formHeader: 'client.page.password.formHeader',
	                	icon: $rootScope.menuIcons.password,
	                	formValidateTarget: 'Password'
                	}
                }]
            }
        });
    }
})();
