(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ClientAttendanceTabIndicationController', ClientAttendanceTabIndicationController);

	ClientAttendanceTabIndicationController.$inject = ['FormDomain', '$scope', '$translate', '$timeout', 'ClientManagerModalInvoker', 'Client'];
	function ClientAttendanceTabIndicationController(FormDomain, $scope, $translate, $timeout, ClientManagerModalInvoker, Client) {

		var vm = $scope.vm;

		var clientIndicationFormOptions = {
			model: vm.clientData,
			pageConfig: vm.pageConfig,
			buttonsModel: null
		};
	
		vm.clientIndicationFormOptions = FormDomain.create(clientIndicationFormOptions)
			.add(
				[new FormField({
					formTarget: "attendanceIndication",
					cellCssClass: "col-sm-4",
					type: "String",
                    id: "indicationsManagerName",
					label: "common.domain.client.indicationName",
					readOnly: true
				}),
				new FormField({
					formTarget: "attendanceIndication",
					cellCssClass: "col-sm-4",
					type: "String",
					id: "indicationsCategory",
					label: "common.domain.indicationsManager.indicationTypeDescription",
					readOnly: true
				}),
				new FormField({
					formTarget: "attendanceIndication",
					cellCssClass: "col-sm-4",
					type: "String",
					id: "originIndications",
					label: "common.domain.indicationsManager.indicationDescription",
					readOnly: true
				}),

				])

			.endConfig();

		// Sincronismo inicial
		$timeout(function() {
			//			synchIndicationsFields();
		}, 100);
	};
})();
