(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('FileUploadModalController', FileUploadModalController);

    FileUploadModalController.$inject = ['$scope', 'FileUploadParam', '$uibModalInstance', 'Upload', 'UcGridService', '$translate', 'UcAlertService', 'UcService'];

    function FileUploadModalController($scope, FileUploadParam, $uibModalInstance, Upload, UcGridService, $translate, UcAlertService, UcService)
    {
        var vm = this;

        /*public*/ vm.doUploadFile = function(p_uploadFile)
        {
        	UcAlertService.dismissBox();
        	
        	// Verifica se tem arquivo selecionado
        	if(!p_uploadFile || !p_uploadFile.name)
        	{
        		UcAlertService.message($translate.instant('client.page.fileUploadModalDialog.noSelectedFile'), "WARNING", "GROWL");
        		return;
        	}
        	
        	var json = {
        		file: p_uploadFile
        	};
        	
        	if(FileUploadParam.addExtraFormParam)
        	{
        		FileUploadParam.addExtraFormParam(json);
        	}

        	p_uploadFile.upload = Upload.upload({
        		url: FileUploadParam.uploadUrl,
        		data: json
        	});

        	p_uploadFile.upload.then(function (p_response) // ok
        	{
        		closeOk();
        	});
        };

		/**
		 * Cancel or top-icon close
		 */
        /*public*/ vm.closeModal = function()
        {
        	UcAlertService.dismissBox();
        	$uibModalInstance.dismiss();
        };
        
        /*private*/ function closeOk()
        {
        	if(FileUploadParam.okCallback)
        	{
        		FileUploadParam.okCallback();
        	}
    		$uibModalInstance.dismiss();
        }
	}
})();