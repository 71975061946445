var PillRenderer =
{
    renderer: function(p_param)
    {
    	if(p_param && p_param.value)
    	{
    		return "<span class='uc-pills " + p_param.value.cssClass + "'>" + p_param.value.label + "</span>";
    	}
    	else
    	{
    		return "";
    	}
    },
    rendererEnum: function(p_param)
    {
    	if(!p_param)
    	{
    		return null;
    	}
    	return PillRenderer.renderer({
    		value: p_param
    	});
    }
};