(function() {
    'use strict';

	angular.module('sgmApp').service('NotificationService', NotificationService);
	
	NotificationService.$inject = ['FormHandler', 'UcAlertService', 'FormFieldValidate', '$state', '$translate'];
	
	function NotificationService(FormHandler, UcAlertService, FormFieldValidate, $state, $translate)
	{
		var vm = this;
		
		/**
		 * Dispara o evento com as notificações. 
		 */
		/*public*/ vm.send = function(p_title, p_text)
		{
			var currentNotification = new Notification(p_title,
			{
				icon: 'https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/uc_logo2.png',
				body: p_text,
				sound: true,
				silent: false
			});
			currentNotification.onclick = function()
			{
				this.close();
			};
		};
	};
	
	
	/**
	 * Solicita permissão de notificação noload do script
	 */
	document.addEventListener('DOMContentLoaded', function ()
	{
		if((typeof Notification === "undefined") || !Notification)
		{
			console.error("Your browser not supports Notification"); 
			return;
		}
		else
		{
			if (Notification.permission !== "granted")
			{
				Notification.requestPermission();
			}
		}
	});


	// Deve ser inicializado com false já que o sistema deve estar em foco quando a apliação é aberta
	var windowNotShown = false;
	//var windowNotShown = true;

	$(window).focus(function()
	{
		windowNotShown = false;
	}).blur(function()
	{
		windowNotShown = true;
	});

})();
