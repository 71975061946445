(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RoleDomainController', RoleDomainController);

    RoleDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'Role', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout', 'permissionsList', 'TabStatePersister'];

    function RoleDomainController(FormDomain, $scope, $state, entity, Role, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout, permissionsList, TabStatePersister)
    {
        var vm = this;
		vm.entityPermissions = [];
		var allPermissionsSet = [];

		for(var i = 0; i < permissionsList.length; i++) {
			allPermissionsSet.push({
				id: permissionsList[i],
				defaultDescription: permissionsList[i],
				alreadyIncluded: false,
				selected: false
			})
		};
		if(entity && entity.permissionSet) {
			for(var i = 0; i < entity.permissionSet.length; i++) {
				vm.entityPermissions.push({
					id: entity.permissionSet[i],
					defaultDescription: entity.permissionSet[i],
					alreadyIncluded: true,
					selected: true
				});
				var p = allPermissionsSet.find(obj => obj.id == entity.permissionSet[i]);
				if(p) {
					p.alreadyIncluded = true;
					p.selected = true;
				}
			};
		}
		entity.permissionSet = vm.entityPermissions;
		vm.domain = entity;
		sortPermissions();
		if(!entity.roleType) {
			entity.roleType = 'U';
		}
   
        vm.pageConfig = pageConfig;
		vm.tabList = [
    		{code: "permissions", icon: "fa fa-file-text-o", label: "client.page.role.tab.permissions.title"},
    	];
		vm.tabHandler = {
			disabledMap: {},
			active: TabStatePersister.init('RoleDomainController', 'permissions'),
			isActive: function (p_code) {
				return (p_code === vm.tabHandler.active);
			},
			isDisabled: function (p_code) {
				if ((p_code === "permissions") && !vm.domain.id) {
					return true;
				}
				return false;
			},
			isShow: function (p_code) {
				return true;
			},
			select: function (p_id) {
				if (vm.tabHandler.isDisabled(p_id)) {
					return;
				}

				vm.tabHandler.active = TabStatePersister.set("RoleDomainController", p_id);
				vm.tabHandler.active = p_id;
			}
		};
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "ROLE",
        	stateRoot: "role",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };
		
		
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: Role,
        	historyConfig: {historyId: "ROLE"},
        	getForm: function(){ return $scope.editForm; }
        };

		var oldValue = Array.from(entity.permissionSet);
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
				cellCssClass: "col-sm-6",
				type: "String",
				id: "id",
				required: true,
				label: "client.global.label.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})])
        	.add([
				new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "name",
					label: "common.domain.role.name",
					required: true
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "code",
					label: "common.domain.role.code",
					required: true
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					hide: true,
					id: "roleType",
					label: "common.domain.role.roleType",
					required: true
			 	}),
        	])
			.add([
				new FormField({
					cellCssClass: "col-sm-12",
					type: "Select",
					id: "permissionSet",
					required: true,
					multiple: true,
					label: "common.domain.role.permissions",
					dataSource: FrontEndSelectDataSource.create(allPermissionsSet),
					onChange: function(p_objModel, p_fieldModel, p_value) {
						if(oldValue.length < p_value.length) {
							var p_item = p_value[p_value.length - 1];
							if(!p_item.alreadyIncluded) {
								p_item.new = true;
							} else {
								p_item.new = false;
							}
							console.log(p_item);
						}
						sortPermissions();
						oldValue = Array.from(entity.permissionSet);
						console.log(p_value);

					}
				})
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "client.global.label.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();

		function sortPermissions() {
			vm.domain.permissionSet.sort((a,b) => {
				if (a.id > b.id) {
					return 1;
				  }
				  if (a.id < b.id) {
					return -1;
				  }

				  return 0;
			});
		}
        /*public*/ vm.save = function()
        {
			var json = vm.formHandler.readForm();
			json.permissionSet = json.permissionSetId.split(";");
        	vm.formHandler.saveJson(json);
        };

        /*public*/ vm.goBack = function()
        {
        	$state.go("role");
        }
    }
})();
