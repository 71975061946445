(function() {
	'use strict';

	angular
		.module('sgmApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

	function stateConfig($stateProvider, PermissionSet) {
		$stateProvider
			.state('indications', {
				parent: 'entity',
				url: '/indications?page&sort',
				data: {
					authorities: [PermissionSet.INDICATIONS.access],
				},
				views: {
					'content@': {
						templateUrl: 'app/common/page-filter/page-filter.html',
						controller: 'IndicationsController',
						controllerAs: 'vm'
					}
				},
				resolve: {
					gridDefaultSort: function() {
						return {
							"name": "description",
							"type": "asc"
						};
					},
                    indicationTypeList: ['IndicationType', '$stateParams', function(IndicationType) {
						return IndicationType.listAllActive().$promise;
					}],
					filterPageConfig: ['$rootScope', 'account', function($rootScope, account) {
						return new PageConfig({
							pageMode: "FILTER",
							account: account,
							title: 'client.page.indications.title',
							icon: $rootScope.menuIcons.cadastre.indications,
							formValidateTarget: 'Indications',
							permission: PermissionSet.INDICATIONS
						})
					}]
				}
			})
			.state('indications.detail', {
				parent: 'indications',
				url: '/{id}/detail',
				views: {
					'content@': {
						templateUrl: 'app/common/page-domain/page-domain.html',
						controller: 'IndicationsDomainController',
						controllerAs: 'vm'
					}
				},
				resolve:
				{
					entity: ['$stateParams', 'IndicationsService', function($stateParams, IndicationsService) {
						return IndicationsService.get({ id: $stateParams.id }).$promise;
					}],
                    indicationTypeList: ['IndicationType', '$stateParams', function(IndicationType) {
                        return IndicationType.listAllActive().$promise;
                    }],
					pageConfig: ['filterPageConfig', function(filterPageConfig) {
						return filterPageConfig.clone().set(
							{
								pageMode: "READONLY"
							});
					}]
				}
			})
			.state('indications.edit', {
				parent: 'indications',
				url: '/{id}/edit',
				views: {
					'content@': {
						templateUrl: 'app/common/page-domain/page-domain.html',
						controller: 'IndicationsDomainController',
						controllerAs: 'vm'
					}
				},
				resolve:
				{
					entity: ['IndicationsService', '$stateParams', function(IndicationsService, $stateParams) {
						return IndicationsService.get({ id: $stateParams.id }).$promise;
					}],
                    indicationTypeList: ['IndicationType', '$stateParams', function(IndicationType) {
                        return IndicationType.listAllActive().$promise;
                    }],
					pageConfig: ['filterPageConfig', function(filterPageConfig) {
						return filterPageConfig.clone().set(
							{
								pageMode: "EDIT"
							});
					}]
				}
			})
			.state('indications.new', {
				parent: 'indications',
				url: '/new',
				views: {
					'content@': {
						templateUrl: 'app/common/page-domain/page-domain.html',
						controller: 'IndicationsDomainController',
						controllerAs: 'vm'
					}
				},
				resolve:
				{
					entity: function() {
						return {}
					},
                    indicationTypeList: ['IndicationType', '$stateParams', function(IndicationType) {
                        return IndicationType.listAllActive().$promise;
                    }],
					pageConfig: ['filterPageConfig', function(filterPageConfig) {
						return filterPageConfig.clone().set(
							{
								pageMode: "CREATE"
							});
					}]
				}
			});
	}

})();
