(function()
{
    'use strict';
    angular
        .module('sgmApp')
        .factory('GeneralService', GeneralService);

    GeneralService.$inject = ['$resource', 'URL', 'SERVICES'];

    function GeneralService ($resource, URL, SERVICES)
    {
        var url = URL[SERVICES.environment].api;

    	var resourceUrl =  url + 'api/general';

        return $resource(resourceUrl, {}, {
        	'getCityByIBGECode': { method: 'GET', url: resourceUrl + '/get-city-by-ibge-code/:code' }
        });
    }
})();
