(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PaymentOptionController', PaymentOptionController);

    PaymentOptionController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'PaymentOption', 'FrontEndSelectDataSource', 'FormConstants'];

    function PaymentOptionController(FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, PaymentOption, FrontEndSelectDataSource, FormConstants)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;
        
        $rootScope.initialFocus("code");
        
        vm.buttonsModel =
        {
        	stateRoot: "paymentOption",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); }
        };

        var formOptions = {
        	formStatePersisterId: "PaymentOption"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([new FormField({
	        		cellCssClass: "col-sm-6",
	        		type: "String",
	        		id: "code",
	        		label: "common.domain.paymentOption.code"
	        	}),
	        	new FormField({
		       		cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					label: "common.domain.paymentOption.description"
	        })])
	        .add([new FormField({
		       		cellCssClass: "col-sm-6",
					type: "Select",
					id: "free",
					label: "client.global.label.free",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
		     	}),
		        new FormField({
		       		cellCssClass: "col-sm-6",
					type: "Select",
					id: "active",
					label: "common.domain.paymentOption.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
		     })])
			.endConfig();

        vm.gridFieldList = UcGridService.createFieldList([
        	{headerName: $translate.instant("common.domain.paymentOption.code"), field: "code", width: 20},
        	{headerName: $translate.instant("common.domain.paymentOption.description"), field: "description", width: 35},
        	{headerName: $translate.instant("common.domain.paymentOption.type"), field: "type", width: 20, suppressSorting: true},
        	UcGridService.field.BOOLEAN({
        		title: $translate.instant("client.global.label.free"), 
        		field: "free",
        		suppressSizeToFit: false,
        		width: 5
        	}),
            UcGridService.field.ACTIVE(),	// width: 5
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "PAYMENT_OPTION",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "paymentOption",
        	account: account,
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList, 
        	sort: vm.sort,
        });
        
        vm.gridOptions = grid.getGridOptions();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        
        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};
	}
})();
