(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('PrintAttendanceProductDialogController', PrintAttendanceProductDialogController);

    PrintAttendanceProductDialogController.$inject = ['attendances', 'accountName', '$uibModalInstance', '$timeout', '$translate'];

    function PrintAttendanceProductDialogController(attendances, accountName, $uibModalInstance, $timeout)
    {
        var vm = this;
        
        var MAX_DIV_SIZE = 800;
		vm.includeHeader = true;
        var BREAK_LINE_TAGS = ["</div>", "</p>", "</li>", "</tr>", "<br>", "<br/>", "<br />"]
		vm.html = "<div class='print-page print-page-fixed-height'>";
        $timeout(function()
        {
			
        	
        	var pw = frames["toPrintFrame"];
        	pw.document.write('<html moznomarginboxes mozdisallowselectionprint><head><title>Relatório de Produtos</title>');
            pw.document.write(`<style>
			table { page-break-inside:avoid;  font-size: 8.5pt; border-collapse: collapse;word-break: keep-all; width: 100%;}
			tr    { page-break-inside:avoid; page-break-after:auto }
			thead { display:table-header-group }
			tfoot { display:table-footer-group }
			td, th, tr { border: 1px solid black; }
			.report-info-title { font-weight: bold; margin-right: 3px; }
			.report-info-row td { padding: 5px;}
			.print-page {
				height: 24.44cm !important;
				padding: 1cm !important;
				background: #FFFFFF !important;
				width: 17.2cm !important;
			}
		
			@media print {
				.print-page {
					padding: 1cm !important;
					height: 26.44cm !important;
					width: 19.2cm !important;
				}
			}
			</style>`);
            pw.document.write('</head>');
            pw.document.write(generatePrintCssStyle());
            pw.document.write('<body>');
            pw.document.write('<div id="print-area"></div> <div id="itemsize"></div> <div id="pagesize"></div>');
            pw.document.write('</body>');
            pw.document.write('</html>');
            try
            {
	            pw.document.close(); // necessary for IE >= 10
	            pw.focus(); // necessary for IE >= 10*/
            }
            catch(exc)
            {
            	console.error("Error on prepare document to print: " + exc.message);
            }
			var accum_ = "";
			var _html;
			if(Array.isArray(attendances)) {
				for(var i = 0; i < attendances.length; i++) {
					var attendance = attendances[i];
					accum_ = vm.buildPrintContent(attendance, (i < attendances.length - 1), accum_);
					console.log("accum_\n"+accum_);
					vm.includeHeader = false;
				}
				_html = accum_;
			} else {
				_html = vm.buildPrintContent(attendances, false, "");
			}
			console.log("\n\nFINAL:\n" + vm.html);
			console.log("\n\nACCUM:\n" + _html)
        	vm.html = "<div style='width: 100%;'>" + vm.html + _html + "</div></div>";
			
            $timeout(function()
            {
            	preparePrint(vm.html)
            }, 500)
            
//            $timeout(function()
//            {
//            	frames["toPrintFrame"].print();
//            }, 500)
        }, 100);

		function checkPageSize(accum, row) {
			var pageSizeContainer = $("#toPrintFrame").contents().find("#pagesize");
			var pageSizeContainer = pageSizeContainer.html(accum + row);
			console.log(pageSizeContainer.height());
			return (pageSizeContainer.height() > MAX_DIV_SIZE);
		}
		/*public*/ vm.buildPrintContent = function (attendance, includeLine, accum_) 
		{
			var attendanceProductSet = attendance.attendanceProductSet.filter(obj => obj.manufacturerProductStockFlowIdFk);
			var accum = "";
			if(vm.includeHeader) {
				accum += '<h3 style="text-align: center; margin-top: 20px;">Relatório de Produtos Utilizados</h3>';
			}
			accum += '<h5 style="text-align: center; margin-top: 5px;">Atendimento '+attendance.id+'</h3>'
			accum += '<table class="report-info" style="margin: 10px 0px;">';
			accum += '<tr class="report-info-row"><td>' 
						+ '<span class="report-info-title">Data de Geração:</span><span class="report-info-detail">'+moment().format('DD/MM/YYYY HH:mm')+'</span>' 
						+ '</td>';
			accum += '<td>' 
						+ '<span class="report-info-title">Usuário:</span><span class="report-info-detail">'+accountName+'</span>' 
						+'</td></tr>';
			accum += '<tr class="report-info-row"><td>' 
						+ '<span class="report-info-title">ID Atendimento:</span><span class="report-info-detail">'+attendance.id+'</span>' 
						+ '</td>';
			accum += '<td>' 
						+ '<span class="report-info-title">ID Requisição:</span><span class="report-info-detail">'+attendance.salesRequest.id+'</span>' 
						+ '</td></tr>';
			accum += '<tr class="report-info-row"><td>' 
						+ '<span class="report-info-title">ID Paciente:</span><span class="report-info-detail">'+attendance.salesRequest.client.id+'</span>' 
						+ '</td>';
			accum += '<td>' 
						+ '<span class="report-info-title">Nome Paciente:</span><span class="report-info-detail">'+attendance.salesRequest.client.defaultDescription+'</span>' 
						+ '</td></tr>';
			accum += '<tr class="report-info-row"><td>' 
						+ '<span class="report-info-title">Data do Atendimento:</span><span class="report-info-detail">'+moment(attendance.plannedDate).format('DD/MM/YYYY HH:mm')+'</span>' 
						+ '</td>';    
			accum += '<td>' 
						+ '<span class="report-info-title">Nome do Médico:</span><span class="report-info-detail">'+attendance.userMedicId.defaultDescription+'</span>' 
						+ '</td></tr>'; 
			accum += '<tr class="report-info-row"><td>' 
						+ '<span class="report-info-title">Procedimento:</span><span class="report-info-detail">'+(attendance.procedure ? attendance.procedure.defaultDescription : attendance.salesRequest.procedure.defaultDescription)+'</span>' 
						+ '</td>'; 
			accum += '<td class="report-info-row">' 
						+ '<span class="report-info-title">Família do Procedimento:</span><span class="report-info-detail">'+(attendance.familyProcedure ? attendance.familyProcedure.defaultDescription : attendance.salesRequest.procedure.familyProcedure.defaultDescription)+'</span>' 
						+ '</td></tr>';       
			accum += '</table>';
			accum += `<table style="width: 100%; font-size: 8.5pt; border-collapse: collapse;word-break: keep-all;">
			<tr style="background-color: #09486d; color: white; font-weight: bold;">
				<th >Desc. Gen. Produto</th>
				<th >Un.<br>Medida</th>
				<th >Qtd.<br>Usada</th>
				<th >Prod. do Fab.</th>
				<th style="width: 50px">Lote</th>
				<th >Fabricante</th>
				<th >Loc.<br>Estoque</th>
				<th >ID<br>Fluxo</th>
				<th >Data da conferência</th>
				<th >Portaria 344/98</th>
			</tr>
			`;
			if(checkPageSize(accum_, accum)) {
				vm.html += (accum_ + `</div>
					<div class='print-page print-page-fixed-height'>`);
			} else {
				accum = accum_ + accum;
			}
			var usedProducts = attendanceProductSet.filter(obj => obj.usedAmount > 0);
			for(var i = 0; i < usedProducts.length; i++) {
				var attendanceProduct = usedProducts[i];
				var row = `<tr>
					<td>`+attendanceProduct.product.description+`</td>
					<td>`+attendanceProduct.product.measureUnit.description+`</td>
					<td>`+attendanceProduct.usedAmount+`</td>
					<td>`+attendanceProduct.manufacturerProduct.name + " (" + attendanceProduct.manufacturerProduct.barCode+")"+`</td>
					<td>`+attendanceProduct.manufacturerProductBatch.batchCode+`</td>
					<td>`+attendanceProduct.manufacturerProduct.manufacturer.tradingName+`</td>
					<td>`+attendanceProduct.stockPlace.defaultDescription+`</td>
					<td>`+attendanceProduct.manufacturerProductStockFlowIdFk+`</td>
					<td>`+(attendanceProduct.manufacturerProductStockFlowDate ? moment(attendanceProduct.manufacturerProductStockFlowDate).format('DD/MM/YYYY HH:mm') : 'Não informado')+`</td>
					<td>`+(attendanceProduct.product.concierge34498 ? 'Sim' : 'Não')+`</td>
					</tr>
				`;
				if(checkPageSize(accum, row + "</table>")) {
					vm.html += (accum + `</table>
					</div>
					<div class='print-page print-page-fixed-height'>`);
					accum = `<table style="width: 100%; font-size: 8.5pt; border-collapse: collapse;word-break: keep-all;">
					<tr style="background-color: #09486d; color: white; font-weight: bold;">
						<th >Desc. Gen. Produto</th>
						<th >Un.<br>Medida</th>
						<th >Qtd.<br>Usada</th>
						<th >Prod. do Fab.</th>
						<th style="width: 50px">Lote</th>
						<th >Fabricante</th>
						<th >Loc.<br>Estoque</th>
						<th >ID<br>Fluxo</th>
						<th >Data da conferência</th>
					</tr>` + row;
					console.log(vm.html);
				} else {
					accum += row;
				}
			}
			accum += '</table>';
			if(includeLine) {
				accum += '<div style="width: 100%; height: 1px; background: black; margin: 20px 0px;"></div>'
			}
			return accum;
		}

        /*public*/ vm.print = function()
        {
        	frames["toPrintFrame"].print();
        };
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        


        /*private*/ function preparePrint(p_str) 
        {
        	
			$("#toPrintFrame").contents().find("#print-area").html(p_str);
			$("#toPrintFrame").contents().find("#pagesize").html("");
			$("#toPrintFrame").contents().find("#itemsize").html("");
        }
	}
})();