(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('User', User);

    User.$inject = ['$resource', 'URL', 'SERVICES'];

    function User ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/users/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateMyAccount': { method: 'PATCH', url: url + 'api/users/updateMyAccount' },
            'updatePassword': { method: 'PATCH', url: url + 'api/users/updatePassword' },
            'resetPassword': { method: 'PATCH', url: url + 'api/users/resetPassword' },
            'listGender': { method: 'GET', url: url + 'api/users/listGender', isArray: true },
            'getRecordsPrintConfigBackgroundImage': { method: 'GET', url: url + 'api/users/get-records-print-config-background-image/:id' },
            'getRecordsPrintConfigSignatureImage': { method: 'GET', url: url + 'api/users/get-records-print-config-signature-image/:id' },
            'removeRecordsPrintConfigBackgroundImage': { method: 'DELETE', url: url + 'api/users/remove-records-print-config-background-image/:id' },
            'removeRecordsPrintConfigSignatureImage': { method: 'DELETE', url: url + 'api/users/remove-records-print-config-signature-image/:id' }
        });
    }
})();
