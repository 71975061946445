(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('IndicationsManagerController', IndicationsManagerController);

	IndicationsManagerController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'indicationsList', 'IndicationsManagerService','Principal','UcAlertService','$uibModal'];

	function IndicationsManagerController(FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, indicationsList, IndicationsManagerService,Principal,UcAlertService,$uibModal) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		vm.buttonsModel =
		{
			stateRoot: "indicationsManager",
			onFilter: function() { doFilter(); },
			onClear: function() { doClear(); },
			extraButtonList: [

				{
					order: 96,
					cssClass: 'btn btn-success',
					cssClassIcon: 'fa fa-file-excel-o',
					label: 'client.global.label.generateIndicationsReport',
					type: 'button',
					isDisabled: function() {
						return !Principal.hasAnyAuthority(['ROLE_ATENDIMENTO_RELATORIO_PAGAMENTO', 'ROLE_ATENDIMENTO_ADMINISTRACAO']);
					},
					isToAdd: function() { return true; },
					click: function() { generateIndicationsReport(); }
				}
			]
		};

		$rootScope.initialFocus("description");

		var formOptions = {
			formStatePersisterId: "IndicationsManager"
		};


		// campos do formulário
		vm.formHandler = FormFilter.create(formOptions)
			.add([

				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "indicatorName",
					label: "common.domain.indicationsManager.indicatorName",
				}),

				new FormField({
					cellCssClass: "col-sm-3",
					type: "Phone",
					id: "indicatorPhone",
					label: "common.domain.indicationsManager.indicatorPhone",
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "indicatorAddress",
					label: "common.domain.indicationsManager.indicatorAddress"
				}),

				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "indicatorEmail",
					label: "common.domain.indicationsManager.indicatorEmail"
				}),
			])
			.endConfig();

		// Campos do Grid
		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.indicationsManager.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 5 },
			{ headerName: $translate.instant("common.domain.indicationsManager.indicatorName"), field: "indicatorName", width: 27 },
			{ headerName: $translate.instant("common.domain.indicationsManager.indicatorPhone"), field: "indicatorPhone", width: 11 },
			{ headerName: $translate.instant("common.domain.indicationsManager.indicatorAddress"), field: "indicatorAddress", cellStyle: { alignItems: "center", textAlign: "center" }, width: 4 },
			{ headerName: $translate.instant("common.domain.indicationsManager.indicatorEmail"), field: "indicatorEmail", width: 13 },
			{ headerName: $translate.instant("common.domain.indicationsManager.indicationTypeDescription"), field: "indicationTypeDescription", width: 13 },
			{ headerName: $translate.instant("common.domain.indicationsManager.indicationDescription"), field: "indicationDescription", width: 13 },
		]);


		// Configurações do Grid
		var grid = UcGridService.create(
			{
				gridId: "INDICATIONS_MANAGER",
				stateBaseName: "indicationsManager",
				account: account,
				getFilterData: function() { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				rowHeight: UcGridService.AVATAR_GRID_HEIGHT,
				sort: vm.sort,
			});

		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};

  /*private*/ function generateIndicationsReport() {
			UcAlertService.dismissBox();
			$uibModal.open({
				templateUrl: 'app/pages/managers/indications/report/indications/indications-report-dialog.html',
				controller: 'IndicationsReportDialogController',
				controllerAs: 'vm',
			});
		};

/*
		 * Limpa o tab da página de edição
		 */
		/*private*/ function clearEditTabState() {
			TabStatePersister.reset("IndicationsManagerDomainController");

			// Retorna true para não interromper o fluxo da operação
			return true;
		};

	}
})();
