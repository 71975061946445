
(function() {
	'use strict';
	angular
		.module('sgmApp')
		.controller('IndicationsReportDialogController', IndicationsReportDialogController);

	IndicationsReportDialogController.$inject = [
	    '$scope',
        '$uibModalInstance',
        'FormDomain',
        'FrontEndSelectDataSource',
        '$translate',
        'Client',
        'FormConstants',
        'BackEndSelectDataSource'
    ];

	function IndicationsReportDialogController(
	    $scope,
        $uibModalInstance,
        FormDomain,
        FrontEndSelectDataSource,
        $translate,
        Client,
        FormConstants,
        BackEndSelectDataSource
    ) {
		var vm = this;

		vm.pageConfig = new PageConfig({
			pageMode: "CUSTOM_EDIT", // Para não ser inseridos buttons
			formValidateTarget: "AgendaReportDialog"
		});

		var entity = {
			day: new Date()
		};

		var formOptions = {
			model: entity,
			pageConfig: vm.pageConfig,
			buttonsModel: vm.buttonsModel,
			getForm: function() { return $scope.editForm; }
		};
		vm.formHandler = FormDomain.create(formOptions)
			.add(new FormField({
				cellCssClass: "col-sm-12",
				type: "Date",
				id: "startDate",
				required: true,
				label: "client.page.indicationsReportDialog.startDate"
			}))
			.add(new FormField({
				cellCssClass: "col-sm-12",
				type: "Date",
				id: "endDate",
				required: true,
				label: "client.page.indicationsReportDialog.endDate"
			}))
            .add(new FormField({
                cellCssClass: "col-sm-12",
                type: "Select",
                id: "indicationsManager",
                label: "client.page.indicationsReportDialog.indications",
                dataSource: FormConstants.DataSources.BackEnd.IndicationsManager(BackEndSelectDataSource, $translate)
            }))

			.endConfig();

        /*private*/ var generateButton = {
			order: 0,
			cssClass: 'btn btn-success',
			cssClassIcon: 'fa fa-play',
			label: 'client.global.label.generateReport',
			type: 'submit',
			isDisabled: function() { return false; },
			isToAdd: function() { return true; },
			click: function() { /* void - por ser submit, a ação está no ng-submit do form */ }
		};

        /*private*/ var closeButton = {
			order: 10,
			cssClass: 'btn btn-default',
			cssClassIcon: 'fa fa-times',
			label: 'client.global.label.close',
			type: 'button',
			isDisabled: function() { return false; },
			isToAdd: function() { return true; },
			click: function() {
				$uibModalInstance.dismiss();
			}
		};

		vm.buttonsModel = {
			customButtonList: [generateButton, closeButton]
		};

		/*public*/ vm.closeModal = function() {
			$uibModalInstance.dismiss();
		}

        /*public*/ vm.generate = function() {
			vm.formHandler.preSave(internalGenerate)
		}

        /*private*/ function internalGenerate(json) {
			// Busca os dados
			Client.listAllInList({
				indicationsManagerId: json.indicationsManager && json.indicationsManager.id ? json.indicationsManager.id : null,
				startDate: json.startDate.toISOString().split('T')[0],
				endDate: json.endDate.toISOString().split('T')[0],
			}).$promise.then(function(data) {
				// Monta os dados
				var clients = configData(data);

				// Exporta o arquivo
				exportData(clients);
			});
		};
	}


	// Gera um arquivo .xlsx
	var exportData = function(data) {
		alasql('SELECT * INTO XLSX("relatorio_indicacoes.xlsx",{headers:true}) FROM ?', [data]);
	};

	//
	var configData = function(jsonData) {
		var clients = [];

		for (var c = 0; c < jsonData.length; c++) {
			clients.push({
				ID_SGR_CLIENTE: jsonData[c].id,
				CLIENTE: jsonData[c].name,
				CPF_CLIENTE: jsonData[c].cpfFormatted ? jsonData[c].cpfFormatted : "Não informado",
				CONTATO_CLIENTE: jsonData[c].cel,
				DATA_PRIMEIRA_CONSULTA: moment(jsonData[c].firstAttendance.plannedDate).format('DD-MM-YYYY HH:mm'),
				STATUS: jsonData[c].firstAttendance.status.defaultDescription,
				VALOR: Number(jsonData[c].salesRequestFinalValue),
				NOME_MEDICO: jsonData[c].firstAttendance.userMedicId.defaultDescription,
				TIPO_INDICACAO: jsonData[c].indicationsManager.indication.indicationType.description,
				DESCRICAO_INDICACAO: jsonData[c].indicationsManager.indication.description,
				NOME_PARCEIRO: jsonData[c].indicationsManager.indicatorName,
				ID_SGR_PARCEIRO: jsonData[c].indicationsManager.id
			});
		}

		return clients;
	}

})();
