(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ClientManagerDirectiveController', ClientManagerDirectiveController);

	ClientManagerDirectiveController.$inject = ['$scope', '$translate', '$rootScope', 'FormConstants', '$state', '$stateParams', 'ClientCommon', 'Client', 'FormDomain', '$timeout', 'RecordsModalInvoker', 'FormFieldValidate'];
	function ClientManagerDirectiveController($scope, $translate, $rootScope, FormConstants, $state, $stateParams, ClientCommon, Client, FormDomain, $timeout, RecordsModalInvoker, FormFieldValidate) {
		var vm = this;

		vm.domain = $scope.domain;
		vm.pageConfig = $scope.pageConfig;
		vm.injectedResources = $scope.injectedResources;

		vm.tabList = ClientCommon.tabList;
		
		var source = $scope.source;

		// Apenas para o modal
		var uibModalInstance = $scope.uibModalInstance;

		vm.tabHandler = {
			disabledMap: {},
			active: $stateParams.activeTab,
			isActive: function (p_code) {
				return (p_code === vm.tabHandler.active);
			},
			isDisabled: function (p_code) {
				if ((p_code == "photo" || p_code == "summary") && !vm.domain.id) {
					return true;
				}
				return false;
			},
			isShow: function (p_code) {
				return true;
			},
			select: function (p_id) {
				if (vm.tabHandler.isDisabled(p_id)) {
					return;
				}

				$state.go('.', { activeTab: p_id }, { notify: false });
				vm.tabHandler.active = p_id;

				// Se for Foto, recarrega a imagem
				if (p_id == "photo") {
					$scope.$broadcast('sgmApp.photoUpload.refresh');
				}
				else if (p_id == "summary") // Se for Summary, atualiza o grid
				{
					$scope.$broadcast('client-manager.refreshTab.summary');
				}
			}
		};

		var tabErrorListner = $scope.$on(FormConstants.Events.Runtime.ShowTabError, function (p_, p_arg) {
			if (p_arg) {
				vm.tabHandler.select(p_arg);
			}
		});
		$scope.$on('$destroy', function () {
			if (angular.isDefined(tabErrorListner) && tabErrorListner !== null) {
				tabErrorListner();
			}
		});

		if (!$stateParams.activeTab) {
			vm.tabHandler.select("personal");
		}

		// Se a aba inicial for a foto, carrega
		if ($stateParams.activeTab == "photo") {
			$timeout(function () {
				$scope.$broadcast('sgmApp.photoUpload.refresh');
			}, 1);

		}
		/**********************************************************/

		var formOptions = {
			model: vm.domain,
			pageConfig: vm.pageConfig,
			buttonsModel: null,
			service: Client,
			afterCreateState: "client.detail",
			showTabWithError: true,
			historyConfig: { historyId: "CLIENT" },
			afterUpdate: function () { doAfterUpdate(); },
			getForm: function () { return $scope.editForm; }
		};

		vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-3",
				type: "String",
				id: "id",
				required: true,
				label: "common.domain.client.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			}),
			new FormField({
				formTarget: "main",
				cellCssClass: (vm.domain && vm.domain.id) ? "col-sm-3" : "col-sm-6",
				type: "NumberRaw",
				id: "cadclinId",
				label: "common.domain.client.cadclinId"
			}),
			new FormField({
				cellCssClass: "col-sm-6",
				formTarget: "main",
				type: "CPF",
				id: "cpf",
				label: "common.domain.client.cpf",
				maxLength: 14,
				required:false
			}),
			new FormField({
				cellCssClass: "col-sm-6",
				formTarget: "main",
				type: "String",
				id: "clientPassport",
				label: "common.domain.client.clientPassport",
				maxLength: 50
			})
			])
			.add([new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-6",
				type: "String",
				id: "name",
				required: true,
				label: "client.global.label.name",
			}),
			new FormField({
				formTarget: "main",
				cellCssClass: "col-sm-6",
				type: "String",
				id: "ci",
				label: "common.domain.client.ci"
			})
			])
			.add([new FormField({
				cellCssClass: "col-sm-3",
				formTarget: "main",
				type: "Boolean",
				id: "specialNeeds",
				label: "common.domain.client.specialNeeds",
				onChange: function () {
					synchSpecialNeedsDescriptionField();
				}
			}),
			new FormField({
				cellCssClass: "col-sm-6",
				formTarget: "main",
				type: "String",
				id: "specialNeedsDescription",
				label: "common.domain.client.specialNeedsDescription",
				maxLength: 200
			}),
			new FormField({
				cellCssClass: "col-sm-3",
				formTarget: "main",
				type: "Boolean",
				id: "foreignClient",
				label: "common.domain.client.foreignClient",
				onChange: function () {
					synchForeignClientFields();
				}
			}),
			new FormField({
				cellCssClass: "col-sm-6",
				formTarget: "main",
				type: "String",
				id: "clientCountry",
				label: "common.domain.client.clientCountry"
			})
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					formTarget: "main",
					type: "Boolean",
					id: "diabetic",
					label: "common.domain.client.diabetic",
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					formTarget: "main",
					type: "Boolean",
					id: "active",
					label: "client.global.label.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();

        /*public*/ vm.save = function () {
			vm.formHandler.save();
		};

		/*private*/ function synchSpecialNeedsDescriptionField() {
			var readOnly = !vm.domain.specialNeeds;
			vm.formHandler.findField('specialNeedsDescription').readOnly = readOnly;
			if (readOnly) {
				delete vm.domain["specialNeedsDescription"];
			}
		}

		/*private*/ function synchForeignClientFields() {
			var hideForeignClientFields = !vm.domain.foreignClient;
			vm.formHandler.findField('clientPassport').hide = hideForeignClientFields;
			vm.formHandler.findField('clientCountry').hide = hideForeignClientFields;
			vm.formHandler.findField('cpf').hide = !hideForeignClientFields;
			
			if (!hideForeignClientFields) {
				delete vm.domain['cpf'];
				$scope.editForm.cpf.$setViewValue("");	
				$scope.editForm.cpf.$setPristine();
				
				FormFieldValidate.removeValidation(formOptions.pageConfig.formValidateTarget, 'cpf', $scope.editForm.cpf, FormFieldValidate.validators.NOT_NULL);				
				FormFieldValidate.addValidation(formOptions.pageConfig.formValidateTarget, 'clientPassport', FormFieldValidate.validators.NOT_NULL);
				FormFieldValidate.addValidation(formOptions.pageConfig.formValidateTarget, 'clientCountry', FormFieldValidate.validators.NOT_NULL);
			
			} else {
				delete vm.domain['clientPassport'];
				delete vm.domain['clientCountry'];
				
				FormFieldValidate.removeValidation(formOptions.pageConfig.formValidateTarget, 'clientPassport', $scope.editForm.clientPassport, FormFieldValidate.validators.NOT_NULL);
				FormFieldValidate.removeValidation(formOptions.pageConfig.formValidateTarget, 'clientCountry', $scope.editForm.clientCountry, FormFieldValidate.validators.NOT_NULL);
				//FormFieldValidate.addValidation(formOptions.pageConfig.formValidateTarget, 'cpf', FormFieldValidate.validators.NOT_NULL);
			}
		}

	    /*private*/ function createModalButtonList(p_modal) {
			var pageMode = vm.pageConfig.pageMode;

			var buttonList = [];

			if (p_modal) {
				if (pageMode === 'READONLY') {
					buttonList.push(createEditButton(), createCloseButton());
					handleRecordsButton(buttonList, pageMode);
				}
				else if (pageMode === 'EDIT') {
					buttonList.push(createSaveButton(), createCancelButton(), createCloseButton());
					handleRecordsButton(buttonList, pageMode);
				}
			}
			else {
				handleRecordsButton(buttonList, pageMode);
			}
			return buttonList;
		};

	    /*private*/ function handleRecordsButton(p_buttonList, p_pageMode) {
			if (p_pageMode === 'READONLY' || p_pageMode === 'EDIT') {
				if (vm.injectedResources.isToShowRecordsButton && vm.injectedResources.isToShowRecordsButton.value) {
					p_buttonList.push(createRecordsButton());
				}
			}
		}

	    /*private*/ function createEditButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'glyphicon glyphicon-pencil',
				label: 'client.global.label.edit',
				type: 'button',
				isDisabled: function () { return /*checar permissões*/ false; },
				isToAdd: function () { return true },
				click: function () {
					closeAndReopen(true);
				}
			}
		};

	    /*private*/ function createCloseButton() {
			return {
				order: 100,
				cssClass: 'btn btn-default',
				cssClassIcon: 'fa fa-times',
				label: 'client.global.label.close',
				type: 'button',
				isDisabled: function () { return false; },
				isToAdd: function () { return true; },
				click: function () {
					uibModalInstance.dismiss();
				}
			}
		};

	    /*private*/ function createCancelButton() {
			return {
				order: 100,
				cssClass: 'btn btn-accent',
				cssClassIcon: 'fa fa-undo',
				label: 'client.global.label.cancel',
				type: 'button',
				isDisabled: function () { return false; },
				isToAdd: function () { return true; },
				click: function () {
					closeAndReopen(false);
				}
			};
		};

	    /*private*/ function createSaveButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'glyphicon glyphicon-save',
				label: 'client.global.label.save',
				type: 'submit',
				isDisabled: function () { return (/*checar permissões || */ vm.buttonsModel.isSaving); },
				isToAdd: function () { return true; },
				click: function () { /* void - por ser submit, a ação está no ng-submit do form */ }
			};
		};

	    /*private*/ function createRecordsButton() {
			return {
				order: 99,
				cssClass: 'btn btn-info',
				cssClassIcon: 'fa fa fa-user-md',
				label: 'client.page.attendance.records',
				type: 'button',
				isToAdd: function () { return true },
				click: function () {
					RecordsModalInvoker.invoke({
						client: vm.domain,
						attendanceId: null
					});
				}
			}
		}

	    /*private*/ function closeAndReopen(p_edit) {
			uibModalInstance.close({
				id: vm.domain.id,
				account: vm.pageConfig.account,
				reopen: true,
				edit: p_edit
			});
		}

		// O comportamento dos botões variam de acordo com o source
		if (source == "EDIT_PAGE") {
			vm.buttonsModel = {
				isSaving: false,
				gridId: "CLIENT",
				previousState: { name: "client" },
				stateRoot: "client",
				extraButtonList: createModalButtonList(false)
			};
		}
		else if (source == "MODAL") {
			vm.buttonsModel = {
				isSaving: false,
				useSuggestedButton: false,
				stateRoot: "client",
				extraButtonList: createModalButtonList(true)
			};
		}
		else {
			alert("Config param source not set")
		}

	    /*private*/ function doAfterUpdate() {
			vm.refreshSignal();
		}

		$timeout(function () {
			vm.refreshSignal();
			synchSpecialNeedsDescriptionField()
			synchForeignClientFields()
		}, 1);

		// Implementar na aba "notes"
		/*public*/ /*abstract*/ /*vm.refreshSignal()*/		
		vm.migrar = function(){		
			console.log(Client);	
			Client.migrateAvatar(function(data){
					console.log(data);
        	});
		}

	};
})();