(function() {
    'use strict';

    agGrid.initialiseAgGridWithAngular1(angular);
    angular
        .module('sgmApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.bootstrap.contextMenu',
            'ui.router',
            'blockUI',
            'angular-growl',
            'ngBootbox',
            'infinite-scroll',
            'agGrid',
            'ui.select',
            'daterangepicker',
            'ui.utils.masks',
			'angularjs-dropdown-multiselect',
			'ui.calendar',
            'angular.viacep',
			'treeControl',
			'angularDurationFormat'
        ])	    
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope', '$filter', 'menuIcons', '$translate', '$ngBootbox', 'GridConstants', '$timeout', '$sce', 'MessageListener'];

    function run(stateHandler, translationHandler, $rootScope, $filter, menuIcons, $translate, $ngBootbox, GridConstants, $timeout, $sce, MessageListener)
    {
        stateHandler.initialize();
        translationHandler.initialize();
        
        MessageListener.start();
    
        // Pendura a constante menuIcons no $rootScope
        $rootScope.menuIcons = menuIcons;
        
        /****************************************
         ********** Config $rootScope ***********
         ****************************************/
    	$rootScope.formatNumber = function(p_value, p_decimalPlaces)
    	{
    		try
    		{
	    		if(p_value || p_value === 0)
	    		{
	    			return $filter('number')(p_value, p_decimalPlaces);
	    		}
	    		else
	    		{
	    			return "";
	    		}
    		}
    		catch(eee)
    		{
    			alert(eee.message)
    			/* Silence */
    		}
    	};
    	
    	$rootScope.tsl = function(p_)
    	{
    		return $filter('translate')(p_);
    	};
    	
    	/**
    	 * Toggle nos paineis
    	 */
    	$rootScope.clickPanelToggle = function($event)
    	{
    		try
    		{
    			$("#main-section").scope().$emit(GridConstants.Events.RefreshGrid);
    		}
    		catch(eee)
    		{
    			console.error("Erro at clickPanelToggle - $(#main-section).scope(): " + eee.console)
    		}
    		

    		var tag = $($event.currentTarget);
    		var hpanel = tag.closest('div.panel');
    		var icon = tag.find('i');
    		var body = hpanel.find('div.panel-body');
    		var footer = hpanel.find('div.panel-footer');
    		
    		if(icon.hasClass("fa-chevron-down"))
			{
    			icon.removeClass("fa-chevron-down");
    			icon.addClass("fa-chevron-up");
			}
    		else
			{
    			icon.removeClass("fa-chevron-up");
    			icon.addClass("fa-chevron-down");
			}

    		body.slideToggle(300);
    		footer.slideToggle(200);
    	};
    	
    	/**
    	 * Pré-configuração para o confirm do ng-bootbox
    	 */
    	$rootScope.confirm = function(p_)
    	{
			var buttons = {};
			if(!p_.hideSuccessButton) {
				buttons['success'] = 
				{
					label: "<i class='fa fa-check' aria-hidden='true'></i> " + $translate.instant('client.global.label.yes'),
					className: "btn btn-success",
					callback: function(){ p_.callback(); }
				};
			}
			buttons['cancel'] = {
				label: "<i class='fa fa-times' aria-hidden='true'></i> " + $translate.instant('client.global.label.no'),
				className: "btn btn-danger",
				callback: function(){ if(p_.noCallback) { p_.noCallback(); } }
			};
    		var options =
    		{
    			message: p_.message,
    			className: 'z-index-2100',
    			title: '<i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp;&nbsp;' + $translate.instant('client.global.label.confirmation'),
    			buttons: buttons
    		};
    		$ngBootbox.customDialog(options);
    	};
    	
    	/**
    	 * SearchModal
    	 */
//    	$rootScope.searchModal = function(p_)
//    	{
//    		var options =
//    		{
//                templateUrl: 'app/components/search-modal/search-modal-dialog.html',
//                size: 'lg',
//    			title: '<i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp;&nbsp;' + $translate.instant('client.global.label.confirmation'),
//    			buttons:
//    			{
//    				cancel:
//    				{
//    					label: "<i class='fa fa-times' aria-hidden='true'></i> " + $translate.instant('client.global.label.no'),
//    					className: "btn btn-danger",
//    					callback: function(){ /* empty */ }
//    				}
//    			}
//    		};
//    		$ngBootbox.customDialog(options);
//    	};
    	
    	$rootScope.initialFocus = function(p_fieldId)
    	{
    		$timeout(function()
            {
    			try { $("#" + p_fieldId).focus(); } catch(eee) { console.warn("Error at apply initialFocus " + p_fieldId); }
            }, 1000);
    	};
    	
    	// Cabeçalho do formulário. - page-domain.html
    	$rootScope.resolveFormHeader = function(p_pageConfig)
    	{
    		var key = 'client.global.label.registrationData';
	    	if(p_pageConfig && (p_pageConfig.formHeader || p_pageConfig.formHeader === ""))
	    	{
	    		key = p_pageConfig.formHeader;
	    	}
	    	return $translate.instant(key); 
    	};
    	
    	$rootScope.formatMoney = function(p_value)
    	{
    		return $filter("currency")(p_value, "");
    	}
    	
    	/**
    	 * Mensagem acima dos grids
    	 */
    	$rootScope.showGridResultMessage = function(p_gridOptions)
    	{
    		if(p_gridOptions)
    		{
	    		if(p_gridOptions.gridStateFlag == GridConstants.State.Loading)
	    		{
	    			return $translate.instant("client.global.label.loading") + "...";
	    		}
	    		else if(p_gridOptions.gridStateFlag == GridConstants.State.Complete)
	    		{
	    			var count = p_gridOptions.api.getModel().getRowCount();
	    			if(count === 1)
	    			{
	    				return $translate.instant("client.global.label.showingP0Item", {value: count});
	    			}
	    			else if(count)
	    			{
	    				return $translate.instant("client.global.label.showingP0Items", {value: count});
	    			}
	    			else
	    			{
	    				return $translate.instant("client.global.label.noItemsToShow");
	    			}
	    		}
    		}
    		return "";
    	};

    	/**
    	 * Passa o cósigo para trustedHTML
    	 */
    	$rootScope.toTrustedHtml = function(p_)
    	{
    		return $sce.trustAsHtml((!p_ && p_ !== 0) ? "" : p_);
    	}

    	/**
    	 * Para a Agenda
    	 */
		var scheduleStaticTooltipBoxContent = '' + 
		'<div class="m-b-xs tooltip-wrapper">' +
			'<div class="tooltip-item-full">' + 
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-info-circle" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="id-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-user" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="client-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-medkit" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="medic-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-file-text-o" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="procedure-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-clock-o" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="date-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="local-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-laptop" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="equipment-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="scheduling-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="obs-tgt"></span></div>' +
			'</div>' +
			'<div class="tooltip-item-locking">' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-ban" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="locking-tgt"></span></div>' +
			'	<div class="tooltip-item"><div class="tooltip-item-icon"><i class="fa fa-clock-o" aria-hidden="true"></i></div>&nbsp;&nbsp;<span class="date-tgt"></span></div>' +
			'</div>' +
  	 	'</div>';

    	$rootScope.staticTooltipBoxConfig = 
		{
    		id: "scheduleStaticTooltipBoxId",
    		height: "210px",
    		width: "300px",
    		cssClass: "schedule-static-tooltip-box",
    		content: scheduleStaticTooltipBoxContent    		
    	};

    };

})();
