(function(){
	'use strict';
	angular.module('sgmApp').controller('InstallmentExpenseCalcDialogController', InstallmentExpenseCalcDialogController);
	
	InstallmentExpenseCalcDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'installmentCalcData','Expense'];
	
	
	function InstallmentExpenseCalcDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, installmentCalcData, Expense){
		
		var vm = this;
		vm.pageConfig = pageConfig;
		
		
		vm.installmentCalcData = installmentCalcData;
		
		var formOptions = {
    			model: vm.installmentCalcData,
    			pageConfig: vm.pageConfig,
    			service: Expense,
    			afterCreateState: "expense.detail",
    			getForm: function () { return  vm.expenseCalcForm; }
        };
		
		vm.expenseCalcForm = FormDomain.create(formOptions).
			add([
				new FormField({
					type: "Money",
					readOnly: vm.readOnlyFinish,
					id: "totalValue",
					required: true,
					label: "common.domain.expense.totalValue",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Date",
					id: "firstInstallmentExpirationDate",
					label: "common.domain.expense.firstInstallmentExpirationDate"
				})]).add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					readOnly: vm.readOnlyFinish,
					id: "expensePaymentOption",
					label: "common.domain.expense.paymentOption",
					dataSource: FormConstants.DataSources.BackEnd.ExpensePaymentOption(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Month",
					readOnly: vm.readOnlyFinish,
					id: "competenceDate",
					label: "common.domain.expense.competenceDate"
				})]).add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					readOnly: vm.readOnlyFinish,
					id: "nfDoc",
					label: "common.domain.expense.nfDoc"
				})
			])
		
		 /*public*/ vm.closeModal = function(recalc)
        {
			$uibModalInstance.close(recalc);
        };
        
        vm.calcInstallments = function(){
        	vm.closeModal(true);
        }
	       
	}
})();
