(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('NotesAttendanceDomainController', NotesAttendanceDomainController);
    
    NotesAttendanceDomainController.$inject = ['FormDomain', '$scope', '$state', 'Attendance', '$rootScope', '$translate', '$timeout', 'Client', 'TextDialogInvoker'];

    function NotesAttendanceDomainController(FormDomain, $scope, $state, Attendance, $rootScope, $translate, $timeout, Client, TextDialogInvoker)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var pageConfig = $scope.vm.pageConfig;
        var permissions = vm.permissions;
        var medicNoteInfo = $scope.vm.medicNoteInfo;
        
        var notesData = {
        	obs: null,
        	clientObs: null,
        	medicObs: null
        };
        var notesFormOptions = {
			model: notesData,
			pageConfig: vm.pageConfig,
			buttonsModel: null
        };
        
        var inputsReadOnly = true;
        if(pageConfig.pageMode == 'CREATE') // Criação editável
        {
        	inputsReadOnly = false;
        }
        else if(pageConfig.pageMode == 'EDIT' && permissions.hasMainEditPermission) // Edição, se tiver permissão
        {
        	inputsReadOnly = false;
        }
        
        
        vm.notesFormHandler = FormDomain.create(notesFormOptions);
        if(medicNoteInfo != null && medicNoteInfo.show)
        {
	        vm.notesFormHandler.add([new FormField({
						formTarget: "notes",
			       		cellCssClass: "col-sm-12",
						type: "TextArea",
						id: "medicObs",
						label: "client.page.attendance.tabs.notes.medicObs",
						readOnly: inputsReadOnly || !medicNoteInfo.edit,
						extraCssClass: "small-textarea"
			})]);
        }

        vm.notesFormHandler.add([new FormField({
					formTarget: "notes",
	           		cellCssClass: "col-sm-6",
					type: "TextArea",
					id: "clientObs",
					readOnly: true,
					label: "common.domain.client.clientObs",
					extraCssClass: "medium-textarea"
        		}),
        		new FormField({
					formTarget: "notes",
	           		cellCssClass: "col-sm-6",
					type: "TextArea",
					id: "obs",
					label: "client.page.attendance.tabs.notes.salesRequestObservation",
					readOnly: inputsReadOnly,
					extraCssClass: "medium-textarea"
        		})
        ]).add([

		]);
        vm.notesFormHandler.endConfig();
        
        // @Override
	    /*public*/ vm.saveNotes = function(p_json)
	    {
	    	//p_json['clientObs'] = pageConfig.pageMode == 'CREATE' ? notesData['clientObs'] : null;
	    	p_json['obs'] = notesData['obs'];
	    	p_json['medicObs'] = notesData['medicObs'];
	    };
	    
		vm.addClientNote = function() {
			if(vm.clientId == null) {
				UcAlertService.error($translate.instant("client.page.attendance.tabs.notes.errorClient"))
			} else {
				TextDialogInvoker.invoke(
					{
						title: $translate.instant("client.page.attendance.tabs.notes.addNote"),
						pageConfig: {
							pageMode: 'CREATE'
						},
						callback: function(text) {
							createClientNote(text);
						}
					}
				);
			}
			
		}

		function createClientNote(text) {
			Client.createClientNote({clientId: vm.clientId, content: text}, function(res) {
				notesData['clientObs'] = res.value;
			})
		}

	    /**
	     * Seta/Atualiza a nota do médico 
	     */
	    // @Override
	    /*public*/ vm.syncMedicNotes = function(p_clientId)
	    {
	    	Client.getMedicNotesInfo({id : p_clientId}, function(p_dto)
	    	{
				console.log("loloç");
				console.log(p_dto);
	    		notesData['medicObs'] = (p_dto) ? p_dto.text : null;
				console.log(notesData);
	    	});
	    }


        // @Override
	    /*public*/ vm.syncClientNotes = function(p_clientObs)
	    {
        	// Só p criação
        	if(!entity.salesRequest)
        	{
        		notesData['clientObs'] = p_clientObs;
        		refreshSignal();
        	}
	    };

        /*private*/ function initTab()
        {
        	// Se é EDIÇÃO, carrega diretamente do SalesRequest
        	if(entity.salesRequest)
        	{
        		notesData.obs = entity.salesRequest.obs;
        		notesData.clientObs = entity.salesRequest.client.clientObs;
        	}
        	else 
        	{
        		// CRIAÇÃO
        		var salesRequestParam = $scope.vm.salesRequestParam;
        		if(salesRequestParam) 
        		{
        			// Quando está usando uma requisição, carrega a observação da requisição. O cliente é carregado indiretamente pelo métido vm.syncClientNotes
        			notesData.obs = salesRequestParam.obs;
        		}
        		else
        		{
        			// Criando tudo nova, não faz nada no init
        		}
        	}
        	notesData.medicObs = (medicNoteInfo != null && medicNoteInfo.show) ? medicNoteInfo.text : null;
        	refreshSignal();
        };
        
        /**
         * Sincroniza a "bolinha" para só ser exibida quando houver contéudo na aba
         * 
         * NÃO ATUALIZAR no onChange, só nos eventos de load e troca de cliente 
         */
        /*private*/ function refreshSignal()
        {
        	$timeout(function()
        	{
        		// ALgum campo preenchido?
        		if(notesData.clientObs || notesData.obs || (medicNoteInfo != null && medicNoteInfo.show && notesData.medicObs))
        		{
        			$("#notesSignal").removeClass("display-none");
        		}
        		else
        		{
        			$("#notesSignal").addClass("display-none");
        		}
        	}, 500); // 500 milis de delay
        };
        
       	$timeout(function()
       	{
       		initTab();
       	}, 1);

    };
})();
