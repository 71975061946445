(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('Test2Controller', Test2Controller);

    Test2Controller.$inject = ['$scope', 'Test2Service', 'UcDatasourceService', '$timeout'];
    
    
    // 10008 - medico

    function Test2Controller ($scope, Test2Service, UcDatasourceService, $timeout)
    {
        var vm = this;
        
        UcDatasourceService.fetch(
        		{
    				gridId: 'ATTENDANCE',
    				//medicListId: 10008, // Id do Médico
    				therapyPlaceCode: 'SL2', // Código da Sala 
    				page: 1,
    				plannedDateStart: '2016-08-19 00:00:00', // Datas
    				plannedDateEnd: '2018-08-19 23:59:59',
    				size: 50,
    				sort: 'plannedDate,desc',
        		}, 
        		function(p_list)
        		{
        			if(p_list && p_list.content)
        			{
        				for (var i = 0; i < p_list.content.length; i++)
        				{
        					var row = p_list.content[i];
        					console.log(row.id + " - " + row.plannedDate + " - " + row.plannedDateEnd + " - " + row.medic +  " - " + row.medicId + " - " + row.therapyPlace +  " - " + row.therapyPlaceCode + " - " + row.obs);
						}
        			}
        		});
        
        
        $timeout(function()
        {
//        	create("SL2");
        	
        	var id = 19393;
        	
//        	Test2Service.delete({id: id});
        	
        	get(id);
        	//update(id, "10008", "Evento update", "2017-12-01 09:00:00", "2017-12-01 09:45:00", false);
        	
        });
        
        
        function update(p_id, p_idSchedulable, p_title, p_start, p_end, p_allDay)
        {
            Test2Service.update({
            	id: p_id,
            	idSchedulable: p_idSchedulable,
            	title: p_title,
            	start: p_start,
            	end: p_end,
            	allDay: p_allDay
            },
            function(p_)
            {
            	get(p_id)
            },
            function()
            {
            	console.error("Erro");
            });
        	
        }
        

        function create(p_idSchedulable)
        {
            Test2Service.save({
            	idSchedulable: p_idSchedulable,
            	title: "Evento 2",
            	start: '2017-08-24 11:00:00',
            	allDay: true
            },
            function(p_)
            {
            	console.log("Ok " + p_);
            },
            function()
            {
            	console.error("Erro");
            });

        }
        
        function get(p_id)
        {
        	Test2Service.get({id: p_id}, function(p_)
        	{
        		console.log(">>>>>>>>>>> Id: " + p_.id);
        		console.log(">>>>>>>>>>> Médico: " + p_.medic.defaultDescription);
        		console.log(">>>>>>>>>>> Sala: " + ((p_.therapyPlace) ? p_.therapyPlace.defaultDescription : null));
        		console.log(">>>>>>>>>>> title: " + p_.title);
        		console.log(">>>>>>>>>>> start: " + p_.start);
        		console.log(">>>>>>>>>>> end: " + p_.end);
        		console.log(">>>>>>>>>>> allDay: " + p_.allDay);
        		console.log("-----------------------------------------------------------");
        	});
        	
        }

    }
    
})();
