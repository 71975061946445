(function() {
    'use strict';

    angular
        .module('sgmApp')
        .service('UcGridService', UcGridService);

    UcGridService.$inject = ['UcDatasourceService', 'UcAlertService', '$translate', '$ngBootbox', '$rootScope', '$state', '$timeout', 'GridConstants', '$window', 'PhotoManager'];
    
    var AVATAR_GRID_HEIGHT = 60; // Altura para grids com avatar

    function UcGridService (UcDatasourceService, UcAlertService, $translate, $ngBootbox, $rootScope, $state, $timeout, GridConstants, $window, PhotoManager)
    {
    	var vm = this;
    	
    	/*public*/ vm.AVATAR_GRID_HEIGHT = AVATAR_GRID_HEIGHT;
    	
    	vm.create = function(p_gridParams)
    	{
        	var defaultGridParams =
        	{
        		detailsIcon: true,
        		authenticate: true,
        		responsiveHeight: true,
        		startOnLoad: false,
        		onRowClicked: null, // function(p_data)
        		onViewportChanged: null, // function()
        		beforeOnRowClicked: null, // function(p_data)
        		getRowClass: null, // function(p_params),
				fetchResultTransform: null, // function(p_data),
        	};
    		
    		var gridParams = defaultGridParams;
    		angular.extend(gridParams, (p_gridParams) ? p_gridParams : {});

//    		if(gridParams.authenticate && (!gridParams.account || !gridParams.account.login))
//    		{
//    			UcAlertService.message($translate.instant('client.global.messages.error.loggedUserNotFound'), "ERROR", "ALERT");
//    		}
    		
    		if(!gridParams.gridId)
    		{
    			UcAlertService.message($translate.instant('client.global.messages.error.gridIdRequired'), "ERROR", "ALERT");
    		}

   			return new UcGrid(vm, $ngBootbox, $translate, $rootScope, UcDatasourceService, UcAlertService, $state, gridParams, $timeout, GridConstants, $window);
    	};
    	
    	vm.createField = function(p_)
    	{
    		var fieldDefine = {suppressMenu: true};
    		angular.extend(fieldDefine, p_);
    		return fieldDefine
    	};
    	
    	vm.createFieldList = function(p_array)
    	{
    		var gridFieldList = [];
    		if(p_array)
    		{
    			for(var i = 0 ; i < p_array.length ; i++)
    			{
    				gridFieldList.push(vm.createField(p_array[i]));
    			}
    		}
    		return gridFieldList;
    	};
    	
    	// Fields Factory
    	vm.field =
    	{
            ACTIVE: function(p_)
            {
            	var param = {
            		title: "client.global.label.active", 
            		field: "active"
               	};
            	if(p_)
            	{
            		angular.extend(param, p_);
            	}
            	return vm.field.BOOLEAN(param);
            },
            AVATAR: function(p_customRenderer)
            {
            	return {
            		headerName: null, 
            		width: 60,
                    suppressSizeToFit: true,
                    suppressSorting: true,
                    cellStyle: {alignItems: "center", textAlign: "center", padding: "0px", margin: "0px"},
            		cellRenderer: function (p_)
                    {
            			if(!p_.data)
            			{
            				return "";
            			}
            			else
            			{
            				if(p_.data.avatarId == "custom")
            				{
            					return p_customRenderer(p_.data);
            				}
            				else if(p_.data.avatarId)
            				{
            					// próximo ciclo
            					$timeout(function()
            					{ 
            						PhotoManager.loadAvatar(p_.data.avatarId, p_.data.avatarId + "-avatar");
            						createPopover();
            					}, 1);
            				}
            				else
            				{
            					alert("Avatar ID not set for data");
            				}
            				
                			return '<img src="https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/loading.gif" avatar-id="' + p_.data.avatarId + '" class="' + p_.data.avatarId + '-avatar photoPopover img-circle" style="width:35px;height:35px">';
            			}
                    }
            	};
            },
	
            BOOLEAN: function(p_)
            {
            	var param = {
            		title: "-", 
            		field: null, 
            		width: 50,
                    suppressSizeToFit: true,
                    suppressSorting: false,
            		iconArray: ["check.png", "minus.png"],
            		hintArray: ["client.global.label.active", "client.global.label.inactive"]
               	};
            	angular.extend(param, p_);
            	return {
            		headerName: $translate.instant(param.title), 
            		field: param.field, 
            		width: param.width,
                    suppressSizeToFit: param.suppressSizeToFit,
                    suppressSorting: param.suppressSorting,
                    cellStyle: {alignItems: "center", textAlign: "center"},
            		cellRenderer: function (p_)
                    {
            			var icon, hint;
                    	if(p_.value === true || p_.value === "true")
                    	{
                    		icon = param.iconArray[0];
                    		hint = $translate.instant(param.hintArray[0]);
                    	}
                    	else
                    	{
                    		icon = param.iconArray[1];
                    		hint = $translate.instant(param.hintArray[1]);
                    	}
                    	return '<img src="https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/icons/' + icon + '" title="' + hint + '" class="icon-22">';
                    }
            	};
            },

			MONEY: function(p_customRenderer)
            {
				var param = {
					cellRenderer: function (p_)
                    {
            			if(!p_.value)
            			{
            				return "";
            			}
            			else
            			{
            				return "R$ " + $rootScope.formatMoney(p_.value);
            			}
                    }
				}
				angular.extend(param, p_customRenderer);
            	return param;
            },
    	}
    	// Buttons Factory
    	vm.button =
    	{
            ACTION: function(p_)
            {
            	var param = {
            		title: null,
            		btnClass: "btn-primary",
            		action: null, 
            		width: 50, 
            		fontIcon: "fa fa-check",
            		hint: null
               	};
            	angular.extend(param, p_);
            	return {
            		headerName: (!param.title) ? "" : $translate.instant(param.title), 
            		field: param.field, 
            		width: param.width, 
                    suppressSizeToFit: true,
                    suppressMenu: true,
                    suppressSorting: true,
                    cellStyle: {alignItems: "center", textAlign: "center", padding: "0px"},
            		cellRenderer: function (p_)
                    {
            			var hint = (!param.hint) ? "" : $translate.instant(param.hint);
            			return '<a class="btn ' + param.btnClass + '" title="' + hint + '"><i class="' + param.fontIcon + '"></i></a>';
                    }, 
                    onCellClicked: function(p_params)
                    {
                    	if(param.action)
                    	{
                    		param.action(p_params);
                    	}
                    }

            	};
            },
    	};
    	// CellRendereres
    	vm.renderer =
    	{
    	};
    	
    	/**
    	 * Cria um popover por imagem.
    	 * APesar de chamar para todos (classe), controla para não criar repetido 
    	 */
    	/*private*/ function createPopover(p_placement)
    	{
			$(".photoPopover").each(function()
			{
				var popover = $(this); 
				var id = popover.attr("avatar-id");
				
				// Só cria uma vez!
				if(popover.attr("popover-created"))
				{
					return;
				}
				popover.attr("popover-created", true);
				
				popover.popover({
					trigger : "hover",
					html : true,
					container: 'body',
					content: function()
					{
						return "<div class='photo-popover-content " + id + "-popover'><img class='photo-popover-loading' src='https://s3-sa-east-1.amazonaws.com/sgrsis.com/content/images/loading.gif' /></div>";
				    }
				});
				
				popover.on("shown.bs.popover", function()
				{
					var avatarId = $(this).attr("avatar-id");
					PhotoManager.loadImage(avatarId, avatarId + "-popover", "photo-popover");
				});
				popover.on("show.bs.popover", function(e)
				{
					popover.data("bs.popover").tip().addClass("photo-popover-wrapper");
				});
//				popover.on("hide.bs.popover", function()
//				{
//					throw null;
//				});
			});
    		
    	};
    }
})();
