(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('TestController', TestController);

    TestController.$inject = ['$scope', 'TestService'];

    function TestController ($scope, TestService)
    {
        var vm = this;

        /**
         * Lista todos os "agendáveis" (Usuários de perfil médico e Salas de terapia)
         */
        TestService.listSchedulable({}, function(p_list)
        {
        	vm.r1 = p_list;
        });
        
        /**
         * Criar/Atualiza registro
         * 
         * Se passar o id, atualiza. Id nulo, cria
         * 
         * Retorna a lista de ids da mesma ordem do lista passada como parâmetro: se atualizado, retorna o mesmo. Se criado, retorna o novo
         */
//        TestService.refreshAvailabilitySchedule([
//        	{id: null, idSchedulable: "10008", weekDay: 1, startTime: '01:30', endTime: '01:30', obs: "obs1"},
//        	{id: null, idSchedulable: "10008", weekDay: 2, startTime: '17:00', endTime: '18:00', },
//        	{id: null, idSchedulable: "SL1", weekDay: 1, startTime: '06:15', endTime: '22:23', obs: "obs333"}
//        ], 
//        	function(p_idsList)
//        	{
//        		debugger;
//        	});

        
        TestService.listAvailabilitySchedule({id: "10008"}, function(p_)
        {
        	vm.r2 = p_;
        });

        TestService.listAvailabilitySchedule({id: "SL1"}, function(p_)
        {
        	vm.r3 = p_;
            
//        	TestService.refreshAvailabilitySchedule([
//            	{id: p_[0].id, weekDay: 6,  startTime: '11:10', endTime: '11:20', obs: "obs xxx"}
//         	]);
//
//        	TestService.delete({id: p_[0].id});

        });

    }
})();
