(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientManagerTabContactsController', ClientManagerTabContactsController);
    
    ClientManagerTabContactsController.$inject = ['$scope', 'FrontEndSelectDataSource', '$timeout', '$rootScope', 'FormConstants'];
    function ClientManagerTabContactsController($scope, FrontEndSelectDataSource, $timeout, $rootScope, FormConstants)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        formHandler.reopenConfig()
        	.add([new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "Phone",
					required: true,
					id: "cel",
					label: "common.domain.client.cel",
					addonCssClass: "glyphicon glyphicon-phone"
				}),
				new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "String-Group",
					id: "cel2",
					label: "common.domain.client.cel2",
					addonCssClass: "glyphicon glyphicon-phone"
				}),
				new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "Phone",
					id: "whatsapp",
					label: "common.domain.client.whatsapp",
					addonCssClass: "fa fa-whatsapp"
				})				
        	])
        	.add([new FormField({
           			cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "String-Group",
					id: "email",
					label: "common.domain.client.email",
					addonCssClass: "fa fa-envelope-o"
				}),
        		new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "Phone",
					id: "phone",
					label: "common.domain.client.phone"
				}),
				new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "Phone",
					id: "phoneBusiness",
					label: "common.domain.client.phoneBusiness",
					addonCssClass: "fa fa-briefcase"
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "contacts",
					label: "common.domain.client.contactType",
					id: "contactType",
					type: "Select",
					required: false,
					dataSource: FrontEndSelectDataSource.create(injectedResources.contactTypeList)
				}),
				new FormField({
           			cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "String-Group",
					id: "facebook",
					label: "common.domain.client.facebook",
					addonCssClass: "fa fa-facebook"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "String-Group",
					id: "instagram",
					label: "common.domain.client.instagram",
					addonCssClass: "fa fa-instagram"
				})
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "contacts",
					type: "Boolean-InLine",
					id: "notWantInformSocialNetworks",
					label: "common.domain.client.notWantInformSocialNetworks",
					onChange: function()
					{
						synchSocialNetworksFields();
					}
				})
        	])
		.endConfig();
        
        
        /*private*/ function synchSocialNetworksFields()
        {
        	var readOnly = entity.notWantInformSocialNetworks;
			
       		$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly("facebook"), {absolute: true, readOnly: readOnly});
       		$rootScope.$broadcast(FormConstants.Events.Runtime.ReadOnly("instagram"), {absolute: true, readOnly: readOnly});
       		
       		if(readOnly)
       		{				
       			delete entity["facebook"];
       			delete entity["instagram"];
       		}
        }
        
        // Sincronismo inicial
        $timeout(function()
        {
        	synchSocialNetworksFields();
        }, 100);
    };
})();