(function() {
    'use strict';

    angular.module('sgmApp').service('UcAlertService', UcAlertService);

    UcAlertService.$inject = ['$rootScope', '$filter', 'growl', 'growlMessages', '$injector', '$interval'];
    
    /*const*/ var REPEAT_ALERT_INTERVAL_MILLIS = 1 * 1000; // 1 segundo 
    
    function UcAlertService($rootScope, $filter, growl, growlMessages, $injector, $interval)
    {
    	var repeatedAlertHandler = new RepeatedAlertHandler($interval);
    	
    	var forceAlert = false;
    	
    	/**
    	 * Utilizar este método nos controladores para envio de mensagem direta
    	 */
    	/*public*/ this.message = function(p_message, p_level, p_type)
    	{
    		var mockResponse = {
    			explicitLevel: p_level,
    			explicitType: p_type
    		};
    		var alertObj = new UcAlert(mockResponse, p_message, false);
    		show(alertObj);
    	};

    	/**
    	 * Utilizar este método nos controladores para envio erro direto
    	 */
    	/*public*/ this.error = function(p_message)
    	{
    		this.message(p_message, "ERROR", "ALERT");
    	};
    	/*public*/ this.warning = function(p_message)
    	{
    		this.message(p_message, "WARNING", "GROWL");
    	};

    	/*public*/ this.success = function(p_message)
    	{
    		this.message(p_message, "SUCCESS", "GROWL");
    	};
    	/*public*/ this.info = function(p_message)
    	{
    		this.message(p_message, "INFO", "ALERT");
    	};

        /*public*/ this.dismissBox = function()
        {
        	$rootScope.$broadcast('sgmApp.ucAlert.dismissBox');
        };

    	/**
    	 * Remove todos os alertas
    	 */
        /*public*/ this.dismissAll = function()
        {
        	// Growl
        	growlMessages.destroyAllMessages();
        	
        	// BOX
        	this.dismissBox();
        };
    	
    	/*public*/ this.processError = function(p_response)
    	{
			console.log(p_response);
        	switch (p_response.status)
        	{
        	// connection refused, server not reachable
	        		
	            case -1:
	            	addErrorAlert(p_response, 'Connection error','error.connection.error');
	            	break;
	            case 0:
	            	addErrorAlert(p_response, 'Server not reachable','error.server.not.reachable');
	            	break;

            	case 400:
            		var errorHeader = p_response.headers('X-sgmApp-error');
            		var entityKey = p_response.headers('X-sgmApp-params');
            		if (errorHeader)
            		{
            			var entityName = $translate.instant('client.global.menu.entities.' + entityKey);
            			addErrorAlert(p_response, errorHeader, errorHeader, {entityName: entityName});
            		}
            		else if (p_response.data && p_response.data.fieldErrors)
            		{
            			try
            			{
	            			for (var i = 0; i < p_response.data.fieldErrors.length; i++)
	            			{
	            				var fieldError = p_response.data.fieldErrors[i];
		            			
	            				// convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
	            				var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
	            				var fieldName = fieldError.objectName + '.' + convertedField;
	            				try
	            				{
	            					fieldName = $translate.instant('sgmApp.' + fieldError.objectName + '.' + convertedField);
	            				}
	            				catch(ee){/* silent*/};
	            				addErrorAlert(p_response, 'Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {fieldName: fieldName});
	            			}
            			}
            			// Fallback: mostra o erro todo no alerta default do navegador
            			catch(ee)
            			{
            				alert(JSON.stringify(p_response.data));
            			}
            		}
            		else if (p_response.data && p_response.data.message)
            		{
            			addErrorAlert(p_response, p_response.data.message, p_response.data.message, p_response.data);
            		}
            		else
            		{
            			addErrorAlert(p_response, p_response.data);
            		}
            		break;

            	case 404:
            		addErrorAlert(p_response, 'Not found','error.url.not.found');
            		break;

            	default:
            		var errMsg = "Erro 500!";
            		if(p_response.status)
            		{
            			errMsg = "Erro " + p_response.status;
            			if(p_response.statusText)
            			{
            				errMsg += " - " + p_response.statusText;
            			}
            		}

            		if(p_response.data && p_response.data.message)
            		{
            			errMsg = p_response.data.message;
            		}
            		else if(p_response.message)
            		{
            			errMsg = p_response.message;
            		}
           			addErrorAlert(p_response, errMsg);
            		break;
            }
    	};
    	
    	/*public*/ this.showAlert = function(p_response, p_message)
    	{
    		addAlert(p_response, p_message, null, null, false);
    	};

    	
    	/**
    	 * Utilize este método nos modais onde o AlertBox ficar debaixo e não for possível lê-lo
    	 */
    	/*public*/ this.setForceAlertType = function($currentControllerScope)
    	{
    		forceAlert = true;
    		
    		if($currentControllerScope)
    		{
    			$currentControllerScope.$on('$destroy', function()
		    	{
    				forceAlert = false;
		    	});
    		}
    	};

    	
        /*private*/ function addErrorAlert(p_response, p_message, p_key, p_data)
        {
        	addAlert(p_response, p_message, p_key, p_data, true);
        };
    	
    	/*private*/ function addAlert(p_response, p_message, p_key, p_data, p_isErrorMessage)
    	{
    		// Tipo da mensagem: BOX, GROWL, ALERT ou TOAST. Default ALERT
    		var alertObj = new UcAlert(p_response, parseMessage(p_message, p_key, p_data), p_isErrorMessage);
    		show(alertObj);
    	}
    	
    	/*private*/ function show(p_alertObj)
    	{
    		try
    		{
    			// Verifica se foi enviado uma mensagem igual dentro da tolerânecia de tempo
    			var uniqueAlertIdentifier = repeatedAlertHandler.createUniqueAlertIdentifier(p_alertObj); // zzz
    			if(repeatedAlertHandler.isRepeated(uniqueAlertIdentifier))
    			{
    				// Se for repetida, não envia e loga
    				console.log("Mensagem descartada: " + uniqueAlertIdentifier);
    				return;
    			}
    			
    			// Adiciona o alerta para ser monitorado
    			repeatedAlertHandler.register(uniqueAlertIdentifier);
    			
	    		// Se for do tipo que precisa de diretiva injetada
	    		if((p_alertObj.alertType == "BOX" || p_alertObj.alertType == "TOAST") && !forceAlert)
	    		{
	    			// Tenta enviar pela diretiva injetada, caso exista alguma
	    			if(tryApplyOnDirective(p_alertObj))
	    			{
	    				// Ok! Finaliza o fluxo
	    				debug("Enviado via diretiva");
	    				return;
	    			}
	    			else
	    			{
	    				// Não há diretiva enviada, muda o tipo p fallback. Neste caso, GROWL
	    				p_alertObj.alertType = "GROWL";
	    				debug("Nenhuma diretiva encontrada");
	    			}
	    		}
	    		if(p_alertObj.alertType == "GROWL" && !forceAlert)
	    		{
	    			showGrowlMessage(p_alertObj);
	    		}
	    		else // ALERT
	    		{
	    			showAlertMessage(p_alertObj);
	    		}
    		}
    		catch(ee) // Se der errado, mostra com o alert default 
    		{
    			alert(removeHTML(p_alertObj.msg));
    		}
        };
        
        function tryApplyOnDirective(p_alertObj)
        {
        	$rootScope.$broadcast('sgmApp.ucAlert', p_alertObj);
        	debug("Passou por " + p_alertObj.registerOnDirectiveCount + " diretivas")
        	return (p_alertObj.registerOnDirectiveCount > 0);
        };

        function parseMessage(p_message, p_key, p_data)
        {
        	// Prefrência pela chave
        	if(p_key)
        	{
        		var temp = $filter('translate')(p_key, p_data);
        		if(temp == p_key)
        		{
        			// Tradução falhou. Reroena a p_message, caso exista
        			return (p_message) ? p_message : p_key;
        		}
        		else
        		{
        			return temp;
        		}
        	}
        	else
        	{
        		return p_message;
        	}
        };
        
        function showGrowlMessage(p_alertObj)
        {
        	var msg = p_alertObj.msg;
        	if(p_alertObj.alertLevel.id == "ERROR")
        	{
        		growl.error(msg);
        	}
        	else if(p_alertObj.alertLevel.id == "WARNING")
        	{
        		growl.warning(msg);
        	}
        	else if(p_alertObj.alertLevel.id == "SUCCESS")
        	{
        		growl.success(msg);
        	}
        	else // INFO e fallback
        	{
        		growl.info(msg);
        	}
        };
        
        function showAlertMessage(p_alertObj)
        {
        	var options =
        	{
        		title: '<i class="' + p_alertObj.alertLevel.cssIcon + '" aria-hidden="true"></i>&nbsp;' + p_alertObj.alertLevel.alertTitle,
        		className: p_alertObj.alertLevel.cssClass + ' z-index-2200',
        		message: p_alertObj.msg,
        		buttons:
        		{
        			dashboardButton:
        			{
        				label: "OK",
        				className: p_alertObj.alertLevel.buttonCssClass
        			}
        		}
        	};
        	$injector.get("$ngBootbox").customDialog(options);
        };
    }
    
    /**
     * Classe para gerenciar e impedir a exibição de alertas repetidos em um intervalo determinado de tempo
     * @returns
     */
    /*private*/ /*class*/ function RepeatedAlertHandler($interval)
    {
    	debug("RepeatedAlertHandler created");

    	/*private*/ var map = {};
    	
    	/**
    	 * Cria o identificador único para a mensagem
    	 */
    	/*public*/ this.createUniqueAlertIdentifier = function(p_alertObj)
    	{
    		try
    		{
    			var alertLevel = "";
    			if(p_alertObj.alertLevel)
    			{
    				alertLevel = p_alertObj.alertLevel.id;
    			}
    			return p_alertObj.alertType + ";" + alertLevel + ";" + p_alertObj.msg;
    		}
    		catch(ee)
    		{
    			console.error("Error at createUniqueAlertIdentifier: " + ee.message)
    		}
    	}
    	
    	/*public*/ this.isRepeated = function(p_uniqueId)
    	{
    		return (p_uniqueId != null && map[p_uniqueId]);
    	}
    	
    	/**
    	 * Registra o alerta pelo id único
    	 */
    	/*public*/ this.register = function(p_uniqueId)
    	{
    		if(p_uniqueId != null)
    		{
    			// Captura o timestamp
    			map[p_uniqueId] = (new Date()).getTime();
    		}
    	}

    	/**
    	 * Timer que executa a cada 1 segundo.
    	 * Verifica se a mensagem registrada já expirou. Se sim, a exclui
    	 */
    	$interval(function()
    	{
    		var now = (new Date()).getTime();
    		
    		// Pega todas as chaves do mapa (que são os ids)
    		var keysList = Object.keys(map);
    		var key, timestamp, delta;
    		for (var i = 0; i < keysList.length; i++)
    		{
				key = keysList[i];
				timestamp = map[key];
				timestamp = (!timestamp) ? 0 : timestamp;
				
				delta = now - timestamp;
				debug("Alert check: " + key + " - " + delta);
				
				// Expirou?
				if(delta > REPEAT_ALERT_INTERVAL_MILLIS)
				{
					debug("Alert expired: " + key);
					delete map[key];
				}
			}
    	}, 1 * 1000);  // 1 segundo 
    }
})();
