(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ClientManagerTabIndicationController', ClientManagerTabIndicationController);

	ClientManagerTabIndicationController.$inject = ['$scope', '$timeout', 'Client','$state'];
	function ClientManagerTabIndicationController($scope, $timeout, Client,$state) {

		var vm = $scope.vm;
		var entity = $scope.vm.domain;
		var formHandler = vm.formHandler;

		if (entity.indicationsManager) {

			entity.indicationsManagerName = entity.indicationsManager.indicatorName;
			entity.originIndications = entity.indicationsManager.indication.description;
			entity.indicationsCategory = entity.indicationsManager.indication.indicationType.description;
			entity.indicationsManagerId = entity.indicationsManager.id;
			entity.indicationsManager.indicationTypeDescription = entity.indicationsManager.indication.indicationType.description;
			entity.indicationsManager.indicationDescription = entity.indicationsManager.indication.description;
			entity.indicationsManager = [entity.indicationsManager];
			// // Busca os dados do cliente para obter a catedoria de indicações que não vem na consulta antiga
			// Client.findWithAge({ id: entity.id }).$promise.then(function(data) {
			// 	entity.indicationsCategory = data.indicationsManager.indications.indicationsCategory.defaultDescription;
			// });

		}

		formHandler.reopenConfig()
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					formTarget: "clientIndication",
					type: "SelectModalIndicationsManager",// componente está em app/components/search-modal-indications-manager/
					id: "indicationsManager",
					label: "common.domain.client.indicationTypeSelect",
					required: true,
					multiple: false,// Não pode ser true nesse modal
					readOnly:$state.current.name=='client.detail'?true:false,
					modalConfig: {
						bindLabel: "name",
						searchId: "IndicationsManager",
						onSelect: function(data) {
							// Atualiza os dados do parceiro quando o usuário escolhe um novo Parceiro
							entity.indicationsManagerName = data.indicatorName;
							entity.originIndications = data.indicationDescription;
							entity.indicationsCategory = data.indicationTypeDescription;
							entity.indicationsManagerId = data.id;
						}
					}
				})
			])
			.add(
				[new FormField({
					formTarget: "clientIndication",
					cellCssClass: "col-sm-4",
					type: "String",
                    id: "indicationsManagerName",
					label: "common.domain.client.indicationName",
					readOnly: true
				}),
				new FormField({
					formTarget: "clientIndication",
					cellCssClass: "col-sm-4",
					type: "String",
					id: "indicationsCategory",
					label: "common.domain.indicationsManager.indicationTypeDescription",
					readOnly: true
				}),
				new FormField({
					formTarget: "clientIndication",
					cellCssClass: "col-sm-4",
					type: "String",
					id: "originIndications",
					label: "common.domain.indicationsManager.indicationDescription",
					readOnly: true
				}),

				])

			.endConfig();


        /*public*/ vm.openClientModal = function() {
			ClientManagerModalInvoker.invoke(new ClientManagerModalInvokerParam({
				clientId: clientData.id,
				account: vm.account,
				pageMode: "READONLY",
				callback: function() {
					vm.syncClient(clientData.id);
				}
			}));
		}


		// Sincronismo inicial
		$timeout(function() {
			//			synchIndicationsFields();
		}, 100);
	};
})();
