(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('IndicationsManagerDomainController', IndicationsManagerDomainController);

	IndicationsManagerDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'pageConfig', '$stateParams', 'indicationsList','UcDatasourceService','Client'];

	function IndicationsManagerDomainController(FormDomain, $scope, $state, entity, pageConfig, isToShowRecordsButton, indicationsList,UcDatasourceService,Client) {
		var vm = this;

		vm.domain = entity;
		vm.pageConfig = pageConfig;
		vm.injectedResources = {
			indicationsList: indicationsList,
			UcDatasourceService:UcDatasourceService	,
			Client:Client	
		};

	};
})();
