(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('RecordsTemplate', RecordsTemplate);

    RecordsTemplate.$inject = ['$resource', 'URL', 'SERVICES'];

    function RecordsTemplate ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url +'api/records-template/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getComplete': { method: 'GET', url: url +'api/records-template/get-complete/:id'},
            'update': { method:'PUT' },
        	'listRecordsTypes': { method: 'GET', url: url +'api/records-template/list-types', isArray: true},
        	'getTree': { method: 'GET', url: url +'api/records-template/get-tree/:recordType'}
        	
        });
    }
})();
