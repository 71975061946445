(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'UcAlertService'];

    function notificationInterceptor ($q, UcAlertService)
    {
        var service = {
            response: response
        };

        return service;

        function response (response)
        {
            var alertKey = response.headers('X-sgmApp-alert');
            if (angular.isString(alertKey))
            {
            	UcAlertService.showAlert(response, alertKey);
            }
            return response;
        }
    }
})();
