(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('TimeFormFieldDirectiveController', TimeFormFieldDirectiveController);
    
    TimeFormFieldDirectiveController.$inject = ['$scope', '$translate', '$timeout', '$rootScope', 'FormConstants'];
    
    /**
     * Valor esperado é uma STRING no formato HH:MM
     * Ex.: 12:00, 17:15, 22:12, 01:56, etc
     */
    function TimeFormFieldDirectiveController($scope, $translate, $timeout, $rootScope, FormConstants)
    {
    	var vm = this;
    	
    	vm.objModel = $scope.objModel;
    	vm.fieldModel = $scope.fieldModel;
    	vm.editMode = $scope.editMode;
    	
    	vm.readOnly = (vm.fieldModel.readOnly === true || vm.editMode !== true);

    	// Se a hora estiver setada, faz o ajuste para ser reconhecida pelo input
		if(vm.objModel[vm.fieldModel.id])
		{
			vm.objModel[vm.fieldModel.id] = DateTimeUtil.initTime(vm.objModel[vm.fieldModel.id])
        }
		
		$timeout(function()
		{
			processModelValue();
		}, 1)
		
    	/**
    	 * Resolve a classe para o input
    	 */
    	/*public*/ vm.resolveClass = function()
    	{
    		var cssClass = "form-control";
    		
    		return cssClass; 
    	};

		/**
		 * Classe para o group-date
		 */
		/*public*/ vm.resolveDateGroupClass = function()
		{
			var cssClass = "inner-addon right-addon"
			return cssClass;
		};
		
		/*public*/ vm.onChange = function()
		{
			processModelValue();

			if(vm.fieldModel.onChange)
			{
				vm.fieldModel.onChange(vm.objModel[vm.fieldModel.id]);
			}
			
    		// Se tiver setado o listener checkFormChange
    		if(vm.fieldModel.checkFormChange)
    		{
    			vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
    		}
		};
		
		/*public*/ vm.setTimeNow = function()
		{
			if(!vm.readOnly)
			{
				var now = new Date();
				vm.objModel[vm.fieldModel.id] = DateTimeUtil.extractTime(now);
				vm.onChange();
			}
		};
		
		/**
		 * Valida o valor digitado e ajusta o componente
		 */
		/*private*/ function processModelValue()
		{
			var fontColor;
			if(!isValidTime())
			{
				fontColor = "#FF4444";
				$scope.form[vm.fieldModel.id].$error.datetime = true;
			}
			else
			{
				fontColor = "inherit";
				$scope.form[vm.fieldModel.id].$error.datetime = false;
			}
			$("#" + vm.fieldModel.id).css({color: fontColor});
		}

		/**
		 * Checa se a data é válida
		 */
		/*private*/ function isValidTime()
		{
			var val = vm.objModel[vm.fieldModel.id];
			if(val === null || val === "")
			{
				return true;
			}
			else
			{
				return DateTimeUtil.isValidTime(val);
			}
		};
		
    	// Evento runtime ReadOnly
    	vm.currentReadOnly = vm.readOnly
    	var readOnlyListener = $rootScope.$on(FormConstants.Events.Runtime.ReadOnly(vm.fieldModel.id), function(p_event, p_readOnlyParam)
    	{
    		if(p_readOnlyParam.absolute)
    		{
    			vm.readOnly = p_readOnlyParam.readOnly;
    		}
    		else
    		{
    			vm.readOnly = (p_readOnlyParam.readOnly) ? true : vm.currentReadOnly;
    		}
    	});
    	
    	// Destroy os listeners
        $scope.$on('$destroy', function()
        {
            if(angular.isDefined(readOnlyListener) && readOnlyListener !== null)
            {
            	readOnlyListener();
            }
        });
    };
})();