(function() {
    'use strict';

	angular.module('sgmApp').service('FileUploadModalInvoker', FileUploadModalInvoker);
	
	FileUploadModalInvoker.$inject = ['$uibModal'];
	
	function FileUploadModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(/*FileUploadModalInvokerParam*/ p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/modal/file-upload/file-upload-modal-dialog.html',
                controller: 'FileUploadModalController',
                controllerAs: 'vm',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "FILTER",
    	                	formValidateTarget: 'SelectFileUpload'
                    	})
                    }],
                    FileUploadParam: function()
                    {
                    	return p_param;
                    }
                }
            }).result.then(function()
            {
            	if(p_param.okCallback)
            	{
            		p_param.okCallback();
            	}
            }, function()
            {
            	if(p_param.cancelCallback)
            	{
            		p_param.cancelCallback();
            	}
            });
		};
		
	};

})();
function FileUploadModalInvokerParam(p_param)
{
	var _DEFAULT_PARAM =
	{
		addExtraFormParam: null,
		okCallback: null,
		uploadUrl: "define/upload/url"
	};

	// Carrega os valores default
	angular.extend(this, _DEFAULT_PARAM);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_param) ? p_param : {});
};

