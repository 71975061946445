(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('FormFieldDirectiveController', FormFieldDirectiveController);

	FormFieldDirectiveController.$inject = ['$scope', '$translate', 'UcAlertService', '$timeout', '$uibModal', '$rootScope', 'FormConstants', 'CepSearchEngine'];
	function FormFieldDirectiveController($scope, $translate, UcAlertService, $timeout, $uibModal, $rootScope, FormConstants, CepSearchEngine) {
		var vm = this;

		vm.objModel = $scope.objModel;
		vm.fieldModel = $scope.fieldModel;
		var pageMode = $scope.pageConfig.pageMode;
		vm.inputId = vm.fieldModel.inputId || vm.fieldModel.id;
		vm.editMode = (pageMode !== 'READONLY');
		
		// Erros dos Tipos que têm diretiva própria, não devem ser tratados aqui 
		vm.avoidCommonValidation = (vm.fieldModel.type == "Date" || vm.fieldModel.type == "Time" || vm.fieldModel.type == "DateTime");

		vm.placeHolder = vm.editMode ? vm.fieldModel.placeholder : "";

		// MaxLength
		if (vm.fieldModel.maxLength) {
			$timeout(function() {
				$("#" + vm.fieldModel.id).attr("maxlength", vm.fieldModel.maxLength);
			}, 1);
		}

		if (vm.fieldModel.maxValue) {
			$timeout(function() {
				$("#" + vm.fieldModel.id).attr("max", vm.fieldModel.maxValue);
			}, 1);
		}

		if (vm.fieldModel.minValue) {
			$timeout(function() {
				$("#" + vm.fieldModel.id).attr("min", vm.fieldModel.minValue);
			}, 1);
		}


    	/**
    	 * Trata o evento de reset do formulário
    	 */
		var formResetTaskList = [];
		var formResetListener = $rootScope.$on('form.reset.' + vm.fieldModel.formInternalId, function() {
			for (var i = 0; i < formResetTaskList.length; i++) {
				formResetTaskList[i]();
			}
		});

    	/**
    	 * Gerencia os listener para serem destruidos no $destroy
    	 */
		var listenerList = [];
    	/*private*/ function destroyListener() {
			for (var i = 0; i < listenerList.length; i++) {
				listenerList[i]();
			}
		}

		
		$scope.$on('$destroy', function() {
			destroyListener();
			formResetListener();
		});


		if (vm.fieldModel.type == 'Phone') {
			if (!vm.fieldModel.addonCssClass) // Se não tiver ícone, vai o default
			{
				vm.fieldModel.addonCssClass = "glyphicon glyphicon-earphone";
			}
		}

    	/**
    	 * Função para decidir se o componente deve ou não ser adicionado ao DOM (ng-if)
    	 * 
    	 * Redundante com FormDynamicDirective
    	 */
    	/*public*/ vm.addField = function() {
			// Verifica se tem um listenter definido
			if (vm.fieldModel.addFieldListener) {
				// Ele que decidi
				return vm.fieldModel.addFieldListener(vm.objModel);
			}
			else // default é adicionar
			{
				return true;
			}
		};

    	/**
    	 * Resolve a classe para o input
    	 */
    	/*public*/ vm.resolveClass = function() {
			var cssClass = "form-control";

			// Se for SelectModal e não readOnly, adiciona o cursor-pointer
			if (vm.fieldModel.type === "SelectModal" && !vm.fieldModel.readOnly) {
				cssClass += " cursor-pointer";
			}

			if (vm.fieldModel.type === "SelectModalIndicationsManager" && !vm.fieldModel.readOnly) {
				cssClass += " cursor-pointer";
			}

			if (vm.fieldModel.extraCssClass) {
				cssClass += " " + vm.fieldModel.extraCssClass;
			}

			return cssClass;
		};

    	/*public*/ vm.resolveLabelClass = function() {
			return (vm.fieldModel.required) ? "required_field" : "";
		};

    	/*public*/ vm.onChange = function() {
			// Tem onChange setado?
			if (vm.fieldModel.onChange) {
				vm.fieldModel.onChange(vm.objModel);
			}
		};

    	/*public*/ vm.isReadOnly = function() {
			return vm.fieldModel.readOnly;
		};

    	/*public*/ vm.onInputChange = function() {
			// Se tiver setado o onChange, chama
			if(vm.fieldModel.type === 'Double' && vm.fieldModel.precision) {
				var currentValue = (vm.objModel) ? vm.objModel[vm.fieldModel.id] : null;
				if(currentValue) {
					var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (vm.fieldModel.precision) + '})?');
					currentValue = (currentValue+"").match(re)[0];
					vm.objModel[vm.fieldModel.id] = parseFloat(currentValue);
				}
			}
			if (vm.fieldModel.onChange) {
				var currentValue = (vm.objModel) ? vm.objModel[vm.fieldModel.id] : null;
				currentValue = (currentValue) ? currentValue : null; // Remove undefined

				vm.fieldModel.onChange(vm.objModel, vm.fieldModel, currentValue);
			}

			if (vm.fieldModel.checkFormChange) {
				vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
			}
			
		};

		/*public*/ vm.onInputClick = function() {
			if (vm.fieldModel.onClick) {
				var currentValue = (vm.objModel) ? vm.objModel[vm.fieldModel.id] : null;
				currentValue = (currentValue) ? currentValue : null; // Remove undefined

				vm.fieldModel.onClick(vm.objModel, vm.fieldModel, currentValue);
			}
		}

    	/**
    	 * Se ReadOnly, seta o title do input caso se aplique
    	 */
    	/*private*/ function adjustReadOnlyField() {
			if (vm.fieldModel['type'] === 'String' || vm.fieldModel.type == 'String-Group' || vm.fieldModel['type'] === 'TextArea') {
				$timeout(function() {
					try {
						if (vm.fieldModel.readOnly === true || vm.editMode !== true) {
							$("#" + vm.fieldModel.id).attr("title", vm.objModel[vm.fieldModel.id]);
						}
						else if (vm.fieldModel.readOnly === true || vm.editMode !== true) {
							$("#" + vm.fieldModel.id).removeAttr("title");
						}

					}
					catch (exc) {
						console.error("Error at form-field readOnly set title: " + exc.message);
					}
				}, 300);
			}
		};
		adjustReadOnlyField();


    	/**
    	 * ReadOnly para alguns tipos - implementar sobre demanda
    	 * 
    	 * p_readOnlyParam = {
    	 * 		absolute: true, // Só se aplica oara os tipo de data: datetime-form-field, date-form-field e time-form-field  
    	 * 		readOnly: readonly // Campo que é utilizado nesse arquivo
    	 *  }
    	 *  
    	 *  ATENÇÃO: 
    	 *  Para os tipo abaixo, o listener são criados dentro do seus arquivos:
    	 *     - datetime-form-field
    	 *     - date-form-field
    	 *     - time-form-field 
    	 */
		if (vm.fieldModel.type == 'Select' || vm.fieldModel.type == 'String-Group') {
			listenerList.push($rootScope.$on(FormConstants.Events.Runtime.ReadOnly(vm.fieldModel.id), function(p_event, p_readOnlyParam) {
				vm.fieldModel.readOnly = (p_readOnlyParam && p_readOnlyParam.readOnly);
				adjustReadOnlyField();
			}));
		}

    	/*********************************************************
    	 * ****   NumberRaw
    	 * ****
    	 * ****   Numéros sem máscara
    	 ****************************************************** */

    	/*********************************************************
    	 * ****   Boolean (Checkbox)
    	 * ****
    	 ****************************************************** */

    	/*public*/ vm.booleanClick = function() {
			// Se tiver setado o onChange, chama
			if (vm.fieldModel.onChange) {
				var currentValue = (vm.objModel) ? vm.objModel[vm.fieldModel.id] : null;
				currentValue = (currentValue) ? currentValue : null; // Remove undefined

				vm.fieldModel.onChange(vm.objModel, vm.fieldModel, currentValue);
			}

    		/*
    		 * TODO: Test on demand
    		 */
			// Se tiver setado o listener checkFormChange
			if (vm.fieldModel.checkFormChange) {
				vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
			}

		}

    	/*********************************************************
    	 * ****   Percentage
    	 * ****
    	 * ****   
    	 ****************************************************** */
    	/*
    	 * Workaround para porcentagens negativas
    	 */
    	/*public*/ vm.formatPercentageReadOnly = function() {
			if (!vm.objModel[vm.fieldModel.id] && vm.objModel[vm.fieldModel.id] !== 0) {
				return "";
			}
			else {
				return $rootScope.formatNumber(vm.objModel[vm.fieldModel.id] * 100, 2) + "%";
			}
		}

    	/*********************************************************
    	 * ****   DATE, TIME e DATETIME
    	 * ****
    	 * ****   Tratados em diretivas próprias 
    	 ****************************************************** */

    	/* void */

    	/*********************************************************
    	 * ****   ZipCode
    	 ****************************************************** */
    	/*public*/ vm.doSearchEngine = function() {
			if (!vm.fieldModel["searchEngine"].handleResult) {
				UcAlertService.error("No handleResult for SearchEngine for formField [" + vm.fieldModel.id + "]");
				return;
			}

			var zipCode = vm.objModel[vm.fieldModel.id];
			CepSearchEngine.search({ value: zipCode }, function(p_data) {
				vm.fieldModel["searchEngine"].handleResult(p_data);
			});
		};

		vm.fieldModel['doSearchEngine'] = vm.doSearchEngine;
    	/*********************************************************
    	 * ****   ui.utils.masks
    	 ****************************************************** */

    	/*public*/ vm.calculateUiMaskErrorList = function(p_form) {
			var errorList = {};

			var field = p_form[vm.fieldModel.id];
			if (field) {
				// CPF
				if (vm.fieldModel.type === "CPF") {
					// Validação de CPF
					if (field.$error && field.$error.cpf) {
						errorList["invalid_cpf"] = $translate.instant("client.global.messages.error.invalidCpf");
					}
					else // Demais erros
					{
						if (Object.keys(field.$error).length > 0) {
							angular.extend(errorList, field.ucErrorList);
						}
					}
				}
				// CNPJ
				if (vm.fieldModel.type === "Cnpj") {
					// Validação de CNPJ
					if (field.$error && field.$error.cnpj) {
						errorList["invalid_cnpj"] = $translate.instant("client.global.messages.error.invalidCnpj");
					}
					else // Demais erros
					{
						if (Object.keys(field.$error).length > 0) {
							angular.extend(errorList, field.ucErrorList);
						}
					}
				}
				if (vm.fieldModel.type === "CnpjCpf") {
					// Validação de CNPJ
					if (field.$error) {
						if (field.$error.cpf) {
							errorList["invalid_cpf"] = $translate.instant("client.global.messages.error.invalidCpf");
						}
						if (field.$error.cnpj) {
							errorList["invalid_cnpj"] = $translate.instant("client.global.messages.error.invalidCnpj");
						}
					}
					else // Demais erros
					{
						//	    				if(Object.keys(field.$error).length > 0)
						//	    				{
						//	    					angular.extend(errorList, field.ucErrorList);
						//	    				}
					}
				}
				// Phone
				if (vm.fieldModel.type === "Phone") {
					// Validação de Fone
					if (field.$error && field.$error.brPhoneNumber) {
						errorList["invalid_brPhoneNumber"] = $translate.instant("client.global.messages.error.invalidBrPhoneNumber");
					}
					else // Demais erros
					{
						if (Object.keys(field.$error).length > 0) {
							angular.extend(errorList, field.ucErrorList);
						}
					}
				}
				else // Demais campos
				{
					if (Object.keys(field.$error).length > 0) {
						angular.extend(errorList, field.ucErrorList);
					}
				}
			}
			return errorList;
		}

    	/*********************************************************
    	 * ****   SELECT
    	 ****************************************************** */
		if (vm.fieldModel.type == 'Select') {
			// Default style
			if (!vm.fieldModel['style']) {
				vm.fieldModel['style'] = "min-width:280px; width:100%;";
			}

			// Para o dropdown-multi-select
			if (vm.fieldModel['multiple'] === true) {
				// Inicializa o model com array, caso não esteja inciializado
				if (!vm.objModel[vm.fieldModel.id]) {
					vm.objModel[vm.fieldModel.id] = [];
				}

				vm.dropdownMultiSelectTranslationTexts = getStaticMap('DropdownMultiSelectTranslationTexts', function() {
					return {
						buttonDefaultText: $translate.instant("client.global.label.select"),
						checkAll: $translate.instant("client.global.label.all"),
						uncheckAll: $translate.instant("client.global.label.none"),
						searchPlaceholder: $translate.instant("client.global.label.search")
					}
				});
				vm.dropdownMultiSelectSettings = {
					displayProp: vm.fieldModel.displayProp ? vm.fieldModel.displayProp : "defaultDescription",
					idProperty: "id",
					enableSearch: true,
					smartButtonMaxItems: 10000,
					buttonClasses: "form-control",
					styleActive: true,
					keyboardControls: true
				};
				//var oldText = "";
				//var binded = false;
				vm.dropdownMultiSelectEvents =
				{
					/*onInitDone: function() {

						console.log($scope);
						console.log($("#field-" + vm.fieldModel.id));
						var field = $("#field-" + vm.fieldModel.id).find(".multiselect-parent");
						console.log(field);
						field.on("DOMSubtreeModified", function() {
							var searchField = field.find(".searchField");
							console.log(binded);
							if(searchField && !binded) {
									searchField.on('input', function($event) {
										binded = true;
										console.log(this.value);
										oldText = this.value;
										field.off("DOMSubtreeModified");
									});
							}
						});
					},*/
					onSelectionChanged: function() {
						vm.onSelectChange(vm.objModel[vm.fieldModel.id]);
					}
				};
			}

			// DEVE NECESSARIAMENTE ter um DataSource
			if (!vm.fieldModel.dataSource) {
				UcAlertService.error("No DataSource set for formField [" + vm.fieldModel.id + "]");
				return;
			}
			else {
				vm.fieldModel.dataSource.init(!!vm.fieldModel['multiple'], vm.fieldModel, vm.objModel);
			}

	    	/*public*/ vm.onSelectChange = function($item) {
				vm.fieldModel.dataSource.onSelect($item, vm.objModel, vm.fieldModel.id);

				// Se tiver setado o onChange, chama
				if (vm.fieldModel.onChange) {
					var currentValue = (vm.objModel) ? vm.objModel[vm.fieldModel.id] : null;
					currentValue = (currentValue) ? currentValue : null; // Remove undefined
					vm.fieldModel.onChange(vm.objModel, vm.fieldModel, currentValue);
				}

				// Se tiver setado o listener checkFormChange
				if (vm.fieldModel.checkFormChange) {
					vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
				}
			};

	    	/**
	    	 * Deve ser chamado no reset
	    	 */
			formResetTaskList.push(function() {
				vm.onSelectChange(null);
			});

		}

    	/*********************************************************
    	 * ****   SELECT MODEL
    	 ****************************************************** */

		if (vm.fieldModel.type == 'SelectModal') {
			var modalConfig = vm.fieldModel.modalConfig;
			if (modalConfig == null) {
				UcAlertService.error("No ModalConfig set for formField [" + vm.fieldModel.id + "]");
				return;
			}
			
			$timeout(function() {
				if(modalConfig.currentValue) {
					vm.objModel[vm.fieldModel.id] = modalConfig.currentValue;
				}
				selectModalRefreshValue(false);
			}, 1);


//    		$timeout(function(){
//    			vm.selectModalClick();
//    			modalConfig.extraButtons[0].callback();
//    		}, 500);

	    	/*public*/ vm.selectModalClick = function() {
				if (vm.fieldModel.readOnly !== true && vm.editMode) {
					// Passa configuração para o modal via escopo
					$scope.searchModalConfig =
					{
						
						gridId: modalConfig.gridId, //id do datasource que será utilizado
						/* Atributos para form de pesquisa */
						formHandler: modalConfig.formHandler, //form handler do campo de pesquisa
						sort: modalConfig.sort, //qual o sort inicial 
						/* ---------------------------------- */
						fieldList: modalConfig.fieldList, //colunas da entidade que estarao visíveis
						searchId: modalConfig.searchId,
						extraButtons: modalConfig.extraButtons,
						bindLabel: modalConfig.bindLabel, //qual campo da entidade estará visível ao selecionar no modal
						searchKey: modalConfig.searchKey, //se não tiver formhandler, usa a key para pesquisar o campo. Default: search
						transformOptionsList: modalConfig.transformOptionsList, //função para alterar a lista de opção e deve retornar a lista formatada
						multiple: vm.fieldModel.multiple,
						criteria: vm.fieldModel.criteria, //criteria
						currentValue: vm.objModel[vm.fieldModel.id]
					};

					$uibModal.open({
						templateUrl: 'app/components/search-modal/search-modal-dialog.html',
						controller: 'SearchModalController',
						controllerAs: 'vm',
						scope: $scope,
						size: 'lg',
						windowClass: 'modal-dialog-90-percent',
						resolve: {
							account: ['Principal', function(Principal) {
								return Principal.identity();
							}],
							searchModalConfig: () => $scope.searchModalConfig
						}
					}).result.then(function(p_selectedList) {
						vm.objModel[vm.fieldModel.id] = p_selectedList;
						selectModalRefreshValue(true);
					}, function() {
						/*void*/
					});
				}
			};

	    	/*public*/ vm.selectModalTitle = function() {
				if (!vm.fieldModel["readOnly"]) {
					return $translate.instant("client.global.label.clickToSelect");
				}
				else {
					return "";
				}
			};

	    	/**
	    	 * Exclui o item da seleção 
	    	 */
	    	/*public*/ vm.selectModalRemoveItem = function(p_item) {
				if (p_item && p_item.id) {
					var list = vm.objModel[vm.fieldModel.id];

					for (var i = 0; i < list.length; i++) {
						if (list[i].id == p_item.id) {
							// Exclui o item e quebra o laço
							list.splice(i, 1);
							break;
						}
					}
				}
				selectModalRefreshValue(true);
			}

	    	/*private*/ var selectModalRefreshValue = function(p_isChange) {		
				vm.fieldModel.selecteModalValueList = [];
				if (vm.objModel[vm.fieldModel.id]) {
					vm.fieldModel.selecteModalValueList = vm.objModel[vm.fieldModel.id];
				}

				// Se tiver setado o listener checkFormChange
				if (p_isChange && vm.fieldModel.checkFormChange) {
					vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
				}

				// Se tiver setado o listener onChange
				if (p_isChange && vm.fieldModel.onChange) {
					vm.fieldModel.onChange(vm.objModel, vm.fieldModel.id);
				}
			}

			// No reset do formulário, o label deve ser também limpo
			formResetTaskList.push(function() {
				vm.fieldModel.selecteModalValueList = [];
			});
		}

		/*********************************************************
    	 * ****   SELECT MODEL INDICATIONS MANAGER
    	 ****************************************************** */

		if (vm.fieldModel.type == 'SelectModalIndicationsManager') {
			var modalConfig = vm.fieldModel.modalConfig;
			if (modalConfig == null) {
				UcAlertService.error("No ModalConfig set for formField [" + vm.fieldModel.id + "]");
				return;
			}

			$timeout(function() {
				selectModalRefreshValue(false);
			}, 1);


//    		$timeout(function(){
//    			vm.selectModalClick();
//    			modalConfig.extraButtons[0].callback();
//    		}, 500);

	    	/*public*/ vm.selectModalClick = function() {
				if (!vm.fieldModel["readOnly"]) {
					// Passa configuração para o modal via escopo
					$scope.searchModalConfig =
					{
						searchId: modalConfig.searchId,
						extraButtons: modalConfig.extraButtons,
						multiple: vm.fieldModel.multiple,
						currentValue: vm.objModel[vm.fieldModel.id]
					};

					$uibModal.open({
						templateUrl: 'app/components/search-modal-indications-manager/search-modal-dialog-indications-manager.html',
						controller: 'SearchModalIndicationsManagerController',
						controllerAs: 'vm',
						scope: $scope,
						size: 'lg',
						windowClass: 'modal-dialog-90-percent',
						resolve: {
							account: ['Principal', function(Principal) {
								return Principal.identity();
							}],
							searchModalConfig: () => $scope.searchModalConfig				
						}
					}).result.then(function(p_selectedList) {
						vm.objModel[vm.fieldModel.id] = p_selectedList;
						selectModalRefreshValue(true);
						
						// Executa um callback
						if(modalConfig.onSelect){
							modalConfig.onSelect(vm.objModel[vm.fieldModel.id][0]);
						}
					}, function() {
						/*void*/
					});
				}
			};

	    	/*public*/ vm.selectModalTitle = function() {
				if (!vm.fieldModel["readOnly"]) {
					return $translate.instant("client.global.label.clickToSelect");
				}
				else {
					return "";
				}
			};

	    	/**
	    	 * Exclui o item da seleção 
	    	 */
	    	/*public*/ vm.selectModalRemoveItem = function(p_item) {
				if (p_item && p_item.id) {
					var list = vm.objModel[vm.fieldModel.id];

					for (var i = 0; i < list.length; i++) {
						if (list[i].id == p_item.id) {
							// Exclui o item e quebra o laço
							list.splice(i, 1);
							break;
						}
					}
				}
				selectModalRefreshValue(true);
			}

	    	/*private*/ var selectModalRefreshValue = function(p_isChange) {
				vm.fieldModel.selecteModalValueList = [];
				if (vm.objModel[vm.fieldModel.id]) {
					vm.fieldModel.selecteModalValueList = vm.objModel[vm.fieldModel.id];
				}

				// Se tiver setado o listener checkFormChange
				if (p_isChange && vm.fieldModel.checkFormChange) {
					vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
				}

				// Se tiver setado o listener onChange
				if (p_isChange && vm.fieldModel.onChange) {
					vm.fieldModel.onChange(vm.objModel, vm.fieldModel.id);
				}
			}

			// No reset do formulário, o label deve ser também limpo
			formResetTaskList.push(function() {
				vm.fieldModel.selecteModalValueList = [];
			});
		}

	};
})();