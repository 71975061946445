(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('Client', Client);

    Client.$inject = ['$resource', 'URL', 'SERVICES'];

    function Client ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url + 'api/client/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'listMaritalStatus': { method: 'GET', url: url + 'api/client/list-marital-status', isArray: true },
            'listColor': { method: 'GET', url: url + 'api/client/list-color', isArray: true },
            'getMedicNotesInfo': { method: 'GET', url: url + 'api/client/get-medic-notes-info'},
            'createClientNote': { method: 'POST', url: url + 'api/client/create-client-note'},
            'updateWhatsAppNumber': { method: 'PUT', url: url + 'api/client/update-whatsapp-number'},		
            'updateDiabetic': { method: 'PUT', url: url + 'api/client/update-diabetic/:id'},			
            'getClientByIndicationsManager': { method: 'GET', url: url + 'api/client/find-by-indications-manager'},			
            'countSalesByIndicationsManager': { method: 'GET', url: url + 'api/client/count-client-by-indications-manager/:id'},			
            'totalClientByIndicationsManager': { method: 'GET', url: url + 'api/client/total-sales-by-indications-manager/:id'},			
			'listAllInList':{ method: 'GET', url: url + 'api/client/list-all-in-list', isArray: true },	
			'migrateAvatar':{metthod:'GET',url: url + 'api/uc/migrate-avatar'}		
        });
    }
})();
