(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'PermissionSet'];

    function stateConfig($stateProvider, PermissionSet) {
        $stateProvider
        .state('paymentInstallments', {
            parent: 'entity',
            url: '/paymentInstallments?page&sort',
            data: {
                authorities: [PermissionSet.PAYMENT_INSTALLMENTS.access],
            },
            views: {
                'content@': {
                    templateUrl: 'app/common/page-filter/page-filter.html',
                    controller: 'PaymentInstallmentsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                gridDefaultSort: function()
                {
                	return {
	            		"name": "description", 
	            		"type": "asc"
                	};
            	},
            	filterPageConfig: ['$rootScope', 'account', function($rootScope, account)
                {
                	return new PageConfig({
                		pageMode: "FILTER",
                		account: account,
	                	title: 'client.page.paymentInstallments.title',
	                	icon: $rootScope.menuIcons.cadastre.paymentInstallments,
	                	formValidateTarget: 'PaymentInstallments',
	                	permission: PermissionSet.PAYMENT_INSTALLMENTS
                	})
                }]
            }
        })
        .state('paymentInstallments.detail', {
            parent: 'paymentInstallments',
            url: '/{id}/detail',
            views: {
                'content@': {
                    templateUrl: 'app/common/page-domain/page-domain.html',
                    controller: 'PaymentInstallmentsDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['$stateParams', 'PaymentInstallments', function($stateParams, PaymentInstallments)
                {
                    return PaymentInstallments.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "READONLY"
    	           	});
                }]            
            }
        })
        .state('paymentInstallments.edit', {
            parent: 'paymentInstallments',
            url: '/{id}/edit',
            views: {
                'content@': {
                	templateUrl: 'app/common/page-domain/page-domain.html',
                    controller: 'PaymentInstallmentsDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: ['PaymentInstallments', '$stateParams', function(PaymentInstallments, $stateParams)
                {
                    return PaymentInstallments.get({id : $stateParams.id}).$promise;
                }],
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                   		pageMode: "EDIT"
    	           	});
                }]
            }            
        })
        .state('paymentInstallments.new', {
            parent: 'paymentInstallments',
            url: '/new',
            views: {
                'content@': {
                	templateUrl: 'app/common/page-domain/page-domain.html',
                    controller: 'PaymentInstallmentsDomainController',
                    controllerAs: 'vm'
                }
            },
            resolve:
            {
                entity: function()
                {
                    return {}
                },
                pageConfig: ['filterPageConfig', function(filterPageConfig)
                {
                	return filterPageConfig.clone().set(
                	{
                    	pageMode: "CREATE"
    	           	});
                }]            
            }
        });
    }

})();
