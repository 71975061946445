(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('zebraLine', zebraLineDirective);
    
    zebraLineDirective.$inject = [];
    function zebraLineDirective()
    {
    	return {
            link: function(scope, element, attributes)
            {
            	var index = scope.$eval(attributes.zebraLine);
    			var cssClass = ((index % 2) == 0) ? "zebra-line" : "";
    			element.addClass(cssClass);
    		}            
    	}
    };
})();
