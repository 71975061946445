(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('formButtons', formButtonsDirective);
    
    formButtonsDirective.$inject = [];
    function formButtonsDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "FormButtonDirectiveController",
            templateUrl: "app/components/uc-form/form-button/form-buttons.directive.template.html",
            scope:
            {
            	pageConfig: '=',
            	model: '='
            }
    	}
    };
})();
