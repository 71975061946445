(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierManagerTabAddressController', SupplierManagerTabAddressController);
    
    SupplierManagerTabAddressController.$inject = ['$scope', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$translate'];
    function SupplierManagerTabAddressController($scope, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $translate)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        formHandler.reopenConfig()
        	.add([
        		new FormField({
	           		cellCssClass: "col-sm-2",
	           		formTarget: "address",
					type: "ZipCode",
					id: "zipCode",
					label: "common.domain.supplier.zipCode",
					searchEngine:
					{
						handleResult: function(p_data)
						{
							if(p_data)
							{
								entity.addressStreet = p_data.address;
								entity.addressDistrict = p_data.district;
								entity.city = (p_data.cityDto) ? p_data.cityDto : null;
							}
						}
					}
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "address",
					label: "common.domain.supplier.city",
					id: "city",
					type: "Select",
					dataSource: FormConstants.DataSources.BackEnd.City(BackEndSelectDataSource, $translate)
				}),
        		new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "address",
	           		label: "common.domain.supplier.addressStreet",
					type: "String",
					id: "addressStreet"
				})
								
        	])
        	.add([new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "address",
	           		label: "common.domain.supplier.addressDistrict",
					type: "String",
					id: "addressDistrict"
				}),
				new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "address",
	           		label: "common.domain.supplier.addressNumber",
					type: "String",
					id: "addressNumber"
				}),
				new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "address",
	           		label: "common.domain.supplier.addressComplement",
					type: "String",
					id: "addressComplement"
				})
				
        	])	        	
		.endConfig();
    };
})();