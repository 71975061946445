(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('formDynamic', formDynamicDirective);
    
    formDynamicDirective.$inject = [];
    function formDynamicDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "FormDynamicDirectiveController",
            templateUrl: "app/components/uc-form/form-dynamic/form-dynamic.directive.template.html",
            scope:
            {
            	formHandler: '=',
            	pageConfig: '=',
            	buttonsModel: '=',
            	suppressButtons: '=',
            	formId: "@"
            }
    	}
    };
})();
