(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('StockFlowMotiveDomainController', StockFlowMotiveDomainController);

    StockFlowMotiveDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'StockFlowMotive', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$timeout'];

    function StockFlowMotiveDomainController(FormDomain, $scope, $state, entity, StockFlowMotive, pageConfig, FrontEndSelectDataSource, FormConstants, $timeout)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		var isSaving = false;

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "STOCK_FLOW_MOTIVE",
        	stateRoot: "stockFlowMotive",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        	}
        };

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: StockFlowMotive,
        	historyConfig: {historyId: "STOCK_FLOW_MOTIVE"},
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
				cellCssClass: "col-sm-6",
				type: "String",
				id: "id",
				required: true,
				label: "client.global.label.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})])
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					label: "common.domain.stockFlowMotive.description",
					required: true
				})
        	])
			.endConfig();

        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        };

        /*public*/ vm.goBack = function()
        {
        	$state.go("stockFlowMotive");
        }
    }
})();
