(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsEditHistoryModalInvoker', RecordsEditHistoryModalInvoker);
	
	RecordsEditHistoryModalInvoker.$inject = ['$uibModal'];
	
	function RecordsEditHistoryModalInvoker($uibModal)
	{
		var vm = this;
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/history/records-edit-history-modal-dialog.html',
                controller: 'RecordsEditHistoryModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop  : 'static',
                windowClass: 'modal-dialog-70-percent',
                resolve: {
                    historyList: ['Records', function(Records)
                    {
                   		return Records.listContentEditHistory({id: p_param.id}).$promise;
                    }]
                }
            });
		};
		
	};
})();
