(function() {
    'use strict';

    angular
        .module('sgmApp')
        .directive('pageHeader', pageHeaderDirective);
        
    pageHeaderDirective.$inject = [];
    function pageHeaderDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "PageHeaderDirectiveController",
            templateUrl: "app/components/page-header/page-header-template.html",
            scope:
            {
            	pageConfig: '='
            }
    	}
    };
})();
