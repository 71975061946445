(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ClientManagerTabPersonalController', ClientManagerTabPersonalController);
    
    ClientManagerTabPersonalController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout'];
    function ClientManagerTabPersonalController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        formHandler.reopenConfig()
        	.add([new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					type: "Select",
					id: "gender",
					label: "common.domain.client.gender",
					required: true,
					dataSource: FrontEndSelectDataSource.create(injectedResources.genderList)
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					type: "Select",
					id: "maritalStatus",
					label: "common.domain.client.maritalStatus",
					dataSource: FrontEndSelectDataSource.create(injectedResources.maritalStatusList)
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					label: "common.domain.client.cityNationality",
					id: "cityCityId",
					type: "Select",
					dataSource: FormConstants.DataSources.BackEnd.City(BackEndSelectDataSource, $translate)
				})
        	])
        	.add([new FormField({
        			cellCssClass: "col-sm-4",
        			formTarget: "personal",
					type: "Date",
					id: "birthDate",
					label: "common.domain.client.birthDate",
					required: false,
					onChange: function()
					{
						refreshAge();
					}
				}),
				new FormField({
        			formTarget: "personal",
        			cellCssClass: "col-sm-4",
        			label: "client.global.label.age",
        			type: "String",
        			id: "age",							// Calculado
        			readOnly: true
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					label: "common.domain.client.fatherName",
					id: "fatherName",
					type: "String",
					required: false
				}),
        	])	        	
        	.add([new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					label: "common.domain.client.profession",
					id: "profession",
					type: "Select",
					required: false,
					dataSource: FormConstants.DataSources.BackEnd.Profession(BackEndSelectDataSource, $translate)
				}),
				new FormField({
        			formTarget: "personal",
        			cellCssClass: "col-sm-4",
        			label: "common.domain.client.professionDescription",
        			id: "professionDescription",
        			type: "String"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					label: "common.domain.client.motherName",
					id: "motherName",
					type: "String",
					required: false
				})
			])	
        	.add([new FormField({
					cellCssClass: "col-sm-4",
					formTarget: "personal",
					label: "common.domain.client.companionName",
					id: "companionName",
					type: "String",
					required: false,
					maxLength: 500,
				}),
				new FormField({
	           		cellCssClass: "col-sm-4",
	           		formTarget: "personal",
					type: "Phone",
					required: false,
					id: "companionPhone",
					label: "common.domain.client.companionPhone",
					addonCssClass: "glyphicon glyphicon-phone"
				})
			])
			.endConfig();
        
        $timeout(function()
        {
        	refreshAge();
        }, 1);

        
        /*private*/ function refreshAge()
        {
        	var val = null;
        	if(vm.domain && vm.domain.birthDate)
        	{
        		try
        		{
        			var birthDate = new Date(vm.domain.birthDate).getTime();
        			var now = (new Date()).getTime();
        			var ageDate = new Date(now - birthDate); // miliseconds from epoch
        			val = Math.abs(ageDate.getUTCFullYear() - 1970);
        		}
        		catch(exc)
        		{
        			console.error("Error at calculating age: " + exc.message);
        		}
        	}
        	vm.domain.age = val;
        };
    };
})();