'use strict';

var FormUtil =
{
	/**
	 * Chama a validação de todos os input's do formulário
	 */
	validateAll: function(p_form, p_rowList)
	{
		var row, formField;
		for(var i = 0 ; i < p_rowList.length ; i++)
		{
			row = p_rowList[i];
			for(var j = 0 ; j < row.length ; j++)
			{
				formField = row[j];
				
				// Se for do tipo DateTime, chama a validação para os componentes nested
				if(formField.type == "DateTime")
				{
					_validateFormField(p_form, formField.dateFieldModel);
					_validateFormField(p_form, formField.timeFieldModel);
				}
				else
				{
					_validateFormField(p_form, formField);
				}
			}
		}
	},
		
	validateField: function(p_domain, p_form, UcAlertService, $translate)
	{
		if (p_form.$invalid)
		{
			angular.forEach(p_form.$error, function(field)
			{
				angular.forEach(field, function(errorField)
				{
					errorField.$setTouched();
					errorField.$setDirty();
				})
			});
		}

		if(!p_form.$valid)
		{
			if(DEBUG_MODE)
			{
				_dumpErrors(p_form);
			}
			
			var countErrors = 0;
			if(p_form.formValidatorMap)
			{
				countErrors += Object.keys(p_form.formValidatorMap).length;
			}
			// Componente de data
			if(p_form.$error.date && angular.isArray(p_form.$error.date))
			{
				countErrors += p_form.$error.date.length;
			}

			// Alguns erros não são rastreados por aqui
			var errorMsg;
			if(countErrors)			
			{
				errorMsg = $translate.instant("client.global.messages.error.thereAreErrorsOnFormP0", {count: "" + countErrors})
			}
			else
			{
				errorMsg = $translate.instant("client.global.messages.error.thereAreErrorsOnForm")
			}
			
			UcAlertService.message(errorMsg, "WARNING", "BOX");
			if(p_form.ffvId)
			{
				$("#" + p_form.ffvId).addClass("form-validator-form-error");
			}
		}
		else
		{
			if(p_form.ffvId)
			{
				$("#" + p_form.ffvId).removeClass("form-validator-form-error");
			}
		}
		return p_form.$valid;
	}
};
/*private*/ /*static*/ function _validateFormField(p_form, p_formField)
{
	var parser;
	var modelController = p_form[p_formField.id];
	if(modelController && modelController.$parsers)
	{
		for(var k = 0 ; k < modelController.$parsers.length ; k++)
		{
			parser = modelController.$parsers[k];
			if(parser && parser.ucFormValidation)
			{
				try
				{
					parser(modelController.$modelValue);
				}
				catch(ee)
				{
					console.error("[validateAll] Erro at call field-validate: " + ee.message);
				}
			}
		}
	}
};

/*private*/ /*static*/ function _dumpErrors(p_form)
{
	var allKeys = Object.keys(p_form);
	
	for (var i = 0; i < allKeys.length; i++)
	{
		var key = allKeys[i];
		if(key.indexOf("$") < 0)
		{
			if(!p_form[key].$valid)
			{
				console.log("++++++++++++++++++++++++ " + key);
			}
		}
	}
	
	console.log("---------------------------------");

	
	for (var i = 0; i < allKeys.length; i++)
	{
		var key = allKeys[i];
		if(key.indexOf("$") < 0)
		{
			console.log(">>> " + key + " = " + p_form[key].$valid + " - " + p_form[key].$invalid)
		}
	}
};