(function() {
    'use strict';
    angular
        .module('sgmApp')
        .factory('Attendance', Attendance);

    Attendance.$inject = ['$resource', 'URL', 'SERVICES'];

    function Attendance ($resource, URL, SERVICES) {
        var url = URL[SERVICES.environment].api;
        var resourceUrl = url +'api/attendance/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'cancel': { method:'PATCH', url: url + 'api/attendance/cancel'},
            'reopen': { method:'GET', url: url + 'api/attendance/reopen/:id'},
//            'reschedule': { method: 'PATCH', url: url + 'api/attendance/reschedule'},
            'listStatus': { method: 'GET', url: url + 'api/attendance/list-status', isArray: true },
            'simpleUpdate': { method:'PUT' , url: url + 'api/attendance/simple-update'},
            'attendanceConfirmation': { method: 'PATCH', url: url + 'api/attendance/attendance-confirmation'},
            'getConfirmationData': { method: 'GET', url: url + 'api/attendance/get-confirmation-data'},
            'getClientData': { method: 'GET', url: url + 'api/attendance/get-client-data'},
            'undoAttendanceConfirmation': { method: 'DELETE', url: url + 'api/attendance/undo-attendance-confirmation'},
            'getProcedureStandardValueList': { method: 'GET', url: url + 'api/attendance/get-procedure-standard-value-list', isArray: true },
            'getLastAttendanceByClient': { method: 'GET', url: url + 'api/attendance/get-last-attendance-by-client/:clientId' },
            'getPermissions': { method: 'GET', url: url + 'api/attendance/get-permissions' },
            'getStatusByCode': { method: 'GET', url: url + 'api/attendance/get-status-by-code/:code' },
            'sendConfirmationEmail': { method: 'GET', url: url + 'api/attendance/sendConfirmationEmail/:id' },
            'listAttendanceCostOptions': { method: 'GET', url: url + 'api/attendance/listAttendanceCostOptions', isArray: true },
            'getAttendanceConfirmationMessage': { method: 'GET', url: url + 'api/attendance/get-attendance-confirmation-message/:id'},
            'listAnesthetistSelectOptions': { method: 'GET', url: url + 'api/attendance/list-anesthetist-select-options', isArray: true },
            'isToShowRecordsButton': { method: 'GET', url: url + 'api/attendance/is-to-show-records-button'},
            'getMedicNotesInfo': { method: 'GET', url: url + 'api/attendance/get-medic-notes-info'},
            'listAvailabilitySchedule': { method: 'GET', url: url + 'api/availability-schedule/list-availability-schedule/:id', isArray: true},
            'refreshAvailabilitySchedule': {  method: 'POST', url: url + 'api/availability-schedule/refresh-availability-schedule', isArray: true},
            'delete': { method: 'DELETE', url: url + 'api/availability-schedule/:id'},
            'listSchedulable': {url: url + 'api/availability-schedule/list-schedulable', method: 'GET', isArray: true},
            'listScheduleByResource': {url: url + 'api/attendance-temp/:id', method: 'GET'},
            'schedulingByResource': {url: url + 'api/attendance-temp', method: 'POST', isArray: true},
            'updateSchedule': {url: url + 'api/attendance-temp/:id', method: 'PUT' },
            'getAgenda': { method: 'GET', url: url + 'api/attendance/get-agenda' },
            'getAttendanceStatus': { method: 'GET', url: url + 'api/attendance/get-attendance-status' },
            // TODO_CODE: Verificar se reduntante
//            'listAgendaLocking': {url: url + 'api/agenda-locking/list-agenda-locking/:id', method: 'GET', isArray: true},
//        	'refreshAgendaLocking': {url: url + 'api/agenda-locking/refresh-agenda-locking', method: 'POST', isArray: true},
//        	'deleteAgendaLocking': {url: url + 'api/agenda-locking/:id', method: 'DELETE'},
//        	'listAgendaLockingTime': {url: url + 'api/agenda-locking/list-agenda-locking-time/:id', method: 'GET', isArray: true},
////            'refreshAgendaLockingTime': {url: url + 'api/agenda-locking/refresh-agenda-locking-time', method: 'POST', isArray: true},
//        	'deleteAgendaLockingTime': {url: url + 'api/agenda-locking-time/:id', method: 'DELETE'},
        	'getSchedulable': {url: url + 'api/availability-schedule/get-schedulable/:schedulableId', method: 'GET'},
        	'listFirstAttendanceStatus': {url: url + 'api/attendance/list-first-attendance-status', method: 'GET', isArray: true},
        	'getTodayAgendaConfig': {url: url + 'api/attendance/get-today-agenda-config', method: 'GET'},
        	'updateSalesRequest': {url: url + 'api/attendance/update-sales-request', method: 'PUT'},
        	'listRequestType': {url: url + 'api/attendance/list-request-type', method: 'GET', isArray: true},
        	'listDefaultDuration': {url: url + 'api/attendance/list-default-duration', method: 'GET', isArray: true},
        	'updateAttendanceStatus': {url: url + 'api/attendance/update-attendance-status', method: 'PUT'},
            'confirmAttendanceProducts': {
                url: url + 'api/attendance/confirm-attendance-products/:id', 
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        if(data[0] !== '{') {
                            data = {value: Number.parseFloat(data)};
                        } else {
                            data = angular.fromJson(data);
                        }
                    }
                    console.log(data);
                    return data;
                }
            },
            'refreshAutomaticProcedureProducts': {
            	url: url + 'api/attendance/refresh-automatic-procedure-products/:id', method: 'GET', isArray: true,
            },
            'listAttendanceProductStatus': { method: 'GET', url: url + 'api/attendance/list-attendance-product-status', isArray: true },
            'getClientProductsAttendances': { method: 'GET', url: url + 'api/attendance/get-client-products-attendances/:clientId', isArray: true },
        	//Disponibilidade de agendamento
//    		'listAvailabilityForSchedule' : {url: url + 'api/list-availability-for-schedule', isArray: true, method: 'GET'}
        });
    }
})();
