(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('MyAccountController', MyAccountController);

    MyAccountController.$inject = ['FormDomain', '$scope', 'User', 'pageConfig', 'UcAlertService', '$translate', 'myData', '$rootScope'];

    function MyAccountController(FormDomain, $scope, User, pageConfig, UcAlertService, $translate, myData, $rootScope)
    {
        var vm = this;
        
        var obj = myData;
        
        // Seta os perfis
        setProfiles(obj, myData);

        vm.pageConfig = pageConfig;

        $rootScope.initialFocus("name");

        // Não utiliza  asugestão de botões já que o 'Voltar' não se aplica 
        var saveButton = {
        	order: 0,
        	cssClass: 'btn btn-success',
        	cssClassIcon: 'glyphicon glyphicon-save',
        	label: 'client.global.label.save',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function() { save(); }
        };
        
        vm.buttonsModel = {
        	useSuggestedButton: false,
        	extraButtonList: [saveButton]
        };
        
        var formOptions = {
        	model: obj,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: User,
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "cpf",
					label: "common.domain.user.cpf",
					readOnly: true
				}),
				new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					label: "client.global.label.id",
					readOnly: true
				})
			])			
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "String",
					id: "rolesDescription",
					label: "client.global.label.roles",
					readOnly: true
				})
			])
			.add(new FormField({
           		cellCssClass: "col-sm-12",
				type: "String",
				id: "name",
				required: true,
				label: "common.domain.user.name"
			}))
			.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String-Group",
					id: "email",
					required: true,
					label: "common.domain.user.email",
					addonCssClass: "fa fa-envelope-o"
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Date",
					id: "birthDate",
					label: "common.domain.user.birthDate"
				})
			])
			.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String-Group",
					id: "phone",
					label: "common.domain.user.phone",
					addonCssClass: "glyphicon glyphicon-earphone"
				}),
				new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String-Group",
					id: "job",
					label: "common.domain.user.job",
					addonCssClass: "fa fa-briefcase"
				})
			])
			.endConfig();
        
        
        var isSaving = false;
        
        /*private*/ function save()
        {
	    	UcAlertService.dismissBox();
	    	
	    	FormUtil.validateAll($scope.editForm, vm.formHandler.getRowList());
	    	
	    	if(FormUtil.validateField('MyAccount', $scope.editForm, UcAlertService, $translate))
	    	{
    			isSaving = true;
    			User.updateMyAccount(obj, saveCallback, saveCallback);
	    	}
        };
        
        /*private*/ function saveCallback()
        {
        	isSaving = false;
        };
        
        /*private*/ function setProfiles(p_obj, p_myData)
        {
	        if(p_myData.roleCacheList)
	        {
	        	p_obj.rolesDescription = "";
	        	for(var i = 0 ; i < p_myData.roleCacheList.length ; i++)
	        	{
	        		if(i > 0)
	        		{
	        			p_obj.rolesDescription += ", ";
	        		}
	        		p_obj.rolesDescription += p_myData.roleCacheList[i].defaultDescription;
	        	}
	        }
        };
    }
})();
