(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SpecialtyDomainController', SpecialtyDomainController);
    
    SpecialtyDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'Specialty', 'pageConfig', '$rootScope', 'FormConstants'];

    function SpecialtyDomainController(FormDomain, $scope, $state, entity, Specialty, pageConfig, $rootScope, FormConstants)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;

        $rootScope.initialFocus("code");

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "SPECIALTY",
        	stateRoot: "specialty",
        	previousState: {name: $state.current.parent},
        	onClear: function() { vm.formHandler.clear(); }
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: Specialty,
        	historyConfig: {historyId: "SPECIALTY"},
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "String",
					id: "code",
					required: true,
					label: "common.domain.specialty.code",
					maxLength: 30
				})
        	])
			.add([new FormField({
					cellCssClass: "col-sm-12",
					type: "String",
					id: "description",
					required: true,
					label: "common.domain.specialty.description",
					maxLength: 300
				})
        	])
			.add([new FormField({
	           		cellCssClass: "col-sm-12",
					type: "Boolean",
					id: "active",
					label: "client.global.label.active",
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
			])
			.endConfig();
        
        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        }
    }
})();
