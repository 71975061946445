(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('UserDomainController', UserDomainController);
    
    UserDomainController.$inject = ['FormDomain', '$scope', '$state', 'entity', 'roleList', 'genderList', 'User', 'pageConfig', 'FrontEndSelectDataSource', 'FormConstants', '$rootScope', 'tabList', '$timeout', 'specialtyList', '$translate', 'SgmConstants', '$stateParams', 'PhotoManager', '$filter', 'Attendance', 'DownloadUtil', 'URL', 'SERVICES', 'FileUploadModalInvoker', 'permissionsList', 'Principal'];

    function UserDomainController(FormDomain, $scope, $state, entity, roleList, genderList, User, pageConfig, FrontEndSelectDataSource, FormConstants, $rootScope, tabList, $timeout, specialtyList, $translate, SgmConstants, $stateParams, PhotoManager, $filter, Attendance, DownloadUtil, URL, SERVICES, FileUploadModalInvoker, permissionsList, Principal)
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;
		vm.tabList = tabList;
		var isSaving = false;
		vm.listDaysWeek = [];
		var avaliablePermissions = [];
		var permissionSetDataSource;
		var additionalPermissionsSet = [];
		vm.allPermissions = [];
		vm.initialAllPermissions = [];
		vm.currentSort = 'id';
		for(var i = 0; i < permissionsList.length; i++) {
			additionalPermissionsSet.push({
				id: permissionsList[i],
				defaultDescription: permissionsList[i]
			});
		};
		if(entity.additionalPermissions) {
			for(var i = 0; i < entity.additionalPermissions.length; i++) {
				var permission = entity.additionalPermissions[i];
				entity.additionalPermissions[i] = {
					id: permission,
					defaultDescription: permission,
				};
			}
		} else {
			entity.additionalPermissions = [];
		}
		vm.extraTabList = [
    		{code: "permissions", icon: "fa fa-file-text-o", label: "client.page.user.permissions.title"},
    	];
		vm.extraTabHandler = {
			disabledMap: {},
			active: 'permissions',
			isActive: function (p_code) {
				return (p_code === vm.extraTabHandler.active);
			},
			isDisabled: function (p_code) {
				return false;
			},
			isShow: function (p_code) {
				return true;
			},
			select: function (p_id) {
				vm.extraTabHandler.active = p_id;
			}
		};

			
		vm.sortPermissionList = function(field) {
			vm.currentSort = field;
			vm.allPermissions.sort((a,b) => {
				if (a[field] > b[field]) {
					return 1;
				  }
				  if (a[field] < b[field]) {
					return -1;
				  }

				  return 0;
			});
		}
		
        // Seta os perfis
		setProfiles();
		updatePermissionsSet();
		updateAllUserPermissionsList(true);


		function initDaysWeek() 
		{
			vm.startTime = '';
			vm.endTime = '';
			var daysWeek = [ { day: 'D', number: 0, status: false, startTime: '', endTime: ''}, 
							{ day: 'S', number: 1, status: false, startTime: '', endTime: ''},
							{ day: 'T', number: 2, status: false, startTime: '', endTime: ''},
							{ day: 'Q', number: 3, status: false, startTime: '', endTime: ''},
							{ day: 'Q', number: 4, status: false, startTime: '', endTime: ''},
							{ day: 'S', number: 5, status: false, startTime: '', endTime: ''},
							{ day: 'S', number: 6, status: false, startTime: '', endTime: ''}]; 

			return daysWeek;
		}

		vm.daysWeek = initDaysWeek();
		
        $rootScope.initialFocus("cpf");
        
        // Próximo ciclo para dar tempo de criar as funções
        $timeout(function()
        {
        	refreshTabs();
        	
        	// Se já criado, busca as imagens do print config
        	if(vm.domain.id)
        	{
        		User.getRecordsPrintConfigBackgroundImage({id: vm.domain.id}, function(p_imageDto)
        		{
        			setPrintConfigImage("configImageBackground", p_imageDto);
        		});
        		User.getRecordsPrintConfigSignatureImage({id: vm.domain.id}, function(p_imageDto)
        		{
        			setPrintConfigImage("configImageSignature", p_imageDto);
        		});
        	}
        }, 1);
        
        // Converte do formato do DTO para ser identificado pelo form-field 
        convertSpecialtyList();
        
        // Flag q indica se o usuário tem o perfil Médico
        var hasMedicalProfile = userHasMedicalProfile(entity.roleCacheList);
        var hasSignature = Principal.hasAnyAuthority(['ROLE_ASSINATURA']);
        vm.tabHandler = {
        	active: 'main',
        	disabledMap: {},
        	isActive: function(p_code)
        	{
        		return (p_code === vm.tabHandler.active);
        	},
        	isDisabled: function(p_code)
        	{
				if(p_code == "schedulesAvailable" && vm.pageConfig.pageMode === 'CREATE') {
					return true;
				} 
				else {
					return vm.tabHandler.disabledMap[p_code];
				}
        	},
        	isShow: function(p_code)
        	{
        		// Aba Dados do Profissional, só para o perfil Médico
        		if(p_code == "professional")
        		{
        			if(!hasMedicalProfile)
        			{
        				return false;
        			}
        		} else if(p_code == "signature")
        		{
        			if(!hasSignature)
        			{
        				return false;
        			}
        		}
        		return true;
        	},
        	select: function(p_id)
        	{
        		// só se estiver ativo
        		if(!vm.tabHandler.isDisabled(p_id))
        		{
        			// Se for Foto, recarrega a imagem
        			if(p_id == "photo")
        			{
        				$scope.$broadcast('sgmApp.photoUpload.refresh');
        			}
        			vm.tabHandler.active = p_id;
				}
        	}
        };
        
        var printConfigRequired = !!(vm.domain.id);

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "USER",
        	stateRoot: "user",
        	previousState: {name: $state.current.parent},
        	onClear: function()
        	{
        		vm.formHandler.clear();
        		
        		// Select multiplo não pode receber valor nulo pois gera erro no componente
        		entity.roleList = [];
        	},
        	extraButtonList: [{
	        	order: 90,
	        	cssClass: 'btn btn-primary',
	        	cssClassIcon: 'fa fa-lock',
	        	label: 'client.page.user.resetPassword',
	        	type: 'button',
	        	isDisabled: function()
	        	{
	        		// Deve ser edição:
	        		if(!vm.domain.id)
	        		{
	        			return true;
	        		}
	        		
	        		// Só se tiver a permissão
	        		return !(vm.pageConfig.permission && userHasPermission(vm.pageConfig.account, vm.pageConfig.permission.edit));
	        	},
	        	isToAdd: function()
	        	{
	        		return true;
	        	},
	        	click: function()
	        	{
	        		resetPasword();
	        	}
	        }]
        };
            
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: User,
        	historyConfig: {historyId: "USER"},
        	getForm: function(){ return $scope.editForm; },
        	afterUpdate: function(){ return afterUpdate(); },
        	beforeInvokeService: function(p_json)
        	{
        		// Adiciona o campo de Obs Disponibilidae
        		try
        		{
        			p_json.availabilityObs = editor.getData();
        		}
        		catch(exc)
        		{
        			console.error("Error at read availabilityObs: " + exc.message);
        		}
        	}
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "main",
					type: "CPF",
					id: "cpf",
					label: "common.domain.user.cpf",
					required: true,
					maxLength: 14,
					readOnly: !!(entity && entity.id)
				}),new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "main",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
        	])
			.add([new FormField({
	           		cellCssClass: "col-sm-5",
	           		formTarget: "main",
					type: "String",
					id: "name",
					required: true,
					label: "common.domain.user.name"
				}),
				new FormField({
	           		cellCssClass: "col-sm-1",
	           		formTarget: "main",
					type: "String",
					id: "initials",
					label: "common.domain.user.initials",
					maxLength: 8
				})			
				,new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "main",
					type: "String-Group",
					id: "email",
					required: true,
					label: "common.domain.user.email",
					addonCssClass: "fa fa-envelope-o"
				})
			])
			.add([new FormField({
					cellCssClass: "col-sm-6",
					formTarget: "main",
					type: "Select",
					id: "roleList",
					required: true,
					multiple: true,
					label: "client.global.label.roles",
					dataSource: FrontEndSelectDataSource.create(roleList),
					onChange: function(value) {
						updatePermissionsSet();
					}
				}),
				new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "main",
					type: "String-Group",
					id: "job",
					label: "common.domain.user.job",
					addonCssClass: "fa fa-briefcase"
				}),
				new FormField({
					cellCssClass: "col-sm-12",
					type: "Select",
					id: "additionalPermissions",
					required: true,
					multiple: true,
					label: "common.domain.user.additionalPermissions",
					dataSource: permissionSetDataSource,
					onChange: function(value) {
						updateAllUserPermissionsList();
					}
				})
			])
			.add([new FormField({
					cellCssClass: "col-sm-6",
					formTarget: "main",
					type: "Select",
					id: "gender",
					label: "common.domain.user.gender",
					required: true,
					dataSource: FrontEndSelectDataSource.create(genderList)
				}),new FormField({
					cellCssClass: "col-sm-6",
					formTarget: "main",
					type: "Date",
					id: "birthDate",
					label: "common.domain.user.birthDate"
				})
			])
			.add([new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "main",
					type: "Phone",
					id: "phone",
					label: "common.domain.user.phone"
				}),
				new FormField({
	           		cellCssClass: "col-sm-6",
	           		formTarget: "main",
					type: "Date",
					id: "admissionDate",
					label: "common.domain.user.admissionDate"
				})
			])
			.add([new FormField({
           		cellCssClass: "col-sm-6",
           		formTarget: "main",
				type: "Boolean",
				id: "active",
				label: "common.domain.user.active",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			})])
			.add([new FormField({
				cellCssClass: "col-sm-12",
				formTarget: "professional",
				type: "Select",
				id: "specialtyList",
				multiple: true,
				label: "common.domain.professional.specialties",
				dataSource: FrontEndSelectDataSource.create(specialtyList)
			})])
			.add([new FormField({
				cellCssClass: "col-sm-12",
				formTarget: "schedulesAvailable",
				type: "String",
				id: "availabilityObs",
				label: "common.domain.user.availabilityObs"
			})]);			
			
			
			
			
			if(printConfigRequired)
			{
				vm.formHandler.add([new FormField({
						cellCssClass: "col-sm-6",
						formTarget: "printConfig",
						type: "Double",
						required: true,
						id: "printConfigDimensionsWidth",
						label: "common.domain.recordsPrintConfig.dimensionsWidth"
					}),
					new FormField({
						cellCssClass: "col-sm-6",
						formTarget: "printConfig",
						type: "Double",
						required: true,
						id: "printConfigDimensionsHeight",
						label: "common.domain.recordsPrintConfig.dimensionsHeight"
					})
				])
				.add([new FormField({
						cellCssClass: "col-sm-3",
						formTarget: "printConfig",
						type: "Double",
						required: true,
						id: "printConfigMarginLeft",
						label: "common.domain.recordsPrintConfig.marginLeft"
					}),
					new FormField({
						cellCssClass: "col-sm-3",
						formTarget: "printConfig",
						type: "Double",
						required: true,
						id: "printConfigMarginRight",
						label: "common.domain.recordsPrintConfig.marginRight"
					}),
					new FormField({
						cellCssClass: "col-sm-3",
						formTarget: "printConfig",
						type: "Double",
						required: true,
						id: "printConfigMarginTop",
						label: "common.domain.recordsPrintConfig.marginTop"
					}),
					new FormField({
						cellCssClass: "col-sm-3",
						formTarget: "printConfig",
						type: "Double",
						required: true,
						id: "printConfigMarginBottom",
						label: "common.domain.recordsPrintConfig.marginBottom"
					})
				])
			}
			vm.formHandler.endConfig();
			
			
        /*private*/ var editor = null;
        $timeout(function()
        {
        	editor = initCKEditor("availableObsHtml", 150, nvlNotNull(vm.domain.availabilityObs));
        }, 100);

        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
            $timeout(function()
            {
            	refreshTabs();
            }, 1);
        };
        
        /*public*/ vm.goBack = function()
        {
        	$state.go("user");
        }
        
        /**
         * Botão voltar da aba Foto
         */
        /*public*/ vm.doBack = function()
        {
        	$state.go('user');
        };
        
        /*private*/ function refreshTabs()
        {
        	vm.tabHandler.disabledMap["photo"] = !entity.id; 
        	vm.tabHandler.disabledMap["professional"] = !entity.id;
			vm.tabHandler.disabledMap["signature"] = !entity.id;
        };
        
        /*private*/ function convertSpecialtyList()
        {
	        entity.specialtyList = [];
	    	if(entity.userSpecialtySet)
	    	{
	    		for (var i = 0; i < entity.userSpecialtySet.length; i++)
	    		{
	    			entity.specialtyList.push(entity.userSpecialtySet[i].specialtyCache);
				}
	    	}
        };
        
        /*private*/ function userHasMedicalProfile(p_roleList)
        {
	    	if(p_roleList)
	    	{
	    		for (var i = 0; i < p_roleList.length; i++)
	    		{
	    			if(p_roleList[i].code == SgmConstants.Profile.Medical)
	    			{
	    				return true;
	    			}
				}
	    	}
	    	return false;
        };
        

        /*private*/ function setProfiles()
        {
        	vm.domain.roleList = [];
	        if(vm.domain.roleCacheList)
	        {
	        	for(var i = 0 ; i < vm.domain.roleCacheList.length ; i++)
	        	{
	        		vm.domain.roleList.push(vm.domain.roleCacheList[i]);
	        	}
	        }
        };
	

		function updatePermissionsSet() {
			avaliablePermissions.length = 0;
			for(var i = 0; i < additionalPermissionsSet.length; i++) {
				avaliablePermissions.push(additionalPermissionsSet[i]);
			}
			for(var i = 0; i < vm.domain.roleList.length; i++) {
				var role = vm.domain.roleList[i];
				for(var j = 0; j < role.permissionSet.length; j++) {
					var index = avaliablePermissions.findIndex((obj) => obj.id == role.permissionSet[j]);
					if(index > -1) {
						avaliablePermissions.splice(index, 1);
						index = entity.additionalPermissions.findIndex((obj) => obj.id == role.permissionSet[j]);
						if(index > -1) {
							entity.additionalPermissions.splice(index, 1);
						}
					}
						
				}
			}
			if(permissionSetDataSource) {
				permissionSetDataSource.setOptionsList(avaliablePermissions);
			} else {
				permissionSetDataSource = FrontEndSelectDataSource.create(avaliablePermissions);
			}
			updateAllUserPermissionsList();

		}
		function updateAllUserPermissionsList(initial) {
			vm.allPermissions = [];
			for(var i = 0; i < entity.additionalPermissions.length; i++) {
				var obj = {};
				Object.assign(obj, entity.additionalPermissions[i]);
				vm.allPermissions.push(obj);
			}
			console.log(vm.allPermissions);
			for(var i = 0; i < vm.domain.roleList.length; i++) {
				var role = vm.domain.roleList[i];
				for(var j = 0; j < role.permissionSet.length; j++) {
					var permission = role.permissionSet[j];
					if(vm.allPermissions.findIndex(obj => obj.id == permission) == -1) {
						vm.allPermissions.push({
							id: permission,
							defaultDescription: permission,
							roleName: role['name'],
						});
					}
					
				}
			}
			vm.sortPermissionList(vm.currentSort);
			if(initial) {
				vm.initialAllPermissions = Array.from(vm.allPermissions);
			} else {
				for(var i = 0; i < vm.allPermissions.length; i++) {
					var permission = vm.allPermissions[i];
					var oldPermission = vm.initialAllPermissions.find(obj => obj.id == permission.id);
					if(!oldPermission) {
						permission.new = true;
					} else {
						permission.new = false;
					}
				}
			}
		}

		
        /*private*/ function afterUpdate()
        {
        	// Atualiza a flag que controla a aba Médicos
        	hasMedicalProfile = userHasMedicalProfile(entity.roleList);
        };
        
		/*private*/ function resetPasword(p_data)
		{
			var message = $translate.instant("client.page.user.resetPasswordMessage") + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					User.resetPassword(
						{
							id: $stateParams.id
						},
						function()
						{
							/* void */
						}
					);
				} 
			});
		}
		
        /**
         * Para o Print Config
         */
        /*public*/ vm.removeImage = function(p_imageType)
        {
        	var operation = $translate.instant("client.page.user.printConfig.imageDelete");
        	var message = $translate.instant("client.global.messages.info.confirmOperationP0", {operation: operation});
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					var func = (p_imageType == "BACKGROUND") ? User.removeRecordsPrintConfigBackgroundImage : User.removeRecordsPrintConfigSignatureImage;
					func({id: vm.domain.id}, function(p_imageDto)
               		{
               			setPrintConfigImage((p_imageType == "BACKGROUND") ? "configImageBackground" : "configImageSignature", null);
               		});
				} 
			});
        };

        /*public*/ vm.updateImage = function(p_imageType)
        {
        	var url = URL[SERVICES.environment].api;
        	FileUploadModalInvoker.invoke(new FileUploadModalInvokerParam({
        		uploadUrl: url + "/api/users/update-records-print-config-image",
        		addExtraFormParam: function(p_json)
        		{
        			p_json.id = vm.domain.id;
        			p_json.type = p_imageType;
        		},
        		okCallback: function()
        		{
        			if(p_imageType == "BACKGROUND")
        			{
	            		User.getRecordsPrintConfigBackgroundImage({id: vm.domain.id}, function(p_imageDto)
	            		{
	            			setPrintConfigImage("configImageBackground", p_imageDto);
	            		});
        			}
        			else if(p_imageType == "SIGNATURE")
        			{
	            		User.getRecordsPrintConfigSignatureImage({id: vm.domain.id}, function(p_imageDto)
	            		{
	            			setPrintConfigImage("configImageSignature", p_imageDto);
	            		});
        			}
        		}
        	}));
        };
        
//        $timeout(function()
//        {
//        	vm.updateImage('BACKGROUND');
//        }, 1000);       

        /*public*/ vm.testPrintConfig = function()
        {
        	var url = URL[SERVICES.environment].api;
        	DownloadUtil.download(url + '/api/users/test-records-print-config-signature-image/' + vm.domain.id);
        }
		
		/*private*/ function setPrintConfigImage(p_imageAreaId, p_imageDto)
		{
			if(p_imageDto != null && p_imageDto.bytes != null)
			{
				$("#" + p_imageAreaId).html("<img src='data:" + p_imageDto.contentType + ";base64," + p_imageDto.bytes + "' />");
			}
			else
			{
				$("#" + p_imageAreaId).html("<div style='padding-top:30px;font-style:italic;'>" + $translate.instant("client.page.user.printConfig.noImage") + "</div>");
			}
			$("#" + p_imageAreaId).removeClass("loading");
		}

		vm.selectDay = function(day, index)
		{
			vm.daysWeek[index].status = !day.status;
			if ($filter('filter')(vm.daysWeek, {status: true}).length > 0) {
				vm.daysWeekIsValid = true;
			}
		};

		vm.clearTime = function(id)
		{
			if (id === 1) {
				vm.startTime = '';
			} else {
				vm.endTime = '';
			}
		};

		function parameterseSchedulesAvailable(edit) 
		{
			var objJson = [];
			angular.forEach(vm.daysWeek, function(item) {
				if (item.status) {
					objJson.push({ id: (edit ? item.id : null), idSchedulable: $stateParams.id, weekDay: item.number, startTime: vm.formSchedulesAvailable.startTime.$viewValue, endTime: vm.formSchedulesAvailable.endTime.$viewValue });
				}
			});

			return objJson;
		}

		function saveCallback()
        {
        	isSaving = false;
		};

		var timeStringToMin = function(time)
		{
			if (angular.isUndefined(time) || time === 0)
			{
				return 0;
			}
			else
			{
				var hoursMinutes = time.split(':');
				var hours = parseInt(hoursMinutes[0], 10);
				var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
				return hours * 60 + minutes;
			}
		};
		
		/**
		 * TODO_CODE: Reescrever: foi alterado para permitir sobreposicação de bordas e quebrou
		 */
		function validateSchedulesAvailable(day, startTime, endTime)
		{
			var auxList = _.filter(vm.listDaysWeek, function(e) {return e.weekDay === day; });
			var startTime = timeStringToMin(startTime);
			var endTime = timeStringToMin(endTime);
			
			var result = true;
			_.each(auxList, function(l){
				if (startTime < timeStringToMin(l.startTime) && endTime > timeStringToMin(l.startTime))
				{
					result = false;
				}
				else if (startTime > timeStringToMin(l.startTime) && endTime < timeStringToMin(l.endTime))
				{
					result = false;
				}
				else if (startTime < timeStringToMin(l.endTime) && endTime > timeStringToMin(l.endTime))
				{
					result = false;
				}
				else if (startTime === timeStringToMin(l.startTime) && endTime === timeStringToMin(l.endTime))
				{
					result = false;
				}
				else if (l.startTime === '00:00' && l.endTime === '00:00')
				{
					result = false;
				}
			});

			return result;
		};

		vm.saveSchedulesAvailable = function()
		{
			vm.daysWeekIsValid = true;
			if ($filter('filter')(vm.daysWeek, {status: true}).length === 0) {
				vm.daysWeekIsValid = false;
				return false;
			}

			vm.timeBigger = false;
			if (Date.parse('01/01/1970 ' + vm.formSchedulesAvailable.startTime.$viewValue + ':00') > Date.parse('01/01/1970 ' + vm.formSchedulesAvailable.endTime.$viewValue + ':00')) {
				vm.timeBigger = true;
				return false;
			}

			vm.timeConflict = false;
			angular.forEach(vm.daysWeek, function(item) {
				if (item.status) {
					if (!validateSchedulesAvailable(item.number, vm.formSchedulesAvailable.startTime.$viewValue, vm.formSchedulesAvailable.endTime.$viewValue)) {
						vm.timeConflict = true;
					}
				}
			});

			if (vm.timeConflict) {
				return false;
			}

        	if (vm.formSchedulesAvailable.$valid)
            {
				isSaving = true;
				var objJson = parameterseSchedulesAvailable(false);
				Attendance.refreshAvailabilitySchedule(objJson, 
					function(response) {
						angular.forEach(response, function(item, key) {
							objJson[key].id = item;
							vm.listDaysWeek.push(objJson[key]);
						});
						vm.daysWeek = initDaysWeek();
						vm.formSchedulesAvailable.$submitted = false;
						isSaving = false;
					}, 
					saveCallback);
			}
		};

		vm.deleteSchedulesRegistered = function(id, index) 
		{
			Attendance.delete({ id: id }, 
				function()
				{
					vm.listDaysWeek.splice(index, 1);
				}, 
				saveCallback);
		}

		vm.getSchedulesAvailable = function() 
		{
			Attendance.listAvailabilitySchedule({ id: $stateParams.id }, 
				function(response)
				{
					angular.forEach(response, function(item) {
						vm.listDaysWeek.push({ id: item.id, weekDay: item.weekDay, startTime: item.startTime, endTime: item.endTime });
					});
				});
		};

		if (vm.pageConfig.pageMode != 'CREATE') {
			vm.getSchedulesAvailable();
		}
    }
})();
