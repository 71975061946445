(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('SalesRequestEditModalController', SalesRequestEditModalController);

    SalesRequestEditModalController.$inject = ['$scope', 'pageConfig', '$uibModalInstance', '$rootScope', '$translate', '$timeout', 'attendanceOriginal', 'salesRequestOriginal', 'familyProcedureList', 'ClientMinManagerModalInvoker', 'account', 'Principal', 'anesthetistList', 'BackEndSelectDataSource', 'FrontEndSelectDataSource', 'FormDomain', 'FormConstants', 'UcAlertService', 'Attendance'];

    function SalesRequestEditModalController($scope, pageConfig, $uibModalInstance, $rootScope, $translate, $timeout, attendanceOriginal, salesRequestOriginal, familyProcedureList, ClientMinManagerModalInvoker, account, Principal, anesthetistList, BackEndSelectDataSource, FrontEndSelectDataSource, FormDomain, FormConstants, UcAlertService, Attendance)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        
        UcAlertService.setForceAlertType($scope);

        var entity = {
        	clientSR: [salesRequestOriginal.client],
        	familyProcedureSR: salesRequestOriginal.procedure.familyProcedure,
        	procedureSR: salesRequestOriginal.procedure,
        	sessionsQuantitySR: salesRequestOriginal.sessionsQuantity,
        	anesthetistOptionSR: attendanceOriginal.anesthetistOption,
        	assistantSR: attendanceOriginal.userAssistantId
        };
        
        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: null,
        	getForm: function(){ return $scope.editForm; },
        	afterUpdate: function() { $uibModalInstance.close(null); }
        };

        var familyProcedureFormField = new FormField({
       		cellCssClass: "col-sm-6",
       		type: "Select",
			id: "familyProcedureSR",
			dependencyName: "familyProcedureListId",
			required: true,
			label: "client.page.attendance.familyProcedure",
			dataSource: FrontEndSelectDataSource.create(familyProcedureList),
			onChange: function(p_objModel, p_fieldModel, p_value)
			{
				synchProcedureData(null, true);
			}
    	});

        vm.formHandler = FormDomain.create(formOptions)
			.add([familyProcedureFormField,
				new FormField({
					cellCssClass: "col-sm-6",
		       		type: "Select",
					id: "procedureSR",
					required: true,
					label: "client.page.attendance.procedure",
					searchActiveOnly: true,
					dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate, familyProcedureFormField, true),
					onChange: function(p_objModel, p_fieldModel, p_value)
					{
						refreshDynamicData(p_value);
						synchProcedureData(p_value, true);
					}
				})
		    ]);
        vm.formHandler
		    .add([new FormField({
					cellCssClass: "col-sm-6",
					type: "SelectModal",
					id: "clientSR",
					required: true,
					label: "client.page.attendance.client",
					modalConfig: {
						searchId: "Client",
						bindLabel: "name",
				        extraButtons: [{
				        	buttonClass: "btn btn-info",
				        	iconClass: "glyphicon glyphicon-plus",
				        	label: 'client.global.label.create',
				        	disabled: !Principal.hasAnyAuthority(['ROLE_CLIENTE_CRIACAO']),
				        	callback: function()
				        	{
				        		ClientMinManagerModalInvoker.invoke({
				        			account: account,
				        			callback: function(p_client)
				        			{
				        				$rootScope.$broadcast("search-modal.close.Client", [p_client]);
				        			}
				        		});
				        	}
				        }]				        
					}
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Long",
					id: "sessionsQuantitySR",
					readOnly: true,
					label: "client.page.attendance.sessionsQuantity"
				})			
		    ]);
        vm.formHandler
		    .add([new FormField({
		        	cellCssClass: "col-sm-6",
					type: "Select",
					id: "anesthetistOptionSR",
					readOnly: true,
					label: "common.domain.attendance.anesthetist",
					dataSource: FrontEndSelectDataSource.create(anesthetistList)
				}),
				new FormField({
		        	cellCssClass: "col-sm-6",
					type: "Select",
					id: "assistantSR",
					readOnly: true,
					label: "common.domain.attendance.assistant",
					dataSource: FormConstants.DataSources.BackEnd.Assistent(BackEndSelectDataSource, $translate)
				})
			])
			.endConfig();
        
        
        $timeout(function()
        {
        	synchProcedureData(salesRequestOriginal.procedure, false);
        }, 1);
        
        /**
         * Atualiza se ReadOnly/limpa valor de acodo com o procedimento selecionado
         */
        /*private*/ function synchProcedureData(p_explicitProcedure, p_clearField)
        {
        	$timeout(function()
        	{
        		var editAssistantFlag = false;
        		var editAnesthetistFlag = false;

				if(p_explicitProcedure)
				{
    				editAssistantFlag = p_explicitProcedure.hasAssistant;
    				editAnesthetistFlag = p_explicitProcedure.hasAnesthetist;
				}
        		
        		if(p_clearField && entity)
        		{
        			if(!editAssistantFlag)
        			{
        				entity["assistantSR"] = null;
        			}
        			if(!editAnesthetistFlag)
        			{
        				entity["anesthetistOptionSR"] = null;
        			}
        		}

        		$scope.$emit(FormConstants.Events.Runtime.ReadOnly("anesthetistOptionSR"), {readOnly: !editAnesthetistFlag});
        		$scope.$emit(FormConstants.Events.Runtime.ReadOnly("assistantSR"), {readOnly: !editAssistantFlag});
        	}, 10);
        };
        
        /**
         * Atualiza os campos sesionIndex e sessionsQuantity 
         */
    	/*private*/ function refreshDynamicData(p_currentProcedure)
    	{
    		// SessionsQuantity
    		{
    			var quantity;
	    		// Próximo, procedimento explicitamente selecionado
	    		if(p_currentProcedure)
	    		{
	    			quantity = p_currentProcedure.sessionsQuantity;
	    		}
	    		// Se nenhum valor encontrado, vazio
	    		else
	    		{
	    			quantity = null;
	    		}
	    		entity.sessionsQuantitySR = quantity;
    		}
    	};
    	
        /*public*/ vm.save = function()
        {
        	// Se for criação, usar o save padrão do form
    		vm.formHandler.save(function(p_json, p_onSaveSuccess, p_onSaveError)
    		{
                var param = {
                	anesthetistOptionId: p_json.anesthetistOptionSRId,
                	assistantId: p_json.assistantSRId,
                	clientId: p_json.clientSRId,
                	procedureId: p_json.procedureSRId,
                	originalAttendanceId: attendanceOriginal.id
            	};

                Attendance.updateSalesRequest(param, p_onSaveSuccess, p_onSaveError);
    		});
        };

		/**
		 * Cancel or top-icon close
		 */
         /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();