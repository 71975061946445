(function () {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ExpensePaymentOptionController', ExpensePaymentOptionController);

    ExpensePaymentOptionController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'ExpensePaymentOption', 'FrontEndSelectDataSource', 'FormConstants'];

    function ExpensePaymentOptionController(FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, ExpensePaymentOption, FrontEndSelectDataSource, FormConstants) {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;

        $rootScope.initialFocus("description");

        vm.buttonsModel =
            {
                stateRoot: "expensePaymentOption",
                onFilter: function () { doFilter(); },
                onClear: function () { doClear(); }
            };

        var formOptions = {
            formStatePersisterId: "ExpensePaymentOption"
            //        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
            .add([new FormField({
                cellCssClass: "col-sm-6",
                type: "String",
                id: "code",
                label: "common.domain.expensePaymentOption.code"
            }),
            new FormField({
                cellCssClass: "col-sm-6",
                type: "String",
                id: "description",
                label: "common.domain.expensePaymentOption.description"
            })])
            .add([
                new FormField({
                    cellCssClass: "col-sm-6",
                    type: "Select",
                    id: "active",
                    label: "common.domain.expensePaymentOption.active",
                    dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), { dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN })
                })])
            .endConfig();

        vm.gridFieldList = UcGridService.createFieldList([
            { headerName: $translate.instant("common.domain.expensePaymentOption.code"), field: "code", width: 20 },
            { headerName: $translate.instant("common.domain.expensePaymentOption.description"), field: "description", width: 35 },
            UcGridService.field.ACTIVE(),	// width: 5
        ]);

        var grid = UcGridService.create(
            {
                gridId: "EXPENSE_PAYMENT_OPTION",
                //        	permission: vm.pageConfig.editPermission,
                stateBaseName: "expensePaymentOption",
                account: account,
                getFilterData: function () { return vm.formHandler.readForm(); },
                gridFieldList: vm.gridFieldList,
                sort: vm.sort,
            });

        vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
            grid.refresh();
        };

        /*private*/ function doClear() {
            vm.formHandler.clear();
            doFilter();
        };
    }
})();
