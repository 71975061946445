(function() {
    'use strict';

	angular.module('sgmApp').service('ClientManagerModalInvoker', ClientManagerModalInvoker);
	
	ClientManagerModalInvoker.$inject = ['$uibModal', 'ClientCommon', '$timeout'];
	
	function ClientManagerModalInvoker($uibModal, ClientCommon, $timeout)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(/*ClientManagerModalInvokerParam*/ p_param)
		{
            $uibModal.open({
                templateUrl: "app/pages/managers/client/modal/client-manager-modal.template.html",
                controller: 'ClientManagerModalController',
                controllerAs: 'vm',
                size: 'lg',
                windowClass: 'modal-dialog-90-percent',
                resolve: ClientCommonStatic().extendResolve(
                {
                    entity: ['$stateParams', 'Client', function($stateParams, Client)
                    {
                        return Client.get({id : p_param.clientId}).$promise;
                    }],
                    pageConfig: ['$rootScope', 'PermissionSet', function($rootScope, PermissionSet)
                    {
                    	return new PageConfig({
                    		pageMode: p_param.pageMode,
                    		account: p_param.account,
    	                	title: 'client.page.client.title',
    	                	icon: $rootScope.menuIcons.main.client,
    	                	formValidateTarget: 'Client',
    	                	permission: PermissionSet.CLIENT
                    	});
                    }]
                })
            }).result.then(function(p_)
            {
            	// Se for reopen, trata aqui
            	if(p_ && p_.reopen)
            	{
            		$timeout(function()
            		{
            			vm.invoke(new ClientManagerModalInvokerParam(
            			{
        	        		clientId: p_.id,
        	        		account: p_.account,
        	        		pageMode: p_.edit ? "EDIT" : "READONLY",
        	        		callback: p_param.callback
            			}));
            		}, 1);
            	}
            },function()
            {
        		// Se tiver um callback definidi, o invoca
        		if(p_param.callback)
        		{
        			p_param.callback();
        		}
            });
		};
		
	};
})();


function ClientManagerModalInvokerParam(p_param)
{
	var _DEFAULT_PARAM =
	{
		account: null,
		clientId: null,
		pageMode: "READONLY",
		callback: null
	};
	
	// Carrega os valores default
	angular.extend(this, _DEFAULT_PARAM);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_param) ? p_param : {});
}

