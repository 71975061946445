(function(){
	'use strict';
	angular.module('sgmApp').controller('SalesRequestInvoiceCancelDialogController', SalesRequestInvoiceCancelDialogController);
	
	SalesRequestInvoiceCancelDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'SalesRequestInvoice', 'entity', 'listCancelCodes', 'FrontEndSelectDataSource'];
	
	
	function SalesRequestInvoiceCancelDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, SalesRequestInvoice, entity, listCancelCodes, FrontEndSelectDataSource){
		
		var vm = this;
	
		vm.pageConfig = {
			pageMode: 'CREATE'
		};
		console.log(entity);
		vm.entity = entity;
		vm.deleteForm = {
		};
		var formOptions = {
				model: vm.deleteForm,
				service: SalesRequestInvoice,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormSalesRequestInvoiceCancel; }
	        };
		
		vm.formHandler = FormDomain.create(formOptions)
			.add([
				new FormField({
					type: "Select",
					id: "cancelCode",
					hide: vm.entity.manual,
					required: !vm.entity.manual,
					dataSource: FrontEndSelectDataSource.create(listCancelCodes),
					cellCssClass: "col-sm-12",
					label: "common.domain.salesRequestInvoice.cancelCode",
				})
			])
			.add([
				new FormField({
					type: "TextArea",
					id: "cancelReason",
					required: true,
					cellCssClass: "col-sm-12",
					label: "common.domain.salesRequestInvoice.cancelReason",
				})
			]);
		
		/*public*/ vm.closeModal = function(salesRequestInvoice)
		{
			$uibModalInstance.close(salesRequestInvoice);
        };

        vm.onCancelSalesRequestInvoice = function(){
        	var domain = null;
			FormUtil.validateAll($scope.editFormSalesRequestInvoiceCancel, vm.formHandler.getRowList());
			console.log($scope.editFormSalesRequestInvoiceCancel);
			if($scope.editFormSalesRequestInvoiceCancel.$valid) {
				domain = vm.formHandler.readForm();
				console.log(domain);
				domain.id = vm.entity.id;
				SalesRequestInvoice.cancel(domain, (res) => {
					vm.closeModal(res)
				});
			}
        	
        }
	       
	}
})();
