(function()
{
    'use strict';

    angular
	    .module('sgmApp')
	    .service('StaticTooltipBoxHandler', StaticTooltipBoxHandler);

    StaticTooltipBoxHandler.$inject = [];
    function StaticTooltipBoxHandler()
    {
    	var vm = this;
    	
    	/*private*/ var map = {};
    	
    	/*public*/ vm.show = function(p_event, p_tooltipId)
    	{
   			hideTooltip(p_tooltipId);
   			showTooltip(p_event, p_tooltipId);
    	};
    	
    	/*public*/ vm.hide = function(p_tooltipId)
    	{
   			hideTooltip(p_tooltipId);
    	};
    	
    	/*private*/ function showTooltip(p_event, p_tooltipId)
    	{
    		setTooltipPosition(p_event, p_tooltipId);

    		$("#" + p_tooltipId).removeClass("display-none");
    		map[p_tooltipId] = true;
    	}

    	/*private*/ function hideTooltip(p_tooltipId)
    	{
    		// Esta ativo?
    		if(map[p_tooltipId])
    		{
    			$("#" + p_tooltipId).addClass("display-none");
    			delete map[p_tooltipId];
    		}
    	}
    	
    	/*private*/ function setTooltipPosition(p_event, p_tooltipId)
    	{
    		var x = (p_event.pageX && !isNaN(p_event.pageX)) ? p_event.pageX : null;
    		var y = (p_event.pageY && !isNaN(p_event.pageY)) ? p_event.pageY : null;
    		
    		if(x !== null && y !== null)
    		{
    			var top = y;    			
    			var left = x;
    			
    			// Considera meio width
    			left -= ($("#" + p_tooltipId).width() / 2);
    			top -= ($("#" + p_tooltipId).height() + 45);

    			if(!isNaN(top) && !isNaN(left))
    			{
	    			$("#" + p_tooltipId).css({
	    				top: top,
	    				left: left,
	    			});
    			}
    			return;
    		}
    		
    		// Fallback
			$("#" + p_tooltipId).css({
				top: 30,
				left: 30,
			});	
    	}
    };
})();