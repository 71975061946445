(function() {
	'use strict';

	angular
		.module('sgmApp')
		.controller('IndicationsManagerDirectiveController', IndicationsManagerDirectiveController);

	IndicationsManagerDirectiveController.$inject = ['$scope', '$translate', '$rootScope', 'FormConstants', '$state', '$stateParams', 'IndicationsManagerCommon', 'IndicationsManagerService', 'FormDomain', '$timeout', 'RecordsModalInvoker', 'FormFieldValidate', 'FrontEndSelectDataSource'];
	function IndicationsManagerDirectiveController($scope, $translate, $rootScope, FormConstants, $state, $stateParams, IndicationsManagerCommon, IndicationsManagerService, FormDomain, $timeout, RecordsModalInvoker, FormFieldValidate, FrontEndSelectDataSource) {
		var vm = this;

		vm.domain = $scope.domain;
		vm.pageConfig = $scope.pageConfig;
		vm.injectedResources = $scope.injectedResources;

		vm.tabList = IndicationsManagerCommon.tabList;

		var source = $scope.source;


		// Apenas para o modal
		var uibModalInstance = $scope.uibModalInstance;

		vm.tabHandler = {
			active: 'indications',
			disabledMap: {},
			isActive: function(p_code) {
				return (p_code === vm.tabHandler.active);
			},
			isDisabled: function(p_code) {
				//				if ((p_code == "photo" || p_code == "summary") && !vm.domain.id) {
				//					return true;
				//				}
				return false;
			},
			isShow: function(p_code) {
				return true;
			},
			select: function(p_id) {
				if (vm.tabHandler.isDisabled(p_id)) {
					return;
				}

				$state.go('.', { activeTab: p_id }, { notify: false });
				vm.tabHandler.active = p_id;

			}
		};

		var tabErrorListner = $scope.$on(FormConstants.Events.Runtime.ShowTabError, function(p_, p_arg) {
			if (p_arg) {
				vm.tabHandler.select(p_arg);
			}
		});
		$scope.$on('$destroy', function() {
			if (angular.isDefined(tabErrorListner) && tabErrorListner !== null) {
				tabErrorListner();
			}
		});

		//		if (!$stateParams.activeTab) {
		//			vm.tabHandler.select("personal");
		//		}

		/**********************************************************/

		var formOptions = {
			model: vm.domain,
			pageConfig: vm.pageConfig,
			buttonsModel: null,
			service: IndicationsManagerService,
			afterCreateState: "indicationsManager.detail",
			showTabWithError: true,
			// historyConfig: { historyId: "INDICATIONS_MANAGER" },
			afterUpdate: function() { doAfterUpdate(); },
			getForm: function() { return $scope.editForm; }
		};

		vm.formHandler = FormDomain.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					label: "common.domain.indicationsManager.id",
                    required: true,
                    readOnly: true,
                    addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Select",
					id: "indication",
					label: "common.domain.indicationsManager.indicationDescription",
					required: true,
					dataSource: FrontEndSelectDataSource.create(vm.injectedResources.indicationsList)
				})

			])
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "indicatorName",
					label: "common.domain.indicationsManager.indicatorName",
					required: true,
				}), new FormField({
					cellCssClass: "col-sm-6",
					type: "Phone",
					id: "indicatorPhone",
					label: "common.domain.indicationsManager.indicatorPhone",
					required: false,
					addonCssClass: "glyphicon glyphicon-phone"
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "indicatorAddress",
					label: "common.domain.indicationsManager.indicatorAddress",
					required: false,
				}), new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "indicatorEmail",
					label: "common.domain.indicationsManager.indicatorEmail",
					required: false,
				}),
			])
			.endConfig();

        /*public*/ vm.save = function() {
			vm.formHandler.save();
		};

	    /*private*/ function createModalButtonList(p_modal) {
			var pageMode = vm.pageConfig.pageMode;

			var buttonList = [];

			if (p_modal) {
				if (pageMode === 'READONLY') {
					buttonList.push(createEditButton(), createCloseButton());
					handleRecordsButton(buttonList, pageMode);
				}
				else if (pageMode === 'EDIT') {
					buttonList.push(createSaveButton(), createCancelButton(), createCloseButton());
					handleRecordsButton(buttonList, pageMode);
				}
			}
			else {
				handleRecordsButton(buttonList, pageMode);
			}
			return buttonList;
		};

	    /*private*/ function handleRecordsButton(p_buttonList, p_pageMode) {
			if (p_pageMode === 'READONLY' || p_pageMode === 'EDIT') {
				if (vm.injectedResources.isToShowRecordsButton && vm.injectedResources.isToShowRecordsButton.value) {
					p_buttonList.push(createRecordsButton());
				}
			}
		}

	    /*private*/ function createEditButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'glyphicon glyphicon-pencil',
				label: 'indications.global.label.edit',
				type: 'button',
				isDisabled: function() { return /*checar permissões*/ false; },
				isToAdd: function() { return true },
				click: function() {
					closeAndReopen(true);
				}
			}
		};

	    /*private*/ function createCloseButton() {
			return {
				order: 100,
				cssClass: 'btn btn-default',
				cssClassIcon: 'fa fa-times',
				label: 'indications.global.label.close',
				type: 'button',
				isDisabled: function() { return false; },
				isToAdd: function() { return true; },
				click: function() {
					uibModalInstance.dismiss();
				}
			}
		};

	    /*private*/ function createCancelButton() {
			return {
				order: 100,
				cssClass: 'btn btn-accent',
				cssClassIcon: 'fa fa-undo',
				label: 'indications.global.label.cancel',
				type: 'button',
				isDisabled: function() { return false; },
				isToAdd: function() { return true; },
				click: function() {
					closeAndReopen(false);
				}
			};
		};

	    /*private*/ function createSaveButton() {
			return {
				order: 0,
				cssClass: 'btn btn-success',
				cssClassIcon: 'glyphicon glyphicon-save',
				label: 'indications.global.label.save',
				type: 'submit',
				isDisabled: function() { return (/*checar permissões || */ vm.buttonsModel.isSaving); },
				isToAdd: function() { return true; },
				click: function() { /* void - por ser submit, a ação está no ng-submit do form */ }
			};
		};

	    /*private*/ function createRecordsButton() {
			return {
				order: 99,
				cssClass: 'btn btn-info',
				cssClassIcon: 'fa fa fa-user-md',
				label: 'indications.page.attendance.records',
				type: 'button',
				isToAdd: function() { return true },
				click: function() {
					RecordsModalInvoker.invoke({
						indications: vm.domain,
						attendanceId: null
					});
				}
			}
		}

	    /*private*/ function closeAndReopen(p_edit) {
			uibModalInstance.close({
				id: vm.domain.id,
				account: vm.pageConfig.account,
				reopen: true,
				edit: p_edit
			});
		}

		// O comportamento dos botões variam de acordo com o source
		if (source == "EDIT_PAGE") {
			vm.buttonsModel = {
				isSaving: false,
				gridId: "INDICATIONS_MANAGER",
				previousState: { name: "indicationsManager" },
				stateRoot: "indicationsManager",
				extraButtonList: createModalButtonList(false)
			};
		}
		else if (source == "MODAL") {
			vm.buttonsModel = {
				isSaving: false,
				useSuggestedButton: false,
				stateRoot: "indicationsManager",
				extraButtonList: createModalButtonList(true)
			};
		}
		else {
			alert("Config param source not set")
		}

	    /*private*/ function doAfterUpdate() {
//			vm.refreshSignal();
		}

		$timeout(function() {
			//			vm.refreshSignal();
			//			synchSpecialNeedsDescriptionField()
			//			synchForeignIndicationsFields()
		}, 1);

		// Implementar na aba "notes"
		/*public*/ /*abstract*/ /*vm.refreshSignal()*/

	};
})();
