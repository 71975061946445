(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsModalController', RecordsModalController);

    RecordsModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'pageConfig',
        'client',
        'attendanceId',
        'RecordsEditModalInvoker',
        'RecordsAttachmentModalInvoker',
        'Records',
        '$timeout',
        'RecordsPrintModalInvoker',
        '$translate',
        '$rootScope',
        'DownloadUtil',
        'URL',
        'SERVICES',
        'RecordsEditHistoryModalInvoker',
        '$filter',
        'Principal',
        'PermissionSet',
        'attendanceProcedureId',
        'ProntuaryAttendance',
        'ProntuaryModel',
        'FormFilter',
        'ProntuaryFormDialogInvoker',
        'FormConstants',
        'BackEndSelectDataSource',
        'PrintProntuaryFormDialogInvoker',
        'prontuaryTabList',
        'productsAttendances',
        'PrintAttendanceProductDialogInvoker'
    ];

    function RecordsModalController(
        $scope,
        $uibModalInstance,
        pageConfig,
        client,
        attendanceId,
        RecordsEditModalInvoker,
        RecordsAttachmentModalInvoker,
        Records,
        $timeout,
        RecordsPrintModalInvoker,
        $translate,
        $rootScope,
        DownloadUtil,
        URL,
        SERVICES,
        RecordsEditHistoryModalInvoker,
        $filter,
        Principal,
        PermissionSet,
        attendanceProcedureId,
        ProntuaryAttendance,
        ProntuaryModel,
        FormFilter,
        ProntuaryFormDialogInvoker,
        FormConstants,
        BackEndSelectDataSource,
        PrintProntuaryFormDialogInvoker,
        prontuaryTabList,
        productsAttendances,
        PrintAttendanceProductDialogInvoker
    )
    {
        var vm = this;
        var attendanceProcedureId = attendanceProcedureId;
        vm.pageConfig = pageConfig;
        vm.productsAttendances = productsAttendances;
        vm.currentType = 'RECORDS';
        vm.selectedTabId = 'MEDICAL';
        vm.account = null;
        console.log(productsAttendances);
        vm.client = client;
        vm.prontuaries = [];
        vm.prontuaryTabList = prontuaryTabList;
        vm.prontuaryFormConfig = {
			attendanceId: attendanceId,
			pageConfig: pageConfig,
            prontuary: null,
            hideAttendanceButtons: attendanceId != null
		};
        vm.prontuaryTabList = prontuaryTabList;
        vm.attendanceId = attendanceId;
        vm.selectedTabModel = null;
        vm.filterPageConfig = {
            pageMode: "FILTER",
        }
        vm.filterButtonsModel =
        {
            onFilter: function () { getProntuaryAttendances(); },
            onClear: function () { vm.clearAndSearch(); },
        };

        if(vm.attendanceId) {
            vm.prontuaryFormConfig.onDeleteProntuaryAttendance = function() {
                var index = vm.prontuaries.findIndex(obj => obj.id == vm.prontuaryFormConfig.prontuary.id);
                vm.prontuaries.splice(index, 1);
                vm.prontuaryFormConfig.prontuary = null;
                vm.currentType = 'RECORDS';
            }
            
        var formOptions = {
            formStatePersisterId: "ProntuaryAttendanceRecordsFilter",
            internalId: "ProntuaryAttendanceRecordsFilter"
                //        	checkFormChange: function() { doFilter(); }
            };
        }

        vm.filterFormHandler = FormFilter.create(formOptions)
        .add([
            new FormField({
                cellCssClass: "col-sm-2",
                type: "Long",
                id: "id",
                label: "common.domain.prontuaryAttendance.id"
            }),
            new FormField({
                cellCssClass: "col-sm-2",
                type: "Long",
                id: "attendanceId",
                label: "common.domain.prontuaryAttendance.attendance"
            }),	
            new FormField({
                cellCssClass: "col-sm-2",
                type: "Select",
                id: "procedure",
                label: "common.domain.prontuaryAttendance.procedure",
                multiple: true,
                searchActiveOnly: true,
                dataSource: FormConstants.DataSources.BackEnd.Procedure(BackEndSelectDataSource, $translate)
            }),
            new FormField({
                cellCssClass: "col-sm-2",
                type: "Date",
                id: "attendancePlannedDateStart",
                label: "client.page.attendance.plannedDateStart",
                jsonValTransform: function (p_json, p_model, p_val)
                {
                    if(p_val)
                    {
                        p_val.setHours(0);
                        p_val.setMinutes(0);
                        p_val.setSeconds(0);
                        p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
                    }
                }
            }),
            new FormField({
                cellCssClass: "col-sm-2",
                type: "Date",
                id: "attendancePlannedDateEnd",
                label: "client.page.attendance.plannedDateEnd",
                jsonValTransform: function (p_json, p_model, p_val)
                {
                    if(p_val)
                    {
                        p_val.setHours(23);
                        p_val.setMinutes(59);
                        p_val.setSeconds(59);
                        p_json[p_model.id] = moment(p_val).format("YYYY-MM-DD HH:mm:ss");
                    }
                }
            }),
        ])
        .endConfig();


        /*public*/ vm.showRecordsLoading = true;
        /*public*/ vm.recordsList = [];

        /*public*/ vm.tabList = [];
        /*public*/ vm.selectedType = null;

        /*private*/ var clientRecords = null;

        $timeout(function()
        {
        	reloadClientRecords();
            if(Principal.hasAnyAuthority([PermissionSet.PRONTUARY_ATTENDANCE.access])) {
                getProntuaryModels();
            }
        }, 100);


        /*public*/ vm.create = function()
        {
            if (vm.canUserEdit()) {
                RecordsEditModalInvoker.invoke({
                    recordType: vm.selectedType,
                    client: client,
                    attendanceId: attendanceId,
                    callback: function()
                    {
                        reloadClientRecords();
                    }
                });
            }
        }

        /*public*/ vm.addAttach = function()
        {
            if (vm.canUserEdit()) {
                RecordsAttachmentModalInvoker.invoke({
                    recordType: vm.selectedType,
                    client: client,
                    attendanceId: attendanceId,
                    callback: function()
                    {
                        reloadClientRecords();
                    }
                });
            }
        }

        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };

        /*public*/ vm.clickTab = function(p_tab)
        {
        	vm.selectedType = p_tab;
            vm.currentType = 'RECORDS';
        	refresh();
        }

        /*public*/ vm.clickProductsTab = function()
        {
            vm.currentType = 'PRODUCTS';
        }
    
         /*public*/ vm.clickProntuaryTab = function(prontuary)
         {
            vm.currentType = 'PRONTUARY';
            vm.selectedTabModel = prontuary;
            if(vm.selectedTabModel != null) {
                $timeout(function()
                {
                    getProntuaryAttendances();
                }, 100);
            }
            
        }

        vm.printProductsAttendance = function(attendance) {
            if(vm.account) {
                PrintAttendanceProductDialogInvoker.invoke({
                    attendances: attendance,
                    accountName: vm.account.name
                });
            } else {
                Principal.identity().then(account => {
                    vm.account = account;
                    PrintAttendanceProductDialogInvoker.invoke({
                        attendances: attendance,
                        accountName: vm.account.name
                    });
                });
            }
            
        }


        /*public*/ vm.resolveAttendanceDate = function(p_record)
        {
        	if(p_record.attendance)
        	{
        		return $filter('date')(p_record.attendance.plannedDate, "dd/MM/yyyy HH:mm");
        	}
        	else
        	{
        		return "-";
        	}
        }
        

        /*public*/ vm.resolveMedicName = function(p_record)
        {
        	// Preferencia para a data do Atendimento
        	if(p_record.attendance && p_record.attendance.userMedicId)
        	{
        		return p_record.attendance.userMedicId.name
        	}
        	// // Segunda opção, "dono do registro"
        	// else if(p_record.ownerUser)
        	// {
        	// 	return p_record.ownerUser.name;
        	// }
        	else
    		{
        		return "-";
    		}
        }

        /*public*/ vm.resolveUserCreator = function(p_record)
        {
            // Preferencia para a data do Atendimento
            if(p_record.ownerUser)
            {
                return p_record.ownerUser.name;
            }
            else
            {
                return "-";
            }
        }

        /*public*/ vm.resolveSignUser = function(p_record)
        {
            // Preferencia para a data do Atendimento
            if(p_record.signUser)
            {
                return p_record.signUser.name;
            }
            else
            {
                return "-";
            }
        }

        vm.resolveHTML = function(p_record) {
            var html = p_record.content.contentResolvedHtml;
            if(p_record.signUser && p_record.signUser.signatureImage) {
                html += "<br><b>Assinatura</b><br>"
				+"<img style='max-height: 80px' src='data:" 
				+ p_record.signUser.signatureImage.contentType + ";base64," 
				+ p_record.signUser.signatureImage.bytes 
				+ "' />";
            }
            return $rootScope.toTrustedHtml(html);
        }

        /*public*/ vm.resolveFamilyProcedure = function (p_record)
        {
            if (p_record.attendance && p_record.attendance.salesRequest && p_record.attendance.salesRequest.procedure && p_record.attendance.salesRequest.procedure.familyProcedure)
            {
                return p_record.attendance.salesRequest.procedure.familyProcedure.defaultDescription;
            }
            else {
                return "-";
            }
        }

        /*public*/ vm.resolveProcedure = function (p_record)
        {
            if (p_record.attendance && p_record.attendance.salesRequest && p_record.attendance.salesRequest.procedure && p_record.attendance.salesRequest.procedure)
            {
                return p_record.attendance.salesRequest.procedure.defaultDescription;
            }
            else {
                return "-";
            }
        }

        /*public*/ vm.countItems = function(p_tab)
        {
        	if(clientRecords && clientRecords.list)
        	{
	        	for (var i = 0; i < clientRecords.list.length; i++)
	        	{
					if(clientRecords.list[i].type.id == p_tab.id)
					{
						return (clientRecords.list[i].list.length) ? clientRecords.list[i].list.length : 0;
					}
				}
        	}
        	return 0;
        }

        /*public*/ vm.sign = function(p_record)
        {
            RecordsPrintModalInvoker.invoke({
        		clientName: (vm.client) ? vm.client.name : "-",
        		record: p_record,
        		recordType: vm.selectedType,
                signCallback: function(credentials) {doSign(p_record, credentials)}
        	});
            
        }

        function doSign(p_record, credentials) {
            Object.assign(credentials, {id: p_record.id});
            Records.sign(credentials, function(record) {
                p_record.signUser = record.signUser;
                p_record.signDate = record.signDate;
            });
        }
        /*public*/ vm.print = function(p_record)
        {
        	RecordsPrintModalInvoker.invoke({
        		clientName: (vm.client) ? vm.client.name : "-",
        		record: p_record,
        		recordType: vm.selectedType
        	});
		}
        

		/*public*/ vm.printAll = function()
        {
            debugger
            if(vm.currentType == 'PRONTUARY') {
                PrintProntuaryFormDialogInvoker.invoke({
                    clientName: (vm.client) ? vm.client.name : "-",
                    prontuaries: vm.prontuaries
                });
            } else if(vm.currentType == 'PRODUCTS') {
                vm.printProductsAttendance(productsAttendances);
            } else {
                var recordListHeaders = buildRecordListHeadersToPrint();
                RecordsPrintModalInvoker.invoke({
                    clientName: (client) ? client.name : "-",
                    record: null,
                    recordList: vm.recordsList,
                    recordType: vm.selectedType,
                    recordListHeaders: recordListHeaders
                });
            }

		}

        /*public*/ vm.export_ = function(p_record)
        {
        	var url = URL[SERVICES.environment].api;
        	DownloadUtil.download(url + '/api/records/export-document/' + p_record.id);
        }

        /*public*/ vm.generateEditRecordButtonTitle = function(p_records, p_delete)
        {
        	// Só o dono pode editar
        	if(!p_records.owner)
        	{
        		return $translate.instant("client.page.records.main.onlyOwnerCanEditDeleteItem");
        	}

        	if(p_records.inEdition)
        	{
        		return $translate.instant((p_delete) ? "client.page.records.main.itemCanBeDeletedUntilP0" : "client.page.records.main.itemCanBeEditedUntilP0", {"date": p_records.finishedEditDateFormatted});
        	}
        	else
        	{
        		return $translate.instant((p_delete) ? "client.page.records.main.deletedFinishedAtP0" : "client.page.records.main.editedFinishedAtP0", {"date": p_records.finishedEditDateFormatted});
        	}
        }

        /*public*/ vm.canUserEdit = function(tab, isAccess)
        {
            tab = tab || vm.selectedType;
            var permission = 'edit';
            if(isAccess) {
                permission = 'access';
            }
            if (tab) {
                switch (tab.id) {
                    case "NOT":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.NOTES[permission]]);
                    case "NAS":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.NOTES_ASSISTENT[permission]]);
                    case "EXS":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.EXAM_REQUEST[permission]]);
                    case "EXR":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.EXAM_RESULT[permission]]);
                    case "PRE":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.PRESCRIPTION[permission]]);
                    case "EMA":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.EMAIL_CONTACT[permission]]);
                    case "ORC":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.BUDGET[permission]]);
                    case "FOT":
                        return Principal.hasAnyAuthority([PermissionSet.RECORDS.PHOTOS[permission]]);
                }
            }
            return false;
        }

        /*public*/ vm.editButtonEnabled = function(p_records)
        {
            vm.canUserEdit();
        	// Somente em edição e para e o dono
        	if(!p_records.inEdition || !p_records.owner || !vm.canUserEdit())
        	{
        		return false;
        	}
        	else
        	{
        		return true;
        	}
        }

        /*public*/ vm.editRecord = function(p_record)
        {
        	RecordsEditModalInvoker.invoke({
        		id: p_record.id,
        		recordType: vm.selectedType,
        		client: client,
        		attendanceId: attendanceId,
        		callback: function()
        		{
        			reloadClientRecords();
        		}
        	});
        }

        /*public*/ vm.editRecordDoubleClick = function(p_record)
        {
        	if(vm.editButtonEnabled(p_record))
        	{
        		vm.editRecord(p_record);
        	}
        }

        /*public*/ vm.deleteRecord = function(p_record)
        {
			$rootScope.confirm(
			{
				message: $translate.instant("client.page.records.main.deleteRecordConfirmation"),
				callback: function()
				{
					Records.delete(
					{
						id: p_record.id
					},
					function()
					{
						reloadClientRecords();
					});
				}
			});
        }

        /*public*/ vm.openHistory = function(p_records)
        {
    		RecordsEditHistoryModalInvoker.invoke(
    		{
    			id: p_records.id
    		});
        }

        /*public*/ vm.download = function(p_records)
        {
        	var url = URL[SERVICES.environment].api;
			if(p_records.content.fileLink){
				window.open(p_records.content.fileLink, '_blank');
			}else{
				DownloadUtil.download(url + "api/records/download/" + p_records.id);
			}
		}

		/*private*/ function buildRecordListHeadersToPrint()
		{
            var recordListHeaders = [];
            var medicLabel = $translate.instant('client.global.label.medic');
            var recordDateLabel = $translate.instant('client.page.records.main.recordDate');
            var attendanceDateLabel = $translate.instant('client.page.records.main.attendanceDate');
            var procedureFamilyLabel = $translate.instant('client.global.label.family');
            var procedureLabel = $translate.instant('client.global.label.procedure');
            var header = "";
            vm.recordsList.forEach(function (record) {
                header += medicLabel + ':  ' + vm.resolveMedicName(record) + '<br>';
                header += recordDateLabel + ':  ' + $filter('date')(record.recordDate,'dd/MM/yyyy HH:mm') + '<br>';
                header += attendanceDateLabel + ':  ' + vm.resolveAttendanceDate(record) + '<br>';
                header += procedureFamilyLabel + ':  ' + vm.resolveFamilyProcedure(record) + '<br>';
                header += procedureLabel + ':  ' + vm.resolveProcedure(record);
                recordListHeaders.push(header);
                header = "";
            });

			return recordListHeaders;
		}

        /*private*/ function reloadClientRecords()
        {
            vm.showRecordsLoading = true;
            vm.recordsList = [];
            vm.tabList = [];

            Records.getClientRecords({clientId: client.id}, function(p_clientRecords)
            {
            	clientRecords = p_clientRecords;

            	if(p_clientRecords && p_clientRecords.list)
            	{
            		// Se o selecionado for nulo, seleciona o primeiro
            		if(vm.selectedType == null)
            		{
            			vm.selectedType = p_clientRecords.list[0].type;
            		}


    	        	for (var i = 0; i < clientRecords.list.length; i++)
    	        	{
    	        		vm.tabList.push(p_clientRecords.list[i].type);
    	        	}
            	}

                refresh();
                vm.showRecordsLoading = false;
            });
        };

        vm.changeTab = function(tab) {
            vm.selectedTabId = tab.id;
            if(vm.currentType !== 'PRODUCTS') {
                if(vm.selectedTabId == 'MEDICAL') {
                    vm.clickTab(vm.tabList[0]);
                } else {
                    var model = vm.prontuaryModels.find((obj) => obj.prontuaryTab.id == vm.selectedTabId);
                    vm.clickProntuaryTab(model);
                }
            }
            
        }

        

        function getProntuaryModels() {
            var filter = {};
            vm.prontuaryModels = [];
            vm.showRecordsLoading = true;
            if(vm.attendanceId) {
                console.log(client);
                filter['procedureId'] = attendanceProcedureId;
                filter['checkInProcedureId'] = attendanceProcedureId; 
                filter['attendanceClientId'] = client.id;
            }
            ProntuaryModel.fetchAll(filter, function(prontuaryModels) {
                for(var i = 0; i < prontuaryModels.length; i++) {
                    var prontuaryModel = prontuaryModels[i];
                    prontuaryModel.prontuaryType = 'MODEL';
                }
                vm.prontuaryModels = prontuaryModels;
                vm.showRecordsLoading = false;
            })
        }

        /*private*/ function getProntuaryAttendances() {
            var filter = vm.filterFormHandler.readForm();
            filter['clientId'] = client.id;
            filter['prontuaryModelId'] = vm.selectedTabModel.id;
            vm.showRecordsLoading = true;
            ProntuaryAttendance.fetchAll(filter, function(prontuaryAttendances)
            {
                console.log(prontuaryAttendances);
                for(var i = 0; i < prontuaryAttendances.length; i++) {
                    var prontuaryAttendance = prontuaryAttendances[i];
                    prontuaryAttendance.prontuaryType = 'ATTENDANCE';
                }
                vm.prontuaries = prontuaryAttendances;
                vm.showRecordsLoading = false;
            });
        };

        /*private*/ vm.clearAndSearch = function() {
            vm.filterFormHandler.clear();
            getProntuaryAttendances();
        };

        vm.canUserEditProntuaryAttendance = function() {
            return Principal.hasAnyAuthority([PermissionSet.PRONTUARY_ATTENDANCE.edit, PermissionSet.PRONTUARY_ATTENDANCE.create]);
        }

        vm.createProntuary = function() {
            var prontuaryFormConfig = {
                isAttendance: true,
                pageMode: 'EDIT',
                prontuary: vm.selectedTabModel,
                attendanceId: vm.attendanceId,
                client: client
            }
            
            ProntuaryFormDialogInvoker.invoke(
                {
                    prontuaryFormConfig: prontuaryFormConfig,
                    callback: function() {
                        getProntuaryAttendances();
                    }
                }
            )
        }

        vm.viewProntuary = function(prontuaryAttendance) {
            var prontuaryFormConfig = {
                isAttendance: true,
                client: client,
                pageMode: vm.canUserEditProntuaryAttendance() && (vm.selectedTabModel.active  && !vm.selectedTabModel.notInProcedure && prontuaryAttendance.editable) ? 'EDIT' : 'READONLY',
                prontuary: prontuaryAttendance
            }
            
            ProntuaryFormDialogInvoker.invoke(
                {
                    prontuaryFormConfig: prontuaryFormConfig,
                    callback: function() {
                        getProntuaryAttendances();
                    }
                }
            )
        }

        /*private function reloadProntuaries()
        {
            vm.showRecordsLoading = true;
            vm.prontuaries = [];
            
            ProntuaryAttendance.fetchAll({
                clientId: client.id, 
                attendanceId: attendanceId, 
                checkInProcedure: true
            }, function(prontuaryAttendances)
            {
                console.log(prontuaryAttendances)
                var prontuaryModelIds = [];

                for(var i = 0; i < prontuaryAttendances.length; i++) {
                    var prontuaryAttendance = prontuaryAttendances[i];
                    prontuaryAttendance.prontuaryType = 'ATTENDANCE';
                    prontuaryModelIds.push(prontuaryAttendance.prontuaryModelIdFk);
                    if(prontuaryAttendance.prontuaryItems) {
                        for(var j = 0; j < prontuaryAttendance.prontuaryItems.length; j++) {
                            var item = prontuaryAttendance.prontuaryItems[j];
                            if(item.itemIsNotUpdated) {
                                prontuaryAttendance.hasItemNotUpdated = true;
                                break;
                            }
                        }
                    }
                    
                    vm.prontuaries.push(prontuaryAttendance);
                    
                }
                if(attendanceId) {
                    ProntuaryModel.fetchAll({
                        procedureId: attendanceProcedureId,
                        idNotIn: serializeList(prontuaryModelIds)
                    }, function(prontuaryModels) {
                        console.log(prontuaryModels)
                        for(var i = 0; i < prontuaryModels.length; i++) {
                            var prontuaryModel = prontuaryModels[i];
                            prontuaryModel.prontuaryType = 'MODEL';
                            vm.prontuaries.push(prontuaryModel);
                        }
    
                        vm.showRecordsLoading = false;
    
                    })
                } else {
                    vm.showRecordsLoading = false;
                }
                
                
            });
        };*/



/*
         vm.prontuaryFormConfig.saveProntuary = function()
         {
					
            var p_json = vm.prontuaryFormConfig.parseJson(vm.prontuaryFormConfig.prontuary);
            p_json.attendanceIdFk = attendanceId;
            console.log(p_json);

            if(vm.prontuaryFormConfig.prontuary.prontuaryType == 'ATTENDANCE') {
                ProntuaryAttendance.update(p_json, function(res, putResponseHeaders) {
                    console.log(res);
                    Object.assign(vm.prontuaryFormConfig.prontuary, res);
                });
            } else {
                ProntuaryAttendance.save(p_json, function(res, putResponseHeaders) {
                    console.log(res);
                    Object.assign(vm.prontuaryFormConfig.prontuary, res);
                });
            }
				
        };

        function callbackDeleteProntuary() {
            
            ProntuaryAttendance.delete({id: vm.prontuaryFormConfig.prontuary.id}, function(res, putResponseHeaders) {
                var index = vm.prontuaries.findIndex(obj => obj.id == vm.prontuaryFormConfig.prontuary.id);
                vm.prontuaries.splice(index, 1);
                vm.prontuaryFormConfig.prontuary = null;
                vm.showingProntuary = false;
            })
            
        }
        
        vm.prontuaryFormConfig.deleteProntuary = function() {
            ConfirmDialogInvoker.invoke(
                {
                    callback: function(res) { 
                        if(res) {
                            callbackDeleteProntuary();
                        } 
                    }, 
                    text: $translate.instant("client.page.records.prontuaries.deleteConfirmationMessage")
                }
            );
        }
        */
        /*private*/ function refresh()
        {
        	vm.recordsList = [];
        	if(vm.selectedType && clientRecords && clientRecords.list)
        	{
	        	for (var i = 0; i < clientRecords.list.length; i++)
	        	{
					if(clientRecords.list[i].type.id == vm.selectedType.id)
					{
						vm.recordsList = clientRecords.list[i].list;
						break;
					}
				}
//	        	vm.print(vm.recordsList[0]);
        	}
        }
	}
})();
