(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ScheduleAttendanceDialogController', ScheduleAttendanceDialogController);

    ScheduleAttendanceDialogController.$inject = ['schedulableList', '$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', 'Attendance', '$rootScope', '$filter', 'dataSchedule', 'uiCalendarConfig', '$document', 'UcAlertService', '$timeout', '$compile', 'StaticTooltipBoxHandler', '$state', 'rescheduleAttendance', 'attendanceStatusList', 'AgendaLockingDialogInvoker', 'AgendaLockingTime', 'AgendaReminder', 'AgendaReminderDialogInvoker', 'hasAttendancePermissions'];

    function ScheduleAttendanceDialogController(schedulableList, $scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, Attendance, $rootScope, $filter, dataSchedule, uiCalendarConfig, $document, UcAlertService, $timeout, $compile, StaticTooltipBoxHandler, $state, rescheduleAttendance, attendanceStatusList, AgendaLockingDialogInvoker, AgendaLockingTime, AgendaReminder, AgendaReminderDialogInvoker, hasAttendancePermissions)
    {
    	$timeout(function()
    	{
//    		$("button.fc-basicWeek-button").html("Semana Básico")
//    		$("button.fc-basicDay-button").html("Dia Básico")
    		
//    		vm.changeResource("Carolina Lamounier", "10006")
    	} , 1000);
    	
		var vm = this;
		vm.showScheduling = false;
		vm.dataSchedule = dataSchedule;
		vm.editable = true;
		var startOfMonth = moment().startOf('month');
		
		vm.listMonths = [
			{value: 0, name: "months.january"},
			{value: 1, name: "months.february"},
			{value: 2, name: "months.mars"},
			{value: 3, name: "months.april"},
			{value: 4, name: "months.may"},
			{value: 5, name: "months.june"},
			{value: 6, name: "months.july"},
			{value: 7, name: "months.august"},
			{value: 8, name: "months.september"},
			{value: 9, name: "months.october"},
			{value: 10, name: "months.november"},
			{value: 11, name: "months.december"},
		]
		vm.listYears = [];
		for(let i = 2017; i <= startOfMonth.year() + 2; i++) {
			vm.listYears.push(i);
		}
		vm.selectedCalendarYear = i;
		vm.selectedCalendarMonth = vm.listMonths[startOfMonth.month()];
		vm.listBlockSchedule = [];
		vm.listReminderSchedule = [];
		vm.changeCalendarMonthYear = function() {
			if(uiCalendarConfig.calendars && uiCalendarConfig.calendars.myCalendar) {
				let date = $('.schedule-calendar').fullCalendar('getDate');
				date.set('month', vm.selectedCalendarMonth.value);
				date.set('year',  vm.selectedCalendarYear);
				uiCalendarConfig.calendars.myCalendar.fullCalendar('gotoDate', date);
			}
		}
		/**
		 * Exibe a lista de Horários disponíveis
		 */
		/*public*/ vm.displayListDayWeek = [];
		
		var timeZone = (moment.tz('America/Sao_Paulo')._offset/60)*-1;
		
		/*public*/ vm.legendList = [
			{
				id: "UNAVAILABLE",
				circleCssClass: "circle-daysUnavailable",
				label: 'client.page.attendance.legend.daysUnavailable'
			},
			{
				id: "BLOCKS",
				circleCssClass: "circle-schedulesBlocks",
				label: 'client.page.attendance.legend.schedulesBlocks'
			}
		];
		for (var i = 0; i < attendanceStatusList.length; i++)
		{
			if(attendanceStatusList[i].activeStatus)
			{
				vm.legendList.push({
					id: attendanceStatusList[i].id,
					circleCssClass: attendanceStatusList[i].cssClass,
					label: attendanceStatusList[i].label,
				});
			}
		}
		
		vm.uiCalendarConfig = uiCalendarConfig;
		
		/*public*/ vm.listSchedulable = [];
		console.log(schedulableList);
		angular.forEach(schedulableList, function(item)
		{
			vm.listSchedulable.push({
				value: item.id, 
				name: item.name, 
				availabilityObs: item.availabilityObs,
				type: item.type
			});
		});

		vm.dataSchedule.resource = angular.isUndefined(vm.dataSchedule.resource) ? null : vm.dataSchedule.resource;

        /*public*/ vm.closeModal = function()
        {
			$uibModalInstance.close();
        };
        
		function eventClick(p_date)
		{
			StaticTooltipBoxHandler.hide("scheduleStaticTooltipBoxId");
			
			if(p_date && p_date.dto && p_date.dto.status)
			{
				// Bloqueio
				if(hasAttendancePermissions && p_date.dto.status.locking)
				{
					openAgendaLockingDialog(p_date.id);
				}
				// Lembrete
				else if(hasAttendancePermissions && p_date.dto.status.reminder)
				{
					openAgendaReminderDialog(p_date.id);
				}
				else if(p_date.dto.status.attendance) // Atendimento
				{
					$rootScope.confirm(
					{
						message: $translate.instant("client.page.scheduleAttendance.goToAttendanceConfirmationP0", {client: p_date.dto.label}),
						callback: function()
						{
							$state.go('attendance.detail', {id: p_date.id});
							vm.closeModal();
						} 
					});
				}
			}
		}

		function openModal(objData) 
		{
			console.log(objData);
			$uibModal.open({
                templateUrl: 'app/pages/managers/attendance/confirmation/attendance-confirmation-schedule-dialog.html',
                controller: 'AttendanceConfirmationScheduleDialogController',
                controllerAs: 'vm',
                scope: $scope,
                resolve:
                {
                    dataScheduling: function()
                    {
                        return objData;
                    },
                    rescheduleAttendance: function()
                    {
                    	return rescheduleAttendance;
                    }
                }
            }).result.then(function(p_)
            {
            	if(p_ && p_.attendanceInput)
            	{
            		$uibModalInstance.close(p_);
            	}
            }, function() {
                /*void*/
			});
		}

		function eventOnClick(p_date, p_cell) 
		{
			if(hasAttendancePermissions) {
				StaticTooltipBoxHandler.hide("scheduleStaticTooltipBoxId");
				
				var currentTimeZone = timeZone;
				try
				{
					currentTimeZone = (p_date._d.getTimezoneOffset() / 60);
				}
				catch(exc)
				{
					console.error("Error at retrieve TimezoneOffset from Date: " + exc.message);
				}
				
				
				var objData = {
					idResource: vm.idResource,
					idSchedule: angular.isDefined(vm.dataSchedule.idSchedule) ? vm.dataSchedule.idSchedule : 0,
					startDate: moment(p_date._d).add(currentTimeZone, 'hours')._d,
					startTime: moment(p_date._d).add(currentTimeZone, 'hours').format("HH:mm"),
					endTime: '',
					name: vm.name,
					listDaysWeek: vm.listDaysWeek,
					reschedule: vm.editable,
					schedulableType: vm.schedulableType
				};
				openModal(objData);
			}
		}
		
		function eventOnDrop(p_dto, p_cell, p_revertFunc) 
		{
			StaticTooltipBoxHandler.hide("scheduleStaticTooltipBoxId");

			if(rescheduleAttendance && rescheduleAttendance.id === p_dto.id)
			{
				p_revertFunc();
			
				var objData = {
					idResource: vm.idResource,
					idSchedule: angular.isDefined(p_dto.id) ? p_dto.id : 0,
					startDate: moment(p_dto.start._i).add(p_cell._data.days, 'days')._d, 
					startTime: angular.isDefined(p_dto.start._i) ? moment(moment(p_dto.start._i).add(p_cell._data.hours, 'hours').add(p_cell._data.minutes, 'm')).format('LT') : '',
					endTime: angular.isDefined(p_dto.end._i) ? moment(p_dto.end._i).add(p_cell._data.hours, 'hours').add(p_cell._data.minutes, 'm').format('LT') : '',
					name: vm.name,
					listDaysWeek: vm.listDaysWeek,
					reschedule: vm.editable
				}
				openModal(objData);
			}
			else
			{
				UcAlertService.error($translate.instant("client.page.scheduleAttendance.eventOnlyCanBeMovedWhenRescheduleMode"));
				p_revertFunc();
			}
        }

		function eventOnResize(p_dto, p_cell, p_revertFunc) 
		{
			StaticTooltipBoxHandler.hide("scheduleStaticTooltipBoxId");

			if(rescheduleAttendance && rescheduleAttendance.id === p_dto.id)
			{
				p_revertFunc();

				var objData = {
					idResource: vm.idResource,
					idSchedule: angular.isDefined(p_dto.id) ? p_dto.id : 0,
					startDate: p_dto.start._d,
					startTime: angular.isDefined(p_dto.start._i) ? moment(p_dto.start._i).format('LT') : '',
					endTime: angular.isDefined(p_dto.end._i) ? moment(p_dto.end._i).add(p_cell._data.hours, 'hours').add(p_cell._data.minutes, 'm').format('LT') : '',
					name: vm.name,
					listDaysWeek: vm.listDaysWeek,
					reschedule: vm.editable
				};			
				openModal(objData);
			}
			else
			{
				UcAlertService.error($translate.instant("client.page.scheduleAttendance.eventOnlyCanBeMovedWhenRescheduleMode"));
				p_revertFunc();
			}
		}
    
		
        function loadCalendar() {
			$timeout(function() {

				if(uiCalendarConfig.calendars && uiCalendarConfig.calendars.myCalendar)
				{
					// Se é reagendamento, avança p mês atual
					if(rescheduleAttendance && rescheduleAttendance.plannedDateNoTimezone)
					{
						var goToDate = moment(rescheduleAttendance.plannedDateNoTimezone, 'YYYY-MM-DD HH:mm:ss');
						if(goToDate._d && isValidDate(goToDate._d))
						{
							uiCalendarConfig.calendars.myCalendar.fullCalendar('gotoDate', goToDate);
						}
					}
					uiCalendarConfig.calendars.myCalendar.fullCalendar('refetchEvents');
				}
			}, 1);
		}
        
		function objParameters() 
		{
			var referenceDate = new Date($('.schedule-calendar').fullCalendar('getDate')._d.getTime());
			var schedulableId, schedulableType;

			if (_.isNaN(parseFloat(vm.idResource)))
			{
				schedulableId = vm.idResource; 
				schedulableType = "TRP";
			}
			else
			{
				schedulableId = vm.idResource;
				schedulableType = "USU";
			}

			return {
				referenceDate: moment(referenceDate).format("YYYY-MM-DD HH:mm:ss"),
				schedulableId: schedulableId,
				schedulableType: schedulableType
			};
		}

		function reloadSelectedMonthYearVariables() {
			if(uiCalendarConfig.calendars.myCalendar) {
				let date = $('.schedule-calendar').fullCalendar('getDate') || startOfMonth;
				vm.selectedCalendarMonth = vm.listMonths[date.month()];
				vm.selectedCalendarYear = date.year();
			}
		}

		vm.events = function(start, end, time, callback)
		{
			// Se o calendário ainda não estiver startado, cancela a operação
			if(!uiCalendarConfig.calendars.myCalendar)
			{
				return;
			}
			reloadSelectedMonthYearVariables();
			Attendance.getAgenda(objParameters(), 
				function(response)
				{
					var events = []; 
					var endDate = '';
					var startDate = '';
					var attendanceDay = false;
					angular.forEach(response.itemList, function(item) 
					{
						startDate = moment(item.startNoTimezone, 'YYYY-MM-DD HH:mm:ss');
						endDate = moment(item.endNoTimezone, 'YYYY-MM-DD HH:mm:ss');
						
						attendanceDay = false;
//						if (startDate === vm.dataSchedule.startDate && endDate === vm.dataSchedule.endDate) {
//							attendanceDay = true;
//							vm.dataSchedule.idSchedule = item.id;
//						}
						
						var isToRescheduleEvent = (rescheduleAttendance && rescheduleAttendance.id == item.id);
						events.push({
							id: item.id, 
							title: item.label, 
							dto: item,
							isToRescheduleEvent: isToRescheduleEvent,
							start: startDate._d, 
							end: endDate._d, 
//							status: status, 
							attendanceDay: attendanceDay
						});
						
					})

					callback(events);
				},function() {
					callback([]);
				});
			
			getListBlockSchedule(vm.idResource);
			getListReminderSchedule(vm.idResource);
		}
		
		/*private*/ function getCalendarHeight()
		{
			var DEFAULT_HEIGHT = 450;
			try
			{
				var h = $(window).height() - 200;
				return Math.max(h, DEFAULT_HEIGHT);
			}
			catch(ee)
			{
				console.error("Error at calculating Calendar Height: " + ee.message);
				return DEFAULT_HEIGHT;
			}
		}
		
		vm.uiConfig = {
            calendar: {
//            	defaultView: "agendaDay",
				height: getCalendarHeight(),
                editable: true,
				timezone: 'America/Sao_Paulo',
                axisFormat: $translate.instant('client.page.attendance.tabs.scheduling.time'),
				timeFormat: $translate.instant('client.page.attendance.tabs.scheduling.time'),
				views: {
                    week: {
                        columnFormat: $translate.instant('client.page.attendance.tabs.scheduling.columnWeek'),
                        titleFormat: $translate.instant('client.page.attendance.tabs.scheduling.columnWeekTitle')
                    }
                },
				titleFormat: {
					week: $translate.instant('client.page.attendance.tabs.scheduling.columnWeek'),
					day: $translate.instant('client.page.attendance.tabs.scheduling.columnDay'),
				},
                header: {
                    left: 'month, agendaWeek, agendaDay',
                    center: 'title',
                    right: 'today prev,next'
                },
                buttonText: {
                    today: $translate.instant('client.page.attendance.tabs.scheduling.today'),
                    month: $translate.instant('client.page.attendance.tabs.scheduling.month'),
                    week: $translate.instant('client.page.attendance.tabs.scheduling.week'),
                    day: $translate.instant('client.page.attendance.tabs.scheduling.day')
                },
                allDayText: $translate.instant('client.page.attendance.tabs.scheduling.allDayText'),
				dayClick: eventOnClick,
				eventDrop: eventOnDrop,
				eventResize: eventOnResize,
				eventClick: eventClick,
				dayRender: function (date, cell) { 
					if (moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
						cell[0].classList.add('fc-block');
					}
				},
				eventAfterRender: function (p_event, p_element, p_view)
				{
					try
					{
						var item = p_event.dto;
						var original = p_element.html();
						var title = "<span class='agenda-end-date'>";
						if(item)
						{
							var reminder = (p_event.dto && p_event.dto.status && p_event.dto.status.id == "LEM");
							
							// Mesmo dia?
							if(reminder || isSameDay(p_event.start._d, p_event.end._d))
							{
								if(!reminder)
								{
									title += " : " + p_event.end.format("HH:mm");
								}
							}
							else
							{
								title += " a " + p_event.end.format("DD/MM/YY HH:mm");
							}
							title += "</span>";
							title += " - " + item.label;
						}
						var cicleCode = "";
						if(p_event.dto && p_event.dto.status && p_event.dto.status.id != "BLO" && p_event.dto.status.id != "LEM")
						{
							cicleCode = '<div class="fc-agenda-cicle ' + p_event.dto.status.cssClass + '"></div>';
						}
	
						var obsIcon = "";
						if(p_event.dto.data && (p_event.dto.data.hasClientObs || p_event.dto.data.hasObs))
						{
							obsIcon = '<div class="fc-agenda-obs"><i class="fa fa-info-circle" aria-hidden="true" title="' + $translate.instant("client.page.scheduleAttendance.showObservations") + '" onclick="agenda_showObs(' + p_event.dto.id + ', event);"></i></div>'
						}
						
						var resizer = "";
						if(original.indexOf("fc-resizer") > -1)
						{
							resizer = '<div class="fc-resizer"></div>';
						}
						
						p_element.html('<div class="fc-content">' + cicleCode + '' + obsIcon + '<span class="fc-time">' + p_event.start.format("HH:mm") + '</span><span class="fc-title">' + title + '</span></div>' + resizer);
					}
					catch(eee)
					{
						console.log("Error at eventAfterRender: " + eee.message);
					}
				},
				
				eventAfterAllRender : function (data) { 
//					var daysDiff = 0;
//					var indexAux = 0;
//					//var countDays = data.dayGrid.dayDates.length;
//					var countDays = data.dayGrid.dayEls.length;
//					var currentDate = moment().format('YYYY-MM-DD');
//					var breakForeach = false;
//					var dateItem;
//
//					angular.forEach(data.dayGrid.cellDates, function(item, index) {
//						
//						dateItem = moment(moment(item._d).add(timeZone, 'hours')).format('YYYY-MM-DD');
//
//						if (dateItem >= currentDate) {
//							if (countDays === 1)
//							{
//
//								if (_.findIndex(vm.listDaysWeek, function(dw) { return dw.weekDay === moment(dateItem).day(); }) > -1) {
//									data.dayGrid.dayEls[0].classList.add('backgound-schedulesAvailable'); 
//								}
//
//								breakForeach = false;
//								angular.forEach(vm.listBlockSchedule, function(db, index) { 
//									if (!breakForeach) {
//										if (dateItem >= moment(db.startDateNoTimezone).format('YYYY-MM-DD') && dateItem <= moment(db.endDateNoTimezone).format('YYYY-MM-DD')) {
//											data.dayGrid.dayEls[0].classList.add('backgound-schedulesBlock'); 
//											breakForeach = true;
//										}
//									}
//								});
//
//							}
//							else
//							{
//
//								if (_.findIndex(vm.listDaysWeek, function(dw) { return dw.weekDay === moment(dateItem).day(); }) > -1) {
//									data.dayGrid.dayEls[index].classList.add('backgound-schedulesAvailable'); 
//								}
//
//								indexAux = _.findIndex(vm.listBlockSchedule, function(db) { return moment(db.startDateNoTimezone).format('YYYY-MM-DD') === dateItem; });
//								if (indexAux > -1) {
//									daysDiff = moment(moment(vm.listBlockSchedule[indexAux].endDateNoTimezone).format('YYYY-MM-DD')).diff(moment(vm.listBlockSchedule[indexAux].startDateNoTimezone).format('YYYY-MM-DD'), 'days'); 
//									for (var x = 0; x <= daysDiff; x++) { 
//										data.dayGrid.dayEls[index + x].classList.add('backgound-schedulesBlock'); 
//									}
//								}
//
//							}
//						}
//					});
				},
				eventRender: function (event, element)
				{
					if(event.isToRescheduleEvent)
					{
						element[0].classList.add('fc-toReschedule');
					}
					else
					{
						if(event.dto && event.dto.status && event.dto.status.id == "BLO") // Bloqueio deve setar a classe para todo o DIV
						{
							element[0].classList.add(event.dto.status.cssClass);
							element[0].classList.add('fc-agenda-locking');
						}
						else if(event.dto && event.dto.status && event.dto.status.id == "LEM") // Para o lembrete
						{
							element[0].classList.add(event.dto.status.cssClass);
							element[0].classList.add('fc-agenda-reminder');
						}
					}
					
					element[0].onmouseover = function(p_event)
					{
						setScheduleStaticTooltip(event);
						StaticTooltipBoxHandler.show(p_event, "scheduleStaticTooltipBoxId");
					}
					element[0].onmouseout = function(p_event)
					{
						StaticTooltipBoxHandler.hide("scheduleStaticTooltipBoxId");
					}
				}
            }
        };

		vm.eventSources = [vm.events];

//		function getResource() 
//		{
//			Attendance.listSchedulable({}, 
//				function(response)
//				{
//					angular.forEach(response, function(item) {
//						vm.listSchedulable.push({value: item.id, name: item.name});
//					});
//					vm.dataSchedule.resource = angular.isUndefined(vm.dataSchedule.resource) ? null : vm.dataSchedule.resource;
//				});
//		}

		vm.changeResource = function(name, id, type)
		{
			vm.name = name;
			vm.idResource = id;
			vm.schedulableType = type;
			vm.showScheduling = true;
			console.log(vm.idResource);
			getListAvailabilitySchedule(vm.idResource);
			loadCalendar();
		}

		function getListAvailabilitySchedule(id) 
		{
			Attendance.listAvailabilitySchedule({ id: id }, 
				function(response)
				{
					vm.listDaysWeek = [];
					angular.forEach(response, function(item) {
						vm.listDaysWeek.push({ id: item.id, weekDay: item.weekDay, startTime: item.startTime, endTime: item.endTime });
					});
					
					vm.displayListDayWeek = createDisplayListDayWeek(vm.listDaysWeek); 
				});
		}

		function getListBlockSchedule(id) 
		{
			var referenceDate = new Date($('.schedule-calendar').fullCalendar('getDate')._d.getTime());

			AgendaLockingTime.listByScheduler(
			{ 
				id: id,
				dateRef: moment(referenceDate).format("YYYY-MM-DD HH:mm:ss") 
			}, 
			function(p_response)
			{
				vm.listBlockSchedule = p_response;
			});
		}

		function getListReminderSchedule(id) 
		{
			var referenceDate = new Date($('.schedule-calendar').fullCalendar('getDate')._d.getTime());

			AgendaReminder.listByScheduler(
			{
				id: id,
				dateRef: moment(referenceDate).format("YYYY-MM-DD HH:mm:ss") 
			},
			function(p_response)
			{
				vm.listReminderSchedule = p_response;
			});
		}
		
		vm.clickLockingList = function(p_id)
		{
			openAgendaLockingDialog(p_id);
		}

		function openAgendaLockingDialog(p_id)
		{
			AgendaLockingDialogInvoker.invoke({
				agendaLockingTimeId: p_id,
				callback: function(p_)
				{
					if(p_ && p_.success)
					{
						// Recarrega a agenda
						vm.changeResource(vm.name, vm.idResource);
					}
				}
			});
		}
		
		vm.clickReminderList = function(p_id)
		{
			openAgendaReminderDialog(p_id);
		}

		function openAgendaReminderDialog(p_id)
		{
			AgendaReminderDialogInvoker.invoke({
				id: p_id,
				callback: function(p_)
				{
					if(p_ && p_.success)
					{
						// Recarrega a agenda
						vm.changeResource(vm.name, vm.idResource);
					}
				}
			});
		}

		vm.confirmAttendance = function() {
			if (angular.isUndefined(vm.dataSchedule.startDate) || angular.isUndefined(vm.dataSchedule.endDate) || _.isNull(vm.dataSchedule.startDate) || _.isNull(vm.dataSchedule.endDate)) {
				UcAlertService.message($translate.instant("client.page.attendance.completedAttendanceMessage"), "INFO", "ALERT");
			} else {
				vm.closeModal();
			}
		}
		
		/*public*/ vm.showAvailableObs = function()
		{
			return (vm.dataSchedule && vm.dataSchedule.resource && vm.dataSchedule.resource.availabilityObs);
		}

		/*private*/ function setScheduleStaticTooltip(p_)
		{
			var dto = (p_ && p_.dto) ? p_.dto : null;
			if(dto)
			{
				var date = (dto.startNoTimezone) ? moment(dto.startNoTimezone, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : "";
				if(dto.endNoTimezone)
				{
					date += " - " + moment(dto.endNoTimezone, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
				}
				$("#scheduleStaticTooltipBoxId .date-tgt").html(date);

				// Locking?
				if(dto.status.locking || dto.status.reminder)
				{
					$(".tooltip-item-locking").removeClass("display-none");
					$(".tooltip-item-full").addClass("display-none");
					
					$("#scheduleStaticTooltipBoxId .locking-tgt").html(dto.id + "   -   " + dto.label);
				}
				else // Full
				{
					$(".tooltip-item-locking").addClass("display-none");
					$(".tooltip-item-full").removeClass("display-none");
					
					var complementaryFlag = "";
					if(dto.data.complementaryFlag != null)
					{
						complementaryFlag = "  " + dto.data.complementaryFlag.pill;
					}

					$("#scheduleStaticTooltipBoxId .id-tgt").html(dto.id + "   -   " + dto.status.label + complementaryFlag);
					$("#scheduleStaticTooltipBoxId .client-tgt").html(dto.label);
					$("#scheduleStaticTooltipBoxId .medic-tgt").html(dto.data.medic);
					$("#scheduleStaticTooltipBoxId .procedure-tgt").html(dto.data.procedure);
					$("#scheduleStaticTooltipBoxId .local-tgt").html(dto.data.local);
					$("#scheduleStaticTooltipBoxId .equipment-tgt").html(dto.data.equipment);
					$("#scheduleStaticTooltipBoxId .scheduling-tgt").html(generateSchedulingLabel(dto));
					$("#scheduleStaticTooltipBoxId .obs-tgt").html(generateObsHintLabel(dto));
				}				
			}
			else
			{
				$(".tooltip-item-locking").addClass("display-none");
				$(".tooltip-item-full").removeClass("display-none");

				$("#scheduleStaticTooltipBoxId .id-tgt").html("");
				$("#scheduleStaticTooltipBoxId .client-tgt").html("");
				$("#scheduleStaticTooltipBoxId .medic-tgt").html("");
				$("#scheduleStaticTooltipBoxId .procedure-tgt").html("");
				$("#scheduleStaticTooltipBoxId .local-tgt").html("");
				$("#scheduleStaticTooltipBoxId .equipment-tgt").html("");
				$("#scheduleStaticTooltipBoxId .date-tgt").html("");
				$("#scheduleStaticTooltipBoxId .scheduling-tgt").html("");
				$("#scheduleStaticTooltipBoxId .obs-tgt").html("");
			}
		}
		
		/*private*/ function generateObsHintLabel(p_dto)
		{
			var obsText;
			if(!p_dto.data.hasObs && !p_dto.data.hasClientObs)
			{
				obsText = $translate.instant("client.page.scheduleAttendance.noObservations");
			}
			else
			{
				if(p_dto.data.hasClientObs)
				{
					obsText = $translate.instant("client.global.label.client"); 
				}
				if(p_dto.data.hasObs)
				{
					if(obsText)
					{
						obsText += "/";
					}
					else
					{
						obsText = "";
					}
					obsText += $translate.instant("client.global.label.salesRequest");
				}
				obsText = $translate.instant("client.page.scheduleAttendance.hasObservations") + ": " + obsText;
			}
			return obsText;
		}
		
		/*private*/ function generateSchedulingLabel(p_dto)
		{
			if(p_dto.data)
			{
				if(p_dto.data.reschedulingNumber > 0)
				{
					return $translate.instant("client.page.scheduleAttendance.rescheduledByP0", {user: p_dto.data.lastSchedulingUser}); 
				}
				else
				{
					return $translate.instant("client.page.scheduleAttendance.scheduledByP0", {user: p_dto.data.lastSchedulingUser}); 
				}
			}
			return "-";
		}
		
		/*private*/ function createDisplayListDayWeek(p_listDaysWeek)
		{
			var map = {};
			var itm, temp;
			for (var i = 0; i < p_listDaysWeek.length; i++)
			{
				itm = p_listDaysWeek[i];
				temp = map[itm.weekDay];
				if(temp == null)
				{
					temp = {
						weekDay: itm.weekDay,
						list: []
					};
					map[itm.weekDay] = temp;
				}
				// Considera-se que a lista já vem ordenada pelos horários 
				temp.list.push(itm);
			}

			// Cria a lista de dias
			var list = [];
			var keys = Object.keys(map);
			for (var i = 0; i < keys.length; i++)
			{
				list.push(map[keys[i]]);
			}
			list.sort(function(p_1, p_2)
			{
				return p_1.weekDay - p_2.weekDay;
			});
			return list;
		}

		function init()
		{
			
			if (vm.dataSchedule.resource !== null)
			{
				getListAvailabilitySchedule(vm.dataSchedule.resource.value)
				vm.changeResource(vm.dataSchedule.resource.name, vm.dataSchedule.resource.value);
			}
			
		}

		init();
    }
})();

function agenda_showObs(p_id, p_event)
{
	try
	{
		getStaticController().showAgendaObs(p_id);
		p_event.stopPropagation();
	}
	catch(exc)
	{
		console.error(exc.message);
	}
}