(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ManufacturerManagerDirectiveController', ManufacturerManagerDirectiveController);

	ManufacturerManagerDirectiveController.$inject = ['$scope', '$translate', 'Principal', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$state', '$stateParams', 'ManufacturerCommon', 'Manufacturer', 'FormDomain', 'UcAlertService', 'TabStatePersister'];
	function ManufacturerManagerDirectiveController($scope, $translate, Principal, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $state, $stateParams, ManufacturerCommon, Manufacturer, FormDomain, UcAlertService, TabStatePersister) {
		var vm = this;

		vm.domain = $scope.domain;
		vm.pageConfig = $scope.pageConfig;
		vm.injectedResources = $scope.injectedResources;
		
		vm.readOnlyFinish = false;
		
		if(vm.domain){
			vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
		}
		
	
		
		var formOptions = {
				model: vm.domain,
				pageConfig: vm.pageConfig,
				buttonsModel: null,
				service: Manufacturer,
				afterCreateState: "manufacturer.detail",
				showTabWithError: true,
				afterUpdate: function () { doAfterUpdate(); },
				getForm: function () { return $scope.editForm; },
				historyConfig: {historyId: "MANUFACTURER"},
				beforeInvokeService: function(p_json)
	        	{
	        		
	        	}
			};

			vm.formHandler = FormDomain.create(formOptions)
				.add([new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.manufacturer.id",
					readOnly: true
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "String",
					id: "userName",
					multiple: false,
					readOnly: true,
					label: "common.domain.manufacturer.user"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "createdDate",
					label: "common.domain.manufacturer.createdDate"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-2",
					type: "CnpjCpf",
					id: "cnpjCpf",
					required: true,
					label: "common.domain.manufacturer.cnpjCpf"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-5",
					type: "String",
					id: "companyName",
					required: true,
					label: "common.domain.manufacturer.companyName"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-5",
					type: "String",
					id: "tradingName",
					required: true,
					label: "common.domain.manufacturer.tradingName"
				})
				]).add([new FormField({
		           		cellCssClass: "col-sm-12",
						type: "Boolean",
						formTarget: "main",
						id: "active",
						label: "common.domain.manufacturer.active",
						addFieldListener: FormConstants.AddFieldListeners.IdNotNull
					})
				])
				.endConfig();


	        /*public*/ vm.save = function () {
				vm.formHandler.save();
			};

			vm.buttonsModel = {
				isSaving: false,
				gridId: "MANUFACTURER",
				stateRoot: "manufacturer",
				previousState: { name: "manufacturer" },
				onClear: function()
				{
					vm.formHandler.clear();
				},
			};

			  /*private*/ function createBackButton() {
				return {
					order: 500,
					cssClass: 'btn btn-default',
					cssClassIcon: 'glyphicon glyphicon-arrow-left',
					label: 'client.global.label.back',
					type: 'button',

					isDisabled: function () {
						return false;
					},
					isToAdd: function () {
						return true;
					},
					click: function () {
						$state.go('manufacturer');
					}
				};
			};

			
		    /*private*/ function createEditButton() {
				return {
					order: 0,
					cssClass: 'btn btn-success',
					cssClassIcon: 'glyphicon glyphicon-pencil',
					label: 'client.global.label.edit',
					type: 'button',
					isDisabled: function () { return /*checar permissões*/ false; },
					isToAdd: function()
		        	{
		        		// Só para READONLY
		        		return (vm.pageConfig && vm.pageConfig.pageMode === "READONLY");
		        	},
					click: function () {
						$state.go('manufacturer.edit', { id: $stateParams.id });
					}
				}
			};

		    /*private*/ function createSaveButton() {
				return {
					order: 0,
					cssClass: 'btn btn-success',
					cssClassIcon: 'glyphicon glyphicon-save',
					label: 'client.global.label.save',
					type: 'submit',
					isDisabled: function () { return (/*checar permissões || */ vm.buttonsModel.isSaving); },
					isToAdd: function () { return true; },
					click: function () { /* void - por ser submit, a ação está no ng-submit do form */ }
				};
			};

		    /*private*/ function doAfterUpdate() {
				$state.reload();
			}
	
		
	};
})();