(function() {
    'use strict';

    angular
        .module('sgmApp')
        .factory('DownloadUtil', DownloadUtil);

    DownloadUtil.$inject = ['UcAlertService', '$translate'];

    function DownloadUtil(UcAlertService, $translate)
    {
    	return {
    		download: function(p_url, p_data, p_httpMethod)
    		{
    			// Default é get!
    			if(!p_httpMethod)
    			{
    				p_httpMethod = "GET";
    			}
    			
    			/*
 				  Para que o download util funcione é necessário incluir um cookie no servidor para que a finalização do download seja detectado:
 				  header('Set-Cookie: fileDownload=true; path=/');
    			 */
				var downloadDialog = $('#downloadDialog');
				downloadDialog.addClass("downloadDialogModal");

				$.fileDownload(p_url,
				{
					successCallback: function(url)
					{
						downloadDialog.removeClass("downloadDialogModal");
					},
					failCallback: function(responseHtml, url)
					{
						var errorJson = null;
						try
						{
							errorJson = JSON.parse(responseHtml.replace(/<[^>]+>/gm, ''));
						}
						catch(eee){ /*silent*/ }

						if(!errorJson.completeMessage)
						{
							downloadDialog.removeClass("downloadDialogModal");
						}
						
						var errorMessage;
						if(errorJson && errorJson.error)
						{
							errorMessage = errorJson.message;
						}
						else
						{
							errorMessage = $translate.instant('client.global.messages.error.contactAdministrator')
						}
						if(!errorJson.completeMessage)
						{
							errorMessage = "<b>" + $translate.instant('client.global.download.errorMessage') + "</b>: " + errorMessage
						}
						UcAlertService.error(errorMessage);
					},
					httpMethod: p_httpMethod,
					data: p_data
				});
    		}
    	};
    }
})();