(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AdminTaskController', AdminTaskController);

    AdminTaskController.$inject = ['$scope', 'Principal', /*'LoginService',*/ '$state', '$interval', 'AdminTask'];

    function AdminTaskController ($scope, Principal, /*LoginService,*/ $state, $interval, AdminTask)
    {
    	var vm = this;
    	
    	vm.code = null;
    	
    	vm.exec = function()
    	{
    		AdminTask.exec({code: vm.code}, function(p_)
    		{
    			console.log(p_.value)
    		});
    	}
    	
    	$interval(function()
    	{
    		vm.exec();
    	}, 30 * 1000);
    	
        
    }
})();
