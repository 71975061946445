(function() {
    'use strict';

    /**
     * Gerencia o estado do menu (opção selecionada destacada)
     */
    angular.module('sgmApp').service('MenuHighlight', MenuHighlight);

    MenuHighlight.$inject = [];
    
    function MenuHighlight()
    {
    	var vm = this;
    	
    	/**
    	 * O event é passado no argumento do ng-click: Ex: ng-click="doAction($event)"
    	 * Null se não quiser que nenhum seja marcado
    	 */
    	/*public*/ vm.refresh = function(p_event)
    	{
    		// Limpa todas as classe de menu marcadas como ativas
	    	$(".right-menu-root .menu-item.active").removeClass("active");
	    	
	    	if(p_event)
	    	{
		    	// Percorre para cima até o nó raiz do menu: class='right-menu-root'
		    	var node = $(p_event.currentTarget);
		    	while(node && !node.hasClass('right-menu-root'))
		    	{
		    		// Se for menu-item, seta a classe
		    		if(node.hasClass('menu-item'))
		    		{
		    			node.addClass("active");
		    		}
		    		node = node.parent();
		    	}
	    	}
    	};
    }
})();