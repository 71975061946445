(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('DateFormFieldDirectiveController', DateFormFieldDirectiveController);
    
    DateFormFieldDirectiveController.$inject = ['$scope', '$translate', '$rootScope', 'FormConstants', '$timeout'];
    function DateFormFieldDirectiveController($scope, $translate, $rootScope, FormConstants, $timeout)
    {
    	var vm = this;
    	
    	vm.objModel = $scope.objModel;
    	vm.fieldModel = $scope.fieldModel;
    	vm.editMode = $scope.editMode;
    	
    	vm.readOnly = (vm.fieldModel.readOnly === true || vm.editMode !== true);

		// Se a data estiver setada, faz o ajuste para ser reconhecida pelo input
		if(vm.objModel[vm.fieldModel.id])
		{
			vm.objModel[vm.fieldModel.id] = DateTimeUtil.initDate(vm.objModel[vm.fieldModel.id]);
        }
		
		if((!angular.isDefined(vm.fieldModel.dateOptions) || vm.fieldModel.dateOptions === null))
		{
			vm.fieldModel.dateOptions =
			{
				opened: false,
				format: "dd/MM/yyyy",
				dateDisabled: false, 
				maxDate: vm.fieldModel.maxDate,
				formatYear: 'yyyy', 
				startingDay: 1,
				altInputFormats: ['M!/d!/yyyy']
			};
        }
		
		$timeout(function()
		{
			$("#" + vm.fieldModel.id).mask("99/99/9999", {placeholder: " "});
		}, 1);
		
    	/**
    	 * Resolve a classe para o input
    	 */
    	/*public*/ vm.resolveClass = function()
    	{
    		var cssClass = "form-control";
    		
    		return cssClass; 
    	};

		/**
		 * Abre o painel para seleção da data
		 */
		/*public*/ vm.openDataPanel = function()
		{
			if(!(vm.readOnly === true || vm.editMode !== true))
			{
				vm.fieldModel.dateOptions.opened = true;
			}
		}
		/**
		 * Classe para o group-date
		 */
		/*public*/ vm.resolveDateGroupClass = function()
		{
			var cssClass = "inner-addon right-addon"
			if(!(vm.readOnly === true || vm.editMode !== true))
			{
				cssClass += " date-group" 
			}
			else
			{
				cssClass += " date-group-readonly" 
			}

			return cssClass;
		};
		
		/*public*/ vm.onChange = function()
		{
			if(vm.fieldModel.onChange)
			{
				vm.fieldModel.onChange(vm.objModel[vm.fieldModel.id]);
			}

    		// Se tiver setado o listener checkFormChange
    		if(vm.fieldModel.checkFormChange)
    		{
    			vm.fieldModel.checkFormChange(vm.objModel, vm.fieldModel.id);
    		}
		};
		
    	// Evento runtime ReadOnly
    	vm.currentReadOnly = vm.readOnly
    	var readOnlyListener = $rootScope.$on(FormConstants.Events.Runtime.ReadOnly(vm.fieldModel.id), function(p_event, p_readOnlyParam)
    	{
    		if(p_readOnlyParam.absolute)
    		{
    			vm.readOnly = p_readOnlyParam.readOnly;
    		}
    		else
    		{
    			vm.readOnly = (p_readOnlyParam.readOnly) ? true : vm.currentReadOnly;
    		}
    	});
    	
    	// Destroy os listeners
        $scope.$on('$destroy', function()
        {
            if(angular.isDefined(readOnlyListener) && readOnlyListener !== null)
            {
            	readOnlyListener();
            }
        });

    };
})();