(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('formField', formFieldDirective);
    
    formFieldDirective.$inject = [];
    function formFieldDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "FormFieldDirectiveController",
            templateUrl: "app/components/uc-form/form-field/form-field.directive.template.html",
            scope:
            {
            	pageConfig: '=',
            	objModel: '=',
            	fieldModel: '='
            },
    		require: ['^form'],
    		link: function(scope, element, attr, mCtrl)
    		{
    			var FormController = mCtrl[0];
    			scope.form = FormController; 
    		}            
    	}
    };
})();
