(function() {
    'use strict';

	angular.module('sgmApp').service('ClientMinManagerModalInvoker', ClientMinManagerModalInvoker);
	
	ClientMinManagerModalInvoker.$inject = ['$uibModal', 'ClientCommon', '$timeout'];
	
	function ClientMinManagerModalInvoker($uibModal, ClientCommon, $timeout)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(/*ClientMinManagerModalInvokerParam*/ p_param)
		{
            $uibModal.open({
                templateUrl: "app/pages/managers/client/modal/client-min-manager-modal.template.html",
                controller: 'ClientMinManagerModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: 
                {
                	genderList:['User', function(User)
                    {
                        return User.listGender().$promise;
                    }],
                    pageConfig: ['$rootScope', 'PermissionSet', function($rootScope, PermissionSet)
                    {
                    	return new PageConfig({
                    		pageMode: "CUSTOM_EDIT",
                    		account: p_param.account,
    	                	formValidateTarget: 'Client',
    	                	permission: PermissionSet.CLIENT
                    	});
                    }]
                }
            }).result.then(function(p_client)
            {
        		// Se tiver um callback definido, o invoca
        		if(p_param.callback)
        		{
        			p_param.callback(p_client);
        		}
            },function()
            {
            });
		};
		
	};
})();


function ClientMinManagerModalInvokerParam(p_param)
{
	var _DEFAULT_PARAM =
	{
		account: null,
		callback: null
	};
	
	// Carrega os valores default
	angular.extend(this, _DEFAULT_PARAM);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_param) ? p_param : {});
}

