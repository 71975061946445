(function() {
    'use strict';

    angular
        .module('sgmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider)
    {
        $stateProvider.state('my-account',
        {
            parent: 'authenticatedPage',
            url: '/my-account',
            views: {
                'content@': {
                    templateUrl: 'app/common/page-domain/page-domain.html',
                    controller: 'MyAccountController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                account: ['Principal', function(Principal)
                {
                    return Principal.identity();
                }],
            	myData: ['User', 'account', function(User, account)
                {
            		return User.get({id: account.id}).$promise;
                }],            	
                pageConfig: ['$rootScope', function($rootScope)
                {
                	return {
                		pageMode: "EDIT",
	                	title: 'client.page.myAccount.title',
	                	subTitle: '',
	                	formHeader: 'client.page.myAccount.formHeader',
	                	icon: $rootScope.menuIcons.myAccount,
	                	formValidateTarget: 'MyAccount'
                	}
                }]
            }
        });
    }
})();
