(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('SupplierController', SupplierController);

	SupplierController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'Principal', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Supplier', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'DownloadUtil','TabStatePersister'];

	function SupplierController(FormFilter, UcGridService, $translate, account, Principal, filterPageConfig, gridDefaultSort, $rootScope, Supplier, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, DownloadUtil,TabStatePersister) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		vm.buttonsModel =
			{
				stateRoot: "supplier",
				onFilter: function () { doFilter(); },
				onClear: function () { doClear(); },
				extraButtonList: [{
					order: 90,
					cssClass: 'btn btn-primary',
					cssClassIcon: 'fa fa-file-excel-o',
					label: 'client.global.label.export',
					type: 'button',
					isDisabled: function () {
						return !Principal.hasAnyAuthority(['REPORT_SUPPLIER']);
					},
					isToAdd: function () { return true; },
					click: function () { generateReport(); }
				}]
			};

		$rootScope.initialFocus("cnpjCpf");

		var formOptions = {
			formStatePersisterId: "Supplier" 
		};


		vm.formHandler = FormFilter.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "String",
					id: "cnpjCpf",
					label: "common.domain.supplier.cnpjCpf"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "companyName",
					label: "common.domain.supplier.companyName"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "tradingName",
					label: "common.domain.supplier.tradingName"
				}),
				new FormField({
		       		cellCssClass: "col-sm-2",
					type: "Select",
					id: "active",
					label: "common.domain.supplier.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
				})
				
			]).add([
				new FormField({
					cellCssClass: "col-sm-5",
					type: "Select",
					id: "categoryList",
					multiple: true,
					label: "common.domain.supplier.category",
					dataSource: FormConstants.DataSources.BackEnd.SupplierCategory(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-5",
					type: "Select",
					id: "subcategoryList",
					multiple: true,
					label: "common.domain.supplier.subcategory",
					dataSource: FormConstants.DataSources.BackEnd.SupplierSubcategory(BackEndSelectDataSource, $translate)
				})
				
			])
			.endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.supplier.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 1 },
			{ headerName: $translate.instant("common.domain.supplier.cnpjCpf"), field: "cnpjCpf", cellStyle: { alignItems: "center", textAlign: "center" }, width: 10 },
			{ headerName: $translate.instant("common.domain.supplier.companyName"), field: "companyName", width: 25  },
			{ headerName: $translate.instant("common.domain.supplier.tradingName"), field: "tradingName", width: 25},
			UcGridService.field.ACTIVE()
		]);

		var grid = UcGridService.create(
			{
				gridId: "SUPPLIER",
				stateBaseName: "supplier",
				account: account,
				getFilterData: function () { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
				beforeOnRowClicked: function() { return clearEditTabState(); },
			});

		
		/*
		 * Limpa o tab da página de edição
		 */
		/*private*/ function clearEditTabState()
		{
			TabStatePersister.reset("SupplierDomainController");
			
			// Retorna true para não interromper o fluxo da operação
			return true;
		};
		
		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};
		
		function generateReport() {
			var data = vm.formHandler.readForm();
			delete data["categoryList"];
			delete data["subcategoryList"];
			
			data.generateReport = true;
			DownloadUtil.download('api/supplier/generate-report', data);
		}

	}
})();
