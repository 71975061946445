(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('SupplierManagerDirectiveController', SupplierManagerDirectiveController);

	SupplierManagerDirectiveController.$inject = ['$scope', '$translate', 'Principal', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', '$state', '$stateParams', 'SupplierCommon', 'Supplier', 'FormDomain', 'UcAlertService', 'TabStatePersister'];
	function SupplierManagerDirectiveController($scope, $translate, Principal, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, $state, $stateParams, SupplierCommon, Supplier, FormDomain, UcAlertService, TabStatePersister) {
		var vm = this;

		vm.domain = $scope.domain;
		vm.pageConfig = $scope.pageConfig;
		vm.injectedResources = $scope.injectedResources;

		vm.contactList = vm.domain != null && vm.domain.contactSet ?  vm.domain.contactSet : [];
		//Limitado a 5 contatos no máximo
		vm.contactMaxSize = 5;
		vm.contactIdListToRemove = [];

		vm.readOnlyFinish = false;

		if(vm.domain){
			vm.domain.createdDate = moment(vm.domain.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d
		}

		vm.categoryTabConfig = {
			treeOptions: {
	        	    nodeChildren: "children",
	        	    dirSelectable: false,
	        	    multiSelection: true,
	        	    isSelectable : function(){ return vm.pageConfig.pageMode !== 'READONLY';},
	        	    injectClasses: {
//	        	        ul: "a1",
//	        	        li: "a2",
//	        	        liSelected: "a7",
//	        	        iExpanded: "a3",
//	        	        iCollapsed: "a4",
//	        	        iLeaf: "a5",
//	        	        label: "a6",
	        	        labelSelected: "selected-tree-item"
	    	    }
			}
		};

		function mapIdToObjWithId(id){
			return {id: id};
		}

		function selectTreeItems(selectedArray, treeData, idListToCompare){
			treeData.forEach(function(cat){
				if(cat.children){
					cat.children.forEach(function(subcat){
						if(idListToCompare.includes(subcat.id)) {
							selectedArray.push(subcat);
						}
					});
				}
			})
		}


		if(vm.injectedResources.categoryTreeDataSource){
			vm.categoryTabConfig.treeDataEquipment = vm.injectedResources.categoryTreeDataSource.equipmentItems;
			vm.categoryTabConfig.treeDataMaterial = vm.injectedResources.categoryTreeDataSource.materialItems;
			vm.categoryTabConfig.treeDataService = vm.injectedResources.categoryTreeDataSource.serviceItems;

			vm.selectedCategoryEquipment = [];
			vm.selectedCategoryMaterial = [];
			vm.selectedCategoryService = [];

			selectTreeItems(vm.selectedCategoryEquipment, vm.categoryTabConfig.treeDataEquipment, vm.domain.subcategoryEquipmentSet);
			selectTreeItems(vm.selectedCategoryMaterial, vm.categoryTabConfig.treeDataMaterial, vm.domain.subcategoryMaterialSet);
			selectTreeItems(vm.selectedCategoryService, vm.categoryTabConfig.treeDataService, vm.domain.subcategoryServiceSet);
		}


		vm.tabList = SupplierCommon.tabList;

		vm.tabHandler = {
				disabledMap: {},
				active: TabStatePersister.init('SupplierDomainController', 'contact'),
				isActive: function (p_code) {
					return (p_code === vm.tabHandler.active);
				},
				isDisabled: function (p_code) {
					if ((p_code === "category") && !vm.domain.id) {
						return true;
					}
					return false;
				},
				isShow: function (p_code) {
					return true;
				},
				select: function (p_id) {
					if (vm.tabHandler.isDisabled(p_id)) {
						return;
					}

					vm.tabHandler.active = TabStatePersister.set("SupplierDomainController", p_id);
					vm.tabHandler.active = p_id;
				}
			};


		var formOptions = {
				model: vm.domain,
				pageConfig: vm.pageConfig,
				buttonsModel: null,
				service: Supplier,
				afterCreateState: "supplier.detail",
				showTabWithError: true,
				afterUpdate: function () { doAfterUpdate(); },
				getForm: function () { return $scope.editForm; },
				historyConfig: {historyId: "SUPPLIER"},
				beforeInvokeService: function(p_json)
	        	{
	        		// Preenche a lista de parcelas no momento da criação da despesa.
					// No caso de edição, a lista de parcelas não é modificada
					if(vm.pageConfig){
						p_json.contactSet = vm.contactList;
						p_json.contactIdListToRemove = vm.contactIdListToRemove;
						p_json.subcategoryIdList = getSubcatetorySelectedList();
					}
	        	}
			};

			vm.formHandler = FormDomain.create(formOptions)
				.add([new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.supplier.id",
					readOnly: true
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-6",
					type: "String",
					id: "userName",
					multiple: false,
					readOnly: true,
					label: "common.domain.supplier.user"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-3",
					readOnly: true,
					type: "DateTime",
					id: "createdDate",
					label: "common.domain.supplier.createdDate"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-2",
					type: "CnpjCpf",
					id: "cnpjCpf",
					required: true,
					label: "common.domain.supplier.cnpjCpf"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-5",
					type: "String",
					id: "companyName",
					required: true,
					label: "common.domain.supplier.companyName"
				}),
				new FormField({
					formTarget: "main",
					cellCssClass: "col-sm-5",
					type: "String",
					id: "tradingName",
					required: true,
					label: "common.domain.supplier.tradingName"
				})
				]).add([new FormField({
		           		cellCssClass: "col-sm-3",
						type: "Boolean",
						formTarget: "main",
						id: "active",
						label: "common.domain.supplier.active",
						addFieldListener: FormConstants.AddFieldListeners.IdNotNull
					}),
					new FormField({
						cellCssClass: "col-sm-3",
						type: "Boolean",
						formTarget: "main",
						id: "productSupplier",
						label: "common.domain.supplier.productSupplier",
				 	})
				])
				.endConfig();


	        /*public*/ vm.save = function () {
				vm.formHandler.save();
			};

			vm.buttonsModel = {
				useSuggestedButton: false,
				isSaving: false,
				gridId: "SUPPLIER",
				previousState: { name: "supplier" },
				stateRoot: "supplier",
				extraButtonList: [
					createSaveButton(),
					createEditButton(),
					createBackButton()
				]
			};

			  /*private*/ function createBackButton() {
				return {
					order: 500,
					cssClass: 'btn btn-default',
					cssClassIcon: 'glyphicon glyphicon-arrow-left',
					label: 'client.global.label.back',
					type: 'button',

					isDisabled: function () {
						return false;
					},
					isToAdd: function () {
						return true;
					},
					click: function () {
						$state.go('supplier');
					}
				};
			};


		    /*private*/ function createEditButton() {
				return {
					order: 0,
					cssClass: 'btn btn-success',
					cssClassIcon: 'glyphicon glyphicon-pencil',
					label: 'client.global.label.edit',
					type: 'button',
					isDisabled: function () { return /*checar permissões*/ false; },
					isToAdd: function()
		        	{
		        		// Só para READONLY
		        		return (vm.pageConfig && vm.pageConfig.pageMode === "READONLY");
		        	},
					click: function () {
						$state.go('supplier.edit', { id: $stateParams.id });
					}
				}
			};

		    /*private*/ function createSaveButton() {
				return {
					order: 0,
					cssClass: 'btn btn-success',
					cssClassIcon: 'glyphicon glyphicon-save',
					label: 'client.global.label.save',
					type: 'submit',
					isDisabled: function () { return (/*checar permissões || */ vm.buttonsModel.isSaving); },
					isToAdd: function () { return true; },
					click: function () { /* void - por ser submit, a ação está no ng-submit do form */ }
				};
			};

		    /*private*/ function doAfterUpdate() {
				$state.reload();
			}

		    function mapObjToIdList(obj){
		    	return obj.id;
		    }

		    function getSubcatetorySelectedList(){
		    	var list = vm.selectedCategoryEquipment.map(mapObjToIdList).concat(vm.selectedCategoryMaterial.map(mapObjToIdList),
		    		vm.selectedCategoryService.map(mapObjToIdList));
		    	return list;
		    }

	};
})();
