(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('CashFlowReportDialogController', CashFlowReportDialogController);

    CashFlowReportDialogController.$inject = ['$scope', '$uibModalInstance', 'FormDomain', 'DownloadUtil'];

    function CashFlowReportDialogController($scope, $uibModalInstance, FormDomain, DownloadUtil)
    {
        var vm = this;
        
        vm.pageConfig = new PageConfig({
    		pageMode: "CUSTOM_EDIT", // Para não ser inseridos buttons
    		formValidateTarget: "CashFlowReportDialog"
    	});
        
        var entity = {
        	day: new Date()
        };
        
        var formOptions = {
        	model: entity,
        	pageConfig: vm.pageConfig,
        	buttonsModel: vm.buttonsModel,
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
			.add(new FormField({
	        	cellCssClass: "col-sm-12",
				type: "Date",
				id: "day",
				required: true,
				label: "client.page.cashFlowReportDialog.day"
	        }))	        
			.endConfig();
        
        /*private*/ var generateButton = {
        	order: 0,
        	cssClass: 'btn btn-success',
        	cssClassIcon: 'fa fa-play',
        	label: 'client.global.label.generateReport',
        	type: 'submit',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function() { /* void - por ser submit, a ação está no ng-submit do form */ }
        };

        /*private*/ var closeButton = {
        	order: 10,
        	cssClass: 'btn btn-default',
        	cssClassIcon: 'fa fa-times',
        	label: 'client.global.label.close',
        	type: 'button',
        	isDisabled: function() { return false; },
        	isToAdd: function() { return true; },
        	click: function()
        	{
            	$uibModalInstance.dismiss();
        	}
        };
        
        vm.buttonsModel = {
	    	customButtonList: [ generateButton, closeButton ]
	    };
        
		/*public*/ vm.closeModal = function()
		{
			$uibModalInstance.dismiss();
		}
		
        /*public*/ vm.generate = function()
        {
        	vm.formHandler.preSave(internalGenerate)
        }
        
        /*private*/ function internalGenerate(p_json)
        {
        	var formData = {
        		day: DateTimeUtil.extractDate(p_json.day)
        	};
        	DownloadUtil.download('api/attendance/generate-cash-flow-report', formData);
        	$uibModalInstance.dismiss();
        };
    }
})();