(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ExpenseController', ExpenseController);

	ExpenseController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'Principal', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Expense', 'FrontEndSelectDataSource', 'FormConstants', 'BackEndSelectDataSource', 'listExpenseStatusType', 'listExpenseTimeStatusType', 'listInstallmentsquantity', 'DownloadUtil', '$filter'];

	function ExpenseController(FormFilter, UcGridService, $translate, account, Principal, filterPageConfig, gridDefaultSort, $rootScope, Expense, FrontEndSelectDataSource, FormConstants, BackEndSelectDataSource, listExpenseStatusType, listExpenseTimeStatusType, listInstallmentsquantity, DownloadUtil, $filter) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		vm.buttonsModel =
			{
				stateRoot: "expense",
				onFilter: function () { doFilter(); },
				onClear: function () { doClear(); },
				extraButtonList: [{
					order: 90,
					cssClass: 'btn btn-primary',
					cssClassIcon: 'fa fa-file-excel-o',
					label: 'client.global.label.generateExpenseReport',
					type: 'button',
					isDisabled: function () {
						return !Principal.hasAnyAuthority(['ROLE_DESPESA_RELATORIO']);
					},
					isToAdd: function () { return true; },
					click: function () { generateReport(); }
				}]
			};

		$rootScope.initialFocus("id");

		var formOptions = {
			formStatePersisterId: "Expense"
		};


		vm.formHandler = FormFilter.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "NumberRaw",
					id: "id",
					label: "common.domain.expense.id",
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "NumberRaw",
					id: "sourceId",
					label: "common.domain.expense.sourceId",
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Date",
					id: "createdDate",
					label: "common.domain.expense.createdDate",
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "createdUserList",
					label: "common.domain.expense.user",
					dataSource: FormConstants.DataSources.BackEnd.User(BackEndSelectDataSource, $translate)
				})
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					label: "common.domain.expense.description"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "expenseStatusList",
					multiple: true,
					label: "common.domain.expense.status",
					dataSource: FrontEndSelectDataSource.create(listExpenseStatusType)
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Select",
					id: "expenseStatusTimeList",
					multiple: true,
					label: "common.domain.expense.statusTime",
					dataSource: FrontEndSelectDataSource.create(listExpenseTimeStatusType)
				})
			]).
			add([
				new FormField({
					cellCssClass: "col-sm-4",
					type: "Select",
					id: "expenseTypeList",
					label: "common.domain.expense.type",
					multiple: true,
					dataSource: FormConstants.DataSources.BackEnd.ExpenseType(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "Select",
					id: "costCenterList",
					multiple: true,
					label: "common.domain.expense.costCenter",
					dataSource: FormConstants.DataSources.BackEnd.ExpenseCostCenter(BackEndSelectDataSource, $translate)
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "Select",
					id: "responsibleUserList",
					multiple: true,
					label: "common.domain.expense.responsibleUser",
					dataSource: FormConstants.DataSources.BackEnd.User(BackEndSelectDataSource, $translate)
				}),
			]).
			add([
				new FormField({
					cellCssClass: "col-sm-4",
					type: "String",
					id: "assignor",
					label: "common.domain.expense.assignor"
				}),
				new FormField({
					cellCssClass: "col-sm-4",
					type: "Select",
					id: "supplierList",
					label: "common.domain.expense.supplier",
					dataSource: FormConstants.DataSources.BackEnd.Supplier(BackEndSelectDataSource, $translate)
				})
			]).endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.expense.idAbrev"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 2 },
			{ headerName: $translate.instant("common.domain.expense.sourceIdAbrev"), field: "sourceId", cellStyle: { alignItems: "center", textAlign: "center" }, width: 2 },
			{ headerName: $translate.instant("common.domain.expense.installmentNumber"), field: "installmentNumber", cellStyle: { alignItems: "center", textAlign: "center" }, width: 2 },
			{ headerName: $translate.instant("common.domain.expense.type"), field: "expenseType", cellStyle: { alignItems: "center", textAlign: "center" }, width: 15 },
			{ headerName: $translate.instant("common.domain.expense.description"), field: "description", width: 30 },
			{ headerName: $translate.instant("common.domain.expense.providedValue"), field: "providedValue", width: 11, cellStyle: {alignItems: "right"}},				
			{ headerName: $translate.instant("common.domain.expense.paymentDate"), field: "plannedDate", cellStyle: { alignItems: "center", textAlign: "center" }, width: 4 },
			{ headerName: $translate.instant("common.domain.expense.assignor"), field: "assignor", cellStyle: { alignItems: "center", textAlign: "center" }, width: 10},
			{ headerName: $translate.instant("common.domain.expense.supplier"), field: "supplier", cellStyle: { alignItems: "center", textAlign: "center" }, width: 12},
			{ headerName: $translate.instant("common.domain.expense.status"), field: "expenseStatus", width: 12, cellRenderer: function (p_param) { return renderExpenseStatus(p_param); } },
			{ headerName: $translate.instant("common.domain.expense.statusTime"), field: "expenseStatusTime", width: 12, suppressSorting: true, cellStyle: {alignItems:"center"},
				cellRenderer: function (p_param) { return renderExpenseStatusTime(p_param); } }
		]);



		var grid = UcGridService.create(
			{
				gridId: "EXPENSE",
				stateBaseName: "expense",
				account: account,
				getFilterData: function () { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				sort: vm.sort
			});

		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};

				
		/*private*/ function hasStopPropagation(p_event)
		{
			var targetObj = $(p_event.target);
			if(targetObj && (targetObj.attr("stop-propagation") || targetObj.closest("[stop-propagation='true']").length))
			{
				return true;
			}
			else
			{
				return false;
			}
		}

		/*private*/
		function generateReport() {
			var data = vm.formHandler.readForm();
			delete data["expenseStatusList"];
			delete data["costCenterList"];
			delete data["expenseStatusTimeList"];
			delete data["expenseTypeList"];
			delete data["installmentQuantityList"];
			delete data["paymentOptionList"];
			delete data["responsibleUserList"];


			data.generateReport = true;
			data.day = "01/01/2018";
			DownloadUtil.download('api/expense/generate-expense-report', data);
		}

		function renderExpenseStatus(p_param) {
			if (p_param.data.expenseStatus) {
				return p_param.data.expenseStatus.defaultDescription;
			}
			else {
				return "";
			}
		}

		function renderExpenseStatusTime(p_param) {
			if(p_param.data && p_param.data.expenseStatusTime && p_param.data.expenseStatusTime.id == "NPR")
			{
				return "<div stop-propagation='true' style='height:100%;background-color:transparent'><span class='uc-pills " + 'uc-pills-green' + "'>" + $translate.instant("common.domain.expense.statusTimeLabel.npr") + "</span></div>";
			}
			else if(p_param.data && p_param.data.expenseStatusTime && p_param.data.expenseStatusTime.id == "ATR")
			{
				return "<div stop-propagation='true' style='height:100%;background-color:transparent'><span class='uc-pills " + 'uc-pills-red' + "'>" + $translate.instant("common.domain.expense.statusTimeLabel.atr") + "</span></div>";
			}
			else {
				return "";
			}
		}

	}
})();
