(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('AttendanceTagDialogController', AttendanceTagDialogController);

        AttendanceTagDialogController.$inject = ['$scope', 'pageConfig', '$uibModalInstance', '$rootScope', '$translate', '$timeout', 'entity', 'UcAlertService', 'FormDomain', 'Attendance', 'clientData', 'ClientAllergy', 'Client', '$uibModal'];

    function AttendanceTagDialogController($scope, pageConfig, $uibModalInstance, $rootScope, $translate, $timeout, entity, UcAlertService, FormDomain, Attendance, clientData, ClientAllergy, Client, $uibModal)
    {
        var vm = this;
        vm.clientData = clientData;
        vm.entity = entity;
        if(vm.clientData.diabetic !== false && vm.clientData.diabetic !== true) {
            vm.clientData.diabetic = false;
        }
        if(vm.clientData.clientAllergiesSet) {
            vm.clientAllergiesSet = vm.clientData.clientAllergiesSet;
        } else {
            vm.clientAllergiesSet = [];
        }
        console.log(vm.clientData);
        console.log(vm.clientAllergiesSet);
        vm.pageConfig = pageConfig;
        var backupAllergy = {};
        vm.editAllergy = function(allergy, index) {
            var allergyCopy = {};
            Object.assign(allergyCopy, allergy);
            backupAllergy[""+allergyCopy.id]= allergyCopy;
            allergy.enabled = true;
            
        }

        var clientOnlyHtml = "";

        vm.addAllergy = function() {
            vm.clientAllergiesSet.push({
                id: null,
                description: "",
                enabled: true,
                clientId: clientData.id,
            });
        }

        vm.saveAllergy = function(allergy, index) {
            allergy.enabled = false;
            if(allergy.id) {
                ClientAllergy.update(allergy);
            } else {
                ClientAllergy.save(allergy, function(res, putResponseHeaders) {
                    vm.clientAllergiesSet[index] = res;
                });
            }
        }

        vm.removeAllergy = function(allergy, index) {
            $uibModal.open({
				templateUrl: 'app/common/delete-dialog/delete-dialog-template.html',
				controller: 'DeleteDialogController',
				controllerAs: 'vm',
				size: 'sm',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
                    service: 'ClientAllergy',
                    obj: allergy
                }
			}).result.then(function(p_)
			{
				if(p_) {
                    vm.clientAllergiesSet.splice(index, 1);
                }
			}, function() {
				/*void*/
			});
        }

        vm.cancelEditAllergy = function(allergy, index) {
            if(allergy.id == null) {
                vm.clientAllergiesSet.splice(index, 1);
            } else {
                vm.clientAllergiesSet[index] = backupAllergy[""+allergy.id];
                vm.clientAllergiesSet[index].enabled = false;
            }
        }


        vm.changeDiabetic = function(value) {
            console.log(value);
            vm.clientData.diabetic = value;
            console.log(vm.clientData.diabetic);
            Client.updateDiabetic({"id": clientData.id, "diabetic": vm.clientData.diabetic});
        }

        vm.printTags = function(hasPatientTag, hasCompanyTag) {
            var win = window.open('','printwindow');
            win.document.write(`<html>
            <head>
                <title>
                    Etiquetas
                </title>
                <style type="text/css">
                .tag-wrapper {
                    font-weight: bold;
                    border: 1px solid;
                    width: 69mm;
                    font-family: monospace;
                    height: 22mm;
                    page-break-after: always;
                    background: white;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                }
                .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            
                .tag-header {
                    -webkit-print-color-adjust: exact;
                    border-radius: 7px 7px 0px 0px;
                    font-size: 8.2pt;
                    display: flex;
                    padding: 2px 14px 2px 2px;
                    border-bottom: 1px solid;
                    justify-content: space-between;
                }
            
                .tag-regis {
                    font-size: 7.9pt;
                }
                .patient-tag .tag-header{
                    background-color: black;
                    color:  white;
                }
                .company-tag .tag-header {
                    background-color: white;
                    color: black;
                }
            
                .tag-title {
                    justify-self: center;
                }
            
                .tag-date {
            
                }
                .allergies-title {
                    font-weight: bold;
                }
            
                .allergies {
                    flex: 1;
                }
            
                .diabetic {
                    padding: 2px 0px;
                    border-top: 1px solid black;
                }
            
                .tag-side-info > * {
                    padding-left: 8px;
                }
                .tag-body {
                    font-size: 6.5pt;
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                }
                .tag-body > * {
                    padding-top: 2px;
                }
                .tag-main-info {
                    flex: 1;
                    padding: 2px;
                    border-right: 1px solid;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
                .tag-main-info, .tag-header {
                    padding-left: 3px !important;
                }
            
                .tag-side-info {
                    width: 70px;
                    display: flex;
                    font-size: 5.8pt;
                    flex-direction: column;
                }
                .allergies-plus {
                    display: inline-block;
                }
                .allergies-plus div {
                    -webkit-print-color-adjust: exact;
                    background-color: black;
                    color: white;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    font-size: 7pt;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .allergies-list {
                    line-break: anywhere;
                }
                .client-name {
                    font-size: 8.2pt;
                }
            </style>
            </head>
            <body style="margin: 0px">`);
            if(hasPatientTag) {
                var patientTag = getTagHtml(true);
                console.log(patientTag);
                win.document.write(patientTag);
            }
            if(hasCompanyTag) {
                var companyTag = getTagHtml(false);
                console.log(companyTag);
                win.document.write(companyTag);
            }
            win.document.write('</body></html>');
            //win.print();
            //win.close();
        }
        function getSmallName(name, charLimit) {
            if(name == null) {
                return "Não informado";
            }
            if(name.length > charLimit) {
                var splitName = name.split(" ");
                var smallName = "";
                for(var i = 0; i < splitName.length; i++) {
                    var name = splitName[i];
                    if(i == 0 || i == splitName.length -1) {
                        smallName += name +" ";
                    } else {
                        smallName += name[0] +". ";
                    }
                }
                if(smallName.length > charLimit) {
                    smallName = smallName.slice(0, charLimit - 3) + "...";
                }
                return smallName;
            } else {
                return name;
            }
        }
        function getTagHtml(isPatient) {
            var tagTemplate = "";
            if(isPatient) {
                tagTemplate += '<div class="tag-wrapper patient-tag">'
            } else {
                tagTemplate += '<div class="tag-wrapper company-tag">'
            }
            var date = moment().format('DD/MM/YYYY');
            var title = isPatient ? "PACIENTE" : "ACOMPANHANTE";
            var clientName = getSmallName(vm.clientData.name, 30);
            var clientBirthdate = vm.clientData.birthDateFormatted;
            var procedureName = getSmallName(vm.entity.procedure.defaultDescription, 28);
            var attendanceId = vm.entity.id;
            var clientSgrId = vm.clientData.id;
            var clientCadClinId = vm.clientData.cadclinId;
            var doctorName = getSmallName(vm.entity.medic.defaultDescription, 29);
            console.log(doctorName);
            var motherName = getSmallName(vm.clientData.motherName, 32);
            var allergies = "";
            var diabetic = "";
            var charLimit = 50;
            if(vm.clientData.diabetic) {
                diabetic += `<div class="diabetic">
                    DIABETES
                </div>`;
                charLimit = 30;
            }
            for(var i = 0; i < vm.clientAllergiesSet.length; i++) {
                var allergy = vm.clientAllergiesSet[i];
                if((allergies.length + allergy.description.length) > charLimit) {
                    i = vm.clientAllergiesSet.length;
                    allergies += '... <div class="allergies-plus"><div>+</div></div>'
                    break;
                } else {
                    if(i == 0) {
                        allergies += allergy.description;
                    } else {
                        allergies += ', ' + allergy.description;
                    }
                }
                
            };
           
           tagTemplate += `
                <div class="tag-header">
                    <span class="tag-date">${date}</span>
                    <span class="tag-title">${title}</span>
                    <span class="tag-regis">REGIS</span>
                </div>
                <div class="tag-body">
                    <div class="tag-main-info">
                        <div class="tag-info client-name">
                            <span>${clientName}</span>
                        </div>
                        <div class="row">
                            <div class="tag-info">
                                <b>
                                    ID SGR:
                                </b>
                                <span>${clientSgrId}</span>
                            </div>
                            <div class="tag-info">
                                <b>
                                    ID CC:
                                </b>
                                <span>${clientCadClinId ? clientCadClinId : "Não Informado"}</span>
                            </div>
                        </div>
                        
                        <div class="tag-info">
                            <b>
                                Proced.:
                            </b>
                            <span>${procedureName}</span>
                        </div>
                        <div class="tag-info">
                            <b>
                                Mãe:
                            </b>
                            <span>${motherName ? motherName : "Não Informado"}</span>
                        </div>
                        <div class="tag-info">
                            <b>
                                Médico:
                            </b>
                            <span>${doctorName}</span>
                        </div>
                    </div>
                    <div class="tag-side-info">
                        <div class="allergies">
                            <span class="allergies-title">
                                Alergias
                            </span>
                            <div class="allergies-list">${allergies}</div>
                                
                        </div>
                        ${diabetic}
                    </div>
                </div>
            </div>
            `
            return tagTemplate;
        }
		/**
		 * Cancel or top-icon close
		 */
         /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
	}
})();