(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('FormButtonDirectiveController', FormButtonDirectiveController);

    FormButtonDirectiveController.$inject = ['$scope', '$state', '$stateParams', '$translate', '$rootScope', 'UcDatasourceService', 'UcAlertService'];
    function FormButtonDirectiveController($scope, $state, $stateParams, $translate, $rootScope, UcDatasourceService, UcAlertService)
    {
    	var vm = this;
    	
    	var defaultModel = 
    	{
    		isSaving: false,
    		useSuggestedButton: true,
    		canClear: true,
    		customButtonList: [],
    		onCreate: function() { defaultOnCreate(); },
    		beforeCreate: null,
    		beforeEdit: null,
			canDelete: true
    	};
    	
    	vm.model = $scope.model;
    	var pageMode = ($scope.pageConfig) ? $scope.pageConfig.pageMode : null;

    	var mergeModel = defaultModel;
    	angular.extend(mergeModel, vm.model);
    	vm.model = mergeModel;

    	vm.editMode = (pageMode !== 'READONLY');
    	
    	
    	/*public*/ vm.buttonList = [];
    	
    	/*private*/ function init()
    	{
    		// Se a sugestão estiver habilidada, adiciona por tipo
    		if(vm.model.useSuggestedButton)
    		{
    			if(pageMode === 'FILTER')
    			{
    				vm.buttonList.push(templates.FILTER_BUTTON(), templates.CREATE_BUTTON(), templates.CLEAR_BUTTON());
    			}
    			else if(pageMode === 'READONLY')
    			{
    				vm.buttonList.push(templates.EDIT_BUTTON(), templates.DELETE_BUTTON(), templates.BACK_BUTTON());
    			}
    			else if(pageMode === 'CREATE')
    			{
    				vm.buttonList.push(templates.BACK_BUTTON(), templates.SAVE_BUTTON(), templates.CLEAR_BUTTON());
    			}
    			else if(pageMode === 'EDIT')
    			{
    				vm.buttonList.push(templates.SAVE_BUTTON(), templates.DELETE_BUTTON(), templates.CANCEL_BUTTON(), templates.BACK_BUTTON());
    			}
    			else if(pageMode === "CUSTOM_EDIT") // Lista própria
    			{
    				vm.buttonList = vm.model.customButtonList;
    			}
    		}

        	if(vm.model.extraButtonList)
        	{
        		vm.buttonList = vm.buttonList.concat(vm.model.extraButtonList);
        	}
        	
        	// ordena a lista de botões
        	vm.buttonList.sort(function(p_1, p_2)
        	{
        		return p_1.order - p_2.order;
        	});
    	}

    	/*private*/ var templates =
    	{
	        SAVE_BUTTON: function()
	        {
	        	return {
		        	order: 0,
		        	cssClass: 'btn btn-success',
		        	cssClassIcon: 'glyphicon glyphicon-save',
		        	label: 'client.global.label.save',
		        	type: 'submit',
		        	
		        	isDisabled: function()
		        	{
		        		return vm.model.isSaving;
		        	},
		        	isToAdd: function()
		        	{
		        		// Se estivem em edição...
		        		if(vm.editMode == true)
		        		{
		        			if($scope.pageConfig.permission)
		        			{
			        			// ... checa as permissões por pageMode
			        			if(pageMode === 'CREATE' && userHasPermission($scope.pageConfig.account, $scope.pageConfig.permission.create))
			        			{
			        				return true;
			        			}
			        			if(pageMode === 'EDIT' && userHasPermission($scope.pageConfig.account, $scope.pageConfig.permission.edit))
			        			{
			        				return true;
			        			}
		        			}		        			
		        		}
		        		return false;
		        	},
		        	click: function()
		        	{
		        		/* void - por ser submit, a ação está no ng-submit do form */
		        	}
		        };
	        },
	        
	        BACK_BUTTON: function()
	        {
	        	return {
		        	order: 100,
		        	cssClass: 'btn btn-default',
		        	cssClassIcon: 'glyphicon glyphicon-arrow-left',
		        	label: 'client.global.label.back',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return false;
		        	},
		        	isToAdd: function()
		        	{
		        		return true;
		        	},
		        	click: function()
		        	{
		        		$state.go(vm.model.previousState.name);
		        	}
	        	}
	        },

	        CANCEL_BUTTON: function()
	        {
	        	return {
		        	order: 100,
		        	cssClass: 'btn btn-accent',
		        	cssClassIcon: 'fa fa-undo',
		        	label: 'client.global.label.cancel',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return false;
		        	},
		        	isToAdd: function()
		        	{
		        		return true;
		        	},
		        	click: function()
		        	{
		        		$state.go(vm.model.stateRoot + '.detail', {id: $stateParams.id});
		        	}
	        	}
	        },

	        CLEAR_BUTTON: function()
	        {
		        return {
		        	order: 70,
		        	cssClass: 'btn btn-danger',
		        	cssClassIcon: 'fa fa-eraser',
		        	label: 'client.global.label.reset',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return false;
		        	},
		        	isToAdd: function()
		        	{
		        		return vm.model.canClear;
		        	},
		        	click: function()
		        	{
		        		if(vm.model.onClear)
		        		{
		        			vm.model.onClear();
		        		}
		        		else
		        		{
		        			console.error("[uc] onClear not defined");
		        		}
		        	}
		        }
	        },
	        
	        DELETE_BUTTON: function()
	        {
	        	return {
		        	order: 0,
		        	cssClass: 'btn btn-danger',
		        	cssClassIcon: 'glyphicon glyphicon-remove',
		        	label: 'client.global.label.delete',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return !vm.model.canDelete;
		        	},
		        	isToAdd: function()
		        	{
		        		// Só se tiver a permissão
		        		return ($scope.pageConfig.permission && userHasPermission($scope.pageConfig.account, $scope.pageConfig.permission["delete"]));
		        	},
		        	click: function()
		        	{
		        		defaultOnDelete();
		        	}
	        	}
	        },

	        EDIT_BUTTON: function()
	        {
	        	return {
		        	order: 0,
		        	cssClass: 'btn btn-success',
		        	cssClassIcon: 'glyphicon glyphicon-pencil',
		        	label: 'client.global.label.edit',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return false;
		        	},
		        	isToAdd: function()
		        	{
		        		// Só se tiver a permissão
		        		return ($scope.pageConfig.permission && userHasPermission($scope.pageConfig.account, $scope.pageConfig.permission.edit));
		        	},
		        	click: function()
		        	{
		        		if(vm.model.beforeEdit)
		        		{
		        			if(!vm.model.beforeEdit())
		        			{
		        				return;
		        			}
		        		}
		        		$state.go(vm.model.stateRoot + '.edit', {id: $stateParams.id});
		        	}
	        	}
	        },

	        ///////////////////////////////////////////////////////
	        
	        FILTER_BUTTON: function()
	        {
	        	return {
		        	order: 0,
		        	cssClass: 'btn btn-accent',
		        	cssClassIcon: 'glyphicon glyphicon-search',
		        	label: 'client.global.label.filter',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return false;
		        	},
		        	isToAdd: function()
		        	{
		        		return true;
		        	},
		        	click: function()
		        	{
		        		if(vm.model.onFilter)
		        		{
		        			vm.model.onFilter();
		        		}
		        		else
		        		{
		        			console.error("[uc] onFilter not defined");
		        		}
		        	}
	        	}
	        },
	        
	        CREATE_BUTTON: function()
	        {
	        	return {
		        	order: 50,
		        	cssClass: 'btn btn-success',
		        	cssClassIcon: 'glyphicon glyphicon-plus',
		        	label: 'client.global.label.create',
		        	type: 'button',
		        	
		        	isDisabled: function()
		        	{
		        		return false;
		        	},
		        	isToAdd: function()
		        	{
		        		// Só se tiver a permissão
		        		return ($scope.pageConfig.permission && userHasPermission($scope.pageConfig.account, $scope.pageConfig.permission.create));
		        	},
		        	click: function()
		        	{
		        		if(vm.model.beforeCreate)
		        		{
		        			if(!vm.model.beforeCreate())
		        			{
		        				return;
		        			}
		        		}
		        		
		        		if(vm.model.onCreate)
		        		{
		        			vm.model.onCreate();
		        		}
		        		else
		        		{
		        			console.error("[uc] onCreate not defined");
		        		}
		        	}
	        	}
	        },	 	        
    	};
    	
    	/*private*/ function defaultOnCreate()
		{
			if(vm.model.stateRoot)
			{
				$state.go(vm.model.stateRoot + ".new");
			}
			else
			{
				console.log("[uc] FormButtonDirective - stateRoot not defined");
			}
		};
		
		/*private*/ function defaultOnDelete()
		{
			var message = $translate.instant("client.global.messages.info.deleteRecord") + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
			
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					UcDatasourceService.delete(
					{
						id: $stateParams.id,
						gridId: vm.model.gridId
					},
					function(p_customMessage)
					{
						UcAlertService.success($translate.instant("client.global.messages.info.operationSuccessfuly"));
						$state.go(vm.model.previousState.name);
					});
				} 
			});
    	};

    	init();
    };
})();