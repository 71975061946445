(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('sgmApp')
        .constant('VERSION', "1.1.6")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('VERSION_DATE', "17/06/2024")
;
})();
