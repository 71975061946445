(function () {
	'use strict';

	angular
		.module('sgmApp')
		.controller('ProductKitController', ProductKitController);

	ProductKitController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'Principal', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'ProductKit', 'FrontEndSelectDataSource', 'FormConstants', 'TabStatePersister'];

	function ProductKitController(FormFilter, UcGridService, $translate, account, Principal, filterPageConfig, gridDefaultSort, $rootScope, ProductKit, FrontEndSelectDataSource, FormConstants, TabStatePersister) {
		var vm = this;

		vm.pageConfig = filterPageConfig;
		vm.sort = gridDefaultSort;

		vm.buttonsModel =
			{
				stateRoot: "productKit",
				onFilter: function () { doFilter(); },
				onClear: function () { doClear(); },
			};

		$rootScope.initialFocus("description");

		var formOptions = {
			formStatePersisterId: "ProductKit" 
		};

		vm.formHandler = FormFilter.create(formOptions)
			.add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "String",
					id: "description",
					label: "common.domain.productKit.description"
				}),
				new FormField({
		       		cellCssClass: "col-sm-2",
					type: "Select",
					id: "active",
					label: "common.domain.productKit.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
				})
				
			])
			.endConfig();

		vm.gridFieldList = UcGridService.createFieldList([
			{ headerName: $translate.instant("common.domain.productKit.id"), field: "id", cellStyle: { alignItems: "center", textAlign: "center" }, width: 1 },
			{ headerName: $translate.instant("common.domain.productKit.description"), field: "description", cellStyle: { alignItems: "center", textAlign: "center" }, width: 40 },
			UcGridService.field.ACTIVE()
		]);

		var grid = UcGridService.create(
			{
				gridId: "PRODUCT_KIT",
				stateBaseName: "productKit",
				account: account,
				getFilterData: function () { return vm.formHandler.readForm(); },
				gridFieldList: vm.gridFieldList,
				sort: vm.sort,
				beforeOnRowClicked: function() { return clearEditTabState(); },
			});

		
		/*
		 * Limpa o tab da página de edição
		 */
		/*private*/ function clearEditTabState()
		{
			TabStatePersister.reset("ProductKitDomainController");
			
			// Retorna true para não interromper o fluxo da operação
			return true;
		};
		
		vm.gridOptions = grid.getGridOptions();

        /*private*/ function doFilter() {
			grid.refresh();
		};

        /*private*/ function doClear() {
			vm.formHandler.clear();
			doFilter();
		};
		
		function generateReport() {
		/* 	var data = vm.formHandler.readForm();
			delete data["categoryList"];
			delete data["subcategoryList"];
			
			data.generateReport = true;
			DownloadUtil.download('api/productkit/generate-report', data); */
		}

	}
})();
