(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('SupplierManagerTabCategoryController', SupplierManagerTabCategoryController);
    
    SupplierManagerTabCategoryController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout'];
    function SupplierManagerTabCategoryController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

    };
})();