(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsTemplateDirectoryModalInvoker', RecordsTemplateDirectoryModalInvoker);
	
	RecordsTemplateDirectoryModalInvoker.$inject = ['$uibModal'];
	
	function RecordsTemplateDirectoryModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/template/directory/records-template-directory-modal-dialog.html',
                controller: 'RecordsTemplateDirectoryModalController',
                controllerAs: 'vm',
                backdrop  : 'static',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT"
                    	})
                    }],
                    recordType: function()
                    {
                    	return p_param.recordType;
                    },
                    entity: ['RecordsTemplateDir', function(RecordsTemplateDir)
                    {
                    	if(p_param.templateDirId)
                    	{
                    		return RecordsTemplateDir.get({id: p_param.templateDirId}).$promise;
                    	}
                    	else
                    	{
                    		return {name: null};
                    	}
                    }],
                }
            }).result.then(function(p_flag)
            {
            	if(p_param.callback)
            	{
            		p_param.callback(p_flag);
            	}

            });
		};
	};
})();
