(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('ConfirmDialogInvoker', ConfirmDialogInvoker);

		ConfirmDialogInvoker.$inject = ['$uibModal'];

    function ConfirmDialogInvoker($uibModal)
    {
		var vm = this;

		/*public*/ vm.invoke = function(p_param)
		{
			$uibModal.open({
				templateUrl: 'app/common/confirm-dialog/confirm-dialog-template.html',
				controller: 'ConfirmDialogController',
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					text: () => p_param.text,
                    title: () => p_param.title,
					hideButtons: () => p_param.hideButtons
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				if(p_param.dismiss)
				{
					p_param.dismiss();
				}
			});
		}
    }
})();