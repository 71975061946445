(function(){
	'use strict';
	angular.module('sgmApp').controller('ManufacturerProductStockFlowDialogController', ManufacturerProductStockFlowDialogController);

	ManufacturerProductStockFlowDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'ManufacturerProductStockFlow', 'isInput', 'entity'];


	function ManufacturerProductStockFlowDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, ManufacturerProductStockFlow, isInput, entity){

		var vm = this;
		vm.pageConfig = pageConfig;
		vm.isInput = isInput;
		if(entity) {
			if(entity.manufacturerProduct) {
				if(entity.manufacturerProduct.manufacturer) {
					entity.manufacturerTradingName = entity.manufacturerProduct.manufacturer.tradingName;
				}
				entity.manufacturerProductName = entity.manufacturerProduct.name;
				if(entity.manufacturerProduct.product) {
					entity.productDescription = entity.manufacturerProduct.product.description;
					if(entity.manufacturerProduct.product.measureUnit) {
						entity.productMeasureUnit = entity.manufacturerProduct.product.measureUnit.description;
					}
				}
			}
			if(entity.manufacturerProductBatch) {
				entity.manufacturerProductBatchCode = entity.manufacturerProductBatch.batchCode;
				entity.manufacturerProductBatchExpirationDate =  entity.manufacturerProductBatch.expirationDateWithoutTimezone ? moment(entity.manufacturerProductBatch.expirationDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : ''
				entity.manufacturerProductBatchExpirationDateView =  entity.manufacturerProductBatch.expirationDateWithoutTimezone ? moment(entity.manufacturerProductBatch.expirationDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : ''
			}
			entity.createdDate = entity.createdDateWithoutTimezone ? moment(entity.createdDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
			entity.flowDate = entity.flowDateWithoutTimezone ? moment(entity.flowDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
			entity.nfDate = entity.nfDateWithoutTimezone ? moment(entity.nfDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
		}
		vm.entity = entity || {};
		let isNewBatch = false;
		console.log(vm.entity);
		var manufacturerProductFormField = new FormField({
			type: "Select",
			id: "manufacturerProduct",
			dependencyName: "manufacturerProductId",
			required: true,
			label: "client.page.manufacturerProductStockFlow.dialog.selectManufacturerProduct",
			cellCssClass: "col-sm-6",
			displayProp: vm.entity.id ? "barCode" : null,
			dataSource: FormConstants.DataSources.BackEnd.ManufacturerProduct(BackEndSelectDataSource, $translate, 'barCodeOrName',
				function(optionsList) {
					optionsList.forEach((option) => {
						if(!vm.entity.id) {
							option.defaultDescription  += " (COD: " + option.barCode + ")";
						} else {
							option.defaultDescription = option.barCode;
						}
					});
					return optionsList;
				},
				null,
				null,
				null,
				function(option) {
					return option.barCode;
				}
			),
			onChange: function(value) {
				var manufacturerProduct = value.manufacturerProduct;
				let entity = vm.formHandler.getModel();
				if(manufacturerProduct) {
					entity.manufacturerTradingName = manufacturerProduct.manufacturer.tradingName;
					entity.manufacturerProductName = manufacturerProduct.name;
					entity.productDescription = manufacturerProduct.product.description;
					entity.productMeasureUnit = manufacturerProduct.product.measureUnit.description;
				}
				else {
					entity.manufacturerTradingName = null;
					entity.manufacturerProductName = null;
					entity.productDescription = null;
					entity.productMeasureUnit = null;
				}
			}
		});

		var stockPlaceFormField = new FormField({
			type: "Select",
			id: "stockPlace",
			dependencyName: "stockPlaceId",
			required: true,
			label: "client.page.manufacturerProductStockFlow.dialog.selectStockPlace",
			cellCssClass: "col-sm-6",
			dataSource: FormConstants.DataSources.BackEnd.StockPlace(BackEndSelectDataSource, $translate),
		});
		vm.isReadonly = pageConfig.pageMode == "READONLY";
		var formOptions = {
				model: vm.entity,
				service: ManufacturerProductStockFlow,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormManufacturerProductStockFlow; }
	        };

		vm.formHandler = FormDomain.create(formOptions)
		.add([
			new FormField({
				cellCssClass: "col-sm-2",
				type: "String",
				id: "id",
				label: "common.domain.manufacturerProductStockFlow.id",
				readOnly: true,
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			}),
			new FormField({
				cellCssClass: "col-sm-5",
				readOnly: true,
				type: "DateTime",
				id: "createdDate",
				label: "common.domain.manufacturerProductStockFlow.createdDate",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			}),
			new FormField({
				cellCssClass: "col-sm-5",
				type: "String",
				id: "userName",
				readOnly: true,
				label: "common.domain.manufacturerProductStockFlow.user",
				addFieldListener: FormConstants.AddFieldListeners.IdNotNull
			}),
		])
		.add([
				manufacturerProductFormField,
				new FormField({
					type: "String",
					readOnly: true,
					id: "manufacturerTradingName",
					label: "common.domain.manufacturerProductStockFlow.manufacturerTradingName",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "String",
					readOnly: true,
					id: "manufacturerProductName",
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductName",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "String",
					readOnly: true,
					id: "productDescription",
					label: "common.domain.manufacturerProductStockFlow.productDescription",
					cellCssClass: "col-sm-6",
				}),
			]).add([
				new FormField({
					cellCssClass: "col-sm-2",
					type: "Double",
					id: "amount",
					required: true,
					onChange: function(value) {
						updateUnitValue(value);
					},
					label: "common.domain.manufacturerProductStockFlow.amount",
				}),
				new FormField({
					cellCssClass: "col-sm-2",
					type: "String",
					id: "productMeasureUnit",
					readOnly: true,
					label: "common.domain.manufacturerProductStockFlow.productMeasureUnit",
				}),
				new FormField({
					type: "Money",
					id: "totalValue",
					hide: !isInput,
					cellCssClass: "col-sm-4",
					required: isInput,
					onChange: function(value) {
						updateUnitValue(value);
					},
					label: "common.domain.manufacturerProductStockFlow.totalValue",
				}),
				new FormField({
					type: "Money",
					readOnly: true,
					hide: !isInput,
					id: "unitValue",
					cellCssClass: "col-sm-4",
					required: isInput,
					label: "common.domain.manufacturerProductStockFlow.unitValue",
				}),
				new FormField({
					type: "Select",
					id: "stockFlowMotive",
					hide: isInput || (vm.entity.attendance != null && vm.entity.attendance.id),
					label: "common.domain.manufacturerProductStockFlow.stockFlowMotive",
					cellCssClass: "col-sm-4",
					dataSource: FormConstants.DataSources.BackEnd.StockFlowMotive(BackEndSelectDataSource, $translate),
				}),
				new FormField({
					type: "Select",
					id: "attendance",
					readOnly: true,
					hide: isInput || !(vm.entity.attendance),
					label: "common.domain.manufacturerProductStockFlow.attendance",
					cellCssClass: "col-sm-4",
					dataSource: FormConstants.DataSources.BackEnd.Attendance(BackEndSelectDataSource, $translate),
				}),
			]).add([
				new FormField({
					type: "Boolean",
					hide: !isInput || vm.isReadonly,
					id: "isNewBatch",
					cellCssClass: "col-sm-12",
					onChange: function(value) {
						isNewBatch = value.isNewBatch;
						if(isNewBatch) {
							entity.manufacturerProductBatch = null;
							entity.manufacturerProductBatchId = null;
						}
						changeBatchFieldsVisibility();
					},
					label: "client.page.manufacturerProductStockFlow.dialog.isNewBatch",
				}),
				stockPlaceFormField,
				new FormField({
					type: "Select",
					id: "manufacturerProductBatch",
					required: true,
					label: "client.page.manufacturerProductStockFlow.dialog.selectManufacturerProductBatch",
					cellCssClass: "col-sm-6",
					dataSource: FormConstants.DataSources.BackEnd.ManufacturerProductBatch(
						BackEndSelectDataSource,	
						vm.isInput ? manufacturerProductFormField : [manufacturerProductFormField, stockPlaceFormField],
						function(optionsList) {
							var newList = [];
							if(vm.isInput) {
								return optionsList;
							} else {
								optionsList.forEach((option) => {
									if(option.amountOnStock > 0.0) {
										option.defaultDescription  += " (Qtd: " + option.amountOnStock + ")";
										newList.push(option);
									}
								});
							}
							return newList;
						}
					),
					onChange: function(value) {
						var manufacturerProductBatch = value.manufacturerProductBatch;
						let entity = vm.formHandler.getModel();
						if(manufacturerProductBatch) {
							entity.manufacturerProductBatchCode = manufacturerProductBatch.batchCode;
							entity.manufacturerProductBatchExpirationDate = manufacturerProductBatch.expirationDateWithoutTimezone ? moment(manufacturerProductBatch.expirationDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
							entity.manufacturerProductBatchExpirationDateView = manufacturerProductBatch.expirationDateWithoutTimezone ? moment(manufacturerProductBatch.expirationDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : ''
						}
						else {
							entity.manufacturerProductBatchCode = null;
							entity.manufacturerProductBatchExpirationDate = null;
						}
					}
				}),
				new FormField({
					type: "String",
					hide: !isInput,
					id: "manufacturerProductBatchCode",
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductBatchCode",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "Date",
					id: "manufacturerProductBatchExpirationDate",
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductBatchExpirationDate",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "Date",
					hide: true,
					readOnly: true,
					id: "manufacturerProductBatchExpirationDateView",
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductBatchExpirationDate",
					cellCssClass: "col-sm-6",
				}),
				
				new FormField({
					type: "String",
					id: "nfNumber",
					hide: !isInput,
					label: "common.domain.manufacturerProductStockFlow.nfNumber",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "Date",
					id: "nfDate",
					hide: !isInput,
					label: "common.domain.manufacturerProductStockFlow.nfDate",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "Select",
					id: "supplier",
					hide: !isInput,
					label: "common.domain.manufacturerProductStockFlow.supplier",
					cellCssClass: "col-sm-6",
					dataSource: FormConstants.DataSources.BackEnd.Supplier(BackEndSelectDataSource, $translate, {
						productSupplier: true
					}),
				}),
			])
			.add([
				new FormField({
					cellCssClass: "col-sm-6",
					required: true,
					type: "DateTime",
					id: "flowDate",
					nowAsMaxDate: true,
					label: "common.domain.manufacturerProductStockFlow.flowDate"
				}),
				new FormField({
					type: "String",
					id: "obs",
					label: "common.domain.manufacturerProductStockFlow.obs",
					cellCssClass: "col-sm-6",
				}),
			])

		changeBatchFieldsVisibility();
		 /*public*/ vm.closeModal = function(manufacturerProductStockFlow)
		 {
			$uibModalInstance.close(manufacturerProductStockFlow);
        };
		if(!isInput) {
			vm.formHandler.findField("stockFlowMotive").required = true;
		}
        vm.onSaveManufacturerProductStockFlow = function(){

        	var domain = null;
			//if is save needs to validate and return the object
			vm.formHandler.reloadValidation($scope.editFormManufacturerProductStockFlow);
			FormUtil.validateAll($scope.editFormManufacturerProductStockFlow, vm.formHandler.getRowList());
			console.log($scope.editFormManufacturerProductStockFlow);
			console.log(vm.formHandler.readForm());
        	if($scope.editFormManufacturerProductStockFlow.$valid) {
				domain = vm.formHandler.readForm();

				vm.closeModal(domain);
			}
        	//
        }

		function changeBatchFieldsVisibility() {
			vm.formHandler.findField("manufacturerProductBatch").hide = isNewBatch;
			vm.formHandler.findField("manufacturerProductBatch").required = !isNewBatch;
			vm.formHandler.findField("manufacturerProductBatchCode").hide = !isNewBatch || !isInput;
			vm.formHandler.findField("manufacturerProductBatchCode").required = isNewBatch;
			vm.formHandler.findField("manufacturerProductBatchExpirationDate").hide = !isNewBatch;
			vm.formHandler.findField("manufacturerProductBatchExpirationDateView").hide = isNewBatch;
			vm.formHandler.findField("manufacturerProductBatchExpirationDate").required = isNewBatch;
			vm.formHandler.reloadValidation($scope.editFormManufacturerProductStockFlow);
		}

		function updateUnitValue(value) {
			if(value.amount > 0) {
				vm.formHandler.getModel().unitValue = Number.parseFloat((value.totalValue/value.amount).toFixed(2));
			} else {
				vm.formHandler.getModel().unitValue = null;
			}
		}

		/*private*/ function renderPlannedDateGridCell(p_param)
		{
			var code = "";
			if(p_param.data)
			{
				code = p_param.data.plannedDateFull;
				if(code)
				{
					code = "<div class='planned-date-grid-cell'><div>" + $.trim(code).replace(" ", "</div><div>") + "</div></div>";
				}
			}
			return code;
		}
	}
})();
