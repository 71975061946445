(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .service('ScheduleAttendanceDialogInvoker', ScheduleAttendanceDialogInvoker);

    ScheduleAttendanceDialogInvoker.$inject = ['$uibModal'];

    function ScheduleAttendanceDialogInvoker($uibModal)
    {
		var vm = this;
		
		/*public*/ vm.invoke = function(/*ScheduleAttendanceDialogInvokerParam*/ p_param)
		{
			$uibModal.open({
				templateUrl: 'app/pages/managers/attendance/schedule/schedule-attendance-dialog.html',
				controller: 'ScheduleAttendanceDialogController',
				controllerAs: 'vm',
				size: 'lg',
				windowClass: 'modal-dialog-90-percent modal-dialog-schedule',
				resolve: {
                    dataSchedule: function()
                    {
                        return p_param.objData;
                    },
					hasAttendancePermissions: function()
                    {
                        return p_param.hasAttendancePermissions;
                    },
                    rescheduleAttendance: ['Attendance', function(Attendance)
                    {
                    	if(p_param.rescheduleAttendanceId)
                    	{
                    		return Attendance.get({id: p_param.rescheduleAttendanceId}).$promise;
                    	}
                    	else
                    	{
                    		return null;
                    	}
                    }],
                    schedulableList: ['Attendance', function(Attendance)
                    {
                    	return Attendance.listSchedulable({}).$promise;
                    }],
                    attendanceStatusList: ['Attendance', function(Attendance)
                    {
                    	return Attendance.listStatus().$promise;
                    }]
                    /*,
                    availabilityScheduleList: ['$rootScope', 'account', function($rootScope, account)
                    {
                    	Attendance.listAvailabilitySchedule({ id: id }).$promise;
                    }]*/
                }
			}).result.then(function(p_)
			{
				if(p_param.callback)
				{
					p_param.callback(p_);
				}
			}, function() {
				/*void*/
			});
		}
    }
})();
function ScheduleAttendanceDialogInvokerParam(p_param)
{
	var _DEFAULT_PARAM =
	{
		objData: null,
		hasAttendancePermissions: false,
		rescheduleAttendanceId: null,
		callback: null
	};
	
	// Carrega os valores default
	angular.extend(this, _DEFAULT_PARAM);
	
	// Sobreescreve só os passados como parâmetro
	angular.extend(this, (p_param) ? p_param : {});
}
