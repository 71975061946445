(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('staticTooltipBox', staticTooltipBoxDirective);
    
    staticTooltipBoxDirective.$inject = [];
    function staticTooltipBoxDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "StaticTooltipBoxDirectiveController",
            templateUrl: "app/components/static-tooltip-box/static-tooltip-box.directive.template.html",
            scope:
            {
            	config: '='
            }
    	}
    };
})();
