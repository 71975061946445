(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('expenseManager', expenseManagerDirective);
    
    expenseManagerDirective.$inject = [];
    function expenseManagerDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "ExpenseManagerDirectiveController",
            templateUrl: "app/pages/managers/expense/manager/expense-manager.directive.template.html",
            scope:
            {
            	source: '@',
            	pageConfig: '=',
            	injectedResources: '=',
            	domain: '=',
            	uibModalInstance: '='
            }
    	}
    };
})();
