(function(){
	'use strict';
	angular.module('sgmApp').controller('AttendanceProductDialogController', AttendanceProductDialogController);
	
	AttendanceProductDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'AttendanceProduct', 'entity', 'isUpdate', 'save', 'closeOnSave', 'index', 'length', 'ConfirmDialogInvoker'];
	
	
	function AttendanceProductDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, AttendanceProduct, entity, isUpdate, save, closeOnSave, index, length, ConfirmDialogInvoker){
		
		var vm = this;
		
		const _entity = {};
		if(entity) {
			entity = Object.assign(_entity, entity);
			entity.manufacturerTradingName = "";
			entity.manufacturerProductBarCode = "";
			if(entity.manufacturerProduct) {
				entity.manufacturerProductBarCode = entity.manufacturerProduct.barCode;
				if(entity.manufacturerProduct.manufacturer) {
					entity.manufacturerTradingName = entity.manufacturerProduct.manufacturer.tradingName;
				}
			}
			if(entity.manufacturerProductBatch) {
				entity.manufacturerProductBatchExpirationDate =  entity.manufacturerProductBatch.expirationDateWithoutTimezone ? moment(entity.manufacturerProductBatch.expirationDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
			}
		}
		entity = _entity;
		vm.pageConfig = pageConfig;
		if(entity.finalTotalExpectedAmount) {
			vm.pageConfig.pageMode = "READONLY";
		}
		
		vm.isUpdate = isUpdate || false;
		vm.length = length;
		vm.index = index;
		var formOptions = {
				model: entity,
				service: AttendanceProduct,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormAttendanceProduct; }
	        };

			var productFormField = new FormField({
			type: "Select",
			id: "product",
			required: !vm.isUpdate,
			dependencyName: "productId",
			readOnly: entity.autoGenerated,
			label: "client.page.attendance.tabs.attendanceProduct.dialog.selectProduct",
			cellCssClass: "col-sm-6",
			onChange: function(value) {
				if(value && value.product) {
					vm.formHandler.findField("manufacturerProduct").readOnly = false;
				} else {
					vm.formHandler.findField("manufacturerProduct").readOnly = true;
				}
			},
			dataSource: FormConstants.DataSources.BackEnd.Product(BackEndSelectDataSource, $translate)
		});

		var manufacturerProductFormField = new FormField({
			type: "Select",
			id: "manufacturerProduct",
			dependencyName: "manufacturerProductId",
			readOnly: !vm.isUpdate || !entity.product,
			label: "client.page.attendance.tabs.attendanceProduct.dialog.selectManufacturerProduct",
			cellCssClass: "col-sm-6",
			onChange: function(value) {
				var manufacturerProduct = value.manufacturerProduct;
				var entity = vm.formHandler.getModel();
				if(manufacturerProduct) {
					entity.manufacturerTradingName = manufacturerProduct.manufacturer.tradingName;
					entity.manufacturerProductBarCode = manufacturerProduct.barCode;
				}
				else {
					entity.manufacturerTradingName = null;
					entity.manufacturerProductBarCode = null;
				}
			},
			dataSource: FormConstants.DataSources.BackEnd.ManufacturerProduct(BackEndSelectDataSource, $translate, 'barCodeOrName',
				function(optionsList) {
					optionsList.forEach((option) => {
						option.defaultDescription  += " (COD: " + option.barCode + ") | Qtd: " +option.amountOnStock;
					});
					return optionsList;
				},
				{
					searchActiveOnly: true,
					searchWithAmountInStock: true
				},
				productFormField
			)
		});
		var stockPlaceFormField = new FormField({
			type: "Select",
			id: "stockPlace",
			dependencyName: "stockPlaceId",
			required: true,
			label: "client.page.manufacturerProductStockFlow.dialog.selectStockPlace",
			cellCssClass: "col-sm-6",
			dataSource: FormConstants.DataSources.BackEnd.StockPlace(BackEndSelectDataSource, $translate, 
				manufacturerProductFormField,
				function(optionsList) {
					optionsList.forEach((option) => {
						option.defaultDescription  += " | Qtd: " +option.amountOnStock;
					});
					return optionsList;
				},
				{
					searchWithAmountInStock: true
				}
			)
		});
		vm.formHandler = FormDomain.create(formOptions).
			add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.attendanceProduct.id",
					readOnly: true,
					hide: true,
				}),
			]).add([
				productFormField,
				manufacturerProductFormField,
				new FormField({
					type: "String",
					id: "manufacturerTradingName",
					hide: !vm.isUpdate,
					readOnly: true,
					label: "common.domain.manufacturerProduct.manufacturerTradingName",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					type: "String",
					id: "manufacturerProductBarCode",
					hide: !vm.isUpdate,
					readOnly: true,
					label: "common.domain.manufacturerProduct.barCode",
					cellCssClass: "col-sm-6",
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Double",
					id: "expectedAmount",
					required: true,
					hide: vm.isUpdate,
					precision: 2,
					label: "common.domain.attendanceProduct.expectedAmount"
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Double",
					id: "totalExpectedAmount",
					hide: !vm.isUpdate,
					readOnly: true,
					label: "common.domain.attendanceProduct.expectedAmount"
				}),
				new FormField({
					cellCssClass: "col-sm-6",
					type: "Double",
					id: "usedAmount",
					precision: 2,
					label: "common.domain.attendanceProduct.usedAmount"
				}),
				stockPlaceFormField,
				new FormField({
					type: "Select",
					id: "manufacturerProductBatch",
					required: true,
					label: "client.page.manufacturerProductStockFlow.dialog.selectManufacturerProductBatch",
					cellCssClass: "col-sm-6",
					dataSource: FormConstants.DataSources.BackEnd.ManufacturerProductBatch(
						BackEndSelectDataSource,	
						[manufacturerProductFormField, stockPlaceFormField],
						function(optionsList) {
							var newList = [];
							optionsList.forEach((option) => {
								if(option.amountOnStock > 0.0) {
									option.defaultDescription  += " | Qtd: " + option.amountOnStock;
									newList.push(option);
								}
								
							});
							return newList;
						}
					),
					onChange: function(value) {
						var manufacturerProductBatch = value.manufacturerProductBatch;
						let entity = vm.formHandler.getModel();
						if(manufacturerProductBatch) {
							entity.manufacturerProductBatchCode = manufacturerProductBatch.batchCode;
							entity.manufacturerProductBatchExpirationDate = manufacturerProductBatch.expirationDateWithoutTimezone ? moment(manufacturerProductBatch.expirationDateWithoutTimezone, "YYYY-MM-DD HH:mm:ss")._d : '';
						}
						else {
							entity.manufacturerProductBatchCode = null;
							entity.manufacturerProductBatchExpirationDate = null;
						}
					}
				}),
				new FormField({
					type: "Date",
					readOnly: true,
					id: "manufacturerProductBatchExpirationDate",
					label: "common.domain.manufacturerProductStockFlow.manufacturerProductBatchExpirationDate",
					cellCssClass: "col-sm-6",
				}),	
						
			])
		
		 /*public*/ vm.closeModal = function(next)
		 {
			$uibModalInstance.close(next);
         };

        
		vm.saveFunction = function(domain, next) {
			save(domain);
			if(closeOnSave || next) {
				vm.closeModal(next);
			}
		}
        vm.onSaveAttendanceProduct = function(next){
        	
        	var domain = {};
			//if is save needs to validate and return the object
			vm.formHandler.reloadValidation($scope.editFormAttendanceProduct);
			FormUtil.validateAll($scope.editFormAttendanceProduct, vm.formHandler.getRowList());
			if($scope.editFormAttendanceProduct.$valid) {
				domain = Object.assign(domain, entity || {}, vm.formHandler.readForm())
				if(domain.autoGenerated || domain.expectedAmount >= 0) {
					var ratio = domain.usedAmount/domain.totalExpectedAmount*1.0;
					console.log(ratio);
					if(ratio > 1.5 || (ratio > 0 && ratio < 0.5)) {
						ConfirmDialogInvoker.invoke(
							{
								callback: function(res) { 
									if(res) {
										vm.saveFunction(domain, next);
									} 
								}, 
								text: $translate.instant("client.page.attendance.tabs.attendanceProduct.dialog.amountWarn", {expectedAmount: domain.totalExpectedAmount, usedAmount: domain.usedAmount})
							}
						);
					} else {
						vm.saveFunction(domain, next)
					}
					
				}
				
			}
        	
        }
	       
	}
})();
