(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('RecordsTemplateItemModalController', RecordsTemplateItemModalController);

    RecordsTemplateItemModalController.$inject = ['$uibModalInstance', 'pageConfig', '$translate', '$timeout', 'entity', 'RecordsTemplate', 'recordsTemplateDirList', 'UcAlertService', '$rootScope'];

    function RecordsTemplateItemModalController($uibModalInstance, pageConfig, $translate, $timeout, entity, RecordsTemplate, recordsTemplateDirList, UcAlertService, $rootScope)
    {
        var vm = this;

        vm.pageConfig = pageConfig;
        
        vm.entity = entity;
        
        vm.recordsTemplateDirList = recordsTemplateDirList;
        
        /*private*/ var editor = null;
        
        $timeout(function()
        {
        	var initialContentHtml = null;
        	if(entity && entity.content)
        	{
        		initialContentHtml = entity.content;
        	}
        	editor = initCKEditor("recordsEditorTemplate", 300, initialContentHtml);
        }, 100);
        
        
        /*public*/ vm.save = function()
        {
        	if(!vm.entity.recordsTemplateDir)
        	{
        		UcAlertService.error($translate.instant("client.global.messages.error.requiredFieldP0", {field: $translate.instant("client.page.records.templatesItem.directory")}));
        		return;
        	}

        	if(!vm.entity.name)
        	{
        		UcAlertService.error($translate.instant("client.global.messages.error.requiredFieldP0", {field: $translate.instant("client.global.label.name")}));
        		return;
        	}

        	var content = editor.getData();
        	if(!content)
        	{
        		UcAlertService.error($translate.instant("client.page.records.templatesItem.contentRequired"));
        		return;
        	}

        	if(!vm.entity.id)
        	{
        		RecordsTemplate.save(
				{
					recordsTemplateDirId: vm.entity.recordsTemplateDir.id,
					name: vm.entity.name,
					content: content
				}, function()
				{
					$uibModalInstance.close(true);	
				}); 
        	}
        	else
        	{
        		RecordsTemplate.update(
				{
					id: vm.entity.id,
					recordsTemplateDirId: vm.entity.recordsTemplateDir.id,
					name: vm.entity.name,
					content: content
				}, function()
				{
					$uibModalInstance.close(true);	
				});
        	}
        }
        
        /*public*/ vm.deleteObj = function()
        {
        	var message = $translate.instant("client.global.messages.info.deleteRecord") + "<br/>" + $translate.instant("client.global.messages.info.confirmOperation");
			$rootScope.confirm(
			{
				message: message,
				callback: function()
				{
					RecordsTemplate.delete(
					{
						id: vm.entity.id
					},
					function()
					{
						$uibModalInstance.close(true);	
					});
				} 
			});
        }        
        
        
        /*public*/ vm.clear = function()
        {
        	editor.setData("");
        }
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss();
        };
        
        /*private*/ function reload()
        {
			RecordsTemplate.getTree({recordType: recordType.id}, function(p_templateTree)
			{
				vm.templateTree = p_templateTree;
			});
        }
	}
})();