(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ForgetPasswordController', ForgetPasswordController);

    ForgetPasswordController.$inject = ['$rootScope', 'ForgetPassword', 'UcAlertService'];

    function ForgetPasswordController ($rootScope, ForgetPassword, UcAlertService)
    {
        var vm = this;
        
        vm.obj = {
        	cpf: null,
        	email: null
        };
        
        vm.resetPasswordExternal = function()
        {
        	// Limpa a mensagem atual, caso exista
        	UcAlertService.dismissAll();
        	
        	ForgetPassword.resetPasswordExternal(vm.obj);
        };

        $rootScope.initialFocus("cpf");
    }
})();
