(function(){
	'use strict';
	angular.module('sgmApp').controller('ProcedureProductDialogController', ProcedureProductDialogController);
	
	ProcedureProductDialogController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'FormDomain', 'FormConstants', 'pageConfig', 'BackEndSelectDataSource','$translate', 'ProcedureProduct', 'entity'];
	
	
	function ProcedureProductDialogController($scope, $uibModalInstance, $uibModal, FormDomain, FormConstants, pageConfig, BackEndSelectDataSource, $translate, ProcedureProduct, entity){
		
		var vm = this;
		vm.pageConfig = pageConfig;
		if(!entity) {
			entity = {};
		} else {
			entity = Object.assign({}, entity);
			if(entity.product.measureUnit) {
				entity.productMeasureUnit = entity.product.measureUnit.description;
			}
		}
		
		var formOptions = {
				model: entity,
				service: ProcedureProduct,
	        	pageConfig: pageConfig,
	        	buttonsModel: vm.buttonsModel,
	        	getForm: function(){ return $scope.editFormProcedureProduct; }
	        };
		
		vm.formHandler = FormDomain.create(formOptions).
			add([
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					id: "id",
					label: "common.domain.procedureProduct.id",
					readOnly: true,
					hide: true,
				}),
			]).add([
				new FormField({
					type: "Select",
					id: "product",
					required: true,
					label: "client.page.procedure.tab.product.dialog.selectProduct",
					cellCssClass: "col-sm-6",
					dataSource: FormConstants.DataSources.BackEnd.Product(BackEndSelectDataSource, $translate),
					onChange: function(value) {
						var product = value.product;
						let entity = vm.formHandler.getModel();
						if(product) {
							entity.productMeasureUnit = product.measureUnit.description;
						} else {
							entity.productMeasureUnit = null;
						}
					}
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "Double",
					id: "amount",
					required: true,
					label: "common.domain.procedureProduct.amount"
				}),
				new FormField({
					cellCssClass: "col-sm-3",
					type: "String",
					readOnly: true,
					id: "productMeasureUnit",
					label: "common.domain.procedureProduct.productMeasureUnit"
				}),
			])
		
		 /*public*/ vm.closeModal = function(procedureProduct)
		 {
			$uibModalInstance.close(procedureProduct);
        };
        
        vm.onSaveProcedureProduct = function(){
        	
        	var domain = null;
			//if is save needs to validate and return the object
			console.log($scope.editFormProcedureProduct);
			console.log(vm.formHandler.getRowList());
			FormUtil.validateAll($scope.editFormProcedureProduct, vm.formHandler.getRowList());
			if($scope.editFormProcedureProduct.$valid) {
				domain = vm.formHandler.readForm();
				vm.closeModal(domain);
			}
        	
        }
	       
	}
})();
