(function() {
    'use strict';
    /**
     * LEMBRAR DE ALTERAR TAMBÉM O APPLICATION-PROD.YML QUANDO HOM/PROD
     */
    angular
        .module('sgmApp')
        .constant('SERVICES',
        {
//        	environment: 'local'
//        	environment: 'dev'	// dev é o dev-remoto
//            environment: 'uc'	// UseCase
//        	environment: 'charmea'	// Charmeá
           	environment: 'prod'
//        	environment: 'hom'
        })

})();
