(function()
{
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProcedureProductKitsTabController', ProcedureProductKitsTabController);
    
    ProcedureProductKitsTabController.$inject = ['$scope', 'FormConstants', 'FrontEndSelectDataSource', 'BackEndSelectDataSource', '$translate', '$timeout', 'ProcedureProductKitDialogInvoker', 'ProcedureProductKit', '$uibModal', 'ProcedureProductKitItemsDialogInvoker'];
    function ProcedureProductKitsTabController($scope, FormConstants, FrontEndSelectDataSource, BackEndSelectDataSource, $translate, $timeout, ProcedureProductKitDialogInvoker, ProcedureProductKit, $uibModal, ProcedureProductKitItemsDialogInvoker)
    {
        var vm = $scope.vm;
        var entity = $scope.vm.domain;
        var formHandler = vm.formHandler;
        
        var injectedResources = vm.injectedResources;

        function callBackAddProcedureProductKit(procedureProductKit){
        	if(procedureProductKit){
                procedureProductKit.procedureId = entity.id;
                if(!procedureProductKit.id) {
                    ProcedureProductKit.save(procedureProductKit, function(res, putResponseHeaders) {
                        vm.procedureProductKits.push(res);
                    })
                }
        	}
        }

        function callBackUpdateProcedureProductKit(procedureProductKit, index){
        	if(procedureProductKit){
                procedureProductKit.procedureId = entity.id;
                ProcedureProductKit.update(procedureProductKit, function(res, putResponseHeaders) {
                    vm.procedureProductKits[index] = res;
                });
        	}
        }


        vm.editProcedureProductKit = function(procedureProductKit, index){
            if(vm.productTabIsReadonly) {
                return;
            }
        	ProcedureProductKitDialogInvoker.invoke({callback: function(res) { 
                callBackUpdateProcedureProductKit(res, index)
            }, entity: procedureProductKit});
        }

        vm.addProcedureProductKit = function(){
            if(vm.productTabIsReadonly) {
                return;
            }
        	ProcedureProductKitDialogInvoker.invoke({callback: callBackAddProcedureProductKit});
        }

        vm.showProcedureProductKitItems = function(procedureProductKit){
        	ProcedureProductKitItemsDialogInvoker.invoke({entity: procedureProductKit});
        }

        vm.removeProcedureProductKit = function(index) {
            if(vm.productTabIsReadonly) {
                return;
            }
            $uibModal.open({
				templateUrl: 'app/common/delete-dialog/delete-dialog-template.html',
				controller: 'DeleteDialogController',
				controllerAs: 'vm',
				size: 'sm',
				resolve: {
					pageConfig: [function()
					{
						return {};
					}],
                    service: 'ProcedureProductKit',
                    obj: vm.procedureProductKits[index]
                }
			}).result.then(function(p_)
			{
				if(p_) {
                    vm.procedureProductKits.splice(index, 1);
                }
			}, function() {
				/*void*/
			});
		}
    };
})();