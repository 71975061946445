(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('ProcedureController', ProcedureController);

    ProcedureController.$inject = ['FormFilter', 'UcGridService', '$translate', 'account', 'filterPageConfig', 'gridDefaultSort', '$rootScope', 'Procedure', 'FrontEndSelectDataSource', 'FormConstants', 'familyProcedureList'];

    function ProcedureController(FormFilter, UcGridService, $translate, account, filterPageConfig, gridDefaultSort, $rootScope, Procedure, FrontEndSelectDataSource, FormConstants, familyProcedureList)
    {
        var vm = this;

        vm.pageConfig = filterPageConfig;
        vm.sort = gridDefaultSort;
        
        $rootScope.initialFocus("code");
        
        vm.buttonsModel =
        {
        	stateRoot: "procedure",
        	onFilter: function() { doFilter(); },
        	onClear: function() { doClear(); },
			/*extraButtonList: [
				{
					order: 100,
					cssClass: 'btn btn-primary',
					label: 'atualizar',
					type: 'button',
					isToAdd: function () { return true; },
					click: function () { updateLoad(); }
				}
			]*/
        };

        var formOptions = {
        	formStatePersisterId: "Procedure"
//        	checkFormChange: function() { doFilter(); }
        };
        vm.formHandler = FormFilter.create(formOptions)
        	.add([new FormField({
		       		cellCssClass: "col-sm-6",
					type: "String",
					id: "code",
					label: "common.domain.procedure.code"
				}),
				new FormField({
		       		cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					label: "common.domain.procedure.description"
		    })])
		    .add([new FormField({
		       		cellCssClass: "col-sm-6",
					type: "Select",
					id: "familyProcedure",
					label: "common.domain.procedure.familyProcedure",
					multiple: true,
					dataSource: FrontEndSelectDataSource.create(familyProcedureList)
		    	}),
		    	new FormField({
		       		cellCssClass: "col-sm-6",
					type: "Select",
					id: "active",
					label: "common.domain.procedure.active",
					dataSource: FrontEndSelectDataSource.create(FormConstants.DataSources.FrontEnd.Boolean($translate), {dsJsonValTransform: FormConstants.ValTransforms.BOOLEAN})
		    })])
			.endConfig();
        
        
        
        vm.gridFieldList = UcGridService.createFieldList([
        	{headerName: $translate.instant("common.domain.procedure.code"), field: "code", width: 15},
        	{headerName: $translate.instant("common.domain.procedure.description"), field: "description", width: 35},
        	{headerName: $translate.instant("common.domain.procedure.familyProcedure"), field: "familyProcedureName", width: 20},
        	{headerName: $translate.instant("common.domain.procedure.sessionsQuantity"), field: "sessionsQuantity", width: 10, cellStyle: {alignItems: "right"}},
            UcGridService.field.ACTIVE(),	// width: 5
        ]);

        var grid = UcGridService.create(
        {
        	gridId: "PROCEDURE",
//        	permission: vm.pageConfig.editPermission,
        	stateBaseName: "procedure",
        	account: account,
        	getFilterData: function() { return vm.formHandler.readForm(); },
        	gridFieldList: vm.gridFieldList, 
        	sort: vm.sort,
        });
        
        vm.gridOptions = grid.getGridOptions();
        
        /*private*/ function doFilter()
        {
        	grid.refresh();
        };
        
        /*private*/ function doClear()
		{
        	vm.formHandler.clear();
			doFilter();
		};
/* 
		function updateLoad() {
			var procedures = ("10195;false;PRO100811;ALUGUEL DE SALA\n" +
			"10310;true;APLIC 01;APLICACAO DE  FLUORACIL\n" +
			"10327;true;APLIC 02;APLICACAO DE ACIDO\n" +
			"10413;true;APLIC 03;APLICACAO DE ANESTESICO (MEDICO)\n" +
			"10202;false;APLIC 04;APLICACO DE MEDICACAO\n" +
			"10022;false;PRO10022;ARGILOTERAPIA\n" +
			"10205;true;BIOEST 04;BIOESTIMULADOR A\n" +
			"10307;true;BIOEST 03;BIOESTIMULADOR B\n" +
			";;BIOEST 07;BIOESTIMULADOR C\n" +
			"10431;true;BIOEST 01;BIOESTIMULADOR D\n" +
			";;BIOEST 08;BIOESTIMULADOR  E\n" +
			"10417;true;BIOEST 02;BIOESTIMULADOR F\n" +
			"10233;false;BIOEST 05;BIOESTIMULADOR SCULPTRA 2SS\n" +
			"10213;false;BIOEST 06;BIOESTIMULADOR SCULPTRA 3SS\n" +
			"10309;true;BIOIM;BIOIMPEDANCIA\n" +
			"10018;true;BIOP 01;BIOPSIA CABELO\n" +
			"10231;true;BIOP 02;BIOPSIA DE PELE\n" +
			"10325;true;BIOP 03;BIOPSIA EXERESE\n" +
			"10048;true;BIOP 04;BIOPSIA GINECOLOGICA\n" +
			"10200;true;CARGA;CARGA\n" +
			"10019;true;CAUT ;CAUTERIZACAO\n" +
			"10381;true;CIRURG 02;CIRURGIA CANCER DE PELE\n" +
			"10324;true;CIRURG 01;CIRURGIA CICATRIZ\n" +
			"10262;true;CIRURG 03;CIRURGIA LIPOENXERTIA\n" +
			"10376;true;CIRURG 04;CIRURGIA LOBULO FENDIDO\n" +
			"10049;true;COLETA 01;COLETA DE MATERIAL\n" +
			"10238;true;COLETA 02;COLETA SLIM DNA\n" +
			"10013;true;CONS 01;CONSULTA CIRURGIA PLASTICA\n" +
			"10011;true;CONS 02;CONSULTA DERMATOLGICA CAPILAR\n" +
			"10012;true;CONS 03;CONSULTA DERMATOLGICA PELE\n" +
			"10060;false;CONS 04;CONSULTA EQUILÍBRIO INTEGRAL\n" +
			"10014;true;CONS 05;CONSULTA GINECOLOGICA\n" +
			"10015;true;CONS 06;CONSULTA NUTROLGICA\n" +
			"10016;false;CONS 07;CONSULTA NUTROLGICA EQ. INT.\n" +
			"10017;false;CONS 08;CONSULTA NUTROLGICA EQ. INT. 3M\n" +
			"10249;true;CORTE 01;CORTE DE CABELO\n" +
			"10040;true;DERMO 01;DERMOPIGMENTACAO  1 AREA\n" +
			"10207;true;DERMO 03;DERMOPIGMENTACAO  COMPLEMENTO 1 AREA\n" +
			"10208;true;DERMO 04;DERMOPIGMENTACAO  COMPLEMENTO 2 AREAS\n" +
			"10209;true;DERMO 05;DERMOPIGMENTACAO  COMPLEMENTO CABECA TOTAL\n" +
			"10041;true;DERMO 06;DERMOPIGMENTACAO 2 AREAS\n" +
			"10039;true;DERMO 07;DERMOPIGMENTACAO 3 AREAS\n" +
			"10244;true;DERMO 08;DERMOPIGMENTACAO 4 AREAS\n" +
			"10210;true;DERMO 09;DERMOPIGMENTACAO AVALIACAO \n" +
			"10045;true;DERMO 10;DERMOPIGMENTACAO CABECA TOTAL\n" +
			"10044;true;DERMO 11;DERMOPIGMENTACAO CICATRIZ FUE\n" +
			"10367;true;DERMO 12;DERMOPIGMENTACAO CICATRIZ FUT 2SS\n" +
			"10043;false;DERMO 13;DERMOPIGMENTACAO CICATRIZ FUT 3SS\n" +
			"10245;true;DERMO 15;DERMOPIGMENTACAO COMPLEMENTO 3 AREAS\n" +
			"10364;true;DERMO 16;DERMOPIGMENTACAO COMPLEMENTO CICATRIZ FUT\n" +
			"10348;true;DERMO 17;DERMOPIGMENTACAO PEQUENAS AREAS\n" +
			"10038;true;DERMO 18;DERMOPIGMENTACAO TESTE \n" +
			"10206;false;DERMO 02;DERMOPIGMENTACO  COMPLEMENTAR 1S\n" +
			"10201;false;DERMO 14;DERMOPIGMENTACO CIRÚRGICA\n" +
			"10023;false;PRO10023;DESENCRUSTE\n" +
			"10198;true;ELETRO;ELETROCOAGULACAO\n" +
			"10056;true;EXAME 01;EXAME DNA AGE\n" +
			"10057;true;EXAME 02;EXAME FITSPORT\n" +
			"10058;true;EXAME 03;EXAME FOOD DETECTIVE\n" +
			"10059;true;EXAME 04;EXAME GENODIET COMPLETO\n" +
			"10430;true;FIO 01;FIO PDO BIOESTIMULO\n" +
			"10427;true;FIO 03;FIO PDO BIOESTIMULO PALPEBRA\n" +
			"10429;true;FIO 02;FIO PDO SUSTENTAÇÃO\n" +
			"10050;true;GINECO 02;GINECO COLOCACAO DE DIU\n" +
			"10237;true;GINECO 03;GINECO IMPLANTE DE HORMÔNIO\n" +
			"10052;true;GINECO 04;GINECO PEELING 1S\n" +
			"10053;true;GINECO 05;GINECO PEELING 3S\n" +
			"10054;true;GINECO 06;GINECO PREENCHIMENTO \n" +
			"10055;true;GINECO 07;GINECO RETIRADA DE DIU\n" +
			"10269;true;GINECO 01;GINECO RETIRADA DE IMPLANTE HORMONAL\n" +
			"10241;true;MD CODES 1;MD CODES 1\n" +
			"10242;true;MD CODES 2;MD CODES 2\n" +
			"10243;true;MD CODES 3;MD CODES 3\n" +
			"10024;false;PRO10024;HidrataCo Profunda\n" +
			"10020;true;INFILT 01;INFILTRACAO\n" +
			"10236;true;INFILT 02;INFILTRACAO DE FERRO \n" +
			"10235;false;LASER DEMO;LASER DEMONSTRACAO\n" +
			"10248;false;LAS;Laser 1ss\n" +
			"10253;false;LAS 02;Laser 2ss\n" +
			"10255;false;LAS ME;Laser 2ss Melanose\n" +
			"10251;false;LAS 04;Laser 3 ss\n" +
			"10246;true;LASER 01;LASER ACROMA 3 SS\n" +
			"10299;true;LASER 02;LASER ACROMA AXILAS\n" +
			"10298;true;LASER 03;LASER ACROMA AXILAS 8SS\n" +
			"10333;true;LASER 04;LASER ACROMA CABECA\n" +
			"10203;true;LASER 05;LASER ACROMA CLAREAMENTO DE TATUAGEM\n" +
			"10303;true;LASER 06;LASER ACROMA MELANOSE/CERATOSE SEBORREICA BRACO/ANTEBRACO\n" +
			"10311;true;LASER 07;LASER ACROMA MELANOSE/CERATOSE SEBORREICA COLO\n" +
			"10312;true;LASER 08;LASER ACROMA MELANOSE/CERATOSE SEBORREICA FACE\n" +
			"10313;true;LASER 09;LASER ACROMA MELANOSE/CERATOSE SEBORREICA LESAO UNICA\n" +
			"10302;true;LASER 11;LASER ACROMA MELANOSE/CERATOSE SEBORREICA MEIA PERNA\n" +
			"10314;true;LASER 10;LASER ACROMA MELANOSE/CERATOSE SEBORREICA MOS\n" +
			"10316;true;LASER 12;LASER ACROMA MELANOSE/CERATOSE SEBORREICA PEQUENAS REAS\n" +
			"10315;true;LASER 13;LASER ACROMA MELANOSE/CERATOSE SEBORREICA V DO DECOTE\n" +
			"10306;true;LASER 14;LASER ACROMA MELASMA FACE\n" +
			"10356;true;LASER 15;LASER ACROMA MELASMA FACE 2SS\n" +
			"10362;true;LASER 16;LASER ACROMA MELASMA FACE 3SS\n" +
			"10326;true;LASER 17;LASER ACROMA MELASMA FACE 6SS\n" +
			"10317;true;LASER 18;LASER ACROMA MELASMA LABIO SUPERIOR\n" +
			"10318;true;LASER 19;LASER ACROMA MELASMA MALAR OU FRONTE\n" +
			"10297;true;LASER 20;LASER ACROMA OLHEIRAS\n" +
			"10296;true;LASER 21;LASER ACROMA OLHEIRAS 8SS\n" +
			"10301;true;LASER 22;LASER ACROMA PERNAS\n" +
			"10300;true;LASER 23;LASER ACROMA PERNAS 8SS\n" +
			"10375;true;LASER 24;LASER DUAL MODE ANTEBRACO\n" +
			"10290;true;LASER 25;LASER DUAL MODE AREAS PEQUENAS (GLABELA\n" +
			"10288;true;LASER 26;LASER DUAL MODE COLO\n" +
			"10292;true;LASER 27;LASER DUAL MODE ESTRIAS\n" +
			"10261;true;LASER 28;LASER DUAL MODE FACE\n" +
			"10295;true;LASER 29;LASER DUAL MODE INTRA ORAL\n" +
			"10294;true;LASER 30;LASER DUAL MODE LASER PEEL\n" +
			"10287;true;LASER 31;LASER DUAL MODE LATERAL DA FACE\n" +
			"10289;true;LASER 32;LASER DUAL MODE MAOS\n" +
			"10285;true;LASER 33;LASER DUAL MODE PERIOCULAR\n" +
			"10373;true;LASER 34;LASER DUAL MODE PERIOCULAR 2SS\n" +
			"10286;true;LASER 35;LASER DUAL MODE PERIORAL\n" +
			"10291;true;LASER 36;LASER DUAL MODE PESCOCO\n" +
			"10061;false;PRO10061;LASER FRAXEL 1 S\n" +
			"10062;false;PRO10062;LASER FRAXEL 10S\n" +
			"10063;false;PRO10063;LASER FRAXEL 5S\n" +
			"10256;true;LASER 37;LASER INTIMO 1SS\n" +
			"10328;true;LASER 38;LASER INTIMO 3SS\n" +
			"10263;true;LASER 39;LASER INTIMO AVALIACO \n" +
			"10259;No;LASER 40;LASER LUZ PULSADA\n" +
			"10267;true;LASER 41;LASER LUZ PULSADA AREAS PEQUENAS (GLABELA, NARIZ, OLHEIRAS)\n" +
			"10270;false;LASER 42 (inat);LASER LUZ PULSADA BRACO/ANTEBRACO (inat)\n" +
			"10351;true;LASER 42;LASER LUZ PULSADA BRACO/ANTEBRACO\n" +
			"10271;true;LASER 43;LASER LUZ PULSADA CICATRIZ OU ESTRIA PEQUENA\n" +
			"10265;true;LASER 44;LASER LUZ PULSADA COLO\n" +
			"10412;true;LASER 45;LASER LUZ PULSADA EM CICATRIZ DE ABDOMEM\n" +
			"10264;true;LASER 46;LASER LUZ PULSADA FACE\n" +
			"10274;true;LASER 47;LASER LUZ PULSADA INTRA ORAL\n" +
			"10272;true;LASER 48;LASER LUZ PULSADA MELASMA\n" +
			"10266;false;LASER 49 (inta);LASER LUZ PULSADA MAOS (inat)\n" +
			"10352;true;LASER 49;LASER LUZ PULSADA MAOS\n" +
			"10268;true;LASER 50;LASER LUZ PULSADA PESCOCO\n" +
			"10254;true;LASER 51;LASER PEEL 1SS\n" +
			"10293;true;LASER 52;LASER PEEL DUAL MODE FACE\n" +
			"10273;true;LASER 53;LASER PEEL LUZ PULSADA\n" +
			"10283;true;LASER 54;LASER PEEL PRODEEP\n" +
			"10357;true;LASER 55;LASER PRODEEP ANTEBRACO\n" +
			"10359;true;LASER 56;LASER PRODEEP CICATRIZ\n" +
			"10278;true;LASER 57;LASER PRODEEP COLO\n" +
			"10281;true;LASER 58;LASER PRODEEP ESTRIAS\n" +
			"10371;true;LASER 59;LASER PRODEEP ESTRIAS 3SS\n" +
			"10382;true;LASER 60;LASER PRODEEP ESTRIAS 4SS\n" +
			"10260;true;LASER 61;LASER PRODEEP FACE\n" +
			"10330;true;LASER 62;LASER PRODEEP FACE 3SS\n" +
			"10284;true;LASER 63;LASER PRODEEP INTRA ORAL \n" +
			"10277;true;LASER 64;LASER PRODEEP LATERAL DA FACE\n" +
			"10418;true;LASER 65;LASER PRODEEP LATERAL DA FACE 3SS\n" +
			"10282;true;LASER 66;LASER PRODEEP MELASMA\n" +
			"10279;true;LASER 67;LASER PRODEEP MOS\n" +
			"10334;true;LASER 68;LASER PRODEEP PALPEBRA\n" +
			"10275;true;LASER 69;LASER PRODEEP PERIOCULAR\n" +
			"10276;true;LASER 70;LASER PRODEEP PERIORAL\n" +
			"10280;true;LASER 71;LASER PRODEEP PESCOCO\n" +
			"10188;true;LAVAG ;LAVAGEM DE CABELO\n" +
			"10025;false;PRO10025;LIMPEZA PROF. COURO\n" +
			"10064;true;MMP 01;MMP 1S\n" +
			"10065;false;PRO10065;MMP 1S + FRAXEL 1S\n" +
			"10066;false;PRO10066;MMP 1S + FRAXEL 3S\n" +
			"10067;false;PRO10067;MMP 1S + FRAXEL 6S\n" +
			"10068;false;PRO10068;MMP 1S + FRAXEL 8S\n" +
			"10069;false;MMPTEP 01;MMP 1S + TERAPIA 4S\n" +
			"10187;true;MMP 02;MMP 2S\n" +
			"10070;true;MMP 03;MMP 3S\n" +
			"10071;false;MMPTEP 02;MMP 3S + TERAPIA 12S\n" +
			"10221;true;MMP 04;MMP 4S\n" +
			"10197;true;MMP 05;MMP 5S\n" +
			"10072;true;MMP 06;MMP 6S\n" +
			"10204;false;MMPTEP 03;MMP 6S + TERAPIA 6S\n" +
			"10369;No;MMP 10;MMP CUSTO \n" +
			"10220;true;MMP 11;MMP INTRADERMOTERAPIA 6SS\n" +
			"10212;true;MMP 12;MMP INTRADERMOTERAPIA ISOLADA \n" +
			"10189;true;OZON 01;OZÔNIO\n" +
			"10223;true;OZON 02;OZÔNIO 10SS\n" +
			"10222;true;OZON 03;OZÔNIO 5SS\n" +
			"10051;false;PRO10051;PARTO\n" +
			"10224;false;2222;PEELING CAPILAR (inat)\n" +
			"10026;No;PEEL 01;PEELING CAPILAR\n" +
			"10030;true;PEEL 02;PEELING FACIAL\n" +
			"10029;true;PEEL 03;PEELING MÉDIO\n" +
			"10320;true;PERMUTA;PERMUTA\n" +
			"10321;false;PMR;PERMUTA (inat)\n" +
			"10217;false;90;PESAGEM\n" +
			"10073;true;PLASM 01;PLASMA (PRP) - ASSISTENTE\n" +
			"10074;true;PLASM 02;PLASMA (PRP) - MEDICO\n" +
			"10336;true;PLASM 03;PLASMA (PRP) GINECOLOGICO\n" +
			"10227;true;PLASM 04;PLASMA 2SS\n" +
			"10228;true;PLASM 05;PLASMA 3SS\n" +
			"10075;true;PLASM 06;PLASMA E MMP - ASSISTENTE\n" +
			"10031;true;PREENCH 1;PREECHIMENTO 1 SERINGA\n" +
			"10036;false;PRO10036;PREECHIMENTO SCULPTRA 1 FRASCO\n" +
			"10257;false;SCU 2 Fr;PREECHIMENTO SCULPTRA 2 FRASCOS\n" +
			"10258;false;SCU 3 FR;PREECHIMENTO SCULPTRA 3 FRASCOS\n" +
			"10329;true;PREENCH 2;PREENCHIMENTO 2 SERINGAS\n" +
			"10032;true;PREENCH 3;PREENCHIMENTO 3 SERINGAS\n" +
			"10350;false;PRE 10;PREENCHIMENTO FACIAL COM ENXERTO DE GORDURA\n" +
			"10250;false;PRE 09;PREENCHIMENTO KYSSE\n" +
			"10323;false;PRE 017;PREENCHIMENTO LIFT\n" +
			"10033;false;PRO10033;PREENCHIMENTO MALAR\n" +
			"10034;false;PRO10034;PREENCHIMENTO PERLANE\n" +
			"10035;false;PRO10035;PREENCHIMENTO RADIESE\n" +
			"10252;false;PRE 08;PREENCHIMENTO REFYNE\n" +
			"10215;true;PREENCH 4;PREENCHIMENTO SKIN BOOSTER\n" +
			"10308;false;PREENCH 5;PREENCHIMENTO SKINBOOSTER 3 SS\n" +
			"10037;false;PRO10037;PREENCHIMENTO SULCO\n" +
			"10304;false;PRE 23;PREENCHIMENTO VOLYME\n" +
			"10046;false;PRO10046;PROCEDIMENTO DERMATOLOGIA\n" +
			"10374;false;PRO 20;PRODEEP SEIOS\n" +
			"10428;false;PROF1;PROFIHLO AVULSO\n" +
			"10027;false;PRO10027;QUERATINIZACO\n" +
			"10247;true;REAV 01;REAVALIACAO DE DERMOPIGMENTACAO\n" +
			"10322;true;REAV 02;REAVALIAR LASER\n" +
			"10219;true;REAV 03;REAVALIAR MMP\n" +
			"10229;true;REAV 04;REAVALIAR PRP\n" +
			"10355;true;REAV 05;REAVALIAR TERAPIA CAPILAR\n" +
			"10047;true;RETIR 01;RETIRADA DE NEVUS\n" +
			"10083;true;RETIR 02;RETIRADA DE PINTA\n" +
			"10211;true;RETIR 03;RETIRADA DE PONTO\n" +
			"10196;true;RETOQ 01;RETOQUE CIRURGIA\n" +
			"10366;true;RETOQ 02;RETOQUE FUE\n" +
			"10240;false;WRT;SUTURA SILHOUETTE (TESTE)\n" +
			"10331;true;TELE CONS;TELECONSULTA\n" +
			"10365;false;TEL 0099;TELECONSULTA GINECOLOGICA\n" +
			"10377;true;TERAP 01;TERAPIA 3SS\n" +
			"10379;true;TERAP 02;TERAPIA CAPILAR  2X 20SS\n" +
			"10342;true;TERAP 03;TERAPIA CAPILAR (PC) 1X SEMANA 10SS\n" +
			"10343;true;TERAP 04;TERAPIA CAPILAR (PC) 1X SEMANA 11SS\n" +
			"10344;true;TERAP 05;TERAPIA CAPILAR (PC) 1X SEMANA 12SS\n" +
			"10340;true;TERAP 06;TERAPIA CAPILAR (PC) 1X SEMANA 13SS\n" +
			"10341;true;TERAP 07;TERAPIA CAPILAR (PC) 1X SEMANA 14SS\n" +
			"10345;true;TERAP 08;TERAPIA CAPILAR (PC) 1X SEMANA 15SS\n" +
			"10339;true;TERAP 09;TERAPIA CAPILAR (PC) 1X SEMANA 16SS\n" +
			"10346;true;TERAP 10;TERAPIA CAPILAR (PC) 1X SEMANA 17SS\n" +
			"10338;true;TERAP 11;TERAPIA CAPILAR (PC) 1X SEMANA 18SS\n" +
			"10337;true;TERAP 12;TERAPIA CAPILAR (PC) 1X SEMANA 24SS\n" +
			"10372;true;TERAP 13;TERAPIA CAPILAR (PC) 1X SEMANA 6SS\n" +
			"10082;true;TERAP 14;TERAPIA CAPILAR 1X (ISOLADA)\n" +
			"10358;true;TERAP 15;TERAPIA CAPILAR 1X SEMANA 10S\n" +
			"10076;true;TERAP 16;TERAPIA CAPILAR 1X SEMANA 12S\n" +
			"10360;true;TERAP 17;TERAPIA CAPILAR 1X SEMANA 16SS\n" +
			"10335;true;TERAP 18;TERAPIA CAPILAR 1X SEMANA 18SS\n" +
			"10349;true;TERAP 19;TERAPIA CAPILAR 1X SEMANA 18SS ASSOCIADO A MMP\n" +
			"10077;true;TERAP 20;TERAPIA CAPILAR 1X SEMANA 24S\n" +
			"10078;true;TERAP 21;TERAPIA CAPILAR 1X SEMANA 4S\n" +
			"10216;true;TERAP 22;TERAPIA CAPILAR 1X SEMANA 6S\n" +
			"10347;true;TERAP 23;TERAPIA CAPILAR 1X SEMANA 8SS\n" +
			"10234;true;TERAP 24;TERAPIA CAPILAR 2X SEMANA 10S\n" +
			"10353;true;TERAP 25;TERAPIA CAPILAR 2X SEMANA 12SS\n" +
			"10239;true;TERAP 26;TERAPIA CAPILAR 2X SEMANA 13S\n" +
			"10218;true;TERAP 27;TERAPIA CAPILAR 2X SEMANA 16S\n" +
			"10079;true;TERAP 28;TERAPIA CAPILAR 2X SEMANA 24S\n" +
			"10361;true;TERAP 29;TERAPIA CAPILAR 2X SEMANA 27SS\n" +
			"10354;true;TERAP 30;TERAPIA CAPILAR 2X SEMANA 28SS\n" +
			"10080;true;TERAP 31;TERAPIA CAPILAR 2X SEMANA 36S\n" +
			"10081;true;TERAP 32;TERAPIA CAPILAR 2X SEMANA 8S\n" +
			"10368;true;TERAP 33;TERAPIA CAPILAR 5SS\n" +
			"10416;true;TERAP 34;TERAPIA FACE 1SS\n" +
			"10426;true;TERAP 35;TERAPIA FACE 4SS\n" +
			"10414;true;TERAP 36;TERAPIA FACE 8SS\n" +
			"10319;true;TERAP 37;TERAPIA PELE\n" +
			"10000;false;PRO10000;TESTE FUE\n" +
			"10415;false;acroteste;TESTE LASER ACROMA\n" +
			"10332;true;TOX 01;TOXINA CORPORAL\n" +
			"10085;true;TOX 02;TOXINA DO COURO CABELUDO\n" +
			";;TOX 06;TOXINA FEM DUAS AREAS DO 1_3 SUPERIOR \n" +
			";;TOX 07;TOXINA FEM TERCO INFERIOR COMPLETO \n" +
			";;TOX 08;TOXINA FEM TERCO INFERIOR UMA AREA \n" +
			";;TOX 09;TOXINA FEM TERCO SUPERIOR  E TERCO INFERIRO \n" +
			"10028;true;TOX 04;TOXINA FEM TERCO SUPERIOR COMPLETO \n" +
			"10084;true;TOX 05;TOXINA FEM UMA AREA DO 1_3 SUPERIOR \n" +
			"10363;true;TOX 03;TOXINA HIPERIDROSE\n" +
			";;TOX 12;TOXINA MASC DUAS AREAS DO 1_3 SUPERIOR \n" +
			";;TOX 13;TOXINA MASC TERCO INFERIOR COMPLETO \n" +
			";;TOX 14;TOXINA MASC TERCO INFERIOR UMA AREA \n" +
			";;TOX 15;TOXINA MASC TERCO SUPERIOR  E TERCO INFERIRO \n" +
			";;TOX 10;TOXINA MASC TERCO SUPERIOR COMPLETO \n" +
			";;TOX 11;TOXINA MASC UMA AREA DO 1_3 SUPERIOR \n" +
			"10001;true;TRANSP 01 ;TRANSPLANTE BARBA\n" +
			"10190;true;TRANSP 02;TRANSPLANTE BARBA (SEM ANESTESISTA)\n" +
			"10425;true;TRANSP 03;TRANSPLANTE BARBA JR\n" +
			"10003;true;TRANSP 04;TRANSPLANTE CORRECAO\n" +
			"10004;true;TRANSP 05;TRANSPLANTE COSTELETAS\n" +
			"10370;true;TRANSP 06;TRANSPLANTE DE MELANCITO\n" +
			"10005;true;TRANSP 07;TRANSPLANTE FUE PARCIAL\n" +
			"10191;true;TRANSP 08;TRANSPLANTE FUE PARCIAL (SEM ANESTESISTA)\n" +
			"10421;true;TRANSP 09;TRANSPLANTE FUE PARCIAL JR\n" +
			"10006;true;TRANSP 10;TRANSPLANTE FUE TOTAL\n" +
			"10193;true;TRANSP 11;TRANSPLANTE FUE TOTAL (SEM ANESTESISTA)\n" +
			"10419;true;TRANSP 12;TRANSPLANTE FUE TOTAL JR\n" +
			"10002;true;TRANSP 13;TRANSPLANTE FUT\n" +
			"10420;true;TRANSP 14;TRANSPLANTE FUT JR\n" +
			"10232;true;TRANSP 15;TRANSPLANTE FUT PARCIAL\n" +
			"10422;true;TRANSP 16;TRANSPLANTE FUT PARCIAL JR\n" +
			"10007;false;PRO10007;TRANSPLANTE HÍBRIDA PARCIAL\n" +
			"10008;false;PRO10008;TRANSPLANTE HÍBRIDA TOTAL\n" +
			"10199;false;PRO10101;TRANSPLANTE ROBÔ\n" +
			"10009;true;TRANSP 17;TRANSPLANTE SOBRANCELHA PARCIAL\n" +
			"10192;true;TRANSP 18;TRANSPLANTE SOBRANCELHA PARCIAL (SEM ANESTESISTA)\n" +
			"10424;true;TRANSP 19;TRANSPLANTE SOBRANCELHA PARCIAL JR\n" +
			"10010;true;TRANSP 20;TRANSPLANTE SOBRANCELHA TOTAL\n" +
			"10194;true;TRANSP 21;TRANSPLANTE SOBRANCELHA TOTAL (SEM ANESTESISTA)\n" +
			"10423;true;TRANSP 22;TRANSPLANTE SOBRANCELHA TOTAL JR\n" +
			"10230;true;TRICOSCO;TRICOGRAMA\n" +
			"10021;true;TRICOGR;TRICOSCOPIA\n" +
			"10390;true;ULTRA 01;ULTRAFORMER 1/2 PALPEBRAS (INTERIOR OU SUPERIOR)\n" +
			"10389;true;ULTRA 02;ULTRAFORMER COM FOCO EM GORDURA (2 OU 3 SESSAO)\n" +
			"10380;true;ULTRACORPO 01;ULTRAFORMER CORPORAL\n" +
			"10399;true;ULTRACORPO 02;ULTRAFORMER CORPORAL ABDOME - GORDURA\n" +
			"10404;true;ULTRACORPO 03;ULTRAFORMER CORPORAL BRACOS\n" +
			"10397;true;ULTRACORPO 04;ULTRAFORMER CORPORAL COLO\n" +
			"10406;true;ULTRACORPO 05;ULTRAFORMER CORPORAL ESCAPULAR - SUPRA OU INFRA\n" +
			"10403;true;ULTRACORPO 06;ULTRAFORMER CORPORAL EXTERNO DE COXA - CULOTES\n" +
			"10407;true;ULTRACORPO 07;ULTRAFORMER CORPORAL FLANCOS\n" +
			"10409;true;ULTRACORPO 08;ULTRAFORMER CORPORAL GLUTEOS\n" +
			"10408;true;ULTRACORPO 09;ULTRAFORMER CORPORAL INFRAGLUTEO - BANANINHA\n" +
			"10402;true;ULTRACORPO 10;ULTRAFORMER CORPORAL INTERNO DE COXA\n" +
			"10401;true;ULTRACORPO 11;ULTRAFORMER CORPORAL JOELHOS - FLACIDEZ\n" +
			"10410;true;ULTRACORPO 12;ULTRAFORMER CORPORAL MAOS\n" +
			"10411;true;ULTRACORPO 13;ULTRAFORMER CORPORAL MONTE PUBIANO\n" +
			"10405;true;ULTRACORPO 14;ULTRAFORMER CORPORAL PRE-AXILAR\n" +
			"10398;true;ULTRACORPO 15;ULTRAFORMER CORPORAL UMBIGO TRISTE\n" +
			"10400;true;ULTRACORPO 16;ULTRAFORMER CORPORAL1/2 ABDOME - GORDURA\n" +
			"10384;true;ULTRA 03;ULTRAFORMER ESTOQUE DE COLAGENO (FULLFACE)\n" +
			"10393;true;ULTRA 04;ULTRAFORMER FRONTE (TESTA)\n" +
			"10383;true;ULTRA 05;ULTRAFORMER FULLFACE (TERCO SUPERIOR + TERCO INFERIOR SUBMENTO)\n" +
			"10378;true;ULTRA 06;ULTRAFORMER FULLFACE E PESCOCO\n" +
			"10391;true;ULTRA 07;ULTRAFORMER PALPEBRAS COMPLETO\n" +
			"10392;true;ULTRA 08;ULTRAFORMER PALPEBRAS COMPLETO (2 OU 3 SESSAO)\n" +
			"10385;true;ULTRA 09;ULTRAFORMER PESCOCO\n" +
			"10387;true;ULTRA 10;ULTRAFORMER SUBMENTO (PAPADA)\n" +
			"10386;true;ULTRA 11;ULTRAFORMER SUBMENTO (PAPADA) + PESCOCO\n" +
			"10388;true;ULTRA 12;ULTRAFORMER SUBMENTO COM FOCO EM GORDURA (1 SESSO)\n" +
			"10395;true;ULTRA 13;ULTRAFORMER TERCO INFERIOR + SUBMENTO (CONTORNO)\n" +
			"10396;true;ULTRA 14;ULTRAFORMER TERCO INFERIOR + SUBMENTO (CONTORNO) + PERCOCO\n" +
			"10394;true;ULTRA 15;ULTRAFORMER TERCO SUPERIOR (PERIORBITAL + FRONTE)");
			Procedure.updateLoad(procedures, function(res) {
				console.log(res);
			});
		} */
	}
})();
