(function() {
    'use strict';

	angular.module('sgmApp').service('ExpenseAttachmentModalInvoker', ExpenseAttachmentModalInvoker);
	
	ExpenseAttachmentModalInvoker.$inject = ['$uibModal'];
	
	function ExpenseAttachmentModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/expense/attachment/expense-attachment-modal-dialog.html',
                controller: 'ExpenseAttachmentModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop  : 'static',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT"
                    	})
                    }],
                    entity: ['Expense', function(Expense)
                    {
                    	return {id: null, name: null, content: null};
                    }], 
                    expense: function()
                    {
                    	return p_param.expense;
                    } 
                }
            }).result.then(function(p_flag)
            {
            	if(p_param.callback)
            	{
            		p_param.callback(p_flag);
            	}

            });
		};
		
	};
})();
