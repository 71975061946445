(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsPrintModalInvoker', RecordsPrintModalInvoker);
	
	RecordsPrintModalInvoker.$inject = ['$uibModal'];
	
	function RecordsPrintModalInvoker($uibModal)
	{
		var vm = this;
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/print/records-print-modal-dialog.html',
                controller: 'RecordsPrintModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                	clientName: function()
                    {
                    	return p_param.clientName;
                    }, 
                	record: function()
                    {
                    	return p_param.record;
					},
					recordList: function ()
					{
						return p_param.recordList;
					},
					recordListHeaders: function ()
					{
						return p_param.recordListHeaders;
					},
                    recordType: function()
                    {
                    	return p_param.recordType;
                    },
					hasSignCallback: function()
                    {
                    	return p_param.signCallback != null;
                    }, 
                }
            }).result.then(function(p_)
			{
				if(p_param.signCallback)
				{
					p_param.signCallback(p_);
				}
			}, function() {
				/*void*/
			});
		};
	};
})();
