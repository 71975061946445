(function() {
    'use strict';

	angular.module('sgmApp').service('RecordsEditModalInvoker', RecordsEditModalInvoker);
	
	RecordsEditModalInvoker.$inject = ['$uibModal'];
	
	function RecordsEditModalInvoker($uibModal)
	{
		var vm = this;
		
		
		/**
		 * Invoca o modal 
		 */
		/*public*/ vm.invoke = function(p_param)
		{
            $uibModal.open({
                templateUrl: 'app/pages/managers/records/edit/records-edit-modal-dialog.html',
                controller: 'RecordsEditModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop  : 'static',
                windowClass: 'modal-dialog-80-percent',
                resolve: {
                	pageConfig: ['$rootScope', function($rootScope)
                    {
                    	return new PageConfig({
                    		pageMode: "EDIT"
                    	})
                    }],
                    client: function()
                    {
                    	return p_param.client;
                    },
                    attendanceId: function()
                    {
                    	return p_param.attendanceId;
                    },
                    recordType: function()
                    {
                    	return p_param.recordType;
                    },
                    recordId: function()
                    {
                    	return (p_param.id) ? p_param.id : null;
                    },
                    initialContent: ['Records', function(Records)
                    {
                    	if(p_param.id)
                    	{
                    		return Records.getContent({id: p_param.id}).$promise;
                    	}
                    	else
                    	{
                    		return null;
                    	}
                    }]
                }
            }).result.then(function(p_flag)
            {
            	if(p_param.callback)
            	{
            		p_param.callback(p_flag);
            	}

            }, function()
            {

            });
		};
		
	};
})();
