(function() {
    'use strict';

    angular
        .module('sgmApp')
        .directive('jhSortBy', jhSortBy);

    function jhSortBy() {
        var directive = {
            restrict: 'A',
            scope: false,
            require: '^jhSort',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, parentCtrl) {
            element.bind('click', function ()
            {
            	// #UC Fábio Jardim
            	// Se o atributo for nulo, não faz a operação
            	if(attrs.jhSortBy)
            	{
            		parentCtrl.sort(attrs.jhSortBy);
            	}
            });
        }
    }
})();
