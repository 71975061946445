(function() {
    'use strict';

	angular
		.module('sgmApp')
		.controller('ClientManagerModalController', ClientManagerModalController);
	
	ClientManagerModalController.$inject = ['pageConfig', 'entity', 'genderList', 'maritalStatusList', 'colorList', 'contactTypeList', 'netAccessTypeList', 'indicationTypeList', '$uibModalInstance', 'isToShowRecordsButton'];
	
	function ClientManagerModalController(pageConfig, entity, genderList, maritalStatusList, colorList, contactTypeList, netAccessTypeList, indicationTypeList, $uibModalInstance, isToShowRecordsButton)
	{
		var vm = this;

		vm.pageConfig = pageConfig;
		vm.domain = entity;
		vm.uibModalInstance = $uibModalInstance;
		
		vm.injectedResources = {
        	colorList: colorList,
        	contactTypeList: contactTypeList,
			genderList: genderList,
			indicationTypeList: indicationTypeList,
			maritalStatusList: maritalStatusList,
			netAccessTypeList: netAccessTypeList,
			isToShowRecordsButton: isToShowRecordsButton
		};
	}
})();
