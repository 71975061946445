(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('IndicationsDomainController', IndicationsDomainController);

    IndicationsDomainController.$inject = [
        'FormDomain',
        '$scope',
        '$state',
        'entity',
        'IndicationsService',
        'pageConfig',
        '$rootScope',
        'FormConstants',
        'FrontEndSelectDataSource',
        'indicationTypeList'
    ];

    function IndicationsDomainController(
        FormDomain,
        $scope,
        $state,
        entity,
        IndicationsService,
        pageConfig,
        $rootScope,
        FormConstants,
        FrontEndSelectDataSource,
        indicationTypeList
    )
    {
        var vm = this;

        vm.domain = entity;
        vm.pageConfig = pageConfig;

        $rootScope.initialFocus("code");

        vm.buttonsModel = {
        	isSaving: false,
        	gridId: "INDICATIONS",
        	stateRoot: "indications",
        	previousState: {name: $state.current.parent},
        	onClear: function() { vm.formHandler.clear(); }
        };

        var formOptions = {
        	model: entity,
        	pageConfig: pageConfig,
        	buttonsModel: vm.buttonsModel,
        	service: IndicationsService,
        	historyConfig: {historyId: "INDICATIONS"},
        	getForm: function(){ return $scope.editForm; }
        };
        vm.formHandler = FormDomain.create(formOptions)
        	.add([new FormField({
	           		cellCssClass: "col-sm-6",
					type: "String",
					id: "id",
					required: true,
					label: "client.global.label.id",
					readOnly: true,
					addFieldListener: FormConstants.AddFieldListeners.IdNotNull
				})
        	])
        	.add([new FormField({
					cellCssClass: "col-sm-6",
					type: "String",
					id: "description",
					required: true,
					label: "common.domain.indications.description",
					maxLength: 100
				}),
					new FormField({
				cellCssClass: "col-sm-6",
				type: "Select",
				id: "indicationType",
                required: true,
				label: "common.domain.indications.indicationType",
				dataSource: FrontEndSelectDataSource.create(indicationTypeList)
			})
        	])
            .add([new FormField({
                cellCssClass: "col-sm-12",
                type: "Boolean",
                id: "active",
                label: "common.domain.indicationType.active",
                addFieldListener: FormConstants.AddFieldListeners.IdNotNull
            })
            ])
			.endConfig();

        /*public*/ vm.save = function()
        {
        	vm.formHandler.save();
        }
    }
})();
