(function() {
    'use strict';

    angular
        .module('sgmApp')
        .controller('LoginModalController', LoginModalController);

    LoginModalController.$inject = ['$scope', '$uibModalInstance', 'UcAlertService', 'Auth', '$rootScope', 'MessageListener', 'hasCallback'];

    function LoginModalController($scope, $uibModalInstance, UcAlertService, Auth, $rootScope, MessageListener, hasCallback)
    {
        var vm = this;
        
        vm.password = null;
        vm.username = null;
        vm.hasCallback = hasCallback;
        /*public*/ vm.login = function(event)
        {
        	UcAlertService.dismissAll();
            event.preventDefault();
            if(hasCallback) {
                $uibModalInstance.close({
                    username: vm.username,
                    password: vm.password
                });
            } else {
                Auth.login({
                    username: vm.username,
                    password: vm.password,
                    rememberMe: false
                }).then(function ()
                {
                    $rootScope.$broadcast('authenticationSuccess');
                    
                    MessageListener.start();
                    
                    vm.closeModal();
    
                }).catch(function () {
                });
            }
            
        }

        /*public*/ vm.goToLoginPage = function()
        {
        	window.open("?tk=" + (new Date()).getTime(), "_self");
        }
        
        /*public*/ vm.closeModal = function()
        {
        	$uibModalInstance.dismiss('cancel');
        }
    }
})();