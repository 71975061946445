(function() {
    'use strict';
    
    angular
        .module('sgmApp')
        .directive('prontuaryForm', ProntuaryFormDirective);
    
    ProntuaryFormDirective.$inject = [];
    function ProntuaryFormDirective()
    {
    	return {
            restrict : "E",
			replace: true,
			controllerAs: 'vm',
			controller: "ProntuaryFormDirectiveController",
            templateUrl: "app/components/prontuary-form/prontuary-form.directive.template.html",
            scope:
            {
            	prontuaryFormConfig: '=',
                prontuary: '='
            }
    	}
    };
})();
