var TIME_MASK = function(p_hour, p_minute)
{
	var now = new Date();
	return createNDate(now.getFullYear(), now.getMonth(), now.getDate(), p_hour, p_minute, 0);
};

var DateTimeUtil =
{
	extractDate: function(p_date)
	{
		try
		{
	    	if(isValidDate(p_date))
	    	{
	    		return completeLeftWithZero(p_date.getDate(), 2) + "/" + completeLeftWithZero(p_date.getMonth() + 1, 2) + "/" + completeLeftWithZero(p_date.getFullYear(), 4);
	    	}
		}
		catch(eee) { /* silent */ }
		return null;
	},
	
	extractTime: function(p_date)
	{
		try
		{
	    	if(isValidDate(p_date))
	    	{
	    		return completeLeftWithZero(p_date.getHours(), 2) + ":" + completeLeftWithZero(p_date.getMinutes(), 2);
	    	}
		}
		catch(eee) { /* silent */ }
		return null;
	},
	
    /**
     * Passa a String para o formato Date evitando problemas de timezone e de i18n (formato da data)
     * 
     * Se falhar, mantém o valor inválido para evidenciar o erro
     */
    initDate: function(p_dateAsStr)
    {
    	var obj = (!p_dateAsStr) ? null : p_dateAsStr;

    	if(obj && !angular.isDate(obj))
    	{
    		// Se for inteiro, considera como millis
    		if(angular.isNumber(obj))
    		{
    			obj = new Date(obj);
    		}
    		
    		// Testa o formato YYYY-MM-DD
    		else if(angular.isString(obj) && obj.length >= 10 && obj.substring(0, 10).search(/^\d{4}[./-]\d{2}[./-]\d{2}$/) === 0)
    		{
    			var tokens = $.trim(obj).split("-");
	    		obj = createNDate(parseInt(tokens[0], 10), parseInt(tokens[1], 10) - 1, parseInt(tokens[2], 10), 0, 0, 0);
    		}
    		else
    		{
	    		try
	    		{
		    		var tokens = $.trim(obj).split("/");
		    		obj = createNDate(parseInt(tokens[2], 10), parseInt(tokens[1], 10) - 1, parseInt(tokens[0], 10), 0, 0, 0);
	    		}
	    		catch(eee) { /* silent */ }
    		}
    	}
    	return obj;
    },
	
    /**
     * Converte a data no formato String HH:MM evitando problemas de timezone
     * Se for valor inválido, mantém a String para evidenciar o erro 
     */
    initTime: function(p_timeAsStr)
    {
    	return (p_timeAsStr) ? p_timeAsStr : "";
//		var date = null;
//		try
//		{
//    		var tokens = $.trim(p_timeAsStr).split(":");
//    		var hours = parseInt(tokens[0], 10);
//    		var minutes = parseInt(tokens[1], 10);
//    		date = TIME_MASK(hours, minutes);
//		}
//		catch(eee){ /*silent*/ }
//		
//		if(isValidDate(date))
//		{
//			return date;    			
//		}
//		else
//		{
//			return null;
//		}
    },
    
    /**
     * Checa se o time é valido considerando que é uma String 
     */
    isValidTime: function(p_timeAsStr)
    {
		if(p_timeAsStr)
		{
			try
			{
				var tokens = $.trim(p_timeAsStr).split(":");
				var hours = parseInt(tokens[0], 10);
				var minutes = parseInt(tokens[1], 10);
				
				return (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59);
			}
			catch(eee) { /*silent*/ }
		}
		return false;

    }
};